import { useState, useCallback } from "react";
import {
  SelectField,
  useSelectQuery,
  Typography,
  Button,
  Switch,
} from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";
import { StyleSheet, View } from "react-native";

import { useDrawerNav } from "@/common/AppDrawer";
import { useCMWFeatureFlagMutation } from "@/api/cmw-feature-flags";
import { instance } from "@/lib/hooks";

export function AddFeatureFlagGate({ flagName }: { flagName: string }) {
  const [organization, setOrganization] = useState(null);
  const [group, setGroup] = useState(null);
  const [entity, setEntity] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const drawer = useDrawerNav();

  const orgQueryProps = useSelectQuery(
    (inputValue) => ["organization-lookup", { inputValue }] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey;
      return instance
        .get("/organizations", {
          params: {
            name: inputValue ? inputValue : undefined,
            page: pageParam,
            limit: 500,
          },
        })
        .then((response) => response.data as PaginatedResponse);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

  const groupQueryProps = useSelectQuery(
    (inputValue) => ["group-lookup", { inputValue }] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey;
      return instance
        .get("/groups", {
          params: {
            name: inputValue ? inputValue : undefined,
            page: pageParam,
            include_organization: true,
            limit: 500,
          },
        })
        .then((response) => response.data as PaginatedResponse);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

  const [updateFeatureFlag] = useCMWFeatureFlagMutation();
  const onSubmit = useCallback(async () => {
    if (!(organization || group)) {
      return null;
    }

    const payload: { enabled: boolean } & Record<string, any> = { enabled };

    if (organization) {
      payload.organization_id = organization.id;
    } else if (group) {
      payload.group_id = group.id;
    }

    await updateFeatureFlag({
      flag: flagName,
      payload,
    });

    drawer.pop();
  }, [flagName, updateFeatureFlag, organization, group, enabled, drawer]);

  return (
    <View style={styles.container}>
      {entity ? null : (
        <View>
          <Typography type="title4">
            Do you want to add an organization or a group?
          </Typography>
          <View style={styles.marginContainer}>
            <Button onPress={() => setEntity("organization")}>
              Organization
            </Button>
            <Typography type="body" style={styles.center}>
              - or -
            </Typography>
            <Button onPress={() => setEntity("group")}>Group</Button>
          </View>
        </View>
      )}
      {entity === "organization" ? (
        <SelectField
          name="organization"
          label="Select Organization"
          getOptionValue={({ id }) => id}
          getOptionLabel={({ name }) => name}
          required
          value={organization}
          onChange={setOrganization}
          {...orgQueryProps}
        />
      ) : null}

      {entity === "group" ? (
        <SelectField
          name="group"
          label="Select Group"
          getOptionValue={({ id }) => id}
          getOptionLabel={({ marketing_name, organization_name }) =>
            `${marketing_name} - ${organization_name}`
          }
          required
          value={group}
          onChange={setGroup}
          {...groupQueryProps}
        />
      ) : null}

      {entity ? (
        <View style={styles.marginContainer}>
          <Switch
            name="enabled"
            label="Enabled"
            assistiveText={`Whether the feature is enabled or not for this ${entity}`}
            onValueChange={setEnabled}
            value={enabled}
          />
        </View>
      ) : null}

      {group || organization ? (
        <View style={styles.marginContainer}>
          <Button onPress={onSubmit}>Save</Button>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  marginContainer: {
    marginTop: 16,
  },
  center: {
    textAlign: "center",
    marginVertical: 8,
  },
});
