import { useState, useCallback, useEffect, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { Link } from "react-router-dom";
import moment from "moment";

import { Text, Link as SRLink, useTheme } from "@smartrent/ui";

import Box from "@/common/Box";
import Pill from "@/common/Pill";
import HubDebug from "@/pages/unit/hub/HubDebug";
import { HubTroubleshooting } from "@/pages/unit/hub/HubTroubleshooting";
import { useUser } from "@/layout/Context";

import helpers from "@/lib/helpers";

import { HubPrepLog as HubPrepLogProp } from "@/types";
import { useRedisStatsQuery } from "@/api/zipato";

import { useIsMobile } from "@/hooks/breakpoints";

import { HubPrepLogOnlineButton } from "./HubPrepLogOnlineButton";
import { HubPrepLogCounter } from "./HubPrepLogCounter";
import { HubPrepLogTerminateButton } from "./HubPrepLogTerminateButton";
import { HubPrepRetryFirmwareUpgradeButton } from "./HubPrepRetryFirmwareUpgradeButton";

export default function HubPrepLog({
  hubPrepLog,
}: {
  hubPrepLog: HubPrepLogProp;
}) {
  const { colors } = useTheme();
  const { permissions } = useUser();
  const isMobile = useIsMobile();
  const [logs, setLogs] = useState(null);
  const [box, setBox] = useState(null);
  const [serial, setSerial] = useState(null);

  const [ableToRefresh, setAbleToRefresh] = useState(true);

  const updateHubLogs = useCallback(
    (logs: any) => {
      setLogs(
        logs.map((log) => {
          try {
            return JSON.parse(log?.message);
          } catch {
            return {};
          }
        })
      );
    },
    [setLogs]
  );

  const hubType = serial && helpers.getHubTypeFromSerial(serial);
  // disable query, only use it when hub is rebooting
  const { refetch, isFetched, isFetching, isLoading } = useRedisStatsQuery(
    { serial },
    { enabled: false }
  );

  // call box/session & update our box state
  const reFetchBox = useCallback(async () => {
    if (!isFetching && serial && hubType !== "smartrent") {
      setAbleToRefresh(false); // disable the refresh online button
      const { isSuccess, data } = await refetch();
      if (isSuccess) {
        const { box: redisBox } = data;
        setBox(redisBox);
      }
    }
  }, []);

  const {
    serial: hubPrepLogSerial,
    started_at,
    action_required,
    hub_account_registration_required,
    hub_account_registered_at,
    hub_account_id,
    updated_at,
    config_update_attempted_at,
    config_updated_at,
    config_update_required,
    firmware_desired,
    firmware_update_required,
    firmware_upgrade_attempted_at,
    firmware_upgraded_at,
    rekey_required,
    rekey_attempted_at,
    rekeyed_at,
    secure_inclusion_enabled_at,
    cell_network_confirmed_at,
    reboot_required,
    reboot_attempted_at,
    rebooted_at,
    box_sync_required,
    box_sync_attempted_at,
    box_synced_at,
    community_wifi_required,
    community_wifi_attempted_at,
    community_wifi_applied_at,
    hub,
    reset_zwave_required,
    reset_zwave_attempted_at,
    reset_zwave_completed_at,
    status,
    verify_hub_sim_required,
    verify_hub_sim_attempted_at,
    times_run,
    failed_at,
  } = hubPrepLog;

  // set hub serial state to use in our hooks
  useEffect(() => setSerial(hubPrepLogSerial), [hubPrepLogSerial]);

  const isRebooting = reboot_attempted_at && !rebooted_at;

  const borderLeftColor = useMemo(() => {
    switch (status) {
      case "Complete":
        return colors.green;
      case "In Progress":
        return colors.blue;
      case "Failed":
        return colors.error;
    }
  }, [colors.blue, colors.error, colors.green, status]);

  // disable refresh button for few seconds to show activity animation instead
  useEffect(() => {
    let loader;

    if (!isLoading && !isFetching) {
      loader = window.setTimeout(() => {
        setAbleToRefresh(true);
      }, 1500);
    }

    return () => {
      window.clearTimeout(loader);
    };
  }, [isLoading, isFetching]);

  // fetch box/session if hub is rebooting
  useEffect(() => {
    if (serial && isRebooting && !isFetched) {
      reFetchBox();
    }
  }, [serial, isRebooting]);

  return (
    <Box
      key={serial}
      style={[
        isMobile ? mobileStyles.boxCard : styles.boxCard,
        styles.colorBorder,
        { borderLeftColor },
      ]}
    >
      {isRebooting ? (
        <HubPrepLogOnlineButton
          disabled={!ableToRefresh}
          isFetching={isFetching || ableToRefresh}
          onPress={reFetchBox}
          isOnline={box?.online} // only source of truth during reboot phase is redis stats
          style={styles.onlineButton}
        />
      ) : null}

      <View
        style={isMobile ? mobileStyles.headerContainer : styles.headerContainer}
      >
        <SRLink
          href={`/hubs/${serial}`}
          ellipsizeMode="middle"
          numberOfLines={1}
        >
          {serial}
        </SRLink>

        <View style={styles.flexRow}>
          {times_run ? <HubPrepLogCounter times_run={times_run} /> : null}
          {permissions.advanced_hub_troubleshooting &&
          action_required &&
          started_at ? (
            <HubPrepLogTerminateButton serial={serial} />
          ) : null}
        </View>
      </View>

      {hub ? (
        <View style={styles.unitLink}>
          <Link className="a" to={`/units/${hub.unit_id}`}>
            (unit)
          </Link>
        </View>
      ) : null}

      <Text type="body">
        {started_at ? (
          <div className="u-font14 u-text-gray-600">
            Running since {helpers.formatDate(started_at)}
            {moment(started_at).isBefore(moment().subtract(15, "minutes")) ? (
              <span
                style={{
                  color: `${
                    moment(started_at).isBefore(
                      moment().subtract(15, "minutes")
                    )
                      ? colors.orange
                      : "#000"
                  }`,
                }}
              >
                {" "}
                ({moment(started_at).fromNow()})
              </span>
            ) : null}
          </div>
        ) : action_required ? (
          <div className="u-font14 u-text-gray-600">Not Started</div>
        ) : (
          <div className="u-font14 u-text-gray-600">
            Done at {helpers.formatDate(updated_at)}
          </div>
        )}
      </Text>

      {failed_at && !!logs?.length ? (
        <HubPrepRetryFirmwareUpgradeButton
          serial={serial}
          box={box}
          firmware_desired={firmware_desired}
          firmware_update_required={firmware_update_required}
          firmware_upgrade_attempted_at={firmware_upgrade_attempted_at}
        />
      ) : null}

      <View style={styles.pillContainer}>
        {hub_account_registration_required && !hub_account_id ? (
          <Pill color={colors.orange} borderColor={colors.orange}>
            Registering Hub Account
          </Pill>
        ) : hub_account_id && hub_account_registered_at ? (
          <Pill color={colors.green} borderColor={colors.green}>
            Registered
          </Pill>
        ) : null}

        {firmware_update_required && !firmware_upgrade_attempted_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Checking Firmware
          </Pill>
        )}
        {firmware_upgrade_attempted_at && !firmware_upgraded_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Upgrading to {firmware_desired}
          </Pill>
        )}
        {firmware_upgraded_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            {firmware_desired} Installed
          </Pill>
        )}

        {!config_update_attempted_at &&
          (config_update_required && !config_updated_at ? (
            <Pill color={colors.blue} borderColor={colors.blue}>
              Updating Hub Config
            </Pill>
          ) : null)}
        {config_updated_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Updated Hub Config
          </Pill>
        )}

        {rekey_required && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Queued To Rekey
          </Pill>
        )}
        {!rekeyed_at && rekey_attempted_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Rekeying
          </Pill>
        )}
        {rekeyed_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Rekeyed
          </Pill>
        )}

        {cell_network_confirmed_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Cell Network
          </Pill>
        )}
        {secure_inclusion_enabled_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Secure Inclusion
          </Pill>
        )}

        {reboot_required && !reboot_attempted_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Queued To Reboot
          </Pill>
        )}
        {reboot_attempted_at && !rebooted_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Rebooting
          </Pill>
        )}
        {rebooted_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Rebooted
          </Pill>
        )}

        {box_sync_required && !box_sync_attempted_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Box Sync Queued
          </Pill>
        )}
        {box_sync_attempted_at && !box_synced_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Box Syncing
          </Pill>
        )}

        {box_synced_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Box Synced
          </Pill>
        )}

        {community_wifi_required && !community_wifi_attempted_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Apply WiFi Device Network
          </Pill>
        )}
        {community_wifi_attempted_at && !community_wifi_applied_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Applying WiFi Device Network
          </Pill>
        )}
        {community_wifi_applied_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Applied WiFi Device Network
          </Pill>
        )}

        {reset_zwave_required && !reset_zwave_completed_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Reset Z-Wave Network
          </Pill>
        )}
        {!reset_zwave_required &&
          reset_zwave_attempted_at &&
          reset_zwave_completed_at && (
            <Pill color={colors.green} borderColor={colors.green}>
              Reset Z-Wave Network
            </Pill>
          )}
        {!reset_zwave_required &&
          reset_zwave_attempted_at &&
          !reset_zwave_completed_at && (
            <Pill color={colors.error} borderColor={colors.error}>
              Reset Z-Wave Network
            </Pill>
          )}

        {verify_hub_sim_required && !verify_hub_sim_attempted_at && (
          <Pill color={colors.blue} borderColor={colors.blue}>
            Verify Hub SIM
          </Pill>
        )}
        {verify_hub_sim_attempted_at && (
          <Pill color={colors.green} borderColor={colors.green}>
            Verify Hub SIM
          </Pill>
        )}
      </View>

      <View style={styles.logs}>
        <HubTroubleshooting
          hub={hub || { serial }}
          permissions={permissions}
          leftAligned={true}
          logsCallback={updateHubLogs}
          redisStatsCallback={({ box }) => {
            setBox(box);
          }}
        />
      </View>

      <HubDebug hub={{ serial }} permissions={permissions} devices={[]} />
    </Box>
  );
}

const styles = StyleSheet.create({
  boxCard: {
    padding: 24,
    marginTop: 16,
    flex: 1,
  },
  logs: {
    marginTop: 16,
  },
  colorBorder: {
    borderLeftWidth: 4,
  },

  onlineButton: {
    justifyContent: "center",
  },

  headerContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },

  // shorten the width of the link so it doesn't extend across the entire card
  unitLink: { marginVertical: 4, maxWidth: "25%" },

  flexRow: { flexDirection: "row" },

  pillContainer: {
    flex: 1,
    flexWrap: "wrap",
    alignItems: "flex-start",
    flexDirection: "row",
  },
});

const mobileStyles = StyleSheet.create({
  boxCard: {
    padding: 8,
    marginTop: 8,
    flex: 1,
  },

  headerContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});
