import { createAxiosQuery, createAxiosMutation } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";

import { FeatureFlag, FeatureFlagPayload } from "@/types";

export const useCMWFeatureFlagsQuery = createAxiosQuery(
  "cmw-feature-flags",
  async () => {
    return instance.get<FeatureFlag[]>("/cmw-feature-flags");
  }
);

export const useCMWFeatureFlagMutation = createAxiosMutation(
  async ({ flag, payload }: { flag: string; payload: FeatureFlagPayload }) => {
    return instance.patch(`/cmw-feature-flags/${flag}`, payload);
  },
  {
    successToast: () => ({
      message:
        "CMW feature flag updated. Changes can take up to 10 minutes to take effect.",
    }),
    errorToast: () => ({
      message: `Something went wrong. Please contact engineering.`,
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({ queryKey: ["cmw-feature-flags"] });
    },
  }
);
