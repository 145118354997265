import { useCallback, useEffect, useState } from "react";
import { startCase } from "lodash-es";

import { UserApplication, fetchApps, onPressApp } from "@/api/my-applications";

export const useCentralStation = () => {
  const [umbrellaUserId, setUmbrellaUserId] = useState<null | string>(null);
  const [apps, setApps] = useState<UserApplication[]>([]);
  const [appsLoading, setAppsLoading] = useState<boolean>(false);
  const [appsError, setAppsError] = useState<string | undefined>(undefined);

  const [redirectLinkLoading, setRedirectLinkLoading] = useState<string | null>(
    null
  );
  const [urlError, setUrlError] = useState<string | undefined>(undefined);

  const getApps = useCallback(async () => {
    try {
      setAppsLoading(true);
      const { id, applications } = await fetchApps();
      setUmbrellaUserId(id);
      setApps(applications);
    } catch (e) {
      setAppsError("Error fetching applications");
    } finally {
      setAppsLoading(false);
    }
  }, []);

  useEffect(() => {
    getApps();
  }, [getApps]);

  const generateAppRedirectLink = useCallback(
    async (appName: any, options?: { redirectTo: string }) => {
      try {
        setRedirectLinkLoading(appName);
        const redirectUrl = await onPressApp(appName, options?.redirectTo);

        window.open(redirectUrl, "_blank");
      } catch (e) {
        setUrlError(
          `Error fetching redirect url for application: ${startCase(appName)}`
        );
      } finally {
        setRedirectLinkLoading(null);
      }
    },
    []
  );

  const handlePressViewAll = useCallback(async () => {
    try {
      setRedirectLinkLoading("view-all");
      const redirectUrl = await onPressApp("central_station");

      window.open(redirectUrl, "_blank");
    } catch (e) {
      setUrlError(`Error fetching redirect url to view all applications`);
    } finally {
      setRedirectLinkLoading(null);
    }
  }, []);

  return {
    apps,
    appsLoading,
    appsError,
    getApps,
    redirectLinkLoading,
    urlError,
    generateAppRedirectLink,
    handlePressViewAll,
    umbrellaUserId,
  };
};
