import { useEffect, useMemo, useState } from "react";
import * as React from "react";

import Table from "@/common/Table";
import Helpers from "@/lib/helpers";
import { useGroupImportsQuery } from "@/api/imports";

interface ImportListProps {
  groupId: string;
}

function formatVendor(vendor: string): string {
  switch (vendor) {
    case "pni":
      return "PNI";
    default:
      return vendor;
  }
}

function formatResults(type: string, result: any): React.ReactNode {
  if (type === "parking-sensors") {
    return (
      <>
        <p>Created: {result.created}</p>
        <p>Duplicates: {result.duplicates}</p>
        <p>Errors: {result.errors}</p>
      </>
    );
  }

  return "\u2014";
}

export const ImportList: React.FC<React.PropsWithChildren<ImportListProps>> = ({
  groupId,
}) => {
  const [polling, setPolling] = useState(false);
  const { data } = useGroupImportsQuery(parseInt(groupId), "parking-sensors", {
    refetchInterval: polling ? 10000 : false,
  });

  useEffect(() => {
    const hasIncompleteImports = (data ?? []).some((row) => !row.completed);
    setPolling(hasIncompleteImports);
  }, [data]);

  const columns = useMemo(
    () => [
      {
        label: "Date",
        className: "u-flex u-flex-align-items-center grid__sm--12 grid__md--3",
        render: (record) => (
          <div>
            {record.inserted_at ? Helpers.formatDate(record.inserted_at) : null}
          </div>
        ),
      },
      {
        label: "Type",
        className: "u-flex u-flex-align-items-center grid__sm--12 grid__md--3",
        render: (record) => <div>{record.type}</div>,
      },
      {
        label: "Vendor",
        className: "u-flex u-flex-align-items-center grid__sm--12 grid__md--3",
        render: (record) => <div>{formatVendor(record.vendor)}</div>,
      },
      {
        label: "Result",
        className: "u-flex u-flex-align-items-center grid__sm--12 grid__md--3",
        render: (record) => (
          <div>{formatResults(record.type, record.results)}</div>
        ),
      },
    ],
    []
  );

  return (
    <Table
      columns={columns}
      records={data ?? []}
      noRecordsTitle="No Imports Found"
      classes={{
        container: "list--striped",
        chevron: "icon--gray-400",
        chevronActive: "icon--gray-500",
        header: "u-text-upper u-sans-bold u-text-gray-400",
      }}
    />
  );
};
