export const uploadcareConfig = {
  tabs: "file camera url",
  imagesOnly: "true",
  previewStep: "true",
  crop: "500x290 minimum",
};

// We made Support and Support International uploadcare projects.
// We use same key for all envs in the same region (like NA vs international).
export const uploadcarePublicKey =
  import.meta.env.REACT_APP_UPLOADCARE_PUBLIC_KEY || "";
