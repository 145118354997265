import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";

import { Button, LegacyFlatList } from "@smartrent/ui";

import { Space } from "@smartrent/parking";

import { SignSpace } from "@/common/parking/drawer/EditSignSpaces/SignSpace";

import { useSpacesInfiniteQuery } from "@/api";
import NoRecords from "@/common/NoRecords";

interface SignSpacesProps {
  signId: string;
  propertyId: string;
}

const ITEM_HEIGHT = 100;

export const SignSpaces: React.FC<React.PropsWithChildren<SignSpacesProps>> = ({
  signId,
  propertyId,
}) => {
  const {
    reducedData: spaces,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useSpacesInfiniteQuery(propertyId, {
    sign_id: signId,
  });

  const showLoadMoreButton = useMemo(
    () => hasNextPage && !isFetchingNextPage,
    [hasNextPage, isFetchingNextPage]
  );

  const onLoadMore = useCallback(() => fetchNextPage(), [fetchNextPage]);

  return (
    <LegacyFlatList<Space>
      data={spaces}
      keyExtractor={(item) => item.id}
      renderItem={({ item }) => <SignSpace space={item} />}
      ListFooterComponent={
        showLoadMoreButton ? (
          <Button onPress={onLoadMore} variation="plain" style={styles.button}>
            Load More
          </Button>
        ) : null
      }
      ListFooterComponentStyle={styles.buttonContainer}
      getItemLayout={(data, index) => ({
        length: ITEM_HEIGHT,
        offset: ITEM_HEIGHT * index,
        index,
      })}
      ListEmptyComponent={
        <View style={styles.noRecords}>
          <NoRecords title="No Spaces Assigned" />
        </View>
      }
    />
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  button: { width: 200, marginVertical: 20 },
  noRecords: { marginTop: 16 },
});
