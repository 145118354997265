import { EM_DASH } from "./chars";

export const getOrgName = (org, group) => {
  if (group?.organization) {
    return group.organization.name;
  } else if (org) {
    return org.name;
  } else {
    return EM_DASH;
  }
};
