import { useState } from "react";
import { StyleSheet } from "react-native";

import {
  ConfirmDialog,
  SelectField,
  Spacer,
  Typography,
  useSelectQuery,
  VStack,
} from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

import { useUpsertRoleMutation } from "@/api";
import { useUser } from "@/layout/Context";

const RoleSwitcher = () => {
  const { permissions } = useUser();
  const [value, setValue] = useState(null);
  const [upsertRole] = useUpsertRoleMutation();

  const selectQueryProps = useSelectQuery(
    (inputValue) => ["users", { inputValue }],
    async function fetchUsers({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey as [unknown, { inputValue: string }];
      const response = await instance.get(`/roles`, {
        params: {
          name: inputValue ? inputValue : undefined,
        },
      });

      return {
        records: response.data,
        total_pages: 1,
        page: 1,
        total_records: response?.data?.length,
      };
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

  const { visible, onOpen, onClose } = useModalState();

  return (
    <VStack spacing={16} style={styles.roleSwitcher}>
      <Typography variation="bold.title.three">Switch Roles</Typography>
      <VStack spacing={4}>
        <Typography variation="bold.title.five" color="textSecondary">
          Current Role:
        </Typography>
        <Typography variation="bold.title.five">{permissions?.name}</Typography>
      </VStack>
      <SelectField
        name="roles"
        label="Temporary role"
        getOptionValue={({ id }: { id: string }) => id}
        getOptionLabel={({ name }: { name: string }) => name}
        value={value}
        onChange={(value) => {
          setValue(value);
          onOpen();
        }}
        {...selectQueryProps}
      />
      <ConfirmDialog
        onClose={onClose}
        visible={visible}
        title="Are you sure?"
        description={`This will set your role to ${value?.name} until the next time you log in.`}
        onConfirm={async () => {
          await upsertRole(value);
          window.location.replace("/");
        }}
      />
      <Spacer height={16} />
    </VStack>
  );
};

const styles = StyleSheet.create({
  roleSwitcher: { padding: 8 },
});

export default RoleSwitcher;
