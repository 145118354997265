import { View, StyleSheet } from "react-native";
import { filter, startCase } from "lodash-es";
import { TrashX } from "@smartrent/icons";
import { Box, StatusBadge, Typography, useTheme, VStack } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { useUser } from "@/layout/Context";

import AccessCodesOnLock from "./AccessCodeOnLock";
import DeviceAttribute from "./DeviceAttribute";

import { useUnit } from "./UnitContext";
import { DeletedDeviceDebugInfo } from "./DeletedDeviceDebugInfo";

export default function DeletedDevices() {
  const { permissions } = useUser();
  const { colors } = useTheme();
  const { deletedDevices, deviceAttributes, accessCodes, unit } = useUnit();

  const elements = deletedDevices.map((device) => {
    const attributes = filter(deviceAttributes, (da) => {
      return da.device_id === device.id && !da.name.includes("NOT_MAPPED");
    });

    return (
      <View
        key={device.id}
        style={[styles.cardOutline, { borderColor: colors.error }]}
      >
        <Box style={styles.boxStyle}>
          <View style={styles.verticalMargin}>
            <View style={styles.flexRow}>
              <TrashX size={18} color={colors.error} />
              <Typography type="title5" color="error">
                Deleted{" "}
              </Typography>

              <Typography type="title5" color="error">
                {startCase(device.residents_name || device.internal_name)}{" "}
              </Typography>
              {device.primary_lock ? (
                <Typography color="textSecondary" type="title6">
                  primary
                </Typography>
              ) : null}
            </View>
            <View>
              {device.device_descriptor_id ? (
                <Typography type="title4">
                  {device.manufacturer} {device.model}
                </Typography>
              ) : (
                <View
                  style={[styles.badgeBox, { borderColor: colors.warning }]}
                >
                  <Typography
                    color="warning"
                    type="title6"
                    style={styles.alignTextCenter}
                  >
                    No device descriptor rules found. This device may not
                    properly report device attribute changes and residents could
                    have issues controlling this device.
                  </Typography>
                </View>
              )}
            </View>
            {device.warning ? (
              <View style={[styles.badgeBox, { borderColor: colors.warning }]}>
                <Typography
                  color="warning"
                  type="title6"
                  style={styles.alignTextCenter}
                >
                  Device in "warning" state Communication with device failing
                  intermittently If this continues, the device will be marked as
                  offline.
                </Typography>
              </View>
            ) : null}
            {device.internal_name &&
            device.internal_name !== device.residents_name ? (
              <Typography type="title6" color="textSecondary">
                Internal Name: ({startCase(device.internal_name)})
              </Typography>
            ) : null}
          </View>

          <View>
            <AccessCodesOnLock
              device={device}
              accessCodes={accessCodes[device.id]}
              permissions={permissions}
            />

            {attributes.map((da) => (
              <DeviceAttribute
                key={da.id}
                da={da}
                unit={unit}
                accessCodes={accessCodes}
              />
            ))}
          </View>
          <View
            style={[styles.viewBorderTop, { borderTopColor: colors.gray200 }]}
          >
            {!device.secure ? (
              <View style={styles.secureInclusionWrapper}>
                <StatusBadge
                  status={
                    device.type === "entry_control" ? "error" : "knowledge"
                  }
                  variation={
                    device.type === "entry_control" ? "default" : "outlined"
                  }
                  size="semibold.title.three"
                >
                  Secure Inclusion Failed
                </StatusBadge>
              </View>
            ) : null}
            <Typography
              style={styles.dskMarginTop}
              type="caption"
              color="textSecondary"
            >
              DSK: {device.dsk || "\u2014"}
            </Typography>
            <VStack style={styles.verticalMargin} spacing={2}>
              <Typography type="title5" color="textSecondary">
                {device.configuration ||
                device.installation_status === "in_progress"
                  ? `Installed${
                      device.installed_by_first_name &&
                      device.installed_by_last_name
                        ? ` by ${device.installed_by_first_name} ${device.installed_by_last_name}`
                        : ""
                    } on `
                  : `Legacy install on `}
                {Helpers.formatDate(device.inserted_at, "L LT")}
              </Typography>
              <Typography type="title5" color="textSecondary">
                Deleted on {Helpers.formatDate(device.deleted_at, "L LT")}
              </Typography>
              {device.installation_status !== "complete" ? (
                <View style={styles.verticalMargin}>
                  <StatusBadge status="error" size="semibold.title.four">
                    Device install never completed
                  </StatusBadge>
                </View>
              ) : null}
            </VStack>

            <DeletedDeviceDebugInfo {...device} />
          </View>
        </Box>
      </View>
    );
  });

  return <>{elements}</>;
}

const styles = StyleSheet.create({
  alignTextCenter: { textAlign: "center" },
  cardOutline: {
    margin: 4,
    width: 480,
    borderWidth: 1,
    borderRadius: 2,
  },
  dskMarginTop: { marginTop: 8 },
  flexRow: { flexDirection: "row" },
  boxStyle: { height: "100%" },
  secureInclusionWrapper: { marginTop: 8, marginBottom: 8, width: "50%" },
  badgeBox: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 4,
    marginVertical: 8,
  },
  viewBorderTop: {
    borderTopWidth: 1,
    padding: 4,
  },
  verticalMargin: { marginVertical: 8 },
});
