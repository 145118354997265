import { useMemo } from "react";
import * as React from "react";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerLoader,
  FormikSelectField,
  FormikSubmit,
  SelectOption,
} from "@smartrent/ui";

import { useListUnitMapQuery, useParkingPropertyQuery } from "@/api";
import { ParkingProperty } from "@/types";
import { NoRecords } from "@/common/sr-table/NoRecords";

export type ParkingMapsFormValues = Pick<ParkingProperty, "map_id">;

interface ParkingMapsFormProps {
  propertyId: string;
  onSubmit: (
    values: ParkingMapsFormValues,
    actions: FormikHelpers<ParkingMapsFormValues>
  ) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

const validationSchema = Yup.object({
  map_id: Yup.string().nullable().required("Please select a map.").trim(),
});

export const ParkingMapsForm: React.FC<
  React.PropsWithChildren<ParkingMapsFormProps>
> = ({ propertyId, onSubmit, onCancel }) => {
  const {
    data: maps,
    isLoading: loadingUnitMaps,
    isError,
  } = useListUnitMapQuery({});

  const { data: property, isLoading: loadingProperty } =
    useParkingPropertyQuery(propertyId);

  const loading = loadingUnitMaps || loadingProperty;

  const mapOptions: SelectOption[] = useMemo(
    () =>
      maps?.map((unitMap) => ({
        label: unitMap.name,
        value: unitMap.id,
      })) ?? [],
    [maps]
  );

  const initialValues: ParkingMapsFormValues = useMemo(
    () => ({
      map_id: property?.map_id ?? "",
    }),
    [property]
  );

  const label = property?.maps_module_enabled ? "Update" : "Enable";

  const submittingLabel = property?.maps_module_enabled
    ? "Updating..."
    : "Enabling...";

  return loading ? (
    <DrawerLoader />
  ) : (
    <Formik<ParkingMapsFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <DrawerContent>
          {maps && maps.length && !isError ? (
            <FormikSelectField
              name="map_id"
              options={mapOptions}
              label="Unit Map"
            />
          ) : (
            <NoRecords
              title="Failed to Load Maps"
              text="Please refresh the page"
            />
          )}
        </DrawerContent>
        <DrawerActions>
          <Button onPress={onCancel} variation="plain">
            Cancel
          </Button>
          <FormikSubmit label={label} submittingLabel={submittingLabel} />
        </DrawerActions>
      </>
    </Formik>
  );
};
