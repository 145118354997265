import { useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import { FormikStaticTextField, useTheme } from "@smartrent/ui";

import { useFormikFieldsHook } from "@/pages/hub-refurbish/hooks/useFormikFieldsHook";

import {
  HubRefurbishFormikFieldLabels,
  HubRefurbishFormikFieldNames,
  HubRefurbishReactQueryKeys,
} from "@/types/HubRefurbishProps";

import { useHubRefurbishContext } from "../HubRefurbishContext";

export const FormikHubRefurbishJobErrorsField: React.VFC = () => {
  const { colors } = useTheme();
  const { getHubs } = useHubRefurbishContext();

  const errorJobs = useMemo(() => getHubs((hub) => hub?.isError), [getHubs]);

  const { onClearCallback, containerStyle, labelStyle, disabled } =
    useFormikFieldsHook({
      required: false,
      name: HubRefurbishFormikFieldNames.JobErrors,
      queryKey: HubRefurbishReactQueryKeys.HubRefurbishJob,
      value: errorJobs,
    });

  return (
    <FormikStaticTextField
      style={styles.root}
      containerStyle={{
        ...containerStyle,
        ...{ backgroundColor: colors.mutedBackgroundWarning },
      }}
      labelStyle={{
        ...labelStyle,
        ...{ color: colors.mutedTextWarning },
      }}
      name={HubRefurbishFormikFieldNames.JobErrors}
      label={HubRefurbishFormikFieldLabels.JobErrors}
      onPress={() => null}
      transformValue={(value) => (value || []).length}
      onClear={onClearCallback}
      disabled={disabled}
    />
  );
};

const styles = StyleSheet.create({
  root: { maxWidth: 175 },
});
