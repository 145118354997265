import { useQuery } from "@tanstack/react-query";

import { AxiosError } from "axios";

import { createAxiosQuery } from "@/hooks/react-query";
import { instance } from "@/lib/hooks";
import {
  RedisStatsBatchResponse,
  ZipatoAccountResponse,
  ZipatoMessageLogProps,
  ZipatoQueryParams,
  ZipatoRedisStatsProps,
} from "@/types";

export const useAccountQuery = createAxiosQuery(
  "zipato-account-query",
  async ({ serial }: ZipatoQueryParams) =>
    instance.get<ZipatoAccountResponse>(`/hubs/${serial}/zipato`)
);

export const useHubLogsQuery = createAxiosQuery(
  "zipato-hub-logs-query",
  async ({ remote_id, serial }: ZipatoQueryParams) =>
    instance.get<ZipatoMessageLogProps[]>("/zipato/message-logs", {
      params: { remote_id, serial },
    })
);

export const useBoxQuery = createAxiosQuery(
  "zipato-box-query",
  async ({ unit_id }: ZipatoQueryParams) =>
    instance.get(`units/${unit_id}/hub/box-get`)
);

export const useRedisStatsQuery = createAxiosQuery(
  "zipato-redis-stats-query",
  async ({ serial }: ZipatoQueryParams) =>
    instance.get<ZipatoRedisStatsProps>(`/zipato/redis-stats`, {
      params: { serial },
    })
);

export const redisStatsBatchQueryFn = async (serials: string[]) =>
  (
    await instance.get<RedisStatsBatchResponse>(`/zipato/redis-stats/batch`, {
      params: { serials },
    })
  ).data;

export const useZipatoSerialsPreCheckQuery = ({ serials, options }: any) =>
  useQuery<any, AxiosError, any>(
    ["zipato-serials-all-exist", { serials }],
    async () => {
      const { data } = await instance.post<any>(`/zipato/serials-all-exist`, {
        serials,
      });
      return data;
    },
    options
  );
