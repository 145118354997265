import { useUnit } from "../UnitContext";

export default function UnitImage() {
  const { unit, group, organization } = useUnit();
  const preferredImage = getPreferredImage({ unit, group, organization });

  return (
    <div className="u-mbottom8">
      {preferredImage ? (
        <img
          src={`${preferredImage}?fit=clamp&max-h=275&w=432`}
          alt={unit.marketing_name}
          className="u-size-full"
          style={{ maxHeight: "275px" }}
        />
      ) : null}
    </div>
  );
}

function getPreferredImage({ unit, group, organization }) {
  if (unit.image_url) {
    return unit.image_url;
  } else if (group.group_image) {
    return group.group_image;
  } else if (organization.logo_url) {
    return organization.logo_url;
  }
  return null;
}
