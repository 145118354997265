import { useCallback, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useMutationCompat, useQueryCompat } from "@smartrent/hooks";

import { ParkingProperty } from "@/types";
import Context from "@/layout/Context";
import { AxiosMutationConfig } from "@/lib/react-query-helpers";

import { instance } from "@/lib/hooks";
import { useInvalidateSignQuery, useInvalidateSignsQuery } from "@/api";
import { useInvalidateSpacesQuery } from "@/api/parking/spaces";
import { getErrorMessage } from "@/lib/axios-helpers";
import { createAxiosMutation } from "@/hooks/react-query";

interface UpdatePropertyOptions {
  property: Pick<ParkingProperty, "id" | "decal_module_enabled" | "map_id">;
  message?: string;
  errorMessage?: string;
}
interface ToggleGuestParkingModuleOptions {
  property: ParkingProperty;
}

interface GuestPaymentOptions {
  property: Pick<
    ParkingProperty,
    | "id"
    | "guest_parking_rate"
    | "guest_parking_tax_rate"
    | "guest_parking_session_max_subtotal"
    | "stripe_connect_id"
    | "guest_parking_payments_module_enabled"
    | "guest_parking_application_fee"
  >;
}

interface ParkingMapOptions {
  property: Pick<ParkingProperty, "id" | "map_id" | "maps_module_enabled">;
}

interface UpdateParkingMapOptions {
  property: Pick<ParkingProperty, "id" | "map_id">;
}

export const useParkingPropertyQuery = (
  propertyId: string,
  parkingEnabled: boolean = false
) =>
  useQueryCompat(
    ["property", propertyId],
    async (key: "group", propertyId) => {
      const {
        data: { property },
      } = await instance.get<{ property: ParkingProperty }>(
        `/parking/properties/${propertyId}`
      );

      return property;
    },
    { enabled: !!propertyId && !!parkingEnabled }
  );

export const useInvalidateParkingProperty = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (propertyId: string) =>
      queryClient.invalidateQueries(["property", propertyId]),
    [queryClient]
  );
};

export const useUpdatePropertyMutation = (
  options?: AxiosMutationConfig<UpdatePropertyOptions>
) => {
  const context = useContext(Context);
  const invalidateProperty = useInvalidateParkingProperty();
  const invalidateSigns = useInvalidateSignsQuery();
  const invalidateSign = useInvalidateSignQuery();
  const invalidateSpaces = useInvalidateSpacesQuery();

  return useMutationCompat(
    async ({ property, message, errorMessage }: UpdatePropertyOptions) => {
      try {
        const { data } = await instance.patch(
          `/parking/properties/${property.id}`,
          property
        );

        context.setToast({
          type: "success",
          title: "Success",
          message: message ?? "Parking property successfully updated.",
        });

        invalidateProperty(property.id);
        invalidateSigns(property.id);
        invalidateSign(property.id);
        invalidateSpaces(property.id);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: errorMessage ?? "Error updating parking property.",
        });

        throw err;
      }
    },
    options
  );
};

export const useToggleGuestParkingModule = (
  options?: AxiosMutationConfig<ToggleGuestParkingModuleOptions>
) => {
  const context = useContext(Context);
  const invalidateProperty = useInvalidateParkingProperty();
  const invalidateSigns = useInvalidateSignsQuery();
  const invalidateSign = useInvalidateSignQuery();
  const invalidateSpaces = useInvalidateSpacesQuery();

  return useMutationCompat(
    async ({ property }: ToggleGuestParkingModuleOptions) => {
      try {
        const { data } = (await property?.guest_parking_module_enabled)
          ? await instance.delete(
              `/parking/properties/${property.id}/guest-parking`
            )
          : await instance.post(
              `/parking/properties/${property.id}/guest-parking`
            );

        context.setToast({
          type: "success",
          title: "Success",
          message: `Successfully ${
            property?.guest_parking_module_enabled ? "disabled" : "enabled"
          } guest parking.`,
        });

        invalidateProperty(property.id);
        invalidateSigns(property.id);
        invalidateSign(property.id);
        invalidateSpaces(property.id);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: `Error ${
            property?.guest_parking_module_enabled ? "disabling" : "enabling"
          } guest parking`,
        });

        throw err;
      }
    },
    options
  );
};

export const useToggleGuestPaymentsModule = (
  options?: AxiosMutationConfig<GuestPaymentOptions>
) => {
  const context = useContext(Context);
  const invalidateProperty = useInvalidateParkingProperty();

  return useMutationCompat(async ({ property }: GuestPaymentOptions) => {
    try {
      const { data } = (await property?.guest_parking_payments_module_enabled)
        ? await instance.post(
            `/parking/properties/${property.id}/guest-payments`,
            property
          )
        : await instance.delete(
            `/parking/properties/${property.id}/guest-payments`
          );

      context.setToast({
        type: "success",
        title: "Success",
        message: `Successfully ${
          property?.guest_parking_payments_module_enabled
            ? "enabled"
            : "disabled"
        } guest payments.`,
      });

      invalidateProperty(property.id);

      return data;
    } catch (err: any) {
      context.setToast({
        type: "error",
        title: "Error",
        message: `Error ${
          property?.guest_parking_payments_module_enabled
            ? "enabling"
            : "disabling"
        } guest payments. ${getErrorMessage(err)}`,
      });

      throw err;
    }
  }, options);
};

export const useUpdateGuestPaymentsModule = (
  options?: AxiosMutationConfig<GuestPaymentOptions>
) => {
  const context = useContext(Context);
  const invalidateProperty = useInvalidateParkingProperty();

  return useMutationCompat(async ({ property }: GuestPaymentOptions) => {
    try {
      const { data } = await instance.patch(
        `/parking/properties/${property.id}/guest-payments`,
        property
      );

      context.setToast({
        type: "success",
        title: "Success",
        message: "Successfully updated guest payments.",
      });

      invalidateProperty(property.id);

      return data;
    } catch (err: any) {
      context.setToast({
        type: "error",
        title: "Error",
        message: `Error updating guest payments. ${getErrorMessage(err)}`,
      });

      throw err;
    }
  }, options);
};

export const useToggleParkingMapModule = createAxiosMutation(
  async ({ property }: ParkingMapOptions) => {
    const { data } = (await property?.maps_module_enabled)
      ? await instance.post(
          `/parking/properties/${property.id}/parking-maps`,
          property
        )
      : await instance.delete(
          `/parking/properties/${property.id}/parking-maps`
        );

    return data;
  },
  {
    successToast: (data, { property }) => ({
      message: `Successfully ${
        property?.maps_module_enabled ? "enabled" : "disabled"
      } parking maps module.`,
    }),
    errorToast: (err, { property }) => ({
      message: `Error ${
        property?.maps_module_enabled ? "enabling" : "disabling"
      } parking maps module. ${getErrorMessage(err)}`,
    }),
    onSuccess: (queryClient, data, { property }) =>
      queryClient.invalidateQueries(["property", property.id]),
  }
);

export const useUpdateParkingMapsModule = createAxiosMutation(
  async ({ property }: UpdateParkingMapOptions) => {
    const { data } = await instance.patch(
      `/parking/properties/${property.id}/parking-maps`,
      property
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated parking maps module.",
    }),
    errorToast: (err) => ({
      message: `Error updating parking maps module. ${getErrorMessage(err)}`,
    }),
    onSuccess: (queryClient, data, { property }) =>
      queryClient.invalidateQueries(["property", property.id]),
  }
);
