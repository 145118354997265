import { useMemo, useCallback } from "react";

import {
  Typography,
  ColumnOptions,
  useTableQuery,
  Table,
  FilterPresetTextInputField,
  FilterPresetSelectField,
} from "@smartrent/ui";

import { capitalize } from "lodash-es";

import { GetQueryKeyFn } from "@/common/sr-table";

import { useGroupUnitsTableQuery } from "@/api/units";
import { getNumberOrdinal } from "@/lib/helpers";
import { UnitProps } from "@/types/UnitProps";

import {
  HubConnectionOptions,
  UnitStatusOptions,
  YesNoOptions,
} from "@/utils/options";

import { EM_DASH } from "@/utils/chars";

import { useGroup } from "../GroupContext";

interface AllUnitsProps {
  searchValue: string;
}

export const AllUnits = ({ searchValue }: AllUnitsProps) => {
  const { group, groupCommunityWifi } = useGroup();

  const columns = useMemo<ColumnOptions<UnitProps>[]>(() => {
    const tableColumns = [
      {
        header: "Unit Name",
        name: "marketing_name",
        render: ({ row }) => <Typography>{row.marketing_name}</Typography>,
        sortable: true,
      },
      {
        header: "Building",
        name: "building",
        render: ({ row }) => (
          <Typography>
            {[null, ""].includes(row.building) ? EM_DASH : row.building}
          </Typography>
        ),
        filterType: {
          type: "textInputField",
        } as FilterPresetTextInputField,
        sortable: true,
      },
      {
        header: "Floor",
        name: "floor",
        render: ({ row }) => (
          <Typography>
            {[null, ""].includes(row.floor)
              ? EM_DASH
              : getNumberOrdinal(parseInt(row.floor as string))}
          </Typography>
        ),
        filterType: {
          type: "textInputField",
        } as FilterPresetTextInputField,
        sortable: true,
      },
      {
        header: "Occupied",
        name: "is_occupied",
        render: ({ row }) => (
          <Typography>{row.is_occupied ? "Yes" : "No"}</Typography>
        ),
        filterType: {
          type: "selectField",
          options: YesNoOptions,
        } as FilterPresetSelectField<any>,
        sortable: true,
      },
      {
        header: "Status",
        name: "unit_status",
        render: ({ row }) => <Typography>{row.status}</Typography>,
        filterType: {
          type: "selectField",
          options: UnitStatusOptions,
        } as FilterPresetSelectField<any>,
        sortable: true,
      },
      {
        header: "Remote ID",
        name: "remote_id",
        render: ({ row }) => (
          <Typography>
            {[null, ""].includes(row.remote_id) ? EM_DASH : row.remote_id}
          </Typography>
        ),
        filterType: {
          type: "textInputField",
        } as FilterPresetTextInputField,
        sortable: true,
      },
      {
        header: "Hub Connectivity",
        name: "hub_connection",
        render: ({ row }) => (
          <Typography>
            {row?.hub
              ? row.hub?.connection
                ? capitalize(row.hub.connection)
                : EM_DASH
              : ""}
          </Typography>
        ),
        sortable: true,
        filterType: {
          type: "selectField",
          options: HubConnectionOptions,
        } as FilterPresetSelectField<any>,
      },
      {
        header: "Active Self Guided Tours",
        name: "can_be_toured",
        hidden: true,
        render: () => null,
        filterType: {
          type: "selectField",
          options: YesNoOptions,
        } as FilterPresetSelectField<any>,
      },
      {
        header: "Unit Ids",
        name: "ids",
        hidden: true,
        render: () => null,
        filterType: { type: "textInputField" } as FilterPresetTextInputField,
        sortable: false,
      },
    ];

    if (groupCommunityWifi?.enabled) {
      tableColumns.push({
        header: "Connected To WiFi Device Network?",
        name: "connected_to_community_wifi",
        render: ({ row }) => (
          <Typography>
            {row?.hub
              ? row.hub.connected_to_community_wifi
                ? "Yes"
                : "No"
              : ""}
          </Typography>
        ),
        sortable: false,
      });
    }
    return tableColumns;
  }, [groupCommunityWifi?.enabled]);

  const getQueryKey: GetQueryKeyFn = useCallback(
    ({
      filters,
      page,
      pageSize = 25,
      sortColumn = "marketing_name",
      sortDirection = "asc",
    }) => [
      "units",
      {
        include_hub: true, // adds hub obj to response
        ...(group?.id && { group_id: group.id }),
        ...(searchValue && { marketing_name: searchValue }), // maps url '?name' to be '?marketing_name' for api call
        ...filters,
        limit: pageSize,
        sort: sortColumn,
        dir: sortDirection,
        page,
      },
    ],
    [group, searchValue]
  );

  const keyExtractor = useCallback((row: UnitProps) => `${row.id}`, []);
  const getRowHref = useCallback((unit: UnitProps) => `/units/${unit.id}`, []);

  const tableProps = useTableQuery({
    fetch: useGroupUnitsTableQuery,
    columns,
    getQueryKey,
    keyExtractor,
  });

  return <Table getRowHref={getRowHref} {...tableProps} />;
};
