import { StyleSheet, View } from "react-native";

import { useTheme, AnimatedPlaceholder } from "@smartrent/ui";

export default function JobHeaderLoader() {
  const { colors } = useTheme();

  return (
    <View>
      <View style={styles.navWrapper}>
        <View style={styles.row}>
          <AnimatedPlaceholder
            style={{ width: 24, height: 24, backgroundColor: colors.gray200 }}
          />
          <AnimatedPlaceholder
            style={{
              width: 150,
              marginLeft: 8,
              marginTop: 3,
              height: 18,
              backgroundColor: colors.gray200,
            }}
          />
        </View>
      </View>
      <View style={[styles.row, { paddingTop: 16 }]}>
        <View>
          <View style={styles.row}>
            <View>
              <AnimatedPlaceholder
                style={{
                  width: 160,
                  height: 24,
                  backgroundColor: colors.gray200,
                }}
              />
            </View>
            <View style={styles.statusWrapper}>
              <View style={styles.status}>
                <AnimatedPlaceholder
                  style={{
                    width: 120,
                    height: 30,
                    backgroundColor: colors.gray200,
                  }}
                />
              </View>
            </View>
          </View>
          <View style={styles.completionLabel}>
            <View>
              <AnimatedPlaceholder
                style={{
                  width: 180,
                  height: 18,
                  backgroundColor: colors.gray200,
                }}
              />
            </View>
          </View>
          <AnimatedPlaceholder
            style={{
              width: "100%",
              height: 24,
              backgroundColor: colors.gray200,
            }}
          />
        </View>
        <View style={styles.headerRightWrapper}>
          <View style={styles.buttonWrapper}>
            <AnimatedPlaceholder
              style={{
                width: 267,
                height: 40,
                backgroundColor: colors.gray200,
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  navWrapper: { flexDirection: "row" },
  row: {
    flexDirection: "row",
  },
  completionLabel: { paddingVertical: 8 },
  headerRightWrapper: { flex: 1, marginLeft: 32 },
  buttonWrapper: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  statusWrapper: {
    marginLeft: 16,
  },
  status: {
    paddingHorizontal: 8,
  },
});
