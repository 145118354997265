import * as React from "react";

import {
  Button,
  ConfirmDialog,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  ListItemLabelValue,
  VStack,
} from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";

import { ManagedWifiAccessPoints } from "@/api";
import { useRebootManagedWifiAccessPointMutation } from "@/api/group";

import { EM_DASH } from "@/utils/chars";
import { useDrawerNav } from "@/common/AppDrawer";
import { formatDate } from "@/utils";
import { useGroup } from "@/pages/group/GroupContext";

export const ManageWifiAccessPoints: React.FC<
  React.PropsWithChildren<ManagedWifiAccessPoints>
> = (accessPoint: ManagedWifiAccessPoints) => {
  const [rebootAccessPoint] = useRebootManagedWifiAccessPointMutation();
  const { visible, onOpen, onClose } = useModalState();
  const { group } = useGroup();
  const drawer = useDrawerNav();

  return (
    <>
      <DrawerHeader title="Access Point Details" />
      <DrawerContent>
        <VStack spacing={8}>
          <ListItemLabelValue label="Name" value={accessPoint.name} />
          <ListItemLabelValue label="ID" value={accessPoint.id} />
          <ListItemLabelValue
            label="Serial"
            value={accessPoint.serial_number || EM_DASH}
          />
          <ListItemLabelValue
            label="MAC Address"
            value={accessPoint.mac || EM_DASH}
          />
          <ListItemLabelValue
            label="Model"
            value={accessPoint.model || EM_DASH}
          />
          <ListItemLabelValue
            label="Created At"
            value={formatDate({ date: accessPoint.created_at }) || EM_DASH}
          />
          <ListItemLabelValue
            label="Updated At"
            value={formatDate({ date: accessPoint.updated_at }) || EM_DASH}
          />
          <ListItemLabelValue
            label="Online"
            value={accessPoint.online ? "Yes" : "No"}
          />
          <ListItemLabelValue
            label="Last Seen At"
            value={accessPoint.last_seen_at || EM_DASH}
          />
        </VStack>
      </DrawerContent>
      <DrawerActions>
        <Button onPress={onOpen}>Reboot Access Point</Button>
      </DrawerActions>
      <ConfirmDialog
        title="Reboot Access Point"
        description="Are you sure you want to reboot this access point?"
        confirmText="Reboot"
        confirmType="primary"
        visible={visible}
        onConfirm={async () => {
          onClose();
          await rebootAccessPoint({
            group_id: group.id,
            access_point_id: accessPoint.id,
          });
          drawer.pop();
        }}
        onClose={onClose}
      />
    </>
  );
};
