import { useState } from "react";
import { View, StyleSheet } from "react-native";

import { Typography, Button } from "@smartrent/ui";

import { AccessControlDevice } from "@/types";
import helpers from "@/lib/helpers";

import { useRingAlarmLogQuery } from "@/api";

import { formatRingLog } from "@/utils";

export const RingAlarmLogs = ({ device }: { device: AccessControlDevice }) => {
  const [showMore, setShowMore] = useState(false);

  const { data } = useRingAlarmLogQuery(
    { deviceId: device.id },
    {
      enabled:
        device.provider_name === "Ring" && device.device_type === "alarms",
    }
  );

  return (
    <>
      <Typography color="textSecondary" style={styles.recentLogs}>
        Recent Logs (Up to 30 days):
      </Typography>
      {data?.length ? (
        <>
          <View style={showMore ? styles.fetchedContainer : styles.container}>
            {data?.map((log) => {
              return (
                <View key={log.id} style={styles.rowItem}>
                  <Typography color="textSecondary">
                    {formatRingLog({ action: log.action, status: log.status })}
                  </Typography>
                  <Typography type="bodySemibold">
                    {helpers.formatDate(log.inserted_at)}
                  </Typography>
                </View>
              );
            })}
          </View>
          {!showMore && data.length > 5 ? (
            <Button
              variation="plain"
              onPress={() => {
                setShowMore(true);
              }}
            >
              Fetch More
            </Button>
          ) : null}
        </>
      ) : (
        <Typography type="bodySemibold">
          No activity found for the last 30 days
        </Typography>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  recentLogs: { marginBottom: 8 },
  container: { marginLeft: 8, maxHeight: 245, overflowY: "hidden" },
  fetchedContainer: { marginLeft: 8, maxHeight: 285, overflowY: "scroll" },
  rowItem: { marginBottom: 0 },
});
