import * as React from "react";
import { StyleSheet } from "react-native";

import { StatusBadge, StatusBadgeProps } from "@smartrent/ui";

import { ParkingSensor } from "@/types";

export interface SensorStatusPillProps {
  parkingSensor: ParkingSensor;
}

const SensorStatusText = (parkingSensor: ParkingSensor) => {
  if (!parkingSensor.active_space) {
    return "Not Installed";
  }

  return parkingSensor.is_online ? "Online" : "Offline";
};

const SensorStatus = (
  parkingSensor: ParkingSensor
): StatusBadgeProps["status"] => {
  if (!parkingSensor.active_space) {
    return "knowledge";
  }

  return parkingSensor.is_online ? "success" : "error";
};

export const SensorStatusPill: React.FC<
  React.PropsWithChildren<SensorStatusPillProps>
> = ({ parkingSensor }) => {
  const text = SensorStatusText(parkingSensor);
  const status = SensorStatus(parkingSensor);

  return (
    <StatusBadge status={status} style={styles.root} size="semibold.title.four">
      {text}
    </StatusBadge>
  );
};

const styles = StyleSheet.create({ root: { width: 128 } });
