import * as React from "react";
import { View, StyleSheet } from "react-native";

type Props = {
  children: React.ReactNode;
  styles?: Object;
};

export default function CardHeading({ children, styles }: Props) {
  return (
    <View style={{ ...originalStyles.heading, ...(styles || {}) }}>
      <div>{children}</div>
    </View>
  );
}

const originalStyles = StyleSheet.create({
  heading: {
    fontSize: 24,
    fontWeight: "600",
    zIndex: 1,
  },
});
