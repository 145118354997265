import "react-dates/initialize";

// Date picker range library
import { DateRangePicker } from "react-dates";

// Typescript validation
import moment from "moment";

/**
 * Documentation: https://airbnb.io/react-dates/?path=/story/daterangepicker-drp--default
 */

export type DateObjectType = moment.Moment;

export interface OnCloseType {
  startDate: DateObjectType;
  endDate: DateObjectType;
}

export default (props) => <DateRangePicker {...props} />;
