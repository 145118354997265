import { useCallback, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Stack, Checkbox, Text } from "@smartrent/ui";

import {
  VendorApplication,
  VendorPermissionAction,
  VendorPermissionResource,
} from "@/types";
import { Table, ColumnOptions } from "@/common/sr-table";
import {
  useAvailableVendorPermissionsQuery,
  useGrantVendorPermissionMutation,
  useRevokeVendorPermissionMutation,
} from "@/api";
import {
  formatVendorPermissionAction,
  formatVendorPermissionResource,
} from "@/lib/helpers";

interface VendorPermissionTableAction {
  id: number;
  name: VendorPermissionAction;
  description: string;
  enabled: boolean;
}

interface VendorPermissionTableRow {
  resource: VendorPermissionResource;
  actions: VendorPermissionTableAction[];
}

interface VendorApplicationGroupsPermissionsProps {
  vendorApplication: VendorApplication;
}

export const VendorApplicationPermissionsTable: React.FC<
  React.PropsWithChildren<VendorApplicationGroupsPermissionsProps>
> = ({ vendorApplication }) => {
  const { data: availablePermissions, isLoading } =
    useAvailableVendorPermissionsQuery();
  const [grantVendorPermission] = useGrantVendorPermissionMutation();
  const [revokeVendorPermission] = useRevokeVendorPermissionMutation();

  const keyExtractor = useCallback(
    (row: VendorPermissionTableRow) => `${row.resource}`,
    []
  );

  const handlePermissionChange = useCallback(
    (permissionId: any, granted: any) => {
      if (granted) {
        grantVendorPermission({
          organizationId: vendorApplication.organization_id,
          vendorApplicationId: vendorApplication.id,
          vendorPermissionId: permissionId,
        });
      } else {
        revokeVendorPermission({
          organizationId: vendorApplication.organization_id,
          vendorApplicationId: vendorApplication.id,
          vendorPermissionId: permissionId,
        });
      }
    },
    []
  );

  // group permissions by resource for display in the table
  const groupedPermissions = useMemo(() => {
    const grouped =
      availablePermissions?.reduce((result, permission) => {
        if (!result[permission.resource]) {
          result[permission.resource] = [];
        }

        result[permission.resource].push({
          id: permission.id,
          name: permission.action,
          description: permission.description,
          enabled: vendorApplication.permissions?.find(
            (p) => p.id == permission.id
          ),
        });

        return result;
      }, {}) ?? {};

    return Object.keys(grouped).map((key) => ({
      resource: key,
      actions: grouped[key],
    }));
  }, [vendorApplication, availablePermissions]);

  const columns = useMemo<ColumnOptions<VendorPermissionTableRow>[]>(
    () => [
      {
        name: "resource",
        header: "Resource",
        sortable: true,
        render: ({ row }) => (
          <View style={styles.resourceColumn}>
            <Text>{formatVendorPermissionResource(row.resource)}</Text>
          </View>
        ),
      },
      {
        name: "actions",
        header: "Actions",
        render: ({ row }) => (
          <Stack direction="column">
            {row.actions.map((action) => (
              <View key={action.id}>
                <Checkbox
                  name={row.resource + action.name}
                  label={formatVendorPermissionAction(action.name)}
                  checked={Boolean(action.enabled)}
                  onChangeChecked={(checked) => {
                    handlePermissionChange(action.id, checked);
                  }}
                />
              </View>
            ))}
          </Stack>
        ),
      },
    ],
    []
  );

  return (
    <Table
      title="Permissions"
      loading={isLoading}
      data={groupedPermissions}
      noRecordsText="No Permissions Available"
      columns={columns}
      keyExtractor={keyExtractor}
      onRowPress={null}
    />
  );
};

const styles = StyleSheet.create({
  resourceColumn: {
    alignSelf: "flex-start",
  },
});
