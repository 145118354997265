import Select, { Props, Theme, OptionTypeBase } from "react-select";
import { ThemeColors, useTheme } from "@smartrent/ui";

function ReactSelect<TOption = OptionTypeBase>({
  error,
  ...props
}: Props<TOption>) {
  const { colors } = useTheme();

  return (
    <div style={{ position: "relative", width: "100%", zIndex: 9999 }}>
      <Select
        {...props}
        styles={{
          control: (provided) =>
            error
              ? {
                  ...provided,
                  borderColor: colors.error,
                }
              : provided,
        }}
        theme={(theme) => getTheme(theme, colors)}
        menuContainerStyle={{ zIndex: 999 }}
      />
    </div>
  );
}

const getTheme = (theme: Theme, colors: ThemeColors): Theme => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.primary,
      // @todo used to be primaryLight
      primary75: colors.primary,
      primary50: colors.gray100,
      primary25: colors.gray050,
    },
  };
};

export default ReactSelect;
