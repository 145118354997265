import { useState, useMemo } from "react";
import { compact, flatMap } from "lodash-es";
import { renderToString } from "react-dom/server";
import { Link } from "react-router-dom";
import { View, StyleSheet } from "react-native";

import { HStack, MultiSelectField, Typography, VStack } from "@smartrent/ui";
import { HighRiseBuilding } from "@smartrent/icons";

import { integrations as Integrations, modules as Modules } from "@/utils";
import { SRMap, SRMapMarker } from "@/common/map";

import Box from "@/common/Box";

import { OrganizationMultiSelect } from "@/common/filters/OrganizationMultiSelect";
import { GroupMultiSelect } from "@/common/filters/GroupMultiSelect";

import { useCoverageMap } from "@/api/coverage-map";

export function CoverageMap() {
  const [organizationIds, setOrganizationIds] = useState([]);
  const [groupIds, setGroupIds] = useState([]);
  const [modules, setModules] = useState([]);
  const [integrations, setIntegrations] = useState([]);

  const response = useCoverageMap({
    organization_ids: organizationIds,
    group_ids: groupIds,
    modules: modules.map((m) => m.value),
    integrations: integrations.map((i) => i.value),
  });

  const { loading, points, mapboxApiKey } = useMemo(() => {
    return {
      loading: !response?.isFetched,
      points: response?.data?.points || [],
      mapboxApiKey: response?.data?.mapboxApiKey,
    };
  }, [response]);

  const highRiseIconHTML = useMemo(() => {
    return renderToString(
      <View style={styles.divIcon}>
        <HighRiseBuilding size={24} />
      </View>
    );
  }, []);

  const markersDOM = useMemo(() => {
    return compact(
      flatMap(
        points,
        ({ lat, lng, count, label, group_id, organization_id, image_url }) => {
          return (
            <SRMapMarker
              position={{ lat, lng }}
              key={`${group_id || organization_id}`}
              popupOptions={{
                minWidth: 300,
                maxWidth: 460,
                autoPan: true,
                maxHeight: 400,
              }}
              divIconProps={{
                iconSize: [30, 30],
                // This just prevents a default bordered box from showing up in the background, the class doesn't
                // actually exist
                className: "sr-map-icon",
                html: highRiseIconHTML,
              }}
            >
              <Link
                to={
                  group_id
                    ? `/groups/${group_id}`
                    : `/organizations/${organization_id}`
                }
                className="u-link-clean h3"
                target="_blank"
              >
                <VStack spacing={12}>
                  <img src={image_url} />

                  <Typography type="bodySemibold">{label}</Typography>
                </VStack>
              </Link>
              <Typography type="body">
                {count} unit{count !== 1 ? "s" : ""}
              </Typography>
            </SRMapMarker>
          );
        }
      )
    );
  }, [points, highRiseIconHTML]);

  return (
    <Box style={styles.box}>
      <View style={styles.container}>
        <Typography type="title4">SmartRent Client Map</Typography>
        <HStack spacing={12}>
          <OrganizationMultiSelect
            onChange={(records) => setOrganizationIds(records.map((r) => r.id))}
          />
          <GroupMultiSelect
            onChange={(records) => setGroupIds(records.map((r) => r.id))}
          />
          <MultiSelectField
            name="modules"
            label="Modules"
            value={modules}
            onChange={setModules}
            options={Modules}
          />
          <MultiSelectField
            name="integrations"
            label="Integrations"
            value={integrations}
            onChange={setIntegrations}
            options={Integrations}
          />
        </HStack>
        {/* Future, ability to apply layers */}
        {/* <View>
            <SelectField
              style={styles.flexShrink}
              name="layer"
              label="Layer"
              value=""
              onChange={({ value }) => {
                console.log(value);
              }}
              options={[{ label: "Hubs Offline", value: "hubs_offline" }]}
            />
          </View> */}

        <View style={styles.mapContainer}>
          <SRMap
            mapboxApiKey={mapboxApiKey}
            loading={loading}
            markersDOM={markersDOM}
          />
        </View>
      </View>
    </Box>
  );
}

const styles = StyleSheet.create({
  box: {
    margin: 8,
  },
  container: {
    padding: 16,
  },
  mapContainer: {
    height: "60vh",
  },
  divIcon: {
    borderRadius: 15,
    backgroundColor: "#00ACA0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 4,
  },
});
