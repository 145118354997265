import { useMemo } from "react";
import { reduce, find, filter } from "lodash-es";
import moment from "moment";

import { Typography, VStack } from "@smartrent/ui";

import { useJobsQuery } from "@/api/jobs";
import { useUser } from "@/layout/Context";
import Alert from "@/common/Alert";

import { formatDateToBeHumanReadable, formatDate } from "@/utils";

import { useUnit } from "./UnitContext";

export default function Headlines() {
  const { permissions } = useUser();

  const {
    devices,
    accessCodes,
    unit,
    hub,
    residents,
    workflows,
    accessControlDevices,
    communityWifi,
  } = useUnit();

  const { data: unitInstallJobs } = useJobsQuery({
    unit_remote_id: unit?.id,
    contains_date: new Date().toISOString().split("T")[0],
  });

  const primaryLock = find(devices, {
    type: "entry_control",
    primary_lock: true,
  });

  const nonPrimaryLocks = filter(devices, {
    type: "entry_control",
    primary_lock: false,
  });

  const batteryPoweredTstatCount = filter(devices, {
    type: "thermostat",
    battery_powered: true,
  }).length;

  const hasActiveInstallJobs = useMemo(
    () => Boolean(unitInstallJobs?.pages[0]?.records?.length),
    [unitInstallJobs]
  );

  const headlines = [];
  const invalidDeviceCounts = reduce(
    devices,
    (memo, d) => {
      return d.valid_config && !d.access_codes_broken ? memo : memo + 1;
    },
    0
  );

  const stuckWorkflows =
    workflows && workflows.length
      ? workflows.filter(({ status }) => status !== "completed")
      : [];
  if (stuckWorkflows.length) {
    headlines.push({
      type: "warning",
      headline: (
        <Typography color="textPrimaryInverse">
          {stuckWorkflows.length} stuck move ins | outs
        </Typography>
      ),
    });
  }

  if (primaryLock && primaryLock.access_codes_broken) {
    headlines.push({
      type: "warning",
      headline: (
        <Typography color="textPrimaryInverse">
          Primary lock needs to be re-paired **
        </Typography>
      ),
    });
  }

  if (primaryLock) {
    // This Fn may be oversimplified, not accounting for replacements
    const unappliedCodeCount = reduce(
      accessCodes[primaryLock.id],
      (memo, ac) => {
        return ac.added_at === null &&
          moment(ac.add_at).isBefore(moment()) &&
          (ac.remove_at === null || moment(ac.remove_at).isAfter(moment())) &&
          ac.reserved_slot_id
          ? memo + 1
          : memo;
      },
      0
    );

    if (unappliedCodeCount > 0) {
      headlines.push({
        type: "warning",
        headline: (
          <Typography color="textPrimaryInverse">
            {unappliedCodeCount} access code
            {unappliedCodeCount > 1 ? "s" : ""} that haven't been yet applied to
            lock(s)
          </Typography>
        ),
      });
    }

    const residentAccessCodeCount = filter(
      accessCodes[primaryLock.id],
      (ac) => {
        return !!ac.resident_access_code_id && !ac.removed_at;
      }
    ).length;

    const movedInResidents = filter(residents, (r) => !!r.moved_in);
    if (
      permissions.resident_details &&
      unit.is_occupied &&
      !movedInResidents.length
    ) {
      headlines.push({
        type: "warning",
        headline: (
          <Typography color="textPrimaryInverse">
            Occupied unit with no moved in residents
          </Typography>
        ),
      });
    }

    if (
      residentAccessCodeCount !== movedInResidents.length &&
      movedInResidents.length - residentAccessCodeCount > 0
    ) {
      headlines.push({
        type: "warning",
        headline: (
          <Typography color="textPrimaryInverse">
            Missing {movedInResidents.length - residentAccessCodeCount} resident
            access codes
          </Typography>
        ),
      });
    }
  } else if (accessControlDevices.length === 0) {
    headlines.push({
      type: "warning",
      headline: (
        <Typography color="textPrimaryInverse">No primary lock</Typography>
      ),
    });
  }

  nonPrimaryLocks.forEach((nonPrimaryLock) => {
    const unappliedCodeCount = reduce(
      accessCodes[nonPrimaryLock.id],
      (memo, ac) => {
        return ac.added_at === null &&
          moment(ac.add_at).isBefore(moment()) &&
          (ac.remove_at === null || moment(ac.remove_at).isAfter(moment())) &&
          ac.reserved_slot_id
          ? memo + 1
          : memo;
      },
      0
    );

    if (unappliedCodeCount > 0) {
      headlines.push({
        type: "warning",
        headline: (
          <Typography color="textPrimaryInverse">
            {unappliedCodeCount} access code
            {unappliedCodeCount > 1 ? "s" : ""} that haven't been yet applied to
            non-primary lock.
          </Typography>
        ),
      });
    }

    const residentAccessCodeCount = filter(
      accessCodes[nonPrimaryLock.id],
      (ac) => {
        return !!ac.resident_access_code_id;
      }
    ).length;
    const movedInResidents = filter(residents, (r) => !!r.moved_in);

    if (
      residentAccessCodeCount !== movedInResidents.length &&
      movedInResidents.length - residentAccessCodeCount > 0
    ) {
      headlines.push({
        type: "warning",
        headline: (
          <Typography color="textPrimaryInverse">
            Missing {movedInResidents.length - residentAccessCodeCount} resident
            access codes on non-primary lock
          </Typography>
        ),
      });
    }
  });

  if (batteryPoweredTstatCount) {
    headlines.push({
      type: "warning",
      headline: (
        <Typography color="textPrimaryInverse">
          {batteryPoweredTstatCount} battery powered tstat(s)
        </Typography>
      ),
    });
  }

  if (invalidDeviceCounts) {
    headlines.push({
      type: "warning",
      headline: (
        <Typography color="textPrimaryInverse">
          {invalidDeviceCounts} invalid devices
        </Typography>
      ),
    });
  }

  if (hasActiveInstallJobs) {
    headlines.push({
      type: "info",
      headline: (
        <Typography color="textPrimaryInverse">
          Installations are currently in progress at this group.
        </Typography>
      ),
    });
  }

  if (
    hub &&
    communityWifi.isEnabled &&
    communityWifi?.data &&
    !communityWifi.isHubConnected
  ) {
    let problem = "";

    if (!communityWifi.data.online) {
      problem = "the hub is offline";
    } else if (communityWifi.data.connection !== "wifi") {
      problem = `the hub is connected via ${JSON.stringify(
        communityWifi.data.connection
      )}`;
    } else if (communityWifi.data.connection === "wifi") {
      problem = `the hub's WiFi SSID does not match the IoT Network SSID`;
    }

    headlines.push({
      type: "warning",
      headline: (
        <VStack spacing={8} align="center">
          <Typography color="textPrimaryInverse">
            Device WiFi Network enabled, but {problem}.
          </Typography>
        </VStack>
      ),
    });
  }

  if (!headlines.length) {
    return null;
  }

  return (
    <div className="u-mbottom16">
      {unit.deleted_at && (
        <Alert type="error" className="u-mbottom4">
          Unit Deleted -{" "}
          {formatDateToBeHumanReadable({ date: unit.deleted_at })} (
          {formatDate({ date: unit.deleted_at })})
        </Alert>
      )}
      {headlines.map(({ headline, type }, idx) => (
        <Alert
          type={type}
          key={idx}
          className="u-p8 u-flex-col"
          style={
            idx + 1 === headlines.length
              ? {}
              : { borderBottom: "2px solid #fff" }
          }
        >
          {headline}
        </Alert>
      ))}
    </div>
  );
}
