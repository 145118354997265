import { View, StyleSheet, FlatList } from "react-native";
import { ActivityIndicator, Typography, useTheme } from "@smartrent/ui";

import { useGetUserLoginActivity } from "@/api/users";

import Card from "@/common/Card";

import { LoginActivityLineItem } from "./LoginActivityLineItem";

export const LoginActivity = ({ userId }: { userId: string | number }) => {
  const { colors } = useTheme();

  const { isLoading, hasNextPage, fetchNextPage, reducedData } =
    useGetUserLoginActivity(userId, {
      enabled: !!userId,
    });

  if (isLoading) {
    return (
      <View style={styles.loadingWrapper}>
        <ActivityIndicator />
      </View>
    );
  }

  return (
    <Card heading={<Typography type="title4">Login Activity</Typography>}>
      <View style={styles.listContainer}>
        <FlatList
          data={reducedData}
          keyExtractor={({ id, verb }) => `${id}-${verb}`}
          ItemSeparatorComponent={() => {
            return (
              <View
                style={[
                  {
                    backgroundColor: colors.border,
                  },
                  styles.separator,
                ]}
              />
            );
          }}
          renderItem={(loginActivity) => {
            return (
              <LoginActivityLineItem loginActivityEvent={loginActivity.item} />
            );
          }}
          onEndReachedThreshold={0.25}
          onEndReached={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
          ListEmptyComponent={() =>
            isLoading ? (
              <View style={styles.emptyMessages}>
                <ActivityIndicator />
              </View>
            ) : (
              <View style={styles.emptyMessages}>
                <Typography style={styles.emptyMessageText}>
                  No Login Activity Found
                </Typography>
              </View>
            )
          }
        />
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  loadingWrapper: {
    flexDirection: "row",
    justifyContent: "center",
  },
  listContainer: {
    maxHeight: "600px",
  },
  emptyMessageText: {
    marginTop: 16,
    color: "#656B6F",
  },
  emptyMessages: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 16,
  },
  separator: { height: 1, width: "95%", marginLeft: "2.5%" },
});
