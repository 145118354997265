import { useCallback, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useMutationCompat, useQueryCompat } from "@smartrent/hooks";

import { AxiosMutationConfig } from "@/lib/react-query-helpers";
import { instance } from "@/lib/hooks";
import { getErrorMessage } from "@/lib/axios-helpers";
import Context from "@/layout/Context";
import { Sign } from "@/types";
import { useInvalidateSignsQuery, useInvalidateSpacesQuery } from "@/api";

interface CreateSignOptions {
  propertyId: string;
  values: {
    location: string;
    space_ids?: string[];
  };
}

interface UpdateSignOptions {
  propertyId: string;
  signId: string;
  values: Partial<Sign>;
}

interface DeleteSignOptions {
  propertyId: string;
  signId: string;
}

interface UnassignSignSpaceOptions {
  propertyId: string;
  signId: string;
  spaceId: string;
}

interface EnableSignOptions {
  propertyId: string;
  signId: string;
}

export const useSignQuery = (signId: string, propertyId: string) =>
  useQueryCompat<string[], Sign>(
    ["sign", propertyId, signId],
    async (key: "sign", propertyId, signId) => {
      const { data } = await instance.get<Sign>(
        `/parking/properties/${propertyId}/signs/${signId}`
      );

      return data;
    },
    { enabled: Boolean(signId && propertyId) }
  );

export const useInvalidateSignQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (propertyId: string, signId?: string) =>
      signId
        ? queryClient.invalidateQueries(["sign", propertyId, signId])
        : queryClient.invalidateQueries(["sign", propertyId]),
    [queryClient]
  );
};

export const useCreateSignMutation = (
  options?: AxiosMutationConfig<CreateSignOptions>
) => {
  const context = useContext(Context);
  const invalidateSigns = useInvalidateSignsQuery();

  return useMutationCompat(
    async ({ propertyId, values }: CreateSignOptions) => {
      try {
        const { data } = await instance.post(
          `/parking/properties/${propertyId}/signs`,
          values
        );

        context.setToast({
          type: "success",
          title: "Success",
          message: "Parking sign created successfully.",
        });

        invalidateSigns(propertyId);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: "Error creating parking sign.",
        });

        throw err;
      }
    },
    options
  );
};

export const useUpdateSignMutation = (
  options?: AxiosMutationConfig<UpdateSignOptions>
) => {
  const context = useContext(Context);
  const invalidateSign = useInvalidateSignQuery();
  const invalidateSigns = useInvalidateSignsQuery();
  const invalidateSpaces = useInvalidateSpacesQuery();

  return useMutationCompat(
    async ({ propertyId, signId, values }: UpdateSignOptions) => {
      try {
        const { data } = await instance.patch(
          `/parking/properties/${propertyId}/signs/${signId}`,
          values
        );

        context.setToast({
          type: "success",
          title: "Success",
          message: "Parking sign updated successfully.",
        });

        invalidateSign(propertyId, signId);
        invalidateSpaces(propertyId);
        invalidateSigns(propertyId);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: "Error updating parking sign.",
        });

        throw err;
      }
    },
    options
  );
};

export const useDeleteSignMutation = (
  options?: AxiosMutationConfig<DeleteSignOptions>
) => {
  const context = useContext(Context);
  const invalidateSigns = useInvalidateSignsQuery();
  const invalidateSpaces = useInvalidateSpacesQuery();
  const invalidateSign = useInvalidateSignQuery();

  return useMutationCompat(
    async ({ propertyId, signId }: DeleteSignOptions) => {
      try {
        const { data } = await instance.delete(
          `/parking/properties/${propertyId}/signs/${signId}`
        );

        context.setToast({
          type: "success",
          title: "Success",
          message: "Parking sign archived successfully.",
        });

        invalidateSigns(propertyId);
        invalidateSpaces(propertyId);
        invalidateSign(propertyId, signId);

        return data;
      } catch (err: any) {
        context.setToast({
          type: "error",
          title: "Error",
          message: `Error archiving parking sign. ${getErrorMessage(err)}`,
        });

        throw err;
      }
    },
    options
  );
};

export const useUnassignSignSpaceMutation = (
  options?: AxiosMutationConfig<UnassignSignSpaceOptions>
) => {
  const context = useContext(Context);
  const invalidateSpaces = useInvalidateSpacesQuery();

  return useMutationCompat(
    async ({ propertyId, signId, spaceId }: UnassignSignSpaceOptions) => {
      try {
        const { data } = await instance.delete(
          `/parking/properties/${propertyId}/signs/${signId}/spaces/${spaceId}`
        );

        context.setToast({
          type: "success",
          title: "Success",
          message: "Space unassigned from parking sign successfully.",
        });

        invalidateSpaces(propertyId);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: "Error unassigning space from parking sign.",
        });

        throw err;
      }
    },
    options
  );
};

export const useEnableSignMutation = (
  options?: AxiosMutationConfig<EnableSignOptions>
) => {
  const context = useContext(Context);
  const invalidateSigns = useInvalidateSignsQuery();
  const invalidateSign = useInvalidateSignQuery();

  return useMutationCompat(
    async ({ propertyId, signId }: EnableSignOptions) => {
      try {
        const { data } = await instance.post(
          `/parking/properties/${propertyId}/signs/${signId}`
        );

        context.setToast({
          type: "success",
          title: "Success",
          message: "Enabled parking sign successfully.",
        });

        invalidateSigns(propertyId);
        invalidateSign(propertyId, signId);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: "Error enabling parking sign.",
        });

        throw err;
      }
    },
    options
  );
};
