import { DeviceProps } from "@/types";

export const lockInstalledWithoutAccess = (device: DeviceProps) =>
  device.type === "entry_control" &&
  device.installation_status === "complete" &&
  !device.sync_access_codes;

export const installInProgress = (device: DeviceProps) =>
  device.configuration || device.installation_status === "in_progress";

export const getInstaller = (device: DeviceProps) => {
  if (device.installer) {
    return `by ${device.installer}`;
  } else if (device.installed_by_first_name && device.installed_by_last_name) {
    return `by ${device.installed_by_first_name} ${device.installed_by_last_name}`;
  }

  return "";
};
