import { Fragment } from "react";
import { map, reduce, groupBy } from "lodash-es";
import moment from "moment";
import classNames from "classnames";

import { VAULT, FIRST_RESPONDER } from "@/utils/access-codes";

export default function AccessCodesOnLock({
  accessCodes,
  device,
  permissions,
}) {
  if (device.type !== "entry_control" && !(accessCodes && accessCodes.length)) {
    return null;
  }

  const groupedCodes = groupBy(accessCodes, "type");

  const details = map(groupedCodes, (codes, type) => {
    // Not every role should care about vault codes
    if (type === VAULT && !permissions.vault_codes) {
      return null;
    }

    if (type === FIRST_RESPONDER && !permissions.emergency_codes) {
      return null;
    }

    const codesOnLockThatShouldBeOnLock = reduce(
      codes,
      (memo, code) => {
        if (
          code.add_at &&
          code.added_at &&
          (!code.remove_at || moment(code.remove_at).isAfter(moment()))
        ) {
          return memo + 1;
        }
        return memo;
      },
      0
    );

    const codesNotOnLockThatShouldBeOnLock = reduce(
      codes,
      (memo, code) => {
        if (
          code.add_at &&
          moment(code.add_at).isBefore(moment()) &&
          !code.added_at &&
          (!code.remove_at || moment(code.remove_at).isAfter(moment()))
        ) {
          return memo + 1;
        }
        return memo;
      },
      0
    );

    const notRemovedCodesButShouldBe = reduce(
      codes,
      (memo, code) => {
        if (!code.removed_at && moment(code.remove_at).isBefore(moment())) {
          return memo + 1;
        }
        return memo;
      },
      0
    );

    const removedCodes = reduce(
      codes,
      (memo, code) => {
        if (code.removed_at || !code.reserved_slot_id) {
          return memo + 1;
        }
        return memo;
      },
      0
    );

    const shouldBeOnLockCount =
      codes.length - notRemovedCodesButShouldBe - removedCodes;

    return (
      <div key={type} className="u-font14 u-p8 u-line18">
        <div className="strong u-mbottom4">
          {shouldBeOnLockCount} {type} codes
        </div>
        <div
          className={classNames("u-font12", {
            "u-text-symbolic-success": !(
              notRemovedCodesButShouldBe && codesNotOnLockThatShouldBeOnLock
            ),
            "u-text-symbolic-error":
              notRemovedCodesButShouldBe || codesNotOnLockThatShouldBeOnLock,
            "u-text-gray-400":
              codesOnLockThatShouldBeOnLock + notRemovedCodesButShouldBe === 0,
          })}
        >
          {codesOnLockThatShouldBeOnLock + notRemovedCodesButShouldBe} /{" "}
          {shouldBeOnLockCount} on lock
        </div>
        {codesNotOnLockThatShouldBeOnLock ||
        removedCodes ||
        notRemovedCodesButShouldBe ? (
          <div
            className={classNames("u-font12", {
              "u-text-symbolic-success":
                codesNotOnLockThatShouldBeOnLock === 0 &&
                notRemovedCodesButShouldBe === 0 &&
                shouldBeOnLockCount !== 0,
              "u-text-symbolic-error":
                codesNotOnLockThatShouldBeOnLock !== 0 ||
                notRemovedCodesButShouldBe !== 0,
              "u-text-gray-400":
                codes.length === 0 || shouldBeOnLockCount === 0,
            })}
          >
            ({codesNotOnLockThatShouldBeOnLock} missing, {removedCodes} removed,{" "}
            {notRemovedCodesButShouldBe} can't remove)
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <Fragment>
      <div
        className="strong u-flex u-flex-align-items-baseline u-flex-justify-between u-font14"
        style={{
          backgroundColor: "#f7f7f7",
          padding: "4px",
          borderTop: "1px solid #eee",
          borderBottom: "1px solid #eee",
        }}
      >
        <div>Access Code Details</div>
      </div>
      {details}
    </Fragment>
  );
}
