import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import { redisStatsBatchQueryFn } from "@/api/zipato";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";
import { HubRefurbishReactQueryKeys, RedisStatsBatchResponse } from "@/types";

export const REDIS_STATS_BATCH_REFETCH_INTERVAL = 5000;

export const useApiRedisStatsBatchHook = (options = {}) => {
  const { activeSerials, hubsDict, setHubsDict } = useHubRefurbishContext();

  return useQuery<RedisStatsBatchResponse, AxiosError, RedisStatsBatchResponse>(
    [HubRefurbishReactQueryKeys.RedisStats, { serials: activeSerials }],
    () => redisStatsBatchQueryFn(activeSerials),
    {
      // update hub refurbish context whenever this is successful
      onSuccess: (data) => {
        const mergedRedisRecords = Object.entries(data?.records || {}).reduce(
          (hubsDictCopy, [serial, redisData]) =>
            // only merge if serial exists in the current context's dictionary
            hubsDictCopy[serial]
              ? {
                  ...hubsDictCopy,
                  [serial]: { ...hubsDictCopy[serial], ...redisData },
                }
              : hubsDictCopy,
          hubsDict
        );
        setHubsDict(mergedRedisRecords);
      },
      enabled: (activeSerials || []).length > 0,
      refetchInterval: REDIS_STATS_BATCH_REFETCH_INTERVAL,
      placeholderData: {
        statuses: {},
        records: {},
        stats: {
          total: 0,
          online: 0,
          offline: 0,
          error: 0,
          unknown: 0,
        },
        errors: [],
        unknowns: [],
      },
      ...options,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};
