import { useMemo, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { get } from "lodash-es";

import { Typography } from "@smartrent/ui";

import { useRedisStatsQuery } from "@/api/zipato";

import {
  ZipatoRedisStatsBoxProps,
  ZipatoRedisStatsBoxSessionProps,
} from "@/types";

import { BoxOrRedisValue } from "./BoxOrRedisValue";
import { HubDebugTitleBar } from "./HubDebugTitleBar";

interface HubRedisStatsProps {
  serial: string;
  onFetch?: ({
    box,
    boxSessions,
  }: {
    box: Partial<ZipatoRedisStatsBoxProps>;
    boxSessions: Partial<ZipatoRedisStatsBoxSessionProps>[];
  }) => void;
}

export default function HubRedisStats({ serial, onFetch }: HubRedisStatsProps) {
  const { data, isLoading, isFetching, isFetched, refetch } =
    useRedisStatsQuery({
      serial,
    });

  const box = useMemo(() => data?.box ?? {}, [data]);
  const boxSessions = useMemo(() => data?.boxSessions ?? [], [data]);

  useEffect(() => {
    if (isFetched && onFetch) {
      onFetch({ box, boxSessions });
    }
  }, [isFetched, onFetch, box, boxSessions]);

  if (!serial) {
    return null;
  }

  return (
    <View>
      <HubDebugTitleBar
        title="Box / Session"
        isLoading={isLoading || isFetching}
        onReloadClicked={refetch}
      />

      {box && Object.keys(box) ? (
        <View style={styles.infoContainer}>
          <Typography type="bodyLarge">Box</Typography>
          {Object.keys(box).map((key: string) => (
            <BoxOrRedisValue key={key} value={get(box, key)} label={key} />
          ))}
        </View>
      ) : null}

      {boxSessions && !!boxSessions.length ? (
        <View style={styles.infoContainer}>
          <Typography type="bodyLarge">Box Session</Typography>
          {boxSessions.map((boxSession) => (
            <View key={boxSession.sessionId}>
              {Object.keys(boxSession)
                ? Object.keys(boxSession).map((key: string) => (
                    <BoxOrRedisValue
                      key={key}
                      value={get(boxSession, key)}
                      label={key}
                    />
                  ))
                : null}
            </View>
          ))}
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  infoContainer: {
    paddingVertical: 8,
  },
});
