import { useState, useRef, useEffect } from "react";
import { View, Text } from "react-native-web";
import { Link } from "react-router-dom";
import { Map, TileLayer, Circle, Marker, Popup } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import L from "leaflet";

import { startCase } from "lodash-es";

import moment from "moment";

import largeBlueMarker from "@/assets/images/markers/marker-icon-2x-blue.png";
import largeOrangeMarker from "@/assets/images/markers/marker-icon-2x-orange.png";

import Helpers from "@/lib/helpers";

import { instance } from "@/lib/hooks";

import { TourApiProps, GroupTourConfigProps } from "../types";

import Button from "./Button";
import Card from "./Card";
import CardHeading from "./CardHeading";

export default function ProspectActiveToursMap({
  tour,
  groupTourConfig,
  mapboxApiKey,
}: {
  tour: TourApiProps;
  groupTourConfig: GroupTourConfigProps;
  mapboxApiKey: string;
}) {
  const mapRef = useRef();
  const [zoom, setZoom] = useState(12);
  const [shownCoordinates, setShownCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [displayedNamed, setDisplayedName] = useState(null);
  const [displayedAddress, setDisplayedAddress] = useState(null);

  // Use to hold state of whether or not geolocation is required
  const [geolocationRequired, setGeolocationRequired] = useState(false);
  const prospectLat = tour.prospect.lat;
  const prospectLng = tour.prospect.lng;

  const getCoordinates = () => {
    switch (tour.organization.industry) {
      case "multifamily":
        setShownCoordinates({
          lat: Number(tour.group.lat),
          lng: Number(tour.group.lng),
        });
        setDisplayedName(groupTourConfig.property_name);
        setDisplayedAddress(
          tour.group.street_address_1 +
            (tour.group.street_address_2 ? " " : "") +
            tour.group.street_address_2 +
            " " +
            tour.group.city +
            ", " +
            tour.group.state +
            " " +
            tour.group.zip_code
        );
        break;
      case "single_family":
      case "alloy_smarthome":
        setShownCoordinates({
          lat: Number(tour.units[0].lat),
          lng: Number(tour.units[0].lng),
        });
        setDisplayedName(tour.units[0].marketing_name);
        setDisplayedAddress(
          tour.units[0].street_address_1 +
            (tour.units[0].street_address_2 ? " " : "") +
            tour.units[0].street_address_2 +
            " " +
            tour.units[0].city +
            ", " +
            tour.units[0].state +
            " " +
            tour.units[0].zip
        );
        break;
      default:
        setShownCoordinates({
          lat: null,
          lng: null,
        });
        setDisplayedName(null);
        setDisplayedAddress(null);
        break;
    }
  };

  // On first load, set state of geolocation required
  // Also set the coordinates
  useEffect(() => {
    setGeolocationRequired(tour.geolocation_required);
    getCoordinates();
  }, []);

  function TourStatus({ tour }) {
    if (tour.deleted_at) {
      return (
        <div className="u-font12 u-text-symbolic-error">
          {startCase(tour.deleted_reason)}
        </div>
      );
    } else if (moment().isAfter(tour.scheduled_end)) {
      return <div className="u-font12 u-text-symbolic-success">Past</div>;
    } else if (
      moment().isAfter(tour.scheduled_start) &&
      moment().isBefore(tour.scheduled_end)
    ) {
      return <div className="u-font12 u-text-symbolic-success">Active</div>;
    }

    return <div className="u-font12 u-text-symbolic-knowledge">Future</div>;
  }

  function TourTime({ tour }) {
    return (
      <div className="u-font12 u-text-gray-600">
        {Helpers.formatDate(tour.scheduled_start)} to{" "}
        {Helpers.formatDate(tour.scheduled_end)}
      </div>
    );
  }

  return (
    <Card
      heading={
        <CardHeading>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ alignItems: "col" }}>
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "600",
                  zIndex: 1,
                }}
              >
                {displayedNamed}
              </Text>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "400",
                  zIndex: 1,
                }}
              >
                {displayedAddress}
              </Text>
              <TourTime tour={tour} />
              <TourStatus tour={tour} />
            </View>

            <View style={{ width: 180 }}>
              {tour.only_ble ? null : (
                <Button
                  className={
                    geolocationRequired
                      ? "button button--primary-error u-mbottom0"
                      : "button u-mbottom0"
                  }
                  type="button"
                  onClick={async () => {
                    const geolocationToggleRequest = await instance({
                      url: `/tours/${tour.token}/geolocation`,
                      method: "PATCH",
                      data: { geolocation: !geolocationRequired },
                    });
                    setGeolocationRequired(
                      geolocationToggleRequest?.data?.geolocation_required ??
                        geolocationRequired
                    );
                  }}
                >
                  {geolocationRequired
                    ? "Disable Geolocation"
                    : "Enable Geolocation"}
                </Button>
              )}
            </View>
          </View>
        </CardHeading>
      }
      customStyles={{ width: "100%" }}
    >
      {tour.units.map((unit) => {
        return (
          <div
            key={unit.id}
            className="u-flex u-flex-wrap u-flex-justify-between"
          >
            <Link to={`/units/${unit.id}`} className="a" target="_blank">
              {unit.marketing_name}
            </Link>
          </div>
        );
      })}
      {shownCoordinates.lat && shownCoordinates.lng ? (
        <Map
          style={{ height: 600 }}
          zoom={zoom}
          ref={mapRef}
          onZoom={(e) => {
            setZoom(e.target._zoom);
          }}
          center={[shownCoordinates.lat, shownCoordinates.lng]}
        >
          <TileLayer
            attribution='&amp;copy <a href="#">Mapbox</a>'
            url={`https://api.mapbox.com/styles/v1/jwdotjs/ck3j20hr01f0n1clmvdlhp9oa/tiles/256/{z}/{x}/{y}?access_token=${mapboxApiKey}`}
          />
          {/* radius is 3 miles converted to meters */}
          {geolocationRequired && (
            <Circle
              center={{
                lat: shownCoordinates.lat,
                lng: shownCoordinates.lng,
              }}
              radius={4828.03}
            ></Circle>
          )}
          <Marker
            position={{
              lat: shownCoordinates.lat,
              lng: shownCoordinates.lng,
            }}
            key="property"
            icon={L.icon({
              iconUrl: largeBlueMarker,
              iconSize: [16, 24],
            })}
          >
            <Popup>{displayedNamed}</Popup>
          </Marker>
          <Marker
            position={{ lat: prospectLat, lng: prospectLng }}
            key="prospect"
            icon={L.icon({
              iconUrl: largeOrangeMarker,
              iconSize: [16, 24],
            })}
          >
            <Popup>
              {tour.prospect.first_name + " " + tour.prospect.last_name}
            </Popup>
          </Marker>
        </Map>
      ) : null}
    </Card>
  );
}
