import { View, StyleSheet } from "react-native";

import Layout from "@/layout/Layout";
import { useIsDesktop } from "@/lib/hooks";

import HubsOnlineOffline from "@/common/HubsOnlineOffline";
import { useUser } from "@/layout/Context";

import TroubleshootHubOnline from "./dashboard/TroubleshootHubOnline";
import AccessCodeSync from "./dashboard/AccessCodeSync";
import DevicesInstalledByHour from "./dashboard/DevicesInstalledByHour";
import FirstUnitInstalls from "./dashboard/FirstUnitInstalls";
import DistinctDeletedHubs from "./dashboard/DistinctDeletedHubs";
// import ResidentUnitWorkflows from "./dashboard/ResidentUnitWorkflows"; // May not be relevant anymore
// import AttributeQueues from "./dashboard/AttributeQueues";
import AccessCodesLastDay from "./dashboard/AccessCodesLastDay";
import { CoverageMap } from "./dashboard/CoverageMap";
// import LocksLastDay from "./dashboard/LocksLastDay";
// import LeakSensorsLastDay from "./dashboard/LeakSensorsLastDay";
import AccountAudit from "./dashboard/AccountAudit";
import FailedLeasingSyncs from "./dashboard/FailedLeasingSyncs";
import { MetricCard } from "./dashboard/MetricCard";

export default function Dashboard() {
  const isDesktop = useIsDesktop();
  const { permissions } = useUser();

  return (
    <Layout>
      <View style={isDesktop ? styles.wrapDesktop : styles.wrapMobile}>
        <MetricCard
          title="IoT Devices"
          metricsToShow={[
            {
              path: "/reports/total-devices-installed",
              dataKey: "deviceCount",
              canBeRefreshed: false,
              tooltip: {
                title:
                  "Count of Z-Wave devices installed (not including hubs themselves)",
                name: "devicesInstalled",
              },
            },
          ]}
        />
        <MetricCard
          title="Property Staff"
          metricsToShow={[
            {
              path: "/reports/property-staff-users",
              dataKey: "propertyStaffUserCount",
              canBeRefreshed: false,
              tooltip: {
                title: "Count of property staff users",
                name: "propertyStaff",
              },
            },
          ]}
        />
        <MetricCard
          title="Unit App Adoption"
          tooltip={{
            title: "Minimun of 1 Resident Per Unit | Refreshed every 24 hours",
            name: "unitAppAdoption",
          }}
          metricsToShow={[
            {
              path: "/reports/app-adoption-metrics",
              dataKey: "Units with a moved-in resident",
              label: "Units with a Moved-In Resident",
              tooltip: {
                title:
                  "Total number of units with at least one resident moved in.",
                name: "unitWithResident",
              },
              canBeRefreshed: false,
            },
            {
              path: "/reports/app-adoption-metrics",
              dataKey: "Units with resident adoption",
              label: "Units with a Resident Adoption",
              tooltip: {
                title:
                  "Total Units that have at least one moved-in resident that has set a password.",
                name: "unitAppAdoptionMetric",
              },
              canBeRefreshed: false,
            },
          ]}
        />
        <MetricCard
          title="Resident App Adoption"
          tooltip={{
            title: "Count of all moved-in residents | Refreshed every 24 hours",
            name: "residentAppAdoption",
          }}
          metricsToShow={[
            {
              path: "/reports/app-adoption-metrics",
              dataKey: "Residents moved-in",
              label: "Residents Moved-In",
              tooltip: {
                title: "Total number of residents moved in.",
                name: "residentTotal",
              },
              canBeRefreshed: false,
            },
            {
              path: "/reports/app-adoption-metrics",
              dataKey: "Resident Adoption",
              label: "Resident Adoption",
              tooltip: {
                title:
                  "Total number of residents moved in that have set a password.",
                name: "residentAppAdoptionMetric",
              },
              canBeRefreshed: false,
            },
          ]}
        />
      </View>
      <AccessCodeSync />
      {permissions?.view_groups ? <CoverageMap /> : null}
      <HubsOnlineOffline />
      <AccountAudit />
      <DevicesInstalledByHour />
      <FirstUnitInstalls />
      <DistinctDeletedHubs />
      {/* <ResidentUnitWorkflows /> */}
      {/* <AttributeQueues /> */}
      <AccessCodesLastDay />
      {/* <LeakSensorsLastDay /> */}
      {/* <LocksLastDay /> */}
      <FailedLeasingSyncs />
      <TroubleshootHubOnline />
    </Layout>
  );
}

const styles = StyleSheet.create({
  wrapDesktop: { flexWrap: "wrap", flexDirection: "row" },
  wrapMobile: { flexWrap: "wrap", flexDirection: "column" },
});
