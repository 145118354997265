import * as React from "react";
import { View, StyleSheet } from "react-native";

type Props = {
  children: React.ReactNode;
  style?: Object;
};

export default function Box({ children, style }: Props) {
  return <View style={[styles.box, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  box: {
    boxShadow: "rgba(26, 31, 39, 0.2) 0px 4px 8px",
    backgroundColor: "#FFFFFF",
  },
});
