import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet, ViewStyle } from "react-native";

import { Typography } from "@smartrent/ui";

import { formatDate, formatDateToBeHumanReadable } from "@/utils/date";

export interface ListItemLabelValueCompactProps {
  label?: string;
  value: string | number;
  timestamp?: string;
  timestampOpts?: any;
  style?: ViewStyle;
  justifySpaceBetween?: boolean;
}

export const ListItemLabelValueCompact: React.FC<
  React.PropsWithChildren<ListItemLabelValueCompactProps>
> = ({
  label,
  value,
  timestamp,
  timestampOpts,
  style,
  justifySpaceBetween,
}) => {
  const justifyContent = useMemo(
    () => (justifySpaceBetween ? styles.justifySpaceBetween : null),
    [justifySpaceBetween]
  );

  return (
    <View style={style}>
      <View style={[styles.container, justifyContent]}>
        {label ? (
          <Typography type="title5" color="primary" style={styles.label}>
            {label}:
          </Typography>
        ) : null}
        <Typography>{value}</Typography>
      </View>
      {timestamp ? (
        <Typography type="caption">
          {formatDate({ date: timestamp, ...timestampOpts })} (
          {formatDateToBeHumanReadable({ date: timestamp, ...timestampOpts })})
        </Typography>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingVertical: 8,
    alignItems: "center",
  },
  label: { marginRight: 8 },
  justifySpaceBetween: { justifyContent: "space-between" },
});
