export enum ParkingQueryKeys {
  Assignables = "assignables",
  Areas = "areas",
  Decal = "decal",
  Decals = "decals",
  Guest = "guest",
  GuestSessions = "guest-sessions",
  ParkingSessions = "parking-sessions",
  Reservations = "reservations",
  Section = "section",
  Sections = "sections",
  Sensor = "sensor",
  Sensors = "sensors",
  SensorEvents = "sensor-events",
  Space = "space",
  Spaces = "spaces",
  SpaceActivity = "space-activity",
  SpaceUsage = "space-usage",
  SpaceUsers = "space-users",
  ParkingGroup = "parking-group",
  ParkingResidents = "parking-residents",
  Vehicle = "vehicle",
  Vehicles = "vehicles",
  Violations = "violations",
}
