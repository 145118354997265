(function setFavicon() {
  const favicon = document.getElementById("favicon") as HTMLLinkElement;

  if (favicon) {
    switch (window.location.hostname) {
      case "localhost":
        favicon.href = "/favicon-dev.ico";
        break;
      case "support.qa-smartrent.com":
        favicon.href = "/favicon-qa.ico";
        break;
      default:
        break;
    }
  }
})();
