import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

import { UnitModel, HubRequest } from "@/types";
import { useIsDesktop } from "@/lib/hooks";

import { BoxGet } from "@/pages/hub-page/control-center/BoxGet";

import { BoxSessionCard } from "./control-center/BoxSessionCard";
import { HubPrepLogs } from "./control-center/HubPrepLogs";

export interface ControlCenterProps {
  unit: UnitModel;
  hubSerial: string;
  hub: HubRequest;
}

export const ControlCenter: React.FC<
  React.PropsWithChildren<ControlCenterProps>
> = ({ hubSerial, hub, unit }) => {
  const isDesktop = useIsDesktop();

  return (
    <View>
      <Typography type="title">Control Center</Typography>

      <View style={[styles.cardRow, isDesktop ? styles.row : styles.column]}>
        {unit && hub?.type === "zipato" ? (
          <BoxGet unit={unit} style={!isDesktop ? styles.mobileCard : null} />
        ) : null}
        {hub?.type === "zipato" ? (
          <BoxSessionCard
            hubSerial={hubSerial}
            style={!isDesktop ? styles.mobileCard : null}
          />
        ) : null}
        <HubPrepLogs hubSerial={hubSerial} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardRow: {
    justifyContent: "space-between",
    marginTop: 16,
    alignItems: "flex-start",
  },
  mobileCard: {
    width: "100%",
  },
  row: { flexDirection: "row" },
  column: { flexDirection: "column" },
});
