import { useCallback, useMemo } from "react";
import { StyleSheet } from "react-native";
import { useQueryClient } from "@tanstack/react-query";

import { ChipProps, ThemeColor } from "@smartrent/ui";

import { HubRefurbishErrorNames, HubRefurbishReactQueryKeys } from "@/types";
import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

interface useVirtualBoxHookProps {
  serial?: string;
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
}

export const useVirtualBoxHook = ({
  serial,
  isSuccess,
  isError,
  isLoading,
}: useVirtualBoxHookProps) => {
  const { hubsDict, updateHub } = useHubRefurbishContext();
  const queryClient = useQueryClient();

  const isDisabled = useMemo<boolean>(
    () =>
      serial !== undefined &&
      hubsDict[serial] &&
      hubsDict[serial]?.isDisabled == true,
    [hubsDict, serial]
  );

  const isToBeRemoved = useMemo<boolean>(
    () =>
      serial !== undefined &&
      hubsDict[serial] &&
      hubsDict[serial]?.isToBeRemoved == true,
    [hubsDict, serial]
  );

  const isInHubPrep = useMemo<boolean>(
    () =>
      serial !== undefined &&
      hubsDict[serial] &&
      hubsDict[serial]?.error?.context?.name ===
        HubRefurbishErrorNames.ActiveHubPrepSession,
    [hubsDict, serial]
  );

  // clicking 'remove' button
  const handleOnPress = useCallback(() => {
    if (serial) {
      updateHub(serial, { isToBeRemoved: !isToBeRemoved });
    }
    queryClient.invalidateQueries([HubRefurbishReactQueryKeys.RedisStats]);
    queryClient.invalidateQueries([HubRefurbishReactQueryKeys.HubRefurbishJob]);
  }, [isToBeRemoved, queryClient, serial, updateHub]);

  const serialShort = useMemo(() => {
    if (!serial) {
      return "";
    }

    const replaceRemoved = serial.replaceAll(/disabled_/gi, "");
    const lastFourSerial = replaceRemoved.substring(replaceRemoved.length - 4);
    return lastFourSerial;
  }, [serial]);

  const { chipColor, progressColor } = useMemo<{
    chipColor: ChipProps["color"];
    progressColor: ThemeColor;
  }>(() => {
    if (isDisabled || isToBeRemoved) {
      return {
        chipColor: "grayscale",
        progressColor: "gray600",
      };
    }

    if (isLoading) {
      return { chipColor: "info", progressColor: "info" };
    }

    if (isSuccess) {
      return {
        chipColor: "success",
        progressColor: "success",
      };
    }

    if (isError) {
      return {
        chipColor: "warning",
        progressColor: "warning",
      };
    }

    if (isInHubPrep) {
      return {
        chipColor: "error",
        progressColor: "error",
      };
    }

    return { chipColor: "info", progressColor: "info" };
  }, [isDisabled, isError, isInHubPrep, isLoading, isSuccess, isToBeRemoved]);

  return {
    isDisabled,
    isToBeRemoved,
    isInHubPrep,
    handleOnPress,
    serialShort,
    chipColor,
    progressColor,
    ...styles,
  };
};

const styles = StyleSheet.create({
  style: {
    flexDirection: "column-reverse",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },

  textStyle: {
    marginHorizontal: 0,
    fontSize: 22,
    textAlign: "center",
    alignSelf: "center",
  },
});
