import { useContext } from "react";
import { compact, map } from "lodash-es";

import { useQueryCompat, useMutationCompat } from "@smartrent/hooks";

import { createAxiosQuery } from "@/hooks/react-query";

import { AxiosMutationConfig } from "@/lib/react-query-helpers";
import Context from "@/layout/Context";

import { instance } from "@/lib/hooks";
import { DeviceSettingsProps, GenericObject } from "@/types";

export const useDeviceSettingsQuery = (deviceId: number) =>
  useQueryCompat(
    ["deviceSettings", deviceId],
    async (key: "deviceSettings", deviceId: number) => {
      const { data } = await instance.get<DeviceSettingsProps>(
        `/devices/${deviceId}/settings`
      );

      return data;
    }
  );

interface UpdateDeviceSettingsMutationOptions {
  deviceId: number;
  settings: GenericObject;
}

export const useUpdateDeviceSettingsMutation = (
  options?: AxiosMutationConfig<UpdateDeviceSettingsMutationOptions>
) => {
  const context = useContext(Context);

  return useMutationCompat(
    async ({ deviceId, settings }: UpdateDeviceSettingsMutationOptions) => {
      const payload = compact(
        map(settings, (value, key) => {
          if (!value) {
            return null;
          }

          return { expected_value_label: value, setting_label: key };
        })
      );

      try {
        const { data } = await instance.put(
          `/devices/${deviceId}/settings`,
          payload
        );

        context.setToast({
          type: "success",
          title: "Success",
          message:
            "Device settings are updating, please check back in 5-10 minutes.",
        });

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: "Error updating device settings.",
        });

        throw err;
      }
    },
    options
  );
};

export interface BatteryHistoryResponse {
  battery_level: number;
  device_id: number;
  group_id: number;
  inserted_at: string;
  unit_id: number;
}

export interface UnitInterface {
  id: number | string;
  group_id: number | string;
}

export interface BatteryHistoryOptions {
  deviceId: string | number;
  unit: UnitInterface;
}

export const useBatteryHistoryQuery = createAxiosQuery(
  "battery-history",
  async ({ deviceId, unit }: BatteryHistoryOptions) => {
    return instance.get<BatteryHistoryResponse[]>(
      `/devices/${deviceId}/battery-history?unitId=${unit.id}&groupId=${unit.group_id}`
    );
  }
);

export interface RingAlarmLogResponse {
  id: string | number;
  action: string;
  status: string;
  inserted_at: string;
}

export interface RingAlarmLogOptions {
  deviceId: string | number;
}

export const useRingAlarmLogQuery = createAxiosQuery(
  "ring-alarm-log",
  async ({ deviceId }: RingAlarmLogOptions) => {
    return instance.get<RingAlarmLogResponse[]>(
      `/devices/access-control/${deviceId}/ring-alarm-logs`
    );
  }
);
