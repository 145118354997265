import { useMemo } from "react";
import { HStack, LinearProgress } from "@smartrent/ui";
import { View, Text } from "react-native";

export function HubPrepStats({
  total,
  statuses,
}: {
  total: number;
  statuses: (string | undefined)[];
}) {
  const completedHubsCount = useMemo(() => {
    return statuses.filter((status) => status === "Complete").length;
  }, [statuses]);

  if (!total) {
    return null;
  }

  return (
    <View style={{ marginVertical: 24, flex: 1, alignItems: "center" }}>
      <HStack spacing={4}>
        <LinearProgress
          width={320}
          progress={Math.round((completedHubsCount / total) * 100)}
        />
        <Text>
          Completed {completedHubsCount} / {total}
        </Text>
      </HStack>
    </View>
  );
}
