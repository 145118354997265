import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import {
  HStack,
  ListItemContainer,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  StatusBadge,
  Tooltip,
  useTheme,
} from "@smartrent/ui";

import { InformationSolid } from "@smartrent/icons";

import { useIsDesktop } from "@/hooks/breakpoints";

import { useGroupPropertyHealthReportQuery } from "@/api/reports";

import { useFetchProjectsByGroup } from "@/api/projects";

import { useGroup } from "../GroupContext";

import { UnitProblemsList } from "./property-health/UnitProblemsList";
import { PropertyHealthStatistics } from "./property-health/PropertyHealthStatistics";
import { AllUnitsTableLink } from "./property-health/AllUnitsTableLink";

export const PropertyHealthCard: React.VFC = () => {
  const isDesktop = useIsDesktop();
  const { colors } = useTheme();

  const {
    group: { id },
  } = useGroup();

  const { data: projectsData } = useFetchProjectsByGroup({
    groupId: id,
    status: "in progress",
  });

  const { data, isLoading } = useGroupPropertyHealthReportQuery(
    {
      groupId: id,
    },
    { enabled: Boolean(id && id !== null) }
  );

  const hasUnitProblems = useMemo(
    () => (data?.stats.total_unhealthy_units || 0) > 0,
    [data?.stats?.total_unhealthy_units]
  );

  const isInstallInProgress = useMemo(
    () => (projectsData?.total_records || 0) > 0,

    [projectsData?.total_records]
  );

  return (
    <Panel style={isDesktop ? styles.desktop : styles.mobile}>
      {/* CARD TITLE */}
      <PanelHeader>
        <HStack align="center" style={styles.header}>
          <PanelHeaderTitle title="Property Health" />

          <HStack align="center" spacing={8}>
            {isInstallInProgress ? (
              <StatusBadge
                status="knowledge"
                variation="outlined"
                size="semibold.title.four"
              >
                INSTALL IN PROGRESS
              </StatusBadge>
            ) : null}

            <Tooltip
              name="Property Health Tooltip"
              title="This is a snapshot of the current property health, this can change over time. For more information, please go to the installs page."
              placement="bottom"
            >
              <View>
                <InformationSolid size={14} />
              </View>
            </Tooltip>
          </HStack>
        </HStack>
      </PanelHeader>

      {/* STATS */}
      {!isLoading && data?.stats ? (
        <PanelHeader
          style={{
            backgroundColor: colors.listBackground,
            borderBottomColor: colors.border,
          }}
        >
          <PropertyHealthStatistics
            isInstallInProgress={isInstallInProgress}
            stats={data.stats}
          />
        </PanelHeader>
      ) : null}

      {hasUnitProblems && data?.unitProblems ? (
        <>
          <PanelBody>
            <UnitProblemsList data={data.unitProblems} isLoading={isLoading} />
          </PanelBody>

          <ListItemContainer style={styles.footer}>
            <AllUnitsTableLink {...data.unhealthyUnits} />
          </ListItemContainer>
        </>
      ) : null}
    </Panel>
  );
};

const styles = StyleSheet.create({
  desktop: { width: 480 },
  mobile: { width: "100%" },
  header: { flexGrow: 1, justifyContent: "space-between" },
  footer: {
    height: 64,
    flex: 1,
    paddingVertical: 16,
    flexDirection: "column",
  },
});
