import * as React from "react";
import { StyleSheet, View } from "react-native";

import { AnimatedPlaceholder, useTheme } from "@smartrent/ui";

import { GroupProps } from "@/types";

import { GroupDetailsHeader } from "@/pages/group/cards/group-details/GroupDetailsHeader";

interface PropertyHeaderProps {
  group: GroupProps;
}

export const PropertyHeader: React.FC<
  React.PropsWithChildren<PropertyHeaderProps>
> = ({ group }) => {
  const { colors } = useTheme();

  return (
    <View style={styles.root}>
      {group ? (
        <GroupDetailsHeader thirdLevel="Parking Module" />
      ) : (
        <AnimatedPlaceholder
          style={[styles.bar, { backgroundColor: colors.gray100 }]}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    margin: 24,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bar: {
    height: 56,
    width: 60,
  },
});
