import { useEffect, useMemo } from "react";
import * as React from "react";

import { groupBy } from "lodash-es";

import { queryClient } from "@/api/query-client";
import {
  SOW_QUERY_KEY,
  useSowCompleteErrorsQuery,
  useScopeOfWorkQuery,
} from "@/api";

import {
  ScopeOfWorkDeviceDictionary,
  ScopeOfWorkDeviceProps,
  ScopeOfWorkProps,
  ScopeOfWorkStatus,
} from "@/types";

import { ScopeOfWorkContext } from "./ScopeOfWorkContext";

interface ScopeOfWorkProviderProps {
  id: ScopeOfWorkProps["id"];
}
export const ScopeOfWorkProvider: React.FC<
  React.PropsWithChildren<ScopeOfWorkProviderProps>
> = ({ id, children }) => {
  const pathname = useMemo(() => `/scope-of-work/${id}`, [id]);
  const queryKey = useMemo(() => [SOW_QUERY_KEY, String(id)], [id]);
  const { data, ...queryProps } = useScopeOfWorkQuery(id, {
    queryKey,
  });

  useEffect(() => {
    queryClient.invalidateQueries(["salesforce-properties", ""]);
  }, [data?.sf_opportunity_id]);

  const parCompleteErrorsQuery = useSowCompleteErrorsQuery(id, {
    enabled: data?.status === ScopeOfWorkStatus.Error,
  });

  // memoize the lodash groupBy call
  const devicesGroupedByCategory = useMemo<ScopeOfWorkDeviceDictionary>(
    () =>
      data?.scope_of_work_devices?.length
        ? (groupBy<ScopeOfWorkDeviceProps>(
            data.scope_of_work_devices,
            "sf_device_category"
          ) as ScopeOfWorkDeviceDictionary)
        : ({} as ScopeOfWorkDeviceDictionary),
    [data?.scope_of_work_devices]
  );

  return (
    <ScopeOfWorkContext.Provider
      value={{
        scopeOfWorkId: id,
        scopeOfWorkQuery: { data, ...queryProps },
        scopeOfWorkQueryKey: queryKey?.length ? queryKey : [],
        devicesGroupedByCategory,
        parCompleteErrorsQuery,
        pathname,
      }}
    >
      {children}
    </ScopeOfWorkContext.Provider>
  );
};
