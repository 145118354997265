import { useContext } from "react";
import classNames from "classnames";

import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

import AutoRefresh from "./AutoRefresh";

export default function InstallFilters({
  setIsAutoRefreshing,
  isAutoRefreshing,
  handleIntervalChange,
}) {
  const { location, history } = useContext(Context);
  const qs = Helpers.qsFromLocation(location);
  const interval = qs.interval || "12 hours";
  return (
    <div className="u-flex u-flex-wrap u-flex-align-items-center u-flex-justify-between">
      <div className="u-font12 u-p12">
        <AutoRefresh
          pauseAutoRefresh={() => setIsAutoRefreshing(false)}
          startAutoRefresh={() => setIsAutoRefreshing(true)}
          isAutoRefreshing={isAutoRefreshing}
          updateAutoRefreshInterval={(newInterval) =>
            handleIntervalChange(newInterval)
          }
        />
      </div>
      <div className="u-font12 u-p12 u-text-right">
        <span
          className={classNames(
            "cursor--pointer",
            interval === "12 hours" ? "strong" : ""
          )}
          onClick={() =>
            Helpers.updateQS({
              history: history,
              location: location,
              update: { interval: "12 hours" },
            })
          }
        >
          Last 12 hours
        </span>
        {" | "}

        <span
          className={classNames(
            "cursor--pointer",
            interval === "24 hours" ? "strong" : ""
          )}
          onClick={() =>
            Helpers.updateQS({
              history: history,
              location: location,
              update: { interval: "24 hours" },
            })
          }
        >
          Last 24 hours
        </span>
        {" | "}

        <span
          className={classNames(
            "cursor--pointer",
            interval === "48 hours" ? "strong" : ""
          )}
          onClick={() =>
            Helpers.updateQS({
              history: history,
              location: location,
              update: { interval: "48 hours" },
            })
          }
        >
          Last 48 hours
        </span>
      </div>
    </div>
  );
}
