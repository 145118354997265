import { useMemo } from "react";
import {
  DirectionStackProps,
  HStack,
  Typography,
  useTheme,
} from "@smartrent/ui";

import { getZipatoHubConnectionStatus, ConnectionStatus } from "@/utils/zipato";
import { ZipatoHubOnlineStatus } from "@/types";

import Circle from "../Circle";

interface HubOnlineCircleProps extends Partial<DirectionStackProps> {
  isOnline: ZipatoHubOnlineStatus;
}
export const HubOnlineCircle = ({ isOnline, style }: HubOnlineCircleProps) => {
  const connectionStatus = getZipatoHubConnectionStatus(isOnline);
  const { colors } = useTheme();

  const color = useMemo<string>(() => {
    switch (connectionStatus) {
      case ConnectionStatus.Online:
        return colors.green;
      case ConnectionStatus.Offline:
        return colors.error;
      default:
        return colors.gray400;
    }
  }, [colors.error, colors.gray400, colors.green, connectionStatus]);

  const text = useMemo<string>(() => {
    switch (connectionStatus) {
      case ConnectionStatus.Online:
        return "Yes";
      case ConnectionStatus.Offline:
        return "No";
      default:
        return "Unknown";
    }
  }, [connectionStatus]);

  return (
    <HStack align="center" style={style}>
      <Circle size={8} color={color} />
      <Typography>{text}</Typography>
    </HStack>
  );
};
