import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { AnimatedPlaceholder, DrawerHeader, useTheme } from "@smartrent/ui";

import { useSignQuery } from "@/api";

interface SignHeaderProps {
  signId: string;
  propertyId: string;
}

export const SignHeader: React.FC<React.PropsWithChildren<SignHeaderProps>> = ({
  signId,
  propertyId,
}) => {
  const { colors } = useTheme();

  const { data: sign, isLoading } = useSignQuery(signId, propertyId);

  const title = useMemo(
    () =>
      isLoading || !sign ? (
        <View style={styles.placeholderContainer}>
          <AnimatedPlaceholder
            style={[
              styles.titlePlaceholder,
              { backgroundColor: colors.gray100 },
            ]}
          />
        </View>
      ) : (
        sign?.location
      ),
    [sign]
  );

  return <DrawerHeader title={title} />;
};

const styles = StyleSheet.create({
  placeholderContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  titlePlaceholder: {
    width: 100,
    height: 20,
    borderRadius: 2,
    marginRight: 4,
  },
});
