import { useContext } from "react";
import * as React from "react";
import { StyleSheet, StyleProp, ViewStyle } from "react-native";

import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelHeaderTitle,
  PanelHeaderTitleWithStatusBadge,
  PanelHeaderTitleWithStatusBadgeProps,
  MenuOption,
} from "@smartrent/ui";

import Context from "@/layout/Context";

interface cardProps {
  title?: string;
  statusBadge?: Pick<
    PanelHeaderTitleWithStatusBadgeProps,
    "status" | "statusLabel"
  >;
  children: React.ReactChild;
  actions?: MenuOption[];
  customStyles?: StyleProp<ViewStyle>;
  customHeaderStyles?: StyleProp<ViewStyle>;
}

export const PanelCard = ({
  actions,
  title = "",
  statusBadge,
  children,
  customStyles,
}: cardProps) => {
  const { isDesktop } = useContext(Context);

  return (
    <Panel
      style={[
        isDesktop ? styles.cardWidth : styles.mobileWidth,
        customStyles || {},
      ]}
    >
      <PanelHeader actions={actions}>
        {!statusBadge ? (
          <PanelHeaderTitle title={title} />
        ) : (
          <PanelHeaderTitleWithStatusBadge
            title={title}
            status={statusBadge?.status || "knowledge"}
            statusLabel={statusBadge?.statusLabel}
          />
        )}
      </PanelHeader>
      <PanelBody>{children}</PanelBody>
    </Panel>
  );
};

const styles = StyleSheet.create({
  mobileWidth: {
    width: "100%",
  },
  cardWidth: {
    width: 480,
  },
});
