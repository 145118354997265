import { Typography } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { DataDogLink } from "@/common/links/DataDogLink";
import { hubDebugLogs } from "@/utils/datadog";

import { CopyText } from "@/common/CopyText";

export default function HubHeader({ hub, permissions, devices }) {
  if (!(hub && permissions.view_technical_details)) {
    return null;
  }

  return (
    <>
      <div>
        {hub.type === "smartrent" && devices?.length ? (
          <>
            <Typography type="bodyLargeSemibold">iEX Scripts</Typography>
            <CopyText
              text={`
          defmodule FailedNodeRemover do
            defp send_noop_command(failed_node_id) do
              case Grizzly.ping(failed_node_id, timeout: 60_000) do
                {:error, :nack_response} ->
                  # This is the response you want to get for remove failed node to work
                  {:ok, :remove_failed_node}
              
                {:error, reason} ->
                  # The command failed for another reason, such as the controller being in inclusion mode.
                  {:error, reason}
              
                {:ok, %Grizzly.Report{type: :timeout}} ->
                  # Wait **at least** 30 more seconds before retrying.
                  {:error, :timeout}
              
                {:ok, %Grizzly.Report{type: :queued_delay}} ->
                  # The command was queued. For existing firmware versions,
                  # this is almost always because the command is for a wakeup
                  # device that Z/IP Gateway does not consider to be failed.
                  # If that's the case, there's nothing you can do until
                  # the node is marked as failed.
                  {:error, :queued_delay}
              
                {:ok, %Grizzly.Report{type: :ack_response}} ->
                  # The device is not actually failed (anymore). Don't try to remove it.
                  {:error, :not_failed}
              end
            end
          
            def remove_nodes_if_noop_fails(node_ids_to_keep) do
              piston_failed_node_fn_exists = function_exported?(Piston, :remove_failed_node, 1)
          
              Grizzly.Network.report_failed_node_ids()
              |> elem(1)
              |> IO.inspect(label: "Attempting to recover these failed nodes", charlists: :as_lists)
              |> Enum.each(fn failed_node_id ->
                failed_node_id
                |> send_noop_command()
                |> case do
                  {:error, :not_failed} -> 
                    IO.puts("Recovered node: #{failed_node_id}")
                  
                  {:ok, :remove_failed_node} -> 
                    unless failed_node_id in node_ids_to_keep do
                      if piston_failed_node_fn_exists, do: Piston.remove_failed_node(failed_node_id), else: Grizzly.Network.remove_failed_node(node_id: failed_node_id)
                      IO.puts("Removed failed node: #{failed_node_id}") 
                    end         

                  {:error, reason} -> 
                    IO.puts("Failed to ping node #{failed_node_id}: #{inspect(reason)})")
                end
              end)
          
              unless piston_failed_node_fn_exists do
                Nerves.Runtime.reboot()
              end
            end
          end
          
          FailedNodeRemover.remove_nodes_if_noop_fails([${devices
            .map((device) => device.remote_id)
            .join(", ")}])
                      `}
            >
              <Typography type="body">Remove Failed Nodes Script</Typography>
            </CopyText>
          </>
        ) : null}
      </div>
      <div
        className={`${Helpers.technicalDetailsTextClass} u-font10 u-flex u-flex-justify-between u-pleft4 u-pright4`}
      >
        {hub.id ? (
          <div>
            id: {hub.id}, hub.remote_id: {hub.remote_id}
          </div>
        ) : null}

        <div>
          <DataDogLink
            href={hubDebugLogs(hub)}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </div>
    </>
  );
}
