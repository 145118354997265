import { useCallback, useEffect } from "react";
import { stringify } from "querystring";
import { useLocation, useHistory } from "react-router-dom";

import { getClampedPageNumber } from "@/lib/pagination";

import { useQueryString, useQueryStringField } from "./query-string";

export interface UrlPaginationOptions {
  /**
   * Total number of pages.
   */
  totalPages?: number;

  /**
   * Default page size.
   *
   * @default 25
   */
  defaultPageSize?: number;

  /**
   * Query string key used to store the current page.
   *
   * @default "page"
   */
  pageQueryKey?: string;

  /**
   * Query string key used to store the page size.
   *
   * @default "limit"
   */
  pageSizeQueryKey?: string;

  /**
   * Whether to redirect if the page value in the query string is out of range
   * (i.e. not between 1 and `totalPages`, inclusive).
   *
   * @default true
   */
  redirectIfOutOfRange?: boolean;

  /**
   * Whether to use history.push or history.replace to update the URL.
   *
   * @default "replace"
   */
  method?: "push" | "replace";
}

export const useUrlPagination = (options: UrlPaginationOptions = {}) => {
  const {
    totalPages,
    defaultPageSize = 25,
    pageQueryKey = "page",
    pageSizeQueryKey = "limit",
    redirectIfOutOfRange = true,
    method = "replace",
  } = options;

  const location = useLocation();
  const history = useHistory();

  const qs = useQueryString();
  const page = getClampedPageNumber(qs, totalPages, pageQueryKey);
  let pageSize = useQueryStringField(pageSizeQueryKey, Number);
  if (!pageSize || isNaN(pageSize)) {
    pageSize = defaultPageSize;
  }

  useEffect(() => {
    if (!redirectIfOutOfRange) {
      return;
    }

    if (page < 1) {
      // If page is not a positive integer, redirect to page 1

      history.replace({
        pathname: location.pathname,
        search: stringify({ ...qs, [pageQueryKey]: 1 }),
      });
    } else if (totalPages && page > totalPages) {
      // we only redirect to the last page if the page count is provided
      // (*and* isn't falsey). that way if totalPages is 0 for some reason,
      // we don't redirect to ?page=0, which would then cause us to redirect on
      // the next render to ?page=1 (and enter an infinite loop)

      history.replace({
        pathname: location.pathname,
        search: stringify({ ...qs, [pageQueryKey]: totalPages }),
      });
    }
  }, [
    qs,
    page,
    history,
    totalPages,
    pageQueryKey,
    location.pathname,
    redirectIfOutOfRange,
  ]);

  const setPage = useCallback(
    (page: number) => {
      history[method]({
        pathname: location.pathname,
        search: stringify({ ...qs, [pageQueryKey]: page }),
      });
    },
    [history, method, location.pathname, qs, pageQueryKey]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      history[method]({
        pathname: location.pathname,
        search: stringify({ ...qs, [pageSizeQueryKey]: pageSize }),
      });
    },
    [history, method, location.pathname, qs, pageSizeQueryKey]
  );

  return { page, pageSize, setPage, setPageSize };
};
