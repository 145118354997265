import { startCase } from "lodash-es";

export const formatRingLog = ({
  action,
  status,
}: {
  action: string;
  status: string;
}) => {
  if (status !== "success") {
    return `Unable to ${startCase(action)} (${
      status ? startCase(status) : "Reason Unkown"
    })`;
  } else {
    return `${startCase(action)} - Success`;
  }
};
