import { instance } from "@/lib/hooks";
import { getErrorMessage } from "@/lib/axios-helpers";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { ResidentProps } from "@/types";

export const useResidentQuery = createAxiosQuery(
  "resident",
  async (residentId: ResidentProps["id"]) =>
    instance.get<ResidentProps>(`residents/${residentId}`)
);

export interface DeleteResidentOptions {
  residentId: string | number;
}

export const useDeleteResident = createAxiosMutation(
  async ({ residentId }: DeleteResidentOptions) => {
    return instance.delete(`/residents/${residentId}`);
  },
  {
    successToast: () => ({
      message: "Resident deleted. Please refresh to see changes reflected.",
    }),
    errorToast: (err) => ({
      message: `Error deleting resident. ${getErrorMessage(err)}`,
    }),
  }
);

export const useDissociateUserResident = createAxiosMutation(
  async ({ residentId }: DeleteResidentOptions) => {
    return instance.patch(`/residents/${residentId}/detach`);
  },
  {
    successToast: () => ({
      message:
        "User and resident successfully disassociated. Please refresh to see changes reflected.",
    }),
    errorToast: (err) => ({
      message: `Error disassociating user and resident. ${getErrorMessage(
        err
      )}`,
    }),
  }
);

export const resendResidentInvitation = createAxiosMutation(
  async ({ residentId }: DeleteResidentOptions) => {
    return instance.post(`/residents/${residentId}/resend-invite`);
  },
  {
    successToast: () => ({
      message: "Resident invite resent.",
    }),
    errorToast: (err) => ({
      message: `Error resending invite. ${getErrorMessage(err)}`,
    }),
  }
);
