import { Text, View, TouchableOpacity, ActivityIndicator } from "react-native";
import { startCase } from "lodash-es";

import { CheckCircleSolid, ExclamationCircleOutline } from "@smartrent/icons";

import { useApi } from "@/lib/hooks";
import Helpers from "@/lib/helpers";

import { VerificationImageProps } from "../../types";

export default function VerificationImage({
  label,
  isValid,
  path,
  selectAcceptedImage,
  style,
  reason,
}: {
  label: String;
  isValid: boolean;
  path: string;
  selectAcceptedImage?: null | Function;
  style?: object;
  reason?: null | string;
}) {
  const {
    response,
    loading,
  }: {
    loading: boolean;
    response: { data: null | VerificationImageProps };
  } = useApi({
    url: `/verifications/image/${path}`,
    trigger: [path],
    forceDispatchEffect: () => !!path,
  });

  if (loading) {
    return (
      <View
        style={[
          {
            borderRadius: 8,
            justifyContent: "center",
          },
          style,
        ]}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  } else if (!(response && response.data)) {
    return null;
  }

  return (
    <View
      style={{
        alignItems: "center",
        marginRight: 16,
      }}
    >
      <View
        style={{
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        {selectAcceptedImage ? (
          <TouchableOpacity
            onPress={() => selectAcceptedImage(response)}
            style={{ position: "relative" }}
          >
            <AttemptedImage imageData={response.data} />
          </TouchableOpacity>
        ) : (
          <AttemptedImage imageData={response.data} />
        )}
      </View>
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 8 }}
      >
        <Text>{label}</Text>
        <View style={{ marginLeft: 4 }}>
          {isValid ? (
            <CheckCircleSolid color={Helpers.colors.green} size={14} />
          ) : (
            <ExclamationCircleOutline color={Helpers.colors.orange} size={14} />
          )}
        </View>
      </View>
      {reason && <Text>Reason: {startCase(reason)}</Text>}
    </View>
  );
}

type AttemptedImageProps = {
  imageData: {
    info?: {
      mimetype?: string;
    };
    image?: string;
  };
};

function AttemptedImage({ imageData }: AttemptedImageProps) {
  return (
    <img
      style={{ maxWidth: "600px" }}
      src={`data:${imageData.info && imageData.info.mimetype};base64,${
        imageData.image
      }`}
    />
  );
}
