import { countries as countryList } from "@smartrent/flags";

export const countries = [
  { value: "United States", label: "United States" },
  { value: "Canada", label: "Canada" },
  { value: "Singapore", label: "Singapore" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Netherlands", label: "Netherlands" },
];

export type Countries =
  | "United States"
  | "Canada"
  | "Singapore"
  | "United Kingdom"
  | "Netherlands";

export const countryCodesToNames = {};

Object.entries(countryList).forEach(
  ([name, value]) => (countryCodesToNames[value.countryCode] = name)
);
