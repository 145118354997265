import moment from "moment";
import { Link } from "react-router-dom";

import { useUser } from "@/layout/Context";

import Graph from "@/common/Graph";

export default function FailedLeasingSyncs() {
  const { permissions } = useUser();
  if (!permissions.manage_system_health) {
    return null;
  }

  return (
    <Graph
      path="/reports/failed-leasing-syncs"
      dataKey="failedLeasingSyncs"
      renderProps={(failedLeasingSyncs) => {
        return (
          <div>
            <div className="h3 u-mbottom8">Leasing Syncs Problems</div>

            {failedLeasingSyncs.map(
              (
                {
                  group_id,
                  integration_type,
                  name,
                  last_sync,
                  last_units_sync,
                  last_resident_sync,
                  last_work_order_sync,
                  property_code_problem,
                },
                idx
              ) => (
                <div
                  key={group_id}
                  className="u-mbottom8 u-pbottom8"
                  style={{
                    borderBottom:
                      idx + 1 === failedLeasingSyncs.length
                        ? ""
                        : "1px solid #eee",
                  }}
                >
                  <Link
                    to={`/groups/${group_id}`}
                    className="u-link u-link-clean cursor--pointer"
                  >
                    <div className="h5">
                      {name}{" "}
                      <span className="u-text-gray-600 u-font11">
                        ({integration_type})
                      </span>
                    </div>
                    <div className="u-mleft8 u-line18">
                      <div>
                        <span className="u-text-gray-600">
                          Time since last sync:{" "}
                        </span>{" "}
                        {last_sync ? (
                          moment.duration(last_sync).humanize()
                        ) : (
                          <span className="u-text-symbolic-error">n/a</span>
                        )}
                      </div>
                      <div>
                        <span className="u-text-gray-600">
                          Time since last unit sync:{" "}
                        </span>{" "}
                        {last_units_sync ? (
                          moment.duration(last_units_sync).humanize()
                        ) : (
                          <span className="u-text-symbolic-error">n/a</span>
                        )}
                      </div>
                      <div>
                        <span className="u-text-gray-600">
                          Time since last res sync:{" "}
                        </span>{" "}
                        {last_resident_sync ? (
                          moment.duration(last_resident_sync).humanize()
                        ) : (
                          <span className="u-text-symbolic-error">n/a</span>
                        )}
                      </div>
                      <div>
                        <span className="u-text-gray-600">
                          Time since last WO sync:{" "}
                        </span>{" "}
                        {last_work_order_sync ? (
                          moment.duration(last_work_order_sync).humanize()
                        ) : (
                          <span className="u-text-symbolic-error">n/a</span>
                        )}
                      </div>
                    </div>
                    {property_code_problem && (
                      <div className="u-text-symbolic-error">
                        Please update the property code
                      </div>
                    )}
                  </Link>
                </div>
              )
            )}
          </div>
        );
      }}
    />
  );
}
