import { useContext, useEffect } from "react";

import { useDebounce } from "@/lib/hooks";
import Helpers from "@/lib/helpers";

import Context from "../Context";

export default function Search({
  setSearchResults,
  setSearchError,
  keywords,
  setKeywords,
  setSearchLoading,
}) {
  const { user } = useContext(Context);

  const debouncedKeywords = useDebounce(keywords, 800);

  useEffect(() => {
    if (debouncedKeywords.length <= 2) {
      return;
    }
    setSearchLoading(true);
    setSearchError(null);
    Helpers.fetch({
      path: `/api/search`,
      query: { keywords: debouncedKeywords },
    })
      .then(({ rows, error }) => {
        setSearchResults(rows);
        setSearchLoading(false);
        if (error) {
          setSearchError(error);
        }
      })
      .catch((err) => {
        setSearchError("An unknown error occurred. Please try again.");
      });
  }, [debouncedKeywords, setSearchError, setSearchLoading, setSearchResults]);

  if (!Helpers.auth.can(user, "search")) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/autocomplete-valid
    <input
      style={{
        boxShadow: "0",
        border: 0,
        backgroundColor: "rgb(45, 50, 56)",
        color: "#fff",
      }}
      className="input__input u-size-full"
      type="text"
      placeholder="Enter email, name, phone, hub serial number, property or unit name..."
      autoFocus={true}
      value={keywords}
      onChange={(e) => setKeywords(e.currentTarget.value)}
      autoComplete="omnisearch"
    />
  );
}
