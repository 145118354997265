import { createAxiosQuery } from "@/hooks/react-query";
import { instance } from "@/lib/hooks";
import { EngrainMap } from "@/types";

export const useListUnitMapQuery = createAxiosQuery(
  "parking-maps",
  async () => {
    const response = await instance.get<EngrainMap[]>("/parking/maps");

    return response;
  }
);

export const useMapQuery = createAxiosQuery(
  "parking-map",
  async (filters: { mapId: string }) => {
    const response = await instance.get<EngrainMap>(
      `/parking/maps/${filters.mapId}`
    );

    return response;
  }
);
