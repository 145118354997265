import { instance } from "@/lib/hooks";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";

import { AccountProps, Role } from "@/types";

export interface AccountsResponse {
  current_page: number;
  records: AccountProps[];
  total_records: number;
  total_pages: number;
}

export const useAccountsQuery = createAxiosQuery("accounts", async () => {
  return instance.get<AccountsResponse>(`/accounts`);
});

export const useUpsertRoleMutation = createAxiosMutation(
  async (values: { name: Role }) => {
    return instance.patch(`/roles`, { role: values.name });
  },
  {
    successToast: () => ({
      message: "Changed role!",
    }),
    errorToast: () => ({
      message: "Failed to change role",
    }),
  }
);
