import { createAxiosMutation } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";

export interface FeedbackCollectorBody {
  title: string;
  description: string;
  issue_type: string;
}

export const useFeedbackCollectorMutation = createAxiosMutation(
  async (values: FeedbackCollectorBody) => {
    return instance.post(`/feedback`, values);
  },
  {
    successToast: () => ({
      message: "Feedback submitted successfully, thanks!",
    }),
    errorToast: null,
  }
);
