import { useCallback, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Text, useTheme } from "@smartrent/ui";
import { CaretUp, CaretDown, CaretUpDown } from "@smartrent/icons";

import { SortChangeHandler, SortDirection } from "@/hooks/url-sort";

import { BaseRecord, Column } from "./types";

interface HeaderCellProps<TRecord extends BaseRecord> {
  column: Column<TRecord>;
  onSortChange?: SortChangeHandler;
}

export function HeaderCell<TRecord extends BaseRecord>({
  column,
  onSortChange,
}: HeaderCellProps<TRecord>) {
  const { colors } = useTheme();

  const {
    name,
    header,
    sortable,
    sortDirection,
    relativeWidth,
    minWidth,
    maxWidth,
  } = column;

  const handleSortChange = useCallback(() => {
    if (!onSortChange) {
      return;
    }

    let nextSortColumn = name;
    let nextSortDirection: SortDirection;

    if (!sortDirection) {
      nextSortDirection = "asc";
    } else if (sortDirection === "asc") {
      nextSortDirection = "desc";
    } else if (sortDirection === "desc") {
      nextSortColumn = undefined;
      nextSortDirection = undefined;
    }
    onSortChange(nextSortColumn, nextSortDirection);
  }, [name, sortDirection, onSortChange]);

  const rootStyle = useMemo(
    () => [
      styles.root,
      { width: relativeWidth, minWidth, maxWidth, flexGrow: relativeWidth },
    ],
    [relativeWidth, minWidth, maxWidth]
  );

  const content = (
    <>
      {sortDirection === "asc" ? (
        <CaretDown size={16} color={colors.gray600} />
      ) : sortDirection === "desc" ? (
        <CaretUp size={16} color={colors.gray600} />
      ) : sortable ? (
        <CaretUpDown size={24} color={colors.gray400} />
      ) : null}

      {header ? <Text type="title6">{header}</Text> : null}
    </>
  );

  if (sortable) {
    return (
      <TouchableOpacity onPress={handleSortChange} style={rootStyle}>
        {content}
      </TouchableOpacity>
    );
  }

  return <View style={rootStyle}>{content}</View>;
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",

    flexBasis: "auto",
    flexShrink: 0,
  },
});
