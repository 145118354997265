import { StyleSheet } from "react-native";

import { ActivityIndicator, ChipProps, Typography } from "@smartrent/ui";

import { formatNumber } from "@/utils/format-number";

import { Row } from "@/common/Row";
import { UsersReportChip } from "@/common/users/UsersReportChip";
import {
  UsersToolTip,
  UsersToolTipPlaceholder,
  UsersToolTipProps,
} from "@/common/users/UserToolTip";

interface UserReportRowProps {
  count: string | number | null;
  color: ChipProps["color"];
  title: string;
  toolTipProps?: UsersToolTipProps;
  alwaysVisible?: boolean;
}

export const UserReportItem = ({
  count,
  color,
  title,
  toolTipProps,
  alwaysVisible,
}: UserReportRowProps) => {
  // only display item if alwaysVisible set to 'true' OR count > 0
  if (
    alwaysVisible !== true &&
    (count === undefined ||
      count === null ||
      (typeof count === "string" && Number.parseInt(count) === 0) ||
      count === 0)
  ) {
    return null;
  }

  return (
    <Row style={styles.root}>
      <Row>
        <Typography type="title5" color="textSecondary">
          {" "}
          {title}
        </Typography>
        {toolTipProps ? (
          <UsersToolTip {...toolTipProps} />
        ) : (
          <UsersToolTipPlaceholder />
        )}
      </Row>

      {count ? (
        <UsersReportChip color={color} size="small">
          {formatNumber(count)}
        </UsersReportChip>
      ) : (
        <ActivityIndicator />
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 8,
    justifyContent: "space-between",
  },
});
