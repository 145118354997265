import { groupsTourUrl, homeTourUrl } from "@/utils/links";

import { useUnit } from "../UnitContext";

export default function UnitStatus() {
  const { unit, groupTourConfig } = useUnit();
  return (
    <div className="u-mtop12 u-line18">
      <div>
        <span className="u-text-gray-600">Can Be Toured:</span>{" "}
        {unit.can_be_toured ? "Yes" : "No"}
      </div>
      {unit.can_be_toured && (
        <div>
          <span className="u-text-gray-600">Visible On selftournow.com:</span>{" "}
          {unit.display_home_online ? "Yes" : "No"}
        </div>
      )}
      {unit.can_be_toured && groupTourConfig && (
        <div>
          <div>
            <a
              className="a"
              target="_blank"
              rel="noopener noreferrer"
              href={groupsTourUrl({ uuid: groupTourConfig.uuid })}
            >
              Tour Group
            </a>
          </div>
          <a
            className="a"
            target="_blank"
            rel="noopener noreferrer"
            href={homeTourUrl({
              uuid: groupTourConfig.uuid,
              unit_id: unit.id,
            })}
          >
            Tour Home
          </a>
        </div>
      )}
    </div>
  );
}
