import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import { useQueryClient } from "@tanstack/react-query";

import {
  FormikStaticTextField,
  FormikStaticTextFieldProps,
  useTextInputStyles,
  useTheme,
} from "@smartrent/ui";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";
import {
  HubRefurbishFormikFieldLabels,
  HubRefurbishFormikFieldNames,
  HubRefurbishReactQueryKeys,
} from "@/types/HubRefurbishProps";

interface FormikRemovedHubFieldProps {
  label?: FormikStaticTextFieldProps["label"];
  name?: FormikStaticTextFieldProps["name"];
  queryKey: HubRefurbishReactQueryKeys;
}

// we can't re-use the useFormikFieldsHook for 'removed' because it doesn't use react-query data ..
// it uses our context data, so we keep all the logic in this component instead of another custom hook
export const FormikRemovedHubField: React.FC<
  React.PropsWithChildren<FormikRemovedHubFieldProps>
> = ({
  name = HubRefurbishFormikFieldNames.Removed,
  label = HubRefurbishFormikFieldLabels.Removed,
  queryKey,
}) => {
  const { colors } = useTheme();
  const queryClient = useQueryClient();

  const { removedSerials, disabledSerials, updateAllHubs } =
    useHubRefurbishContext();

  const { setFieldValue } = useFormikContext();

  const fieldValue = useMemo<number | undefined>(() => {
    const inactiveSerials = [...disabledSerials, ...removedSerials];
    return inactiveSerials.length ? inactiveSerials.length : undefined;
  }, [disabledSerials, removedSerials]);

  useEffect(() => {
    setFieldValue(name, fieldValue);
  }, [fieldValue, name, setFieldValue]);

  const disabled = useMemo(
    () => (removedSerials || []).length === 0,
    [removedSerials]
  );

  // make a fake react element
  // in order to trick js-shared into thinking we need to format the textinput for an icon
  // we can remove this once js-shared supports more style props for these types of components
  const startAdornment = useMemo(
    () => (disabled ? <></> : undefined),
    [disabled]
  );

  const { containerStyle, labelStyle } = useTextInputStyles({
    disabled,
    startAdornment,
    required: false,
    isFocused: false,
  });

  const onClearCallback = useCallback(() => {
    updateAllHubs(() => ({ isToBeRemoved: false }));
    setTimeout(() => queryClient.refetchQueries([queryKey]), 250);
  }, [queryClient, queryKey, updateAllHubs]);

  return (
    <FormikStaticTextField
      style={styles.root}
      containerStyle={{
        ...containerStyle,
        ...{ backgroundColor: colors.mutedBackgroundGrayscale },
      }}
      labelStyle={{
        ...labelStyle,
        ...{ color: colors.mutedTextGrayscale },
      }}
      name={name}
      label={label}
      onPress={() => null}
      transformValue={(value) => value}
      onClear={onClearCallback}
      disabled={disabled}
    />
  );
};

const styles = StyleSheet.create({
  root: { maxWidth: 175 },
});
