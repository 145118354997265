import Layout from "@/layout/Layout";

import ManualVerificationList from "./tours-dashboard/ManualVerificationList";

export default function ToursDashboard() {
  return (
    <Layout>
      <ManualVerificationList />
    </Layout>
  );
}
