// build a url with provided query params object
// @returns URL object
//
// @example
//
//  const url = buildUrl({
//    pathname: "/result/1",
//    search: {limit: 5, page: 2}
//  });
//
//    => URL {pathname: "/result/1", search: "?limit=5&page=2", origin: "http://localhost:3002",…}'
//
// url.toString()
//
//    => "http://localhost:3002/result/1?limit=5&page=2"
//
export const buildUrl = ({
  pathname = window.location.pathname,
  search = {},
  base = window.location.origin,
}) => {
  const url = new URL(pathname, base);
  for (const [key, v] of Object.entries(search)) {
    url.searchParams.append(key, v as string);
  }
  return url;
};
