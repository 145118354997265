import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import helpers from "@/lib/helpers";

import { useQueryString } from "./query-string";

export type SortDirection = "asc" | "desc";
export type SortChangeHandler = (
  column: string | undefined,
  direction: SortDirection | undefined
) => void;

interface UseUrlSortOptions {
  defaultColumn?: string;
  defaultDirection?: SortDirection;
  resetPaginationOnChange?: boolean;
}

const isSortDirection = (value: any): value is SortDirection =>
  ["asc", "desc"].includes(value);

const stringFromQs = (value: string | string[]) => {
  if (Array.isArray(value)) {
    return value[0] ?? "";
  }

  return typeof value === "string" ? value : undefined;
};

export const useUrlSort = ({
  defaultColumn,
  defaultDirection,
  resetPaginationOnChange = true,
}: UseUrlSortOptions = {}) => {
  const history = useHistory();
  const location = useLocation();
  const qs = useQueryString();

  const column = stringFromQs(qs.sort) ?? defaultColumn;

  const dir = stringFromQs(qs.dir);
  const direction = isSortDirection(dir) ? dir : defaultDirection;

  const handleSortChange = useCallback<SortChangeHandler>(
    (sort, dir) => {
      const update: Record<string, string> = { sort, dir };
      if (resetPaginationOnChange) {
        update.page = null;
      }

      helpers.updateQS({
        history,
        location,
        update,
        method: "replace",
      });
    },
    [history, location, resetPaginationOnChange]
  );

  return { column, direction, onSortChange: handleSortChange };
};
