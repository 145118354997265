import { useContext } from "react";
import { View, Text, FlatList, StyleSheet } from "react-native";
import { Link } from "@smartrent/ui";

import Skeleton from "react-loading-skeleton";
import { get, compact } from "lodash-es";

import Context from "@/layout/Context";

import { formatPhone } from "@/utils";
import { group as groupLink, organization as orgLink } from "@/utils/links";

import {
  ProspectProps,
  GroupProps,
  OrganizationProps,
  TourProps,
} from "../types";

import Card from "./Card";
import CardHeading from "./CardHeading";

// Note: This is a Dumb Component and should not make API calls
export default function ProspectCard({
  prospect,
  group,
  organization,
  tours,
}: {
  prospect: ProspectProps;
  group: GroupProps;
  organization: OrganizationProps;
  tours: TourProps[];
}) {
  const context = useContext(Context);
  const permissions = context.user.permissions;

  if (!prospect) {
    return null;
  }

  return (
    <View style={styles.cardContainer}>
      <Card
        heading={
          <CardHeading>
            <Link to={`/prospects/${prospect.id}`}>
              {prospect.first_name || prospect.last_name
                ? `${prospect.first_name} ${prospect.last_name}`
                : "No Name"}
            </Link>
          </CardHeading>
        }
      >
        <FlatList
          data={compact([
            {
              label: "Group",
              text:
                group && organization ? (
                  <View>
                    <Text>
                      <Link href={groupLink(group.id)}>
                        {group.marketing_name}
                      </Link>{" "}
                      (
                      <Link href={orgLink(organization.id)}>
                        {organization.name}
                      </Link>
                      )
                    </Text>
                  </View>
                ) : (
                  "N/A"
                ),
            },

            get(permissions, "resident_details")
              ? {
                  label: "Email",
                  text: prospect ? (
                    prospect.email ? (
                      <View>
                        <Text>
                          <span>{prospect.email}</span>
                        </Text>
                      </View>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton width={145} />
                  ),
                }
              : null,
            get(permissions, "resident_details")
              ? {
                  label: "Mobile Phone",
                  text: prospect ? (
                    prospect.phone_number ? (
                      <Text>
                        {formatPhone({ phone: prospect.phone_number })}
                      </Text>
                    ) : (
                      "N/A"
                    )
                  ) : (
                    <Skeleton width={145} />
                  ),
                }
              : null,

            {
              label: "Tours",
              text: <Text>{tours.length} tours</Text>,
            },
          ])}
          renderItem={({ item }) => (
            <View style={styles.listItem}>
              <Text style={styles.listItemLabel}>{item.label}</Text>
              <Text style={styles.listItemText}>{item.text}</Text>
            </View>
          )}
          keyExtractor={(item) => item.label}
          style={{ position: "relative", zIndex: -1 }}
        />
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    margin: 16,
  },
  listItem: {
    borderTopWidth: 1,
    borderTopColor: "#dadada",
    paddingHorizontal: 24,
    paddingVertical: 18,
    zIndex: 2,
    backgroundColor: "#FFFFFF",
  },
  listItemLabel: {
    color: "#00AC9F",
    fontWeight: "600",
  },
  listItemText: {
    color: "#656B6F",
    fontSize: 20,
  },
});
