import { startCase } from "lodash-es";

export const CELSIUS = "celsius";
export const FAHRENHEIT = "fahrenheit";

export const temperatureScales = [FAHRENHEIT, CELSIUS];

export const temperatureScaleOptions = temperatureScales.map((scale) => ({
  value: scale,
  label: startCase(scale),
}));
