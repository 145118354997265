import { useMemo } from "react";
import { View, Text, StyleSheet } from "react-native";

import { useTheme } from "@smartrent/ui";

export function HubPrepVirtualBox({
  status,
  serial,
}: {
  status: null | undefined | "Complete" | "In Progress" | "Failed" | "Disabled";
  serial: string;
}) {
  const { colors } = useTheme();

  const borderColor = useMemo(() => {
    switch (status) {
      case "Complete":
        return colors.success;
      case "In Progress":
        return colors.info;
      case "Failed":
        return colors.error;
      case "Disabled":
        return colors.gray200;
      default:
        return colors.error300;
    }
  }, [status, colors]);

  const textColor = status === "Disabled" ? colors.white : colors.labelText;
  const backgroundColor =
    status === "Disabled" ? colors.gray200 : "transparent";

  return (
    <View
      style={[
        styles.box,
        {
          borderColor,
          backgroundColor,
        },
      ]}
    >
      <Text style={[styles.text, { color: textColor }]}>
        {serial.substring(serial.length - 4)}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  box: {
    borderWidth: 2,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 2,
  },
  text: {
    textAlign: "center",
  },
});
