import { StyleSheet } from "react-native";
import { Typography, Box, Button } from "@smartrent/ui";
import { PlusCircle, Refresh } from "@smartrent/icons";

import { useUser } from "@/layout/Context";

import { Row } from "@/common/Row";

import { useServerConfigHook } from "../hub-refurbish/hooks/useServerConfigHook";

export function HubPrepQuickActions() {
  const { isKillSwitchOn } = useServerConfigHook();
  const { permissions } = useUser();
  return (
    <Box style={styles.root}>
      <Row style={styles.container}>
        <Typography type="title4Bold">Quick Actions</Typography>
        <Row>
          {!isKillSwitchOn && permissions.refurbish_hubs ? (
            <Button
              variation="outlined"
              href="/hub-prep/refurbish-batch/step/offline"
              iconLeft={Refresh}
              style={styles.button}
              textStyle={styles.buttonText}
            >
              Refurbish Batch
            </Button>
          ) : null}

          <Button
            variation="outlined"
            href="/hub-prep/batch"
            iconLeft={PlusCircle}
            style={styles.button}
            textStyle={styles.buttonText}
          >
            New Batch
          </Button>
        </Row>
      </Row>
    </Box>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 24,
    margin: 8,
  },
  container: { justifyContent: "space-between" },
  button: {
    marginLeft: 16,
    borderRadius: 15,
    borderWidth: 1,
  },
  buttonText: { fontSize: 14, lineHeight: 20, fontWeight: "600" },
});
