import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Launch, QuestionCircleBorder, Wifi } from "@smartrent/icons";
import {
  Grid,
  HStack,
  Link,
  Tooltip,
  Typography,
  useTheme,
  Button,
} from "@smartrent/ui";

import { CircleWithInfo } from "@/common/icons/CircleWithInfo";

import { cmwRedirectBuilder } from "@/utils/links";

import { useUser } from "@/layout/Context";

import { HubProps } from "@/types";

import { useUnit } from "../UnitContext";

export const HubCommunityWifiInfo: React.FC<
  React.PropsWithChildren<{ hub: HubProps }>
> = ({ hub }) => {
  const { colors } = useTheme();
  const { organization, group, communityWifi } = useUnit();
  const { permissions } = useUser();

  const href = useMemo(
    () =>
      cmwRedirectBuilder(
        `/manager/groups/${group?.id}/settings/community-wifi`,
        organization?.url || ""
      ),
    [group?.id, organization?.url]
  );

  if (!hub || !communityWifi.isEnabled) {
    return null;
  }
  return (
    <View style={styles.root}>
      <View style={styles.flexRow}>
        <HStack align="center" spacing={8}>
          <Typography type="title6">WiFi Device Network</Typography>

          {permissions.troubleshoot ? (
            <Tooltip
              title={
                "Use 'Reconnect to WiFi Device Network' action to re-apply Wifi Settings to this hub."
              }
              name="communityWifiTroubleshooting"
              placement="top"
            >
              <View>
                <QuestionCircleBorder size={12} />
              </View>
            </Tooltip>
          ) : null}
        </HStack>

        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={href}
          key="wifi-settings"
        >
          <Button iconLeft={Launch} variation="plain" size="x-small">
            WiFi Device Network Settings
          </Button>
        </Link>
      </View>

      <Grid numColumns={3} gap={8}>
        <CircleWithInfo
          circleColor={
            communityWifi.isHubConnected
              ? colors.primary
              : colors.mutedBadgeWarning
          }
          icon={<Wifi size={20} color={colors.white} />}
          label="Connected"
          value={communityWifi?.isHubConnected ? "Yes" : "No"}
        />
      </Grid>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { paddingVertical: 16 },
  flexRow: { justifyContent: "space-between", flexDirection: "row" },
});
