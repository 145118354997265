import { useServerConfigQueryApi } from "@/api";

export const useServerConfigHook = () => {
  const { data: isKillSwitchOn } = useServerConfigQueryApi((data) =>
    Boolean(data.hubRefurbish.isKillSwitchOn)
  );

  return {
    isKillSwitchOn,
  };
};
