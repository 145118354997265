import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import AriaModal from "react-aria-modal";
import { create } from "zustand";

import { Typography, useTheme } from "@smartrent/ui";
import { CloseCircleSolid } from "@smartrent/icons";

import { formatDate } from "@/utils";

type ImageViewerHookState = {
  isOpen: boolean;
  uri: string | null;
  completed_at: string | null;
  close(): void;
  open(): void;
  openWith(uri: string, completed_at: string | null): void;
  toggle(): void;
  setUri(uri: string): void;
  setCompletedAt(uri: string): void;
};

export const useImageViewer = create<ImageViewerHookState>((set) => ({
  isOpen: false,
  uri: null,
  completed_at: null,
  close: () => set(() => ({ isOpen: false })),
  open: () => set(() => ({ isOpen: true })),
  openWith: (uri: string, completed_at: string | null) =>
    set(() => ({ uri, isOpen: true, completed_at })),
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
  setUri: (uri: string) => set(() => ({ uri })),
  setCompletedAt: (completed_at: string) => set(() => ({ completed_at })),
}));

export function TaskImageViewer() {
  const { colors } = useTheme();
  const { isOpen, close, uri, completed_at } = useImageViewer();

  if (!isOpen) {
    return null;
  }

  return (
    <AriaModal
      titleId="image-viewer"
      onExit={() => close()}
      focusDialog={true}
      verticallyCenter={true}
    >
      <View style={styles.modal}>
        {uri ? (
          <>
            <Image source={{ uri: uri }} style={styles.image} />
            <View style={{ width: "100%", backgroundColor: colors.background }}>
              <Typography style={{ textAlign: "center" }}>
                {formatDate({
                  date: completed_at || "",
                  pattern: "P p",
                })}
              </Typography>
            </View>
          </>
        ) : null}
        <TouchableOpacity style={styles.closeButton} onPress={() => close()}>
          <CloseCircleSolid size={48} color={colors.black} />
        </TouchableOpacity>
      </View>
    </AriaModal>
  );
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: "flex-end",
    position: "relative",
  },
  image: {
    resizeMode: "contain",
    flex: 1,
    aspectRatio: 1.5,
    width: 684,
    height: 1224,
    maxWidth: "100%",
    maxHeight: "80vh",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
});
