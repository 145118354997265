import { AxiosError } from "axios";

import warning from "tiny-warning";

export const getErrorMessage = (
  error: AxiosError,
  defaultError = "Something went wrong."
): string => {
  const isArray = Array.isArray(error?.response?.data?.error);
  const isString = typeof error?.response?.data?.error === "string";

  warning(
    isArray || isString,
    `Expected error.response.data.error to be of type string, got "${typeof error
      ?.response?.data?.error}"`
  );

  if (isArray) {
    return (
      error?.response?.data?.error?.description ?? error?.response?.data?.error
    );
  }

  if (isString) {
    return error?.response?.data?.error;
  }

  return defaultError;
};

export const isAxiosError = (err: any): err is AxiosError =>
  typeof err === "object" && "isAxiosError" in err && err.isAxiosError;
