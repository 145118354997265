import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  BookingType,
  ConstructionType,
  Space,
  SpaceStatus,
  SpaceType,
} from "@smartrent/parking";
import { PaginatedResponse, useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

interface ListSpacesFilters {
  space_number?: string;
  area_name?: string;
  type?: SpaceType;
  booking_type?: BookingType;
  construction_type?: ConstructionType;
  status?: SpaceStatus;
  section_id?: string;
  exclude_section_id?: string;
  sign_id?: string;
  active?: boolean;
  limit?: number;
  sort?: "space_number";
  dir?: "asc" | "desc";
}

interface SpacesResponse {
  spaces: Space[];
  total_pages: number;
  total_records: number;
  current_page: number;
}

export const useSpacesInfiniteQuery = (
  propertyId: string,
  filters: ListSpacesFilters
) =>
  useReducedInfiniteQuery(
    ["spaces", propertyId, filters],
    async ({ pageParam }): Promise<PaginatedResponse<Space>> => {
      const response = await instance.get<SpacesResponse>(
        `/parking/properties/${propertyId}/spaces`,
        {
          params: { ...filters, page: pageParam },
        }
      );
      const { spaces, ...rest } = response.data;
      return { records: spaces, ...rest };
    },
    {
      enabled: !!propertyId,
    }
  );

export const useInvalidateSpacesQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (propertyId: string) =>
      queryClient.invalidateQueries(["spaces", propertyId]),
    [queryClient]
  );
};
