import { DeviceProps, GroupProps, HubProps } from "@/types";

enum Services {
  ControlRoom = "control-room",
  Support = "support",
}

const envFacet = () =>
  import.meta.env.REACT_APP_DD_ENV && import.meta.env.REACT_APP_DD_ENV !== ""
    ? `env%3A${String(import.meta.env.REACT_APP_DD_ENV).toLowerCase()}`
    : "";

export const groupDetailLogs = (group: GroupProps) =>
  `https://app.datadoghq.com/logs?index=&query=%40group_id%3A${
    group.id
  }%20${envFacet()}`;

export const hubDebugLogs = (hub: HubProps) =>
  `https://app.datadoghq.com/logs?query=${hub?.serial}%20%20-service%3A${Services.Support}%20%20-%40loadbalancer%3A%22app%2Fsupport-ecs-alb-prod%2Faaf0b9346dccaa5c%22`;

export const deviceLogs = (device: DeviceProps) =>
  `https://app.datadoghq.com/logs?index=&query=%40device_id%3A${device.id}`;
