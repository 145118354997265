import * as React from "react";
import { StyleSheet } from "react-native";

import { useMenu } from "@smartrent/hooks";
import { Box, Placement, Popover, useTheme, VStack } from "@smartrent/ui";

import { ContextMenuLoading } from "./ContextMenuLoading";

type MenuProps = ReturnType<typeof useMenu>["menuProps"];
interface ContextMenuProps extends MenuProps {
  children: React.ReactNode;
  placement?: Placement;
  isLoading?: boolean;
}

export const ContextMenu = ({
  children,
  placement = "bottom-end",
  isLoading = false,
  ...menuProps
}: ContextMenuProps) => {
  const { spacing } = useTheme();

  return (
    <Popover {...menuProps} placement={placement}>
      <Box style={[styles.container, { padding: spacing.xs }]}>
        {isLoading ? (
          <ContextMenuLoading />
        ) : (
          <VStack spacing={8}>{children}</VStack>
        )}
      </Box>
    </Popover>
  );
};

const styles = StyleSheet.create({
  container: {
    minWidth: 300,
  },
});
