import { View } from "react-native";

import {
  Breadcrumbs,
  Stack,
  useQueryStringField,
  useTheme,
} from "@smartrent/ui";

import { compact, truncate } from "lodash-es";

import Layout from "@/layout/Layout";
import { useResidentQuery } from "@/api";

import { ResidentProps } from "@/types";

import { useIsDesktop } from "@/hooks/breakpoints";

import { EmailWebhooks } from "./resident-webhooks/EmailWebhooks";
import { SmsWebhooks } from "./resident-webhooks/SmsWebhooks";

export const ResidentWebhooks = ({ id }: Pick<ResidentProps, "id">) => {
  const { spacing } = useTheme();
  const isDesktop = useIsDesktop();

  const user_full_name = useQueryStringField("user_full_name");
  const unit_marketing_name = useQueryStringField("unit_marketing_name");

  const { data: resident } = useResidentQuery(id);

  const breadcrumbs = compact([
    { label: "Home", to: "/" },
    resident?.unit_id
      ? {
          label: unit_marketing_name ? unit_marketing_name : "Unit",
          to: `/units/${resident.unit_id}`,
        }
      : null,
    resident?.user_id
      ? {
          label: user_full_name ? user_full_name : "User",
          to: `/users/${resident.user_id}`,
        }
      : null,
    {
      label:
        resident?.first_name && resident?.last_name
          ? truncate(`${resident.first_name} ${resident.last_name}`)
          : "Resident",
    },
  ]);

  return (
    <Layout>
      <View style={{ padding: spacing.xl }}>
        <Breadcrumbs items={breadcrumbs} />
      </View>
      <Stack
        direction={isDesktop ? "row" : "column"}
        width="100%"
        flexChildren={isDesktop ? true : false}
      >
        <View>
          <SmsWebhooks phone_number={resident?.phone_number} />
        </View>

        <View>
          <EmailWebhooks email={resident?.email} />
        </View>
      </Stack>
    </Layout>
  );
};
