import * as React from "react";
import {
  GestureResponderEvent,
  Pressable,
  ActivityIndicator,
  StyleSheet,
} from "react-native";

import { HStack, Typography, useTheme, VStack } from "@smartrent/ui";

interface ContextMenuItem {
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  onPress?: (e: GestureResponderEvent) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ContextMenuItem: React.FC<
  React.PropsWithChildren<ContextMenuItem>
> = ({
  icon,
  title,
  subtitle,
  onPress,
  disabled = false,
  isLoading = false,
}) => {
  const { colors, isLightMode } = useTheme();

  return (
    <Pressable
      onPress={onPress}
      style={[styles.button, disabled ? styles.buttonDisabled : null]}
      disabled={disabled || isLoading}
    >
      <HStack align="center">
        {!isLoading ? (
          icon
        ) : (
          <ActivityIndicator
            color={isLightMode ? colors.gray900 : colors.gray300}
          />
        )}
        <VStack spacing={0} style={styles.content}>
          <Typography type="title6" color="labelText">
            {title}
          </Typography>
          {subtitle ? (
            <Typography type="captionSmall" color="labelText">
              {subtitle}
            </Typography>
          ) : null}
        </VStack>
      </HStack>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 4,
    opacity: 1,
  },
  buttonDisabled: {
    opacity: 0.5,
  },
  content: {
    paddingLeft: 12,
  },
});
