import { useCallback, useState } from "react";
import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import {
  Button,
  Typography,
  HStack,
  FormikTextInputField,
  FormikSubmit,
  useTheme,
  Tooltip,
} from "@smartrent/ui";
import { Formik } from "formik";
import * as Yup from "yup";

import { DollarSign, Email, PencilSolid } from "@smartrent/icons";

import { useUser } from "@/layout/Context";

import { useUpdateSOWMutation } from "@/api";

import { SowStatusBadge } from "./sow-report/SowStatusBadge";
import { useScopeOfWorkContext } from "./provider/ScopeOfWorkContext";
import { SowSubmittedByTooltip } from "./sow-report/SowSubmittedByTooltip";
import { SowReadyForReviewButton } from "./sow-report/SowReadyForReviewButton";
import { SowCompleteButton } from "./sow-report/SowCompleteButton";

export const ScopeOfWorkHeader: React.VFC = () => {
  const [editing, setEditing] = useState(false);
  const { colors } = useTheme();
  const { permissions } = useUser();

  // USE SOW CONTEXT
  const {
    scopeOfWorkQuery: { data, isLoading, isFetching },
  } = useScopeOfWorkContext();

  const [updateSow] = useUpdateSOWMutation();

  const updateSowName = useCallback(
    (values: any, sow: any) => {
      updateSow({
        id: sow.id,
        name: values.name,
      });
      setEditing(false);
    },
    [updateSow]
  );

  return (
    <HStack
      spacing={8}
      align="center"
      justify="space-between"
      style={styles.header}
    >
      {!editing ? (
        <HStack align="center" spacing={4}>
          {/* Status Badge and Submitted By Tooltip */}
          <HStack spacing={8} align="center">
            <SowStatusBadge
              parStatus={data?.status}
              isAnimating={isFetching || isLoading}
              size="semibold.title.three"
            />
            <SowSubmittedByTooltip user={data?.user} status={data?.status} />
          </HStack>
          {/* Name and Edit Button */}
          <Typography type="title2">{data?.name || "Scope of Work"}</Typography>
          {permissions.manage_sow ? (
            <TouchableOpacity onPress={() => setEditing(true)}>
              <PencilSolid color={colors.primary} />
            </TouchableOpacity>
          ) : null}
        </HStack>
      ) : (
        <Formik
          initialValues={{
            name: data?.name,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Please enter a name"),
          })}
          onSubmit={async (values) => {
            await updateSowName(values, data);
          }}
        >
          <HStack align="center">
            <FormikTextInputField name="name" label="Name" required />
            <Button
              size="x-small"
              variation="plain"
              onPress={() => setEditing(false)}
            >
              Cancel
            </Button>
            <FormikSubmit
              label="Save"
              submittingLabel="Saving..."
              size="x-small"
            />
          </HStack>
        </Formik>
      )}
      {/* Buttons for Ready For Review/Complete SOW */}
      <HStack spacing={16} align="center">
        {permissions.manage_sow ? (
          <>
            <SowReadyForReviewButton />
            <SowCompleteButton />
            <Tooltip
              name="invalid_info_tooltip"
              title={
                data?.show_pricing
                  ? "Pricing is visible to the client"
                  : "Pricing is hidden from the client"
              }
              placement="bottom"
            >
              <View>
                <DollarSign
                  color={
                    data?.show_pricing ? colors.success : colors.disabledDark
                  }
                  size={20}
                />
              </View>
            </Tooltip>
            <Tooltip
              name="invalid_info_tooltip"
              title={
                data?.reminder_interval
                  ? `Reminder interval is set to ${data.reminder_interval} day${parseInt(data.reminder_interval) > 1 ? "s" : ""} after the SOW is marked Ready for Review.`
                  : "No reminder interval set"
              }
              placement="bottom"
            >
              <View>
                <Email
                  color={
                    data?.reminder_interval
                      ? colors.success
                      : colors.disabledDark
                  }
                  size={20}
                />
              </View>
            </Tooltip>
          </>
        ) : null}
      </HStack>
    </HStack>
  );
};

const styles = StyleSheet.create({
  header: {
    width: "100%",
    flex: 1,
    paddingRight: 16,
  },
});
