import * as React from "react";
import { View, StyleSheet } from "react-native";

import { TextLoading } from "@smartrent/ui";

const _LoadingRow: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <View style={styles.root}>
      <TextLoading width="100%" />
    </View>
  );
};

export const LoadingRow = React.memo(_LoadingRow);

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    padding: 16,
  },
});
