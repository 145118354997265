import { FormikTextInputField, FormikSelectField } from "@smartrent/ui";

type FormikSelectFieldOptions = { label: string; value: any };
export interface FormikDisableSelectFieldProps {
  name: string;
  label: string;
  options: FormikSelectFieldOptions[];
  required: boolean;
  isTextField: boolean;
  textDisabled: boolean;
  selectDisabled?: boolean;
  assistiveText?: string;
}

export const FormikSelectOrInputField = ({
  name,
  label,
  options,
  required,
  isTextField,
  textDisabled,
  selectDisabled,
  assistiveText,
}: FormikDisableSelectFieldProps) => {
  return (
    <>
      {isTextField ? (
        // Using FormikTextInputField instead of FormikStaticField to make disabled field visuals consistent
        <FormikTextInputField
          name={name}
          label={label}
          required={required}
          disabled={textDisabled}
          assistiveText={assistiveText}
        />
      ) : (
        <FormikSelectField
          required={required}
          name={name}
          label={label}
          options={options}
          disabled={selectDisabled}
          assistiveText={assistiveText}
        />
      )}
    </>
  );
};
