import * as React from "react";

import { Grid, Skeleton } from "@smartrent/ui";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

import { HubRefurbishJobVirtualBox } from "@/pages/hub-refurbish/hub-refurbish-job/HubRefurbishJobVirtualBox";

import { ColHeader } from "@/pages/hub-refurbish/grid/ColHeader";
import { RowHeader } from "@/pages/hub-refurbish/grid/RowHeader";

import { useVirtualGridHook } from "@/pages/hub-refurbish/hooks/useVirtualGridHook";

export const HubRefurbishJobVirtualGrid: React.VFC = () => {
  const { isCriticalError, hubsDict } = useHubRefurbishContext();
  const {
    numColumns,
    numColumnsArr,
    transposedSerials,
    width,
    marginLeft,
    gap,
    chipSize,
    fontSize,
    boxHeight,
    boxWidth,
    styles,
  } = useVirtualGridHook();

  if (numColumns === 0) {
    return null;
  }

  return (
    <Grid
      numColumns={numColumns + 1}
      gap={gap}
      style={[styles.grid, { width }, { marginLeft }]}
    >
      {/* top left corner */}
      {numColumns > 0 ? <Skeleton /> : null}

      {/* column headers */}
      {numColumns > 0
        ? numColumnsArr.map((col, index) => {
            return <ColHeader key={`col-header-${index}`} index={index} />;
          })
        : null}

      {/* row headers + actual hubs */}
      {transposedSerials.map((rowOfSerials, rowIndex) => {
        return rowOfSerials.map((serial, colIndex) => {
          return serial === null || serial === undefined ? (
            <Skeleton key={`placeholder-${rowIndex}-${colIndex}`} />
          ) : serial === "header" ? (
            <RowHeader
              key={`row-header-${rowIndex}-${colIndex}}`}
              index={rowIndex}
            />
          ) : // set each box to be a glimmering placeholder if critical error is detected
          isCriticalError ? (
            <Skeleton
              key={`row-header-${rowIndex}-${colIndex}}`}
              width={50}
              style={styles.placeholderVisible}
            />
          ) : (
            <HubRefurbishJobVirtualBox
              width={boxWidth}
              key={`hub-refurbish-job-${rowIndex}-${colIndex}-${serial}`}
              serial={serial}
              size={chipSize}
              fontSize={fontSize}
              style={[styles.box, { height: boxHeight, width: boxWidth }]}
              {...(hubsDict && hubsDict[serial] ? hubsDict[serial] : {})}
            />
          );
        });
      })}
    </Grid>
  );
};
