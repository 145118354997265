import { format } from "date-fns-tz";
import { sortBy } from "lodash-es";

export const timezones = sortBy(
  [
    // List of supported timezone names from the IANA timezone database.
    "America/Anchorage",
    "America/Boise",
    "America/Chicago",
    "America/Denver",
    "America/Edmonton",
    "America/Halifax",
    "America/Iqaluit",
    "America/Los_Angeles",
    "America/New_York",
    "America/Phoenix",
    "America/Regina",
    "America/St_Johns",
    "America/Swift_Current",
    "America/Thunder_Bay",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yellowknife",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Helsinki",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Paris",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Pacific/Honolulu",
  ],
  // Sort by GMT offset as a number (-1000, -800, -700, etc.). This will produce
  // a list that is, in general, sorted from west to east starting from the
  // international date line.
  (tz) => Number(format(new Date(), "xx", { timeZone: tz }))
);

export const timezoneOptions: Array<{
  label: string;
  value: string;
}> = timezones.map((tz) => {
  // e.g. GMT-06:00
  const gmtOffset = format(new Date(), "OOOO", { timeZone: tz });

  // e.g. MST, MDT, PST, PDT. Sometimes produces GMT offsets instead, paricularly
  // for non-North American timezones -- this may be due to system locale settings.
  const tzAbbr = format(new Date(), "zzz", { timeZone: tz });

  // Replace underscores in the timezone name, but only for the label
  // const zoneName = tz.replaceAll("_", " ");
  const zoneName = tz.split("_").join(" ");

  return {
    label: `(${gmtOffset}) ${zoneName} (${tzAbbr})`,
    value: tz,
  };
});
