import { useMemo, useState, useEffect, useCallback } from "react";
import { View, StyleSheet } from "react-native";

import {
  Button,
  FormikTextInputField,
  Typography,
  Table,
  ColumnOptions,
} from "@smartrent/ui";

import Layout from "@/layout/Layout";
import { useUser } from "@/layout/Context";

import helpers from "@/lib/helpers";
import { useUrlFilters } from "@/hooks/url-filters";
import { useOrganizationsQuery } from "@/api/organizations";

import { OrganizationProps } from "@/types";
import { useDrawerNav } from "@/common/AppDrawer";

export default function Organizations() {
  const { permissions } = useUser();

  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const drawer = useDrawerNav();

  const { filters, setAllFilters } = useUrlFilters({
    keys: ["name", "inserted_at", "page", "sort", "dir"],
  });

  const { data, isLoading } = useOrganizationsQuery({
    filters,
  });

  useEffect(() => {
    if (data?.records) {
      setTotalPages(data?.total_pages ?? 1);
      setTotalRecords(data?.total_records ?? 0);
    }
  }, [data]);

  const currentPage = Number(filters?.page) || 1;
  const columns: ColumnOptions<OrganizationProps>[] = useMemo<
    ColumnOptions<OrganizationProps>[]
  >(
    () => [
      {
        name: "name",
        header: "Name",
        sortable: true,
        filter: () => <FormikTextInputField name="name" label="Name" />,
        render: ({ row }) => <Typography>{row.name}</Typography>,
      },
      {
        name: "id",
        header: "Created",
        sortable: true,
        render: ({ row }) => (
          <Typography>{helpers.formatDate(row.inserted_at)}</Typography>
        ),
      },
    ],
    []
  );

  const keyExtractor = useCallback((row: OrganizationProps) => `${row.id}`, []);

  const handleGetRowHref = useCallback(
    ({ id }: any) => `/organizations/${id}`,
    []
  );

  // We need this because if you don't pre-define the filter input values,
  // they complain about changing from an uncontrolled to controlled input.
  const fixedFilters = useMemo(
    () => ({ name: filters?.name ?? "", ...filters }),
    [filters]
  );

  const handleCreateOrgButtonClick = useCallback(
    () => drawer.push("createOrganization", {}),
    [drawer]
  );

  return (
    <Layout>
      <View style={styles.container}>
        <Table
          title="Organizations"
          loading={isLoading}
          data={data?.records || []}
          noRecordsText="No Organizations"
          totalRecords={totalRecords}
          totalPages={totalPages}
          currentPage={currentPage}
          columns={columns}
          keyExtractor={keyExtractor}
          getRowHref={handleGetRowHref}
          filters={fixedFilters}
          onFiltersChange={setAllFilters}
          onPageChange={(page) =>
            setAllFilters({
              ...filters,
              page,
            })
          }
          onSortChange={(sort) =>
            setAllFilters({
              ...filters,
              sort,
              dir: filters.dir === "asc" ? "desc" : "asc",
            })
          }
          showClearFiltersButton
          action={
            permissions.update_organization ? (
              <Button onPress={handleCreateOrgButtonClick}>Create</Button>
            ) : null
          }
        />
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
});
