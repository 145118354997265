import { useUnit } from "../UnitContext";

export default function UnitNotes() {
  const { unitNotes } = useUnit();
  if (!(unitNotes && unitNotes.length)) {
    return null;
  }
  return (
    <div style={{ maxWidth: "150px", overflow: "scroll" }} className="u-mtop16">
      <div className="h6">Notes</div>
      <div className="u-line18">
        {unitNotes.map(({ body, id }) => {
          return (
            <div key={id} className="u-font12">
              {body}
            </div>
          );
        })}
      </div>
    </div>
  );
}
