import { StyleSheet } from "react-native";

import { useTheme } from "@smartrent/ui";

import Modal from "../../modal/Modal";

import { UserProps } from "../../../types";

import Header from "./Header";
import { AssignedUsersModalList } from "./AssignedUsersModalList";

interface AssignedUserModalProps {
  show: boolean;
  onClose: () => void;
  headerText: string;
  users: Array<UserProps>;
  statusText: string;
  statusColor: string;
}

export default ({
  show,
  onClose,
  headerText,
  users,
  statusText,
  statusColor,
}: AssignedUserModalProps) => {
  const { colors } = useTheme();
  return (
    <Modal
      titleId="assigned-users-modal"
      style={styles.root}
      isOpen={show}
      onClose={onClose}
    >
      <Modal.Header style={{ borderBottomColor: colors.border }}>
        {Header(headerText, statusText, statusColor)}
      </Modal.Header>
      <Modal.Content scrollable={true}>
        {AssignedUsersModalList(users)}
      </Modal.Content>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    maxHeight: "80vh",
    maxWidth: "90vw",
    paddingBottom: 0,
  },
});
