import { useQueryCompat } from "@smartrent/hooks";

import { useQuery } from "@tanstack/react-query";

import { instance } from "@/lib/hooks";
import { ServerConfigProps } from "@/types";

export const useServerConfigQuery = () =>
  useQueryCompat(["server-config"], async (key: "server-config") => {
    const { data } = await instance.get<any>(`/server-config`);

    return data;
  });

export const useServerConfigQueryApi = (
  select: (data: ServerConfigProps) => any
) =>
  useQuery(
    ["server-config"],
    async () => {
      const { data } = await instance.get<ServerConfigProps>(`/server-config`);
      return data;
    },
    { select }
  );
