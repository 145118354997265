import { useState } from "react";
import { View, StyleSheet } from "react-native";
import { groupBy, upperCase } from "lodash-es";

import { HStack, Typography, useTheme } from "@smartrent/ui";

import { CheckCircle, XCircle } from "@smartrent/icons";

import { PERMISSIONS } from "@/lib/permissions";

import { AccountProps } from "@/types";

/**
 * To update permissions for an account, go to `src/lib/helpers.tsx`
 */
export default function Account(props: { account: AccountProps }) {
  const { colors } = useTheme();
  const [account, setAccount] = useState(props.account);

  const groupedPermissions = groupBy(
    PERMISSIONS,
    (permission) => permission.type
  );

  const PermissionGroup = ({ type, permissions, account, setAccount }) => {
    return (
      <View style={styles.permissionGroup}>
        <View
          style={[
            styles.permissionHeader,
            { borderBottomColor: colors.border },
          ]}
        >
          <Typography type="title4" color="textSecondary">
            {upperCase(type)}
          </Typography>
        </View>
        <View>
          {permissions.map(({ name, type, label, disabled }) => {
            return !disabled ? (
              <View key={`${name}-${type}`} style={styles.alignCheckbox}>
                <View style={styles.alignCheckbox}>
                  {account?.[name] ? (
                    <CheckCircle size={16} color={colors.success} />
                  ) : (
                    <XCircle size={16} color={colors.textDisabled} />
                  )}
                </View>
                <Typography
                  color={account?.[name] ? "textPrimary" : "textDisabled"}
                  style={{ marginLeft: 8 }}
                >
                  {label}
                </Typography>
              </View>
            ) : null;
          })}
        </View>
      </View>
    );
  };

  return (
    <div className="panel">
      <div className="panel__header panel__header--border">
        <Typography type="title3">
          {account.first_name} {account.last_name}{" "}
        </Typography>
        <HStack>
          <Typography style={styles.roleLabel} type="title4">
            Role:
          </Typography>
          <Typography>{account?.name}</Typography>
        </HStack>
      </div>

      <div className="panel__content">
        <div className="h4">Permissions</div>
        <div className="u-flex u-flex-wrap u-mtop8">
          <View style={styles.wrapPermissions}>
            {Object.entries(groupedPermissions).map(([key, value]) => (
              <PermissionGroup
                key={key}
                type={key}
                permissions={value}
                account={account}
                setAccount={setAccount}
              />
            ))}
          </View>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  roleLabel: {
    marginRight: 8,
  },
  permissionGroup: {
    margin: 8,
    width: 325,
    flexWrap: "wrap",
  },
  permissionHeader: {
    borderBottomWidth: 1,
  },
  alignCheckbox: {
    flexDirection: "row",
    alignItems: "center",
  },
  wrapPermissions: { flexDirection: "row", flexWrap: "wrap" },
});
