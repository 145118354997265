import * as React from "react";

import { StyleSheet, View } from "react-native";

import { Box, Typography, useTheme } from "@smartrent/ui";

import { useOrganizationPropertyStaffUsersReportQuery } from "@/api/reports";

import { UsersReport } from "@/common/users/UsersReport";

import { UsersTableLink } from "@/common/users/UsersTableLink";

import { useOrganization } from "./OrganizationContext";

export const OrganizationUsersCard: React.VFC = () => {
  const { colors } = useTheme();
  const {
    organization: { id },
  } = useOrganization();

  const { data } = useOrganizationPropertyStaffUsersReportQuery(
    {
      organizationId: id,
    },
    { enabled: id && id !== null }
  );

  return (
    <Box style={styles.root}>
      {/* CARD HEADING */}
      <View
        style={[
          styles.header,
          {
            backgroundColor: colors.pageBackground,
          },
        ]}
      >
        <Typography type="title3">Property Staff Users</Typography>
      </View>

      {/* CARD BODY */}
      <View style={styles.body}>
        <UsersReport {...data} />
      </View>

      {/* CARD FOOTER */}
      <View style={styles.footer}>
        <UsersTableLink
          search={{
            employee_role: "property_staff",
            organization_id: id,
          }}
        />
      </View>
    </Box>
  );
};
const styles = StyleSheet.create({
  root: { padding: 0 },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 24,
    paddingHorizontal: 12,
  },
  body: {
    flex: 1,
    paddingTop: 8,
    paddingBottom: 16,
    paddingHorizontal: 24,
    borderTopColor: "transparent",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
