import { useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { instance } from "@/lib/hooks";
import { getErrorMessage } from "@/lib/axios-helpers";

import {
  SalesforceDevice,
  ScopeOfWorkDeviceProps,
  OrganizationProps,
  GroupProps,
  ListQueryResponse,
} from "@/types";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import {
  ScopeOfWorkDetailProps,
  ScopeOfWorkProps,
  ScopeOfWorkStatus,
  ScopeOfWorkTemplate,
} from "@/types/ScopeOfWorkProps";

export const SOW_QUERY_KEY = "scope-of-work";

export interface ScopeOfWorkCreateParams {
  group_id: string;
  organization_id: string;
  name: string;
}

// CREATE (SINGLE)
export const useCreateSOWMutation = createAxiosMutation(
  async ({ group_id, organization_id, name }: ScopeOfWorkCreateParams) =>
    instance.post<ScopeOfWorkProps>("/scope-of-work", {
      group_id,
      organization_id,
      name: name || "Scope of Work",
      status: ScopeOfWorkStatus.Draft,
    })
);

export interface ScopeOfWorkParams {
  id: string | number;
  group_id?: string | number;
  name?: string;
  property_id?: string;
}

// UPDATE (SINGLE)
export const useUpdateSOWMutation = createAxiosMutation(
  async ({ id, name, group_id, property_id }: ScopeOfWorkParams) => {
    return instance.patch<ScopeOfWorkProps>(`/scope-of-work/${id}`, {
      name,
      group_id,
      opportunity_property_id: property_id,
    });
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },

    successToast: () => ({
      message: "Successfully updated the SOW!",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
  }
);

// DELETE (SINGLE)
export const useDeleteSOWMutation = createAxiosMutation(
  async ({ id }: ScopeOfWorkParams) => {
    return instance.delete<ScopeOfWorkProps>(`/scope-of-work/${id}`);
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [["scope-of-work-table"]],
      });
    },
    successToast: () => ({
      message: "Successfully deleted the SOW!",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
  }
);

export const useCreateSOWTemplateMutation = createAxiosMutation(
  async ({
    name,
    devices,
    device_order,
  }: {
    name: string;
    devices: ScopeOfWorkDeviceProps[];
    device_order?: string[];
  }) => {
    {
      return instance.post("/scope-of-work/templates", {
        name,
        devices,
        device_order,
      });
    }
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const useUpdateSOWTemplateMutation = createAxiosMutation(
  async ({
    name,
    devices,
    device_order,
  }: {
    name: string;
    devices: ScopeOfWorkDeviceProps[];
    device_order?: string[];
  }) => {
    {
      return instance.put(`/scope-of-work/templates/${name}`, {
        devices,
        device_order,
      });
    }
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const selectQueryPropsOrg = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery(
    (inputValue) => ["orgs", { inputValue }] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey;

      return instance
        .get<ListQueryResponse<OrganizationProps>>(`/organizations`, {
          params: {
            name: inputValue ? inputValue : undefined,
            page: pageParam,
            limit: 25,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsTemplates = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<ScopeOfWorkTemplate>>(
    (inputValue) => ["sow-templates-test", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;

      return instance
        .get(`/scope-of-work/templates`, {
          params: {
            name: inputValue ? inputValue : undefined,
            page: pageParam,
            limit: 25,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsGroups = ({
  selectedOrg,
}: {
  selectedOrg: Partial<OrganizationProps> | undefined;
}) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<ListQueryResponse<GroupProps>>(
    (inputValue) => ["groups", inputValue, selectedOrg?.id] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;
      return instance
        .get(`/groups`, {
          params: {
            marketing_name: inputValue ? inputValue : undefined,
            organization_id: selectedOrg ? selectedOrg.id : undefined,
            active: true,
            page: pageParam,
            limit: 25,
          },
        })
        .then((response) => response.data);
    },
    { enabled: selectedOrg ? true : false },
    {
      inputDebounceInterval: 250,
    }
  );

export interface SalesforceOpportunities {
  Amount: number;
  Description: string;
  FiscalYear: number;
  Id: string;
  IsClosed: boolean;
  IsWon: boolean;
  LeadSource: string;
  Name: string;
  Probability: number;
  SBQQ__PrimaryQuote__c: string;
}

interface useCreateScopeOfWorkDeviceMutationProps {
  scope_of_work_id: ScopeOfWorkProps["id"];
  device: {
    selectedDevice: SalesforceDevice;
    unitAmount: any;
    additionalNotes: any;
  };
  current_order: ScopeOfWorkProps["scope_of_work_devices_order"];
  opportunity?: string;
  category?: string;
}
export const useCreateScopeOfWorkDeviceMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    device,
    current_order,
    opportunity,
    category,
  }: useCreateScopeOfWorkDeviceMutationProps) => {
    return instance.post(`/scope-of-work/${scope_of_work_id}/devices`, {
      ...device,
      current_order,
      opportunity,
      category,
    });
  },
  {
    successToast: () => ({
      message: "Successfully added the device.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

interface useUpdateScopeOfWorkDeviceMutationProps {
  scope_of_work_id: ScopeOfWorkProps["id"];
  device: {
    id: string;
    quantity: string | null;
    notes: string | null;
    sf_product_id: string;
    sf_device_sku: string;
  };
  opportunity?: string;
  category?: string;
}

export const useUpdateScopeOfWorkDeviceMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    device,
    opportunity,
    category,
  }: useUpdateScopeOfWorkDeviceMutationProps) => {
    return instance.patch(
      `/scope-of-work/${scope_of_work_id}/devices/${device.id}`,
      { ...device, opportunity, category }
    );
  },
  {
    successToast: () => ({
      message: "Successfully edited the device.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const useCreateScopeOfWorkReadyForReviewMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    opportunity_id,
  }: {
    scope_of_work_id: ScopeOfWorkProps["id"];
    opportunity_id: ScopeOfWorkProps["sf_opportunity_id"];
  }) => {
    return instance.post(
      `/scope-of-work/${scope_of_work_id}/ready-for-review`,
      { sf_opportunity_id: opportunity_id }
    );
  },
  {
    successToast: () => ({
      message: "Successfully marked the SOW Ready for Review!",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export interface SalesForceOpportunity {
  Id: string;
  Name: string;
  attributes: { type: string; url: string };
}

export const useScopeOfWorkAssignGetOpportunityQuery =
  createAxiosQuery<ScopeOfWorkProps>("salesforce-opportunity", async (sow) => {
    return instance.get<SalesForceOpportunity>(
      `/salesforce/opportunities/${sow?.sf_opportunity_id}`
    );
  });

export const useScopeOfWorkAssignGetPropertyQuery =
  createAxiosQuery<ScopeOfWorkProps>(
    "salesforce-opportunity-property",
    async (sow) => {
      return instance.get<SalesForceOpportunity>(
        `/salesforce/properties/${sow?.opportunity_property_id}`
      );
    }
  );

export type ProductPrice = {
  id: string;
  type: string;
  price: number;
};

interface ProductPriceParams {
  productIds: string[];
  opportunityId: string;
  category: string;
  scopeOfWorkId: string;
}

export const useScopeOfWorkProductsPriceQuery = createAxiosQuery(
  `product-price`,
  async (params?: ProductPriceParams) => {
    if (!params) {
      throw new Error("Missing required parameters.");
    }

    const { scopeOfWorkId, opportunityId, category, productIds } = params;

    return instance.get<ProductPrice[]>(
      `/salesforce/price-of-product/${scopeOfWorkId}/${opportunityId}/${category}`,
      {
        params: {
          product_ids: productIds,
        },
      }
    );
  }
);

export const useScopeOfWorkAssignOpportunityMutation = createAxiosMutation(
  async (sow: Pick<ScopeOfWorkProps, "id" | "sf_opportunity_id">) => {
    return instance.patch(`/scope-of-work/${sow?.id}/assign-opportunity`, {
      sf_opportunity_id: sow?.sf_opportunity_id,
    });
  },
  {
    successToast: () => ({
      message: "Successfully assigned the opportunity.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
      queryClient.invalidateQueries(["salesforce-properties"]);
    },
  }
);

export interface SowTemplateProps {
  scope_of_work_id: string;
  template_id?: string;
}

export const useScopeOfWorkTemplateMutation = createAxiosMutation(
  async ({ scope_of_work_id, template_id }: SowTemplateProps) => {
    return instance.patch(`/scope-of-work/${scope_of_work_id}/template`, {
      template_id,
    });
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

interface useScopeOfWorkAssignDeviceOrderMutationProps {
  scope_of_work_id: ScopeOfWorkProps["id"];
  direction: "up" | "down";
  current_order: ScopeOfWorkProps["scope_of_work_devices_order"];
  index: number;
}
export const useScopeOfWorkAssignDeviceOrderMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    current_order,
    direction,
    index,
  }: useScopeOfWorkAssignDeviceOrderMutationProps) => {
    return instance.patch(`/scope-of-work/${scope_of_work_id}/device-order`, {
      current_order,
      direction,
      index,
    });
  },
  {
    successToast: () => ({
      message: "Successfully updated device order.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const useScopeOfWorkTogglePricingMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    show_pricing,
  }: {
    scope_of_work_id: ScopeOfWorkProps["id"];
    show_pricing: boolean;
  }) => {
    return instance.patch(`/scope-of-work/${scope_of_work_id}/toggle-pricing`, {
      show_pricing,
    });
  },
  {
    successToast: () => ({
      message: "Successfully toggled pricing for this SOW.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const useScopeOfWorkSetReminderIntervalMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    reminder_interval,
  }: {
    scope_of_work_id: ScopeOfWorkProps["id"];
    reminder_interval: string;
  }) => {
    return instance.patch(
      `/scope-of-work/${scope_of_work_id}/reminder-interval`,
      {
        reminder_interval,
      }
    );
  },
  {
    successToast: () => ({
      message: "Successfully updated the reminder interval for this SOW.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const useScopeOfWorkDeleteDeviceMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    device_id,
  }: {
    scope_of_work_id: ScopeOfWorkProps["id"];
    device_id: ScopeOfWorkDeviceProps["id"];
  }) => {
    return instance.delete(
      `/scope-of-work/${scope_of_work_id}/devices/${device_id}`
    );
  },
  {
    successToast: () => ({
      message: "Successfully deleted the device.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const useScopeOfWorkDeleteDeviceCategoryMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    device_category,
    current_order,
  }: {
    scope_of_work_id?: ScopeOfWorkProps["id"];
    device_category: ScopeOfWorkDeviceProps["sf_device_category"];
    current_order: ScopeOfWorkProps["scope_of_work_devices_order"];
  }) => {
    return instance.delete(
      `/scope-of-work/${scope_of_work_id}/devices/${device_category}`,
      { params: { current_order } }
    );
  },
  {
    successToast: () => ({
      message: "Successfully deleted devices.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export const useSubmitRevisionNotesMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    scope_of_work_device_id,
    notes,
  }: {
    scope_of_work_id: string;
    scope_of_work_device_id: string;
    notes: string;
  }) => {
    return instance.post(
      `/scope-of-work/${scope_of_work_id}/devices/${scope_of_work_device_id}/revision`,
      { notes }
    );
  },
  {
    successToast: () => ({
      message: "Successfully added note.",
    }),
    errorToast: (message) => ({
      message: getErrorMessage(message),
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

// API ERRORS ARE STORED IN REDIS CACHE WITH SOW ID IN KEY

// GET (SINGLE)
interface ScopeOfWorkRedisError {
  error?: any;
  context: string;
  scopeOfWorkId: ScopeOfWorkProps["id"];
}

export const getSowCompleteErrors = async ({
  id,
}: Pick<ScopeOfWorkProps, "id">) =>
  (
    await instance.get<ScopeOfWorkRedisError>(
      `/scope-of-work/${id}/complete/errors`
    )
  ).data;

export const useSowCompleteErrorsQuery = (
  id: ScopeOfWorkProps["id"],
  options?: UseQueryOptions<
    ScopeOfWorkRedisError,
    unknown,
    ScopeOfWorkRedisError
  >
) =>
  useQuery(
    [SOW_QUERY_KEY, String(id), "complete-errors"],
    async () => await getSowCompleteErrors({ id }),
    options
  );

// READ (MULTIPLE)
export const useScopeOfWorkQuery = (
  id: ScopeOfWorkProps["id"],
  options?: UseQueryOptions<ScopeOfWorkProps, unknown, ScopeOfWorkProps>
) =>
  useQuery(
    [SOW_QUERY_KEY, String(id)],
    async () => await getSowFn({ id }),
    options
  );

// GET (SINGLE)
export const getSowFn = async ({ id }: Pick<ScopeOfWorkProps, "id">) =>
  (await instance.get<ScopeOfWorkProps>(`/scope-of-work/${id}`)).data;

interface CompleteSowFnProps extends ScopeOfWorkProps {
  productIds: string[];
}
// COMPLETE (SINGLE)
export const completeSowFn = async ({
  id,
  productIds,
  ...variables
}: CompleteSowFnProps) =>
  (
    await instance.post<ScopeOfWorkProps>(
      `/scope-of-work/${id}/complete`,
      variables,
      { params: { productIds } }
    )
  ).data;

// UPDATE STATUS (SINGLE)
export const updateSowFn = async ({ id, ...sow }: ScopeOfWorkProps) =>
  (await instance.patch<ScopeOfWorkProps>(`/scope-of-work/${id}`, sow)).data;

// UPDATE DETAILS (SINGLE)
export const updateSowDetailsFn = async (
  id: ScopeOfWorkProps["id"],
  parDetail: Partial<ScopeOfWorkDetailProps>
) =>
  (
    await instance.put<ScopeOfWorkDetailProps>(
      `/scope-of-work/${id}/details`,
      parDetail
    )
  ).data;

export interface CreateSowDeviceImageProps {
  scope_of_work_id: string;
  sow_device_id: string;
  urls: string[];
}

export const useCreateSowDeviceImagesMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    sow_device_id,
    urls,
  }: CreateSowDeviceImageProps) => {
    return instance.post(
      `/scope-of-work/${scope_of_work_id}/devices/${sow_device_id}/images`,
      {
        urls,
      }
    );
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);

export interface DeleteSowDeviceImageProps {
  scope_of_work_id: string;
  sow_device_id: string;
  imageId: string;
}

export const useDeleteSowDeviceImagesMutation = createAxiosMutation(
  async ({
    scope_of_work_id,
    sow_device_id,
    imageId,
  }: DeleteSowDeviceImageProps) => {
    return instance.delete(
      `/scope-of-work/${scope_of_work_id}/devices/${sow_device_id}/images/${imageId}`
    );
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: [SOW_QUERY_KEY],
      });
    },
  }
);
