import { useContext, useState } from "react";
import { View, Text, StyleSheet } from "react-native";

import { ExclamationCircleOutline } from "@smartrent/icons";

import Context from "@/layout/Context";

import Box from "@/common/Box";
import Button from "@/common/Button";

import Helpers from "@/lib/helpers";
import { instance } from "@/lib/hooks";

import { formatPhone } from "@/utils";

import { VerificationProps, ProspectProps } from "../../types";

import VerifyRow from "./VerifyRow";

export default function ManualVerification({
  originalVerification,
  prospects,
}: {
  originalVerification: VerificationProps;
  prospects: ProspectProps[];
}) {
  const { user, setToast, history } = useContext(Context);

  const [verification, setVerification] = useState(originalVerification);
  const [updatingVerification, setUpdatingVerification] = useState(false);
  const permissions = user.permissions;

  if (!permissions.manual_verifications) {
    return null;
  }

  const cleanName = (name) => {
    if (name) {
      return name.trim().toLowerCase();
      // Handles if the name is null
    } else {
      return "";
    }
  };

  // Checks prospect name against the name given from the verification
  const checkNamesMatch = (prospect) => {
    const prospectFirstName = cleanName(prospect.first_name);
    const prospectLastName = cleanName(prospect.last_name);

    const idFirstName = cleanName(verification.first_name);
    const idLastName = cleanName(verification.last_name);

    return prospectFirstName === idFirstName && prospectLastName === idLastName;
  };

  // Checks all prospect names and determines whether or not to show the warning message
  const showNameNotMatchError = () => {
    // In case there is no prospect information
    if (prospects.length > 0) {
      const result = prospects.every(checkNamesMatch);
      return !result;
    } else {
      return false;
    }
  };

  const verificationNameDetected =
    verification.first_name && verification.last_name;

  return (
    <div>
      <div className="h3">Manual Verification</div>
      <Box style={styles.manualVerificationBox}>
        <View style={styles.manualVerificationMainView}>
          <View style={styles.manualVerifcaitonInformation}>
            <View>
              {verification.verified_at ? (
                <Text style={styles.verificationStatusVerified}>Verified</Text>
              ) : verification.rejected_at ? (
                <Text style={styles.verificationStatusRejected}>Rejected</Text>
              ) : (
                <Text style={styles.verificationStatusPending}>
                  Awaiting Manual Verification
                </Text>
              )}

              <Text>
                Name:{" "}
                {verificationNameDetected
                  ? `${verification.first_name} ${verification.last_name}`
                  : "N/A"}
              </Text>

              <Text>
                Phone Number:{" "}
                {formatPhone({ phone: verification.phone_number })}
              </Text>
              <Text>Date of Birth: {verification.date_of_birth || "N/A"}</Text>
              <Text style={styles.verificationFailedAttempts}>
                {verification.attempts} failed attempts
              </Text>
            </View>
            {verificationNameDetected && showNameNotMatchError() && (
              <View style={styles.nameNotMatchView}>
                <ExclamationCircleOutline
                  size={48}
                  color={Helpers.colors.orange}
                />
                <Text style={styles.nameNotMatchText}>
                  Prospect name(s) above do not match names on the ID.
                </Text>
              </View>
            )}
          </View>
          <VerifyRow
            onUpdate={(front_path: string) =>
              setVerification({ ...verification, front_path })
            }
            type="front"
            verification={verification}
          />
          <VerifyRow
            onUpdate={(back_path: string) =>
              setVerification({ ...verification, back_path })
            }
            type="back"
            verification={verification}
          />
          <VerifyRow
            onUpdate={(selfie_path: string) =>
              setVerification({ ...verification, selfie_path })
            }
            type="selfie"
            verification={verification}
          />

          {!verification.verified_at && !verification.rejected_at && (
            <div className="u-flex u-flex-wrap u-flex-justify-around u-mtop16">
              <Button
                className="button button--symbolic-error u-flex-grow1 u-flex-shrink1 u-mright16"
                disabled={updatingVerification}
                onClick={async () => {
                  if (
                    !updatingVerification &&
                    window.confirm("Are you sure you want to reject this ID?")
                  ) {
                    setUpdatingVerification(true);

                    await instance({
                      url: `/verifications/${verification.id}`,
                      data: { rejected_at: new Date() },
                      method: "PUT",
                    })
                      .then((response) => {
                        setToast({
                          type: "success",
                          title: "Verification Rejected",
                          message: "Rejected verification successfully",
                        });
                        history.push("/tours");
                      })
                      .catch((error) => {
                        alert(
                          "There was an error verifying the image. Please try again"
                        );
                      });
                  }
                }}
              >
                Reject
              </Button>

              <Button
                className="button u-flex-grow1 u-flex-shrink1"
                disabled={updatingVerification || !verification.front_path}
                onClick={async () => {
                  if (
                    !updatingVerification &&
                    window.confirm("Are you sure you want to approve this ID?")
                  ) {
                    setUpdatingVerification(true);

                    await instance({
                      url: `/verifications/${verification.id}`,
                      data: {
                        verified_at: new Date(),
                        manually_verified_at: new Date(),
                        front_path: verification.front_path,
                        back_path: verification.back_path,
                        selfie_path: verification.selfie_path,
                      },
                      method: "PUT",
                    })
                      .then((response) => {
                        setToast({
                          type: "success",
                          title: "Verification Approved",
                          message: "Approved verification successfully",
                        });
                        history.push("/tours");
                      })
                      .catch((error) => {
                        alert(
                          "There was an error verifying the image. Please try again"
                        );
                      });
                  }
                }}
              >
                {!verification.front_path
                  ? "Please Select Front Image To Approve"
                  : "Approve"}
              </Button>
            </div>
          )}
        </View>
      </Box>
    </div>
  );
}

const styles = StyleSheet.create({
  manualVerificationBox: {
    marginBottom: 24,
    paddingTop: 12,
    paddingHorizontal: 12,
    lineHeight: 22,
  },
  manualVerifcaitonInformation: {
    flexDirection: "row",
    alignItems: "center",
  },
  manualVerificationMainView: {
    marginBottom: 12,
  },
  verificationStatusVerified: {
    color: Helpers.colors.green,
    fontWeight: "bold",
  },
  verificationStatusRejected: {
    color: Helpers.colors.red,
    fontWeight: "bold",
  },
  verificationStatusPending: {
    color: Helpers.colors.orange,
    fontWeight: "bold",
  },
  verificationFailedAttempts: { fontWeight: "bold" },
  nameNotMatchView: {
    marginLeft: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  nameNotMatchText: { fontWeight: "bold", marginLeft: 8, fontSize: 16 },
});
