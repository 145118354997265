import Value from "./Value";

export default function LastState({ da, accessCodes }) {
  // attributes where we don't want to display last read state because it has sensitive data in it
  if (["locked", "notifications"].includes(da.name)) {
    return null;
  }

  return (
    <span className="u-font14 u-mright4">
      (
      <Value
        accessCodes={accessCodes}
        attribute={da}
        value={da.last_read_state}
        inserted_at={da.last_read_state_changed_at}
      />
      )
    </span>
  );
}
