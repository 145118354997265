import { View, StyleSheet } from "react-native";

import Alert from "@/common/Alert";

import { formatDateToBeHumanReadable, formatDate } from "@/utils";
import { useGroup } from "@/pages/group/GroupContext";

export const GroupAlerts = () => {
  const { group } = useGroup();

  if (!group.deleted_at) {
    return null;
  }

  return (
    <View style={styles.wrapper}>
      {group.deleted_at && (
        <Alert type="error" className="u-mbottom4">
          Group Deleted -{" "}
          {formatDateToBeHumanReadable({ date: group.deleted_at })} (
          {formatDate({ date: group.deleted_at })})
        </Alert>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: { marginBottom: 16 },
});
