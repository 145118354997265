import { useQuery } from "@tanstack/react-query";

import { Decal, Section, Space, Vehicle } from "@smartrent/parking";

import { instance } from "@/lib/hooks";
import { ResidentProps } from "@/types";

import { getErrorMessage } from "@/lib/axios-helpers";
import { createAxiosMutation } from "@/hooks/react-query";

export type DecalListItem = Pick<
  Decal,
  "id" | "active" | "inserted_at" | "assigned" | "code"
> & {
  deleted_at: string;
  assigned_at: string;
  vehicle: Pick<Vehicle, "license_plate" | "state" | "id">;
  spaces: Pick<Space, "space_number" | "id" | "construction_type">[];
  sections: Pick<Section, "name" | "id">[];
  residents: Pick<ResidentProps, "first_name" | "last_name" | "unit_id">[];
  baseURL: string;
};

interface DecalPaginatedResult {
  records: DecalListItem[];
  total_records: number;
  total_pages: number;
  current_page: number;
}

interface DecalListFilters {
  limit?: number;
  dir?: "asc" | "desc";
}

interface OrderDecalsProps {
  propertyId: string;
  count: number;
  marketingName: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  organizationName: string;
}

export const useDecalsPaginatedQuery = (
  propertyId: string,
  page: number,
  filters?: DecalListFilters
) =>
  useQuery<DecalPaginatedResult>(
    ["decals", propertyId, page, filters],
    async () => {
      const { data } = await instance.get(
        `/parking/properties/${propertyId}/decals`,
        {
          params: { ...filters, page },
        }
      );
      return data;
    },
    {
      enabled: !!propertyId,
      keepPreviousData: true,
    }
  );

export const useOrderDecals = createAxiosMutation(
  async (params: OrderDecalsProps) => {
    const { propertyId } = params;

    return instance.post(`/parking/properties/${propertyId}/decals`, {
      ...params,
    });
  },
  {
    onSuccess: (queryClient, _, params) => {
      queryClient.invalidateQueries(["decals", params.propertyId]);
    },
    successToast: (data, { count }) => ({
      message: `Successfully ordered ${count} decals.`,
    }),
    errorToast: (err) => ({
      message: `Error ordering decals. ${getErrorMessage(err)}`,
    }),
  }
);
