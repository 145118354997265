import { useMemo, useState, useEffect } from "react";
import * as React from "react";
import { View, StyleSheet, ViewStyle } from "react-native";

import { useLatestHubMessagesQuery } from "@/api/hub";

import { ListItemLabelValueCompact } from "@/common/ListItemLabelValueCompact";
import { NoContent } from "@/pages/hub-page/NoContent";

interface HubMessagesProps {
  serial: string;
  fetchData: string;
}
export const HubMessages: React.FC<
  React.PropsWithChildren<HubMessagesProps>
> = ({ serial, fetchData }) => {
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const {
    data: latestMessages,
    isError,
    refetch,
  } = useLatestHubMessagesQuery(
    { serial },
    {
      refetchInterval: shouldRefetch ? 5000 : false,
      onError: () => setShouldRefetch(false),
    }
  );

  useEffect(() => {
    const refetchAndSetInterval = () => {
      refetch();
      setShouldRefetch(true);
    };
    if (fetchData === "hub-messages") {
      refetchAndSetInterval();
    }
  }, [refetch, fetchData]);

  const logs = useMemo(
    () =>
      latestMessages?.total_records > 0 ? latestMessages.records.reverse() : [],
    [latestMessages]
  );

  return (
    <View style={styles.container}>
      {isError ? (
        <NoContent isError={true} />
      ) : (
        logs.map(({ message, timestamp }, key) => (
          <ListItemLabelValueCompact
            key={key}
            value={message}
            timestamp={timestamp}
            style={styles.logItem}
          />
        ))
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxHeight: 500,
    overflow: "auto",
    padding: 16,
  } as Omit<ViewStyle, "overflow">,
  logItem: {
    marginBottom: 8,
  },
});
