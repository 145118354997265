import { useState, useCallback } from "react";
import * as React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from "react-native";

import { Panel, PanelBody, PanelHeader, Typography } from "@smartrent/ui";
import { ChevronDown, ChevronRight } from "@smartrent/icons";

export interface AccordionProps {
  title: string | React.ReactNode;
  type?: "normal" | "compact";
  initialOpenState?: boolean;
  style?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
  isDisabled?: boolean;
  children?: React.ReactNode;
}

export const Accordion = ({
  title,
  children,
  type = "normal",
  initialOpenState = true,
  style,
  headerStyle,
  isDisabled = false,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isDisabled ? false : initialOpenState);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const shouldShowChevrons = useCallback(() => {
    if (isDisabled) {
      return null;
    } else {
      return isOpen ? <ChevronDown /> : <ChevronRight />;
    }
  }, [isDisabled, isOpen]);

  return (
    <Panel style={style}>
      <TouchableOpacity
        disabled={isDisabled}
        onPress={toggleIsOpen}
        style={headerStyle}
      >
        <PanelHeader style={!isOpen && styles.closedHeader}>
          <>
            {typeof title === "string" ? (
              <Typography type={type === "normal" ? "title2" : "title5"}>
                {title}
              </Typography>
            ) : (
              title
            )}

            {shouldShowChevrons()}
          </>
        </PanelHeader>
      </TouchableOpacity>

      {isOpen ? (
        <PanelBody>
          <>{children}</>
        </PanelBody>
      ) : null}
    </Panel>
  );
};

const styles = StyleSheet.create({
  closedHeader: {
    borderBottomWidth: 0,
  },
});
