import { useEffect } from "react";
import * as React from "react";
import { StyleSheet, View, FlatList } from "react-native";
import {
  FlatListFooterLoader,
  Typography,
  useLoadingItems,
  useTheme,
} from "@smartrent/ui";

import { useBreakpoint } from "@/hooks/breakpoints";
import { useFlaggedTasksCount, useTasksFlatList } from "@/api/tasks";
import { JobTask } from "@/types";

import { TaskListItem } from "./TaskListItem";
import { TaskFlagModal } from "./TaskFlagModal";
import { TaskListItemLoading } from "./TaskListItemLoading";
import { TaskImageViewer } from "./TaskImageViewer";
import { EmptyTaskList } from "./EmptyTaskList";
import { ResolveTaskFlagModal } from "./ResolveTaskFlagModal";

interface TasksListProps {
  id: string;
}

function getColumnSizeFromBreakpoint(breakpoint: "xs" | "sm" | "md" | "lg") {
  switch (breakpoint) {
    case "xs":
      return 1;
    case "sm":
      return 1;
    case "md":
      return 2;
    case "lg":
      return 3;
    default:
      return 4;
  }
}

export const TasksList: React.FC<React.PropsWithChildren<TasksListProps>> = ({
  id,
}) => {
  const {
    data: tasks,
    isLoading,
    refreshing,
    onRefresh,
    totalPages,
    totalRecords,
    totalRecordsLoaded,
    onEndReached,
  } = useTasksFlatList(id);

  const loadingItems = useLoadingItems(20);

  const { fonts, colors } = useTheme();
  const size = useBreakpoint();
  const { setCount: setTotalFlaggedTasksCount } = useFlaggedTasksCount();

  useEffect(() => {
    const totalTaskFlags = tasks
      .flatMap((task) => task.task_flags)
      .reduce((total, taskFlag) => {
        if (taskFlag.completed_at === null) {
          return total + 1;
        }

        return total;
      }, 0);

    setTotalFlaggedTasksCount(totalTaskFlags);
  }, [setTotalFlaggedTasksCount, tasks]);

  const numColumns = getColumnSizeFromBreakpoint(size);

  return (
    <View style={styles.container}>
      <Typography style={[styles.headerTitle, fonts.semibold]}>
        Tasks
      </Typography>
      <View style={styles.listContainer}>
        <FlatList
          style={styles.list}
          key={`task-list-${numColumns}`}
          numColumns={numColumns}
          renderItem={({ item }) =>
            isLoading ? (
              <TaskListItemLoading />
            ) : (
              <TaskListItem key={item.id} task={item} numColumns={numColumns} />
            )
          }
          data={isLoading ? (loadingItems as JobTask[]) : tasks}
          onEndReached={onEndReached}
          ListEmptyComponent={EmptyTaskList}
          ListFooterComponent={
            <FlatListFooterLoader
              style={[styles.listFooter, { borderTopColor: colors.border }]}
              totalPages={totalPages}
              totalRecords={totalRecords}
              recordsLoaded={totalRecordsLoaded === totalRecords}
            />
          }
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      </View>
      <TaskFlagModal jobId={id} />
      <ResolveTaskFlagModal />
      <TaskImageViewer />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 32,
    paddingHorizontal: 32,
  },
  listContainer: {
    marginTop: 16,
  },
  headerTitle: {
    fontSize: 18,
  },
  list: {
    height: "calc(100vh - 40px)",
  },
  listFooter: {
    borderTopWidth: 1,
  },
});
