import { useContext } from "react";
import { View, StyleSheet } from "react-native";

// import Skeleton from "react-loading-skeleton";
import { get, find } from "lodash-es";

import Layout from "@/layout/Layout";
import Context from "@/layout/Context";
// import Helpers from "@/lib/helpers";

import ProspectCard from "@/common/ProspectCard";
import ProspectVerificationResults from "@/common/ProspectVerificationResults";

// import CardHeading from "./common/CardHeading";

import { useApi } from "@/lib/hooks";

import {
  ProspectProps,
  GroupProps,
  OrganizationProps,
  TourProps,
  PermissionProps,
  VerificationProps,
  AccountProps,
  ProspectVerificationsProps,
} from "../types";

import ManualVerification from "./verification/ManualVerification";

export default function Verification() {
  const { user, match, setToast, history } = useContext(Context);
  const currentUser: AccountProps = user;

  const permissions: PermissionProps = currentUser.permissions;

  const { response, error } = useApi({
    url: `/verifications/${match.params.verificationId}`,
    trigger: [match.params.verificationId],
  });

  if (get(error, "response.status") === 404) {
    setToast({
      type: "error",
      title: "Verification Not Found",
      message: "We were unable to find that verification record",
    });
    history.push("/tours");
  }

  if (
    !(get(response, "data.verification") && permissions.manual_verifications)
  ) {
    return null;
  }

  const verification: VerificationProps = response.data.verification;
  const prospects: ProspectProps[] = response.data.prospects; // one per group
  const tours: TourProps[] = response.data.tours;
  const groups: GroupProps[] = response.data.groups;
  const organizations: OrganizationProps[] = response.data.organizations;
  const prospectVerifications: ProspectVerificationsProps[] =
    response.data.prospectVerifications;

  return (
    <Layout>
      <View>
        <View style={styles.prospectInfoContainer}>
          <View style={styles.prospectCard}>
            <div>
              <div className="h3">Connected Prospect(s)</div>
            </div>
            {prospects.map((prospect) => {
              const group: GroupProps = find(groups, ({ id }) => {
                return Number(prospect.group_id) === Number(id);
              });

              const organization = find(organizations, ({ id }) =>
                group && group.organization_id
                  ? group.organization_id === Number(id)
                  : false
              );

              const filteredTours = tours.filter(
                ({ prospect_id }) => Number(prospect_id) === Number(prospect.id)
              );

              return (
                <ProspectCard
                  key={prospect.id}
                  prospect={prospect}
                  group={group}
                  organization={organization}
                  tours={filteredTours}
                />
              );
            })}
          </View>
          <View style={styles.prospectVerifications}>
            <ProspectVerificationResults
              prospectVerifications={prospectVerifications}
            />
          </View>
        </View>
        <View style={styles.manualVerificationContainer}>
          <ManualVerification
            originalVerification={verification}
            prospects={prospects}
          />
        </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  prospectInfoContainer: {
    flexDirection: "row",
    flex: 1,
  },
  prospectCard: { margin: 16 },

  prospectVerifications: {
    flexDirection: "column",
    flex: 1,
    marginTop: 8,
  },
  manualVerificationContainer: {
    margin: 16,
  },
});
