import { useContext, useMemo } from "react";
import { View, StyleSheet } from "react-native";

import { Typography, Link, useTheme } from "@smartrent/ui";

import { Hub } from "@smartrent/icons";

import Circle from "@/common/Circle";
import { StatusPill } from "@/common/StatusPill";

import helpers from "@/lib/helpers";
import { HubRequest, HubDetails } from "@/types";

import Context from "@/layout/Context";

function registrationDetails(hub: HubRequest) {
  if (hub.hub_account_id) {
    return "Hub Account";
  } else if (hub.type === "zipato") {
    return "Group Account";
  }

  return null;
}

export const HubHeader = ({ hub }: { hub: HubRequest & HubDetails }) => {
  const { colors } = useTheme();
  const { isDesktop } = useContext(Context);

  const hubRegistrationDetails = useMemo(() => {
    return registrationDetails(hub);
  }, [hub]);

  return (
    <View
      style={isDesktop ? styles.headerContainer : styles.headerContainerMobile}
    >
      <View style={styles.leftContent}>
        <Circle color={hub.online ? colors.success : colors.error} />
        <Link
          href={`/hubs/${hub?.serial}`}
          style={styles.marginLeft}
          type={isDesktop ? "body" : "caption"}
          ellipsizeMode="middle"
          numberOfLines={1}
        >
          {hub.serial}
        </Link>
        {hub ? (
          <StatusPill style={styles.hubType} icon={Hub} type="gray">
            {helpers.getHubType(hub) === "unknown"
              ? "Unknown Hub Type"
              : helpers.getHubType(hub)}
          </StatusPill>
        ) : null}
      </View>
      <View style={isDesktop ? styles.rightContent : styles.rightContentMobile}>
        <Typography style={styles.firmware} color="textSecondary" type="body">
          {hub.firmware}
        </Typography>

        {hubRegistrationDetails ? (
          <Typography style={styles.account} color="textSecondary" type="body">
            {hubRegistrationDetails}
          </Typography>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    flex: 1,
    flexWrap: "wrap",
  },
  headerContainerMobile: {
    flexDirection: "row",
    flex: 1,
  },
  leftContent: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  rightContent: {
    paddingRight: 12,
    alignItems: "flex-end",
  },
  rightContentMobile: {
    paddingRight: 12,
    alignItems: "flex-end",
    flexWrap: "wrap",
  },
  firmware: { fontSize: 12, height: 12 },
  account: { fontSize: 12 },
  marginLeft: { marginLeft: 8 },
  hubType: { marginHorizontal: 8 },
});
