import { useCallback, useMemo } from "react";

import {
  Typography,
  ColumnOptions,
  Table,
  useTableQuery,
  GetQueryKeyFn,
  FormikTextInputField,
} from "@smartrent/ui";

import {
  fetchAlloyInstallUsers,
  InstallUserListProps,
} from "@/api/alloy-install/users";
import { EM_DASH } from "@/utils/chars";
import { DEALER_API_RECORDS_LIMIT, formatPhone } from "@/utils";

interface UsersListProps {
  searchValue?: string;
  group?: any;
}

const keyExtractor = ({ id }: InstallUserListProps) => id;
const getRowHref = ({ id }: InstallUserListProps) =>
  `/alloy-install/users/${id}`;

export default function UsersList({ searchValue, group }: UsersListProps) {
  const columns = useMemo<ColumnOptions<InstallUserListProps>[]>(
    () => [
      {
        header: "First Name",
        name: "first_name",
        render: ({ row }) => <Typography>{row.first_name}</Typography>,
      },
      {
        header: "Last Name",
        name: "last_name",
        render: ({ row }) => <Typography>{row.last_name}</Typography>,
      },
      {
        header: "Phone Number",
        name: "property",
        render: ({ row: { id, phone } }) => (
          <Typography>{phone ? formatPhone({ phone }) : EM_DASH}</Typography>
        ),
      },
      {
        relativeWidth: relativeWidths.email,
        header: "Email",
        name: "email",
        render: ({ row }) => (
          <Typography numberOfLines={1}>{row.email}</Typography>
        ),
      },
      {
        header: "Role",
        name: "role",
        render: ({ row }) => <Typography>{row.role}</Typography>,
      },
      {
        header: "Company",
        name: "company_name",
        render: ({ row }) => <Typography>{row.company?.name}</Typography>,
        filter: () => (
          <FormikTextInputField name="company_name" label="Company Name" />
        ),
      },
      {
        header: "Jobs Assigned",
        name: "jobs_assigned",
        render: ({ row }) => <Typography>{row.job_count || 0}</Typography>,
      },
      {
        header: "Projects Assigned",
        name: "projects_assigned",
        render: ({ row }) => <Typography>{row.project_count || 0}</Typography>,
      },

      // filters
      {
        header: "Name",
        name: "name",
        hidden: true,
        render: () => null,
        filter: () => <FormikTextInputField name="name" label="Name" />,
      },
    ],
    []
  );

  const getQueryKey: GetQueryKeyFn = useCallback(
    ({ filters, page }) => [
      "users",
      {},
      {
        ...(group?.id && { property_remote_id: group?.id }),
        ...(searchValue && { name: searchValue }),
        ...filters,
        page,
      },
    ],
    [group?.id, searchValue]
  );

  const tableProps = useTableQuery({
    columns,
    fetch: fetchAlloyInstallUsers,
    getQueryKey,
    keyExtractor,
    defaultPageSize: DEALER_API_RECORDS_LIMIT,
  });

  return (
    <Table
      title="Users"
      noRecordsText="No users found"
      getRowHref={getRowHref}
      onRowPress={() => {}}
      {...tableProps}
    />
  );
}

const relativeWidths = {
  email: 300,
};
