import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import { transform, startCase } from "lodash-es";
import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerLoader,
  FormikSelectField,
  FormikSubmit,
  FormTitle,
  VStack,
} from "@smartrent/ui";

import { DescriptorSetting, DeviceSetting, UpdateDeviceSetting } from "@/types";
import { FormikTemperatureField } from "@/common/formik/FormikTemperatureField";

interface SettingsFormProps {
  descriptorSettings: DescriptorSetting[];
  deviceSettings: DeviceSetting[];
  onSubmit: (
    values: UpdateDeviceSetting[],
    actions: FormikHelpers<UpdateDeviceSetting[]>
  ) => void;
  onCancel: () => void;
  action?: React.ReactElement;
  isLoading?: boolean;
}

export const DeviceSettingsForm: React.FC<
  React.PropsWithChildren<SettingsFormProps>
> = ({
  onSubmit,
  onCancel,
  descriptorSettings,
  deviceSettings,
  action,
  isLoading = false,
}) => {
  const initialValues = useMemo(() => {
    return transform(
      deviceSettings,
      (memo, { setting_label, current_value_label }) => {
        memo[setting_label] = current_value_label;
      },
      {}
    );
  }, [deviceSettings]);

  return isLoading ? (
    <DrawerLoader />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      // Fields do their own validation
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      enableReinitialize
    >
      <>
        <DrawerContent>
          <View style={styles.header}>
            <FormTitle>Update Device Settings</FormTitle>
            {action}
          </View>
          <VStack spacing={8}>
            {descriptorSettings.map((descriptorSetting) =>
              descriptorSetting.type === "temperature" ? (
                <FormikTemperatureField
                  key={descriptorSetting.name}
                  name={descriptorSetting.name}
                  label={startCase(descriptorSetting.name)}
                  min={descriptorSetting.min}
                  max={descriptorSetting.max}
                  scale={descriptorSetting.scale}
                />
              ) : (
                <FormikSelectField
                  key={descriptorSetting.name}
                  name={descriptorSetting.name}
                  options={descriptorSetting.options.map((o) => ({
                    label: o.key,
                    value: o.key,
                  }))}
                  label={startCase(descriptorSetting.name)}
                />
              )
            )}
          </VStack>
        </DrawerContent>
        <DrawerActions>
          <Button onPress={onCancel} variation="plain">
            Cancel
          </Button>
          <FormikSubmit label="Update" />
        </DrawerActions>
      </>
    </Formik>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
