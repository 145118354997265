import { Typography, useTheme } from "@smartrent/ui";
import { StyleSheet } from "react-native";

export function EmptyTaskList() {
  const { colors, shadows } = useTheme();
  return (
    <Typography
      style={[
        styles.emptyTaskListText,
        shadows.regular,
        { backgroundColor: colors.background, color: colors.gray600 },
      ]}
    >
      No Tasks
    </Typography>
  );
}

const styles = StyleSheet.create({
  emptyTaskListText: {
    paddingVertical: 24,
    fontSize: 24,
    borderRadius: 8,
    textAlign: "center",
  },
});
