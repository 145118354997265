import { useUnit } from "../UnitContext";

export default function UnitStatus() {
  const { unit } = useUnit();

  return (
    <div className="u-mtop12 u-line18">
      <div>
        <span className="u-text-gray-600">Unit Status:</span> {unit.status}
      </div>
      <div>
        <span className="u-text-gray-600">Occupancy:</span>{" "}
        {unit.is_occupied ? "Is Occupied" : "Not Occupied"}
      </div>
    </div>
  );
}
