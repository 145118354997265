// Parking
import { CreateSign } from "@/common/parking/drawer/CreateSign";
import { ParkingQuickActions } from "@/common/parking/drawer/ParkingQuickActions";
import { ParkingPropertySettings } from "@/common/parking/drawer/ParkingPropertySettings";
import { EditSign } from "@/common/parking/drawer/EditSign";
import { SignDetail } from "@/common/parking/drawer/SignDetail";
import { CreatePaymentConfiguration } from "@/common/parking/drawer/CreatePaymentConfiguration";
import { EditPaymentConfiguration } from "@/common/parking/drawer/EditPaymentConfiguration";
import OrderDecals from "@/common/parking/drawer/OrderDecals";
import SensorActivity from "@/common/parking/drawer/SensorActivityList/";
import MigrateSensor from "@/common/parking/drawer/MigrateSensor";
import SensorActions from "@/common/parking/drawer/SensorActions";
import { EnableParkingMaps } from "@/common/parking/drawer/EnableParkingMaps";
import { UpdateParkingMaps } from "@/common/parking/drawer/UpdateParkingMaps";

// Devices
import { DeviceSettings } from "@/common/devices/drawer/DeviceSettings";
import { ZipatoAccount } from "@/pages/hub-page/drawers/ZipatoAccount";

// Vendor Applications
import { CreateVendorApplication } from "@/common/vendor-applications/drawer/CreateVendorApplication";
import { AssignGroupToVendorApplication } from "@/common/vendor-applications/drawer/AssignGroupToVendorApplication";

// Groups
import { CreateGroup } from "@/common/groups/drawer/CreateGroup";
import { UpdateGroup } from "@/common/groups/drawer/UpdateGroup";
import { ManageWifiAccessPoints } from "@/common/groups/drawer/ManageWifiAccessPoints";
import { GroupReportsDrawer } from "@/common/groups/drawer/GroupReports/GroupReportsDrawer";

// Organizations
import { CreateOrganization } from "@/common/organizations/drawer/CreateOrganization";
import { PropertyTransfer } from "@/common/organizations/drawer/PropertyTransfer";
import { UnitTransfer } from "@/common/organizations/drawer/UnitTransfer";
import { UpdateOrganization } from "@/common/organizations/drawer/UpdateOrganization";
import { PmSyncActions } from "@/common/organizations/drawer/PmSyncActions";
import { AlloyInstallEnvironment } from "@/common/organizations/drawer/AlloyInstallEnvironment";
import { LinkIntegrationToOrg } from "@/common/organizations/drawer/LinkIntegrationToOrg";

// CMW Feature Flags
import { AddFeatureFlagGate } from "@/common/cmw-feature-flags/drawer/AddFeatureFlagGate";

// Scopes of Work
import { ScopeOfWorkRevisions } from "@/common/scope-of-work/drawer/ScopeOfWorkRevisions";

// Account Roles
import { CreateRole } from "@/common/users/drawer/CreateRole";

import { createDrawer } from "./drawer/DrawerManager";
import { LinkIntegrationToGroup } from "./groups/drawer/LinkIntegrationToGroup";

const { Provider: AppDrawer, useDrawerNav } = createDrawer("AppDrawer", {
  routes: {
    // parking
    createSign: { component: CreateSign },
    editSign: { component: EditSign },
    signDetail: { component: SignDetail },
    parkingQuickActions: {
      component: ParkingQuickActions,
      title: "Parking Quick Actions",
    },
    parkingPropertySettings: {
      component: ParkingPropertySettings,
      title: "Property Settings",
    },
    createPaymentConfiguration: {
      component: CreatePaymentConfiguration,
      title: "Parking Quick Actions",
    },
    editPaymentConfiguration: {
      component: EditPaymentConfiguration,
      title: "Manage Payments",
    },
    orderDecals: {
      component: OrderDecals,
      title: "Create Decals",
    },
    sensorActivity: {
      component: SensorActivity,
    },
    migrateSensor: {
      component: MigrateSensor,
    },
    sensorActions: {
      component: SensorActions,
    },
    enableParkingMaps: {
      component: EnableParkingMaps,
      title: "Enable Parking Maps",
    },
    updateParkingMaps: {
      component: UpdateParkingMaps,
      title: "Update Parking Maps",
    },
    // devices
    deviceSettings: { component: DeviceSettings },

    // hub page
    hubSettings: { component: ZipatoAccount },

    // vendor applications
    createVendorApplication: { component: CreateVendorApplication },
    assignGroupToVendorApplication: {
      component: AssignGroupToVendorApplication,
    },

    // orgs page
    createOrganization: { component: CreateOrganization },
    alloyInstallEnvironment: { component: AlloyInstallEnvironment },
    linkIntegrationToOrg: { component: LinkIntegrationToOrg },

    // org page
    createGroup: { component: CreateGroup },
    updateOrganization: { component: UpdateOrganization },
    propertyTransfer: { component: PropertyTransfer },
    unitTransfer: { component: UnitTransfer },
    pmSyncActions: { component: PmSyncActions },
    linkIntegrationToGroup: { component: LinkIntegrationToGroup },

    // group page
    updateGroup: { component: UpdateGroup },
    groupReports: { component: GroupReportsDrawer },

    // CMW Feature Flags
    addFeatureFlagGate: {
      component: AddFeatureFlagGate,
      title: "Add Org/Group",
    },

    // scope of work
    scopeOfWorkRevisions: { component: ScopeOfWorkRevisions },

    // scope of work
    manageWifiAccessPoints: { component: ManageWifiAccessPoints },

    // account roles
    createRole: { component: CreateRole },
  },
});

export { AppDrawer, useDrawerNav };
