import { useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { getErrorMessage } from "@/lib/axios-helpers";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { EmailWebhookView, UserProps } from "@/types";

export const useGetUser = createAxiosQuery(
  "get-user",
  async ({ id }: Partial<UserProps>) => instance.get<UserProps>(`/users/${id}`)
);

export interface UpdateUserEmailPayload {
  email: string;
}
export interface UpdateUserEmailOptions {
  userId: string | number;
  payload: UpdateUserEmailPayload;
}

export const useUpdateUserEmailMutation = createAxiosMutation(
  async ({ userId, payload }: UpdateUserEmailOptions) => {
    return instance.patch<UpdateUserEmailPayload>(
      `/users/${userId}/email-address`,
      payload
    );
  },
  {
    successToast: () => ({
      message: "Email updated. Please refresh to see changes reflected.",
    }),
    errorToast: (err) => ({
      message: `Error updating user email. ${getErrorMessage(err)}`,
    }),
  }
);

export interface UpdateSmartRentUserEmailPayload {
  email: string;
}

export interface UpdateSmartRentUserEmailOptions {
  userId: string | number;
  payload: UpdateSmartRentUserEmailPayload;
}

export const useChangeSmartRentLogin = createAxiosMutation(
  async ({ userId, payload }: UpdateSmartRentUserEmailOptions) => {
    return instance.patch<UpdateSmartRentUserEmailPayload>(
      `/users/${userId}`,
      payload
    );
  },
  {
    successToast: () => ({
      message:
        "SmartRent login updated. Once the user confirms the new email, they will be able to log in with the new email address.",
    }),
    errorToast: (err) => ({
      message: `Error updating user email. ${getErrorMessage(err)}`,
    }),
  }
);

export interface RemoveServiceAccountPayload {
  userId: string | number;
}

export const useRemoveServiceAccount = createAxiosMutation(
  async ({ userId }: RemoveServiceAccountPayload) => {
    return instance.patch<UpdateSmartRentUserEmailPayload>(`/users/${userId}`, {
      service_account: false,
    });
  },
  {
    successToast: () => ({
      message: "SmartRent Service Account removed.",
    }),
    errorToast: (err) => ({
      message: `Error removing user service account. ${getErrorMessage(err)}`,
    }),
  }
);

export interface DeleteUserOptions {
  userId: string | number;
}

export const useDeleteSmartRentUser = createAxiosMutation(
  async ({ userId }: DeleteUserOptions) => {
    return instance.delete(`/users/${userId}`);
  },
  {
    successToast: () => ({
      message: "User deleted. Please refresh to see changes reflected.",
    }),
    errorToast: (err) => ({
      message: `Error deleting user. ${getErrorMessage(err)}`,
    }),
  }
);

export const useDeleteUserTFACredentials = createAxiosMutation(
  async ({ userId }: DeleteUserOptions) => {
    return instance.delete(`/users/${userId}/credentials`);
  },
  {
    successToast: () => ({
      message: "User TFA disabled. Please refresh to see changes reflected.",
    }),
    errorToast: (err) => ({
      message: `Error disabling TFA. ${getErrorMessage(err)}`,
    }),
  }
);

export const useUserAssignedGroupsInfiniteQuery = (
  { id }: UserProps,
  filters
) => {
  return useReducedInfiniteQuery(
    [
      ["user-assigned-groups", { id }],
      {
        ...filters,
      },
    ],
    async ({ pageParam }) => {
      const { data } = await instance.get(`/users/${id}/assigned-groups`, {
        params: { ...filters, page: pageParam },
      });
      return data;
    }
  );
};

export interface GetUserWebhooksResponse {
  webhooks: EmailWebhookView[];
}

export const useGetUserWebhooksQuery = createAxiosQuery(
  "get-user-webhooks-query",
  async ({ id }: UserProps) =>
    instance.get<GetUserWebhooksResponse>(`/users/${id}/webhooks`)
);

export interface LoginActivity {
  id: string;
  verb: string;
  was_the_user_authenticated: string;
  user_agent: string;
  country_code: string;
  device_operating_system: string;
  requested_url: string;
  inserted_at: string;
}

export interface LoginActivityResponse {
  current_page: number;
  records: LoginActivity[];
  total_records: number;
  total_pages: number;
}

export const useGetUserLoginActivity = (userId, options) =>
  useReducedInfiniteQuery(
    ["get-user-login-activity", userId],
    async ({ pageParam = 1 }): Promise<LoginActivityResponse> => {
      return instance
        .get(`/users/${userId}/login-activity?page=${pageParam}`)
        .then((response) => response.data);
    },
    { ...options }
  );
