import { useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";

import { Typography, Box } from "@smartrent/ui";

interface ModuleCardProps {
  name: string;
  icon: React.ReactElement;
  enabled: boolean;
}

export const ModuleCard: React.FC<React.PropsWithChildren<ModuleCardProps>> = ({
  name,
  icon,
  enabled,
}) => {
  const statusText = useMemo(
    () => (enabled ? "Enabled" : "Disabled"),
    [enabled]
  );

  return (
    <Box style={styles.root}>
      <Typography type="title4" color="info" style={styles.header}>
        {name}
      </Typography>

      <View style={styles.iconContainer}>{icon}</View>

      <View style={styles.statusContainer}>
        <Typography color={enabled ? "success" : "error"}>
          {statusText}
        </Typography>
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  root: {
    marginHorizontal: 16,
    marginVertical: 16,
    width: 120,
    height: 160,
  },
  header: {
    flex: 1,
    marginBottom: 16,
    textAlign: "center",
  },
  iconContainer: {
    flex: 1,
    alignItems: "center",
    marginBottom: 16,
  },
  statusContainer: { flex: 1, alignItems: "center" },
});
