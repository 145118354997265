import { useCallback } from "react";
import * as React from "react";
import { FormikHelpers } from "formik";

import { SignHeader } from "@/common/parking/drawer/SignHeader";
import { useSignQuery, useUpdateSignMutation } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";
import { SignForm, SignFormValues } from "@/common/parking/drawer/SignForm";
import { ArchiveSignAction } from "@/common/parking/ArchiveSignAction";

interface EditSignProps {
  signId: string;
  propertyId: string;
}

export const EditSign: React.FC<React.PropsWithChildren<EditSignProps>> = ({
  signId,
  propertyId,
}) => {
  const drawer = useDrawerNav();

  const { data: sign, isLoading } = useSignQuery(signId, propertyId);

  const [updateSign] = useUpdateSignMutation();

  const handleCancel = useCallback(() => drawer.pop(), []);

  const handleSubmit = useCallback(
    async (values: SignFormValues, actions: FormikHelpers<SignFormValues>) => {
      actions.setSubmitting(true);
      await updateSign({
        signId,
        propertyId,
        values,
      });
      actions.setSubmitting(false);
      drawer.pop();
    },
    [drawer, signId, propertyId, updateSign]
  );

  return (
    <>
      <SignHeader signId={signId} propertyId={propertyId} />
      <SignForm
        sign={sign}
        propertyId={propertyId}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        action={
          <ArchiveSignAction
            signId={signId}
            propertyId={propertyId}
            onSuccess={handleCancel}
            variation="plain"
          />
        }
      />
    </>
  );
};
