import { useMemo } from "react";

import { StyleSheet } from "react-native";

import { Chip, useTheme, ChipProps } from "@smartrent/ui";

export const UsersReportChip = ({
  children,
  color,
  size,
  style,
  textStyle,
}: ChipProps) => {
  const { colors } = useTheme();
  const backgroundColor = useMemo(() => {
    switch (color) {
      case "success":
        return colors.success100;
      case "error":
        return colors.error100;
      case "info":
        return colors.info100;
      case "grayscale":
        return colors.gray100;
      case "warning":
        return colors.warning100;
      default:
        return colors.mutedBackgroundError;
    }
  }, [
    color,
    colors.error100,
    colors.gray100,
    colors.info100,
    colors.mutedBackgroundError,
    colors.success100,
    colors.warning100,
  ]);

  const borderColor = useMemo(() => {
    switch (color) {
      case "success":
        return colors.success100;
      case "error":
        return colors.error100;
      case "info":
        return colors.info100;
      case "grayscale":
        return colors.gray100;
      case "warning":
        return colors.warning100;
      default:
        return colors.mutedBackgroundError;
    }
  }, [
    color,
    colors.error100,
    colors.gray100,
    colors.info100,
    colors.mutedBackgroundError,
    colors.success100,
    colors.warning100,
  ]);

  const textColor = useMemo(() => {
    switch (color) {
      case "success":
        return colors.success600;
      case "error":
        return colors.error600;
      case "info":
        return colors.info600;
      case "grayscale":
        return colors.gray600;
      case "warning":
        return colors.warning600;
      default:
        return colors.textPrimary;
    }
  }, [
    color,
    colors.error600,
    colors.gray600,
    colors.info600,
    colors.success600,
    colors.textPrimary,
    colors.warning600,
  ]);

  return (
    <Chip
      disabled
      variation="solid"
      corners="badge"
      color={color}
      size={size}
      style={[styles.root, { backgroundColor, borderColor }, style]}
      textStyle={[styles.text, { color: textColor }, textStyle]}
    >
      {children}
    </Chip>
  );
};

const styles = StyleSheet.create({
  root: { marginRight: 4 },
  text: { fontSize: 16 },
});
