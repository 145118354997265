import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  Button,
  Form,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
} from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";

import { useUpdateOrganizationMutation } from "@/api";
import { useGlobalContext } from "@/layout/Context";

import { OrganizationProps, SolicitError } from "@/types";
import { organizationRequiredMessage } from "@/utils";

import { OrganizationForm } from "../OrganizationForm";

interface UpdateOrganizationProps {
  organization: OrganizationProps;
  refetchOrganization?: Function;
}

export const UpdateOrganization: React.FC<
  React.PropsWithChildren<UpdateOrganizationProps>
> = ({ organization, refetchOrganization }) => {
  const { pop } = useDrawerNav();

  const { setToast } = useGlobalContext();

  const [updateOrganization] = useUpdateOrganizationMutation();

  const initialValues = useMemo<Partial<OrganizationProps>>(
    () => ({
      name: organization?.name ?? "",
      url: organization?.url ?? "",
      logo_url: organization?.logo_url ?? "",
      street_address_1: organization?.street_address_1 ?? "",
      street_address_2: organization?.street_address_2 ?? "",
      city: organization?.city ?? "",
      state: organization?.state ?? "",
      zip: organization?.zip ?? "",
      country: organization?.country ?? "",
    }),
    [organization]
  );

  const validationSchema = Yup.object({
    name: Yup.string().required(organizationRequiredMessage("name")),
    url: Yup.string().required(organizationRequiredMessage("URL")),
    logo_url: Yup.mixed().notRequired(),
  });

  const handleCancel = useCallback(() => pop(), [pop]);

  const handleSubmit = useCallback(
    async (
      values: Partial<OrganizationProps>,
      actions: FormikHelpers<Partial<OrganizationProps>>
    ) => {
      actions.setSubmitting(true);

      try {
        const { id } = organization;
        await updateOrganization({ id, ...values });

        if (typeof refetchOrganization === "function") {
          await refetchOrganization();
        }

        pop();

        setToast({
          type: "success",
          title: "Success",
          message: "Successfully updated organization.",
        });
      } catch (error) {
        // try to display inline form validations using formik
        const solicitErrors = (error as any)?.response?.data?.errors;
        if (solicitErrors && solicitErrors.length) {
          solicitErrors.forEach(({ field, description }: SolicitError) =>
            actions.setFieldError(field, description)
          );
        } else {
          pop();
          console.error(error);
          setToast({
            type: "error",
            title: "Error",
            message: "Something went wrong. Please contact engineering.",
          });
        }
      }
      actions.setSubmitting(false);
    },
    [pop, refetchOrganization, updateOrganization, organization, setToast]
  );

  return (
    <>
      <DrawerHeader title="Update Organization" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <>
            <DrawerContent contentContainerStyle={styles.drawerContent}>
              <Form>
                <OrganizationForm
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Form>
            </DrawerContent>

            <DrawerActions>
              <Button onPress={handleCancel} variation="plain">
                Cancel
              </Button>
              <FormikSubmit label="Save" submittingLabel="Saving..." />
            </DrawerActions>
          </>
        )}
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  drawerContent: { paddingTop: 16 },
});
