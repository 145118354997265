import { useField } from "formik";

import { Typography } from "@smartrent/ui";

import ImageUpload, { UploadcareSettings } from "@/common/formik/ImageUpload";

interface FormikImageUploadProps {
  name: string;
  configuration: UploadcareSettings;
  displayImage?: boolean;
  alt?: string;
}

export const FormikImageUpload = ({
  name,
  displayImage,
  alt,
  ...props
}: FormikImageUploadProps) => {
  const [{ value }, { error }, { setTouched, setValue }] =
    useField<string>(name);

  return (
    <>
      {displayImage && value ? (
        <img
          src={value}
          alt={alt}
          style={{ maxWidth: "100%", marginBottom: 8 }}
        />
      ) : null}
      <ImageUpload
        inputId={name}
        {...props}
        onChange={(imageUrl) => {
          setTouched(true);
          setValue(imageUrl);
        }}
      />
      {error ? <Typography color="error">{error}</Typography> : null}
    </>
  );
};
