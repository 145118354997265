import { View, StyleSheet } from "react-native";

import { useTheme } from "@smartrent/ui";

type DividerProps = {
  color?: string;
};

export default function Divider({ color }: DividerProps) {
  const { colors } = useTheme();

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.divider,
          {
            borderLeftColor: color ?? colors.gray300,
          },
        ]}
      ></View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { height: "100%", alignItems: "center", justifyContent: "center" },
  divider: {
    borderLeftWidth: 1,
    height: "80%",
  },
});
