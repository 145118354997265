import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { startCase } from "lodash-es";

import {
  Typography,
  Box,
  SelectOption,
  ActivityIndicator,
  FlatListItemSeparator,
  ListItemContainer,
  ListItemLabelValue,
  useTheme,
  PanelHeaderTitle,
} from "@smartrent/ui";

import { useGroupLockSettingsQuery } from "@/api";

import { EM_DASH } from "@/utils/chars";

import { useGroup } from "../GroupContext";

export const GroupLockSettings: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { group } = useGroup();
  const { colors } = useTheme();

  const { data: groupLockSettings, isLoading: groupLockSettingsLoading } =
    useGroupLockSettingsQuery({
      groupId: group?.id,
    });

  const items: SelectOption[] = useMemo(
    () => [
      {
        label: "Lock Vacant Units",
        value: groupLockSettings?.data.lock_vacant_units
          ? "Enabled"
          : "Disabled",
      },
      {
        label: "Lock Audio",
        value: groupLockSettings?.data.lock_audio
          ? startCase(groupLockSettings?.data.lock_audio)
          : EM_DASH,
      },
      {
        label: "Privacy Lock",
        value: groupLockSettings?.data.privacy_lock ? "Enabled" : "Disabled",
      },
      {
        label: "Auto-Lock Timeout",
        value: groupLockSettings?.data.auto_lock_timeout
          ? `${groupLockSettings?.data.auto_lock_timeout.toLocaleString()} seconds`
          : EM_DASH,
      },
      {
        label: "Battery Threshold",
        value: groupLockSettings?.data.battery_threshold
          ? `${groupLockSettings?.data.battery_threshold}%`
          : EM_DASH,
      },
    ],
    [groupLockSettings]
  );

  return (
    <Box style={styles.cardStyle}>
      <View
        style={[
          styles.headingWrapper,
          { backgroundColor: colors.pageBackground },
        ]}
      >
        <PanelHeaderTitle title="Lock Settings" />
      </View>
      <View style={styles.cardWrapper}>
        {groupLockSettingsLoading ? (
          <View style={styles.centerAlign}>
            <ActivityIndicator />
          </View>
        ) : groupLockSettings?.data ? (
          <View>
            <FlatList<SelectOption>
              data={items}
              keyExtractor={({ label }) => label}
              renderItem={({ item: { label, value } }) => (
                <ListItemContainer key={label}>
                  <ListItemLabelValue label={label} value={value} />
                </ListItemContainer>
              )}
              ItemSeparatorComponent={FlatListItemSeparator}
            />
          </View>
        ) : (
          <View style={styles.centerAlign}>
            <Typography color="textSecondary" type="title6">
              No lock settings found.
            </Typography>
          </View>
        )}
      </View>
    </Box>
  );
};
const styles = StyleSheet.create({
  headingWrapper: {
    flexDirection: "row",
    height: 72,
    alignItems: "center",
    padding: 24,
  },
  cardWrapper: {
    flex: 1,
    marginBottom: 8,
    borderTopColor: "transparent",
  },
  cardStyle: { padding: 0 },
  centerAlign: { alignItems: "center" },
});
