import { useState } from "react";
import * as React from "react";
import { ScrollView, TouchableOpacity, View, StyleSheet } from "react-native";
import { Hamburger, BellRing } from "@smartrent/icons";
import { Button, Logo, Tooltip, useTheme } from "@smartrent/ui";

import { useManualVerificationsQuery } from "@/api";
import { NineSquareButton } from "@/common/NineSquareButton/NineSquareButton";

import { useGlobalContext, useUser } from "../Context";

import helpers from "../../lib/helpers";

import SearchBar from "./SearchBar";
import SearchResults from "./SearchResults";

const Header: React.FC<React.PropsWithChildren<unknown>> = () => {
  const qs = helpers.qsFromLocation(location); // keyword search /route?q=<search terms>
  const defaultKeywords = Array.isArray(qs.q) ? "" : qs.q || "";

  const { colors, shadows } = useTheme();
  const { sidenavOpen, setSidenavOpen, isDesktop } = useGlobalContext();
  const { permissions } = useUser();

  const [keywords, setKeywords] = useState(defaultKeywords);
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchError, setSearchError] = useState(null);

  const { data } = useManualVerificationsQuery(
    {},
    {
      enabled: !!permissions?.manual_verifications,
      refetchInterval: 30000,
    }
  );

  return (
    <>
      <View style={[styles.headerWrapper, { ...shadows.regular }]}>
        <View
          style={[styles.sideNavWrapper, { backgroundColor: colors.gray800 }]}
        >
          <TouchableOpacity
            onPress={() => setSidenavOpen(!sidenavOpen)}
            style={styles.hamburger}
          >
            <Hamburger color={colors.primary} size={20} />
          </TouchableOpacity>
          <View style={styles.flexGrow}>
            <SearchBar
              setSearchResults={setSearchResults}
              setSearchError={setSearchError}
              keywords={keywords}
              setKeywords={setKeywords}
              setSearchLoading={setSearchLoading}
            />
          </View>
          {data?.length && permissions.manual_verifications ? (
            <View>
              <Tooltip
                name="manual_verification"
                title={`${data?.length} manual verification${
                  data?.length === 1 ? "" : "s"
                } that need${data?.length === 1 ? "s" : ""} to be reviewed.`}
              >
                <Button variation="plain" href="/tours/manual-verifications">
                  <BellRing color={colors.warning} />
                </Button>
              </Tooltip>
            </View>
          ) : null}

          {false && <NineSquareButton />}
          {isDesktop && <Logo width={100} />}
        </View>
      </View>
      {/* TODO: De-couple search from Global Context, use something like Downshift for proper keyboard + accessibility support */}
      {keywords ? (
        <ScrollView style={styles.searchWrapper}>
          <View style={styles.searchResultsWrapper}>
            <SearchResults
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              keywords={keywords}
              setKeywords={setKeywords}
              searchLoading={searchLoading}
              searchError={searchError}
            />
          </View>
          <TouchableOpacity
            style={styles.clickableSearchBackground}
            onPress={() => {
              setKeywords("");
              setSearchResults([]);
              setSearchLoading(false);
            }}
          />
        </ScrollView>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  headerWrapper: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 40,
  },
  sideNavWrapper: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  flexGrow: { flexGrow: 1 },
  clickableSearchBackground: {
    zIndex: 2,
    position: "absolute",
    width: "100%",
    height: "calc(100vh - 40px)",
    backgroundColor: "rgba(0,0,0,.5)",
  },
  searchWrapper: {
    position: "absolute",
    zIndex: 1, // it's the wild wild west out here
    top: 40,
    left: 0,
    width: "100%",
    height: "calc(100% - 40px)",
  },
  searchResultsWrapper: { zIndex: 3 },
  hamburger: {
    padding: 10, // adds a little more hitbox to the icon
  },
});

export default Header;
