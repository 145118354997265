import { useCallback, useMemo } from "react";
import * as React from "react";
import slugify from "slugify";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerLoader,
  FormikSubmit,
  FormikTextInputField,
} from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import { useParkingPropertyQuery, useUpdatePropertyMutation } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";

const validationSchema = Yup.object({
  slug: Yup.string()
    .required("Property Slug is required.")
    .matches(
      /^[a-z0-9-]+$/,
      "slug must consist of lowercase alpha-numeric characters and dashes (-) only."
    ),
});

interface ParkingPropertySettingsProps {
  propertyId: string;
}

export const ParkingPropertySettings: React.FC<
  React.PropsWithChildren<ParkingPropertySettingsProps>
> = ({ propertyId }) => {
  const drawer = useDrawerNav();
  const { permissions } = useUser();

  const { data: property, isLoading } = useParkingPropertyQuery(propertyId);

  const [updateProperty] = useUpdatePropertyMutation();

  const initialSlug = useMemo(() => {
    if (property) {
      return property.slug ?? property.marketing_name ?? "";
    }
    return "";
  }, [property]);

  const makeSlug = useCallback(
    (string: any) => slugify(string, { lower: true, strict: true }),
    []
  );

  const onSubmit = useCallback(
    async (values: any, actions: any) => {
      actions.setSubmitting(true);
      try {
        property.slug = makeSlug(values.slug);

        await updateProperty({ property });

        actions.setSubmitting(false);
        drawer.pop();
      } catch (_e) {
        actions.setSubmitting(false);
        drawer.pop();
      }
    },
    [property]
  );

  return isLoading ? (
    <DrawerLoader />
  ) : permissions.manage_parking && property ? (
    <Formik
      initialValues={{ slug: initialSlug }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <>
        <DrawerContent>
          <FormikTextInputField name="slug" label="Property Slug" required />
        </DrawerContent>
        <DrawerActions>
          <Button
            onPress={() => {
              drawer.pop();
            }}
            variation="outlined"
          >
            Cancel
          </Button>
          <FormikSubmit label="UPDATE" />
        </DrawerActions>
      </>
    </Formik>
  ) : null;
};
