import * as React from "react";

import { Grid, Skeleton } from "@smartrent/ui";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

import { ColHeader } from "@/pages/hub-refurbish/grid/ColHeader";
import { RowHeader } from "@/pages/hub-refurbish/grid/RowHeader";

import { useVirtualGridHook } from "@/pages/hub-refurbish/hooks/useVirtualGridHook";

import { VirtualBoxRedisStats } from "@/pages/hub-refurbish/redis-stats/RedisStatsVirtualBox";

export const RedisStatsVirtualGrid: React.VFC = () => {
  const { hubsDict, isCriticalError } = useHubRefurbishContext();
  const {
    numColumns,
    numColumnsArr,
    transposedSerials,
    width,
    marginLeft,
    gap,
    chipSize,
    fontSize,
    boxHeight,
    boxWidth,
    styles,
  } = useVirtualGridHook();

  if (numColumns === 0) {
    return null;
  }

  return (
    <Grid
      numColumns={numColumns + 1}
      gap={gap}
      style={[styles.grid, { width }, { marginLeft }]}
    >
      {/* top left corner */}
      {numColumns > 0 ? <Skeleton /> : null}

      {/* column headers */}
      {numColumns > 0
        ? numColumnsArr.map((col, index) => {
            return <ColHeader key={`col-header-${index}`} index={index} />;
          })
        : null}

      {/* row headers + actual hubs */}
      {transposedSerials.map((rowOfSerials, rowIndex) => {
        return rowOfSerials.map((serial, colIndex) => {
          switch (true) {
            case serial === null || serial === undefined: {
              return <Skeleton key={`placeholder-${rowIndex}-${colIndex}`} />;
            }
            case serial === "header": {
              return (
                <RowHeader
                  key={`row-header-${rowIndex}-${colIndex}}`}
                  index={rowIndex}
                />
              );
            }
            case isCriticalError: {
              return (
                <Skeleton
                  key={`row-header-${rowIndex}-${colIndex}}`}
                  width={50}
                  style={styles.placeholderVisible}
                />
              );
            }
            default: {
              return (
                <VirtualBoxRedisStats
                  key={`hub-refurbish-job-${rowIndex}-${colIndex}-${serial}`}
                  serial={serial}
                  size={chipSize}
                  fontSize={fontSize}
                  style={[styles.box, { height: boxHeight, width: boxWidth }]}
                  redisStatus={
                    hubsDict && hubsDict[serial] && hubsDict[serial]?.status
                      ? hubsDict[serial].status
                      : null
                  }
                />
              );
            }
          }
        });
      })}
    </Grid>
  );
};
