import { useEffect, useState } from "react";

const DUMMY_ANIMATION_TIMEOUT = 1000; // 1 sec

interface useDummyAnimationHookProps {
  isLoading?: boolean;
  isFetching?: boolean;
  timeout?: number;
  dataUpdatedAt?: number;
}
export const useDummyAnimationHook = ({
  isLoading,
  isFetching,
  timeout = DUMMY_ANIMATION_TIMEOUT,
  dataUpdatedAt,
}: useDummyAnimationHookProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(dataUpdatedAt);
  // disable refresh button for few seconds to show activity animation instead
  useEffect(() => {
    let loader;
    if (isLoading === undefined && isFetching === undefined) {
      return () => null;
    }

    if (!isLoading && !isFetching) {
      loader = setTimeout(() => {
        setIsAnimating(false);
        setUpdatedAt(dataUpdatedAt + timeout);
      }, timeout);
    }

    return () => {
      clearTimeout(loader);
      setIsAnimating(true);
    };
  }, [isLoading, isFetching, timeout, dataUpdatedAt]);

  return { isAnimating, updatedAt };
};
