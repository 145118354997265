import { useCallback } from "react";
import {
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikIntlPhoneInputField,
  FormikSelectField,
  FormikSubmit,
  FormikTextInputField,
  Button,
} from "@smartrent/ui";
import { Form, Formik, FormikHelpers } from "formik";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PaginatedResponse } from "@smartrent/hooks";
import * as Yup from "yup";

import { useDrawerNav } from "@/common/AppDrawer";
import { OrganizationProps, SolicitError, UserProps } from "@/types";
import { instance } from "@/lib/hooks";
import { useGlobalContext } from "@/layout/Context";
import { companiesQueryKeys } from "@/api/alloy-install/companies";

export function AlloyInstallEnvironment(props: AlloyInstallEnvironmentProps) {
  const drawer = useDrawerNav();
  const queryClient = useQueryClient();
  const { setToast } = useGlobalContext();

  const { data: admins, isError } = useQuery({
    queryKey: [
      "users",
      {
        organization_id: props.organization.id,
        employee_role: "organization_admin",
      },
    ],
    queryFn: ({ queryKey: [_key, params] }) =>
      instance
        .get<PaginatedResponse<UserProps>>("/users", {
          params,
        })
        .then((res) => res.data),
  });

  const handleSubmit = useCallback(
    async (
      {
        existing_organization_id,
        existing_organization_name,
        select_alloy_install_admin,
        org_admin,
        admin_first_name,
        admin_last_name,
        admin_email,
        admin_phone,
      }: AlloyInstallEnvironmentFormData,
      { setFieldError }: FormikHelpers<AlloyInstallEnvironmentFormData>
    ) => {
      try {
        const adminUser =
          select_alloy_install_admin === "org_admin" && org_admin
            ? {
                admin_first_name: org_admin.first_name,
                admin_last_name: org_admin.last_name,
                admin_email: org_admin.email,
                admin_phone: org_admin.mobile_phone,
              }
            : {
                admin_first_name: admin_first_name,
                admin_last_name: admin_last_name,
                admin_email: admin_email,
                admin_phone: admin_phone,
              };

        await instance.post("alloy-install/companies", {
          organization_name: existing_organization_name,
          organization_id: existing_organization_id,
          ...adminUser,
        });

        drawer.pop();
        setToast({
          type: "success",
          title: "Success",
          message: "Created an Alloy Install Environment",
        });
        queryClient.invalidateQueries(companiesQueryKeys.all);
      } catch (error: any) {
        const solicitErrors = error?.response?.data?.errors;
        if (solicitErrors && solicitErrors.length) {
          solicitErrors.forEach(({ field, description }: SolicitError) =>
            setFieldError(field, description)
          );
        } else {
          drawer.pop();
          setToast({
            type: "error",
            title: "Error",
            message: `Something went wrong: ${
              error.message || "Please contact engineering."
            }`,
          });
        }
      }
    },
    [drawer, setToast]
  );

  return (
    <>
      <DrawerHeader title="Create AI Environment" />
      <Formik<AlloyInstallEnvironmentFormData>
        validationSchema={validationSchema}
        initialValues={initialValues(props)}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <>
            <DrawerContent>
              <Form>
                <FormikTextInputField
                  name="existing_organization_name"
                  assistiveText="Existing Organization Name"
                  label="Name"
                  disabled
                />
                <FormikTextInputField
                  name="existing_organization_id"
                  assistiveText="Existing Organization ID"
                  label="ID"
                  disabled
                />
                <FormikSelectField
                  name="select_alloy_install_admin"
                  label="Admin"
                  assistiveText="Select Alloy Install Admin"
                  options={Array.from(selectAlloyInstallAdminOptions)}
                  required
                />
                {values.select_alloy_install_admin === "org_admin" ? (
                  <FormikSelectField
                    name="org_admin"
                    label="Admin"
                    options={
                      admins?.records.map((u) => ({
                        label: u.email,
                        value: u,
                      })) || []
                    }
                    required
                    error={isError ? "Error fetching org admins" : undefined}
                  />
                ) : null}
                {values.select_alloy_install_admin === "create_admin" ? (
                  <>
                    <FormikTextInputField
                      name="admin_first_name"
                      label="First name"
                      required
                    />
                    <FormikTextInputField
                      name="admin_last_name"
                      label="Last name"
                      required
                    />
                    <FormikTextInputField
                      name="admin_email"
                      label="Email"
                      required
                    />
                    <FormikIntlPhoneInputField
                      name="admin_phone"
                      label="Phone"
                      required
                    />
                  </>
                ) : null}
              </Form>
            </DrawerContent>
            <DrawerActions>
              <Button onPress={drawer.pop} variation="plain">
                Close
              </Button>
              <FormikSubmit label="Create" submittingLabel="Creating..." />
            </DrawerActions>
          </>
        )}
      </Formik>
    </>
  );
}

interface AlloyInstallEnvironmentProps {
  organization: OrganizationProps;
}

interface AlloyInstallEnvironmentFormData {
  existing_organization_name: string;
  existing_organization_id: string;
  select_alloy_install_admin:
    | (typeof selectAlloyInstallAdminOptions)[number]["value"]
    | null;
  org_admin: UserProps | null;
  admin_first_name: string;
  admin_last_name: string;
  admin_email: string;
  admin_phone: string;
}

const selectAlloyInstallAdminOptions = [
  {
    label: "Use Existing Organization Admin",
    value: "org_admin",
  },
  { label: "Create Admin", value: "create_admin" },
] as const;

const initialValues = ({ organization }: AlloyInstallEnvironmentProps) => ({
  existing_organization_name: organization.name,
  existing_organization_id: organization.id,
  select_alloy_install_admin: null,
  org_admin: null,
  admin_first_name: "",
  admin_last_name: "",
  admin_email: "",
  admin_phone: "",
});

const exactly = (target: string) => (value: string) => value === target;
const requiredMessage = (name: string) => `Please enter a valid ${name}`;

const validationSchema = Yup.object({
  existing_organization_name: Yup.string().required(
    requiredMessage("Organization Name")
  ),
  existing_organization_id: Yup.string().required(
    requiredMessage("Organization Id")
  ),
  select_alloy_install_admin: Yup.string().required(requiredMessage("choice")),
  org_admin: Yup.object()
    .nullable()
    .when("select_alloy_install_admin", {
      is: exactly("org_admin"),
      then: Yup.object().required(requiredMessage("Organization Admin")),
    }),
  admin_first_name: Yup.string().when("select_alloy_install_admin", {
    is: exactly("create_admin"),
    then: Yup.string().required(requiredMessage("First Name")),
  }),
  admin_last_name: Yup.string().when("select_alloy_install_admin", {
    is: exactly("create_admin"),
    then: Yup.string().required(requiredMessage("Last Name")),
  }),
  admin_email: Yup.string().when("select_alloy_install_admin", {
    is: exactly("create_admin"),
    then: Yup.string().email().required(requiredMessage("Email")),
  }),
  admin_phone: Yup.string(),
});
