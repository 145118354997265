import { instance } from "@/lib/hooks";
import { getErrorMessage } from "@/lib/axios-helpers";
import { createAxiosMutation } from "@/hooks/react-query";

export const useRotateSigningKeys = createAxiosMutation(
  async () => {
    return instance.post(`/keys/signing/rotate`);
  },
  {
    successToast: (message) => ({
      message: "Key rotated",
    }),
    errorToast: (err) => ({
      message: `Error rotating keys. ${getErrorMessage(err)}`,
    }),
  }
);
