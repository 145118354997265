import { View, StyleSheet } from "react-native";

import { Chip, HStack, Typography } from "@smartrent/ui";
import { InstallDetails as InstallDetailsType } from "@smartrent/install";
import { startCase } from "lodash-es";

const getStatusBadgeStyle = (index: number) => ({
  marginLeft: index > 0 ? 4 : 0,
});

export function InstallDetails({
  install_detail,
}: {
  install_detail: InstallDetailsType;
}) {
  return (
    <View style={styles.installDetails}>
      <Typography style={styles.installTypeLabel}>Install Type:</Typography>
      <Typography style={styles.installTypeTitle}>
        {startCase(install_detail?.install_type)}
      </Typography>
      {install_detail ? (
        <HStack wrap>
          {install_detail?.device_types?.map((deviceType, index) => (
            <View style={styles.badge} key={deviceType}>
              <Chip
                variation="outlined"
                key={deviceType}
                style={getStatusBadgeStyle(index)}
              >
                {startCase(deviceType)}
              </Chip>
            </View>
          ))}
        </HStack>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  badge: {
    marginTop: 8,
  },
  installDetails: {
    justifyContent: "center",
    height: "100%",
    flex: 1,
    maxWidth: 400,
  },
  installTypeLabel: {
    fontSize: 14,
  },
  installTypeTitle: { fontWeight: "600", fontSize: 22 },
});
