import * as React from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "@smartrent/ui";

import { useHubHealth } from "@/api/group";

import { HubHealthReport } from "./HubHealthReport";

/**
 * Responsible for fetching the data from the API and passing the data to the HubHealthReport component.
 */
export const HubHealthReportContainer: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const params = useParams<{ groupId: string }>();
  const { data, isLoading } = useHubHealth(params.groupId);

  return isLoading ? (
    <Skeleton textType="title" />
  ) : (
    <HubHealthReport {...data} />
  );
};
