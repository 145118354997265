import { useMemo } from "react";

import { groupBy, isEmpty } from "lodash-es";

import { useThirdPartyProvidersCredentialsQuery, useTppViewQuery } from "@/api";
import { ThirdPartyProviderItem } from "@/pages/group/cards/group-integrations/GroupIntegrationsListItem";

export interface useTppViewProvidersHookProps {
  tppc_scope: string;
  groupId: number;
  organizationId: number;
}

export const useTppViewHook = ({
  tppc_scope,
  groupId,
  organizationId,
}: useTppViewProvidersHookProps) => {
  const { data, isLoading, isError } = useTppViewQuery({
    filters: {
      tppc_scope,
      sortBy: "provider",
      orderBy: "asc",
      limit: 9999,
      groupId,
      organizationId,
    },
  });

  const providers = useMemo<ThirdPartyProviderItem[]>(() => {
    if (isLoading || isError || isEmpty(data) || !data?.records?.length) {
      return [];
    }

    // map the tpp_view records to what ListItem component expects
    const mappedProviders: ThirdPartyProviderItem[] = data?.records?.map(
      (tppView) => {
        return {
          custom_provider_configuration: tppView.custom_provider_configuration,
          enabled: tppView.enabled,
          locked: tppView.locked,
          provider_id: tppView.provider_id,
          provider_name: tppView.provider,
          integration_level: tppView.tppc_scope,
        };
      }
    );

    // sort by enabled integrations so they appear at the top
    const sortedProviders = mappedProviders.sort(
      ({ enabled: a }, { enabled: b }) => Number(b) - Number(a)
    );

    return sortedProviders;
  }, [isLoading, isError, data]);

  return { providers, data, isError, isLoading };
};

// Goal is to return tpp_view records with credentials props
// The credentials are encrypted in CMW db,
//  so we have to call endpoint to get them in plain text
export const useTppViewMapCredentialsHook = ({
  data,
  groupId,
  enabled = false, // default to disabled since we use a feature flag for this hook
}: {
  data: any;
  groupId?: any;
  enabled?: boolean;
}) => {
  const providerIds = useMemo<string[]>(
    () => (data || []).map(({ provider_id }) => String(provider_id)),
    [data]
  );

  // API CALL - fetching providers with credentials
  const {
    data: providersCredentialsQueryData,
    isLoading,
    isError,
  } = useThirdPartyProvidersCredentialsQuery(
    {
      groupId,
      filters: { provider_ids: providerIds },
    },
    { enabled: enabled && providerIds?.length > 0 }
  );

  // we want to merge the 'credentials' prop to each of our integration providers
  const providersWithCredentials = useMemo<
    { provider_name: string; credential?: any }[]
  >(() => {
    if (isEmpty(data) || isEmpty(providersCredentialsQueryData)) {
      return [];
    }

    // build a dictionary with provider_names as key, helps my brain merge credentials easier
    const credsToMerge = groupBy(
      providersCredentialsQueryData?.data?.providers,
      ({ provider_name }) => provider_name
    );

    // For example
    //
    // 1. our original data has obj containing the Entrata provider
    // data = [{provider_name: "Entrata"}, ...]
    //
    // 2. our dictionary we just made, has credentials for Entrata provider
    // credsToMerge = {"Entrata" => [{credentials: {..}, configurations: [..]}]}
    //
    // 3. if "Entrata" exists in our dictionary, we merge in the credentials prop
    // credentials = credsToMerge["Entrata"].credentials
    //
    const mergedData = data.map(({ provider_name, ...props }) => {
      const credential =
        !credsToMerge[provider_name] ||
        !credsToMerge[provider_name].length ||
        !credsToMerge[provider_name][0]?.credential
          ? // if no creds, set to empty
            {}
          : // otherwise, use the credentials from the api response
            credsToMerge[provider_name][0]?.credential;

      return { provider_name, ...props, credential };
    });

    return mergedData;
  }, [data, providersCredentialsQueryData]);

  return { providersWithCredentials, isLoading, isError };
};
