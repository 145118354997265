import { useState, useMemo, useCallback } from "react";
import { View, TouchableHighlight, useWindowDimensions } from "react-native";

import { Typography, useTheme, ColumnOptions } from "@smartrent/ui";

import { useUnitHealthReportTableQuery } from "@/api/reports";

import { UrlControlledReactQueryTable, GetQueryKeyFn } from "@/common/sr-table";

import { UnitProps } from "@/types/UnitProps";

import { useGroup } from "../GroupContext";

const filters = [
  { label: "All", value: "unhealthy" },
  { label: "Missing Codes", value: "missingcodes" },
  { label: "Hub Offline", value: "huboffline" },
  { label: "Incomplete Installs", value: "incompleteinstalls" },
  { label: "Devices Offline", value: "devicesoffline" },
  { label: "Warnings", value: "warnings" },
];

interface Props {
  searchValue: string;
}

export const GroupHealthSection = ({ searchValue }: Props) => {
  const { group } = useGroup();
  const { colors } = useTheme();
  const { width } = useWindowDimensions();

  const [statusFilter, setStatusFilter] = useState("unhealthy");

  const getStatus = (field) => {
    if (field.missing_resident_access_codes) {
      const pluralizer = field.missing_resident_access_codes !== 1 ? "s" : "";
      return <Typography color="pink">res code{pluralizer} missing</Typography>;
    } else if (field.hub_offline) {
      return <Typography color="error">Hub Offline</Typography>;
    } else if (field.incomplete_installs) {
      return (
        <Typography color="error">
          {field.incomplete_installs} incomplete installs
        </Typography>
      );
    } else if (field.devices_offline) {
      const pluralizer = field.devices_offline !== 1 ? "s" : "";
      return (
        <Typography color="deepOrange">
          {field.devices_offline} device{pluralizer} offline
        </Typography>
      );
    } else if (field.warnings) {
      const pluralizer = field.warnings !== 1 ? "s" : "";
      return (
        <Typography color="orange">
          {field.warnings} device{pluralizer} warning
        </Typography>
      );
    } else {
      return <Typography>Unknown</Typography>;
    }
  };

  const columns = useMemo<ColumnOptions<UnitProps>[]>(
    () => [
      {
        header: "Unit Name",
        name: "name",
        render: ({ row }) => <Typography>{row.marketing_name}</Typography>,
      },
      {
        header: "Problem",
        name: "problem",
        render: ({ row }) => getStatus(row),
      },
    ],
    []
  );

  const keyExtractor = (row: UnitProps) => `${row.id}`;

  const getQueryKey: GetQueryKeyFn = useCallback(
    ({ filters, page }) => [
      "unit-health-report",
      {
        page,
        ...filters,
        groupId: group.id,
        unitName: searchValue,
        status: statusFilter,
      },
    ],
    [group.id, searchValue, statusFilter]
  );

  const handleGetRowHref = useCallback((item: any) => `/units/${item.id}`, []);

  return (
    <>
      <View
        style={{
          flexDirection: width > 700 ? "row" : "column",
          display: "flex",
          marginTop: 16,
        }}
      >
        {filters.map((filter) => (
          <TouchableHighlight
            key={filter.value}
            style={{
              flexDirection: width > 700 ? "row" : "column",
              alignItems: "center",
              justifyContent: "space-around",
              flexGrow: 1,
            }}
            onPress={(e) => setStatusFilter(filter.value)}
            activeOpacity={0.8}
            underlayColor="transparent"
          >
            <Typography
              style={{
                color:
                  filter.value === statusFilter ? colors.info : colors.gray400,
              }}
              font={filter.value === statusFilter ? "bold" : undefined}
            >
              {filter.label}
            </Typography>
          </TouchableHighlight>
        ))}
      </View>
      <UrlControlledReactQueryTable
        columns={columns}
        keyExtractor={keyExtractor}
        getQueryKey={getQueryKey}
        fetch={useUnitHealthReportTableQuery}
        getRowHref={handleGetRowHref}
      />
    </>
  );
};
