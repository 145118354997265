import { useCallback, useMemo } from "react";

import { Link, Typography, ColumnOptions } from "@smartrent/ui";

import helpers from "@/lib/helpers";
import { useUser } from "@/layout/Context";

import { UrlControlledReactQueryTable, GetQueryKeyFn } from "@/common/sr-table";

import { fetchScheduledTours } from "@/api/tours";

import { useUnit } from "./UnitContext";

export const TourCard = function TourCard() {
  const { permissions } = useUser();
  const { unit } = useUnit();

  const keyExtractor = useCallback((row: any) => `${row.id}`, []);

  const columns = useMemo<ColumnOptions<any>[]>(
    () => [
      {
        name: "id",
        header: "ID",
        render: ({ row }) => <Typography>{`${row?.id}`}</Typography>,
        sortable: true,
        hidden: !permissions.view_technical_details,
      },
      {
        name: "scheduled_start",
        header: "Scheduled Start",
        render: ({ row }) => {
          return (
            <Typography>{helpers.formatDate(row.scheduled_start)}</Typography>
          );
        },
        sortable: true,
      },
      {
        name: "scheduled_end",
        header: "Scheduled End",
        render: ({ row }) => (
          <Typography>{helpers.formatDate(row.scheduled_end)}</Typography>
        ),
        sortable: true,
      },
      {
        name: "prospect",
        header: "Prospect",
        render: ({ row }) => (
          <Typography>
            {row?.prospect?.first_name || row?.prospect?.last_name ? (
              <Link href={`/prospects/${row.prospect.id}`} target="_blank">
                {row?.prospect?.first_name} {row?.prospect?.last_name}
              </Link>
            ) : (
              "\u2014"
            )}
          </Typography>
        ),
        sortable: false,
      },
      {
        name: "geolocation_required",
        header: "Geolocation Required",
        render: ({ row }) =>
          row?.geolocation_required ? (
            <Typography>Yes</Typography>
          ) : (
            <Typography>No</Typography>
          ),
        sortable: false,
      },
      {
        name: "access_code_viewed_at",
        header: "Access Code Viewed At",
        render: ({ row }) =>
          row?.access_codes_viewed_at ? (
            <Typography>
              {helpers.formatDate(row.access_codes_viewed_at)}
            </Typography>
          ) : (
            <Typography>&mdash;</Typography>
          ),

        sortable: false,
      },
      {
        name: "sms_sent_at",
        header: "SMS Sent At",
        render: ({ row }) =>
          row?.sms_sent_at ? (
            <Typography>{helpers.formatDate(row.sms_sent_at)}</Typography>
          ) : (
            <Typography>{"\u2014"}</Typography>
          ),
        sortable: false,
      },
    ],
    [permissions.view_technical_details]
  );

  const getQueryKey: GetQueryKeyFn = useCallback(
    ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      "scheduled-tours",
      {
        page,
        limit: pageSize,
        unitId: unit.id,
        sort: sortColumn,
        dir: sortDirection,
        ...filters,
      },
    ],
    [unit.id]
  );

  return (
    <UrlControlledReactQueryTable
      title="Scheduled Tours"
      columns={columns}
      keyExtractor={keyExtractor}
      getQueryKey={getQueryKey}
      fetch={fetchScheduledTours}
    />
  );
};
