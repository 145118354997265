import Badge from "../Badge";

type BadgesProps = {
  statusColor: string;
  statusText: string;
  flagCount: number;
};

export function Badges({ flagCount, statusColor, statusText }: BadgesProps) {
  const label = flagCount > 1 ? "Flags" : "Flag";

  return (
    <>
      <Badge color={statusColor} text={statusText} />
      {flagCount > 0 ? (
        <Badge
          color="red"
          text={`${flagCount} ${label}`}
          style={{ marginLeft: 8 }}
        />
      ) : null}
    </>
  );
}
