import { ZipatoHubOnlineStatus } from "@/types";

export enum ConnectionStatus {
  Online = "online",
  Offline = "offline",
  Unknown = "unknown",
  Error = "error",
  InHubPrep = "in_hub_prep",
}
// Box/Session -> gives us a string or null
// Get Box     -> gives us a boolean
export const getZipatoHubConnectionStatus = (
  isOnline: ZipatoHubOnlineStatus
): ConnectionStatus => {
  if (isOnline === true || String(isOnline).toLowerCase() === "true") {
    return ConnectionStatus.Online;
  }

  if (isOnline === false || String(isOnline).toLowerCase() === "false") {
    return ConnectionStatus.Offline;
  }

  return ConnectionStatus.Unknown;
};
