import {
  getAccessControlLockProviderNames,
  hasAccessControlLocks,
  hasZWaveLocks,
} from "@/utils/unit";

import { useUnit } from "../UnitContext";

export default function UnitLockStatus() {
  const { devices, nonZwaveLocks } = useUnit();

  return (
    <div className="u-mtop12 u-line18">
      <div>
        <span className="u-text-gray-600">Has Z-Wave Locks:</span>{" "}
        {hasZWaveLocks(devices) ? "Yes" : "No"}
      </div>
      <div>
        <span className="u-text-gray-600">Has Non-Z-Wave Locks:</span>{" "}
        {hasAccessControlLocks(nonZwaveLocks) ? (
          <>
            Yes ({getAccessControlLockProviderNames(nonZwaveLocks).join(", ")})
          </>
        ) : (
          "No"
        )}
      </div>
    </div>
  );
}
