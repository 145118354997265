import * as React from "react";
import { StyleSheet } from "react-native";
import * as Yup from "yup";

import {
  Form,
  FormikSelectField,
  FormikTextInputField,
  FormSection,
} from "@smartrent/ui";

import { FormikImageUpload } from "@/common/formik/FormikImageUpload";
import {
  LocationForm,
  locationValidationFields,
} from "@/common/formik/LocationForm";

import {
  timezoneOptions,
  temperatureScaleOptions,
  uploadcareConfig,
  uploadcarePublicKey,
  timezones,
  temperatureScales,
} from "@/utils";
import { GroupProps } from "@/types";

export interface GroupFormValues extends Partial<GroupProps> {
  // required fields
  marketing_name: string;
  state: string;
  zip: string;
  country: string;
  timezone: string;
  temperature_scale: string;
}

export const validationSchema = Yup.object({
  ...locationValidationFields,
  marketing_name: Yup.string().trim().min(1).required("Required"),
  property_code: Yup.string()
    .nullable()
    .default(null)
    .max(100, "Must be less than 100 characters"),
  group_image: Yup.string().nullable().notRequired(),
  timezone: Yup.string().oneOf(timezones).required("Required"),
  temperature_scale: Yup.string().oneOf(temperatureScales).required("Required"),
});

export const GroupForm: React.FC<React.PropsWithChildren<GroupFormValues>> = (
  ...props
) => {
  return (
    <Form>
      <FormSection title="Details" style={styles.detailFormContainer}>
        <FormikTextInputField required name="marketing_name" label="Name" />

        <FormikTextInputField name="property_code" label="Property Code" />

        <FormikSelectField
          required
          name="timezone"
          label="Timezone"
          options={timezoneOptions}
        />

        <FormikSelectField
          required
          name="temperature_scale"
          label="Temperature Scale"
          options={temperatureScaleOptions}
        />
      </FormSection>

      <LocationForm title="Location" canEdit={true} {...props} />

      <FormSection title="Upload Image" style={styles.uploadImageSection}>
        <FormikImageUpload
          displayImage
          alt="Group"
          name="group_image"
          configuration={{
            ...uploadcareConfig,
            publicKey: uploadcarePublicKey,
            imagesOnly: "true",
          }}
        />
      </FormSection>
    </Form>
  );
};

const styles = StyleSheet.create({
  detailFormContainer: { marginBottom: 16 },
  uploadImageSection: { marginTop: 8 },
});
