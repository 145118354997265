import { View, StyleSheet, ScrollView } from "react-native";
import { startCase } from "lodash-es";
import { Box, Typography, Link } from "@smartrent/ui";

import Table from "@/common/Table";
import Helpers from "@/lib/helpers";

import {
  group as groupLink,
  organization as orgLink,
  unit as unitLink,
} from "@/utils/links";

import { ProspectVerificationsProps } from "../types";

// Note: This is a Dumb Component and should not make API calls
export default function ProspectVerificationResults({
  prospectVerifications,
}: {
  prospectVerifications: ProspectVerificationsProps[];
}) {
  const columns = [
    {
      label: "Location",
      className: "grid__sm--12 grid__md--2",
      render: (record) => (
        <div>
          <Link href={orgLink(record.organization_id)}>
            {record.organization_name}
          </Link>{" "}
          /{" "}
          <Link href={groupLink(record.group_id)}>
            {record.group_marketing_name}
          </Link>
        </div>
      ),
    },
    {
      label: "Unit(s)",
      className: "grid__sm--12 grid__md--2",
      render: ({ units }) => (
        <div>
          {units.map((unit, idx) => (
            <>
              <Link key={unit.id} href={unitLink(unit.id)}>
                {unit.marketing_name}
              </Link>
              {idx + 1 < units.length ? ", " : ""}
            </>
          ))}
        </div>
      ),
    },
    {
      label: "Start Time",
      className: "grid__sm--12 grid__md--2",
      render: (record) => (
        <div>
          {record.scheduled_start
            ? Helpers.formatDate(record.scheduled_start)
            : null}
        </div>
      ),
    },
    {
      label: "Last ID Step",
      className: "grid__sm--12 grid__md--2",
      render: (record) => (
        <div>{startCase(record.identity_verification_last_step_completed)}</div>
      ),
    },
    {
      label: "Verification Result",
      className: "grid__sm--12 grid__md--2",
      render: (record) => (
        <div>{startCase(record.identity_verification_result)}</div>
      ),
    },
    {
      label: "Finalized At",
      className: "grid__sm--12 grid__md--2",
      render: (record) => (
        <div>
          {record.finalized_at ? Helpers.formatDate(record.finalized_at) : "-"}
        </div>
      ),
    },
  ];

  return (
    <Box style={styles.mainView}>
      <View style={styles.titleContainer}>
        <Typography type="title2">Previous Verifications</Typography>
      </View>

      <ScrollView nestedScrollEnabled={true}>
        <Table
          noRecordsTitle="No Previous Verifications"
          columns={columns}
          records={prospectVerifications}
          classes={{
            container: "list--striped",
            chevron: "icon--gray-400",
            chevronActive: "icon--gray-500",
            header: "u-text-upper u-sans-bold u-text-gray-400",
          }}
        />
      </ScrollView>
    </Box>
  );
}

const styles = StyleSheet.create({
  mainView: {
    height: "auto",
    padding: 18,
    width: "100%",
  },
  titleContainer: {
    marginBottom: 18,
  },
});
