import { useContext } from "react";
import { map, groupBy, compact, find, last } from "lodash-es";
import moment from "moment";
import { Line } from "react-chartjs-2";

import Graph from "@/common/Graph";
import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

export default function DevicesInstalledByHour() {
  const { isDesktop } = useContext(Context);

  if (!isDesktop) {
    return null;
  }

  return (
    <Graph
      path="/reports/devices-installed-by-hour"
      dataKey="devices"
      refetchInterval={600000}
      renderProps={({ metrics }) => {
        const groupedData = groupBy(metrics, "type");
        const timeslots = compact(
          map(groupedData.completed, (r) => {
            if (
              ["Saturday", "Sunday"].includes(moment(r.date).format("dddd"))
            ) {
              return null;
            } // strip out data from weekends
            return r.date;
          })
        );
        const daysOfYearRepresented = [];

        return (
          <div>
            {isDesktop && (
              <div className="u-flex u-flex-wrap">
                <Line
                  data={{
                    labels: map(timeslots, (r) => {
                      const dayOfYear = moment(r).dayOfYear();
                      if (!daysOfYearRepresented.includes(dayOfYear)) {
                        daysOfYearRepresented.push(dayOfYear);
                        return moment(r).format("ddd MM/DD hh:mm a");
                      } else {
                        return moment(r).format("MM/DD hh:mm a");
                      }
                    }),
                    datasets: map(groupedData, (origData, type) => {
                      const data = map(timeslots, (date) => {
                        const datapoint = find(origData, { date });
                        return datapoint ? Number(datapoint.count) : 0;
                      });

                      let color = "";
                      let label = "";
                      switch (type) {
                        case "pairing":
                          color = "rgba(82,213,211,0.8)";
                          label = "Currently Pairing";
                          break;
                        case "failed":
                          color = Helpers.lineGraphColors.red;
                          label = "Deleted Devices";
                          break;
                        case "completed":
                          color = Helpers.lineGraphColors.green;
                          label = "Paired Devices";
                          break;
                        default:
                          break;
                      }

                      // include most recent data points
                      const modifiedType = `${label} (${last(data)})`;

                      return {
                        ...Helpers.lineGraphDefaults,
                        label: modifiedType,
                        data,
                        backgroundColor: color,
                        borderColor: color,
                        pointBorderColor: color,
                        pointBackgroundColor: "#fff",
                      };
                    }),
                  }}
                  height={45}
                />
              </div>
            )}
          </div>
        );
      }}
    />
  );
}
