import { useEffect, useRef, useContext } from "react";
import * as React from "react";
import {
  Animated,
  View,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { useTheme } from "@smartrent/ui";

import { useIsPhone } from "@/hooks/breakpoints";
import Loading from "@/common/Loading";
import Toast from "@/common/Toast";

import Header from "./main/Header";
import SideNav from "./main/SideNav";

import Context, { useGlobalContext } from "./Context";

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { colors } = useTheme();
  const { sidenavOpen, setSidenavOpen, scrollViewRef } = useGlobalContext();
  const { pageLoading, user } = useContext(Context);

  const offset = useRef(new Animated.Value(-260)).current;
  const opacity = useRef(new Animated.Value(0)).current;

  const isPhone = useIsPhone();
  useEffect(() => {
    const showAnimation = () => {
      Animated.parallel([
        Animated.timing(offset, {
          toValue: 0,
          duration: 275,
          useNativeDriver: false,
        }),
        Animated.timing(opacity, {
          toValue: 1,
          duration: 275,
          useNativeDriver: false,
        }),
      ]).start();
    };

    const hideAnimation = () => {
      Animated.parallel([
        Animated.timing(offset, {
          toValue: -260,
          duration: 275,
          useNativeDriver: false,
        }),
        Animated.timing(opacity, {
          toValue: 0,
          duration: 275,
          useNativeDriver: false,
        }),
      ]).start();
    };

    if (sidenavOpen) {
      showAnimation();
    } else {
      hideAnimation();
    }
  }, [sidenavOpen, offset, opacity]);

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: colors.pageBackground },
        isPhone ? styles.mobileContainer : null,
      ]}
    >
      {/* TODO: Do this animation better? */}
      <Animated.View
        style={[
          styles.animatedContainer,
          {
            transform: [{ translateX: offset }],
          },
        ]}
      >
        <SideNav />
      </Animated.View>
      {/* TODO: Do this underlay better? */}
      <Animated.View
        style={[
          styles.animatedContainerSidenav,
          { opacity },
          sidenavOpen ? styles.sidenavVisible : styles.sidenavHidden,
        ]}
      >
        <TouchableOpacity
          style={styles.sidenavButton}
          onPress={() => setSidenavOpen(false)}
        />
      </Animated.View>
      <View style={styles.headerAndLoading}>
        <Header />

        <Loading>
          {!pageLoading && user ? (
            <ScrollView ref={scrollViewRef}>{children}</ScrollView>
          ) : null}
        </Loading>
      </View>
      <Toast />
    </View>
  );
};

export default Layout;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100vh",
    flexDirection: "row",
    overflow: "hidden",
  },
  animatedContainer: { position: "absolute", zIndex: 3 },
  animatedContainerSidenav: {
    position: "absolute",
    zIndex: 2,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.25)",
  },
  headerAndLoading: { flex: 1, flexGrow: 1 },
  sidenavButton: { flex: 1 },
  mobileContainer: { paddingBottom: "65px" },
  // @ts-expect-error
  sidenavVisible: { visibility: "visible" },
  // @ts-expect-error
  sidenavHidden: { visibility: "hidden" },
});
