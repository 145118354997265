import { Text, useTheme } from "@smartrent/ui";
import { View } from "react-native";

interface Props {
  color: string;
  text: string;
  style?: object;
}

export default function Badge({ color, text, style }: Props) {
  const { fonts } = useTheme();

  return (
    <View
      style={{
        borderColor: color,
        borderWidth: 1,
        borderRadius: 4,
        paddingVertical: 2,
        paddingHorizontal: 8,
        ...style,
      }}
    >
      <Text
        style={[
          fonts.bold,
          {
            fontSize: 11,
            color,
            textTransform: "uppercase",
          },
        ]}
      >
        {text}
      </Text>
    </View>
  );
}
