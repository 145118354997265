import { useContext } from "react";
import { View, Text } from "react-native";

import { last } from "lodash-es";
import moment from "moment";
import { Line } from "react-chartjs-2";

import { CaretUp, CaretDown } from "@smartrent/icons";

import Graph from "@/common/Graph";
import Context, { useUser } from "@/layout/Context";
import Helpers from "@/lib/helpers";

interface VerificationStats {
  daily_total: string;
  auto_verified: string;
  manual_approvals: string;
  manual_rejections: string;
  skipped: string;
  date: string;
  minutes_to_resolve: string;
}

export default function VerificationsByDay() {
  const { isDesktop } = useContext(Context);
  const { permissions } = useUser();

  // This is a support/engineering concern/graph, not for all departments
  if (!(isDesktop && permissions.manual_verifications)) {
    return null;
  }

  const displayPercentage = (delta: number | null) => {
    if (delta == null) {
      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CaretUp color={Helpers.colors.red} size={24} />
          <Text>{"\u221e% increase"}</Text>
        </View>
      );
    } else if (delta >= 0) {
      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CaretUp color={Helpers.colors.red} size={24} />
          <Text>{`${delta}% increase`}</Text>
        </View>
      );
    } else if (delta < 0) {
      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <CaretDown color={Helpers.colors.green} size={24} />
          <Text>{`${Math.abs(delta)}% decrease`}</Text>
        </View>
      );
    }
    return null;
  };

  return (
    <Graph
      path="/reports/verifications-by-day"
      dataKey="data"
      leftFooterRenderProps={({
        manualVerificationChanges,
      }: {
        manualVerificationChanges: {
          sevenDayDelta: number | null;
          thirtyDayDelta: number | null;
        };
      }) => {
        return (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: Helpers.colors.gray, textAlign: "center" }}>
              Manual Verifications
            </Text>
            <View
              style={{
                padding: 8,
              }}
            >
              <Text style={{ color: Helpers.colors.gray, textAlign: "center" }}>
                Last 30 Days
              </Text>
              {displayPercentage(manualVerificationChanges.thirtyDayDelta)}
            </View>
            <View
              style={{
                padding: 8,
              }}
            >
              <Text style={{ color: Helpers.colors.gray, textAlign: "center" }}>
                Last 7 Days
              </Text>
              {displayPercentage(manualVerificationChanges.sevenDayDelta)}
            </View>
          </View>
        );
      }}
      renderProps={({
        verifications,
      }: {
        verifications: VerificationStats[];
      }) => (
        <Line
          data={{
            labels: verifications.map((r) =>
              moment.utc(r.date).format("ddd MM/DD")
            ),
            datasets: [
              {
                ...Helpers.lineGraphDefaults,

                label: `Total Verifications (${Number(
                  last(verifications)?.daily_total ?? 0
                ).toLocaleString()})`,
                data: verifications.map(({ daily_total }) => daily_total),

                backgroundColor: Helpers.lineGraphColors.blue,
                borderColor: Helpers.lineGraphColors.blue,

                pointBorderColor: Helpers.lineGraphColors.blue,

                pointHoverBackgroundColor: Helpers.lineGraphColors.blue,
                pointHoverBorderColor: Helpers.lineGraphColors.blue,
              },
              {
                ...Helpers.lineGraphDefaults,

                label: `Auto Verified (${Number(
                  last(verifications)?.auto_verified ?? 0
                ).toLocaleString()})`,
                data: verifications.map(({ auto_verified }) => auto_verified),

                backgroundColor: Helpers.lineGraphColors.cyan,
                borderColor: Helpers.lineGraphColors.cyan,
                pointBorderColor: Helpers.lineGraphColors.cyan,
                pointHoverBackgroundColor: Helpers.lineGraphColors.cyan,
                pointHoverBorderColor: Helpers.lineGraphColors.cyan,
              },
              {
                ...Helpers.lineGraphDefaults,

                label: `Manual Approvals (${Number(
                  last(verifications)?.manual_approvals ?? 0
                ).toLocaleString()})`,
                data: verifications.map(
                  ({ manual_approvals }) => manual_approvals
                ),

                backgroundColor: Helpers.lineGraphColors.green,
                borderColor: Helpers.lineGraphColors.green,
                pointBorderColor: Helpers.lineGraphColors.green,
                pointHoverBackgroundColor: Helpers.lineGraphColors.green,
                pointHoverBorderColor: Helpers.lineGraphColors.green,
              },
              {
                ...Helpers.lineGraphDefaults,

                label: `Manual Rejections (${Number(
                  last(verifications)?.manual_rejections ?? 0
                ).toLocaleString()})`,
                data: verifications.map(
                  ({ manual_rejections }) => manual_rejections
                ),

                backgroundColor: Helpers.lineGraphColors.red,
                borderColor: Helpers.lineGraphColors.red,
                pointBorderColor: Helpers.lineGraphColors.red,
                pointHoverBackgroundColor: Helpers.lineGraphColors.red,
                pointHoverBorderColor: Helpers.lineGraphColors.red,
              },
              {
                ...Helpers.lineGraphDefaults,

                label: `Skipped (${Number(
                  last(verifications)?.skipped ?? 0
                ).toLocaleString()})`,
                data: verifications.map(({ skipped }) => skipped),

                backgroundColor: Helpers.lineGraphColors.gray200,
                borderColor: Helpers.lineGraphColors.gray200,
                pointBorderColor: Helpers.lineGraphColors.gray200,
                pointHoverBackgroundColor: Helpers.lineGraphColors.gray200,
                pointHoverBorderColor: Helpers.lineGraphColors.gray200,
              },
              {
                ...Helpers.lineGraphDefaults,

                label: `Avg Minutes To Resolution (${Number(
                  last(verifications)?.minutes_to_resolve ?? 0
                ).toLocaleString()})`,
                data: verifications.map(
                  ({ minutes_to_resolve }) => minutes_to_resolve
                ),

                backgroundColor: Helpers.lineGraphColors.darkGreen,
                borderColor: Helpers.lineGraphColors.darkGreen,
                pointBorderColor: Helpers.lineGraphColors.darkGreen,
                pointHoverBackgroundColor: Helpers.lineGraphColors.darkGreen,
                pointHoverBorderColor: Helpers.lineGraphColors.darkGreen,
              },
            ],
          }}
          height={45}
        />
      )}
    />
  );
}
