import { startCase } from "lodash-es";

import { HStack, Table, Typography } from "@smartrent/ui";

import { DeviceStatsProps } from "@/types";

import { DeviceTypeIcon } from "./DeviceTypeIcon";

export interface DeviceBreakdownProps {
  data: DeviceStatsProps[];
}

/**
 * Pure functional component
 *
 * Handle data fetching upstream and pass data to this component
 */
export const DeviceBreakdown = ({ data }: DeviceBreakdownProps) => {
  return (
    <Table
      data={data}
      columns={[
        {
          name: "device_type",
          header: "Device Type",
          render: ({ row }) => (
            <HStack spacing={8}>
              <DeviceTypeIcon type={row.type} />
              <Typography>
                {startCase(row.type).replace("Slave", "")}
              </Typography>
            </HStack>
          ),
        },
        {
          name: "total_devices",
          header: "Total Devices",
          render: ({ row }) => row.count,
        },
        {
          name: "online_devices",
          header: "Online Devices",
          render: ({ row }) => row.online,
        },
        {
          name: "offline_devices",
          header: "Offline Devices",
          render: ({ row }) => row.offline,
        },
        {
          name: "warning_devices",
          header: "Warning Devices",
          render: ({ row }) => row.warning,
        },
      ]}
      noRecordsText="No devices Found"
      selectable={false}
    />
  );
};
