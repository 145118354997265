import { View, StyleSheet } from "react-native";
import {
  ActivityIndicator,
  Typography,
  useTheme,
  Banner,
  HStack,
} from "@smartrent/ui";

import Layout from "@/layout/Layout";

import { useCMWFeatureFlagsQuery } from "../api/cmw-feature-flags";

import { CMWFeatureFlag } from "./cmw-feature-flags/CMWFeatureFlag";

export function CMWFeatureFlags() {
  const { data: flags, isLoading, error, refetch } = useCMWFeatureFlagsQuery();
  const { colors } = useTheme();

  if (isLoading) {
    return <ActivityIndicator size={24} color={colors.green} />;
  } else if (error) {
    return <View></View>;
  }

  return (
    <Layout>
      <View style={styles.m16}>
        <Banner
          title="Once globally enabled, all orgs/groups will be able to see this feature in CMW within 10 minutes UNLESS that flag is specifically disabled for that org/group."
          color="info"
        />
      </View>
      <HStack spacing={18} wrap={true}>
        {flags.map((flag) => (
          <CMWFeatureFlag
            flag={flag}
            key={flag.name}
            refetch={refetch}
            isLoading={isLoading}
          />
        ))}
      </HStack>
      {error ? (
        <Typography color="error">
          An error occurred. Please try again later
        </Typography>
      ) : null}
    </Layout>
  );
}

const styles = StyleSheet.create({
  m16: {
    marginHorizontal: 16,
    marginVertical: 8,
  },
});
