import * as React from "react";
import { TouchableHighlight } from "react-native";
import AriaModal from "react-aria-modal";

import { Text, useTheme, Box, Button, ButtonProps } from "@smartrent/ui";
import { Close } from "@smartrent/icons";

import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalActions from "./ModalActions";

export interface ModalProps {
  titleId: string;
  isOpen: boolean;
  children?: React.ReactNode;
  header?: React.ReactNode;
  content?: string;
  actions?: Array<ButtonProps & { key?: string; content: String }>;
  closeIcon?: null | React.ReactNode;
  onClose?: Function;
  onOpen?: Function;
  size?: "small" | "medium" | "large" | "fullscreen";
  style?: object;
}

const Modal = ({
  titleId,
  children,
  header,
  content,
  actions,
  closeIcon,
  onClose,
  onOpen,
  isOpen,
  size,
  style,
  ...rest
}: ModalProps) => {
  const { colors, shadows } = useTheme();

  return isOpen ? (
    <AriaModal
      titleId={titleId}
      onExit={() => onClose()}
      focusDialog={true}
      verticallyCenter={true}
      {...rest}
    >
      <Box
        style={{
          borderRadius: 3,
          borderWidth: 1,
          borderColor: colors.gray300,
          paddingVertical: 16,
          paddingHorizontal: 16,
          overflow: "hidden",
          ...shadows.regular,
          ...style,
        }}
      >
        <TouchableHighlight
          style={{
            position: "absolute",
            top: 16,
            right: 16,
            zIndex: 1,
          }}
          onPress={() => onClose()}
          activeOpacity={0.8}
          underlayColor="transparent"
        >
          {!!closeIcon ? (
            closeIcon
          ) : (
            <Button variation="plain" onPress={() => onClose()}>
              <Close size={32} color={colors.iconsWithinComponents} />
            </Button>
          )}
        </TouchableHighlight>
        {children}
        {!!header && (
          <ModalHeader>
            <Text>{header}</Text>
          </ModalHeader>
        )}
        {!!content && (
          <ModalContent>
            <Text>{content}</Text>
          </ModalContent>
        )}
        {!!actions && <ModalActions actions={actions} />}
      </Box>
    </AriaModal>
  ) : null;
};

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Actions = ModalActions;

export default Modal;
