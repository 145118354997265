import { useContext, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useMutationCompat } from "@smartrent/hooks";

import Context from "@/layout/Context";
import { AxiosMutationConfig } from "@/lib/react-query-helpers";
import { Sign } from "@/types";
import { instance } from "@/lib/hooks";

interface SignsPaginatedResult {
  records: Sign[];
  total_records: number;
  total_pages: number;
  current_page: number;
}

interface FetchSignsFilters {
  property_id: string;
  page: number;
  limit?: number;
  dir?: "asc" | "desc";
}

export const useSignsPaginatedQuery = (filters: FetchSignsFilters) =>
  useQuery<SignsPaginatedResult>(
    ["signs", filters],
    async () => {
      const { data } = await instance.get(`/parking/signs`, {
        params: { ...filters },
      });

      return data;
    },
    {
      enabled: !!filters.property_id,
      keepPreviousData: true,
    }
  );

export const useInvalidateSignsQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (propertyId: string) =>
      queryClient.invalidateQueries(["signs", propertyId]),
    [queryClient]
  );
};

interface GenerateSignQRCodeOptions {
  propertyId: string;
  signId: string;
}

export const useGenerateSignQRCodeMutation = (
  options?: AxiosMutationConfig<GenerateSignQRCodeOptions>
) => {
  const context = useContext(Context);

  return useMutationCompat(
    async ({ propertyId, signId }: GenerateSignQRCodeOptions) => {
      try {
        const { data } = await instance.post(
          `/parking/properties/${propertyId}/signs/${signId}/qr-code`,
          {}
        );

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: "Unable to generate QR Code.",
        });

        throw err;
      }
    },
    options
  );
};
