import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useRouteMatch } from "react-router-dom";

import {
  HStack,
  Typography,
  VStack,
  Button,
  SelectField,
  ConfirmDialog,
  useToast,
  Panel,
  PanelHeader,
  PanelHeaderTitle,
  PanelBody,
  FieldSeparator,
} from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";

import { useGlobalContext } from "@/layout/Context";

import {
  useScopeOfWorkAssignGetOpportunityQuery,
  useScopeOfWorkAssignGetPropertyQuery,
  selectQueryPropsSalesforceOpportunities,
  selectQueryPropsSalesforceOpportunityProperties,
  useScopeOfWorkAssignOpportunityMutation,
  selectQueryPropsTemplates,
  useScopeOfWorkTemplateMutation,
  selectQueryPropsGroups,
  useUpdateSOWMutation,
} from "@/api";

import {
  ScopeOfWorkDeviceCategoryOption,
  ScopeOfWorkTemplate,
  SalesForceOpportunity,
  ScopeOfWorkProps,
} from "@/types";

import { useScopeOfWorkContext } from "./provider/ScopeOfWorkContext";
import { deviceCategoryOptions } from "./utils";
import { SowExportDetails } from "./sow-report/SowExportDetails";

export const ScopeOfWorkBuilder: React.FC<
  React.PropsWithChildren<{
    disabled: boolean;
  }>
> = ({ disabled }) => {
  const setToast = useToast();
  const { url } = useRouteMatch();
  const { visible, onOpen, onClose } = useModalState();
  const {
    visible: templateVisible,
    onOpen: templateOnOpen,
    onClose: templateOnClose,
  } = useModalState();
  const {
    visible: propertyVisible,
    onOpen: propertyOnOpen,
    onClose: propertyOnClose,
  } = useModalState();

  const [template, setTemplate] = useState<ScopeOfWorkTemplate | undefined>(
    undefined
  );
  const [device, setDevice] = useState<
    ScopeOfWorkDeviceCategoryOption | undefined
  >(undefined);
  const [opportunity, setOpportunity] = useState<SalesForceOpportunity>({
    Id: "",
    Name: "",
  });
  const [property, setproperty] = useState<SalesForceOpportunity>({
    Id: "",
    Name: "",
  });
  const [opportunityFieldDirty, setOpportunityFieldDirty] = useState(false);
  const [propertyFieldDirty, setpropertyFieldDirty] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<
    ScopeOfWorkProps["group"] | undefined
  >();

  const { history } = useGlobalContext();

  const [parOpportunityMutation] = useScopeOfWorkAssignOpportunityMutation();

  const [updateSow] = useUpdateSOWMutation();

  const [parTemplateMutation, { isError, isSuccess }] =
    useScopeOfWorkTemplateMutation();

  useEffect(() => {
    if (isSuccess) {
      setToast({
        status: "success",
        title: "Template loaded!",
        message: "",
      });
    }
    if (isError) {
      setToast({
        status: "error",
        title: "Error loading template. Please try again.",
        message: "",
      });
    }
  }, [isSuccess, isError, setToast]);

  const onChangeOpportunity = useCallback((value: any) => {
    setOpportunityFieldDirty(true);
    setOpportunity(value);
  }, []);

  const onChangeproperty = useCallback((value: any) => {
    setpropertyFieldDirty(true);
    setproperty(value);
  }, []);

  const onChangeGroup = useCallback((value: any) => {
    setSelectedGroup(value);
  }, []);

  // USE SOW CONTEXT
  const {
    scopeOfWorkQuery: { data: sow_report },
    scopeOfWorkId,
  } = useScopeOfWorkContext();

  const { data: opportunityFromSF } = useScopeOfWorkAssignGetOpportunityQuery(
    sow_report,
    {
      enabled: !!sow_report?.sf_opportunity_id,
    }
  );
  const { data: propertyFromSF } = useScopeOfWorkAssignGetPropertyQuery(
    sow_report,
    {
      enabled: !!sow_report?.opportunity_property_id,
    }
  );

  useEffect(() => {
    setSelectedGroup(sow_report?.group);
  }, [sow_report?.group]);

  useEffect(() => {
    if (!sow_report?.opportunity_property_id && property.Id) {
      setpropertyFieldDirty(false);
      setproperty({ Id: "", Name: "" });
    }
  }, [sow_report?.sf_opportunity_id]);

  return (
    <Panel style={styles.cardPadding}>
      <PanelHeader>
        <PanelHeaderTitle title="SOW Builder" />
      </PanelHeader>
      <PanelBody>
        <VStack spacing={16}>
          <SowExportDetails sow={sow_report} />

          <View>
            <Typography type="bodyLargeSemibold" style={styles.builderTitle}>
              Choose Template
            </Typography>
            <Typography
              type="caption"
              color="textSecondary"
              style={styles.builderSubtitle}
            >
              Optional
            </Typography>
            <View style={styles.flexRow}>
              <SelectField<ScopeOfWorkTemplate>
                disabled={disabled}
                label="Template"
                name="template"
                value={template}
                onChange={setTemplate}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                {...selectQueryPropsTemplates()}
                style={styles.flexOne}
              />
              <HStack>
                <Button
                  size="large"
                  onPress={templateOnOpen}
                  disabled={disabled || !template}
                  style={styles.buttonMargin}
                >
                  Confirm
                </Button>
              </HStack>
            </View>
          </View>
          <View>
            <Typography type="bodyLargeSemibold" style={styles.builderTitle}>
              Choose Product
            </Typography>
            <View style={styles.flexRow}>
              <SelectField<ScopeOfWorkDeviceCategoryOption>
                disabled={disabled}
                label="Product"
                name="device"
                value={device}
                onChange={setDevice}
                getOptionValue={({ value }) => value}
                getOptionLabel={({ label }) => label}
                options={deviceCategoryOptions}
                style={styles.flexOne}
              />
              <HStack>
                <Button
                  size="large"
                  onPress={() => {
                    history.push(`${url}/add/${device?.value}`);
                  }}
                  disabled={disabled || !device}
                  style={styles.buttonMargin}
                >
                  Confirm
                </Button>
              </HStack>
            </View>
          </View>

          <FieldSeparator />

          <View>
            <Typography type="bodyLargeSemibold" style={styles.builderTitle}>
              Choose Salesforce Opportunity
            </Typography>
            <View style={styles.flexRow}>
              <SelectField<SalesForceOpportunity>
                label="Opportunity"
                name="opportunitySelection"
                value={
                  opportunityFieldDirty
                    ? opportunity
                    : (opportunityFromSF as SalesForceOpportunity)
                }
                onChange={onChangeOpportunity}
                getOptionValue={(opp) => (opp?.Id ? opp.Id : "")}
                getOptionLabel={(opp) => (opp?.Name ? opp.Name : "")}
                {...selectQueryPropsSalesforceOpportunities()}
                disabled={disabled}
                style={styles.flexOne}
              />
              <HStack>
                <Button
                  size="large"
                  onPress={onOpen}
                  disabled={!opportunity || disabled}
                  style={styles.buttonMargin}
                >
                  Confirm
                </Button>
              </HStack>
            </View>
          </View>
          <View>
            <Typography type="bodyLargeSemibold" style={styles.builderTitle}>
              Choose Salesforce Property
            </Typography>
            <View style={styles.flexRow}>
              <SelectField<SalesForceOpportunity>
                label="Property"
                name="propertySelection"
                value={
                  propertyFieldDirty
                    ? property
                    : (propertyFromSF as SalesForceOpportunity)
                }
                onChange={onChangeproperty}
                getOptionValue={(opp) => (opp?.Id ? opp.Id : "")}
                getOptionLabel={(opp) => (opp?.Name ? opp.Name : "")}
                {...selectQueryPropsSalesforceOpportunityProperties(
                  sow_report?.sf_opportunity_id
                )}
                disabled={!sow_report?.sf_opportunity_id || disabled}
                style={styles.flexOne}
              />
              <HStack>
                <Button
                  size="large"
                  onPress={propertyOnOpen}
                  disabled={!sow_report?.sf_opportunity_id || disabled}
                  style={styles.buttonMargin}
                >
                  Confirm
                </Button>
              </HStack>
            </View>
          </View>
          <View>
            <Typography type="bodyLargeSemibold" style={styles.builderTitle}>
              Choose Group
            </Typography>
            <View style={styles.flexRow}>
              <SelectField<ScopeOfWorkProps["group"]>
                label="Group"
                name="groupSelection"
                value={selectedGroup}
                onChange={onChangeGroup}
                getOptionValue={(group) => (group?.id ? group.id : "")}
                getOptionLabel={(group) =>
                  group?.marketing_name ? group.marketing_name : ""
                }
                {...selectQueryPropsGroups({
                  selectedOrg: sow_report?.organization,
                })}
                disabled={!sow_report?.organization?.id}
                style={styles.flexOne}
              />
              <HStack>
                <Button
                  size="large"
                  onPress={() => {
                    if (!sow_report || !selectedGroup) {
                      return;
                    }
                    updateSow({
                      id: sow_report.id,
                      group_id: selectedGroup.id,
                    });
                  }}
                  disabled={!selectedGroup}
                  style={styles.buttonMargin}
                >
                  Confirm
                </Button>
              </HStack>
            </View>
          </View>
        </VStack>
      </PanelBody>

      <ConfirmDialog
        title="Are you sure?"
        description={
          <Typography type="bodyLargeSemibold" style={styles.confirmPrompt}>
            Are you sure you want to assign{" "}
            <Typography type="bodyLargeSemibold">
              {opportunity?.Name}
            </Typography>{" "}
            to this SOW?
          </Typography>
        }
        visible={visible}
        onClose={onClose}
        onConfirm={() => {
          parOpportunityMutation({
            id: scopeOfWorkId,
            sf_opportunity_id: opportunity?.Id,
          });
          onClose();
        }}
      />
      <ConfirmDialog
        title="Are you sure?"
        description={
          <Typography type="bodyLargeSemibold" style={styles.confirmPrompt}>
            Are you sure you want to use this template? Any existing device data
            will be replaced.
          </Typography>
        }
        visible={templateVisible}
        onClose={templateOnClose}
        onConfirm={() => {
          parTemplateMutation({
            scope_of_work_id: scopeOfWorkId,
            template_id: template?.id,
          });
          templateOnClose();
        }}
        confirmType="destructive"
        confirmText="Confirm"
      />
      <ConfirmDialog
        title="Are you sure?"
        description={
          <Typography type="bodyLargeSemibold" style={styles.confirmPrompt}>
            Are you sure you want to assign{" "}
            <Typography type="bodyLargeSemibold">{property?.Name}</Typography>{" "}
            to this SOW?
          </Typography>
        }
        visible={propertyVisible}
        onClose={propertyOnClose}
        onConfirm={() => {
          if (!property || !sow_report) {
            return;
          }
          updateSow({
            id: sow_report.id,
            property_id: property.Id,
          });
          propertyOnClose();
        }}
        confirmText="Confirm"
      />
    </Panel>
  );
};

const styles = StyleSheet.create({
  confirmPrompt: { textAlign: "center", marginVertical: 16 },
  builderTitle: { marginBottom: 16 },
  builderSubtitle: { marginBottom: 8 },
  buttonMargin: { marginLeft: 8 },
  flexOne: { flex: 1 },
  flexRow: { flexDirection: "row" },
  cardPadding: { marginRight: 16 },
});
