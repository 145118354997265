import { useCallback } from "react";
import * as React from "react";

import { Typography } from "@smartrent/ui";

import { UnitProps } from "@/types";

import { useUnitsWithBatteryPoweredThermostats } from "@/api/units";

import { useGroup } from "@/pages/group/GroupContext";

import { GetQueryKeyFn, UrlControlledReactQueryTable } from "@/common/sr-table";

export interface BatteryPoweredThermostatsProps {
  searchValue: string;
}

const keyExtractor = (row: UnitProps) => `${row.id}`;

export const BatteryPoweredThermostats: React.FC<
  React.PropsWithChildren<BatteryPoweredThermostatsProps>
> = ({ searchValue }) => {
  const { group } = useGroup();

  const columns = [
    {
      header: "Unit Name",
      name: "name",
      render: ({ row }) => <Typography>{row.marketing_name}</Typography>,
    },
  ];

  const getQueryKey: GetQueryKeyFn = useCallback(
    ({ filters, page, pageSize, sortColumn, sortDirection }) => [
      "battery-powered-thermostats",
      {
        ...(group?.id && { groupId: group?.id }),
        ...(searchValue && { unitName: searchValue }),
        ...filters,
        page,
      },
    ],
    [group?.id, searchValue]
  );

  const handleGetRowHref = useCallback(
    (row: UnitProps) => `/units/${row.id}`,
    []
  );

  return (
    <UrlControlledReactQueryTable
      title="Battery Powered Thermostats"
      columns={columns}
      keyExtractor={keyExtractor}
      getQueryKey={getQueryKey}
      fetch={useUnitsWithBatteryPoweredThermostats}
      getRowHref={handleGetRowHref}
    />
  );
};
