import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

interface NoContentProps {
  isError?: boolean;
}

export const NoContent: React.FC<React.PropsWithChildren<NoContentProps>> = ({
  isError = false,
}) => (
  <View style={styles.noContentContainer}>
    {isError ? (
      <Typography style={styles.noContentText} color="error">
        Error Fetching Data
      </Typography>
    ) : (
      <Typography style={styles.noContentText}>Refresh to Show Data</Typography>
    )}
  </View>
);

const styles = StyleSheet.create({
  noContentContainer: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noContentText: {
    fontStyle: "italic",
  },
});
