import * as React from "react";
import { View } from "react-native";

import {
  ActivityIndicator,
  FlatList,
  PanelSeparator,
  Skeleton,
} from "@smartrent/ui";

import { useFormikContext } from "formik";

import { Accordion } from "@/common/Accordion";

import { HubRefurbishErrorsListTitle } from "@/pages/hub-refurbish/HubRefurbishErrorsListTitle";
import { RedisStatsBatchErrorsListItem } from "@/pages/hub-refurbish/redis-stats/RedisStatsErrorsListItem";

import {
  HubRefurbishFormikFieldNames,
  HubRefurbishRedisStatsFormikValues,
} from "@/types";

export const RedisStatsBatchErrorsList: React.VFC = () => {
  const { values } = useFormikContext<HubRefurbishRedisStatsFormikValues>();

  const errorAndUnknownHubs =
    values && values[HubRefurbishFormikFieldNames.Error]
      ? values[HubRefurbishFormikFieldNames.Error]
      : [];

  return (
    <View>
      <PanelSeparator />
      <Accordion
        isDisabled={(errorAndUnknownHubs || []).length === 0}
        initialOpenState={false}
        title={
          <HubRefurbishErrorsListTitle
            disabled={(errorAndUnknownHubs || []).length === 0}
            value={`Error Details (${errorAndUnknownHubs.length})`}
          />
        }
      >
        <View>
          <FlatList
            itemSeparators={true}
            keyExtractor={(item, index) =>
              `redis-error-${
                item?.serial
                  ? item.serial
                  : `${index}-${new Date().toISOString()}`
              }`
            }
            data={errorAndUnknownHubs}
            LoadingItemComponent={ActivityIndicator}
            ItemComponent={RedisStatsBatchErrorsListItem}
            isLoading={!errorAndUnknownHubs}
            totalRecords={errorAndUnknownHubs.length}
            totalRecordsLoaded={errorAndUnknownHubs.length}
            totalPages={1}
            ListEmptyComponent={Skeleton as React.FC}
          />
        </View>
      </Accordion>
    </View>
  );
};
