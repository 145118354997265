import { useState } from "react";
import * as React from "react";

import { Button } from "@smartrent/ui";
import { Download } from "@smartrent/icons";

import { useGroup } from "@/pages/group/GroupContext";
import { apiUrl } from "@/utils/links";

export const GroupJobsReportButton: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [disabled, setDisabled] = useState(false);
  const { group } = useGroup();

  const handleDownload = () => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 5000);
  };

  return (
    <>
      {disabled ? (
        <Button iconLeft={Download} disabled>
          Alloy Install Jobs Report
        </Button>
      ) : (
        <a
          href={`${apiUrl}/alloy-install-job-report/${group.id}`}
          download={`alloy-install-jobs-report-${new Date().toISOString()}.csv`}
        >
          <Button iconLeft={Download} onPress={handleDownload}>
            Alloy Install Jobs Report
          </Button>
        </a>
      )}
    </>
  );
};
