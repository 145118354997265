// This component was copied from CMW
// This is a pure functional component

import { useMemo, useCallback } from "react";
import { StyleSheet, View } from "react-native";
import {
  BarChart as RBarChart,
  BarProps as RBarProps,
  Bar,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  LegendProps,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useTheme, Typography } from "@smartrent/ui";

import { LegendItem } from "@/common/graphs/LegendItem"; // Note: relative path change from CMW

type BarProps = RBarProps & {
  legendLabel: string;
};

interface BarChartProps {
  data: Record<string, unknown>[];
  showLegend?: boolean;
  colorful?: boolean;
  legendLabel?: string;
  xAxisLabel?: string;
  bars: BarProps[];
  allowDecimals?: boolean;
}

export function BarChart({
  data,
  xAxisLabel,
  colorful,
  bars,
  showLegend = false,
  allowDecimals = false,
}: BarChartProps) {
  const { colors } = useTheme();

  const colorOptions = useMemo(
    () => [
      colors.darkBlue,
      colors.primary,
      colors.orange,
      colors.cyan,
      colors.purple,
      colors.lime,
      colors.pink,
      colors.deepOrange,
      colors.deepPurple,
      colors.indigo,
    ],
    [colors]
  );

  const getFillColor = useCallback(
    (index: any) => {
      if (index <= colorOptions.length - 1) {
        return colorOptions[index];
      } else {
        const randIndex = Math.floor(Math.random() * 6) + 1;
        return colorOptions[randIndex];
      }
    },
    [colorOptions]
  );

  const colorsArray = useMemo(() => {
    return data.map((_, index) => {
      return getFillColor(index);
    });
  }, [data, getFillColor]);

  const renderTooltipContent = useCallback(
    (payload: any) => {
      const content = bars.map((barData) => {
        const count =
          payload && payload[0]?.payload[`${barData.dataKey}`]
            ? payload[0]?.payload[`${barData.dataKey}`].toLocaleString()
            : 0;

        return (
          <Typography
            key={barData.legendLabel}
            type="title5"
            style={{ color: barData.fill }}
          >
            {barData.legendLabel}: {count}
          </Typography>
        );
      });

      const time = (payload && payload[0]?.payload?.name) ?? "";
      return (
        <View style={[styles.tooltip, { backgroundColor: colors.gray800 }]}>
          <Typography style={{ color: colors.gray200 }} type="body">
            {time}
          </Typography>
          {content}
        </View>
      );
    },
    [colors, bars]
  );

  return (
    <>
      <View style={styles.container}>
        <ResponsiveContainer height="100%" width="100%">
          <RBarChart data={data} margin={chartMargin}>
            <Tooltip
              content={({ payload }: LegendProps) =>
                renderTooltipContent(payload)
              }
            />
            <CartesianGrid vertical={false} stroke={colors.gray100} />
            <XAxis dataKey="name" stroke="0" dx={-20} dy={40} angle={-45} />
            <YAxis
              stroke="0"
              dx={-10}
              tickFormatter={(item) => item.toLocaleString()}
              allowDecimals={allowDecimals}
            />
            {colorful
              ? bars.map((barProps: BarProps) => (
                  <Bar key={`${barProps.dataKey}`} {...barProps}>
                    {data.map((_, index) => (
                      <Cell key={`cell-${index}`} fill={colorsArray[index]} />
                    ))}
                  </Bar>
                ))
              : bars.map((barProps: BarProps) => (
                  <Bar key={`${barProps.dataKey}`} {...barProps} />
                ))}
          </RBarChart>
        </ResponsiveContainer>
        {xAxisLabel ? (
          <Typography
            style={[styles.label, { color: colors.gray600, paddingLeft: 40 }]}
          >
            {xAxisLabel}
          </Typography>
        ) : null}
      </View>
      {showLegend &&
        bars.map(({ dataKey, legendLabel, fill }) => (
          <View key={`${dataKey}`}>
            <LegendItem color={fill as string} description={legendLabel} />
          </View>
        ))}
    </>
  );
}

const chartMargin = { top: 20, right: 30, left: 10, bottom: 70 };

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    height: 450,
    width: "100%",
    flexGrow: 0,
  },
  label: {
    paddingLeft: 40,
  },
  tooltip: {
    borderRadius: 4,
    paddingVertical: 12,
    paddingHorizontal: 24,
  },
});
