import { useState, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { find, reject, startCase, orderBy } from "lodash-es";
import { isAfter, isBefore, differenceInMinutes, parseISO } from "date-fns";
import { CheckCircle, XCircle } from "@smartrent/icons";
import { Button, Link, Typography, useTheme, Table } from "@smartrent/ui";

import Helpers from "@/lib/helpers";
import { useUser } from "@/layout/Context";

import { EM_DASH } from "@/utils/chars";

import { useUnit } from "./UnitContext";

function AccessCodeSlotInformation({ canTroubleshoot, record, accessCodes }) {
  const currentDateTime = useMemo(() => new Date(), []);
  const shouldBeRemoved = useMemo(() => {
    return (
      record.remove_at && isBefore(parseISO(record.remove_at), currentDateTime)
    );
  }, [currentDateTime, record]);
  const isStuck = useMemo(() => {
    return (
      record.reserved_slot_id &&
      (record.remove_attempts === 0 || record.remove_attempts >= 5) &&
      !find(accessCodes, (ac) => {
        return (
          ac.access_code_activation_id !== record.access_code_activation_id &&
          ac.reserved_slot_id === record.reserved_slot_id &&
          ac.added_at &&
          isAfter(parseISO(ac.added_at), parseISO(record.add_at))
        );
      })
    );
  }, [record, accessCodes]);

  const valueDOM = useMemo(() => {
    if (record.reserved_slot_id && !shouldBeRemoved) {
      return (
        <>
          {record.reserved_slot_id !==
            record.access_code_activation_slot_id && (
            <Typography color="error" font="bold">
              ACTIVATION SLOT ID IS {record.access_code_activation_slot_id}
            </Typography>
          )}
        </>
      );
    } else if (record.reserved_slot_id && shouldBeRemoved && isStuck) {
      return (
        <Typography color="error" type="caption">
          Stuck
        </Typography>
      );
    } else if (
      record.reserved_slot_id &&
      shouldBeRemoved &&
      !record.removed_at
    ) {
      return <Typography type="caption">Removing</Typography>;
    } else if (record.removed_at) {
      return (
        <Typography type="caption">
          Removed, was {record.original_slot_id}
        </Typography>
      );
    }

    return "Not on lock";
  }, [record, shouldBeRemoved, isStuck]);

  if (!canTroubleshoot) {
    return null;
  }

  return (
    <Typography type="caption" style={styles.alignItemsCenter}>
      {record.reserved_slot_id ? (
        <Typography style={styles.marginRight4} type="caption">
          Slot {record.reserved_slot_id}
        </Typography>
      ) : null}
      {valueDOM ? valueDOM : null}
    </Typography>
  );
}

export default function UnitAccessCodes({ device, access_codes }) {
  const { colors } = useTheme();
  const { permissions } = useUser();
  const { workOrders } = useUnit();
  const [hideRemoved, setHideRemoved] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const currentDateTime = new Date();
  const records = useMemo(() => {
    // Not every role should care about vault codes
    let filteredACs = permissions.vault_codes
      ? access_codes
      : reject(access_codes, { type: "vault" });

    if (hideRemoved) {
      filteredACs = reject(filteredACs, (ac) => {
        return !!ac.removed_at;
      });
    }

    return orderBy(filteredACs, ["reserved_slot_id"], ["asc"]);
  }, [permissions, access_codes, hideRemoved]);

  const columns = [
    {
      name: "type",
      header: "Type",
      component: ({ row: r }) => {
        const wo = r.work_order_access_code_id
          ? find(workOrders, {
              work_order_access_code_id: r.work_order_access_code_id,
            })
          : null;
        return (
          <View>
            <Typography>{startCase(r.type)}</Typography>
            {r.activation_type && (
              <Typography type="caption">({r.activation_type})</Typography>
            )}
            {r.lock_label && (
              <Typography type="caption">
                Assigned To: {r.lock_label}
              </Typography>
            )}
            {r.purpose && (
              <Typography type="caption">Purpose: {r.purpose}</Typography>
            )}
            {r.issued_to && (
              <Typography type="caption">Issued To: {r.issued_to}</Typography>
            )}
            {wo && (
              <View data-work-order-id={wo.id}>
                <View>
                  <Link type="caption" href={`#work-order-${wo.id}`}>
                    {wo.service_problem}
                  </Link>
                </View>
                <View>
                  <Typography type="caption">
                    {wo.assigned_to !== "Unassigned"
                      ? `Assigned to
                        ${wo.assigned_to}`
                      : wo.assigned_to}
                  </Typography>
                </View>
              </View>
            )}

            <AccessCodeSlotInformation
              canTroubleshoot={permissions.troubleshoot}
              record={r}
              accessCodes={access_codes}
            />
          </View>
        );
      },
    },

    {
      name: "add_at",
      header: "Add At",
      component: ({ row: r }) => (
        <Typography>
          {r.add_at ? Helpers.formatDate(r.add_at) : EM_DASH}
        </Typography>
      ),
    },

    {
      name: "added_at",
      header: "Added At",
      component: ({ row: r }) => {
        let addedContent = null;

        if (
          !r.added_at &&
          r.remove_at &&
          isBefore(parseISO(r.remove_at), currentDateTime)
        ) {
          addedContent = (
            <Typography color="textSecondary" style={styles.alignItemsCenter}>
              <XCircle
                size={16}
                color={colors.error}
                style={styles.marginRight4}
              />
            </Typography>
          );
        } else if (isAfter(parseISO(r.add_at), currentDateTime)) {
          addedContent = <Typography color="info">Future</Typography>;
        } else if (!r.added_at && r.add_attempts >= 5) {
          addedContent = (
            <Typography color="error" style={styles.alignItemsCenter}>
              <XCircle
                size={16}
                color={colors.error}
                style={styles.marginRight4}
              />
              {r.add_attempts} failed attempts
            </Typography>
          );
        } else if (r.duplicate) {
          addedContent = (
            <Typography color="error" style={styles.alignItemsCenter}>
              <XCircle
                size={16}
                color={colors.error}
                style={styles.marginRight4}
              />
              Duplicate, will not add
            </Typography>
          );
        } else if (!r.added_at) {
          addedContent = (
            <Typography style={styles.alignItemsCenter}>
              <XCircle
                size={16}
                color={colors.error}
                style={styles.marginRight4}
              />
              No attempts
            </Typography>
          );
        }

        return (
          <View>
            {addedContent}
            {r.add_at && r.added_at ? (
              <>
                <Typography style={styles.alignItemsCenter}>
                  <CheckCircle
                    size={16}
                    color={colors.success}
                    style={styles.marginRight4}
                  />
                  {Helpers.formatDate(r.added_at)}
                </Typography>
                <Typography type="caption">
                  {`${r.add_attempts} attempt${r.add_attempts > 1 ? "s" : ""}`},
                  {` took ${Number(
                    differenceInMinutes(
                      parseISO(r.added_at),
                      parseISO(r.add_at)
                    )
                  ).toLocaleString()} minute(s)`}
                </Typography>
              </>
            ) : null}
            {permissions?.view_technical_details && (
              <View style={styles.marginTop4}>
                <Typography color="textSecondary" type="caption">
                  (ac id: {r.access_code_id}, aca id:{" "}
                  {r.access_code_activation_id || EM_DASH}
                  {r.work_order_access_code_id
                    ? `, woac id: ${r.work_order_access_code_id}`
                    : ""}{" "}
                  {r.vendor_access_code_id
                    ? `, vendor access code id: ${r.vendor_access_code_id}`
                    : ""}
                  )
                </Typography>
                <Typography color="textSecondary" type="caption">
                  times used: {r.times_used ? r.times_used : EM_DASH}
                </Typography>
              </View>
            )}
          </View>
        );
      },
    },

    {
      name: "remove_at",
      header: "Remove At",
      component: (r) => (
        <Typography>
          {r.remove_at ? Helpers.formatDate(r.remove_at) : EM_DASH}
        </Typography>
      ),
    },

    {
      name: "removed",
      header: "Removed",
      component: ({ row: r }) => {
        if (!r.remove_at) {
          return <Typography>{EM_DASH}</Typography>;
        } else if (isAfter(parseISO(r.remove_at), currentDateTime)) {
          return (
            <Typography type="caption" color="info">
              Future
            </Typography>
          );
        } else if (r.removed_at) {
          return (
            <View>
              <Typography style={styles.alignItemsCenter}>
                <CheckCircle
                  size={16}
                  style={styles.marginRight4}
                  color={colors.success}
                />
                {Helpers.formatDate(r.removed_at)}
              </Typography>
              <Typography type="caption">
                {r.remove_attempts} attempt(s), took{" "}
                {Number(
                  differenceInMinutes(
                    parseISO(r.removed_at),
                    parseISO(r.remove_at)
                  )
                ).toLocaleString()}{" "}
                minute(s)
              </Typography>
            </View>
          );
        } else if (!r.added_at) {
          return (
            <Typography type="caption" style={styles.alignItemsCenter}>
              <XCircle
                size={16}
                color={colors.error}
                style={styles.marginRight4}
              />
              Never added
            </Typography>
          );
        } else {
          return (
            <Typography color="error" style={styles.alignItemsCenter}>
              <XCircle
                size={16}
                color={colors.error}
                style={styles.marginRight4}
              />
              <Typography type="caption" color="error">
                {r.remove_attempts} attempts
              </Typography>
            </Typography>
          );
        }
      },
    },

    {
      name: "replacements",
      header: "Replacements",
      component: ({ row: r }) => {
        if (!(r.replacements && r.replacements.length)) {
          return <Typography>{EM_DASH}</Typography>;
        }

        return (
          <View>
            {r.replacements.map((replacement, idx) => {
              return (
                <View key={idx} style={idx !== 0 ? styles.marginTop8 : null}>
                  <Typography style={styles.alignItemsCenter}>
                    {replacement.added_at ? (
                      <CheckCircle
                        size={16}
                        color={colors.success}
                        style={styles.marginRight4}
                      />
                    ) : (
                      <XCircle
                        size={16}
                        color={colors.error}
                        style={styles.marginRight4}
                      />
                    )}
                    {Helpers.formatDate(replacement.inserted_at)}
                  </Typography>
                  <Typography type="caption">
                    {replacement.added_at
                      ? `${replacement.add_attempts} attempts, took ${Number(
                          differenceInMinutes(
                            parseISO(replacement.added_at),
                            parseISO(replacement.inserted_at)
                          )
                        ).toLocaleString()} minute(s)`
                      : `Not added after ${Number(
                          differenceInMinutes(
                            currentDateTime,
                            parseISO(replacement.inserted_at)
                          )
                        ).toLocaleString()} minutes, ${
                          replacement.add_attempts
                        } attempts`}
                  </Typography>

                  {permissions?.view_technical_details && (
                    <Typography
                      type="caption"
                      color="textSecondary"
                      style={styles.marginTop4}
                    >
                      (replacement id: {replacement.id})
                    </Typography>
                  )}
                </View>
              );
            })}
          </View>
        );
      },
    },
  ];

  return (
    <>
      <View style={styles.accessCodesContainer}>
        <View style={styles.titleBar}>
          {device && device.id ? (
            <Typography>{startCase(device.residents_name)}</Typography>
          ) : null}

          {device &&
          device.internal_name &&
          device.internal_name !== device.residents_name ? (
            <Typography style={styles.marginLeft} color="textSecondary">
              {startCase(device.internal_name)}
            </Typography>
          ) : null}

          {device && device.primary_lock && (
            <Typography color="textSecondary"> primary</Typography>
          )}
        </View>
        <View style={styles.buttonContainer}>
          <Button
            variation="outlined"
            style={styles.marginLeft}
            onPress={() => setHideRemoved(!hideRemoved)}
          >
            {hideRemoved ? "Show Removed" : "Hide Removed"}
          </Button>
          <Button
            variation="outlined"
            style={styles.marginLeft}
            onPress={() => setCollapsed(!collapsed)}
          >
            {collapsed ? "Show Access Codes" : "Collapse"}
          </Button>
        </View>
      </View>
      {!collapsed && (
        <Table
          columns={columns}
          data={records}
          noRecordsText="No Access Codes"
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  marginTop4: { marginTop: 4 },
  marginTop8: { marginTop: 8 },
  marginRight4: { marginRight: 4 },
  alignItemsCenter: { alignItems: "center", display: "flex" },
  buttonContainer: {
    flexDirection: "row",
    marginTop: 8,
    marginBottom: 8,
  },
  titleBar: { flexDirection: "row", marginLeft: 8 },
  marginLeft: { marginLeft: 8 },
  accessCodesContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
});
