import { useContext } from "react";
import * as React from "react";
import { FlatList, TouchableHighlight, View } from "react-native";

import { Text, useTheme } from "@smartrent/ui";

import { JobCard, JobCardLoading } from "@smartrent/install";

import { useJobsQuery } from "../../api/jobs";
import { useUnit } from "../../pages/unit/UnitContext";
import Context from "../../layout/Context";

interface UnitJobListProps {
  height?: number;
}

export const UnitJobList: React.FC<
  React.PropsWithChildren<UnitJobListProps>
> = ({ height = undefined }) => {
  const { unit } = useUnit();
  const { colors } = useTheme();

  const { history } = useContext(Context);

  const {
    reducedData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useJobsQuery({ unit_remote_id: unit.id, limit: 3 });

  return (
    <FlatList
      style={{ maxHeight: height }}
      data={
        isLoading
          ? ([{ id: "1" }, { id: "2" }, { id: "3" }] as any)
          : reducedData
      }
      scrollEventThrottle={100}
      onEndReached={() => {
        hasNextPage && !isFetchingNextPage && fetchNextPage();
      }}
      renderItem={({ item, ...rest }) =>
        isLoading ? (
          <JobCardLoading />
        ) : (
          <TouchableHighlight
            style={{ marginBottom: 16 }}
            key={`${item.id}`}
            onPress={(e) => history.push(`/jobs/${item.id}`)}
            activeOpacity={0.8}
            underlayColor="transparent"
            {...rest}
          >
            <JobCard item={item} {...rest} />
          </TouchableHighlight>
        )
      }
      ListEmptyComponent={() => (
        <View>
          <Text style={{ color: colors.gray600 }}>No Jobs Found</Text>
        </View>
      )}
    />
  );
};
