import { useCallback } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { BaseRecord, Column } from "./types";
import { TextCell } from "./TextCell";

export interface RowProps<TRecord extends BaseRecord> {
  value: TRecord;
  columns: Column<TRecord>[];
  onPress?: (record: TRecord) => void;
}

export function Row<TRecord extends BaseRecord>({
  columns,
  value,
  onPress,
}: RowProps<TRecord>) {
  const cells = columns.map((column) => {
    let cellContent = column.render({ column, row: value });

    if (typeof cellContent === "string") {
      cellContent = <TextCell>{cellContent}</TextCell>;
    }

    return (
      <View
        key={`${column.name}_cell`}
        style={[
          {
            width: column.relativeWidth,
            flexGrow: column.relativeWidth,
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,

            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          },
          column.style,
        ]}
      >
        {cellContent}
      </View>
    );
  });

  const handlePress = useCallback(() => {
    onPress(value);
  }, [onPress, value]);

  if (!onPress) {
    return <View style={styles.root}>{cells}</View>;
  }

  return (
    <TouchableOpacity style={styles.root} onPress={handlePress}>
      <View style={styles.inner}>{cells}</View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    padding: 16,
  },
  inner: {
    flex: 1,
    flexDirection: "row",
  },
});
