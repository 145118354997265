import { useCallback } from "react";
import { View, StyleSheet } from "react-native";

import { Button, Typography, useTheme } from "@smartrent/ui";

import { Close } from "@smartrent/icons";

import { useHubPrepTerminateMutation } from "@/api/hub-prep";

import { HubPrepLog } from "@/types";

interface TerminateButtonProps {
  serial: HubPrepLog["serial"];
}

export const HubPrepLogTerminateButton = ({ serial }: TerminateButtonProps) => {
  const { colors } = useTheme();

  const [hubPrepTerminateMutation] = useHubPrepTerminateMutation();

  const handleTerminateHubPrep = useCallback(async () => {
    await hubPrepTerminateMutation({ serial });
  }, [hubPrepTerminateMutation, serial]);

  return (
    <View style={styles.root}>
      <Button onPress={handleTerminateHubPrep} variation="plain">
        <Close size={18} color={colors.error} style={styles.icon} />
        <Typography>Terminate Hub Prep</Typography>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    marginHorizontal: 4,
  },
  icon: { marginRight: 2 },
});
