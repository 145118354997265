import * as React from "react";
import { View, StyleSheet } from "react-native";
import { useHistory, useLocation } from "react-router-dom";

import { Text, useTheme } from "@smartrent/ui";

import { useIsDesktop } from "@/lib/hooks";
import helpers from "@/lib/helpers";

import Pagination from "../Pagination";

interface PaginatedTablePaginatorProps {
  totalRecords: number;
  currentPage: number;
  totalPages: number;
  isLoading: boolean;
  isEmpty: boolean;
}

const PaginatedTablePaginator: React.FC<
  React.PropsWithChildren<PaginatedTablePaginatorProps>
> = ({ totalRecords, currentPage, totalPages, isLoading, isEmpty }) => {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const location = useLocation();
  const { colors } = useTheme();
  const filters = helpers.qsFromLocation(location);

  const limit = Number(filters?.limit) || 25;

  const page = `${(
    (currentPage - 1) * limit +
    1
  ).toLocaleString()} - ${(currentPage * limit > totalRecords
    ? totalRecords
    : currentPage * limit
  ).toLocaleString()}`;

  const resultText = `${page} of ${totalRecords.toLocaleString()}`;

  const pageText = `${currentPage} of ${totalPages} Page${
    totalPages !== 1 ? "s" : ""
  }`;

  return (
    <View
      style={[
        styles.paginationStyles,
        {
          backgroundColor: colors.gray050,
        },
        !isDesktop && styles.column,
      ]}
    >
      <View style={[styles.pageContainer, !isDesktop && styles.hide]}>
        {!isLoading && !isEmpty && <Text style={styles.bold}>{pageText}</Text>}
      </View>
      <View style={styles.flex}>
        <Pagination
          history={history}
          location={location}
          totalPages={totalPages}
        />
      </View>
      <View style={[styles.resultsContainer, !isDesktop && styles.hide]}>
        {!isLoading && !isEmpty && (
          <Text style={styles.bold}>{resultText}</Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  column: { flexDirection: "column" },
  paginationStyles: {
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
    height: 74,
    justifyContent: "space-between",
    paddingHorizontal: 8,
    paddingVertical: 16,
    width: "100%",
  },
  bold: { fontWeight: "500" },
  pageContainer: { flex: 1, alignItems: "flex-start" },
  resultsContainer: { flex: 1, alignItems: "flex-end" },
  hide: { display: "none" },
});

export default PaginatedTablePaginator;
