import SearchResult from "./SearchResult";

export default function SearchResults({
  searchResults,
  setSearchResults,
  searchError,
  setKeywords,
  keywords,
  searchLoading,
}) {
  let resultsDOM;
  if (!keywords) {
    resultsDOM = null;
  } else if (searchLoading) {
    resultsDOM = <div className="u-p16 u-sans-bold">Loading...</div>;
  } else if (searchError) {
    resultsDOM = (
      <div className="u-p16 u-text-symbolic-error">{searchError}</div>
    );
  } else if (
    !(searchResults && searchResults.length) &&
    searchResults !== null
  ) {
    resultsDOM = (
      <div className="u-p16">
        No results{" "}
        <span role="img" aria-label="grimace emoji">
          😬
        </span>
      </div>
    );
  } else if (searchResults && searchResults.length) {
    resultsDOM = searchResults.map((result, idx) => (
      <SearchResult
        key={idx}
        result={result}
        callbacks={{ setKeywords, setSearchResults }}
      />
    ));
  }

  return <ul className="list list--striped">{resultsDOM}</ul>;
}
