import { useState } from "react";
import * as React from "react";
import { FlatList, View, StyleSheet } from "react-native";

import { startCase } from "lodash-es";
import {
  ActivityIndicator,
  Button,
  Typography,
  Drawer,
  DrawerContent,
  DrawerHeader,
  ListItemContainer,
  ListItemLabelValue,
  FlatListItemSeparator,
  Banner,
  BannerIcon,
} from "@smartrent/ui";
import {
  Refresh,
  ExclamationCircleOutline,
  CheckCircleSolid,
} from "@smartrent/icons";

import { EM_DASH } from "@/utils/chars";
import helpers from "@/lib/helpers";

import { useUser } from "@/layout/Context";
import {
  useBackupCodeLogsQuery,
  useGetBackupCodesQuery,
  useResetBackupCodeMutation,
} from "@/api";
import { HubRequest } from "@/types";

export interface BackupAccessCodesProps {
  hub: HubRequest;
  open: boolean;
  onClose: () => void;
}

const CodeReset = ({
  backupCode,
  label,
  resetCode,
  hub,
  refetch,
  isFetching,
  setShouldShowMessage,
}) => {
  const { total_locks, on_locks, code, on_primary_lock } = backupCode || {};

  if (!code) {
    return (
      <View style={styles.codeWrapper}>
        <View style={styles.leftContent}>
          <View style={styles.codeType}>
            <Typography type="title5">{label}:</Typography>
            <Typography
              type="title5"
              color="textSecondary"
              style={styles.codeMargin}
            >
              Code not yet created {"\u2014"} try re-pairing the lock
            </Typography>
          </View>
        </View>
      </View>
    );
  }
  return (
    <View style={styles.codeWrapper}>
      <View style={styles.leftContent}>
        <View style={styles.codeType}>
          <Typography type="title5">{label}:</Typography>
          <Typography
            type="title5"
            color="textSecondary"
            style={styles.codeMargin}
          >
            {code}
          </Typography>
        </View>
        <View style={styles.codeStatus}>
          <Typography type="body">{`On ${on_locks}/${total_locks} locks`}</Typography>
          <Typography color="textSecondary" type="body">
            {on_primary_lock ? " (On primary lock)" : " (Not on primary lock)"}
          </Typography>
          {on_locks !== total_locks ? (
            <Button
              variation="plain"
              style={{ marginLeft: 4 }}
              onPress={() => refetch()}
            >
              {isFetching ? <ActivityIndicator size={22} /> : <Refresh />}
            </Button>
          ) : null}
        </View>
      </View>
      <View style={styles.buttonWrapper}>
        <Button
          onPress={() => {
            setShouldShowMessage(true);
            setTimeout(() => {
              setShouldShowMessage(false);
              refetch();
            }, 5000);
            resetCode({
              codeId: backupCode.id,
              unitId: hub?.unit_id,
            });
          }}
        >
          Reset
        </Button>
      </View>
    </View>
  );
};

export const BackupAccessCodes: React.FC<
  React.PropsWithChildren<Partial<BackupAccessCodesProps>>
> = ({ hub, open, onClose }) => {
  const { permissions } = useUser();
  const [shouldShowMessage, setShouldShowMessage] = useState(false);
  const { data, isFetching, refetch } = useGetBackupCodesQuery(
    { unitId: hub?.unit_id },
    { enabled: !!hub?.unit_id && permissions.emergency_codes }
  );
  const { data: logs } = useBackupCodeLogsQuery(
    { unitId: hub?.unit_id },
    { enabled: !!hub?.unit_id && permissions.emergency_codes }
  );

  const [resetBackupAccessCode, { isError, isSuccess }] =
    useResetBackupCodeMutation();

  const codeType = (codeType) => {
    return startCase(codeType.replace("_emergency_code", ""));
  };

  if (!permissions.emergency_codes) {
    return null;
  }

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerHeader title="Backup Access Codes" />
      <View style={styles.container}>
        <DrawerContent>
          <View style={styles.codeDescription}>
            <Typography type="body">
              As an installer, you can store backup access codes you created
              manually during the install process.
              <Typography type="body" font="light">
                {" "}
                Note: Storing backup codes does not automatically add the backup
                access codes to the lock. A new backup access code will only be
                automatically added to the lock when the "Reset" button is used.
              </Typography>
            </Typography>
          </View>
          {!data?.locks?.length ? (
            <Banner
              leftDetail={<BannerIcon icon={ExclamationCircleOutline} />}
              title="This unit does not currently have any Z-Wave locks configured."
              style={{ marginBottom: 16 }}
              color="warning"
            />
          ) : null}
          {isError && shouldShowMessage ? (
            <Banner
              leftDetail={<BannerIcon icon={ExclamationCircleOutline} />}
              title="There was a problem resetting the access code."
              style={{ marginBottom: 16 }}
              color="error"
            />
          ) : null}
          {isSuccess && shouldShowMessage ? (
            <Banner
              leftDetail={<BannerIcon icon={CheckCircleSolid} />}
              title="Reset code was successful."
              style={{ marginBottom: 16 }}
              color="success"
            />
          ) : null}
          <CodeReset
            label="Installer Code"
            backupCode={data?.installer_code}
            resetCode={resetBackupAccessCode}
            hub={hub}
            refetch={refetch}
            isFetching={isFetching}
            setShouldShowMessage={setShouldShowMessage}
          />
          <CodeReset
            label="PM Code"
            backupCode={data?.property_manager_code}
            resetCode={resetBackupAccessCode}
            hub={hub}
            refetch={refetch}
            isFetching={isFetching}
            setShouldShowMessage={setShouldShowMessage}
          />
          <CodeReset
            label="Resident Code"
            backupCode={data?.resident_code}
            resetCode={resetBackupAccessCode}
            hub={hub}
            refetch={refetch}
            isFetching={isFetching}
            setShouldShowMessage={setShouldShowMessage}
          />

          <View style={styles.logWrapper}>
            <Typography type="title2">Change Log</Typography>
            <Typography
              type="body"
              color="textSecondary"
              style={styles.codeDescription}
            >
              Chronological change log for backup codes
            </Typography>
            <View>
              {logs?.installer_codes?.records.length ? (
                <View>
                  <Typography type="title4">Installer Codes</Typography>
                  <FlatList
                    data={logs?.installer_codes?.records}
                    keyExtractor={(item) => `${item.code}-${item.changed_at}`}
                    renderItem={({ item }) => (
                      <ListItemContainer>
                        <ListItemLabelValue
                          label={`${codeType(
                            item.type
                          )} Code changed at: ${helpers.formatDate(
                            item.changed_at
                          )}`}
                          value={`Code: ${item.code} | User: ${
                            item.user ? item.user : EM_DASH
                          }`}
                        />
                      </ListItemContainer>
                    )}
                    ItemSeparatorComponent={FlatListItemSeparator}
                  />
                </View>
              ) : null}
              {logs?.resident_codes?.records.length ? (
                <View>
                  <Typography type="title4">Resident Codes</Typography>
                  <FlatList
                    data={logs?.resident_codes?.records}
                    keyExtractor={(item) => `${item.code}-${item.changed_at}`}
                    renderItem={({ item }) => (
                      <ListItemContainer>
                        <ListItemLabelValue
                          label={`${codeType(
                            item.type
                          )} Code changed at: ${helpers.formatDate(
                            item.changed_at
                          )}`}
                          value={`Code: ${item.code} | User: ${
                            item.user ? item.user : EM_DASH
                          }`}
                        />
                      </ListItemContainer>
                    )}
                    ItemSeparatorComponent={FlatListItemSeparator}
                  />
                </View>
              ) : null}
              {logs?.property_manager_codes?.records.length ? (
                <View>
                  <Typography type="title4">Property Manager Codes</Typography>
                  <FlatList
                    data={logs?.property_manager_codes?.records}
                    keyExtractor={(item) => `${item.code}-${item.changed_at}`}
                    renderItem={({ item }) => (
                      <ListItemContainer>
                        <ListItemLabelValue
                          label={`${codeType(
                            item.type
                          )} Code changed at: ${helpers.formatDate(
                            item.changed_at
                          )}`}
                          value={`Code: ${item.code} | User: ${
                            item.user ? item.user : EM_DASH
                          }`}
                        />
                      </ListItemContainer>
                    )}
                    ItemSeparatorComponent={FlatListItemSeparator}
                  />
                </View>
              ) : null}
            </View>
          </View>
        </DrawerContent>
      </View>
    </Drawer>
  );
};

const styles = StyleSheet.create({
  buttonWrapper: {
    flex: 1,
    marginLeft: 8,
  },
  codeMargin: { marginLeft: 4 },
  codeStatus: { flexDirection: "row" },
  codeType: { flexDirection: "row", marginBottom: 4 },
  leftContent: { flex: 2, flexDirection: "column", marginBottom: 8 },
  codeWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  codeDescription: { marginBottom: 16 },
  container: { height: "100%" },
  logWrapper: { marginTop: 32 },
});
