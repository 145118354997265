import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { useTheme } from "@smartrent/ui";
import { ChevronRight } from "@smartrent/icons";

import { useIsDesktop } from "@/lib/hooks";

import { PaginatedTableColumn } from "./PaginatedTableColumnHeader";

interface PaginatedTableRowProps {
  columns: PaginatedTableColumn[];
  item: any;
  row: number;
  onRowPress?(r: unknown): void;
}

const PaginatedTableRow: React.FC<
  React.PropsWithChildren<PaginatedTableRowProps>
> = ({ columns, item, row, onRowPress }) => {
  const numberOfColumns = columns.length;
  const isDesktop = useIsDesktop();
  const { colors } = useTheme();

  const renderItems = useCallback(
    () => (
      <View
        style={[
          styles.container,
          { borderBottomColor: colors.border },
          // @ts-expect-error
          onRowPress ? { cursor: "pointer" } : undefined,
          !isDesktop && styles.mobileContainer,
        ]}
      >
        {columns
          .filter((column) => !column.hidden)
          .map((column, index) => {
            return (
              <View
                key={`row${row}_${column.field}`}
                style={[styles.column, column.style]}
              >
                {column.render(item)}
                {onRowPress && index + 1 === numberOfColumns && (
                  // @ts-ignore
                  <ChevronRight style={{ position: "absolute", right: 0 }} />
                )}
              </View>
            );
          })}
      </View>
    ),
    [columns, item, numberOfColumns, isDesktop, onRowPress, row]
  );

  return (
    <View>
      {onRowPress ? (
        <TouchableOpacity key={`item_${row}`} onPress={() => onRowPress(item)}>
          {renderItems()}
        </TouchableOpacity>
      ) : (
        renderItems()
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mobileContainer: {
    flexDirection: "column",
  },
  container: {
    alignItems: "center",
    flexDirection: "row",
    borderBottomWidth: 1,
    paddingVertical: 22,
  },
  column: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 12,
    flexDirection: "row",
    flexBasis: 1,
  },
});

export default PaginatedTableRow;
