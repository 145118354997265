import { instance } from "@/lib/hooks";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { HubPrepAction } from "@/types";
import helpers from "@/lib/helpers";

export interface HubPrepTerminateResponse {
  response: number;
}

export interface HubPrepTerminateOptions {
  serial: string;
}

export const useHubPrepTerminateMutation = createAxiosMutation(
  async ({ serial }: HubPrepTerminateOptions) => {
    return instance.post<HubPrepTerminateResponse>(`/hubs/${serial}/terminate`);
  },
  {
    successToast: () => ({
      message: "Successfully terminated the hub prep session.",
    }),
    errorToast: (err) => ({
      message: "Unable to terminate hub prep.",
    }),
  }
);

interface HubPrepOptions {
  serials: string[];
  action: HubPrepAction;
  advancedOptions: any;
}

export const useHubPrepMutation = createAxiosMutation(
  async (payload: HubPrepOptions) => {
    return instance.post(`/hub-prep-logs`, payload);
  },
  {
    successToast: () => ({
      message: "Started hub account registration.",
    }),
    errorToast: () => ({
      message: "Unable to start hub account registration.",
    }),
  }
);

interface Actions {
  name: string;
  value: string;
}

interface AdvancedHubOptionsResponse {
  actions: Actions[];
}

export const useAdvancedOptionsQuery = createAxiosQuery(
  "hub-advanced-options",
  async () =>
    instance.get<AdvancedHubOptionsResponse>(`/hub-prep/advanced-options`)
);

export const useRetryFirmwareUpgradeMutation = createAxiosMutation(
  async (hub_id_or_serial: string | number) =>
    helpers.zipato.firmware.upgrade({ hub_id_or_serial }),
  {
    errorToast: () => ({
      message:
        "An error occurred while retrying firmware download.\nPlease try again later.",
    }),
    successToast: () => ({
      message:
        "Retrying firmware upgrade. Please wait up to 5 minutes before performing additional actions on this hub.",
    }),
  }
);
