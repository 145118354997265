import * as React from "react";

import { StyleSheet, View, ViewProps } from "react-native";

export const Row: React.FC<React.PropsWithChildren<ViewProps>> = ({
  style,
  ...props
}) => {
  return <View style={[styles.root, style]} {...props}></View>;
};

const styles = StyleSheet.create({
  root: { flexDirection: "row", alignItems: "center" },
});
