import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  Button,
  ConfirmDialog,
  DrawerContent,
  DrawerHeader,
  FormikSelectField,
  FormikSubmit,
  HStack,
  ListItemContainer,
  ListItemLabelValue,
  Result,
  TextInputField,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { Locked, Unlocked } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { OrganizationProps, TppViewProps } from "@/types";
import {
  selectQueryPropsTppView,
  useLockTppMutation,
  useTppViewQuery,
  useUnlockTppMutation,
} from "@/api";
import { FormikSelectGroupField } from "@/common/groups/SelectGroupField/SelectGroupField";

interface GroupTransferFormValuesProps {
  providerId: string;
  groupId: string;
}

interface PmSyncActionsProps {
  organization: OrganizationProps;
}

export const PmSyncActions: React.FC<
  React.PropsWithChildren<PmSyncActionsProps>
> = ({ organization }) => {
  const { colors } = useTheme();
  const { visible: isModalVisible, onOpen, onClose } = useModalState();
  const [integrationToLock, setIntegrationToLock] = React.useState<{
    groupId: string;
    providerId: string;
  } | null>(null);

  const [lockIntegrationMutation] = useLockTppMutation();

  return (
    <>
      <DrawerHeader
        title="PM Sync Actions"
        // subtitle="Locking the PMS will not allow the SmartRent Manager user to make changes for their integration."
      />
      <DrawerContent>
        <View>
          <Formik<GroupTransferFormValuesProps>
            initialValues={{
              providerId: "",
              groupId: "",
            }}
            validationSchema={Yup.object({
              providerId: Yup.string().required(),
              groupId: Yup.string().required(),
            })}
            onSubmit={async (values, actions) => {
              setIntegrationToLock({
                groupId: values.groupId,
                providerId: values.providerId,
              });
              onOpen();
              actions.setSubmitting(false);
            }}
          >
            <GroupTransferForm organization={organization} />
          </Formik>
        </View>
        <View
          style={[
            {
              borderColor: colors.border,
            },
            styles.lockedIntegrationsContainer,
          ]}
        >
          <HStack
            style={[
              {
                borderColor: colors.border,
              },
              styles.lockedIntegrationHeader,
            ]}
            spacing={8}
          >
            <Locked color={colors.helperText} />
            <Typography type="bodySemibold" color="helperText">
              LOCKED PM SYNC INTEGRATIONS
            </Typography>
          </HStack>

          <LockedItems organization={organization} />
        </View>
      </DrawerContent>
      <ConfirmDialog
        title="Lock PM Sync Integration"
        description="Are you sure you want to lock this PM Sync integration?"
        visible={isModalVisible}
        onConfirm={() => {
          if (!integrationToLock) {
            return;
          }
          lockIntegrationMutation(integrationToLock);
          setIntegrationToLock(null);
          onClose();
        }}
        onClose={onClose}
      />
    </>
  );
};

interface DefinedTppViewProps extends TppViewProps {
  group_id: string | number;
  provider_id: string | number;
}

interface LockedItemsProps {
  organization: OrganizationProps;
}
const LockedItems: React.FC<React.PropsWithChildren<LockedItemsProps>> = ({
  organization,
}) => {
  const [integrationToUnlock, setIntegrationToUnlock] = React.useState<{
    groupId: string | number;
    providerId: string | number;
  } | null>(null);

  const { visible: isModalVisible, onOpen, onClose } = useModalState();

  const { data: lockedIntegrations } = useTppViewQuery({
    filters: {
      organizationId: organization.id,
      sortBy: "provider",
      orderBy: "asc",
      limit: 9999,
      locked: true,
    },
  });

  const [unlockIntegrationMutation] = useUnlockTppMutation();

  const unlockIntegration = useCallback(
    ({
      groupId,
      providerId,
    }: {
      groupId: string | number;
      providerId: string | number;
    }) => {
      unlockIntegrationMutation({ groupId, providerId });
    },
    [unlockIntegrationMutation]
  );

  const lockedItems = useMemo(() => {
    if (!lockedIntegrations?.records?.length) {
      return (
        <View style={styles.emptyLockedItems}>
          <Result
            title=""
            description="No Locked Integrations Found"
            status="error"
            graphic
          />
        </View>
      );
    }
    return lockedIntegrations.records
      .filter(
        (item): item is DefinedTppViewProps =>
          item && item.group_id !== null && item.provider_id !== null
      )
      .map((item) => (
        <ListItemContainer
          key={item.provider_configuration_id}
          rightDetail={
            <Button
              size="small"
              iconLeft={Unlocked}
              onPress={() => {
                setIntegrationToUnlock({
                  groupId: item.group_id,
                  providerId: item.provider_id,
                });
                onOpen();
              }}
            >
              Unlock
            </Button>
          }
        >
          <ListItemLabelValue label={item.group} value={item.provider} />
        </ListItemContainer>
      ));
  }, [lockedIntegrations, onOpen]);

  return (
    <>
      {lockedItems}
      <ConfirmDialog
        title="Unlock Integration"
        description="Are you sure you want to unlock this PM Sync integration?"
        visible={isModalVisible}
        onConfirm={() => {
          if (!integrationToUnlock) {
            return;
          }
          unlockIntegration(integrationToUnlock);
          setIntegrationToUnlock(null);
          onClose();
        }}
        onClose={onClose}
      />
    </>
  );
};

interface GroupTransferFormProps {
  organization: OrganizationProps;
}

const GroupTransferForm: React.FC<
  React.PropsWithChildren<GroupTransferFormProps>
> = ({ organization }) => {
  const { colors } = useTheme();
  const { values, isValid } = useFormikContext<GroupTransferFormValuesProps>();

  const integrationProps = selectQueryPropsTppView({
    organizationId: organization.id,
    groupId: values?.groupId,
    sortBy: "provider",
    orderBy: "asc",
    limit: 9999,
    locked: false,
  });

  return (
    <VStack spacing={16}>
      <TextInputField
        label="Organization Name"
        textInputProps={{ value: organization.name }}
        required
        disabled
      />

      <FormikSelectGroupField name="groupId" organizationId={organization.id} />

      {values?.groupId ? (
        <FormikSelectField
          label="Select an Integration"
          name="providerId"
          getOptionValue={({ provider_id }) => provider_id}
          getOptionLabel={({ provider }) => provider}
          {...integrationProps}
          disabled={!values?.groupId}
        />
      ) : null}

      <VStack style={[{ borderColor: colors.border }, styles.formActions]}>
        <FormikSubmit
          size="small"
          iconLeft={Locked}
          label="Lock"
          disabled={!isValid}
          style={styles.submitButton}
        />
      </VStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  lockedIntegrationsContainer: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    marginTop: 32,
  },
  lockedIntegrationHeader: {
    borderBottomWidth: 1,
    padding: 16,
  },
  emptyLockedItems: {
    paddingHorizontal: 16,
    paddingTop: 32,
    paddingBottom: 0,
  },
  formActions: {
    borderTopWidth: 1,
    paddingTop: 16,
  },
  submitButton: {
    width: "100%",
  },
});
