import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import { QueryObserverResult } from "@tanstack/react-query";
import { Formik, FormikHelpers } from "formik";
import { AxiosError, AxiosResponse } from "axios";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
  Link,
  Typography,
  VStack,
} from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";
import { useGlobalContext } from "@/layout/Context";
import {
  GroupForm,
  GroupFormValues,
  validationSchema,
} from "@/common/groups/GroupForm";

import { useCreateGroupMutation } from "@/api";

import {
  CELSIUS,
  FAHRENHEIT,
  countries,
  timezoneOptions,
  states,
  buildUrl,
} from "@/utils";

import { GroupProps, GroupsTableApiResponse } from "@/types";

interface CreateGroupToastMessageProps
  extends Pick<GroupProps, "id" | "marketing_name"> {}
export const CreateGroupToastMessage: React.FC<
  React.PropsWithChildren<CreateGroupToastMessageProps>
> = ({ id, marketing_name }) => {
  const { href } = buildUrl({
    pathname: `/groups/${id}`,
  });

  return (
    <Typography>
      Created Group:{" "}
      <Link color="link" style={styles.link} href={href}>
        {marketing_name}
      </Link>
    </Typography>
  );
};
interface CreateGroupProps {
  organizationId: string | number;
  // used to trigger updating the group's table with new org
  refetch?: () => Promise<
    QueryObserverResult<GroupsTableApiResponse, AxiosError<any>>
  >;
}

export const CreateGroup: React.FC<
  React.PropsWithChildren<CreateGroupProps>
> = ({ organizationId, refetch }) => {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;
  const browserLocale = Intl.DateTimeFormat().resolvedOptions()?.locale;

  const { pop } = useDrawerNav();

  const { setToast } = useGlobalContext();

  const [createGroup] = useCreateGroupMutation();

  const handleCancel = useCallback(() => pop(), [pop]);

  const handleSubmit = useCallback(
    async (
      values: GroupFormValues,
      actions: FormikHelpers<GroupFormValues>
    ) => {
      actions.setSubmitting(true);
      try {
        const body = validationSchema.cast(values); // transform using yup validation rules

        const { data: group } = (await createGroup({
          organizationId,
          body,
        })) as AxiosResponse;

        refetch ? refetch() : null; // refetch if available
        pop();

        setToast({
          type: "success",
          title: "Success!",
          message: <CreateGroupToastMessage {...group} />,
        });
      } catch (error) {
        const solicitErrors = (error as any)?.response?.data?.errors;
        if (solicitErrors && solicitErrors.length) {
          solicitErrors.forEach(({ field, description }) =>
            actions.setFieldError(field, description)
          );
        } else {
          pop();
          console.error(error);
          setToast({
            type: "error",
            title: "Error",
            message: "Something went wrong. Please contact engineering.",
          });
        }
      }
      actions.setSubmitting(false);
    },
    [createGroup, organizationId, refetch, setToast, pop]
  );

  const defaultTemperatureScale = useMemo(
    () => (browserLocale === "en-US" ? FAHRENHEIT : CELSIUS),
    [browserLocale]
  );

  const defaultCountry = useMemo(
    () =>
      browserLocale === "en-US"
        ? countries.find((country) => country.value === "United States")
            ?.value ?? ""
        : "",
    [browserLocale]
  );

  const defaultTimezone = useMemo(() => {
    return (
      timezoneOptions.find(({ value }) => value === browserTimeZone)?.value ??
      ""
    );
  }, [browserTimeZone]);

  const defaultState = useMemo(
    () =>
      browserLocale === "en-US" && browserTimeZone === "America/Phoenix"
        ? states.find(({ value }) => value === "AZ")?.value ?? ""
        : "",
    [browserLocale, browserTimeZone]
  );

  const initialValues = useMemo<GroupFormValues>(
    () => ({
      marketing_name: "",
      temperature_scale: defaultTemperatureScale,
      timezone: defaultTimezone,
      state: defaultState,
      zip: "",
      country: defaultCountry,
    }),
    [defaultCountry, defaultTemperatureScale, defaultTimezone, defaultState]
  );

  return (
    <>
      <DrawerHeader title="Create Group" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <>
          <DrawerContent contentContainerStyle={styles.drawerContent}>
            <VStack spacing={8}>
              <GroupForm {...initialValues} />
            </VStack>
          </DrawerContent>
          <DrawerActions>
            <Button onPress={handleCancel} variation="plain">
              Cancel
            </Button>
            <FormikSubmit label="Create" submittingLabel="Creating..." />
          </DrawerActions>
        </>
      </Formik>
    </>
  );
};

const styles = StyleSheet.create({
  drawerContent: { paddingTop: 16 },
  link: { textDecorationLine: "underline" },
});
