import { StyleSheet } from "react-native";

import { HStack, Typography } from "@smartrent/ui";

import { formatNumber } from "@/utils/format-number";

interface HubRefurbishStepsStatsProp {
  label: string;
  numerator?: number;
  denominator?: number;
  width?: number;
  style?: any;
  title?: string;
  titleProps?: any;
  labelProps?: any;
}
export const HubRefurbishStepPanelHeaderTitle = ({
  label,
  numerator,
  denominator,
  style,
  title,
  titleProps = { type: "title3" },
  labelProps = { type: "bodyLarge" },
}: HubRefurbishStepsStatsProp) => {
  return (
    <HStack spacing={4} style={[styles.root, style]}>
      <Typography {...titleProps}>{title}</Typography>
      <Typography {...labelProps}>{label}</Typography>
      {denominator && denominator !== 0 ? (
        <Typography type="bodyLarge" color="primary">
          | {formatNumber(numerator)}/{formatNumber(denominator)}
        </Typography>
      ) : null}
    </HStack>
  );
};

const styles = StyleSheet.create({
  root: { flex: 1, alignItems: "center" },
});
