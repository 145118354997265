import moment from "moment";

export default function MostRecentOccurrence({ da }) {
  if (!(da && da.last_read_state_changed_at)) {
    return null;
  }
  return da.last_read_state_changed_at ? (
    <span>updated {moment(da.last_read_state_changed_at).fromNow()}</span>
  ) : null;
}
