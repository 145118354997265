import { useContext } from "react";
import { Link } from "react-router-dom";

import Helpers from "@/lib/helpers";
import Graph from "@/common/Graph";
import Context from "@/layout/Context";

import { AccountProps } from "../../types";

export default function AccountAudit() {
  const { user } = useContext(Context);

  if (!Helpers.auth.can(user, "modify_permissions")) {
    return null;
  }

  return (
    <Graph
      path="/reports/account-audit"
      dataKey="accounts"
      leftFooterRenderProps={() => <div>Account Audit</div>}
      renderProps={(accounts: AccountProps[]) => {
        const modifyPermissionAccounts = accounts.filter(
          (a) => a?.permissions?.["modify_permissions"]
        );

        const elevated_activity = accounts.filter(
          (a) => a?.permissions?.["elevated_activity"]
        );

        const account_management = accounts.filter(
          (a) => a?.permissions?.["account_management"]
        );

        const hub_extended_details = accounts.filter(
          (a) => a?.permissions?.["hub_extended_details"]
        );

        return (
          <div className="u-flex u-flex-wrap">
            <div className="u-mright16">
              <Link className="u-link u-link-clean" to="/accounts">
                <span className="u-text-gray-600">Total: </span>
                {accounts.length}
              </Link>
            </div>
            <div className="u-mright16">
              <Link
                className="u-link u-link-clean"
                to="/accounts?permission=elevated_activity"
              >
                <span className="u-text-gray-600">Elevated Activity: </span>
                {elevated_activity.length}
              </Link>
            </div>
            <div className="u-mright16">
              <Link
                className="u-link u-link-clean"
                to="/accounts?permission=account_management"
              >
                <span className="u-text-gray-600">Account Management: </span>
                {account_management.length}
              </Link>
            </div>

            <div className="u-mright16">
              <Link
                className="u-link u-link-clean"
                to="/accounts?permission=hub_extended_details"
              >
                <span className="u-text-gray-600">Hub Extended Details: </span>
                {hub_extended_details.length}
              </Link>
            </div>
            <div className="u-mright16">
              <Link
                className="u-link u-link-clean"
                to="/accounts?permission=modify_permissions"
              >
                <span className="u-text-gray-600">Can Provision: </span>
                {modifyPermissionAccounts.length}
              </Link>
            </div>
          </div>
        );
      }}
    />
  );
}
