import { GroupProps } from "@/types";

export const formatAddress = (
  group:
    | Partial<
        Pick<
          GroupProps,
          | "street_address_1"
          | "street_address_2"
          | "city"
          | "state"
          | "zip"
          | "country"
        >
      >
    | undefined
) => {
  if (!group) {
    return "";
  }
  return joinAddress(
    [
      joinAddress([group?.street_address_1, group?.street_address_2], " "),
      group?.city,
      joinAddress([group?.state, group?.zip], " "),
      group?.country,
    ],
    ", "
  );
};

const joinAddress = (
  addressFragments: Array<string | undefined>,
  separator: string
) =>
  addressFragments
    .filter((v) => !!v)
    .join(separator)
    .trim();
