import { useCallback, useState } from "react";

import { Switch, Slider, Typography } from "@smartrent/ui";
import { View } from "react-native";

type $Props = {
  isAutoRefreshing: boolean;
  updateAutoRefreshInterval: (interval: number) => void;
  pauseAutoRefresh: () => void;
  startAutoRefresh: () => void;
};

export default function AutoRefresh({
  isAutoRefreshing,
  pauseAutoRefresh,
  startAutoRefresh,
  updateAutoRefreshInterval,
}: $Props) {
  const [refreshInterval, setRefreshInterval] = useState(1);

  const formatValue = useCallback((interval: number) => {
    return `${interval} ${interval > 1 ? "mins" : "min"}`;
  }, []);

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Switch
        label="Auto-Refresh"
        name="auto-refresh"
        value={isAutoRefreshing}
        onValueChange={(value) => {
          if (value) {
            startAutoRefresh();
          } else {
            pauseAutoRefresh();
          }
        }}
      />
      <View style={{ flex: 1, minWidth: 250, marginLeft: 16 }}>
        <Typography type="title6">Refresh Interval</Typography>
        <Slider
          name="refreshInterval"
          value={refreshInterval}
          onValueChange={(value) => {
            setRefreshInterval(value);
            updateAutoRefreshInterval(value * 60 * 1000);
          }}
          step={1}
          min={1}
          max={10}
          formatValue={formatValue}
        />
      </View>
    </View>
  );
}
