import { useMutation, useQueryClient } from "@tanstack/react-query";
import { create } from "zustand";

import {
  PaginatedResponse,
  useFlatListQuery,
  useReducedInfiniteQuery,
} from "@smartrent/hooks";

import { ListQueryResponse } from "@smartrent/ui";

import { instance } from "@/lib/hooks";
import { JobTask } from "@/types";

export type FlagTaskRequest = {
  task_id: string;
  reason: string;
  created_by_email: string;
  job_id: string;
  project_id: string | null;
};

export type UpdateFlagTaskRequest = {
  reason: string;
  completed_at?: Date;
  completed_by_email?: string;
};

type UseFlaggedTasksCountState = {
  count: number;
  setCount(count: number): void;
};

export const useFlaggedTasksCount = create<UseFlaggedTasksCountState>(
  (set) => ({
    count: 0,
    setCount: (count) =>
      set(() => ({
        count,
      })),
  })
);

export const useGetTasksByJobId = (jobId: string) => {
  return useReducedInfiniteQuery(
    ["tasks", jobId],
    async ({ pageParam }) => {
      const response = await instance.get<PaginatedResponse<JobTask>>(
        `/jobs/${jobId}/tasks`,
        {
          params: {
            page: pageParam,
          },
        }
      );

      return response.data;
    },
    {
      enabled: !!jobId,
    }
  );
};

export const useTasksFlatList = (
  jobId: string,
  filters?: { type: JobTask["type"] }
) =>
  useFlatListQuery<ListQueryResponse<JobTask>>(
    ["tasks", "flat-list", jobId, filters],
    async ({ pageParam }) =>
      (
        await instance.get(`/jobs/${jobId}/tasks`, {
          params: {
            ...filters,
            page: pageParam,
          },
        })
      ).data
  );

export const useCreateFlagTask = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: FlagTaskRequest) => instance.post(`/task-flags`, data),
    {
      onSuccess() {
        queryClient.invalidateQueries(["tasks", "flat-list"]);
        queryClient.invalidateQueries(["jobs"]);
      },
    }
  );
};

export const useUpdateFlagTask = (taskFlagId: string | null) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateFlagTaskRequest) =>
      instance.put(`/task-flags/${taskFlagId}`, data),
    {
      onSuccess() {
        queryClient.invalidateQueries(["tasks", "flat-list"]);
      },
    }
  );
};
