export default function FailedAttempts({ da }) {
  if (!da.failed_attempts) {
    return null;
  }

  return (
    <div className="u-text-symbolic-warning u-font12">
      {da.failed_attempts} cumulative failed attempt
      {da.failed_attempts !== 1 ? "s" : ""}
    </div>
  );
}
