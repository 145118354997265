import { View, StyleSheet } from "react-native";
import { Typography } from "@smartrent/ui";

export const CircleWithInfo = ({ circleColor, icon, label, value }) => {
  return (
    <View style={styles.flexRow}>
      <Circle color={circleColor} icon={icon} />
      <View style={styles.marginLeft}>
        <Typography type="caption" color="textSecondary">
          {label}:
        </Typography>
        <Typography type="body" color="textPrimary">
          {value.replace(" ", "\n")}
        </Typography>
      </View>
    </View>
  );
};

const Circle = ({ color, icon }) => {
  return (
    <View style={[styles.circle, { backgroundColor: color }]}>{icon}</View>
  );
};

const styles = StyleSheet.create({
  flexRow: { flexDirection: "row", marginTop: 8, marginRight: 16 },
  marginLeft: { marginLeft: 12 },
  circle: {
    width: 36,
    height: 36,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
});
