import { MenuOption } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { ContextProps, HubProps, PermissionProps, UnitProps } from "@/types";

export interface FormikOptions {
  onSubmit: () => void;
}

export interface ConfirmationModalProps {
  title: string;
  description: string;
  confirmText: string;
  onConfirm: () => void;
}

export interface HubActionProps {
  context: ContextProps;
  hub?: HubProps;
  permissions: PermissionProps;
  unit: UnitProps;
  openConfirmationModal: (confirmationProps: ConfirmationModalProps) => void;
}

export function getHubActions({
  context,
  hub,
  permissions,
  unit,
  openConfirmationModal,
}: HubActionProps): MenuOption[] {
  const actions: MenuOption[] = [];

  if (!(hub && permissions.troubleshoot)) {
    return actions;
  }

  // Actions that can be applied when hub is online
  if (hub.online && hub.type === "zipato") {
    actions.push({
      label: "Box Sync",
      onPress: () => {
        openConfirmationModal({
          title: "Box Sync?",
          description: "Are you sure you want to run Box Sync?",
          confirmText: "Continue",
          onConfirm: () => {
            Helpers.zipato.box
              .boxSync({ unit_id: hub.unit_id })
              .then(() => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message:
                    "Box synced. Please wait a moment before performing additional actions.",
                });
              })
              .catch((err) => {
                console.error(err);

                context.setToast({
                  type: "error",
                  title: "Error",
                  message: "Unable to run Box Sync",
                });
              });
          },
        });
      },
    });

    if (permissions.advanced_hub_troubleshooting && hub.type === "zipato") {
      actions.push({
        label: "Save All",
        onPress: () => {
          openConfirmationModal({
            title: "Save All?",
            description: "Are you sure you want to run Save All?",
            confirmText: "Continue",
            onConfirm: () => {
              Helpers.zipato.box
                .saveAll({ unit_id: hub.unit_id })
                .then(() => {
                  context.setToast({
                    type: "success",
                    title: "Success",
                    message:
                      "Save All complete. Please wait a moment before performing additional actions.",
                  });
                })
                .catch((err) => {
                  console.error(err);

                  context.setToast({
                    type: "error",
                    title: "Error",
                    message: "Unable to run Save All",
                  });
                });
            },
          });
        },
      });
    }

    actions.push({
      label: "Reboot",
      onPress: () => {
        openConfirmationModal({
          title: "Reboot?",
          description: "Are you sure you want to Reboot?",
          confirmText: "Continue",
          onConfirm: () => {
            Helpers.zipato.box
              .reboot({ unit_id: hub.unit_id })
              .then(() => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message:
                    "Reboot complete. Please wait for hub to reboot before performing additional actions.",
                });
              })
              .catch((err) => {
                console.error(err);

                context.setToast({
                  type: "error",
                  title: "Error",
                  message: "Unable to run Reboot",
                });
              });
          },
        });
      },
    });
  }

  if (
    permissions.delete_construction_codes &&
    ["Vacant", "Model", "Down"].includes(unit.status)
  ) {
    actions.push({
      label: "Delete Construction Codes",
      onPress: () => {
        openConfirmationModal({
          title: "Delete Construction Codes?",
          description: `This action will ONLY trigger removal of construction codes added
          from Hub Prep v1 that WERE NOT TRACKED by CMW. If you are now adding
          construction codes using the Manager Guest code system within CMW,
          this action is not necessary. Note: This will mark the codes for removal. If the hub or lock are
          offline the codes will be removed when the hub can communicate
          with the lock again. It will allow tracking removal of the codes
          in the Access Code table below.`,
          confirmText: "Continue",
          onConfirm: () => {
            Helpers.hub
              .deleteConstructionCodes({ hubId: hub.id })
              .then(() => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message:
                    "Construction codes are being marked for deletion. Please refresh the page and monitor the Access Code section to see if they've been removed.",
                });
              })
              .catch((err) => {
                console.error(err);

                context.setToast({
                  type: "error",
                  title: "Error",
                  message:
                    "Request failed. Please try again later or contact engineering for further information.",
                });
              });
          },
        });
      },
    });
  }

  // Actions that can be applied when hub is offline
  if (!hub.online) {
    actions.push({
      label: "Offline Check",
      onPress: () => {
        openConfirmationModal({
          title: "Check If Hub Offline?",
          description: "Are you sure you want to check if the hub is offline?",
          confirmText: "Continue",
          onConfirm: () => {
            Helpers.zipato.box
              .offlineCheck({ unit_id: hub.unit_id })
              .then(() => {
                context.setToast({
                  type: "success",
                  title: "Success",
                  message:
                    "Hub Offline check initiating. Please refresh momentarily to check for hub status changes.",
                });
              })
              .catch((err) => {
                console.error(err);

                context.setToast({
                  type: "error",
                  title: "Error",
                  message: "Unable to check if hub is online or offline",
                });
              });
          },
        });
      },
    });
  }

  return actions;
}
