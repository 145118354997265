import * as React from "react";
import { Text, StyleSheet } from "react-native";

interface TextCellProps {
  children: string;
  numberOfLines?: number;
}

export const TextCell: React.FC<React.PropsWithChildren<TextCellProps>> = ({
  children,
  numberOfLines = 1000,
}) => (
  <Text numberOfLines={numberOfLines} style={styles.textContent}>
    {children}
  </Text>
);

const styles = StyleSheet.create({
  textContent: {
    flexShrink: 1,
  },
});
