import * as React from "react";
import { StyleSheet } from "react-native";
import { formatISO } from "date-fns";
import { Line } from "react-chartjs-2";
import { ActivityIndicator, Typography, useTheme } from "@smartrent/ui";

import { useBatteryHistoryQuery, BatteryHistoryOptions } from "@/api";

import Helpers from "@/lib/helpers";

export const HistoricBatteryLevelGraph: React.FC<
  React.PropsWithChildren<BatteryHistoryOptions>
> = ({ deviceId, unit }) => {
  const { data: historicBatteryLevels, isLoading } = useBatteryHistoryQuery({
    deviceId: deviceId,
    unit: unit,
  });

  const { colors } = useTheme();
  if (isLoading) {
    return <ActivityIndicator size={24} color={colors.green} />;
  }

  if (!historicBatteryLevels?.length) {
    return (
      <Typography color="textSecondary" style={styles.noBatteryText}>
        No historic battery data found
      </Typography>
    );
  }

  return (
    <Line
      data={{
        labels: historicBatteryLevels?.map((l) =>
          formatISO(new Date(l.inserted_at), { representation: "date" })
        ),
        datasets: [
          {
            ...Helpers.lineGraphDefaults,
            label: `Battery Level`,
            data: historicBatteryLevels?.map((l) => l.battery_level),
            backgroundColor: colors.green,
            borderColor: colors.green,
            pointBorderColor: colors.gray700,
            pointHoverBackgroundColor: colors.green,
            pointHoverBorderColor: colors.green,
            fill: "origin",
          },
        ],
      }}
      options={{
        scales: {
          xAxes: [
            {
              type: "time",
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 100,
              },
            },
          ],
        },
      }}
    />
  );
};

const styles = StyleSheet.create({
  noBatteryText: { fontStyle: "italic" },
});
