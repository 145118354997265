import { useCallback, useRef, useState } from "react";
import * as React from "react";
import { CSVLink } from "react-csv";
import { Button } from "@smartrent/ui";
import { Download } from "@smartrent/icons";

import {
  BatteryHealthReportRow,
  useDownloadBatteryHealthReportMutation,
} from "@/api/reports";
import { useGroup } from "@/pages/group/GroupContext";

const csvHeaders = [
  { label: "Property Name", key: "Property Name" },
  { label: "Property ID", key: "Property ID" },
  { label: "Unit Name", key: "Unit Name" },
  { label: "Unit ID", key: "Unit ID" },
  { label: "Model", key: "Model" },
  { label: "Device Name", key: "Device Name" },
  { label: "Type", key: "Type" },
  { label: "Online", key: "Online" },
  { label: "Battery Level", key: "Battery Level" },
  { label: "Battery Powered", key: "Battery Powered" },
  { label: "Battery Last Read", key: "Battery Last Read" },
  { label: "DSK", key: "DSK" },
];

export const GroupReportsDeviceReportButton: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { group } = useGroup();
  const [reportLoading, setReportLoading] = useState(false);
  const [reportData, setReportData] = useState<BatteryHealthReportRow[]>([]);
  const csvLinkEl = useRef<any>(null);

  const [downloadReportMutation] = useDownloadBatteryHealthReportMutation();

  const downloadDeviceReport = useCallback(async () => {
    setReportLoading(true);
    const data = await downloadReportMutation({
      groupId: group.id,
    });
    if (!data) {
      setReportLoading(false);
      return;
    }
    setReportData(data);
    setReportLoading(false);

    // This is a hack to get the CSVLink to download the file.
    // The CSVLink component doesn't have a way to trigger the download asynchronously.
    // https://github.com/react-csv/react-csv/issues/341
    setTimeout(() => {
      csvLinkEl.current.link.click();
    }, 0);
  }, [downloadReportMutation, group.id]);

  return (
    <>
      <CSVLink
        data={reportData}
        asyncOnClick={true}
        headers={csvHeaders}
        filename={`device-report-${
          group.marketing_name
        }-${new Date().toISOString()}.csv`}
        ref={csvLinkEl}
      ></CSVLink>
      <Button
        iconLeft={Download}
        isLoading={reportLoading}
        onPress={downloadDeviceReport}
      >
        Device Report
      </Button>
    </>
  );
};
