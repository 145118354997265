import * as React from "react";
import { StyleSheet, View } from "react-native";

import { useTheme } from "@smartrent/ui";

interface HeaderProps {
  children: React.ReactNode;
  style?: object;
}

export default ({ children, style }: HeaderProps) => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.header,
        {
          borderBottomColor: colors.gray100,
        },
        style,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    borderBottomWidth: 1,
    flex: 1,
    alignItems: "flex-start",
    paddingRight: 40,
    paddingBottom: 8,
    flexShrink: 0,
  },
});
