import { forwardRef, useEffect, RefObject } from "react";

import uploadcare from "uploadcare-widget";

/**
 * EXAMPLE:
 * const uploadRef = React.createRef<HTMLInputElement>();
 * <ImageUpload
    id="SOMETHING_UNIQUE"
    onChange={value => doSomething(value)}
    publicKey="PUBLIC_KEY"
    tabs="file camera url"
    imagesOnly="true"
    previewStep="true"
    crop="1500x870, 500x290 minimum"
    ref={uploadRef} />
 */

/**
 * Documentation of each prop https://uploadcare.com/docs/file_uploads/widget/options/
 */

export interface UploadcareSettings {
  publicKey: string;
  multiple?: string;
  multipleMax?: number;
  multipleMin?: number;
  accept?: string;
  dataClearable?: string;
  imagesOnly?: string;
  previewStep?: string;
  crop?: string;
  imageShrink?: string;
  clearable?: string;
  tabs?: string;
  inputAcceptTypes?: string;
  dataMultiple?: boolean;
}

export interface ImageUploadProps {
  inputId: string;
  configuration: UploadcareSettings;
  onChange: (imageUrl: string) => void;
  // When uploading files other than images, use this validate file types on the frontend
  // via drag-n-drop (because `inputAcceptTypes` only validates via the file upload prompt).
  validMimeTypes?: string[];
  value?: string;
}

interface FileInfo {
  isImage?: boolean;
  isStored?: boolean;
  mimeType?: string;
  name?: string;
}

/**
 * Take the camelcased prop keys and convert to data-* to be consumed by uploadcare-widget
 */
const convertPropsToDataTypes = (props: UploadcareSettings) => {
  const convertedProps: Record<string, any> = {};

  Object.keys(props).forEach((key: keyof UploadcareSettings) => {
    const newKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
    convertedProps[`data-${newKey}`] = props[key];
  });

  return convertedProps;
};

/**
 * Verify the file type of uploaded images.
 * Required for validating images uploaded via drag-n-drop.
 * The data-attribute mime-type list only validates files via the upload button.
 */
const mimeTypeValidator = (mimeTypes: string[]) => {
  return (fileInfo: FileInfo) => {
    // This validator will be called multiple times as the image is processed.
    // In the meantime, some properties may be null.
    // We wait until the properties we want are all available.
    if (fileInfo.mimeType === null) {
      return;
    }
    const isValid = mimeTypes.some((mime) => mime === fileInfo.mimeType);
    if (!isValid) {
      throw new Error(`Invalid file type: ${fileInfo.mimeType}`);
    }
  };
};

export default forwardRef(
  (props: ImageUploadProps, ref: RefObject<HTMLInputElement>) => {
    const { configuration, inputId, onChange, validMimeTypes } = props;
    const attributes = convertPropsToDataTypes(configuration);

    // Listen for the element to be rendered, then start observing
    useEffect(() => {
      /**
       * Uploadcare does not have a working React solution currently. As a result,
       * this approach was required with React.forwardRef so we know when an image
       * was successfully uploaded and can update state accordingly.
       *
       * Mutation Observer Docs: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
       */

      const element = document.getElementById(inputId);
      const observer = new MutationObserver(() => {
        const imageURL = (document.getElementById(inputId) as any).value;
        onChange(imageURL);
      });

      observer.observe(element, {
        attributes: true, //configure it to listen to attribute changes
      });

      return () => observer.disconnect();
    }, [ref, inputId, onChange]);

    // Get reference to widget so that we can add validators
    useEffect(() => {
      if (ref && ref.current && validMimeTypes && validMimeTypes.length) {
        const widget = uploadcare.Widget(ref.current);
        if (widget) {
          widget.validators.push(mimeTypeValidator(validMimeTypes));
        }
      }
    }, [ref, validMimeTypes]);

    return (
      <input
        id={inputId}
        type="hidden"
        // eslint-disable-next-line jsx-a11y/aria-role -- this is required for uploadcare to work (despite incorrect usage of aria-role)
        role="uploadcare-uploader"
        ref={ref}
        value={props.value ? props.value : ""}
        {...attributes}
      />
    );
  }
);
