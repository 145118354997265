import { clamp } from "lodash-es";

export function getClampedPageNumber(
  qs: Record<string, string | string[]>,
  totalPages?: number,
  pageQueryKey: string = "page"
) {
  const page = Number(qs[pageQueryKey]);
  if (isNaN(page)) {
    return 1;
  }

  if (typeof totalPages === "undefined" || totalPages === 0) {
    return page ?? 1;
  }

  if (totalPages < 1) {
    totalPages = 1;
  }

  return clamp(page, 1, totalPages || 1);
}
