import Helpers from "@/lib/helpers";
import { useApi } from "@/lib/hooks";

import { useUnit } from "../UnitContext";

export default function HubHeader() {
  const { group } = useUnit();

  const { response } = useApi({
    url: `/groups/${group.id}/maintenance/config`,
    trigger: [group.id],
  });

  const notifyResidentConfig =
    response?.data?.maintenanceConfig?.notify_resident_config;

  const displayBoolean = (b) => (b ? "Yes" : "No");

  return (
    <div style={{ marginTop: 8 }}>
      <span className="u-text-gray-600">Resident Notifications Enabled:</span>
      <div
        style={{ marginLeft: 15, display: "flex", flexDirection: "column" }}
        className={`${Helpers.technicalDetailsTextClass} u-font10 u-line18`}
      >
        <span>Manual: {displayBoolean(notifyResidentConfig?.Manual)}</span>
        <span>Humidity: {displayBoolean(notifyResidentConfig?.Humidity)}</span>
        <span>Leak: {displayBoolean(notifyResidentConfig?.Leak)}</span>
        <span>
          Low Battery: {displayBoolean(notifyResidentConfig?.["Low Battery"])}
        </span>
        <span>Temp: {displayBoolean(notifyResidentConfig?.Temp)}</span>
      </div>
    </div>
  );
}
