import Helpers from "@/lib/helpers";

import { DataDogLink } from "@/common/links/DataDogLink";
import { groupDetailLogs } from "@/utils/datadog";

export const GroupDetailsDebugFooter = ({ group, permissions }) => {
  if (!(group && permissions.view_technical_details)) {
    return null;
  }

  return (
    <div
      className={`${Helpers.technicalDetailsTextClass} u-mtop12 u-line18 u-font10 u-flex u-flex-justify-between u-flex-align-items-center u-pleft4 u-pright8`}
    >
      {group.id ? <div>id: {group.id}</div> : null}
      <div>
        <DataDogLink
          href={groupDetailLogs(group)}
          target="_blank"
          rel="noopener noreferrer"
        />
      </div>
    </div>
  );
};
