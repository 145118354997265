import { useContext, useState } from "react";
import { StyleSheet, View, TouchableHighlight } from "react-native";

import {
  Text,
  useTheme,
  Button,
  Typography,
  Stack,
  HStack,
} from "@smartrent/ui";
import {
  ArrowLeft,
  ChevronRight,
  Users,
  ExclamationCircleSolid,
  Flag,
} from "@smartrent/icons";

import { getJobStatusColor, getJobStatusText } from "@smartrent/install";

import Badge from "@/common/Badge";

import { useIsMobile } from "@/hooks/breakpoints";

import { useGetJob } from "@/api/jobs";
import { useFlaggedTasksCount } from "@/api/tasks";

import Context from "../../layout/Context";
import ProgressBar from "../ProgressBar";
import { InstallDetails } from "../InstallDetails";
import Divider from "../Divider";

import AssignedUsersModal from "../modals/assigned-users-modal/AssignedUsersModal";

import JobHeaderLoader from "./JobHeaderLoader";

interface Props {
  id: string;
}

export default function JobHeader({ id }: Props) {
  const { history } = useContext(Context);

  const [showUserList, setShowUserList] = useState(false);

  const { data, isLoading }: any = useGetJob(id);

  const { colors } = useTheme();

  const statusText = !!data ? getJobStatusText(data) : "";
  const statusColor = !!data ? getJobStatusColor(data, colors) : "";

  const isMobile = useIsMobile();

  const { count: totalFlaggedTasks } = useFlaggedTasksCount();

  return isLoading ? (
    <JobHeaderLoader />
  ) : (
    <>
      <View style={styles.navWrapper}>
        <TouchableHighlight
          activeOpacity={0.8}
          underlayColor="transparent"
          onPress={() => history.goBack()}
        >
          <View style={styles.row}>
            <ArrowLeft />
            <Text style={styles.navParent}>Jobs / </Text>
            <Typography font="semibold">{data?.name}</Typography>
          </View>
        </TouchableHighlight>
      </View>
      <Stack
        direction={isMobile ? "column" : "row"}
        style={isMobile ? mobileStyles.header : styles.header}
        spacing={isMobile ? 16 : 32}
      >
        <View>
          <HStack spacing={16}>
            <Typography type="title2">{data?.name}</Typography>
            <Badge color={statusColor} text={statusText} style={styles.badge} />
          </HStack>

          <View style={styles.completionLabel}>
            <Typography font="semibold">{`Tasks Complete: ${
              data?.completed_task_count || 0
            }/${data?.task_count || 0}`}</Typography>
          </View>
          <ProgressBar
            percentage={Math.round(
              (data?.completed_task_count / data?.task_count) * 100
            )}
          />
        </View>

        {data?.install_details ? (
          <>
            <Divider />
            <InstallDetails install_detail={data?.install_details} />
            <Divider />
          </>
        ) : null}

        <View style={styles.headerRightWrapper}>
          <View style={styles.buttonWrapper}>
            <Button
              iconLeft={Users}
              iconRight={ChevronRight}
              onPress={() => setShowUserList(!showUserList)}
            >
              View All Assigned Workers
            </Button>
            {totalFlaggedTasks ? (
              <View style={styles.taskFlagView}>
                <Flag color={colors.error} />
                <Typography
                  style={[styles.taskFlagNotification, { color: colors.error }]}
                >
                  Task Flags ({totalFlaggedTasks})
                </Typography>
              </View>
            ) : null}
          </View>
          {!!data?.cannot_complete_reason && (
            <View style={styles.cannotCompleteWrapper}>
              <View style={styles.cannotCompleteIcon}>
                <ExclamationCircleSolid color={colors.warning} size={18} />
              </View>
              <Typography font="semibold" style={styles.cannotCompleteText}>
                {data?.cannot_complete_reason}
              </Typography>
            </View>
          )}
        </View>
      </Stack>

      <AssignedUsersModal
        show={showUserList}
        onClose={() => setShowUserList(false)}
        headerText={data?.name || ""}
        users={data?.users}
        statusText={statusText}
        statusColor={statusColor}
      />
    </>
  );
}

const mobileStyles = StyleSheet.create({
  header: {
    alignItems: "flex-start",
    justifyContent: "center",
    paddingTop: 16,
  },
});

const styles = StyleSheet.create({
  header: { paddingTop: 16 },
  badgeContainer: {
    flexDirection: "row",
    marginTop: 8,
  },
  installDetails: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  installTypeTitle: { fontWeight: "600", fontSize: 22 },
  navWrapper: { flexDirection: "row" },
  navParent: { marginLeft: 8 },
  row: {
    flexDirection: "row",
  },
  badge: { marginLeft: 16 },
  completionLabel: { paddingVertical: 8 },
  headerRightWrapper: { flex: 1 },
  buttonWrapper: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  statusWrapper: {
    borderWidth: 2,
    borderRadius: 4,
    paddingVertical: 4,
    marginLeft: 32,
  },
  status: {
    lineHeight: 12,
    fontSize: 12,
    paddingHorizontal: 8,
  },
  cannotCompleteWrapper: { flexDirection: "row", marginTop: 20 },
  cannotCompleteIcon: { paddingTop: 3 },
  cannotCompleteText: { marginLeft: 8 },
  taskFlagView: { flexDirection: "row", marginTop: 16 },
  taskFlagNotification: {
    fontSize: 18,
  },
});
