import { useContext } from "react";
import { View, StyleSheet } from "react-native";

import { MaintenanceVendorCard } from "@/common/MaintenanceVendorCard";

import Layout from "@/layout/Layout";
import Context from "@/layout/Context";

import { SMSWebhookCard } from "@/pages/user/SMSWebhookCard";

import { FullScreenLoader } from "@/common/Loader";
import NoRecords from "@/common/NoRecords";

import { useMaintenanceVendorQuery } from "@/api";

export const MaintenanceVendor = () => {
  const { match } = useContext(Context);

  const { data, isLoading } = useMaintenanceVendorQuery({
    maintenanceVendorId: match.params.maintenanceVendorId,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (!data?.maintenanceVendor) {
    return (
      <View style={styles.notFound}>
        <NoRecords title="No Maintenance Vendor Found" />
      </View>
    );
  } else {
    return (
      <Layout>
        <View style={styles.content}>
          <MaintenanceVendorCard
            maintenanceVendor={data?.maintenanceVendor}
            group={data?.group}
          />
          <SMSWebhookCard
            phone={data?.maintenanceVendor?.phone?.replace("+", "")}
          />
        </View>
      </Layout>
    );
  }
};

const styles = StyleSheet.create({
  notFound: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  content: { flexDirection: "row", flexWrap: "wrap", padding: 16 },
});
