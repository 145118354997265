import { useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";
import type { QueryKey } from "@tanstack/react-query";

import { createAxiosQuery, createAxiosMutation } from "@/hooks/react-query";

import helpers from "@/lib/helpers";

import { TppViewProps, ThirdPartyProviderProps, GroupProps } from "@/types";

import { instance } from "../lib/hooks";

export interface TppViewQueryFilters {
  groupId?: number | string;
  organizationId?: number | string;
  limit?: number | string;
  sortBy?: string;
  orderBy?: string;
  tppc_scope?: string;
  enabled?: boolean;
  locked?: boolean;
}

export interface TppViewQueryResponse {
  records: TppViewProps[];
  total_records: number;
  total_pages: number;
}

export const useTppViewQuery = createAxiosQuery(
  "tpp-view",
  async (params: { filters: TppViewQueryFilters } | undefined) => {
    const path = `/tpp-view`;
    const qs = helpers.toQS(params?.filters || {});
    const url = qs === "" ? path : `${path}?${qs}`;
    return instance.get<TppViewQueryResponse>(url);
  }
);

export const selectQueryPropsTppView = (filters: TppViewQueryFilters) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<TppViewProps>>(
    () => ["tpp-view-selection", filters],
    async function fetch() {
      const path = `/tpp-view`;
      const qs = helpers.toQS(filters);
      const url = qs === "" ? path : `${path}?${qs}`;
      const response = await instance.get(url);
      return response.data;
    },
    { enabled: !!filters.groupId },
    {
      inputDebounceInterval: 250,
    }
  );

export interface ThirdPartyProvidersQueryRequest {
  groupId?: number;
  filters: { provider_ids: string[] };
}

export interface ThirdPartyProvidersQueryResponse {
  providers: ThirdPartyProviderProps[];
}

export const useThirdPartyProvidersCredentialsQuery = createAxiosQuery(
  "third-party-providers-credentials",
  async (params: ThirdPartyProvidersQueryRequest | undefined) => {
    const {
      groupId,
      filters: { provider_ids },
    } = params || { filters: {} };
    const path = `/third-party-providers/groups/${groupId}/credentials`;
    const qs = new URLSearchParams({
      provider_ids: provider_ids?.join(",") || "",
    }).toString();
    return instance.get<{ data: ThirdPartyProvidersQueryResponse }>(
      qs === "" ? path : `${path}?${qs}`
    );
  }
);

export interface OrganizationCredentialsQueryRequest {
  organizationId: number;
}

export interface OrganizationCredentialsQueryResponse {
  providers: ThirdPartyProviderProps[];
}

export const fetchOrganizationCredentials = async ({
  queryKey,
}: {
  queryKey: QueryKey;
}) => {
  const [, , filters] = queryKey as [string, Record<string, unknown>, any];

  const { data } = await instance.get<{
    data: OrganizationCredentialsQueryResponse;
  }>(
    `/third-party-providers/organizations/${filters.organizationId}/org-credentials`
  );

  return data;
};

interface LockTppMutationBody {
  groupId: GroupProps["id"] | string;
  providerId: ThirdPartyProviderProps["id"];
}

export const useLockTppMutation = createAxiosMutation(
  async ({ groupId, providerId }: LockTppMutationBody) => {
    return instance.post(`/third-party-providers/lock`, {
      groupId,
      providerId,
    });
  },
  {
    successToast: () => ({
      message: "Integration locked successfully.",
    }),
    errorToast: () => ({
      message: `There was an error locking the integration.`,
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: ["tpp-view"],
      });
      queryClient.invalidateQueries({
        queryKey: ["tpp-view-selection"],
      });
    },
  }
);

export const useUnlockTppMutation = createAxiosMutation(
  async ({ groupId, providerId }: LockTppMutationBody) => {
    return instance.post(`/third-party-providers/unlock`, {
      groupId,
      providerId,
    });
  },
  {
    successToast: () => ({
      message: "Integration unlocked successfully.",
    }),
    errorToast: () => ({
      message: `There was an error locking the integration.`,
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: ["tpp-view"],
      });
      queryClient.invalidateQueries({
        queryKey: ["tpp-view-selection"],
      });
    },
  }
);
