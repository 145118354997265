import { useMemo } from "react";
import { map, sum, compact } from "lodash-es";
import { Bar } from "react-chartjs-2";

import { useUser } from "@/layout/Context";

import helpers from "@/lib/helpers";

type BarData = {
  count: number;
  type?: string;
  status?: string;
};

export default function AddedAccessCodes({ added, unadded }) {
  const { permissions } = useUser();

  const types = useMemo(() => {
    return compact(
      map(added, (r) => {
        return r.type;
      })
    );
  }, [added]);

  if (!permissions.manage_system_health) {
    return null;
  }

  return (
    <div className="u-flex-grow1" style={{ minWidth: "350px" }}>
      <Bar
        data={{
          labels: map(
            added,
            (r: BarData) => `${r.type} (${Number(r.count).toLocaleString()})`
          ),
          datasets: [
            {
              label: `ACs - Last Day (${sum(
                map(types, (type: string) => {
                  const acOfType = added.find((ac) => ac.type === type);
                  return acOfType ? Number(acOfType.count) : 0;
                })
              ).toLocaleString()})`,
              data: map(types, (type: string) => {
                const acOfType = added.find((ac) => ac.type === type);
                return acOfType ? Number(acOfType.count) : 0;
              }),
              backgroundColor: map(
                added,
                (r, idx) => helpers.lineGraphColors.green
              ),
              hoverBackgroundColor: [],
            },
            {
              label: `Unadded ACs - Last Day (${sum(
                map(types, (type: string) => {
                  const acOfType = unadded.find((ac) => ac.type === type);
                  return acOfType ? Number(acOfType.count) : 0;
                })
              ).toLocaleString()})`,
              data: map(types, (type: string) => {
                const acOfType = unadded.find((ac) => ac.type === type);
                return acOfType ? Number(acOfType.count) : 0;
              }),
              backgroundColor: map(
                unadded,
                (r, idx) => helpers.lineGraphColors.red
              ),
              hoverBackgroundColor: [],
            },
          ],
        }}
        height={65}
      />
    </div>
  );
}
