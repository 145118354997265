import { useContext, useEffect } from "react";
import {
  ExclamationCircleOutline,
  CheckCircleSolid,
  Close,
} from "@smartrent/icons";
import { useTheme } from "@smartrent/ui";

import Context from "@/layout/Context";

import { ToastProps } from "../types";

/*
  Sample usage:

  context.setToast({
    type: "success"|"error",
    title: "Success!"
    message: "Your body here"
  })
*/
export default function Toast() {
  const { colors } = useTheme();
  const { toast, setToast, isDesktop } = useContext(Context);

  // set toast to timeout if 'timeout' prop exists
  useEffect(() => {
    if (!toast?.timeout) {
      return () => null;
    }
    const timer = setTimeout(() => {
      setToast(null);
    }, toast.timeout);
    return () => clearTimeout(timer);
  }, [toast, setToast]);

  if (!toast) {
    return null;
  }

  const { type, message, title }: ToastProps = toast;

  const getTypeColor = (type) => {
    switch (type) {
      case "error":
        return colors.error;
      case "warning":
        return colors.warning;
      default:
        return colors.success;
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case "error":
        return <ExclamationCircleOutline color={colors.error} size={32} />;
      case "warning":
        return <ExclamationCircleOutline color={colors.warning} size={32} />;
      default:
        return <CheckCircleSolid color={colors.success} size={32} />;
    }
  };

  return (
    <div
      className="toast--animated"
      style={{
        background: "#ffffff",
        borderLeft: `5px solid ${getTypeColor(type)}`,
        borderRadius: 3,
        bottom: 30,
        boxShadow: "2px 4px 8px rgba(0,0,0,0.2)",
        display: "flex",
        left: isDesktop ? 40 : 10,
        padding: "16px 68px 16px 16px",
        position: "fixed",
        width: isDesktop ? 450 : "95%",
        zIndex: 99,
      }}
    >
      <div style={{ alignItems: "center", display: "flex", marginRight: 14 }}>
        {getTypeIcon(type)}
      </div>
      <div>
        <h3
          style={{
            fontWeight: 700,
            fontSize: 16,
            letterSpacing: 0.25,
            textTransform: "uppercase",
          }}
        >
          {title}
        </h3>
        <div>{message}</div>
      </div>
      <div
        onClick={() => setToast(null)}
        style={{
          alignItems: "center",
          border: "1px solid #959595",
          borderRadius: 12,
          cursor: "pointer",
          display: "flex",
          height: 20,
          justifyContent: "center",
          padding: 3,
          position: "absolute",
          right: 8,
          top: 8,
          width: 20,
        }}
      >
        <Close color="#000000" size={10} />
      </div>
    </div>
  );
}
