import { useCallback, useContext, useState } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { useHistory } from "react-router-dom";

import { Button, Typography, useTheme } from "@smartrent/ui";

import Context from "@/layout/Context";
import { useImportPniParkingSensorsMutation } from "@/api/imports";
import { useGroup } from "@/pages/group/GroupContext";

export const SensorImport: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory();
  const { colors } = useTheme();
  const { group } = useGroup();
  const { setToast } = useContext(Context);

  const [csvString, setCsvString] = useState<string | null>(null);

  const [createImport, { isLoading }] = useImportPniParkingSensorsMutation();

  const handleInputChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files[0]) {
        return;
      }

      const file = e.target.files[0];
      const text = await file.text();

      setCsvString(text);
    },
    []
  );

  const handleUpload = useCallback(async () => {
    history.replace(`?tab=imports`);

    try {
      await createImport({
        groupId: group.id,
        csvString,
      });

      setToast({
        type: "success",
        title: "Import created",
        message: "It may take a few minutes for the import to complete.",
      });
    } catch (err) {
      setToast({
        type: "error",
        title: "Import failed",
        message: "Please ensure all rows are formatted correctly.",
      });

      throw err;
    } finally {
      // make them pick a new file
      setCsvString(null);
    }
  }, [csvString, group.id, history, setToast, createImport]);

  return (
    <>
      <View
        style={[
          styles.sectionTitle,
          {
            borderBottomColor: colors.gray100,
          },
        ]}
      >
        <Typography type="title5">PARKING SENSOR IMPORT</Typography>
      </View>
      <div className="module-container">
        <label
          style={{
            backgroundColor: colors.primary,
            padding: "4px 24px",
            cursor: "pointer",
            display: "in-line",
            borderRadius: 50,
          }}
        >
          <input
            type="file"
            accept="text/csv"
            style={{ display: "none" }}
            onChange={handleInputChange}
          />
          <Typography font="semibold" color="inputBackground">
            Choose File
          </Typography>
        </label>
        <Button disabled={!csvString || isLoading} onPress={handleUpload}>
          Upload
        </Button>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  sectionTitle: {
    borderBottomWidth: 1,
    paddingBottom: 8,
    marginBottom: 16,
    marginTop: 16,
  },
});
