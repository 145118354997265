import { useCallback, useMemo } from "react";
import * as React from "react";

import { ActionItem } from "@/common/ActionItem";
import { useToggleGuestParkingModule } from "@/api";
import { ParkingProperty } from "@/types";
import { useDialog } from "@/lib/contexts/dialog";

interface Props {
  property: ParkingProperty;
  onSuccess: () => void;
}

export const ToggleGuestModuleAction: React.FC<
  React.PropsWithChildren<Props>
> = ({ property, onSuccess }) => {
  const { confirm } = useDialog();

  const [toggleGuestParking] = useToggleGuestParkingModule();

  const enabled = useMemo(
    () => property?.guest_parking_module_enabled ?? false,
    [property]
  );

  const title = useMemo(
    () => `${enabled ? "Disable" : "Enable"} Guest Parking Module`,
    [enabled]
  );

  const color = useMemo(() => (enabled ? "error" : "primary"), [enabled]);

  const handlePress = useCallback(async () => {
    const confirmed = await confirm({
      title: `${title}?`,
      confirmText: `${enabled ? "Disable" : "Enable"}`,
      confirmType: enabled ? "destructive" : "primary",
      description: enabled
        ? "Warning: The guest parking experience for this property will be deactivated. All signs will be archived and any active guest sessions will be ended."
        : "Are you sure you want to enable the guest parking module?",
    });

    if (confirmed) {
      await toggleGuestParking({
        property,
      });
      onSuccess();
    }
  }, [onSuccess, property, enabled]);

  return <ActionItem onPress={handlePress} title={title} color={color} />;
};
