import { useMemo } from "react";
import { Text, Link } from "@smartrent/ui";
import { View } from "react-native";
import { omit } from "lodash-es";

import Layout from "@/layout/Layout";
import { useGlobalContext } from "@/layout/Context";

import helpers from "@/lib/helpers";

import { SectionLoader } from "@/common/Loader";
import MonthlyReportTable from "@/common/MonthlyReportTable";

import { useFetchMonthlyReportsByPath } from "@/api/reports";

/**
 * If you need to create a new Monthly Reports page:
 *
 * 1. Add the path to `useFetchMonthlyReportsByPath`
 * 2. Add the backend route and route handler
 * 3. Update the `pageName` switch statement below
 */
export default function MonthlyReports() {
  const { user, match } = useGlobalContext();

  const filters = helpers.qsFromLocation(location);

  // Omitting `page` and `limit` from `filters`
  // so we don't trigger refetch on QS change when paginating through results
  const reportResponse = useFetchMonthlyReportsByPath({
    filters: omit(filters, ["page", "limit"]),
    path: match.url,
  });

  const pageName = useMemo(() => {
    let pageName = "";

    switch (match.params.reportName) {
      case `cmw`:
        pageName = "CMW Monthly Reports";
        break;

      case `residents`:
        pageName = "Residents Monthly Reports";
        break;

      case `tours`:
        pageName = "Tours Monthly Reports";
        break;
    }

    return pageName;
  }, [match.params.reportName]);

  if (!user.permissions.reporting) {
    return null;
  }

  return (
    <Layout>
      <View style={{ margin: 24 }}>
        <Text>
          <Link href="/reporting">Reporting</Link> / {pageName}
        </Text>
        {reportResponse?.isLoading ? (
          <View style={{ marginVertical: 24 }}>
            <SectionLoader />
          </View>
        ) : null}
        {!reportResponse?.isLoading && reportResponse?.data
          ? reportResponse.data.map(
              ({
                name,
                description,
                columns,
                rows,
                supportedFilters,
                organizations,
                groups,
                lastRanAt,
              }) => (
                <View
                  style={{ marginVertical: 16, paddingVertical: 16 }}
                  key={name}
                >
                  <MonthlyReportTable
                    key={name}
                    name={name}
                    description={description}
                    columns={columns}
                    rows={rows}
                    filters={filters}
                    supportedFilters={supportedFilters}
                    organizations={organizations}
                    groups={groups}
                    lastRanAt={lastRanAt}
                    reportName={match.params.reportName}
                  />
                </View>
              )
            )
          : null}
      </View>
    </Layout>
  );
}
