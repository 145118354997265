import { View, StyleSheet } from "react-native";

import { Typography, Link } from "@smartrent/ui";

import { formatPhone, formatDate } from "@/utils";
import { EM_DASH } from "@/utils/chars";
import { GroupProps, MaintenanceVendorProps } from "@/types";

import Card from "./Card";

// Note: This is a Dumb Component and should not make API calls
export const MaintenanceVendorCard = ({
  maintenanceVendor,
  group,
}: {
  maintenanceVendor: MaintenanceVendorProps;
  group: GroupProps;
}) => {
  if (!maintenanceVendor) {
    return null;
  }

  return (
    <View style={styles.cardContainer}>
      <Card
        heading={
          <>
            <Typography type="title2Bold">
              {maintenanceVendor.first_name || maintenanceVendor.last_name
                ? `${maintenanceVendor.first_name} ${maintenanceVendor.last_name}`
                : "No Name"}{" "}
            </Typography>
            <Typography type="title5" color="textSecondary">
              (Maintenance Team Member)
            </Typography>
          </>
        }
      >
        <View style={styles.container}>
          <View style={styles.rowItem}>
            <Typography type="title4Bold">Phone:</Typography>
            <Typography color="textSecondary">
              {maintenanceVendor.phone
                ? formatPhone({ phone: maintenanceVendor.phone })
                : EM_DASH}
            </Typography>
          </View>
          <View style={styles.rowItem}>
            <Typography type="title4Bold">Email:</Typography>
            <Typography color="textSecondary">
              {maintenanceVendor.email ? maintenanceVendor.email : EM_DASH}
            </Typography>
          </View>
          <View style={styles.rowItem}>
            <Typography type="title4Bold">Created at:</Typography>
            <Typography color="textSecondary">
              {maintenanceVendor.inserted_at
                ? formatDate({ date: maintenanceVendor.inserted_at })
                : EM_DASH}
            </Typography>
          </View>
          <View style={styles.rowItem}>
            <Typography type="title4Bold">
              Receives Leak Notifications:
            </Typography>
            <Typography color="textSecondary">
              {maintenanceVendor.receives_leak_notifications ? "Yes" : "No"}
            </Typography>
          </View>
          <View style={styles.rowItem}>
            <Typography type="title4Bold">Associated Group:</Typography>
            <Typography color="textSecondary">
              {maintenanceVendor && group ? (
                <Link href={`/groups/${group.id}`}>{group.marketing_name}</Link>
              ) : (
                EM_DASH
              )}
            </Typography>
          </View>
        </View>
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    marginRight: 16,
  },
  container: { paddingHorizontal: 16, paddingTop: 16 },
  rowItem: { marginBottom: 16 },
});
