export const userStatusOptions = [
  { label: "Active", value: "active" },
  { label: "Invitation", value: "invitation" },
  { label: "SAML Active", value: "saml_active" },
  { label: "SAML Inactive", value: "saml_inactive" },
];

export const userLastActiveOptions = [
  {
    label: "Last 15 Days",
    value: "15",
  },
  {
    label: "Last 30 Days",
    value: "30",
  },
  {
    label: "Last 60 Days",
    value: "60",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];
