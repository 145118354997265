import { Bar } from "react-chartjs-2";

import { useApi } from "@/lib/hooks";
import { useUser } from "@/layout/Context";

import Helpers from "@/lib/helpers";

export default function IdentityStats() {
  const { permissions } = useUser();

  const hasPermission = permissions.manual_verifications;

  const { response } = useApi({
    url: "/reports/identity/stats",
    trigger: [],
    forceDispatchEffect: () => hasPermission,
  });

  // This is a support/engineering concern/graph, not for all departments
  if (!hasPermission) {
    return null;
  }

  if (!(response && response.data)) {
    return null;
  }

  const automatic = Number(response.data.automatic);
  const manual = Number(response.data.manual);
  const total = manual + automatic;

  return (
    <div
      className="u-m8 u-p8"
      style={{
        border: "1px solid #eee",
        background: "#fefefe",
        boxShadow: "1px 1px 1px #fefefe",
      }}
    >
      <Bar
        height={150}
        data={{
          labels: [
            `Automatic - ${Math.round((automatic / total) * 100)}%`,
            `Manual - ${Math.round((manual / total) * 100)}%`,
          ],
          datasets: [
            {
              label: `Verifications (Last 30 Days)`,
              data: [automatic, manual],
              backgroundColor: [
                Helpers.lineGraphColors.green,
                Helpers.lineGraphColors.blue,
              ],
              hoverBackgroundColor: [],
            },
          ],
        }}
      />
    </div>
  );
}
