import { useState } from "react";
import { startCase, isEmpty } from "lodash-es";

import {
  Typography,
  Drawer,
  DrawerHeader,
  DrawerContent,
  Table,
  useTableQuery,
  useTheme,
} from "@smartrent/ui";
import { CheckCircle, XCircle } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { useOrganization } from "@/pages/organization/OrganizationContext";
import { CredentialsList } from "@/common/third-party-providers";

import { fetchOrganizationCredentials } from "@/api";

import { EM_DASH } from "@/utils/chars";

interface IntegrationCredentialsTableData {
  id: number;
  name: string;
  provider?: {
    provider_name?: string;
    provider_credentials: Record<string, any>;
  };
  type: string;
  credential?: Record<string, any>;
  credentials?: Record<string, any>;
  encrypted_credentials?: Record<string, any>;
}

export const IntegrationCredentialsTable = () => {
  const { organization } = useOrganization();
  const { colors } = useTheme();

  const [credToShow, setCredToShow] = useState(null);
  const {
    visible: credDrawerVisible,
    onOpen: credDrawerOnOpen,
    onClose: credDrawerOnClose,
  } = useModalState();

  const getQueryKey = ({ filters, page, pageSize }) => [
    "integration-organization-credentials",
    {},
    {
      ...filters,
      page,
      limit: pageSize,
      organizationId: organization.id,
    },
  ];

  const tableProps = useTableQuery<IntegrationCredentialsTableData[], any, any>(
    {
      fetch: fetchOrganizationCredentials,
      getQueryKey: getQueryKey,
      columns: [
        {
          name: "id",
          header: "Provider ID/Credential ID",
          render: ({ row }) => <Typography>{row.id}</Typography>,
        },
        {
          name: "name",
          header: "Credential Name",
          render: ({ row }) => <Typography>{row.name || EM_DASH}</Typography>,
        },
        {
          name: "provider_name",
          header: "Provider Name",
          render: ({ row }) => (
            <Typography>{row?.provider?.provider_name}</Typography>
          ),
        },
        {
          name: "type",
          header: "Type",
          render: ({ row }) => (
            <Typography>{startCase(row.type) || EM_DASH}</Typography>
          ),
        },
        {
          name: "found_credentials",
          header: "Credentials Found",
          render: ({ row }) =>
            !isEmpty(row?.credential) ? (
              <CheckCircle color={colors.success} />
            ) : (
              <XCircle color={colors.error} />
            ),
        },
      ],
    }
  );

  return (
    <>
      <Table
        title="Organization Credentials"
        noRecordsText="No credentials found"
        onRowPress={(row) => {
          setCredToShow(row);
          credDrawerOnOpen();
        }}
        isRowPressable={(row) => !isEmpty(row?.credential)}
        {...tableProps}
      />
      <Drawer
        testID="drawer"
        anchor="right"
        maxWidth={420} // same as group page's card width
        open={credDrawerVisible}
        onClose={credDrawerOnClose}
      >
        <>
          <DrawerHeader
            title={credToShow?.name || credToShow?.provider?.provider_name}
          />
          <DrawerContent>
            {credToShow && credToShow?.credential ? (
              <CredentialsList
                listKey={credToShow?.id}
                credentials={credToShow?.credential}
                size="large"
              />
            ) : (
              <Typography color="textSecondary">
                No credentials found
              </Typography>
            )}
          </DrawerContent>
        </>
      </Drawer>
    </>
  );
};
