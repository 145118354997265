import { useMemo } from "react";
import { View, StyleSheet } from "react-native";
import { Typography } from "@smartrent/ui";
import { startCase } from "lodash-es";

import { PanelCard } from "@/common/PanelCard";
import { useDrawerNav } from "@/common/AppDrawer";
import { GateSwitch } from "@/pages/cmw-feature-flags/GateSwitch";

import { FeatureFlag as FeatureFlagProps } from "@/types";

export function CMWFeatureFlag({
  flag,
  refetch,
  isLoading,
}: {
  flag: FeatureFlagProps;
  refetch: () => void;
  isLoading: boolean;
}) {
  const drawer = useDrawerNav();
  const { globalGate, orgGates, groupGates } = useMemo(() => {
    return flag.gates.reduce(
      (memo, gate) => {
        if (gate.for === "global") {
          memo.globalGate = gate;
        } else if (gate.for.entity === "organization") {
          memo.orgGates.push(gate);
        } else if (gate.for.entity === "group") {
          memo.groupGates.push(gate);
        }
        return memo;
      },
      { globalGate: null, orgGates: [], groupGates: [] }
    );
  }, [flag]);

  const cardActions = [
    {
      label: "Add Org/Group",
      onPress: () => {
        return drawer.push("addFeatureFlagGate", { flagName: flag.name });
      },
    },
  ];

  return (
    <PanelCard
      key={flag.name}
      customStyles={styles.container}
      title={startCase(flag.name)}
      actions={cardActions}
    >
      <View>
        <View>
          <GateSwitch
            isGlobal={true}
            gate={globalGate}
            refetch={refetch}
            isLoading={isLoading}
            flagName={flag.name}
          />
        </View>
        <View style={styles.mt16}>
          <Typography type="title3">Organizations</Typography>
          {orgGates?.length ? (
            orgGates.map((gate, idx) => (
              <View style={styles.mt8} key={idx}>
                <GateSwitch
                  gate={gate}
                  refetch={refetch}
                  isLoading={isLoading}
                  flagName={flag.name}
                />
              </View>
            ))
          ) : (
            <Typography type="body">
              No organization overrides found for this flag.
            </Typography>
          )}
        </View>

        <View style={styles.mt16}>
          <Typography type="title3">Groups</Typography>
          {groupGates?.length ? (
            groupGates.map((gate, idx) => (
              <View style={styles.mt8} key={idx}>
                <GateSwitch
                  gate={gate}
                  refetch={refetch}
                  isLoading={isLoading}
                  flagName={flag.name}
                />
              </View>
            ))
          ) : (
            <Typography type="body">
              No group overrides found for this flag.
            </Typography>
          )}
        </View>
      </View>
    </PanelCard>
  );
}

const styles = StyleSheet.create({
  container: { margin: 16 },
  mt16: { marginTop: 16 },
  mt8: { marginTop: 8 },
});
