import { useState } from "react";
import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

import { formatDate } from "@/utils";

import { FixedHeightCardBody, OpenCloseFooter } from "@/common/card/index";

export default function DeletedHubs({ deleted_hubs }) {
  const [isOpen, setIsOpen] = useState(false);

  if (!(deleted_hubs && deleted_hubs.length)) {
    return null;
  }

  return (
    <>
      <FixedHeightCardBody
        style={styles.cardBody}
        open={isOpen}
        closedHeight={226}
      >
        <Typography color="warning" type="caption" style={styles.textRow}>
          Deleted Hubs
        </Typography>

        {deleted_hubs.map((deleted_hub, index) => (
          <View key={index} style={styles.textRow}>
            <Typography style={styles.topLine}>
              {deleted_hub.serial} | deleted at{" "}
              {formatDate({
                date: deleted_hub.deleted_at,
                pattern: "MM/dd hh:mm aaa",
              })}
              .
            </Typography>

            {deleted_hub.deleted_reason ? (
              <Typography type="caption" color="textSecondary">
                Reason: {deleted_hub.deleted_reason}
              </Typography>
            ) : null}
          </View>
        ))}

        {deleted_hubs.length === 0 && (
          <Typography>No deleted hubs found</Typography>
        )}
      </FixedHeightCardBody>

      {deleted_hubs.length > 5 ? (
        <OpenCloseFooter
          isOpen={isOpen}
          setOpen={setIsOpen}
          style={styles.footer}
        />
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  cardBody: {
    padding: 8,
  },
  textRow: {
    marginBottom: 8,
  },
  topLine: {
    lineHeight: 16,
    fontSize: 16,
  },
  footer: {
    paddingVertical: 8,
  },
});
