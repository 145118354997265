import { HStack, Typography, useTheme } from "@smartrent/ui";

import { useUnit } from "../UnitContext";

export default function UnitCommunityWifiStatus() {
  const { communityWifi } = useUnit();
  const { colors } = useTheme();

  return (
    <HStack spacing={4}>
      <Typography style={{ color: colors.gray600 }}>
        Has WiFi Device Network Enabled:
      </Typography>
      <Typography>{communityWifi.isEnabled ? "Yes" : "No"}</Typography>
    </HStack>
  );
}
