import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

export interface CardFooterProps {
  style?: StyleProp<ViewStyle>;
}

export const CardFooter: React.FC<React.PropsWithChildren<CardFooterProps>> = ({
  children,
  style,
}) => <View style={[styles.cardFooter, style]}>{children}</View>;

const styles = StyleSheet.create({
  // This crap can be overridden by passing a custom stylesheet to the `style` attribute.
  cardFooter: {
    padding: 18,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
