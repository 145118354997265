import { Bar } from "react-chartjs-2";
import { Card } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

export default function AvgTimeToFinalize({ averages }) {
  if (!averages) {
    return null;
  }

  const zeroToFive = Number(averages.zero_to_five);
  const sixToTen = Number(averages.six_to_ten);
  const elevenToFifteen = Number(averages.eleven_to_fifteen);
  const sixteenToTwenty = Number(averages.sixteen_to_twenty);
  const twentyOnePlus = Number(averages.twenty_one_plus);
  const total =
    zeroToFive + sixToTen + elevenToFifteen + sixteenToTwenty + twentyOnePlus;

  return (
    <Card>
      <Bar
        height={40}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  stepSize: 4000,
                },
              },
            ],
          },
        }}
        data={{
          labels: [
            `0 - 5 (${Math.round((zeroToFive / total) * 100)}%)`,
            `6 - 10 (${Math.round((sixToTen / total) * 100)}%)`,
            `11 - 15 (${Math.round((elevenToFifteen / total) * 100)}%)`,
            `16 - 20 (${Math.round((sixteenToTwenty / total) * 100)}%)`,
            `21+ (${Math.round((twentyOnePlus / total) * 100)}%)`,
          ],
          datasets: [
            {
              label: `Avg Minutes To Finalize`,
              data: [
                zeroToFive,
                sixToTen,
                elevenToFifteen,
                sixteenToTwenty,
                twentyOnePlus,
              ],
              backgroundColor: [
                Helpers.lineGraphColors.darkGreen,
                Helpers.lineGraphColors.green,
                Helpers.lineGraphColors.lightGreen,
                Helpers.lineGraphColors.blue,
                Helpers.lineGraphColors.lightBlue,
                Helpers.lineGraphColors.cyan,
              ],
              hoverBackgroundColor: [],
            },
          ],
        }}
      />
    </Card>
  );
}
