import { createContext, useContext } from "react";
import { QueryKey, QueryObserverBaseResult } from "@tanstack/react-query";

import { ScopeOfWorkDeviceDictionary, ScopeOfWorkProps } from "@/types";

export interface ScopeOfWorkContext {
  scopeOfWorkId: ScopeOfWorkProps["id"];
  scopeOfWorkQuery: QueryObserverBaseResult<ScopeOfWorkProps>;
  scopeOfWorkQueryKey: QueryKey;

  devicesGroupedByCategory?: ScopeOfWorkDeviceDictionary;

  pathname: string;
  parCompleteErrorsQuery: any;
}

export const ScopeOfWorkContext = createContext<ScopeOfWorkContext>(
  {} as ScopeOfWorkContext
);

export const useScopeOfWorkContext = () => useContext(ScopeOfWorkContext);
