import { useFormikContext } from "formik";
import { createElement, useCallback, useEffect, useMemo } from "react";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";

import { useTextInputStyles } from "@smartrent/ui";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

import {
  HubRefurbishFormikFieldNames,
  HubRefurbishReactQueryKeys,
  RefurbishHub,
} from "@/types";

interface useFormikFieldsHookProps {
  required: boolean;
  name: HubRefurbishFormikFieldNames;
  refetch?: UseQueryResult["refetch"];
  queryKey: HubRefurbishReactQueryKeys;
  value?: Array<RefurbishHub>;
}

export const useFormikFieldsHook = ({
  required,
  name,
  queryKey,
  value: valueInput,
}: useFormikFieldsHookProps) => {
  const { getFieldHelpers, getFieldMeta, validateField } = useFormikContext();
  const { updateHubs } = useHubRefurbishContext();
  const queryClient = useQueryClient();

  // forces error validations to display in UI if/when they are added from yup
  useEffect(() => {
    const { setError, setTouched } = getFieldHelpers(name);
    const { error, touched } = getFieldMeta<RefurbishHub[]>(name);

    if (error && !touched) {
      setError(error);
      setTouched(true, true);
    }
  }, [getFieldHelpers, getFieldMeta, name]);

  // forces formik to update its value if the length of hub records changes
  useEffect(() => {
    const { setValue } = getFieldHelpers(name);

    setValue(valueInput, true);
    setTimeout(() => validateField(name), 500);
  }, [getFieldHelpers, name, validateField, valueInput]);

  const disabled = useMemo(() => {
    const { error, value } = getFieldMeta<RefurbishHub[]>(name);
    return !error && (required == true || !value?.length);
  }, [getFieldMeta, name, required]);

  const startAdornment = useMemo(
    () => (disabled ? createElement("span") : undefined),
    [disabled]
  );

  // js-shared hack till we can update these styles directly
  const { containerStyle, labelStyle } = useTextInputStyles({
    disabled,
    startAdornment,
    required,
    isFocused: false,
  });

  // handle clicking 'remove' button, should set all hubs to 'be removed'
  const onClearCallback = useCallback(() => {
    const fieldHelper = getFieldHelpers(name);
    const { value } = getFieldMeta<RefurbishHub[]>(name);

    // grab list of serials
    const serials = value.map(({ serial }) => serial);

    // set list of serials to be removed in hub refurbish context
    updateHubs(serials, { isToBeRemoved: true });

    // invalidate the react query data
    queryClient.invalidateQueries([queryKey]);

    // tell formik our field it is empty
    fieldHelper.setValue([]);
    // also, that it should re - validate to trigger yup validations
    fieldHelper.setTouched(true, true);
  }, [getFieldHelpers, getFieldMeta, name, queryClient, queryKey, updateHubs]);

  return {
    disabled,
    containerStyle,
    labelStyle,
    onClearCallback,
  };
};
