import { startCase } from "lodash-es";

import Helpers from "@/lib/helpers";

/*
  @todo handle
  
  {"code":"redacted","success":false,"type":"USER_CODE_INPUT","command":"SET","status":"CODE_SET_FAILED"}
  {"success":false,"error":"zipatobox-backup.smartrent-xyziot.com","command":"LOAD_ALL","transactionId":"5272aafb-3ed4-41b7-a57c-4959657b2e0e","key":"boxSyncFailed"}
  {"type":"NETWORK_HEAL","network":"ZWAVE","status":"ALWAYS_ON_NODES"}
  {"type":"NETWORK_HEAL","deviceName":"Yale YRD226/246/256 - Door Lock ","network":"ZWAVE","status":"NEIGHBOR_UPDATE_DONE"}
  {"type":"INCLUSION","nodeId":12,"network":"ZWAVE","status":"NODE_ALREADY_INCLUDED"}
  08/07/2020 8:59 AM
  {"server":"https://remote.zipato.smartrent.com/zipato-web/remoting/","backup":true}
  {"success":true,"command":"REQUEST_UPGRADE"} - firmware request upgrade
  {"success":false,"type":"USER_CODE_INPUT","command":"SET","status":"DUPLICATE_CODE"}
  {"success":false,"type":"USER_CODE_INPUT","command":"SET","status":"CODE_SET_FAILED"}
  {"reason":"MemoryGetID failed | com.zipato.network.zwave.ZwaveHelper.updateHomeIdNodeId(ZwaveHelper.java:329)","final":true,"type":"INIT","network":"ZWAVE","status":"FAILED"}
  {"reason":"getLibraryType failed | com.zipato.network.zwave.commands.ZwaveNetworkStartup.getLibraryType(ZwaveNetworkStartup.java:166)","final":true,"type":"INIT","network":"ZWAVE","status":"FAILED"}
  {"reset":"timeout"}
  {"reset":"no res"}
  
  {"secondary":false,"sisNodeId":1,"inclusionCtrl":false,"final":true,"primarySis":true,"zNodeId":1,"type":"INIT","homeId":3722304889,"network":"ZWAVE","status":"DONE"}
  
  {"success":true,"command":"CONFIGURE_LOG"} - configure device node
  
  {"success":false,"error":"Received fatal alert: handshake_failure","command":"SAVE_ALL","key":"boxSaveFailed"}
  {"success":false,"error":"NO NETWORK","command":"DISCOVERY_ON","transactionId":"f35b44e4-09b5-4bfd-ab3a-c8200b6cffd9","key":"boxArmedDiscover"}
  
  {"+QCCID":"8901240112202500033F"}
  {"port":"/dev/ttyUSB3","modem":"eg91"}
  
  */

type Type = "info" | "success" | "error" | "warning";
type Status = "pending" | "error" | "success" | "unknown";

interface BaseMessageFuncProps {
  error: boolean;
  inProgress: boolean;
  success: boolean;
  p: Record<string, string>;
}

interface ZwaveResetMessagesProps
  extends Omit<BaseMessageFuncProps, "p" | "error"> {
  deleteDevice: boolean;
}

export interface TransformedMessage {
  type: Type;
  label: string;
  tip?: string;
}

export function getColor(type: Type) {
  switch (type) {
    case "info":
      return Helpers.colors.blue;
    case "success":
      return Helpers.colors.green;
    case "warning":
      return Helpers.colors.orange;
    case "error":
      return Helpers.colors.red;
  }
}

export function parseNetwork(
  network: undefined | null | string
): "zwave" | "unknown" {
  if (network === "ZWAVE") {
    return "zwave";
  }

  return "unknown";
}

export function parseStatus(p: Record<string, any>): Status {
  if (
    ["STARTED", "IN_PROGRESS"].includes(p.status) ||
    p.started === true ||
    p.started === "STARTED" ||
    p.progress === "loading"
  ) {
    return "pending";
  } else if (["FAILED", "UNSUCCESSFUL", "CODE_SET_FAILED"].includes(p.status)) {
    return "error";
  } else if (
    [
      "SUCCESSFUL",
      "done",
      "DONE",
      "ALL_CODE_DELETED",
      "CODE_DELETED",
      "CODE_SET_SUCCESSFUL",
      "CODE_UPDATED",
    ].includes(p.status) ||
    p.success === true ||
    p.progress === "complete"
  ) {
    return "success";
  }

  return "unknown";
}

export function boxSyncMessages({
  p,
  success,
  inProgress,
}: Omit<BaseMessageFuncProps, "error">): TransformedMessage {
  if (inProgress) {
    return {
      type: "info",
      label: "Box Sync started",
    };
  } else if (p.progress === "todo") {
    return {
      type: "info",
      label: "Box Sync enqueued",
    };
  } else if (p.progress === "todoFailed") {
    return {
      type: "error",
      label: "Box Sync failed to run command",
    };
  }
  if (p.progress === "todoDone") {
    return {
      type: "success",
      label: "Box Sync processed",
    };
  } else if (success) {
    return {
      type: "success",
      label: "Box Sync complete",
    };
  } else if (
    !success &&
    p.key === "boxSyncFailed" &&
    p.error === "result 403"
  ) {
    return {
      type: "error",
      label: "Box Sync failed (403)",
    };
  } else if (p.status) {
    console.log("TODO: Check if Box sync can get more specific:", p);
    return {
      type: "success",
      label: "Box Sync success",
    };
  } else if (p?.error?.includes("Storage is busy.")) {
    return {
      type: "error",
      label: "Box Sync failed (Storage is busy)",
      tip: "Occurs after including device or if user issues Box Sync command and doesn't wait for command to finish properly",
    };
  } else if (p.progress === "empty") {
    return {
      type: "warning",
      label: "Box Sync progress unknown",
    };
  }

  console.warn("Unhandled Box Sync event:", p);

  return {
    type: "warning",
    label: "Box Sync unhandled event",
  };
}

export function saveAllMessages({
  inProgress,
  success,
  p,
}: Omit<BaseMessageFuncProps, "error">): TransformedMessage {
  if (inProgress) {
    return {
      type: "info",
      label: "Save All starting",
    };
  } else if (success) {
    return {
      type: "success",
      label: "Save All complete",
    };
  } else if (p?.error?.includes("Storage is busy.")) {
    return {
      type: "error",
      label: "Save All failed (Storage is busy)",
      tip: "Occurs after including device or if user issues Save All command and doesn't wait for command to finish properly",
    };
  } else if (p?.error?.includes("403")) {
    return {
      type: "error",
      label: "Save All failed (403)",
    };
  } else if (p?.error?.includes("409")) {
    return {
      type: "error",
      label: "Save All failed (409)",
    };
  }

  console.warn("Unhandled Save All event:", p);

  return {
    type: "warning",
    label: "Save All unhandled event",
  };
}

export function zwaveResetMessages({
  inProgress,
  success,
  deleteDevice,
}: ZwaveResetMessagesProps): TransformedMessage {
  if (inProgress) {
    return {
      type: "info",
      label: "Z-Wave Reset started",
    };
  } else if (success) {
    return {
      type: "success",
      label: "Z-Wave Reset complete",
    };
  } else if (deleteDevice) {
    return {
      type: "info",
      label: "Devices being deleted",
    };
  }

  return {
    type: "warning",
    label: "Z-Wave Reset unhandled event",
  };
}

export function firmwareUpdateMessages({
  status,
  percent,
}: {
  status: Status;
  percent: string;
}): TransformedMessage {
  if (percent) {
    return {
      type: "info",
      label: `Firmware update (${percent}%)`,
    };
  } else if (status === "pending") {
    return {
      type: "info",
      label: "Firmware update pending",
    };
  } else if (status === "success") {
    return {
      type: "success",
      label: "Firmware update complete",
    };
  }

  return {
    type: "warning",
    label: "Firmware Update unhandled event",
  };
}

export function parseCodeStatus(
  status: string
):
  | "added"
  | "all_removed"
  | "removed"
  | "add_failed"
  | "timeout"
  | "already_running"
  | "duplicate"
  | "unknown" {
  if (status === "CODE_SET_SUCCESSFUL") {
    return "added";
  } else if (["CODE_RESET_SUCCESSFUL", "CODE_DELETED"].includes(status)) {
    return "removed";
  } else if (status === "CODE_SET_FAILED") {
    return "add_failed";
  } else if (status === "NOTIFICATION_TIMEOUT") {
    return "timeout";
  } else if (status === "ALL_CODES_DELETED") {
    return "all_removed";
  } else if (status === "CODE_SET_ALREADY_RUNNING") {
    return "already_running";
  } else if (status === "DUPLICATE_CODE") {
    return "duplicate";
  }

  console.warn("Unhandled access code status", status);
  return "unknown";
}

export function parseCodeCommand(command: string): "set" | "reset" | "unknown" {
  if (command === "SET") {
    return "set";
  } else if (command === "RESET") {
    return "reset"; // clear code on slot
  }

  console.warn("Unhandled access code command", command);
  return "unknown";
}

export function accessCodeMessages({
  p,
  success,
}: Omit<BaseMessageFuncProps, "error" | "inProgress">): TransformedMessage {
  const codeStatus = parseCodeStatus(p.status);
  const codeCommand = parseCodeCommand(p.command);
  const codeReset = p.code === "RESET"; // Indicates code was removed

  if (codeCommand === "set" && codeStatus === "added" && !codeReset) {
    return { type: "success", label: "Code added" };
  } else if (
    codeCommand === "set" &&
    codeStatus === "duplicate" &&
    !codeReset
  ) {
    return { type: "error", label: "Code add failed (duplicate)" };
  } else if (
    codeCommand === "set" &&
    codeStatus === "add_failed" &&
    !codeReset
  ) {
    return { type: "error", label: "Code add failed" };
  } else if (codeCommand === "set" && codeStatus === "added" && codeReset) {
    return { type: "success", label: "Code removed" };
  } else if (
    codeCommand === "set" &&
    codeStatus === "add_failed" &&
    codeReset
  ) {
    return { type: "error", label: "Code remove failed" };
  } else if (codeCommand === "set" && codeStatus === "timeout") {
    return { type: "warning", label: "Code add timeout" };
  } else if (codeStatus === "all_removed" && success) {
    return { type: "success", label: "All codes removed" };
  } else if (
    ["reset", "set"].includes(codeCommand) &&
    ["removed", "added"].includes(codeStatus)
  ) {
    // We can get `{type: "USER_CODE_INPUT", command: "SET", status: "CODE_DELETED"}` so order matters here
    // This can also be that we cleared all codes on slot_id 0 or 255, but we don't get slot_id in this message so
    // we can't hook into that
    return { type: "success", label: "Code removed" };
  } else if (codeStatus === "already_running") {
    return { type: "warning", label: "Code request already running" };
  }

  console.warn("Unhandled access code message", p);

  return {
    type: "warning",
    label: "Access Code unhandled event",
  };
}

export function signalStrengthMessages({
  p,
}: Pick<BaseMessageFuncProps, "p">): TransformedMessage {
  // {"+CSQ":"21,99"}
  const csq = parseInt(p["+CSQ"].substring(0, p["+CSQ"].indexOf(",")));

  const labelBase = "Cell signal strength:";

  if (csq <= 9) {
    return { type: "error", label: `${labelBase} MARGINAL` };
  } else if (csq <= 14) {
    return { type: "warning", label: `${labelBase} OK` };
  } else if (csq <= 19) {
    return { type: "success", label: `${labelBase} GOOD` };
  } else if (csq <= 31) {
    return { type: "success", label: `${labelBase} EXCELLENT` };
  }

  return { type: "warning", label: "Signal Strength unhandled event" };
}

export function transformMessage(
  msg: string,
  hubType = "zipato"
): TransformedMessage {
  return hubType === "smartrent"
    ? transformMessageSmartrent(msg)
    : transformMessageZipato(msg);
}

/**
 * Contributing guide:
 *
 * 1. Parse the json, discard "pattern matches" that are irrelevant like `transactionId`
 * 2. Write the log as a human-readable message that can be understood by someone non-technical
 * 3. Prefer shorter messages over more verbose ones where possible
 * 4. Prefer nouns before verbs when possible
 * 5. Don't end single sentences with punctuation
 *
 * If an action is `pending` use type of `info`, not `success`
 * If the log is an acknowledgement like "Hub received reboot command" use `info`
 *
 * It's ok to capitalize special Z-Wave phrases or commands like `Home Id` or `Z-Wave Reset`,
 * but otherwise in general use normal sentence casing
 *
 * Note: Order can matter here. More specific matches should go at the top of the transform checks and more generic
 *       go further down.
 *
 * Bad:
 *
 *   - Z-Wave Reset Complete
 *   - Inclusion Failed
 *   - Starting exclusion.
 *
 * Good:
 *
 *   - Z-Wave Reset complete
 *   - Inclusion failed
 *   - Exclusion starting
 *
 * Example of parsing:
 *
 * We get "{\"success\":false,\"type\":\"USER_CODE_INPUT\",\"command\":\"SET\",\"status\":\"DUPLICATE_CODE\"}"
 *
 * We can parse it to an error message of `Add code failed (duplicate)`
 *
 */
export function transformMessageZipato(msg: string): TransformedMessage {
  try {
    const p = JSON.parse(msg);

    const network = parseNetwork(p.network);
    const isZwave = network === "zwave";

    // MISC
    const final = p.final;
    const percent = p.percent;
    const forced = p.forced; // incorrect wifi creds

    // STATUSES
    const status = parseStatus(p);
    const success = status === "success";
    const error = status === "error";
    const inProgress = status === "pending";

    // COMMANDS
    const saveAll = p.command === "SAVE_ALL";
    const boxSync = p.command === "LOAD_ALL"; // box sync
    const discoveryOn = p.command === "DISCOVERY_ON";
    const discoveryOff = p.command === "DISCOVERY_OFF";
    const learnCommand = p.command === "LEARN";

    // TYPES
    const init = p.type === "INIT";
    const firmwareUpdate = p.type === "FIRMWARE_UPDATE";
    const typeCode = p.type === "USER_CODE_INPUT";
    const networkHeal = p.type === "NETWORK_HEAL";
    const zwaveReset = p.type === "HARD_RESET";
    const remove = p.type === "REMOVE" || p.command === "REMOVE";
    const inclusion = p.type === "INCLUSION";
    const secureInclusion = p.type === "SECURE_INCLUSION";
    const rediscovery = p.type === "REDISCOVERY";
    const deleteDevice = p.type === "DELETE_DEVICE";
    const slotId = p.slotId;

    // ERRORS
    const certificateMismatch = p.error === "CERTIFICATE MISMATCH";

    // REASONS
    const deleteAllDevices = p.reason === "DELETE_ALL_DEVICES";

    const signalStrength = p["+CSQ"] !== undefined;

    // {"reason":"running code set 35","success":false,"type":"USER_CODE_INPUT","command":"SET","status":"CODE_SET_ALREADY_RUNNING"}
    // {"success":false,"type":"USER_CODE_INPUT","command":"SET","status":"CODE_SET_FAILED"}
    // {"success":true,"type":"USER_CODE_INPUT","command":"SET","status":"ALL_CODES_DELETED"}

    if (slotId && learnCommand && success) {
      return {
        type: "success",
        label: `Code added or removed, slot ${slotId}`,
      };
    } else if (boxSync) {
      return boxSyncMessages({ p, success, inProgress });
    } else if (zwaveReset || deleteAllDevices) {
      return zwaveResetMessages({ inProgress, success, deleteDevice });
    } else if (firmwareUpdate) {
      return firmwareUpdateMessages({ status, percent });
    } else if (saveAll) {
      return saveAllMessages({ inProgress, success, p });
    } else if (typeCode) {
      return accessCodeMessages({ p, success });
    } else if (signalStrength) {
      return signalStrengthMessages({ p });
    }

    if (p.type === "SET_CONF" && isZwave && success) {
      return { type: "success", label: "Device config set" };
    } else if (p.type === "SET_CONF" && isZwave && inProgress) {
      return { type: "info", label: "Device config pending" };
    } else if (p.type === "SET_CONF" && isZwave) {
      return { type: "info", label: `Device config: ${p.status}` };
    } else if (isZwave && !p.final && success) {
      return {
        type: "success",
        label: `Z-Wave started`,
        tip: p.homeId ? `Home Id: ${p.homeId}` : undefined,
      };
    } else if (init && isZwave && inProgress) {
      return { type: "info", label: "Z-Wave starting" };
    } else if (
      rediscovery &&
      (p.status === "KEEPALIVE" || (status === "pending" && isZwave))
    ) {
      return {
        type: "info",
        label: "Learning about device",
        tip: "Please wait",
      };
    } else if (
      p.reason === "Timeout in state:GET_PROTO_NIF" &&
      final &&
      inclusion &&
      isZwave &&
      p.status === "TIMEOUT"
    ) {
      return {
        type: "error",
        label: "Inclusion timeout",
      };
    } else if (success && discoveryOff) {
      return {
        type: "info",
        label: "Discovery stopped",
        tip: "Inclusion, exclusion, or troubleshooting",
      };
    } else if (success && discoveryOn) {
      return {
        type: "info",
        label: "Discovery started",
        tip: "Inclusion, exclusion, or troubleshooting",
      };
    } else if (
      final &&
      remove &&
      p.message === "stopped manually" &&
      status === "error"
    ) {
      return {
        type: "warning",
        label: "Exclusion stopped manually",
      };
    } else if (
      final &&
      inclusion &&
      p.message === "Aborted by user" &&
      status === "error"
    ) {
      return {
        type: "warning",
        label: "Inclusion stopped manually",
      };
    } else if (remove && p.status === "TIMEOUT" && isZwave) {
      return {
        type: "error",
        label: "Exclusion timeout",
      };
    } else if (remove && status === "error" && isZwave) {
      return {
        type: "error",
        label: "Exclusion failed",
      };
    } else if (inclusion && isZwave && inProgress) {
      return {
        type: "info",
        label: "Inclusion started",
      };
    } else if (rediscovery && p.status === "SAVING") {
      return {
        type: "info",
        label: "Device saving",
      };
    } else if (final && rediscovery && success) {
      return {
        type: "success",
        label: "Device paired",
      };
    } else if (final && remove && success) {
      return {
        type: "success",
        label: "Device excluded",
      };
    } else if (p === "Rebooting") {
      return {
        type: "info",
        label: "Rebooting",
      };
    } else if (success && p.command === "REBOOT") {
      return {
        type: "info",
        label: "Reboot enqueued",
      };
    } else if (success && p.command === "DELETE_DEVICE_ZW") {
      return {
        type: "info",
        label: "Exclusion started",
      };
    } else if (remove && p.status === "NODE_FOUND" && isZwave) {
      return {
        type: "info",
        label: "Exclusion: device found",
      };
    } else if (inclusion && p.status === "DEVICE_FOUND" && isZwave) {
      return {
        type: "info",
        label: "Device found",
      };
    } else if (secureInclusion && success && isZwave) {
      return {
        type: "success",
        label: "Securely included",
      };
    } else if (secureInclusion && p.status === "PIN_AUTH") {
      return {
        type: "info",
        label: "DSK required",
      };
    } else if (secureInclusion && error && isZwave) {
      return {
        type: "error",
        label: "Secure inclusion failed",
      };
    } else if (discoveryOn && certificateMismatch) {
      return {
        type: "error",
        label: "Certificate mismatch",
        tip: "Please rekey hub",
      };
    } else if (p.essid && forced && success) {
      // Couldn't get IP from DHCP server, i.e. incorrect wifi password
      return {
        type: "warning",
        label: `Unable to Join WiFi (${p.essid})`,
        tip: "Please confirm WiFi password. If PW is confirmed, the router isn't issuing an IP address to the hub and this requires a network admin to further assess.",
      };
    } else if (p.essid && success) {
      return {
        type: "success",
        label: `Joined WiFi (${p.essid})`,
      };
    } else if (p.trying) {
      return {
        type: "info",
        label: p.trying,
      };
    } else if (
      p.hasOwnProperty("security") &&
      p.strength &&
      p.hasOwnProperty("name")
    ) {
      return {
        type: "info",
        label: `WiFi found (${p.name ? p.name : "Hidden SSID"})`,
        tip: `Strength: ${p.strength.trim()}, Security: ${p.security}${
          p.channel ? `, Channel: ${p.channel}` : ""
        }`,
      };
    } else if (networkHeal) {
      return {
        type: "success",
        label: `Network Heal`,
        tip: startCase(p.status.toLowerCase()),
      };
    } else if (p["SYSTEM"] === "STARTED") {
      return {
        type: "info",
        label: "OS started",
      };
    } else if (p.reset === "timeout") {
      // unknown what this means
      return {
        type: "error",
        label: "Reset timeout",
      };
    } else if (inProgress) {
      // We got a Pending/Started message without context, this may be just for WiFi scans
      // If this is used for other actions later, we should make messaging more generic

      return {
        type: "info",
        label: "Scan started",
      };
    } else if (success && final) {
      // We got a final: true, success message but no context, possibly just WiFi scans
      // If this is used for other actions later, we should make messaging more generic

      return {
        type: "info",
        label: "Scan complete",
      };
    } else if (p.server && !p.backup) {
      return {
        type: "info",
        label: "Connected to Zipato",
        tip: p.server,
      };
    } else if (p["+QCCID"]) {
      return {
        type: "info",
        label: `ICCID (${p["+QCCID"].replace("F", "")})`,
      };
    } else if (p.port === "/dev/ttyUSB3" && p.modem === "eg91") {
      // I think this is right
      return {
        type: "info",
        label: "Syncing cell stats",
      };
    }

    return {
      type: "info",
      label: msg.trim(),
    };
  } catch (err) {
    if (msg === "#truncated#") {
      return { type: "info", label: "truncated" };
    } else if (
      !(
        msg.includes("booted") ||
        msg.includes("Rebooting") ||
        msg.includes("serial ") ||
        msg.includes("upgrade flags set")
      )
    ) {
      console.error(err);
      console.log("Please parse message in HubLogs component:", msg);
    }

    return { type: "info", label: msg.trim() };
  }
}

interface SRHubMessage {
  reason?: string | { request: { cmd?: string; timezone?: string } };
  code?: string;
  msg?: string;
  zwave_sdk_version?: string;
  zwave_device_ids?: string;
  active_interface?: string;
  type?: string;
  board_rev?: string;
  serial_number?: string;
  zwave_series?: string;
  value?: { devices: string };
}
function parseHubMessage(log: SRHubMessage): TransformedMessage {
  if (log.reason === "network_restored") {
    return {
      type: "info",
      label: `Hub is ready. Zwave SDK: ${log.zwave_sdk_version}`,
      tip: log.zwave_device_ids
        ? `Zwave Device IDs: ${log.zwave_device_ids}`
        : undefined,
    };
  } else if (!!log.active_interface) {
    return {
      type: "info",
      label: `Active Interface: ${log.active_interface}`,
    };
  } else if (
    typeof log.reason !== "string" &&
    log.reason?.request?.cmd === "devices"
  ) {
    return {
      type: "info",
      label: `Devices`,
      tip: log.value?.devices ? log.value.devices : undefined,
    };
  } else if (log.code === "going_down") {
    return {
      type: "error",
      label: `${log.msg}`,
    };
  } else if (log.msg === "not_found") {
    return {
      type: "warning",
      label: "Hub Not Found",
    };
  } else if (log.msg === "network_not_ready") {
    return {
      type: "warning",
      label: "Network Not Ready",
    };
  } else if (log.msg === "not_reachable") {
    return {
      type: "warning",
      label: "Not Reachable",
    };
  } else if (typeof log.reason !== "string" && log.reason?.request?.timezone) {
    return {
      type: "info",
      label: `Set Timezone: ${log.reason?.request?.timezone}`,
    };
  } else if (log.msg === "failed to set timezone") {
    return {
      type: "warning",
      label: "Failed to set timezone",
    };
  } else if (!!log.serial_number && log.zwave_sdk_version) {
    return {
      type: "info",
      label: `HUB INFO - Type: ${log.type}, Zwave SDK: ${log.zwave_sdk_version}, Zwave Series: ${log.zwave_series}, Board Rev: ${log.board_rev}`,
    };
  }

  return {
    type: "info",
    label: JSON.stringify(log),
  };
}

interface BackupMessage {
  reason?: { cmd: string };
  status?: string;
}

function parseBackupMessage(log: BackupMessage): TransformedMessage {
  if (log.reason?.cmd === "backup") {
    return {
      type:
        log.status === "complete"
          ? "success"
          : log.status === "failed"
            ? "error"
            : "info",
      label: `Backup ${log.status}`,
    };
  } else if (log.reason?.cmd === "restore") {
    return {
      type: "info",
      label: `Restore ${log.status}`,
    };
  }

  return {
    type: "info",
    label: JSON.stringify(log),
  };
}

interface NetworkMessage {
  network?: string;
  status?: string;
  type?: string;
  code?: string;
  reason?: string | { request: { cmd: string } };
  report?: string;
  zwave_sdk_version?: string;
  serial_number?: string;
  attribute?: {
    value: string;
  };
}
function parseNetworkMessage(log: NetworkMessage): TransformedMessage {
  const reason = log.reason;

  if (reason === "notification") {
    if (log.network === "update") {
      return {
        type: "info",
        label: `New Active Interface: ${log.attribute?.value}`,
      };
    }
  } else if (reason === "network_healing") {
    return {
      type:
        log.status === "done"
          ? "success"
          : log.status === "failed"
            ? "error"
            : "info",
      label: `Network healing - ${log.network} - ${log.status}`,
    };
  } else if (log.type === "inclusion") {
    return {
      type:
        log.status === "completed"
          ? "success"
          : log.status === "canceled"
            ? "warning"
            : log.status === "failed"
              ? "error"
              : "info",
      label: `Inclusion - ${log.network} - ${log.status}`,
    };
  } else if (
    typeof log.reason !== "string" &&
    log.reason?.request?.cmd === "cancel_inclusion"
  ) {
    return {
      type: "warning",
      label: `Cancel Inclusion - ${log.code}`,
    };
  } else if (log.type === "exclusion") {
    return {
      type:
        log.status === "completed"
          ? "success"
          : log.status === "canceled"
            ? "warning"
            : log.status === "failed"
              ? "error"
              : "info",
      label: `Exclusion - ${log.network} - ${log.status}`,
    };
  } else if (
    typeof log.reason !== "string" &&
    log.reason?.request?.cmd === "cancel_exclusion"
  ) {
    return {
      type: "warning",
      label: `Cancel Exclusion - ${log.code}`,
    };
  } else if (log.type === "zwave_serial_api") {
    return {
      type: "info",
      label: `Zwave Serial API - ${log.status}`,
    };
  } else if (log.type === "smart_start") {
    return {
      type: "info",
      label: `Smart Start - ${log.status}`,
    };
  } else if (log.type === "remove_failed_node") {
    return {
      type: "warning",
      label: `Remove Failed Node - ${log.status}`,
    };
  } else if (log.type === "failed_nodes") {
    return {
      type: "warning",
      label: `Failed Node - ${log.reason} - ${log.status}`,
      tip: log.report,
    };
  } else if (log.type === "learn_mode") {
    return {
      type: "info",
      label: `Learn Mode - ${log.status}`,
    };
  } else if (log.type === "zwave_reset") {
    return {
      type: "info",
      label: `Zwave Reset - ${log.status}`,
    };
  } else if (log.zwave_sdk_version && log.serial_number) {
    return {
      type: "info",
      label: `Network Info - Network: ${log.network}, Zwave SDK: ${log.zwave_sdk_version}`,
    };
  }

  return {
    type: "info",
    label: JSON.stringify(log),
  };
}

interface NervesMessage {
  application: string;
  event: string;
  firmware_uuid: string;
  hub_serial: string;
  identifier: string;
  log_level: string;
  mfa: string;
  pid: string;
  service: string;
  source: string;
  task: string;
  timestamp: string;
}

function parseNervesMessage(log: NervesMessage): TransformedMessage {
  const event = log.event;

  if (event === "nerves_hub.devices.connect") {
    return {
      type: "success",
      label: "Nerves Hub Connected",
      tip: "Firmware UUID: " + log.firmware_uuid,
    };
  } else if (event === "nerves_hub.devices.disconnect") {
    return {
      type: "error",
      label: "Nerves Hub Disconnected",
    };
  } else if (event === "nerves_hub.devices.update.automatic") {
    return {
      type: "info",
      label: "Nerves Hub Automatic Update",
    };
  }

  return {
    type: "info",
    label: JSON.stringify(log),
  };
}

export function transformMessageSmartrent(msg: string): TransformedMessage {
  const p = JSON.parse(msg);

  const service = p.service; // control-room or nerves_hub_device
  if (service === "nerves_hub_device") {
    return parseNervesMessage(p);
  } else {
    switch (p.key) {
      case "smartrent.backup_message":
        return parseBackupMessage(p);
      case "smartrent.network_message":
        return parseNetworkMessage(p);
      case "smartrent.hub_message":
        return parseHubMessage(p);
    }
  }

  return { type: "info", label: msg.trim() };
}
