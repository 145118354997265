import { PaginatedResponse } from "@smartrent/hooks";

import { HubProps } from "./HubProps";

export interface UnitProps {
  id: string;
  marketing_name: string;
  group_id: number;
  remote_id: null | string;
  street_address_1: string;
  street_address_2: null | string;
  city: string;
  state: string;
  zip: string;
  country: string;
  lat: null | string;
  lng: null | string;
  building_id: null | string;
  building: null | string;
  floor: null | string;
  is_occupied: boolean;
  status: UnitStatus | string;
  unit_status?: UnitStatus | string;
  image_url: null | string;
  can_be_toured: boolean;
  display_home_online: boolean;
  deleted_at: null | string;
  timezone: null | string;
  hub?: HubProps;
  connected_to_community_wifi?: boolean;
  resident_unit_wifi_accounts?: any[];
  resident_unit_wifi_configuration?: {
    enabled: boolean;
    provider: string;
  };
}

export enum UnitStatus {
  Occupied = "Occupied",
  Vacant = "Vacant",
  CommonArea = "Common Area",
  Model = "Model",
  Down = "Down",
  Sold = "Sold",
  Excluded = "Excluded",
  Admin = "Admin",
}

export type UnitsResponse = PaginatedResponse<UnitProps>;
