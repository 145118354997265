import * as React from "react";

import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

type Props = {
  children: React.ReactNode;
  borderColor?: string;
  color?: string;
  className?: string;
  style?: Object;
};

export function HubPrepPill({
  children,
  borderColor = "#333",
  color = "#333",
  style,
}: Props) {
  return (
    <View style={[styles.container, { borderColor }, style]}>
      <Typography style={{ color }}>{children}</Typography>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    border: `1px solid #333`,
    paddingLeft: 8,
    paddingRight: 8,
  },
});
