import { PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { createAxiosQuery } from "@/hooks/react-query";
import { EmailWebhookView, ResidentProps } from "@/types";

export const useEmailWebhooksAxiosQuery = createAxiosQuery(
  "email-webhooks",
  async ({
    email,
    params = { limit: 25, sortBy: "id", orderBy: "DESC" },
  }: {
    email: ResidentProps["email"];
    params?: {
      limit?: number;
      orderBy?: "DESC" | "ASC";
      sortBy?: keyof EmailWebhookView;
    };
  }) =>
    instance.get<PaginatedResponse<EmailWebhookView>>(
      `/email-webhooks/${encodeURIComponent(email)}`,
      { params }
    )
);
