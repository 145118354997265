import { View, StyleProp, ViewStyle, StyleSheet } from "react-native";
import { Typography, useTheme } from "@smartrent/ui";

interface ValueLabelProps {
  value: string;
  label: string;
  style?: StyleProp<ViewStyle>;
}

export default function ValueLabel({ value, label, style }: ValueLabelProps) {
  const { colors } = useTheme();

  return (
    <View style={[styles.container, style]}>
      <Typography style={[styles.label, { color: colors.textSecondary }]}>
        {label}:
      </Typography>
      <Typography style={[styles.value, { color: colors.textPrimary }]}>
        {value}
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "flex-end",
    height: 24,
  },
  label: {
    alignSelf: "flex-end",
    marginRight: 12,
    fontSize: 14,
    lineHeight: 20,
  },
  value: { fontSize: 18, fontWeight: "bold" },
});
