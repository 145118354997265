import { useState, useCallback, useMemo } from "react";
import * as React from "react";
import {
  TouchableOpacity,
  StyleSheet,
  View,
  StyleProp,
  ViewStyle,
} from "react-native";

import { AlertDialog, useTheme } from "@smartrent/ui";
import { Copy, CheckCircle } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

export interface CopyTextProps {
  text: string;
  iconSize?: number;
  iconColor?: string;
  style?: StyleProp<ViewStyle>;
}

export const CopyText: React.FC<React.PropsWithChildren<CopyTextProps>> = ({
  text,
  iconSize = 18,
  iconColor,
  style,
  children,
}) => {
  const [successState, setSuccessState] = useState<boolean>(false);
  const { visible, onOpen, onClose } = useModalState();
  const { colors } = useTheme();

  const color = useMemo(
    () => (iconColor ? iconColor : colors.primary),
    [iconColor, colors]
  );

  const copyText = useCallback<() => void>(async () => {
    try {
      await navigator.clipboard.writeText(text);
      setSuccessState(true);
      setTimeout(() => {
        setSuccessState(false);
      }, 2000);
    } catch (e) {
      onOpen();
    }
  }, [text, onOpen]);

  return (
    <>
      <TouchableOpacity onPress={copyText} style={style}>
        {children ? (
          <View style={[styles.flexRow, style]}>
            {children}
            {successState ? (
              <CheckCircle color={color} size={iconSize} />
            ) : (
              <Copy color={color} size={iconSize} />
            )}
          </View>
        ) : successState ? (
          <CheckCircle color={color} size={iconSize} />
        ) : (
          <Copy color={color} size={iconSize} />
        )}
      </TouchableOpacity>
      <AlertDialog
        title="Failed to Copy Text"
        description="Sorry about that... You might try updating your browser."
        visible={visible}
        onClose={onClose}
      />
    </>
  );
};

const styles = StyleSheet.create({
  flexRow: { flexDirection: "row", alignItems: "center" },
});
