import { useContext, useCallback, useMemo } from "react";
import moment from "moment";
import { Tooltip, useTheme } from "@smartrent/ui";
import { Launch } from "@smartrent/icons";

import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";
import { formatDate, isDecentHoursToCall, salesforceUrl } from "@/utils";
import Card from "@/common/Card";
import { useDrawerNav } from "@/common/AppDrawer";

import { useGroup } from "../GroupContext";

import {
  GroupDetailsHeader,
  groupDetailsHeaderActions,
} from "./group-details/GroupDetailsHeader";
import { GroupDetailsDebugFooter } from "./group-details/GroupDetailsDebugFooter";

export default function GroupDetails() {
  const context = useContext(Context);
  const { group, groupCommunityWifi, refetchGroup } = useGroup();
  const {
    user: { permissions },
  } = useContext(Context);
  const { colors } = useTheme();

  const drawer = useDrawerNav();

  const handleUpdateGroupActionClick = useCallback(
    () => drawer.push("updateGroup", group),
    [drawer, group]
  );

  const isDecentHours = useMemo(
    () => isDecentHoursToCall(group.timezone),
    [group.timezone]
  );

  const handleLinkIntegrationClick = useCallback(() => {
    drawer.push("linkIntegrationToGroup", { group, refetchGroup });
  }, [drawer, group, refetchGroup]);

  return (
    <Card
      heading={<GroupDetailsHeader />}
      actions={groupDetailsHeaderActions({
        group,
        context,
        permissions,
        groupCommunityWifi,
        handleUpdateGroupActionClick,
        handleLinkIntegrationClick,
      })}
    >
      <div className="u-mbottom8">
        {group.group_image ? (
          <img
            src={`${group.group_image}?fit=clamp&max-h=275&w=432`}
            alt={group.marketing_name}
            className="u-size-full"
            style={{ maxHeight: "275px" }}
          />
        ) : null}
      </div>
      <div className="u-line18 u-flex u-flex-justify-between u-pright4">
        <div>{Helpers.formatAddress(group)}</div>
        <a
          href={`https://maps.google.com?q=${group.lat},${group.lng}`}
          target="_blank"
          className="a u-font11"
          rel="noopener noreferrer"
        >
          (Map)
        </a>
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Time:</span>{" "}
        <Tooltip
          name="group timezone"
          title={`The current time of the property. Right now, ${
            isDecentHours
              ? "it should be ok to contact residents."
              : "it's not a good time to contact residents."
          }`}
        >
          <span
            style={{
              padding: "2px 4px",
              borderRadius: 4,
              backgroundColor: isDecentHours ? colors.green : colors.error,
              color: colors.white,
            }}
          >
            {formatDate({
              date: new Date().toISOString(),
              pattern: "p",
              timeZone: group.timezone,
            })}
          </span>
        </Tooltip>{" "}
        ({group.timezone})
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Property Code:</span>{" "}
        {group.property_code}
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Vacant Access Codes Enabled:</span>{" "}
        {group.vacant_access_codes_enabled ? "Yes" : "No"}
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Resident Security Enabled:</span>{" "}
        {group.resident_security ? "Yes" : "No"}
      </div>
      <div className="u-mtop12 u-line18">
        <div style={{ textTransform: "capitalize" }}>
          <span className="u-text-gray-600">Temperature Scale:</span>{" "}
          {group.temperature_scale}
        </div>
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Created:</span>{" "}
        {Helpers.formatDate(group.inserted_at)} (
        {moment(group.inserted_at).fromNow()})
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Updated:</span>{" "}
        {group.updated_at ? Helpers.formatDate(group.updated_at) : "N/A"} (
        {moment(group.updated_at).fromNow()})
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">UUID:</span> {group.uuid}
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">WiFi Device Network Enabled:</span>{" "}
        {groupCommunityWifi?.enabled ? "Yes" : "No"}
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Zipato Environment:</span>{" "}
        {group.zipato_env ?? "None"}
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">Salesforce Id:</span>{" "}
        {group.salesforce_id ? (
          <a
            href={`${salesforceUrl()}/lightning/r/Property__c/${
              group.salesforce_id
            }/view`}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {group.salesforce_id} <Launch size={8} color={colors.textPrimary} />
          </a>
        ) : (
          " Not Linked"
        )}
      </div>
      <div className="u-mtop12 u-line18">
        <span className="u-text-gray-600">NetSuite Id:</span>{" "}
        {group.netsuite_id ? ` ${group.netsuite_id}` : " Not Linked"}
      </div>

      <GroupDetailsDebugFooter group={group} permissions={permissions} />
    </Card>
  );
}
