import { useMemo } from "react";
import { StyleSheet } from "react-native";

import {
  SelectableFlatList,
  ListItemTouchableContainer,
  Typography,
  MessageCard,
  ListItemLoadingContainer,
  ListItemLoadingValue,
  VStack,
  Stack,
} from "@smartrent/ui";

import { orderBy } from "lodash-es";

import { formatPhone } from "@/utils";

import { alloyInstallUserDetailLink } from "@/utils/links";

import { UserProps } from "../../../types";

const NoAssignedUsersFound = () => {
  return <MessageCard heading="No Assigned Users Found" message="" />;
};

const AssignedUsersDetailsListItemLoading = () => {
  return (
    <ListItemLoadingContainer>
      <ListItemLoadingValue />
    </ListItemLoadingContainer>
  );
};

const UserDetails = ({ first_name, last_name, email, phone }) => {
  return (
    <VStack spacing={4} width="100%">
      <Typography type="title3">
        {first_name} {last_name}
      </Typography>
      <Stack direction="row" wrap={true} style={styles.userDetails}>
        <Typography type="bodySmall" color="helperText">
          {email}
        </Typography>
        <Typography type="bodySmall" color="helperText">
          {formatPhone({ phone })}
        </Typography>
      </Stack>
    </VStack>
  );
};

const UserDetailListItem = ({ item }) => {
  return (
    <ListItemTouchableContainer
      style={styles.listItemContainer}
      href={item?.id ? alloyInstallUserDetailLink(item.id) : undefined}
    >
      <UserDetails {...item} />
    </ListItemTouchableContainer>
  );
};

interface AlloyUserProps extends Partial<UserProps> {}

export const AssignedUsersModalList = (users: AlloyUserProps[]) => {
  const data = useMemo(
    () => orderBy(users, ["first_name", "last_name"], "asc"),
    [users]
  );

  return (
    <SelectableFlatList
      style={styles.root}
      contentContainerStyle={styles.contentContainer}
      keyExtractor={(item, index) => `${index}-${item?.id?.toString()}`}
      ItemComponent={UserDetailListItem}
      onSelectItem={() => null}
      selectedItems={[]}
      selectedKey=""
      data={data}
      ListEmptyComponent={NoAssignedUsersFound}
      LoadingItemComponent={AssignedUsersDetailsListItemLoading}
      ListHeaderComponent={null}
      isLoading={false}
      totalRecordsLoaded={data?.length ? data.length : 0}
      totalRecords={data?.length ? data.length : 0}
      totalPages={1}
    />
  );
};

const styles = StyleSheet.create({
  root: { paddingVertical: 0 },
  contentContainer: { paddingVertical: 0 },
  userDetails: { justifyContent: "space-between" },

  listItemContainer: { marginVertical: 8 },
});
