import { StyleSheet, View } from "react-native";

import { PropertyStaffUsersReportQueryResponse } from "@/api/reports";

import { UserReportItem } from "./UsersReportItem";

export const UsersReport = ({
  active,
  inactive,
  not_accepted,
  never_signed_in,
  total,
}: PropertyStaffUsersReportQueryResponse) => {
  return (
    <View style={styles.container}>
      <UserReportItem
        title="Total Staff Users"
        count={total}
        color="grayscale"
        alwaysVisible={true}
      />
      <UserReportItem
        title="Active"
        count={active}
        color="success"
        toolTipProps={{
          name: "active_users",
          title: "Last signed on within 15 days",
        }}
      />
      <UserReportItem
        title="Inactive"
        count={inactive}
        color="error"
        toolTipProps={{
          name: "inactive_users",
          title: "Last signed on greater than 15 days",
        }}
      />
      <UserReportItem
        title="Never Signed In"
        count={never_signed_in}
        color="warning"
      />
      <UserReportItem title="Not Accepted" count={not_accepted} color="info" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: { paddingVertical: 0 },
});
