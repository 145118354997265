import { SelectOption } from "@smartrent/ui";

export const installDetailInstallTypes: SelectOption[] = [
  {
    value: "new_construction",
    label: "New Construction",
  },
  {
    value: "retrofits",
    label: "Retrofits",
  },
  {
    value: "hub_conversions",
    label: "Hub Conversions",
  },
  {
    value: "quality_assurance",
    label: "Quality Assurance",
  },
  {
    value: "opt-out_installs",
    label: "Opt-Out Installs",
  },
  {
    value: "field_return",
    label: "Field Return",
  },
  {
    value: "hub_swap",
    label: "Hub Swap",
  },
];

export const installDetailDeviceTypes: SelectOption[] = [
  {
    value: "hub",
    label: "Hub",
  },
  {
    value: "ring_device",
    label: "Ring Device",
  },
  {
    value: "entry_control",
    label: "Door Lock",
  },
  {
    value: "switch_binary",
    label: "Outlet/Plug/Switch",
  },
  { value: "switch_multilevel", label: "Dimmer Switch/Bulb" },
  { value: "shade_control", label: "Shade Control" },
  { value: "repeater_slave", label: "Repeater" },
  { value: "thermostat", label: "Thermostat" },
  {
    value: "garage_door_controller",
    label: "Garage Door Controller",
  },
  {
    value: "siren",
    label: "Siren",
  },
];
