import { useQuery } from "@tanstack/react-query";

import { instance } from "@/lib/hooks";

export const useProspectQuery = (prospectId: number, options: {}) => {
  return useQuery<any>(
    ["prospect", prospectId],
    async () => {
      return await instance
        .get(`/prospects/${prospectId}`)
        .then((response) => response.data);
    },
    { ...options }
  );
};
