import { startCase } from "lodash-es";

import { useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

import {
  SalesForceAccount,
  SalesForceOpportunity,
  SalesForceProperty,
  SalesforceDevice,
} from "@/types";
import { createAxiosQuery } from "@/hooks/react-query";

export const useSalesforceAccount = createAxiosQuery(
  "salesforce-account",
  (account_id) =>
    instance.get<PaginatedResponse<SalesForceAccount>>(`/salesforce/accounts`, {
      params: {
        Id: account_id,
        limit: 1,
      },
    })
);

export const useSalesforceProperty = createAxiosQuery(
  "salesforce-account",
  (property_id) =>
    instance.get<PaginatedResponse<SalesForceAccount>>(
      `/salesforce/properties`,
      {
        params: {
          Id: property_id,
          limit: 1,
        },
      }
    )
);

export const selectQueryPropsSalesforceOpportunities = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceOpportunity>>(
    (inputValue) => ["salesforce-opportunities", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;
      return instance
        .get(`/salesforce/opportunities`, {
          params: {
            name: inputValue ? inputValue : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceOpportunityProperties = (
  opportunity_id?: string
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceOpportunity>>(
    (inputValue) =>
      ["salesforce-properties", opportunity_id, inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, , inputValue] = queryKey;

      return instance
        .get(`/salesforce/opportunity-properties`, {
          params: {
            name: inputValue ? inputValue : undefined,
            opportunity_id: opportunity_id ? opportunity_id : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    { enabled: opportunity_id ? true : false },
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceProperties = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceProperty>>(
    (inputValue) => ["salesforce-properties", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;

      return instance
        .get(`/salesforce/properties`, {
          params: {
            name: !isValidSfId(inputValue) ? inputValue : undefined,
            Id: isValidSfId(inputValue) ? inputValue : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceAccounts = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<SalesForceAccount>>(
    (inputValue) => ["salesforce-accounts", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;

      return instance
        .get(`/salesforce/accounts`, {
          params: {
            name: !isValidSfId(inputValue) ? inputValue : undefined,
            Id: isValidSfId(inputValue) ? inputValue : undefined,
            page: pageParam,
            limit: 150,
          },
        })
        .then((response) => response.data);
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

export const selectQueryPropsSalesforceDevices = () =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery(
    (inputValue) => ["salesforce-devices", { inputValue }] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, { inputValue }] = queryKey;
      return instance
        .get(`/salesforce/devices`, {
          params: {
            term: inputValue ? inputValue : undefined,
            category: startCase(window.location.href.split("add-")[1]),
            page: pageParam,
            limit: 25,
          },
        })
        .then(
          (response) => response.data as PaginatedResponse<SalesforceDevice>
        );
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

// https://gist.github.com/step307/3d265b7c7cb4eccdf0cf55a68c9cfefa
function isValidSfId(str) {
  // https://stackoverflow.com/a/29299786/1333724
  if (typeof str !== "string" || str.length !== 18) {
    return false;
  }

  const upperCaseToBit = (char) => (char.match(/[A-Z]/) ? "1" : "0");
  const binaryToSymbol = (digit) =>
    digit <= 25
      ? String.fromCharCode(digit + 65)
      : String.fromCharCode(digit - 26 + 48);

  const parts = [
    str.slice(0, 5).split("").reverse().map(upperCaseToBit).join(""),
    str.slice(5, 10).split("").reverse().map(upperCaseToBit).join(""),
    str.slice(10, 15).split("").reverse().map(upperCaseToBit).join(""),
  ];

  const check = parts.map((str) => binaryToSymbol(parseInt(str, 2))).join("");

  return check === str.slice(-3);
}
