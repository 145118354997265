import { useReducedInfiniteQuery } from "@smartrent/hooks";

import { createAxiosMutation } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";

import { GroupProps } from "../types";

interface GroupPaginatedResult {
  records: GroupProps[];
  total_records: number;
  total_pages: number;
  current_page: number;
}

export interface GroupListFilters {
  organization_id: number;
  marketing_name?: string;
  parking_enabled?: boolean;
  active?: boolean;
  limit?: number;
  dir?: "asc" | "desc";
}

export const useGroupsInfiniteQuery = (
  filters: Omit<GroupListFilters, "page">
) => {
  return useReducedInfiniteQuery<GroupPaginatedResult>(
    [
      "groups",
      {
        ...filters,
      },
    ],
    async ({ pageParam }) => {
      const { data } = await instance.get("/groups", {
        params: { ...filters, page: pageParam },
      });
      return data;
    }
  );
};

export const useUpdateGroupMutation = createAxiosMutation(
  async ({ id, ...formikValues }: Partial<GroupProps>) =>
    instance.patch(`/groups/${id}`, formikValues)
);
