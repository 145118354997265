import { UserProps } from "@/types";

import { EM_DASH } from "./chars";

export const formatUsersFullName = (user: Partial<UserProps>) =>
  user?.first_name || user?.last_name
    ? `${getUsersFirstName(user)} ${getUsersLastName(user)}`.trim()
    : EM_DASH;

// if null, return empty string since full name shouldn't have EM_DASH in it
const getUsersFirstName = (user: Partial<UserProps>) =>
  user?.first_name ? user.first_name : "";
const getUsersLastName = (user: Partial<UserProps>) =>
  user?.last_name ? user.last_name : "";
