import { StyleSheet } from "react-native";

import { Stack, Typography, useTheme, VStack } from "@smartrent/ui";

import Badge from "@/common/Badge";
import { useIsMobile } from "@/hooks/breakpoints";

export default (headerText, statusText, statusColor) => {
  const { colors } = useTheme();
  const isMobile = useIsMobile();
  return (
    <VStack spacing={16} style={styles.root}>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={isMobile ? 16 : 24}
        style={styles.colRight}
        width="100%"
      >
        <Typography type="title">{headerText}</Typography>

        <Badge color={statusColor} text={statusText} />
      </Stack>
      <Typography type="title3" style={{ color: colors.textSecondary }}>
        Workers Assigned
      </Typography>
    </VStack>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingBottom: 8,
    paddingHorizontal: 8,
    justifyContent: "space-between",
    width: "100%",
  },
  colRight: {
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
});
