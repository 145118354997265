import { useQuery } from "@tanstack/react-query";

import { useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { UnitsResponse, GenericObject } from "@/types";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import { CheckHubsCommunityWifiStateProps } from "@/types/CheckHubsCommunityWifiStateProps";

export const getAllGroupUnits = (
  _key: string,
  groupId: string,
  filters: any,
  page = 1
) =>
  instance.get(`/units`, {
    params: {
      page,
      limit: 50,
      sortBy: "marketing_name",
      ...filters,
      groupId,
    },
  });

export const useGroupUnitsQuery = (groupId, filters) =>
  useReducedInfiniteQuery(
    ["units", groupId, filters],
    async ({ pageParam }): Promise<UnitsResponse> => {
      const response = await instance.get(`/units`, {
        params: {
          page: pageParam,
          limit: 50,
          sortBy: "marketing_name",
          ...filters,
          groupId,
        },
      });

      return response.data;
    },
    {
      enabled: !!groupId,
    }
  );

export const useGroupUnitsTableQuery = async ({ queryKey }) => {
  const [, params] = queryKey;
  const { data } = await instance.get(`/units`, { params });
  return data;
};

export const useUnitsWithBatteryPoweredThermostats = async (
  key: "units-with-battery-powered-tstats",
  filters
) => {
  const { groupId } = filters;
  const { data } = await instance.get(
    `/groups/${groupId}/units/battery-powered-thermostats`,
    {
      params: {
        sortBy: "units.marketing_name",
        ...filters,
      },
    }
  );
  return data;
};

export const useUnit = (unitId) => {
  return useQuery(
    ["use-unit", unitId],
    async () => {
      const { data } = await instance.get<GenericObject>(`/units/${unitId}`);
      return data;
    },
    { enabled: !!unitId }
  );
};

export const useCheckUnitsCommunityWifiStateQuery = createAxiosQuery(
  "check-units-hub-community-wifi-status",
  async ({ unitId }) =>
    instance.post<CheckHubsCommunityWifiStateProps>(
      `/units/${unitId}/hub/check-community-wifi`
    )
);

export const useConnectToCommunityWifiMutation = createAxiosMutation(
  async ({ unitId }) =>
    instance.post(`/units/${unitId}/hub/connect-community-wifi`)
);

export interface stuckAccessCodesResponse {
  stuck_codes: boolean;
}

export const useStuckAccessCodesQuery = createAxiosQuery(
  "unit-stuck-access-codes",
  async ({ unitId }) => {
    return instance.get<stuckAccessCodesResponse>(
      `/units/${unitId}/stuck-codes`
    );
  }
);

export const useRetryStuckAccessCodes = createAxiosMutation(
  async ({ unitId }) => instance.post(`/units/${unitId}/stuck-codes`),
  {
    successToast: () => ({
      message:
        "Retrying stuck codes! This process can take up to 10 minutes. Please refresh the page to verify that the retry was successful.",
    }),
    errorToast: (err) => {
      if (err?.response?.status === 409) {
        return {
          message:
            "Unable to retry stuck codes. Please wait 5 minutes before trying again.",
        };
      } else if (err?.response?.status === 422) {
        return {
          message:
            "Unable to retry stuck codes. There may no longer be any stuck codes that can be retried.",
        };
      }
      return {
        message: "Unable to retry stuck codes.",
      };
    },
  }
);
