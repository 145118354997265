import { useMemo, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { Table, TableProps } from "@smartrent/ui";
import { useQueryCompat, CompatQueryFunction } from "@smartrent/hooks";

import { useUrlFilters } from "@/hooks/url-filters";
import { useUrlPagination } from "@/hooks/url-pagination";
import { useUrlSort, SortDirection } from "@/hooks/url-sort";
import { ReactQueryTableBaseRecord, ListQueryResponse } from "@/types";

import { FilterValues } from "./types";

type QueryKey = readonly unknown[];

interface QueryKeyFnOptions {
  filters: FilterValues;
  page: number;
  pageSize: number;
  sortColumn?: string;
  sortDirection?: SortDirection;
}

export type GetQueryKeyFn<TKey extends QueryKey = any> = (
  opts: QueryKeyFnOptions
) => TKey;

export type UrlControlledReactQueryTableProps<
  TKey extends QueryKey,
  TRecord extends ReactQueryTableBaseRecord,
  TResult extends ListQueryResponse<TRecord>,
> = Omit<
  TableProps<TRecord>,
  | "data"
  | "totalPages"
  | "totalRecords"
  | "loading"
  | "fetching"
  | "pageSize"
  | "currentPage"
  | "onPageChange"
  | "sortColumn"
  | "sortDirection"
  | "onSortChange"
  | "filters"
  | "onFiltersChange"
  | "tableClearFiltersAction"
  | "drawerClearFiltersAction"
> & {
  fetch: CompatQueryFunction<TKey, TResult>;
  getQueryKey: GetQueryKeyFn<TKey>;
  prefetchNextPage?: boolean;
  getRowHref?: (record: any) => string;
};

type UseUrlControlledReactQueryTableState<
  TKey extends QueryKey,
  TRecord extends ReactQueryTableBaseRecord,
  TResult extends ListQueryResponse<TRecord>,
> = Pick<
  UrlControlledReactQueryTableProps<TKey, TRecord, TResult>,
  "columns" | "getQueryKey" | "fetch"
>;

export function useUrlControlledReactQueryTableState<
  TKey extends QueryKey,
  TRecord extends ReactQueryTableBaseRecord,
  TResult extends ListQueryResponse<TRecord>,
>({
  columns,
  getQueryKey,
  fetch,
}: UseUrlControlledReactQueryTableState<TKey, TRecord, TResult>) {
  const { page: queryKeyPage, pageSize: queryKeyPageSize } = useUrlPagination();

  const sort = useUrlSort();

  const filterKeys = useMemo(
    () => columns.filter((c) => c.filter || c.filterType).map((c) => c.name),
    [columns]
  );

  const filters = useUrlFilters({
    keys: filterKeys,
  });

  const queryKey = useMemo(
    () =>
      getQueryKey({
        filters: filters.filters,
        page: queryKeyPage,
        pageSize: queryKeyPageSize,
        sortColumn: sort.column,
        sortDirection: sort.direction,
      }),
    [
      getQueryKey,
      filters,
      queryKeyPage,
      queryKeyPageSize,
      sort.column,
      sort.direction,
    ]
  );

  return useQueryCompat<TKey, TResult>(queryKey, fetch, {
    keepPreviousData: true,
  });
}

export function UrlControlledReactQueryTable<
  TKey extends QueryKey,
  TRecord extends ReactQueryTableBaseRecord,
  TResult extends ListQueryResponse<TRecord>,
>({
  fetch,
  getQueryKey,
  prefetchNextPage,
  columns,
  getRowHref,
  ...props
}: UrlControlledReactQueryTableProps<TKey, TRecord, TResult>) {
  const sort = useUrlSort();

  const filterKeys = useMemo(
    () => columns.filter((c) => c.filter || c.filterType).map((c) => c.name),
    [columns]
  );

  const filters = useUrlFilters({
    keys: filterKeys,
  });

  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useUrlControlledReactQueryTableState({
    columns,
    fetch,
    getQueryKey,
  });

  const records = data?.records ?? [];
  const currentPage = data?.current_page ?? 1;
  const totalRecords = data?.total_records ?? 0;
  const totalPages = data?.total_pages ?? 0;

  const pagination = useUrlPagination({ totalPages });

  useEffect(() => {
    if (!prefetchNextPage || isLoading || isFetching) {
      return;
    }

    if (currentPage >= totalPages) {
      return;
    }

    const nextPageQueryKey = getQueryKey({
      filters: filters.filters,
      page: currentPage + 1,
      pageSize: pagination.pageSize,
      sortColumn: sort.column,
      sortDirection: sort.direction,
    });

    queryClient.prefetchQuery(nextPageQueryKey as any, ({ queryKey }) =>
      fetch(...queryKey)
    );
  }, [
    currentPage,
    fetch,
    filters.filters,
    getQueryKey,
    isFetching,
    isLoading,
    pagination.pageSize,
    prefetchNextPage,
    queryClient,
    sort.column,
    sort.direction,
    totalPages,
  ]);

  return (
    <Table
      {...props}
      columns={columns}
      data={records}
      loading={isLoading}
      fetching={isFetching}
      // pagination
      pageSize={pagination.pageSize}
      currentPage={currentPage}
      totalPages={totalPages}
      totalRecords={totalRecords}
      onPageChange={pagination.setPage}
      // sorting
      sortColumn={sort.column}
      sortDirection={sort.direction}
      onSortChange={sort.onSortChange}
      // filtering
      filters={filters.filters}
      onFiltersChange={filters.setAllFilters}
      showClearFiltersButton
      // links
      getRowHref={getRowHref}
    />
  );
}
