import { useContext } from "react";
import { View } from "react-native";
import { compact } from "lodash-es";

import Graph from "@/common/Graph";
import Context, { useUser } from "@/layout/Context";

import AddedAccessCodes from "@/common/graphs/AddedAccessCodes";

export default function DevicesInstalledByHour() {
  const { isDesktop } = useContext(Context);
  const { permissions } = useUser();
  if (!(permissions.manage_system_health && isDesktop)) {
    return null;
  }

  return (
    <Graph
      path="/reports/unapplied-access-codes/last-day"
      dataKey="accessCodesLastDay"
      renderProps={(raw) => {
        const added = compact(raw.filter((r) => r.was_added === "added"));
        const unadded = compact(raw.filter((r) => r.was_added !== "added"));
        return (
          <View>
            <AddedAccessCodes added={added} unadded={unadded} />
          </View>
        );
      }}
    />
  );
}
