import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

import { Button, Link, Typography } from "@smartrent/ui";
import { IconProps, Launch } from "@smartrent/icons";
import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { useCentralStation } from "@/common/NineSquareButton/hooks";
import { cmwRedirectBuilder } from "@/utils/links";
import { Card } from "@/common/card/index";
import { useUser } from "@/layout/Context";

export interface SmartLinkProps {
  title: string;
  href?: string;
  organizationUrl?: string;
  onClick?: () => void;
  /** Override the button icon */
  icon?: React.ComponentType<React.PropsWithChildren<IconProps>>;
}

export interface SRManagerQuickActionProps {
  smartLinks: SmartLinkProps[];
}

export interface SmartLinkQuickLaunchProps {
  title: string;
  href: string;
  organizationUrl: string;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
}

export interface QuickActionProps {
  label?: string;
  smartLinks: SmartLinkProps[];
}

const emptyOnClick = () =>
  console.log("No onClick handler provided for SmartLink");

export const QuickActions: React.FC<
  React.PropsWithChildren<QuickActionProps>
> = ({ label = "Quick Actions", smartLinks }) => {
  const { small, medium } = useWindowDimensionsQuery();

  const { apps, generateAppRedirectLink, redirectLinkLoading } =
    useCentralStation();

  const CMW = "community_manager";

  const connectedToCMWViaCentralStation = !!apps?.find(
    (app) => app.name === CMW && !!app.user_id
  );

  const csAutoLogin = useCallback(
    async (smartLink: any) => {
      if (!smartLink.href) {
        return smartLink.onClick ? smartLink.onClick() : emptyOnClick();
      }

      // Note: if the user's CMW account is linked, this is a redirect path
      // If the account is not linked, this is a fully qualified URL
      const redirectUrl = cmwRedirectBuilder(
        smartLink.href,
        smartLink.organizationUrl,
        { withBaseUrl: !connectedToCMWViaCentralStation }
      );

      if (connectedToCMWViaCentralStation) {
        await generateAppRedirectLink(CMW, {
          redirectTo: redirectUrl,
        });
      } else {
        window.open(redirectUrl, "_blank");
      }
    },
    [connectedToCMWViaCentralStation, generateAppRedirectLink]
  );

  return (
    <View>
      <Card style={small || medium ? styles.smallCard : styles.card}>
        <Typography type="title3">{label}</Typography>
        <View style={small || medium ? styles.flexCol : styles.flexRow}>
          {smartLinks.map((smartLink) => (
            <Button
              disabled={!!redirectLinkLoading}
              onPress={() => csAutoLogin(smartLink)}
              iconLeft={smartLink.icon || Launch}
              key={smartLink.title}
              style={small || medium ? {} : styles.buttonMargin}
            >
              {smartLink.title}
            </Button>
          ))}
        </View>
      </Card>
    </View>
  );
};

export const ManagerQuickActions: React.FC<
  React.PropsWithChildren<SRManagerQuickActionProps>
> = ({ smartLinks }) => {
  const { permissions } = useUser();
  return permissions.core_platform_access ? (
    <QuickActions
      label="SmartRent Manager Quick Actions"
      smartLinks={smartLinks}
    />
  ) : null;
};

export const SmartLinkQuickLaunch: React.FC<
  React.PropsWithChildren<SmartLinkQuickLaunchProps>
> = ({ title, href, organizationUrl, style, contentStyle }) => {
  return (
    <View style={style}>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href={cmwRedirectBuilder(href, organizationUrl)}
        key={title}
      >
        <Button
          style={[styles.buttonStyle, contentStyle]}
          iconLeft={Launch}
          variation="plain"
          contentStyle={styles.buttonStyle}
        >
          {title}
        </Button>
      </Link>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: 12,
    paddingHorizontal: 16,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    marginBottom: 16,
    flexGrow: 1,
  },
  smallCard: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: 16,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    marginBottom: 16,
    gap: 16,
  },
  flexRow: { flexDirection: "row" },
  flexCol: { flexDirection: "column", gap: 8 },
  buttonMargin: { marginLeft: 16 },
  buttonStyle: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});
