import { View, StyleSheet } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { EM_DASH } from "@/utils/chars";

import { RingDoorbellDevice } from "@/types";

export const RingDoorbell = ({ device }: { device: RingDoorbellDevice }) => {
  const { colors } = useTheme();

  return (
    <View style={styles.box}>
      <View
        style={[
          styles.header,
          {
            backgroundColor: colors.gray050,
            borderBottomColor: colors.gray100,
          },
        ]}
      >
        <Typography type="title4Bold">Ring Doorbell</Typography>
        <Typography color="textSecondary">Ring</Typography>
      </View>
      <View style={styles.content}>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">MAC Address:</Typography>
          <Typography type="bodySemibold">
            {device?.mac_address
              ? device.mac_address
                  ?.toUpperCase()
                  .replace(/[^\d|A-Z]/g, "")
                  .match(/.{1,2}/g)
                  ?.join(":")
              : EM_DASH}
          </Typography>
        </View>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">Inserted At:</Typography>
          <Typography type="bodySemibold">
            {Helpers.formatDate(device.inserted_at)}
          </Typography>
        </View>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">Updated At:</Typography>
          <Typography type="bodySemibold">
            {Helpers.formatDate(device.updated_at)}
          </Typography>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    boxShadow: "rgba(26, 31, 39, 0.2) 0px 4px 8px",
    backgroundColor: "#FFFFFF",
    height: "auto",
    margin: 8,
    minWidth: 320,
  },
  header: {
    padding: 16,
    borderBottomWidth: 1,
    paddingBottom: 16,
  },
  rowItem: { marginBottom: 16 },
  content: {
    padding: 16,
  },
});
