import { useInterval } from "@smartrent/hooks";
import { useEffect, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { ScopeOfWorkStatus } from "@/types";

const FIVE_SECONDS = 5000;

// Example
// max count = 12
// 1st half = linear
// 2nd half = exponential
//
// delay intervals should be
// ....[5, 5, 5, 5, 5, 10, 20, 40, 80, 160, 320, 640] // stops after 640s

export const useSowSyncingStatusHook = ({
  status = null,
  queryKey = null,
  minDelay = FIVE_SECONDS,
  exponentDelay = 2,
  invalidateCountMax = 12,
}) => {
  const queryClient = useQueryClient();
  const [delay, setDelay] = useState<number>(minDelay);
  const [invalidateCount, setInvalidateCount] = useState(0);

  const isActive = useMemo(
    () =>
      status === ScopeOfWorkStatus.Syncing &&
      queryKey !== null &&
      // don't invalidate past 2 minutes, most likely means something is wrong
      invalidateCount < invalidateCountMax,
    [invalidateCount, invalidateCountMax, queryKey, status]
  );

  // reset the delay if interval becomes inactive
  useEffect(() => {
    if (!isActive && invalidateCount > 0) {
      setDelay(minDelay);
      setInvalidateCount(0);
    }
  }, [delay, minDelay, isActive, invalidateCount]);

  // invalidate the SOW query & increase the delay time exponentially
  useInterval(
    () => {
      queryClient.invalidateQueries(queryKey);
      // increment counter
      setInvalidateCount(invalidateCount + 1);
      // start exponential backoff halfway through
      if (invalidateCount > Math.floor(invalidateCountMax / 2)) {
        setDelay(FIVE_SECONDS ** exponentDelay);
      }
    },
    isActive ? delay : null
  );

  return { delay };
};
