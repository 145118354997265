import * as React from "react";
import { View, StyleSheet } from "react-native";
import { QueryObserverResult } from "@tanstack/react-query";

import { DrawerLoader, Typography, useTheme } from "@smartrent/ui";

import { useUser } from "@/layout/Context";
import { SignHeader } from "@/common/parking/drawer/SignHeader";
import { SignQRCode } from "@/common/parking/SignQRCode";
import { useSignQuery } from "@/api";
import { SignStatusPill } from "@/common/parking/SignStatusPill";
import { EnableSignAction } from "@/common/parking/EnableSignAction";
import { ArchiveSignAction } from "@/common/parking/ArchiveSignAction";
import { EditSignAction } from "@/common/parking/EditSignAction";
import { SignSpaces } from "@/common/parking/drawer/SignSpaces";
import { Sign } from "@/types";

interface SignDetailProps {
  signId: string;
  propertyId: string;
}

export const SignDetail: React.FC<React.PropsWithChildren<SignDetailProps>> = ({
  signId,
  propertyId,
}) => {
  const { permissions } = useUser();
  const { colors } = useTheme();

  const { data: sign, isLoading }: QueryObserverResult<Sign, Error> =
    useSignQuery(signId, propertyId);

  return (
    <>
      <SignHeader signId={signId} propertyId={propertyId} />
      <View
        style={[styles.headerContainer, { borderBottomColor: colors.gray200 }]}
      >
        <Typography type="title3" style={styles.header}>
          Sign Detail
        </Typography>
        {permissions.manage_parking ? (
          sign?.active ? (
            <View style={styles.buttonContainer}>
              <EditSignAction
                variation="plain"
                signId={signId}
                propertyId={propertyId}
              />
              <ArchiveSignAction
                variation="plain"
                signId={signId}
                propertyId={propertyId}
              />
            </View>
          ) : (
            <EnableSignAction
              signId={signId}
              propertyId={propertyId}
              variation="plain"
            />
          )
        ) : null}
      </View>
      {isLoading && !sign ? (
        <DrawerLoader />
      ) : (
        <View style={styles.root}>
          <View
            style={[styles.container, { borderBottomColor: colors.gray200 }]}
          >
            <Typography
              type="title3"
              style={[styles.header, { color: colors.gray600 }]}
            >
              Location:
            </Typography>
            <View style={styles.content}>
              <Typography type="title4">{sign?.location}</Typography>
            </View>
          </View>
          <View
            style={[styles.container, { borderBottomColor: colors.gray200 }]}
          >
            <Typography
              type="title3"
              style={[styles.header, { color: colors.gray600 }]}
            >
              Status:
            </Typography>
            <View style={styles.content}>
              <SignStatusPill active={sign?.active} />
            </View>
          </View>
          <View
            style={[styles.container, { borderBottomColor: colors.gray200 }]}
          >
            <Typography
              type="title3"
              style={[styles.header, { color: colors.gray600 }]}
            >
              QR Code:
            </Typography>
            <View style={[styles.content, styles.qrCode]}>
              {sign ? <SignQRCode sign={sign} qrCodeSize={128} /> : null}
            </View>
          </View>
          <Typography
            type="title3"
            style={[styles.header, { color: colors.gray600 }]}
          >
            Spaces:
          </Typography>
          <SignSpaces propertyId={propertyId} signId={signId} />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    paddingHorizontal: 16,
    marginTop: 8,
    borderBottomWidth: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: { marginVertical: 16 },
  container: {
    borderBottomWidth: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  content: {
    flex: 1,
    marginRight: 16,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    width: 128,
  },
  root: { marginHorizontal: 16, flex: 1, marginBottom: 16 },
  qrCode: {
    marginVertical: 16,
  },
});
