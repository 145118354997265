import { get } from "lodash-es";

import { useApi } from "@/lib/hooks";

interface ProspectVerificationProps {
  prospectId: string;
  history: any;
}

export default function ProspectVerification({
  prospectId,
  history,
}: ProspectVerificationProps) {
  const { response, error } = useApi({
    url: `/verifications/prospect/${prospectId}`,
    trigger: [prospectId],
  });

  if (error && get(error, "response.status") !== 200) {
    history.push("/tours");
  }

  // redirect to the prospect verification page
  if (response?.data?.verificationId) {
    history.push(`/verifications/${response.data.verificationId}`);
  }

  return null;
}
