import { useCallback, useState } from "react";
import { useFormikContext, useField } from "formik";
import { Button, NumberInputField } from "@smartrent/ui";
import type { NumberInputFieldProps, TextInputBaseProps } from "@smartrent/ui";

type TemperatureScale = "f" | "c";

export interface FormikTemperatureFieldProps
  extends Omit<NumberInputFieldProps, "EndAdornment"> {
  name: string;
  label: string;
  scale: TemperatureScale;
  min?: number;
  max?: number;
  textInputProps?: Omit<
    TextInputBaseProps,
    "onBlur" | "onChangeText" | "onChange" | "value" | "inputMode"
  >;
}

function convertValue(
  v: number | undefined | null,
  valueScale: TemperatureScale,
  displayScale: TemperatureScale
): number | undefined {
  if (typeof v === "undefined" || v === null) {
    return undefined;
  }

  if (valueScale === displayScale) {
    return v;
  }

  if (displayScale === "c") {
    return Math.round((v - 32) * (5 / 9) * 2) / 2;
  }

  return Math.round(v * 1.8 + 32);
}

function formatValue(
  v: number | undefined | null,
  valueScale: TemperatureScale,
  displayScale: TemperatureScale
): string {
  v = convertValue(v, valueScale, displayScale);
  return `${v}°${displayScale.toUpperCase()}`;
}

export const FormikTemperatureField = ({
  name,
  label,
  required,
  assistiveText,
  scale,
  min,
  max,
  disabled,
  textInputProps,
  ...props
}: FormikTemperatureFieldProps) => {
  if (scale !== "f" && scale !== "c") {
    scale = "c";
  }

  const step = scale === "c" ? 0.5 : 1;

  const [displayScale, setDisplayScale] = useState<TemperatureScale>("f");

  const { isSubmitting } = useFormikContext();

  const validate = useCallback(
    (v: any) => {
      if (typeof min === "number" && v < min) {
        return `Must be at least ${formatValue(min, scale, displayScale)}`;
      } else if (typeof max === "number" && v > max) {
        return `Must be less than or equal to ${formatValue(
          max,
          scale,
          displayScale
        )}`;
      } else {
        return undefined;
      }
    },
    [displayScale, max, min, scale]
  );

  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
    name,
    validate,
  });

  const toggleSelectedScale = useCallback(() => {
    const newDisplayScale = displayScale === "c" ? "f" : "c";
    setDisplayScale(newDisplayScale);
    setValue(value);
  }, [displayScale, setValue, value]);

  // On blur, set the field as touched and round the value to the nearest increment of step.
  // We do this on blur instead of on change because doing it in onChange is super annoying.
  const onBlur = useCallback(() => {
    if ((value / step) % (1 / step) !== 0) {
      setValue(Math.round(value / step) * step);
    }
    if (!touched) {
      setTouched(true);
    }
  }, [setTouched, setValue, step, touched, value]);

  const onChangeText = useCallback(
    (v: string | number | undefined) => {
      if (typeof v === "undefined" || v === null) {
        return null;
      }

      if (typeof v === "string") {
        v = parseFloat(v);
      }

      // Convert from the display scale to the internal scale
      v = convertValue(v, displayScale, scale);

      setValue(v);
    },

    [displayScale, scale, setValue]
  );

  return (
    <NumberInputField
      {...props}
      label={label}
      required={required}
      decimal={displayScale === "c" ? true : false}
      assistiveText={assistiveText}
      error={touched && error ? error : undefined}
      disabled={isSubmitting || disabled}
      textInputProps={{
        ...textInputProps,
        value: convertValue(value, scale, displayScale),
        onBlur,
        onChangeText,
      }}
      EndAdornment={() => (
        <Button
          onPress={toggleSelectedScale}
          variation="plain"
          color="primary"
          size="x-small"
        >
          {`°${displayScale.toUpperCase()}`}
        </Button>
      )}
    />
  );
};
