import { useMemo, useCallback } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Link, Typography, useTheme } from "@smartrent/ui";
import { ArrowLeft } from "@smartrent/icons";

export interface LinkItem {
  label: string;
  href?: string;
  onClick?: () => void;
}

export interface LinkTreeProps {
  links: LinkItem[];
  showCurrent?: boolean;
  // Default action is just window.history.back(), but you can override with custom functionality.
  backAction?: () => void;
}

export const LinkTree: React.FC<React.PropsWithChildren<LinkTreeProps>> = ({
  links,
  showCurrent = true,
  backAction,
}) => {
  const { colors } = useTheme();
  const lastLink = useMemo(() => links[links.length - 1], [links]);

  const backArrowClickAction = useCallback(() => {
    if (!backAction) {
      window.history.back();
    } else {
      backAction();
    }
  }, [backAction]);

  const linkTree = useMemo(
    () =>
      links
        .filter((link) => link.href || link.onClick)
        .map((link, index) => (
          <Link
            key={index}
            href={link.href}
            onPress={link.onClick}
            style={styles.text}
          >
            {link.label}
          </Link>
        )),
    [links]
  );

  return (
    <View style={[styles.row, styles.container]}>
      <Link
        onPress={backArrowClickAction}
        style={{ color: colors.textSecondary }}
      >
        <ArrowLeft size={24} style={styles.backLink} />
      </Link>

      <View style={[styles.links, styles.row]}>
        {linkTree.length
          ? linkTree.reduce((a, b) => (
              <>
                {a}
                <Typography color="textSecondary" style={styles.text}>
                  {" "}
                  /{" "}
                </Typography>
                {b}
              </>
            ))
          : null}

        {/* Shows the last link item as a non-clickable item, representing the current page. */}
        {showCurrent ? (
          <>
            {links.length > 1 ? (
              <Typography color="textSecondary" style={styles.text}>
                {" "}
                /{" "}
              </Typography>
            ) : null}

            <Typography color="textSecondary" style={styles.text}>
              {lastLink.label}
            </Typography>
          </>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 24,
  },
  backLink: {
    marginTop: 4,
  },
  row: { flexDirection: "row", alignItems: "center" },
  links: { marginLeft: 16 },
  text: { fontWeight: "bold" },
});
