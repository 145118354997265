import { useCallback } from "react";
import { View, StyleSheet } from "react-native";

import { ActivityIndicator, Button } from "@smartrent/ui";

import { useStuckAccessCodesQuery, useRetryStuckAccessCodes } from "@/api";

export const RetryStuckAccessCodes = ({ unitId }) => {
  const { data, isLoading } = useStuckAccessCodesQuery({ unitId });
  const [retryStuckCodes, { isLoading: isSubmitting }] =
    useRetryStuckAccessCodes();

  const handleSubmit = useCallback(async () => {
    await retryStuckCodes({ unitId });
  }, [retryStuckCodes, unitId]);

  return (
    <View>
      <View style={styles.buttonContainer}>
        {!isLoading && data?.stuck_codes ? (
          <Button
            onPress={handleSubmit}
            variation="outlined"
            style={styles.disabledButton}
            disabled={isSubmitting}
          >
            {isSubmitting ? <ActivityIndicator /> : "Retry Stuck Codes"}
          </Button>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 8,
  },
  disabledButton: { marginLeft: 8, width: 186.7, height: 40 },
});
