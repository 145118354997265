import { useMemo } from "react";
import * as React from "react";

import { useMapQuery } from "@/api";
import { PanelKeyValueList } from "@/common/panel";
import { EM_DASH } from "@/utils/chars";

interface MapRowProps {
  mapId: string | null;
  isLoading: boolean;
}

export const MapRow: React.FC<React.PropsWithChildren<MapRowProps>> = ({
  isLoading,
  mapId,
}) => {
  const { data: map, isLoading: loadingMap } = useMapQuery(
    { mapId },
    { enabled: !!mapId }
  );

  const loading = isLoading || loadingMap;

  const items = useMemo(
    () => [{ label: "Map", value: map?.name ?? EM_DASH }],
    [map]
  );

  return <PanelKeyValueList loading={loading} items={items} />;
};
