import { createAxiosMutation } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";

export const useHubAccountMutation = createAxiosMutation(
  async ({ serial, params }) =>
    instance.patch(`/hubs/${serial}/hub-account`, params),
  {
    successToast: () => ({
      message: "Detaching hub!",
    }),
    errorToast: () => ({
      message: "Unable to detach hub.",
    }),
  }
);
