import { useContext, useState, useMemo, useCallback } from "react";
import * as React from "react";
import { FlatList, View, StyleSheet } from "react-native";
import { useHistory } from "react-router-dom";

import {
  Button,
  FlatListItemSeparator,
  Typography,
  useTheme,
} from "@smartrent/ui";

import Context, { useUser } from "@/layout/Context";
import { instance } from "@/lib/hooks";
import { useParkingPropertyQuery } from "@/api";
import { LoadingDots } from "@/common/LoadingDots";
import Card from "@/common/Card";

import FormikModal from "@/common/formik/Modal";

import { ParkingProperty } from "@/types";

import { ParkingModules } from "@/pages/group/cards/parking/ParkingModules";
import { ListItemLabelValueCompact } from "@/common/ListItemLabelValueCompact";

import { useGroup } from "../GroupContext";

import { ParkingHeader, parkingHeaderActions } from "./parking/ParkingHeader";
import { SensorImport } from "./parking/SensorImport";

const emptyParkingGroup: Partial<ParkingProperty> = {
  total_spaces: 0,
  assigned_spaces: 0,
  available_spaces: 0,
  pooled_spaces: 0,
  decal_module_enabled: false,
  guest_parking_module_enabled: false,
  guest_parking_payments_module_enabled: false,
  total_sections: 0,
  total_areas: 0,
};

export const GroupParkingCard: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const context = useContext(Context);
  const { colors } = useTheme();
  const { group } = useGroup();
  const { permissions } = useUser();
  const [showModal, setShowModal] = useState(false);

  const [parkingEnabled, setParkingEnabled] = useState(group.parking_enabled);
  const history = useHistory();

  const { setToast } = context;

  const { data, isLoading, refetch } = useParkingPropertyQuery(
    group.uuid,
    parkingEnabled
  );

  const parkingGroup = useMemo(() => data ?? emptyParkingGroup, [data]);

  const enableParking = useCallback(async () => {
    try {
      await instance.post(`/groups/${group.id}/parking/enable`);

      refetch();

      setParkingEnabled(true);
      setToast({
        type: "success",
        title: "Success!",
        message: "Parking module successfully enabled, please refresh the page",
      });
      setShowModal(false);
    } catch (error) {
      refetch();

      console.error(error);
      setToast({
        type: "error",
        title: "Error!",
        message: "Something went wrong. Please contact engineering.",
      });
      setShowModal(false);
    }
  }, [group.id, refetch, setToast]);

  const handleViewParking = useCallback(
    () => history.push(`/groups/${group.id}/parking`),
    [history, group]
  );

  const {
    total_spaces,
    assigned_spaces,
    available_spaces,
    pooled_spaces,
    total_sections,
    total_areas,
  } = parkingGroup;

  return (
    <>
      <Card
        heading={<ParkingHeader />}
        actions={
          parkingEnabled
            ? parkingHeaderActions({
                group,
                context,
                permissions,
                parkingGroup,
                parkingEnabled,
                setParkingEnabled,
              })
            : null
        }
      >
        <View>
          {parkingEnabled ? (
            <>
              {isLoading ? (
                <View style={styles.loading}>
                  <LoadingDots />
                </View>
              ) : null}
              {!isLoading ? (
                <>
                  <View
                    style={[
                      styles.sectionTitle,
                      { borderBottomColor: colors.gray100 },
                    ]}
                  >
                    <Typography type="title5">OVERVIEW</Typography>
                  </View>

                  <FlatList
                    data={[
                      { key: "Total Spaces", value: total_spaces },
                      { key: "Assigned Spaces", value: assigned_spaces },
                      { key: "Available Spaces", value: available_spaces },
                      { key: "Pooled Spaces", value: pooled_spaces },
                      { key: "Total Sections", value: total_sections },
                      { key: "Total Areas", value: total_areas },
                    ]}
                    keyExtractor={({ key }) => key}
                    renderItem={({ item }) => (
                      <ListItemLabelValueCompact
                        label={item.key}
                        value={item.value}
                        justifySpaceBetween
                      />
                    )}
                    ItemSeparatorComponent={FlatListItemSeparator}
                  />

                  <ParkingModules parkingGroup={parkingGroup} />
                  {permissions.manage_parking ? <SensorImport /> : null}
                  <Button
                    variation="plain"
                    onPress={handleViewParking}
                    disabled={!group}
                  >
                    Go to parking
                  </Button>
                </>
              ) : null}
            </>
          ) : (
            <View>
              <Typography type="caption">
                Parking Module is currently disabled
              </Typography>
              {permissions.manage_parking ? (
                <View>
                  <Button
                    onPress={() => setShowModal(true)}
                    style={styles.mt16}
                    disabled={!group}
                  >
                    Enable Parking
                  </Button>
                </View>
              ) : null}
            </View>
          )}
        </View>

        {showModal ? (
          <FormikModal
            title="Enable Parking Module"
            toggleModal={() => setShowModal(null)}
            onSubmit={() => enableParking()}
            confirmText="Enable"
          >
            Are you sure you'd like to enable the parking module for this group?
          </FormikModal>
        ) : null}
      </Card>
    </>
  );
};

const styles = StyleSheet.create({
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sectionTitle: {
    borderBottomWidth: 1,
    paddingBottom: 8,
    marginBottom: 8,
    marginTop: 16,
  },
  mt16: { marginTop: 16 },
});
