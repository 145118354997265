import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Button,
  FormikSelectField,
  FormikTextInputField,
  FormikTextareaField,
  FormikSubmit,
  Dialog,
  DialogContent,
  DialogTitle,
  Banner,
} from "@smartrent/ui";

import { useFeedbackCollectorMutation } from "@/api";

import { feedbackComponents } from "@/utils";

interface FeedbackModalProps {
  visible: boolean;
  onClose: () => void;
}

const validationSchema = Yup.object({
  title: Yup.string().trim().min(1).required("Please enter a title."),
  description: Yup.string().trim().min(1).required("Please add a description."),
  label: Yup.string().matches(/^\S*$/g, "This field cannot contain spaces"),
});

export const FeedbackModal = ({ visible, onClose }: FeedbackModalProps) => {
  const [submitFeedbackCollector, { reset, isError }] =
    useFeedbackCollectorMutation();

  useEffect(() => {
    reset();
  }, [onClose, visible]);

  return (
    <Dialog visible={visible} onClose={onClose} titleID="Feedback">
      <DialogContent>
        <View style={styles.container}>
          <DialogTitle type="title2">Submit Feedback</DialogTitle>
          <Formik
            initialValues={{
              title: "",
              description: "",
              issue_type: "Story",
              component: "",
              label: "",
            }}
            onSubmit={async (values, actions) => {
              await submitFeedbackCollector(values);
              onClose();
            }}
            validationSchema={validationSchema}
          >
            <View style={styles.flexOne}>
              <View style={styles.rowWrapper}>
                <FormikSelectField
                  label="Issue Type"
                  name="issue_type"
                  options={[
                    {
                      label: "Feature Request",
                      value: "Story",
                    },
                    {
                      label: "Bug",
                      value: "Bug",
                    },
                  ]}
                  required
                />
              </View>
              <View style={styles.rowWrapper}>
                <FormikTextInputField
                  name="title"
                  label="Issue Title"
                  required
                />
              </View>
              <View style={styles.rowWrapper}>
                <FormikTextareaField
                  name="description"
                  label="Description"
                  required
                />
              </View>
              <View style={styles.rowWrapper}>
                <FormikSelectField
                  label="Component"
                  name="component"
                  options={feedbackComponents}
                  required
                />
              </View>
              <View style={styles.rowWrapper}>
                <FormikTextInputField label="Client" name="label" />
              </View>
              {isError ? (
                <Banner
                  title="Something went wrong submitting your feeback. Please try again."
                  color="error"
                />
              ) : null}
              <View style={styles.buttons}>
                <Button variation="outlined" onPress={onClose}>
                  Cancel
                </Button>
                <FormikSubmit
                  style={styles.submitMargin}
                  label="Submit"
                  submittingLabel="Submitting..."
                />
              </View>
            </View>
          </Formik>
        </View>
      </DialogContent>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  container: { padding: 32 },
  rowWrapper: { marginBottom: 16 },
  flexOne: { flex: 1, minWidth: 350, width: "50vw", padding: 16 },
  buttons: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
    marginTop: 32,
  },
  submitMargin: { marginLeft: 16 },
});
