import { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import { sum, filter, find, debounce } from "lodash-es";
import { Link } from "react-router-dom";

import Context from "@/layout/Context";

import Table from "@/common/Table";
import Helpers from "@/lib/helpers";
import { useInterval } from "@/lib/hooks";

import Filters from "./installs/Filters";

type $Unit = {
  residents_not_moved_in_count: number;
  missing_resident_access_codes: any;
  device_or_hub_problems: any;
  hub_online: boolean;
  battery_powered_tstats: number;
  incomplete_installs: number;
  devices_offline: number;
  warnings: any;
  marketing_name: string;
  installers: any;
  id: number;
};
type $Group = {
  id: number;
  group_name: string;
  org_name: string;
  city: string;
  state: string;
  hubs_installed: string;
  units: $Unit[];
  organization_id: string;
};
type $DeviceReport = {
  group_name?: string;
  devices_installed?: any;
  deleted_devices?: any;
};
type $InstallResponse = {
  units: $Group[];
  device_reports: $DeviceReport[];
};

const Installs = () => {
  const { user, setPageLoading, history, location } = useContext(Context);
  const [records, setRecords] = useState<$Group[]>([]);
  const [deviceReports, setDeviceReports] = useState<$DeviceReport[]>([]);
  const [isAutoRefreshing, setIsAutoRefreshing] = useState(true);
  const [refetchInterval, setRefetchInterval] = useState(60000);

  useEffect(() => {
    Helpers.fetch({
      path: "/api/reports/installs/new",
      query: Helpers.qsFromLocation(location),
    }).then((res: $InstallResponse) => {
      setRecords(res.units);
      setDeviceReports(res.device_reports);
    });
  }, [location]);

  useInterval(() => {
    if (isAutoRefreshing && refetchInterval) {
      Helpers.fetch({
        path: "/api/reports/installs/new",
        query: Helpers.qsFromLocation(location),
      }).then((res: $InstallResponse) => {
        setRecords(res.units);
        setDeviceReports(res.device_reports);
        setPageLoading(false);
      });
    }
  }, refetchInterval);

  const handleIntervalChange = debounce((newInterval) => {
    if (newInterval !== refetchInterval) {
      setRefetchInterval(newInterval);
    }
  }, 1000);

  const hubsOnline = sum(
    records.map((group) => {
      return filter(group.units, { hub_online: true }).length;
    })
  );

  const hubsOffline = sum(
    records.map((group) => {
      return filter(group.units, { hub_online: false }).length;
    })
  );

  return (
    <>
      <Filters
        setIsAutoRefreshing={setIsAutoRefreshing}
        isAutoRefreshing={isAutoRefreshing}
        handleIntervalChange={handleIntervalChange}
      />
      <Table
        columns={[
          {
            label: "Org",
            className: "grid__sm--12 grid__md--2",
            render: (r: $Group) => {
              const device_report = find(deviceReports, {
                group_name: r.group_name,
              });
              return (
                <div>
                  <Link
                    className="a"
                    to={`/organizations/${r.organization_id}`}
                  >
                    {r.org_name}
                  </Link>{" "}
                  /{" "}
                  <Link className="a" to={`/groups/${r.id}`}>
                    {r.group_name}
                  </Link>
                  <div className="u-font12 u-text-gray-600">
                    {r.city}, {r.state}
                  </div>
                  <div className="h6 u-mtop8">
                    - {r.hubs_installed} installations
                  </div>
                  {device_report && (
                    <div className="h6">
                      <div>
                        - {device_report.devices_installed} devices installed
                      </div>

                      {!!Number(device_report.deleted_devices) && (
                        <div
                          className="u-font12 u-mleft4"
                          title="(usually exclusion then re-inclusion)"
                        >
                          - {device_report.deleted_devices} device exclusions
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            label: `Happy Little Units (${hubsOnline} online)`,
            className: "grid__sm--12 grid__md--4",
            render: (r: $Group) => (
              <div className="u-flex u-flex-wrap" style={{ maxWidth: "500px" }}>
                {r.units.map((unit: $Unit) => {
                  const icon = "💚";
                  let title;
                  const textColor = "u-text-symbolic-success";
                  const borderColor = "#4caf50";

                  if (
                    unit.residents_not_moved_in_count ||
                    unit.missing_resident_access_codes ||
                    unit.device_or_hub_problems ||
                    !unit.hub_online ||
                    unit.incomplete_installs ||
                    unit.devices_offline ||
                    unit.battery_powered_tstats ||
                    unit.warnings
                  ) {
                    return null;
                  }

                  const canViewUnits = Helpers.auth.can(user, "units");
                  const child = (
                    <div
                      className={classNames("u-m4", textColor)}
                      style={{
                        border: `1px solid ${borderColor}`,
                        padding: "4px",
                        backgroundColor: "rgb(250, 250, 250)",
                        boxShadow: "1px 1px 1px #ccc",
                      }}
                      title={title}
                    >
                      <div className="u-flex u-flex-justify-start u-flex-align-items-center">
                        <span className="u-mright8">
                          {unit.marketing_name}{" "}
                        </span>
                        <span>{icon}</span>
                      </div>
                      <div className="u-font10">
                        {unit.installers.join(", ")}
                      </div>
                    </div>
                  );

                  if (!canViewUnits) {
                    return child;
                  }

                  return (
                    <Link
                      key={unit.id}
                      to={`/units/${unit.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classNames("u-link-clean", textColor)}
                    >
                      {child}
                    </Link>
                  );
                })}
              </div>
            ),
          },
          {
            label: `Sad Little Units (${hubsOffline} offline)`,
            className: "grid__sm--12 grid__md--5",
            render: (r: $Group) => (
              <div style={{ width: "fit-content" }}>
                {r.units.map((unit) => {
                  let borderColor = "";

                  const problems = [];

                  if (unit.residents_not_moved_in_count) {
                    problems.push({
                      icon: "⏱",
                      title: `${unit.residents_not_moved_in_count} resident${
                        unit.residents_not_moved_in_count > 1 ? "s" : ""
                      } not moved in`,
                      textColor: "u-text-symbolic-error",
                    });

                    borderColor = borderColor || "#840436";
                  }

                  if (unit.battery_powered_tstats) {
                    problems.push({
                      icon: "🌡️",
                      title: "battery powered tstat(s)",
                      textColor: "u-text-symbolic-error",
                    });

                    borderColor = borderColor || "#840436";
                  }

                  if (unit.missing_resident_access_codes) {
                    problems.push({
                      icon: "😱",
                      title: `Missing ${
                        unit.missing_resident_access_codes
                      } resident access code${
                        unit.missing_resident_access_codes > 1 ? "s" : ""
                      }`,
                      textColor: "u-text-symbolic-error",
                    });

                    borderColor = borderColor || "#840436";
                  }

                  if (!unit.hub_online) {
                    problems.push({
                      icon: "",
                      title: `Hub offline`,
                      textColor: "u-text-symbolic-error",
                    });

                    borderColor = borderColor || "#c2185b";
                  }

                  if (unit.incomplete_installs) {
                    problems.push({
                      icon: "🔧",
                      title: `${unit.incomplete_installs} device${
                        Number(unit.incomplete_installs) !== 1 ? "s" : ""
                      } with incomplete install`,
                      textColor: "u-text-symbolic-error",
                    });

                    borderColor = borderColor || "#840436";
                  }

                  if (unit.devices_offline) {
                    problems.push({
                      icon: "",
                      title: `${unit.devices_offline} offline device${
                        unit.devices_offline > 1 ? "s" : ""
                      }`,
                      textColor: "u-text-symbolic-error",
                    });

                    borderColor = borderColor || "#c2185b";
                  }

                  if (unit.warnings) {
                    problems.push({
                      icon: "",
                      title: `${unit.warnings} device warning${
                        unit.warnings > 1 ? "s" : ""
                      }`,
                      textColor: "u-text-symbolic-warning",
                    });

                    borderColor = borderColor || "#fd7037";
                  }

                  if (!problems.length) {
                    return null;
                  }

                  const canViewUnits = Helpers.auth.can(user, "units");
                  const child = (
                    <div key={unit.id}>
                      <div
                        className="u-mright8 u-pleft8 u-pright8 u-ptop4 u-pbottom4"
                        style={{
                          border: `1px solid ${borderColor}`,
                          backgroundColor: "rgb(250, 250, 250)",
                          boxShadow: "1px 1px 1px #ccc",
                        }}
                      >
                        <div
                          className="u-mbottom8 u-sans-bold"
                          style={{ color: borderColor }}
                        >
                          {unit.marketing_name}{" "}
                        </div>
                        {problems.map(({ icon, title, textColor }, idx) => {
                          return (
                            <div
                              key={idx}
                              className={classNames("u-mbottom4", textColor)}
                            >
                              {title} <span className="u-font10">{icon}</span>
                            </div>
                          );
                        })}
                        <div className="u-font10 u-mtop8 u-text-gray-400">
                          {unit.installers.join(", ")}
                        </div>
                      </div>
                    </div>
                  );

                  if (!canViewUnits) {
                    return (
                      <div key={unit.id} className="u-m4 u-line18">
                        {child}
                      </div>
                    );
                  }

                  return (
                    <div className="u-m4 u-line18" key={unit.id}>
                      <Link
                        to={`/units/${unit.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classNames("u-link-clean")}
                      >
                        {child}
                      </Link>
                    </div>
                  );
                })}
              </div>
            ),
          },
        ]}
        location={location}
        history={history}
        records={records}
        classes={{
          container: "list--striped",
          chevron: "icon--gray-400",
          chevronActive: "icon--gray-500",
          header: "u-text-upper u-sans-bold u-text-gray-400",
        }}
        noRecordsTitle="No Recent Installs Found"
      />
    </>
  );
};

export default Installs;
