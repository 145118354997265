import { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import {
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
  Button,
  ConfirmDialog,
  FormSection,
} from "@smartrent/ui";
import { Form, Formik, FormikHelpers } from "formik";
import { useModalState } from "@smartrent/hooks";

import { useDrawerNav } from "@/common/AppDrawer";
import { GroupProps, SalesForceProperty } from "@/types";
import { useGlobalContext } from "@/layout/Context";
import { useLinkSalesforceToGroupMutation, useSalesforceProperty } from "@/api";
import { SalesforcePropertySelect } from "@/common/salesforce/SalesforcePropertySelect";

export function LinkIntegrationToGroup(props: LinkIntegrationToGroupProps) {
  const drawer = useDrawerNav();
  const { setToast } = useGlobalContext();
  const { visible: isModalVisible, onOpen, onClose } = useModalState();
  const [modalSubmitLoading, setModalSubmitLoading] = useState(false);

  const { data: initialProperty } = useSalesforceProperty(
    props.group.salesforce_id || "",
    { enabled: !!props.group.salesforce_id }
  );

  const [linkItegrationToGroup] = useLinkSalesforceToGroupMutation();

  const [property, setProperty] = useState<SalesForceProperty>({
    Id: "",
    Name: "",
  });

  const handleSubmit = useCallback(
    async (_values: {}, actions: FormikHelpers<{}>) => {
      actions.setSubmitting(true);

      if (!props.group.salesforce_id && !property.Id) {
        setToast({
          type: "error",
          title: "Error",
          message: "Please select a salesforce property before submitting.",
        });
        return;
      }

      onOpen();

      actions.setSubmitting(false);
    },
    [props.group.salesforce_id, property.Id, onOpen, setToast]
  );

  const handleConfirm = useCallback(async () => {
    try {
      setModalSubmitLoading(true);
      await linkItegrationToGroup({
        group_id: props.group.id,
        salesforce_property_id: property?.Id || null,
      });

      props.refetchGroup();

      onClose();
      setModalSubmitLoading(false);
      drawer.pop();
    } catch (error) {
      onClose();
      setModalSubmitLoading(false);
      const solicitErrors = (error as any)?.response?.data?.errors;
      setToast({
        type: "error",
        title: "Error",
        message:
          solicitErrors || "Something went wrong. Please contact engineering.",
      });
    }
  }, [property.Id, onClose, linkItegrationToGroup, props, drawer, setToast]);

  return (
    <>
      <DrawerHeader title="Integration Linking" />
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {() => (
          <>
            <DrawerContent style={styles.drawerContent}>
              <Form>
                <FormSection title="Salesforce Property" style={styles.section}>
                  <SalesforcePropertySelect
                    initialValue={
                      property.Id ? property : initialProperty?.records?.[0]
                    }
                    value={property}
                    setValue={setProperty}
                    disabled={false}
                  />
                </FormSection>
              </Form>
            </DrawerContent>
            <DrawerActions>
              <Button onPress={drawer.pop} variation="plain">
                Close
              </Button>
              <FormikSubmit label="Submit" submittingLabel="Submitting" />
            </DrawerActions>
          </>
        )}
      </Formik>
      <ConfirmDialog
        title={property.Name ? "Link Integration" : "Unlink Integration"}
        description={
          property.Name
            ? `Are you sure you want to assign Salesforce Property "${property.Name}" to ${props.group.marketing_name}?`
            : `Are you sure you want to unlink Salesforce Property "${initialProperty?.records?.[0].Name}" from ${props.group.marketing_name}?`
        }
        visible={isModalVisible}
        onConfirm={handleConfirm}
        onClose={onClose}
        confirmButtonLoading={modalSubmitLoading}
      />
    </>
  );
}

interface LinkIntegrationToGroupProps {
  group: GroupProps;
  refetchGroup: () => void;
}

const styles = StyleSheet.create({
  drawerContent: { marginTop: -25 },
  section: { paddingBottom: 16 },
});
