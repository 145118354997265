import { useContext, useState } from "react";
import { StyleSheet, View, TouchableHighlight } from "react-native";

import {
  Text,
  useTheme,
  Button,
  Typography,
  Stack,
  HStack,
} from "@smartrent/ui";
import {
  ArrowLeft,
  ChevronRight,
  ExclamationCircleSolid,
  Users,
} from "@smartrent/icons";

import {
  getProjectStatusColor,
  getProjectStatusText,
} from "@smartrent/install";

import Badge from "@/common/Badge";

import { useIsMobile } from "@/hooks/breakpoints";

import Context from "../../layout/Context";
import { useGetProject } from "../../api/projects";
import ProgressBar from "../ProgressBar";
import { InstallDetails } from "../InstallDetails";
import Divider from "../Divider";

import AssignedUsersModal from "../modals/assigned-users-modal/AssignedUsersModal";

import ProjectHeaderLoader from "./ProjectHeaderLoader";

interface Props {
  id: string;
}

export default function ProjectHeader({ id }: Props) {
  const { history } = useContext(Context);

  const { data, isLoading }: any = useGetProject(id);

  const { colors, fonts } = useTheme();

  const [showUserList, setShowUserList] = useState(false);

  const statusText = !!data ? getProjectStatusText(data) : "";
  const statusColor = !!data ? getProjectStatusColor(data, colors) : "";

  const isMobile = useIsMobile();

  return isLoading ? (
    <ProjectHeaderLoader />
  ) : (
    <>
      <View style={styles.root}>
        <View style={styles.navWrapper}>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor="transparent"
            onPress={() => history.goBack()}
          >
            <View style={styles.row}>
              <ArrowLeft />
              <Text style={styles.navParent}>Projects / </Text>
              <Typography font="semibold">{data?.name}</Typography>
            </View>
          </TouchableHighlight>
        </View>
        <Stack
          direction={isMobile ? "column" : "row"}
          style={isMobile ? mobileStyles.header : styles.header}
          spacing={isMobile ? 16 : 32}
        >
          <View>
            <HStack spacing={16}>
              <Typography type="title2">{data?.name}</Typography>
              <Badge color={statusColor} text={statusText} />
            </HStack>
            <View style={styles.completionLabel}>
              <Text style={styles.completionLabelText}>{`Jobs Complete: ${
                data?.completed_job_count || 0
              }/${data?.job_count || 0}`}</Text>
            </View>
            <ProgressBar
              percentage={Math.round(
                (data?.completed_job_count / data?.job_count) * 100
              )}
            />
          </View>

          {data?.install_details ? (
            <>
              <Divider />
              <InstallDetails install_detail={data?.install_details} />
              <Divider />
            </>
          ) : null}

          <View style={styles.headerRightWrapper}>
            <View style={styles.buttonWrapper}>
              <Button
                iconLeft={Users}
                iconRight={ChevronRight}
                onPress={() => setShowUserList(!showUserList)}
              >
                View All Assigned Workers
              </Button>
            </View>
            {!!data?.cannot_complete_reason && (
              <View style={styles.cannotCompleteWrapper}>
                <View style={styles.cannotCompleteIcon}>
                  <ExclamationCircleSolid color={colors.warning} size={18} />
                </View>
                <Text style={[fonts.semibold, styles.cannotCompleteText]}>
                  {data?.cannot_complete_reason}
                </Text>
              </View>
            )}
          </View>
        </Stack>
      </View>
      <AssignedUsersModal
        show={showUserList}
        onClose={() => setShowUserList(false)}
        headerText={data?.name || ""}
        users={data?.users}
        statusText={statusText}
        statusColor={statusColor}
      />
    </>
  );
}

const styles = StyleSheet.create({
  header: { paddingTop: 16 },
  root: { paddingBottom: 16 },

  navWrapper: { flexDirection: "row" },
  navParent: { marginLeft: 8 },

  row: {
    flexDirection: "row",
  },
  buttonWrapper: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  completionLabel: { paddingVertical: 8 },
  completionLabelText: { fontWeight: "600" },
  headerRightWrapper: { flex: 1 },

  cannotCompleteWrapper: { flexDirection: "row", marginTop: 20 },
  cannotCompleteIcon: { paddingTop: 3 },
  cannotCompleteText: { marginLeft: 8 },
});

const mobileStyles = StyleSheet.create({
  header: {
    alignItems: "flex-start",
    justifyContent: "center",
    paddingTop: 16,
  },
  row: {
    flexDirection: "row",
  },
  buttonWrapper: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});
