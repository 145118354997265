import { useCallback, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import {
  ActivityIndicator,
  Typography,
  Button,
  AlertDialog,
} from "@smartrent/ui";

import { CloudSync, QuestionCircleBorder } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { HubRequest } from "@/types";
import { useHubUpgradeFirmware } from "@/api/hub";
import helpers from "@/lib/helpers";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";
import { useDialog } from "@/lib/contexts/dialog";
export interface UpgradeFirmware {
  hub: HubRequest;
}

export const UpgradeFirmware: React.FC<
  React.PropsWithChildren<UpgradeFirmware>
> = ({ hub }) => {
  const { confirm } = useDialog();
  const { onOpen: openHelp, onClose, visible } = useModalState();
  const [sendUpgradeHubFirmware, { isLoading }] = useHubUpgradeFirmware();

  const onClick = useCallback(async () => {
    const confirmed = await confirm({
      title: "Upgrade Firmware?",
      confirmText: "Upgrade",
      confirmType: "destructive",
      description:
        "Newer firmware versions sometimes cannot be downgraded to use old firmware versions, you should only click this if you know what you are doing.",
    });

    if (confirmed) {
      await sendUpgradeHubFirmware({ serial: hub.serial });
    }
  }, [confirm, sendUpgradeHubFirmware, hub]);

  const hubIsFusion = useMemo(
    () => helpers.getHubTypeFromSerial(hub?.serial ?? "") === "fusion",
    [hub?.serial]
  );

  const isNotAllowed = useMemo(
    () => !hub?.online || hubIsFusion,
    [hub?.online, hubIsFusion]
  );

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Upgrade Firmware</Typography>
            <View style={styles.flexRow}>
              {!hub?.online ? (
                <Typography
                  type="caption"
                  style={styles.marginRight}
                  color="warning"
                >
                  Hub is offline
                </Typography>
              ) : null}
              {hubIsFusion ? (
                <Typography
                  type="caption"
                  style={styles.marginRight}
                  color="warning"
                >
                  {!hub?.online ? "| " : null}Fusion hub
                </Typography>
              ) : null}
            </View>
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton
            disabled={isNotAllowed}
            icon={CloudSync}
            onClick={() => {}}
          />
        </CardBody>
        <CardFooter>
          <Button
            style={styles.disabledButtonStyle}
            disabled={isLoading || isNotAllowed}
            onPress={onClick}
          >
            {isLoading ? <ActivityIndicator /> : "Run Action"}
          </Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description="Retrieves and upgrades the hub to the latest firmware version (if applicable). This process can take up to 10 minutes to complete."
        visible={visible}
        onClose={onClose}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
  disabledButtonStyle: { width: 134.25, height: 40 },
  flexRow: {
    flexDirection: "row",
  },
  marginRight: { marginRight: 4 },
});
