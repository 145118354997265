import { useMemo } from "react";

import { Typography, useTheme } from "@smartrent/ui";

import { CheckCircle, Close, Question } from "@smartrent/icons";
import { StyleSheet, View } from "react-native";

import { UserRole } from "@/utils";
import { UserProps } from "@/types";

enum Status {
  Accepted = "Yes",
  NotAccepted = "No",
  Unknown = "Unknown",
}

export const UserAccountAccepted = ({
  invitation_sent_at,
  invitation_token,
  saml_id,
  role,
}: Partial<UserProps>) => {
  const { colors } = useTheme();

  // There isn't a 'status' field on user records
  // ..so we define what the status is here using same business logic as CMW
  const status = useMemo(() => {
    // if user has a value within either one of these fields, it is not accepted yet
    if (invitation_sent_at || invitation_token) {
      return Status.NotAccepted;
    }

    // if saml user, then having a saml id means the account has been accepted
    if (role === UserRole.PendingStaff) {
      return saml_id ? Status.Accepted : Status.NotAccepted;
    }

    // these should be null if invitation is complete
    if (invitation_sent_at === null && invitation_token === null) {
      return Status.Accepted;
    }

    // if for any reason none of the above work, we'll display 'unknown' status
    return Status.Unknown;
  }, [invitation_sent_at, invitation_token, role, saml_id]);

  const getColor = useMemo(() => {
    switch (status) {
      case Status.Accepted:
        return colors.green;
      case Status.NotAccepted:
        return colors.gray600;
      default:
        return colors.gray200;
    }
  }, [colors.gray200, colors.gray600, colors.green, status]);

  const getSize = useMemo(() => {
    switch (status) {
      case Status.Accepted:
        return 16;
      case Status.NotAccepted:
        return 18;
      default:
        return 16;
    }
  }, [status]);

  const Icon = useMemo(() => {
    switch (status) {
      case Status.Accepted:
        return CheckCircle;
      case Status.NotAccepted:
        return Close;
      default:
        return Question;
    }
  }, [status]);

  return (
    <View style={styles.container}>
      <Icon style={styles.icon} size={getSize} color={getColor} />
      <Typography>
        {status} {saml_id ? ` (saml)` : ""}
      </Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: "row", alignItems: "center" },
  icon: { marginRight: 4 },
});
