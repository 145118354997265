import { View, StyleSheet } from "react-native";

import { Calendar, HeartEmpty, Online, Offline } from "@smartrent/icons";
import { Typography, useTheme } from "@smartrent/ui";

import { formatDate } from "@/utils";
import { CircleWithInfo } from "@/common/icons/CircleWithInfo";

import { HubProps } from "@/types";

import { EM_DASH } from "@/utils/chars";

export default function HubInfo({ hub }: { hub?: HubProps }) {
  const { colors } = useTheme();
  if (!hub) {
    return null;
  }

  return (
    <View>
      <Typography type="title6">Details</Typography>
      <View style={styles.detailsContainer}>
        <CircleWithInfo
          circleColor={colors.primary}
          icon={<Calendar color="white" size={20} />}
          label="Installation Date"
          value={
            hub.inserted_at
              ? formatDate({ date: hub.inserted_at, pattern: "P p" })
              : EM_DASH
          }
        />
        <CircleWithInfo
          circleColor={colors.primary}
          icon={<HeartEmpty color="white" size={20} />}
          label="Last Heartbeat"
          value={
            hub.heartbeat_at
              ? formatDate({
                  date: hub.heartbeat_at,
                  pattern: "P",
                  timeZone: "UTC",
                })
              : EM_DASH
          }
        />
        <CircleWithInfo
          circleColor={hub.offline_at ? colors.error : colors.primary}
          icon={
            hub.offline_at ? (
              <Offline size={20} color={colors.white} />
            ) : (
              <Online size={20} color={colors.white} />
            )
          }
          label={hub.offline_at ? "Offline Since" : "Online Since"}
          value={
            hub.offline_at || hub.online_since
              ? formatDate({
                  date: hub.offline_at ?? hub.online_since,
                  pattern: "P p",
                })
              : EM_DASH
          }
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  detailsContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});
