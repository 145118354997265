import { useState, useEffect, useContext } from "react";
import { View } from "react-native";
import { Text } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import Layout from "../layout/Layout";
import Context from "../layout/Context";

import BatteryReportComponent from "./battery-report";

export default function BatteryReports() {
  const { match } = useContext(Context);

  const [property, setProperty] = useState(null);

  useEffect(() => {
    Helpers.fetch({
      path: `/api/groups/${match.params.groupId}`,
    }).then(({ property }) => setProperty(property));
  }, [match.params.groupId]);

  return (
    <Layout>
      <View style={{ padding: 8, marginTop: 8 }}>
        {property && (
          <View>
            <Text type="title2">
              {property.organization_name} - {property.group_name}
            </Text>
          </View>
        )}
      </View>
      <BatteryReportComponent groupId={match.params.groupId} />
    </Layout>
  );
}
