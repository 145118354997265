import type { QueryKey } from "@tanstack/react-query";

import { instance } from "@/lib/hooks";
import { createAxiosQuery } from "@/hooks/react-query";
import helpers from "@/lib/helpers";

export interface AccessCredential {
  code: string;
  credential_type: string;
  identity_type: string;
  first_name: string;
  last_name: string;
  updated_at: string;
  inserted_at: string;
  deleted_at: string;
}

export interface AccessCredentialResponse {
  current_page: number;
  records: AccessCredential[];
  total_records: number;
  total_pages: number;
}

export interface AccessCredentialsOptions {
  unitId: string | number;
  filters: AccessCredentialsFilters;
}

export interface AccessCredentialsFilters {
  invitation_type: string;
  credential_type: string;
}

export const useAccessCredentialsQuery = createAxiosQuery(
  "access-credentials",
  async ({ unitId, filters }: AccessCredentialsOptions) => {
    return instance.get<AccessCredentialResponse>(
      `/units/${unitId}/access-credentials?${helpers.toQS(filters)}`
    );
  }
);

export const fetchAccessCredentials = async ({
  queryKey,
}: {
  queryKey: QueryKey;
}) => {
  const [, , filters] = queryKey as [string, Record<string, unknown>, any];
  const { data } = await instance.get(
    `/units/${filters.unitId}/access-credentials`,
    {
      params: filters,
    }
  );
  return data;
};
