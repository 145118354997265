type Props = {
  color: string; // hex,
  size?: number;
};

export default function Circle({ color, size }: Props) {
  return (
    <span
      style={{
        backgroundColor: color,
        borderRadius: 10,
        display: "inline-block",
        // marginLeft: 15,
        marginRight: 5,
        width: size || 10,
        height: size || 10,
      }}
    />
  );
}
