import { useCallback, useMemo } from "react";
import {
  Typography,
  FormikSelectField,
  FormikTextInputField,
  ColumnOptions,
  useTableQuery,
  GetQueryKeyFn,
  Table,
  useTheme,
} from "@smartrent/ui";

import {
  getProjectStatusColor,
  getProjectStatusText,
} from "@smartrent/install";

import {
  DEALER_API_RECORDS_LIMIT,
  formatDate,
  installDetailDeviceTypes,
  installDetailInstallTypes,
} from "@/utils";

import { InstallProjectWithTaskFlags, fetchProjects } from "@/api/projects";

import { Badges } from "../sr-table/Badges";

interface Props {
  assignedToUserId?: string;
  searchValue?: string;
  group?: any;
}

const keyExtractor = ({ id }: InstallProjectWithTaskFlags) => id;
const getRowHref = ({ id }: InstallProjectWithTaskFlags) =>
  `/alloy-install/projects/${id}`;

export function ProjectList({ searchValue, group, assignedToUserId }: Props) {
  const { colors } = useTheme();
  const columns = useMemo<ColumnOptions<InstallProjectWithTaskFlags>[]>(
    () => [
      {
        header: "Name",
        name: "name",
        render: ({ row }) => (
          <Typography type="body" color="textPrimary">
            {row?.name}
            {"\n"}
            <Typography color="textSecondary" type="title6">
              {row?.property?.name}
            </Typography>
          </Typography>
        ),
        relativeWidth: columnWidths.name,
      },
      {
        header: "Start Date",
        name: "startDate",
        render: ({ row }) => {
          const startDate = formatDate({
            date: row?.start_date || "",
            pattern: "LLL d, yyyy",
          });

          return (
            <Typography type="body" color="textPrimary">
              {startDate}
            </Typography>
          );
        },
        relativeWidth: columnWidths.startDate,
      },
      {
        header: "End Date",
        name: "endDate",
        render: ({ row }) => {
          const endDate = formatDate({
            date: row?.end_date || "",
            pattern: "LLL d, yyyy",
          });

          return (
            <Typography type="body" color="textPrimary">
              {endDate}
            </Typography>
          );
        },
        relativeWidth: columnWidths.endDate,
      },
      {
        header: "Jobs",
        name: "jobs",
        render: ({ row }) => {
          const total = row.job_count;
          const totalComplete = row.completed_job_count;

          return total ? (
            <Typography type="body" color="textPrimary">
              {`${totalComplete}/${total} Job${
                total > 1 ? "s" : ""
              } Completed (${((totalComplete / total) * 100).toFixed(0)}%)`}
            </Typography>
          ) : (
            <Typography type="body" color="textPrimary">
              No Jobs
            </Typography>
          );
        },
        relativeWidth: columnWidths.jobs,
      },
      {
        header: "Status",
        name: "status",
        relativeWidth: columnWidths.status,
        filter: () => (
          <FormikSelectField
            name="status"
            label="Status"
            options={[
              {
                label: "Completed",
                value: "completed",
              },
              {
                label: "Cannot Complete",
                value: "cannot complete",
              },
              {
                label: "In Progress",
                value: "in progress",
              },
              {
                label: "Not Started",
                value: "not started",
              },
              {
                label: "Overdue",
                value: "overdue",
              },
            ]}
          />
        ),
        render: ({ row }) => (
          <Badges
            statusColor={getProjectStatusColor(row, colors)}
            statusText={getProjectStatusText(row)}
            flagCount={row.open_flags_count}
          />
        ),
      },
      {
        header: "Property Name",
        name: "property",
        hidden: true, // hidden because we want to keep this filter
        render: () => null,
        filter: () => (
          <FormikTextInputField name="property" label="Property Name" />
        ),
      },
      {
        header: "Install Type",
        name: "install_type",
        hidden: true,
        render: () => null,
        filter: () => (
          <FormikSelectField
            name="install_type"
            label="Install Type"
            options={installDetailInstallTypes}
          />
        ),
      },
      {
        header: "Device Type",
        name: "device_type",
        hidden: true,
        render: () => null,
        filter: () => (
          <FormikSelectField
            name="device_type"
            label="Device Type"
            options={installDetailDeviceTypes}
          />
        ),
      },
    ],
    [colors]
  );

  const getQueryKey: GetQueryKeyFn = useCallback(
    ({ filters, page }) => [
      "projects",
      {},
      {
        ...(group?.id && { property_remote_id: group?.id }),
        ...(searchValue && { name: searchValue }),
        ...(assignedToUserId && { assigned_to_user_id: assignedToUserId }),
        ...filters,
        page,
      },
    ],
    [group?.id, searchValue, assignedToUserId]
  );

  const tableProps = useTableQuery({
    columns,
    fetch: ({ queryKey }) =>
      fetchProjects(queryKey[2] as Record<string, unknown>),
    getQueryKey,
    keyExtractor,
    defaultPageSize: DEALER_API_RECORDS_LIMIT,
  });

  return (
    <Table
      title="Projects"
      noRecordsText="No projects found"
      getRowHref={getRowHref}
      {...tableProps}
    />
  );
}

const columnWidths = {
  name: 140,
  startDate: 60,
  endDate: 60,
  jobs: 100,
  status: 36,
};
