import * as React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { Refresh } from "@smartrent/icons";
import {
  ActivityIndicator,
  HStack,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";

import { formatTime } from "@/utils";
import { HubRefurbishStep } from "@/types";

import { useApiRedisStatsBatchHook } from "@/pages/hub-refurbish/hooks/useApiRedisStatsBatchHook";
import { useApiHubRefurbishJobHook } from "@/pages/hub-refurbish/hooks/useApiHubRefurbishJobHook";
import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

interface HubRefurbishRefetchButtonProps {
  queryProps:
    | ReturnType<typeof useApiRedisStatsBatchHook>
    | ReturnType<
        typeof useApiHubRefurbishJobHook
      >["runHubRefurbishBatchJobQuery"];
  title: string;
}

const HubRefurbishRefetchButtonInner: React.FC<
  React.PropsWithChildren<HubRefurbishRefetchButtonProps>
> = ({ queryProps: { refetch, isFetching, dataUpdatedAt, error }, title }) => {
  const { colors } = useTheme();
  const { activeSerials, isCriticalError } = useHubRefurbishContext();

  const isDisabled =
    isCriticalError || !((activeSerials || []).length > 0) || isFetching;

  const color = isDisabled ? colors.primaryMuted : colors.secondary;

  return (
    <VStack spacing={8}>
      <TouchableOpacity disabled={isDisabled} onPress={() => refetch()}>
        <HStack spacing={4}>
          <Typography color={isDisabled ? "primaryMuted" : "secondary"}>
            {title}
          </Typography>

          {isFetching ? (
            <ActivityIndicator color={colors.secondary} size={24} />
          ) : (
            <Refresh color={color} size={24} />
          )}
        </HStack>
      </TouchableOpacity>

      <Typography
        type="captionSmall"
        color="mutedTextGrayscale"
        style={styles.timeText}
      >
        Last Refresh:{" "}
        {dataUpdatedAt ? formatTime(dataUpdatedAt, "pp z") : "now"}
      </Typography>

      {error ? (
        <Typography type="bodyLargeSemibold" color="error">
          Error: {JSON.stringify(error.message)}
        </Typography>
      ) : null}
    </VStack>
  );
};

const HubRefurbishResetJobRefetchButton = () => {
  const { runHubRefurbishBatchJobQuery } = useApiHubRefurbishJobHook();

  return (
    <HubRefurbishRefetchButtonInner
      queryProps={runHubRefurbishBatchJobQuery}
      title="REFETCH JOB STATUS"
    />
  );
};

const HubRefurbishStatusRefetchButton = () => {
  const queryProps = useApiRedisStatsBatchHook();

  return (
    <HubRefurbishRefetchButtonInner
      queryProps={queryProps}
      title="CHECK NETWORK STATUS"
    />
  );
};

export const HubRefurbishRefetchButton = () => {
  const { step } = useHubRefurbishContext();
  return step === HubRefurbishStep.ResetHubs ? (
    <HubRefurbishResetJobRefetchButton />
  ) : (
    <HubRefurbishStatusRefetchButton />
  );
};

const styles = StyleSheet.create({
  timeText: { textAlign: "center" },
});
