import { uniq } from "lodash-es";

import { DeviceProps, AccessControlDevice } from "../types";

const DEVICE_TYPE_ENTRY_CONTROL: String = "entry_control";

export const hasZWaveLocks = (devices: DeviceProps[]): boolean =>
  !!devices.some((device) => device.type === DEVICE_TYPE_ENTRY_CONTROL);

export const hasAccessControlLocks = (
  accessControlDevices: AccessControlDevice[]
): boolean => accessControlDevices.length > 0;

export const getAccessControlLockProviderNames = (
  accessControlDevices: AccessControlDevice[]
) => {
  return uniq(
    accessControlDevices.map((device) => device.provider_name).sort()
  );
};
