import Helpers from "@/lib/helpers";
import { instance } from "@/lib/hooks";
import { ContextProps, OrganizationProps, PermissionProps } from "@/types";

export const getOrganizationActions = ({
  organization,
  context,
  permissions,
  hasAlloyInstallCompany: hasAlloyInstallOrganization,
  handleUpdateOrganizationActionClick,
  handleAlloyInstallOrganizationClick,
  handleLinkIntegrationClick,
}: {
  organization: OrganizationProps;
  context: ContextProps;
  permissions: PermissionProps;
  hasAlloyInstallCompany: boolean;
  handleUpdateOrganizationActionClick: () => void;
  handleAlloyInstallOrganizationClick: () => void;
  handleLinkIntegrationClick: () => void;
}) => {
  const actions = [];

  if (permissions.update_organization) {
    actions.push({
      label: "Update Organization Settings",
      onSubmit: handleUpdateOrganizationActionClick,
    });
  }

  if (permissions.can_send_organization_reports) {
    actions.push({
      label: "Send Organization Reports",
      onSubmit: () => {
        return instance
          .get(`/organizations/${organization.id}/reports/send`)
          .then((response) => {
            if (response.data.success) {
              context.setToast({
                type: "success",
                title: "Success",
                message:
                  "Started processing organization reports. This could take more than 10 minutes.",
              });
            } else {
              context.setToast(Helpers.unknownErrorToast);
            }
          })
          .catch((err) => {
            console.error(err);
            context.setToast(Helpers.unknownErrorToast);
          });
      },
    });
  }

  if (permissions.update_organization && !hasAlloyInstallOrganization) {
    actions.push({
      label: "Create Alloy Install Environment",
      onSubmit: handleAlloyInstallOrganizationClick,
    });
  }

  if (permissions.id_integration_manager) {
    actions.push({
      label: "Link Integration",
      onSubmit: handleLinkIntegrationClick,
    });
  }

  return actions;
};
