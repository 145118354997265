import { useState, useEffect } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { TrashX, ExclamationCircleOutline } from "@smartrent/icons";
import { get, startCase, orderBy } from "lodash-es";

import { Typography, useTheme } from "@smartrent/ui";

import { useApi } from "@/lib/hooks";
import Helpers from "@/lib/helpers";
import { AutomationProps, HubProps, PermissionProps } from "@/types";

interface AutomationNameProps {
  record: AutomationProps;
}

function AutomationName({ record }: AutomationNameProps) {
  let name = "";
  if (record.name && !record.name.includes("-")) {
    name = record.name;
  }

  if (!name && record.time) {
    name = "Schedule";
  } else if (!name) {
    name = "Automation";
  }

  const da = get(
    record,
    "status_check.device_attribute_that_should_be_changed"
  );
  if (da) {
    name += ` - ${startCase(da)}`;
  }

  return (
    <div className="u-font14">
      {name} (created: {Helpers.formatDate(record.inserted_at, "MM/DD/YY")})
    </div>
  );
}

interface AutomationEffectsProps {
  record: AutomationProps;
}

function AutomationEffects({ record }: AutomationEffectsProps) {
  if (
    !(record && record.device_attributes && record.device_attributes.length)
  ) {
    return (
      <div className="u-text-symbolic-warning u-font10">
        No device attribute effects
      </div>
    );
  }
  return (
    <div
      className={`${Helpers.technicalDetailsTextClass} u-font10 u-pleft8 u-line14 u-mbottom4`}
    >
      <div className="u-sans-bold">Effects:</div>
      {record.device_attributes.map(
        ({ name, last_read_state, target_state, device_id }) => {
          return (
            <div key={`${name}-${device_id}`}>
              {startCase(name)} to {target_state}{" "}
              <span className="u-text-gray-400">
                (current: {last_read_state}, device_id: {device_id})
              </span>
            </div>
          );
        }
      )}
    </div>
  );
}

interface AutomationsProps {
  hub?: HubProps;
  permissions: PermissionProps;
}

export const Automations: React.FC<
  React.PropsWithChildren<AutomationsProps>
> = ({ hub, permissions }) => {
  const { colors } = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState<AutomationProps[]>([]);
  const [collapsed, setCollapsed] = useState(false);

  const { response } = useApi({
    url: `/units/${hub?.unit_id}/automations`,
    trigger: [hub?.unit_id],
    forceDispatchEffect: () => !!(hub?.unit_id && permissions.resident_details),
  });

  useEffect(() => {
    if (response && response.data) {
      setIsLoading(false);
      setRecords(response.data.records);
    }
  }, [response]);

  if (!(hub && permissions.resident_details)) {
    return null;
  }

  if (isLoading) {
    return <div>Loading Automations...</div>;
  }

  if (!records.length) {
    return null;
  }

  const activeRecords = records.filter((r) => !r.deleted_at);
  const deletedRecords = records.filter((r) => r.deleted_at);

  const RED = "#f10b0b99";
  const ORANGE = "#fd713799";

  return (
    <View>
      <div
        className="h5 u-mbottom8 cursor--pointer"
        onClick={() => setCollapsed(!collapsed)}
      >
        Automations / Schedules
      </div>
      {!collapsed && (
        <div>
          {activeRecords.map((record) => {
            return (
              <div key={record.id} className="u-p4 u-mbottom16">
                <div
                  className="u-flex u-flex-align-items-center u-mbottom8"
                  title={
                    record.deleted_at
                      ? `Deleted on ${Helpers.formatDate(record.deleted_at)}`
                      : ""
                  }
                >
                  {!!record.status_check && (
                    <div className="u-mright8">
                      <ExclamationCircleOutline size={14} color={ORANGE} />
                    </div>
                  )}
                  <div className="u-line14">
                    <AutomationName record={record} />
                    {record.time && (
                      <div className="u-text-gray-600 u-font12">
                        {record.time
                          ? `${record.deleted_at ? "repeated" : "repeats"} at ${
                              record.time
                            } on ${record.repeat_on.join(", ")}`
                          : ""}
                      </div>
                    )}
                    {!!record.status_check && (
                      <Typography
                        type="caption"
                        color="warning"
                        style={styles.recordStatusCheck}
                      >
                        {record.status_check.status}
                      </Typography>
                    )}
                  </div>
                </div>
                {permissions.advanced_hub_troubleshooting && (
                  <AutomationEffects record={record} />
                )}
                {!!record.executions.length && (
                  <View
                    style={[
                      styles.executionWrapper,
                      { borderColor: colors.border },
                    ]}
                  >
                    {orderBy(record.executions, ["executed_at"]).map(
                      (execution) => (
                        <Typography
                          type="body"
                          color="textSecondary"
                          key={execution.id}
                        >
                          {execution.executed_at
                            ? `Executed at ${Helpers.formatDate(
                                execution.executed_at
                              )}`
                            : `Scheduled for ${Helpers.formatDate(
                                execution.scheduled_for
                              )}`}
                        </Typography>
                      )
                    )}
                  </View>
                )}
                {record.created_by && (
                  <div className="u-mtop4 u-text-gray-600 u-font12 u-text-right u-line14">
                    <div>created by {record.created_by}</div>
                    {permissions.view_technical_details && (
                      <span className={`${Helpers.technicalDetailsTextClass}`}>
                        {" "}
                        (id: {record.id})
                      </span>
                    )}
                  </div>
                )}
              </div>
            );
          })}
          <div className="u-flex u-flex-wrap u-flex-align-items-center">
            <TrashX size={16} color={RED} /> {deletedRecords.length} deleted
            automations / schedules
          </div>
        </div>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  executionWrapper: {
    height: "75px",
    overflow: "scroll",
    borderWidth: 1,
  },
  recordStatusCheck: {
    fontSize: 10,
  },
});
