import { useMemo, useState, useEffect } from "react";
import { Text, Link } from "@smartrent/ui";

import { View } from "react-native";
import moment from "moment";

import Layout from "@/layout/Layout";
import PaginatedTable from "@/common/paginated-table/PaginatedTable";
import { PaginatedTableColumn } from "@/common/paginated-table/PaginatedTableColumnHeader";
import { useHubPrep } from "@/pages/hub-prep/HubPrepContext";
import { useGlobalContext } from "@/layout/Context";

import HubPrepAveragePrepTime from "@/pages/hub-prep/HubPrepAveragePrepTime";
import HubsPreppedPerHour from "@/pages/hub-prep/HubsPreppedPerHour";

import helpers from "@/lib/helpers";
import { useApi } from "@/lib/hooks";

import { HubPrepLog } from "../../types";

import { HubPrepQuickActions } from "./HubPrepQuickActions";

export default function HubPrepContainer() {
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const { hubPrepLogs, setHubPrepLogs, loading, setLoading } = useHubPrep();
  const { setPageLoading } = useGlobalContext();

  const filters = helpers.qsFromLocation(location);
  const { response } = useApi({
    url: `/hub-prep-logs?${helpers.toQS(filters)}`,
    trigger: { filters },
  });

  useEffect(() => {
    if (response && response.data.records) {
      setTotalPages(response?.data?.total_pages || 1);
      setTotalRecords(response?.data?.total_records || 0);
      setPageLoading(false);
      setLoading(false);
      setHubPrepLogs(response.data.records);
    } else if (!response) {
      setLoading(true);
    }
  }, [response, setPageLoading, setHubPrepLogs, setLoading]);

  const currentPage = Number(filters?.page) || 1;
  const columns: PaginatedTableColumn[] = useMemo(
    () => [
      {
        field: "serial",
        label: "Serial",
        filter: "text",
        sortable: true,
        render: (log: HubPrepLog) => (
          <Link href={`/hubs/${log.serial}`}>{log.serial}</Link>
        ),
      },
      {
        field: "type",
        label: "Type",
        render: (log: HubPrepLog) => (
          <Text>
            {log.firmware_upgraded_at || log.firmware_update_required
              ? "Hub Prep"
              : log.config_update_required || log.config_updated_at
                ? "Config Update"
                : "Other"}
          </Text>
        ),
      },
      {
        field: "status",
        label: "Status",
        render: (log: HubPrepLog) => (
          <Text>
            {log.action_required ? (
              moment(log.started_at).add(30, "minutes").isAfter(moment()) ? (
                <div>
                  Running
                  <span className="u-font12 u-text-gray-600">
                    {" "}
                    ({moment(log.started_at).fromNow()})
                  </span>
                </div>
              ) : (
                "Stopped"
              )
            ) : (
              "Completed"
            )}
          </Text>
        ),
      },
      {
        field: "inserted_at",
        label: "Date Created",
        sortable: true,
        render: (log: HubPrepLog) => (
          <Text>{helpers.formatDate(log.inserted_at)}</Text>
        ),
      },
    ],
    []
  );

  // @todo add support for hub detail page (when not attached to unit)
  // const onRowClick = useCallback(
  //   (log: HubPrepLog) => history.push(`/hubs/${log.serial}`),
  //   [history]
  // );

  return (
    <Layout>
      <View style={{ marginHorizontal: 24 }}>
        <div className="u-flex u-flex-justify-between u-flex-align-items-center u-mtop32">
          <div className="h3">Hub Prep v3</div>
        </div>

        <View>
          <HubPrepQuickActions />
          <HubPrepAveragePrepTime />
          <HubsPreppedPerHour />
        </View>

        <PaginatedTable
          isLoading={loading}
          results={hubPrepLogs}
          noRecordsText="No Hub Prep Logs"
          totalResults={totalRecords}
          totalPages={totalPages}
          currentPage={currentPage}
          columns={columns}
          uniqueKey="serial"
        />
      </View>
    </Layout>
  );
}
