import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import * as React from "react";

import {
  GroupProps,
  OrganizationProps,
  GroupCommunityWifi,
  GroupTourConfigProps,
} from "@/types";

import { useApi } from "@/lib/hooks";

import Context from "@/layout/Context";
import Layout from "@/layout/Layout";
import Alert from "@/common/Alert";

interface GroupContextState {
  group: GroupProps;
  organization: OrganizationProps;
  groupCommunityWifi: GroupCommunityWifi;
  groupTourConfig: GroupTourConfigProps;
}

interface GroupContext extends GroupContextState {
  refetchGroup: () => void;
}

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
function createCtx<A extends {} | null>() {
  const ctx = createContext<A | undefined>(undefined);
  function useCtx() {
    const c = useContext(ctx);
    if (c === undefined) {
      throw new Error("useCtx must be inside a Provider with a value");
    }
    return c;
  }
  return [useCtx, ctx] as const; // 'as const' makes TypeScript infer a tuple
}

// We still have to specify a type, but no default!
export const [useGroup, GroupCtx] = createCtx<GroupContext>();

interface GroupProviderProps {
  id: number;
}

export const GroupProvider: React.FC<
  React.PropsWithChildren<GroupProviderProps>
> = ({ id, children }) => {
  const { user, setPageLoading } = useContext(Context);

  const [data, setData] = useState<any>();

  // Non-RESTful endpoint that fetches information about the group itself and all of its surrounding config/context
  const { response, reFetch } = useApi({
    url: `/groups/${id}/details`,
    trigger: [id],
  });

  useEffect(() => {
    if (response && response.data) {
      setPageLoading(false);
      setData(response.data);
    } else {
      setPageLoading(true);
    }
  }, [response, setPageLoading]);

  const refetchGroup = useCallback(() => {
    reFetch();
    // TODO(someday): fix useAxios hook
  }, [id]);

  if (!(data && user)) {
    // loading indicator, if we return null we get no loading indicator and if we continue from here without data
    // we get a runtime error
    return <Layout />;
  }

  const { group, organization, error, groupCommunityWifi, groupTourConfig } =
    data;

  if (error) {
    return <Alert type="error">{error}</Alert>;
  }

  if (!group) {
    return <div>Group not found</div>;
  }

  return (
    <GroupCtx.Provider
      value={{
        group,
        organization,
        refetchGroup,
        groupCommunityWifi,
        groupTourConfig,
      }}
    >
      {children}
    </GroupCtx.Provider>
  );
};
