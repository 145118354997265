import { instance } from "@/lib/hooks";
import { queryClient } from "@/api/query-client";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";

export interface UnitTransferCheckProps {
  group_id: string | number;
  units_csv_url: string;
}

export interface UnitTransferProps {
  source_group_id: string | number;
  target_group_id: string | number;
  units_csv_url: string;
}

export interface UnitTransferCheckResults {
  units_not_in_group: string[];
  units_in_bulk_work_order: string[];
  unit_without_hub_accounts: string[];
  units_in_construction_mode: string[];
}

export const useUnitCheckMutation = createAxiosMutation(
  async ({ group_id, units_csv_url }: UnitTransferCheckProps) => {
    return await instance.post<UnitTransferCheckResults>(
      `/units/transfer-check`,
      {
        group_id,
        units_csv_url,
      }
    );
  }
);

export interface UnitTransferResponse {
  unit_transfer_job_id: string;
}
export const useUnitTransferMutation = createAxiosMutation(
  async ({
    source_group_id,
    target_group_id,
    units_csv_url,
  }: UnitTransferProps) => {
    return await instance.post<UnitTransferResponse>(`/units/transfer`, {
      source_group_id,
      target_group_id,
      units_csv_url,
    });
  },
  {
    successToast(data, variables, context) {
      return {
        message: `Unit Transfer Queued. Job ID: ${data.unit_transfer_job_id}`,
      };
    },
  }
);

export interface UnitTransferResult {
  id: string;
  status: "ok" | "error";
  reason?: string;
}
export type UnitTransferResults = {
  status: "preparing" | "in_progress" | "completed";
  total_units: number;
  results: UnitTransferResult[];
};

export const useUnitTransferResults = createAxiosQuery(
  "unit-transfer-results",
  async (params?: { job_id: string }) => {
    return await instance.get<UnitTransferResults>(
      `/units/transfer/${params?.job_id}`
    );
  },
  {
    onSuccess: (data) => {
      if (data.status === "completed") {
        queryClient.invalidateQueries({
          queryKey: ["units"],
        });
        queryClient.invalidateQueries({
          queryKey: ["use-unit"],
        });
      }
    },
  }
);
