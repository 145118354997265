import * as React from "react";
import { upperCase } from "lodash-es";

import { Typography, TypographyProps, VStack } from "@smartrent/ui";

import { EM_DASH } from "@/utils/chars";

interface SowListItemProps {
  label: TypographyProps["children"];
  labelType?: TypographyProps["type"];
  value: TypographyProps["children"];
  valueType?: TypographyProps["type"];
}
export const SowListItem: React.FC<
  React.PropsWithChildren<SowListItemProps>
> = ({ label, value }) => {
  return (
    <VStack spacing={8}>
      <Typography type="captionLarge" color="textSecondary">
        {typeof label === "string" ? upperCase(label) : label}
      </Typography>
      <Typography type="body">{value || EM_DASH}</Typography>
    </VStack>
  );
};
