import { useCallback, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { FormikHelpers } from "formik";

import { AnimatedPlaceholder, DrawerHeader, useTheme } from "@smartrent/ui";

import { useUpdateDeviceSettingsMutation, useDeviceSettingsQuery } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";
import { DeviceSettingsForm } from "@/common/devices/drawer/DeviceSettingsForm";

import { DeviceProps, GenericObject } from "@/types";

interface DeviceSettingsProps {
  device: DeviceProps;
}
export const DeviceSettings: React.FC<
  React.PropsWithChildren<DeviceSettingsProps>
> = ({ device }) => {
  const { colors } = useTheme();
  const drawer = useDrawerNav();
  const { data, isLoading } = useDeviceSettingsQuery(device.id);
  const { device_settings, descriptor_settings } = useMemo(() => {
    return data ? data : { device_settings: [], descriptor_settings: [] };
  }, [data]);

  const [updateDeviceSettings] = useUpdateDeviceSettingsMutation();

  const title = useMemo(
    () =>
      isLoading ? (
        <View style={styles.placeholderContainer}>
          <AnimatedPlaceholder
            style={[
              styles.titlePlaceholder,
              { backgroundColor: colors.gray100 },
            ]}
          />
        </View>
      ) : (
        device?.internal_name ?? "\u2014"
      ),
    [device, colors, isLoading]
  );

  const handleCancel = useCallback(() => drawer.pop(), [drawer]);
  const handleSubmit = useCallback(
    async (values: GenericObject, actions: FormikHelpers<GenericObject>) => {
      actions.setSubmitting(true);
      await updateDeviceSettings({
        deviceId: device.id,
        settings: values,
      });

      actions.setSubmitting(false);

      drawer.reset();
    },
    [updateDeviceSettings, device, drawer]
  );

  return (
    <>
      <DrawerHeader title={title} subtitle="Device Settings" />
      <DeviceSettingsForm
        descriptorSettings={descriptor_settings}
        deviceSettings={device_settings}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        isLoading={isLoading}
      />
    </>
  );
};

const styles = StyleSheet.create({
  placeholderContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
  titlePlaceholder: {
    width: 100,
    height: 20,
    borderRadius: 2,
    marginRight: 4,
  },
});
