import * as React from "react";
import { View, StyleSheet } from "react-native";
import { startCase } from "lodash-es";
import { intervalToDuration, parseISO, isValid, Duration } from "date-fns";

import { Typography } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

interface ConnectionRowProps {
  isOfflineRow: boolean;
  connection: string;
  connected_at: string;
  disconnected_at: string;
  idx: number;
  internalDiffInSeconds: Duration | null;
  externalDiffInSeconds: Duration | null;
  nextConnectedAt: string | Date;
}

const isPlural = (time: number) => (time !== 1 ? "s" : "");

const displayTime = (time: Duration) => {
  if (Object.values(time).every((v) => v == 0)) {
    return "(0 sec)";
  }
  const { seconds, minutes, days, hours, months, years } = time;

  let displayTime = `(${days ? `${days} day${isPlural(days)}, ` : ""}${
    hours ? `${hours} hr${isPlural(hours)}, ` : ""
  }${minutes ? `${minutes} min${isPlural(minutes)}, ` : ""}${
    seconds ? `${seconds} sec` : ""
  })`;

  if (years) {
    displayTime = `(${years ? `${years} yr${isPlural(years)}, ` : ""}${
      months ? `${months} mo${isPlural(months)}, ` : ""
    }${days ? `${days} day${isPlural(days)}, ` : ""}${
      hours ? `${hours} hr${isPlural(hours)}` : ""
    }`;
  }

  if (months) {
    displayTime = `(${years ? `${years} yr${isPlural(years)}, ` : ""}${
      months ? `${months} mo${isPlural(months)}, ` : ""
    }${days ? `${days} day${isPlural(days)}, ` : ""}${
      hours ? `${hours} hr${isPlural(hours)}, ` : ""
    }${minutes ? `${minutes} min${isPlural(minutes)}` : ""})`;
  }

  return displayTime;
};

const ConnectionRow: React.FC<React.PropsWithChildren<ConnectionRowProps>> = ({
  isOfflineRow,
  connection,
  connected_at,
  disconnected_at,
  idx,
  internalDiffInSeconds,
  externalDiffInSeconds,
  nextConnectedAt,
}) => {
  return (
    <View style={styles.historyRow}>
      <View style={styles.connectionType}>
        {/* Row Start */}
        <Typography
          color={isOfflineRow ? "error" : "textSecondary"}
          type="caption"
        >
          {isOfflineRow ? "Offline" : startCase(connection)}
        </Typography>
      </View>
      {/* Starting/Ending Dates */}
      <View style={styles.flexRow}>
        <Typography
          type="caption"
          color={isOfflineRow ? "error" : "textSecondary"}
        >
          {Helpers.formatDate(
            isOfflineRow ? disconnected_at : connected_at,
            "MM/DD hh:mma"
          )}
        </Typography>
        <Typography
          type="caption"
          color={isOfflineRow ? "error" : "textSecondary"}
        >
          {isOfflineRow
            ? ` - ${Helpers.formatDate(
                idx === 0 ? new Date() : nextConnectedAt,
                "MM/DD hh:mma"
              )}`
            : disconnected_at
              ? ` - ${Helpers.formatDate(disconnected_at, "MM/DD hh:mma")}`
              : null}
        </Typography>

        {/* Time difference formatting */}
        {externalDiffInSeconds && internalDiffInSeconds ? (
          <Typography
            type="caption"
            color={isOfflineRow ? "error" : "textSecondary"}
            style={styles.leftMargin}
          >
            {isOfflineRow
              ? displayTime(externalDiffInSeconds)
              : displayTime(internalDiffInSeconds)}
          </Typography>
        ) : null}
      </View>
    </View>
  );
};

interface HubConnectionHistoryProps {
  hub_connection_history: any[];
}

export const HubConnectionHistory: React.FC<
  React.PropsWithChildren<HubConnectionHistoryProps>
> = ({ hub_connection_history }) => {
  if (!hub_connection_history?.length) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleMargin}>
        <Typography type="title6">Connection History</Typography>
      </View>
      {hub_connection_history.map(
        ({ connection, connected_at, disconnected_at }, idx) => {
          const nextConnection =
            idx === 0
              ? new Date()
              : parseISO(hub_connection_history[idx - 1].connected_at);
          const currentDisconnect = parseISO(
            disconnected_at || new Date().toISOString()
          );

          const externalDiffInSeconds = isValid(nextConnection)
            ? intervalToDuration({
                start: nextConnection,
                end: currentDisconnect,
              })
            : null;

          const internalDiffInSeconds =
            isValid(currentDisconnect) && isValid(parseISO(connected_at))
              ? intervalToDuration({
                  start: parseISO(connected_at),
                  end: currentDisconnect,
                })
              : null;

          return disconnected_at ? (
            // If we have a disconnected_at timestamp, then we
            // display an offline row and an online row
            <View key={`${connected_at}-${idx}`}>
              <ConnectionRow
                isOfflineRow={true}
                connection={connection}
                connected_at={connected_at}
                disconnected_at={disconnected_at}
                idx={idx}
                internalDiffInSeconds={internalDiffInSeconds}
                externalDiffInSeconds={externalDiffInSeconds}
                nextConnectedAt={nextConnection}
              />
              <ConnectionRow
                isOfflineRow={false}
                connection={connection}
                connected_at={connected_at}
                disconnected_at={disconnected_at}
                idx={idx}
                internalDiffInSeconds={internalDiffInSeconds}
                externalDiffInSeconds={externalDiffInSeconds}
                nextConnectedAt={nextConnection}
              />
            </View>
          ) : (
            // If no disconnected_at then just display an online row
            <View key={`${connected_at}-${idx}`}>
              <ConnectionRow
                isOfflineRow={false}
                connection={connection}
                connected_at={connected_at}
                disconnected_at={disconnected_at}
                idx={idx}
                internalDiffInSeconds={internalDiffInSeconds}
                externalDiffInSeconds={externalDiffInSeconds}
                nextConnectedAt={nextConnection}
              />
            </View>
          );
        }
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxHeight: 150,
    overflowY: "scroll",
  },
  historyRow: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    marginRight: 2,
  },
  connectionType: { alignItems: "center", flexDirection: "row" },
  flexRow: { flexDirection: "row" },
  titleMargin: { marginBottom: 12 },
  leftMargin: { marginLeft: 8 },
});
