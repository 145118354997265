import * as React from "react";
import { View, StyleSheet } from "react-native";
import { useTheme } from "@smartrent/ui";

const _RowSeparator: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { colors } = useTheme();

  return <View style={[styles.rowRoot, { backgroundColor: colors.gray200 }]} />;
};

export const RowSeparator = React.memo(_RowSeparator) as React.FC;

const _CardSeparator: React.FC<React.PropsWithChildren<unknown>> = () => {
  return <View style={styles.cardRoot} />;
};

export const CardSeparator = React.memo(_CardSeparator) as React.FC;

const styles = StyleSheet.create({
  rowRoot: {
    height: StyleSheet.hairlineWidth,
    width: "100%",
  },
  cardRoot: {
    width: "100%",
    height: 16,
  },
});
