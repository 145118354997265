import * as React from "react";

import { HStack, Typography, TypographyProps, useTheme } from "@smartrent/ui";

import { ShieldDisabled } from "@smartrent/icons";

interface ErrorTitleProps {
  disabled?: boolean;
  value: TypographyProps["children"];
}
export const HubRefurbishErrorsListTitle: React.FC<
  React.PropsWithChildren<ErrorTitleProps>
> = ({ disabled, value }) => {
  const { colors } = useTheme();
  if (disabled) {
    return (
      <HStack spacing={8}>
        <ShieldDisabled color={colors.mutedBackgroundGrayscale} />
        <Typography
          type="title4"
          style={{ color: colors.mutedBackgroundGrayscale }}
        >
          No Errors Found
        </Typography>
      </HStack>
    );
  }
  return (
    <HStack align="center" spacing={8}>
      <Typography type="title3" style={{ color: colors.mutedTextWarning }}>
        {value}
      </Typography>
    </HStack>
  );
};
