import { useMemo } from "react";
import { StyleSheet } from "react-native";

import {
  Dialog,
  DialogAction,
  DialogContent,
  DialogTitle,
  FormikCheckbox,
  VStack,
} from "@smartrent/ui";

import { useAdvancedOptionsQuery } from "@/api";
import { useUser } from "@/layout/Context";

interface Props {
  onHide: null | (() => void);
  visible: boolean | null;
  values: any;
}

const AdvancedHubPrepModal = ({ visible, onHide, values }: Props) => {
  const { permissions } = useUser();
  const dialogActionText = useMemo(
    () => (values.resetZWave ? "Are You Sure?" : "Save"),
    [values]
  );

  const { data } = useAdvancedOptionsQuery(
    {},
    {
      enabled: permissions?.advanced_hub_troubleshooting === true,
    }
  );

  return (
    <>
      <Dialog
        visible={visible}
        onClose={onHide}
        titleID="dialog-title"
        descriptionID="dialog-desc"
      >
        <DialogContent>
          <VStack spacing={20} style={styles.dialogContent}>
            <DialogTitle style={styles.dialogTitle} nativeID="dialog-title">
              Advanced Hub Prep Settings
            </DialogTitle>
            {data?.actions.map((action) => {
              return (
                <FormikCheckbox
                  key={action.value}
                  name={action.value}
                  label={action.name}
                />
              );
            })}

            <DialogAction text={dialogActionText} onPress={onHide} />
          </VStack>
        </DialogContent>
      </Dialog>
    </>
  );
};

const styles = StyleSheet.create({
  dialogContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    maxWidth: 400,
  },
  dialogTitle: { textAlign: "center" },
});

export default AdvancedHubPrepModal;
