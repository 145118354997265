import {
  TouchableOpacity,
  StyleSheet,
  View,
  TouchableOpacityProps,
} from "react-native";

import { ActivityIndicator, Typography, useTheme } from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";

import { HubOnlineCircle } from "@/common/zipato/HubOnlineCircle";
import { ZipatoHubOnlineStatus } from "@/types";

interface HubPrepOnlineStatusProps
  extends Pick<TouchableOpacityProps, "disabled" | "onPress" | "style"> {
  isOnline: ZipatoHubOnlineStatus;
  isFetching: boolean;
}
export const HubPrepLogOnlineButton = ({
  disabled,
  onPress,
  isOnline,
  isFetching,
  style,
}: HubPrepOnlineStatusProps) => {
  const { colors } = useTheme();
  return (
    // The whole thing is a button
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[styles.root, style]}
    >
      {/* refresh icon */}
      <View style={styles.iconContainer}>
        <Refresh color={colors.gray600} size={16} />
      </View>
      {/* label */}
      <View style={styles.labelContainer}>
        <Typography style={[styles.label, { color: colors.gray600 }]}>
          Online:{" "}
        </Typography>
      </View>
      {/* status */}
      <View style={styles.flexRow}>
        {isFetching ? (
          <HubOnlineCircle style={styles.icon} isOnline={isOnline} />
        ) : (
          <ActivityIndicator size={24} />
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  flexRow: {
    flexDirection: "row",
    minWidth: 100,
  },

  iconContainer: {
    flexDirection: "row",
    marginHorizontal: 2,
    marginBottom: 2,
  },
  icon: { marginHorizontal: 2 },

  labelContainer: {
    flexDirection: "row",
    marginHorizontal: 2,
  },
  label: { fontSize: 14 },
});
