import { useState, useCallback, useContext } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { useHistory } from "react-router-dom";
import {
  Stack,
  Typography,
  Text,
  Box,
  useTheme,
  Button,
  HStack,
} from "@smartrent/ui";
import { Visible, Clipboard } from "@smartrent/icons";

import { VendorApplication } from "@/types";
import Context, { useUser } from "@/layout/Context";
import {
  useVendorApplicationSecretQuery,
  useDeleteVendorApplicationMutation,
} from "@/api";
import { useDialog } from "@/lib/contexts/dialog";

interface VendorApplicationDetailsProps {
  vendorApplication: VendorApplication;
}

export const VendorApplicationDetails: React.FC<
  React.PropsWithChildren<VendorApplicationDetailsProps>
> = ({ vendorApplication }) => {
  const { colors } = useTheme();
  const { setToast } = useContext(Context);
  const { permissions } = useUser();
  const { confirm } = useDialog();
  const history = useHistory();
  const [secret, setSecret] = useState(null);

  const [deleteVendorApplication] = useDeleteVendorApplicationMutation();
  const [getVendorApplicationSecret] = useVendorApplicationSecretQuery();

  const handleDeleteClicked = useCallback(async () => {
    const confirmed = await confirm({
      title: "Delete Vendor Application",
      confirmText: "Delete",
      description:
        "Are you sure you want to delete this Vendor Application? This will revoke access for the entire Organization.",
    });

    if (confirmed) {
      try {
        await deleteVendorApplication({
          organizationId: vendorApplication.organization_id,
          vendorApplicationId: vendorApplication.id,
        });
        history.push(`/organizations/${vendorApplication.organization_id}`);
      } catch (error) {
        return;
      }
    }
  }, [
    confirm,
    deleteVendorApplication,
    history,
    vendorApplication?.id,
    vendorApplication?.organization_id,
  ]);

  const handleViewSecretClicked = useCallback(async () => {
    const { data } = await getVendorApplicationSecret({
      organizationId: vendorApplication.organization_id,
      vendorApplicationId: vendorApplication.id,
    });

    if (data?.secret) {
      setSecret(data.secret);
    }
  }, [
    getVendorApplicationSecret,
    vendorApplication?.id,
    vendorApplication?.organization_id,
  ]);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(secret)
      .then(() => {
        setToast({
          type: "success",
          message: "Text copied to clipboard",
        });
      })
      .catch((error) => {
        setToast({
          type: "error",
          message: `Could not copy text, ${error}`,
        });
      });
  }, [secret, setToast]);

  return (
    <Box style={styles.box}>
      <Stack direction="column">
        <HStack
          flexChildren
          style={[
            styles.header,
            {
              backgroundColor: colors.gray050,
              borderBottomColor: colors.gray200,
            },
          ]}
        >
          <Typography type="title">{vendorApplication.name}</Typography>
          {permissions.modify_vendor_applications ? (
            <Button
              style={[styles.deleteIcon, { backgroundColor: colors.error }]}
              onPress={handleDeleteClicked}
            >
              Delete Vendor
            </Button>
          ) : null}
        </HStack>
        <View style={styles.detailsContainer}>
          <Stack direction="row" align="center">
            <Typography type="title5">Key: </Typography>
            <Text>{vendorApplication.key}</Text>
          </Stack>
          <Stack direction="row" align="center">
            <Typography type="title5">Max Codes Per Unit: </Typography>
            <Text>{vendorApplication.max_codes_per_unit.toLocaleString()}</Text>
          </Stack>
          {permissions.modify_vendor_applications ? (
            <Stack direction="row" align="center" spacing={10}>
              <Typography type="title5">Secret: </Typography>
              {!!secret ? (
                <>
                  <Text>{secret}</Text>
                  <TouchableOpacity onPress={handleCopyToClipboard}>
                    <Clipboard size={24} color={colors.textSecondary} />
                  </TouchableOpacity>
                </>
              ) : (
                <>
                  <Text>********</Text>
                  <TouchableOpacity onPress={handleViewSecretClicked}>
                    <Visible size={24} color={colors.textSecondary} />
                  </TouchableOpacity>
                </>
              )}
            </Stack>
          ) : null}
        </View>
      </Stack>
    </Box>
  );
};

const styles = StyleSheet.create({
  detailsContainer: {
    padding: 16,
    alignItems: "flex-start",
  },
  deleteIcon: {
    alignSelf: "flex-end",
  },
  box: {
    padding: 0,
  },
  header: {
    padding: 16,
    borderBottomWidth: 1,
  },
});
