import { View } from "react-native";

import { HStack, Tooltip, useTheme, Typography } from "@smartrent/ui";

import { InformationSolid } from "@smartrent/icons";

import { EM_DASH } from "@/utils/chars";
import { ScopeOfWorkDeviceProps } from "@/types";
import { ProductPrice } from "@/api";

import { SowListItem } from "./SowListItem";

export const SowDevicePriceItem = ({
  device,
  loading,
  productPrice,
}: {
  device: ScopeOfWorkDeviceProps;
  loading: boolean;
  productPrice?: ProductPrice;
}) => {
  const { colors } = useTheme();
  if (loading || !productPrice) {
    return null;
  }

  return (
    <HStack spacing={32}>
      <HStack spacing={4} align="start">
        <SowListItem
          label={
            <Tooltip name="product_price" title={productPrice.type}>
              <View>
                <HStack align="center" spacing={4}>
                  <Typography type="captionLarge" color="textSecondary">
                    PRICE (EACH)
                  </Typography>
                  <InformationSolid
                    color={
                      productPrice.type === "Contracted Price"
                        ? colors.warning
                        : colors.primary
                    }
                    size={16}
                  />
                </HStack>
              </View>
            </Tooltip>
          }
          value={productPrice.price ? `$${productPrice.price}` : EM_DASH}
        />
      </HStack>
      <SowListItem
        label="Total Price:"
        value={
          productPrice.price && device?.quantity
            ? `$${(productPrice.price * device?.quantity).toLocaleString()}`
            : EM_DASH
        }
      />
    </HStack>
  );
};
