import { useQueryCompat, useReducedInfiniteQuery } from "@smartrent/hooks";

import { InstallJobProps } from "@smartrent/install";

import { QueryKey } from "@tanstack/react-query";

import { JobCommentProps, ListQueryResponse } from "@/types";
import { DEALER_API_RECORDS_LIMIT } from "@/utils";
import { instance, reactQueryInstance } from "@/lib/hooks";

interface FetchJobFilterProps {
  name?: string;
  project_id?: string;
  status?: string;
  unit_name?: string;
  page?: number;
  flagged?: boolean;
}

export type InstallJobPropsWithFlags = {
  open_flags_count: number;
} & InstallJobProps;

export type FetchJobsQueryKey = [
  string,
  Record<string, unknown>,
  FetchJobFilterProps,
];

export const fetchJobs = async ({
  queryKey,
}: {
  queryKey: FetchJobsQueryKey | QueryKey;
}) => {
  const [, , filters] = queryKey as FetchJobsQueryKey;

  const response = await instance.get<
    ListQueryResponse<InstallJobPropsWithFlags>
  >("jobs", {
    params: {
      limit: DEALER_API_RECORDS_LIMIT,
      ...filters,
    },
  });

  return response.data;
};

export const useJobsQuery = (filters: Record<string | number, any>) =>
  useReducedInfiniteQuery(
    ["jobs", filters],
    async ({ pageParam }) => {
      const response = await instance.get(`/jobs`, {
        params: {
          page: pageParam,
          ...filters,
        },
      });

      return response.data;
    },
    {
      enabled: !!filters,
    }
  );

export const searchProjectJobs = (
  _key: string,
  filters?: Record<string | number, any>,
  page = 1
) =>
  reactQueryInstance.get(`/jobs`, {
    params: {
      page,
      ...filters,
    },
  });

export const getJob = async (jobId: string) => {
  const response = await instance.get(`/jobs/${jobId}`);
  return response.data;
};

export const useGetJob = (jobId: string) =>
  // @ts-ignore - react-query types are not up to date
  useQueryCompat(["job", jobId], () => getJob(jobId));

export const getJobComments = async (jobId: string, page: number = 1) => {
  const response = await instance.get<ListQueryResponse<JobCommentProps>>(
    `/jobs/${jobId}/comments`,
    {
      params: {
        page,
      },
    }
  );

  return response.data;
};

export interface DownloadJobReportResponse {
  data: {
    link: string | null;
    message: string | null;
    state: "pending" | "complete" | "failed";
  };
}

export interface DownloadJobReportOptions {
  groupId: string | number;
}
