import { useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { useMutationCompat, useQueryCompat } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

export type ImportType = "parking-sensors";

export const useGroupImportsQuery = (
  groupId: number,
  type: ImportType,
  options?: UseQueryOptions<any>
) => {
  return useQueryCompat(
    ["imports", { groupId, type }],
    async (queryKey: "imports", { groupId, type }) => {
      const response = await instance.get(`/groups/${groupId}/imports/${type}`);

      return response.data;
    },
    options
  );
};

interface ImportPniParkingSensorsMutationOptions {
  groupId: number;
  csvString: string;
}

export const useImportPniParkingSensorsMutation = () => {
  const queryClient = useQueryClient();

  return useMutationCompat(
    async ({ groupId, csvString }: ImportPniParkingSensorsMutationOptions) => {
      await instance.post(`/groups/${groupId}/imports/parking-sensors`, {
        import: csvString,
      });
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => {
            if (!Array.isArray(queryKey)) {
              return false;
            }

            return queryKey[0] === "imports";
          },
        });
      },
    }
  );
};
