import { useCallback } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import { AxiosResponse } from "axios";

import {
  Form,
  Button,
  DrawerActions,
  DrawerContent,
  DrawerHeader,
  FormikSubmit,
  FormSection,
  FormikTextInputField,
  FormikIntlPhoneInputField,
  FormikSwitch,
  Typography,
} from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";
import { useCreateOrganizationMutation } from "@/api";
import { useGlobalContext } from "@/layout/Context";
import { OrganizationProps, SolicitError } from "@/types";
import { Countries } from "@/utils/countries";

import { OrganizationForm, validationSchema } from "../OrganizationForm";

interface CreateOrganizationProps extends OrganizationProps {
  create_org_admin_user: boolean;
  org_admin_first_name?: string;
  org_admin_last_name?: string;
  org_admin_email_address?: string;
  org_admin_phone_number?: string;
  create_alloy_install_account: boolean;
  use_org_admin_as_alloy_install_admin: boolean;
  alloy_install_admin_first_name?: string;
  alloy_install_admin_last_name?: string;
  alloy_install_admin_email_address?: string;
  alloy_install_admin_phone_number?: string;
  country: Countries;
  state: string;
  street_address_1: string;
  street_address_2: string;
  zip: number;
}

const initialValues: Partial<CreateOrganizationProps> = {
  name: "",
  url: "",
  country: "United States",
  state: "",
  street_address_1: "",
  street_address_2: "",
  zip: null,
  create_org_admin_user: false,
  org_admin_first_name: "",
  org_admin_last_name: "",
  org_admin_email_address: "",
  org_admin_phone_number: "",
  create_alloy_install_account: false,
  use_org_admin_as_alloy_install_admin: true,
  alloy_install_admin_first_name: "",
  alloy_install_admin_last_name: "",
  alloy_install_admin_phone_number: "",
  alloy_install_admin_email_address: "",
};

export const CreateOrganization: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { pop } = useDrawerNav();

  const { history, setToast } = useGlobalContext();

  const [createOrganizationMutation] = useCreateOrganizationMutation();

  const handleCancel = useCallback(() => pop(), [pop]);

  const handleSubmit = useCallback(
    async (
      values: Partial<CreateOrganizationProps>,
      actions: FormikHelpers<Partial<CreateOrganizationProps>>
    ) => {
      actions.setSubmitting(true);
      try {
        (await createOrganizationMutation(values)) as AxiosResponse;

        pop();
      } catch (error) {
        // try to display inline form validations using formik
        const solicitErrors = (error as any)?.response?.data?.errors;
        if (solicitErrors && solicitErrors.length) {
          solicitErrors.forEach(({ field, description }: SolicitError) =>
            actions.setFieldError(field, description)
          );
        } else {
          pop();
          console.error(error);
          setToast({
            type: "error",
            title: "Error",
            message: "Something went wrong. Please contact engineering.",
          });
        }
      }
      actions.setSubmitting(false);
    },
    [createOrganizationMutation, pop, setToast, history]
  );

  return (
    <>
      <DrawerHeader title="Create Organization" />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <>
            <DrawerContent contentContainerStyle={styles.drawerContent}>
              <Form>
                <OrganizationForm
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <FormSection
                  title="Organization Admin"
                  style={styles.orgAdminSection}
                >
                  <FormikSwitch
                    name="create_org_admin_user"
                    label="Create Organization Admin User?"
                  />
                  {values.create_org_admin_user ? (
                    <NewUserFields
                      first="org_admin_first_name"
                      last="org_admin_last_name"
                      email="org_admin_email_address"
                      phone="org_admin_phone_number"
                    />
                  ) : null}
                </FormSection>
                <FormSection title="Alloy Install">
                  <FormikSwitch
                    name="create_alloy_install_account"
                    label="Create Alloy Install Account?"
                  />
                  {values.create_alloy_install_account ? (
                    values.create_org_admin_user ? (
                      <>
                        <FormikSwitch
                          name="use_org_admin_as_alloy_install_admin"
                          label="Use Org Admin as Alloy Install Admin"
                        />
                        {!values.use_org_admin_as_alloy_install_admin ? (
                          <AlloyInstallUserFields />
                        ) : null}
                      </>
                    ) : (
                      <AlloyInstallUserFields />
                    )
                  ) : null}
                </FormSection>
              </Form>
            </DrawerContent>
            <DrawerActions>
              <Button onPress={handleCancel} variation="plain">
                Cancel
              </Button>
              <FormikSubmit label="Save" submittingLabel="Saving..." />
            </DrawerActions>
          </>
        )}
      </Formik>
    </>
  );
};

const NewUserFields = ({
  first,
  last,
  email,
  phone,
  title,
}: {
  first: string;
  last: string;
  email: string;
  phone: string;
  title?: string;
}) => (
  <>
    {title ? (
      <Typography type="captionLarge" color="labelText">
        {title}
      </Typography>
    ) : null}
    <FormikTextInputField name={first} label="First Name" required={true} />
    <FormikTextInputField name={last} label="Last Name" required={true} />
    <FormikTextInputField name={email} label="Email Address" required={true} />
    <FormikIntlPhoneInputField
      name={phone}
      label="Phone Number"
      required={true}
    />
  </>
);

const AlloyInstallUserFields = () => (
  <NewUserFields
    title="Alloy Install Admin"
    first="alloy_install_admin_first_name"
    last="alloy_install_admin_last_name"
    email="alloy_install_admin_email_address"
    phone="alloy_install_admin_phone_number"
  />
);

const styles = StyleSheet.create({
  drawerContent: { paddingTop: 16 },
  orgAdminSection: { paddingBottom: 16 },
});
