import * as React from "react";
import { StyleSheet } from "react-native";

import {
  Link,
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderTitle,
  Stack,
  Typography,
  VStack,
} from "@smartrent/ui";

import { useUser } from "@/layout/Context";
import { useGroupCommunityWifi } from "@/api";

import { useGroup } from "../GroupContext";

export const GroupWifiCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { permissions } = useUser();
  const { group } = useGroup();
  const { data } = useGroupCommunityWifi(group.id, {
    enabled: !!group?.id && !!permissions.manage_community_wifi,
  });

  if (!permissions.manage_community_wifi || !data?.enabled) {
    return null;
  }

  return (
    <>
      <Panel>
        <PanelHeader>
          <PanelHeaderTitle title="Property Community Wifi" />
        </PanelHeader>
        <PanelBody>
          <VStack spacing={8}>
            <Typography type="caption" color="labelText">
              Resident Network Name
            </Typography>
            <Typography type="title4">{data.ssid}</Typography>
            <Stack align="center">
              <Link
                type="bodyLargeSemibold"
                style={styles.link}
                href={`${group.id}/manage-wifi`}
              >
                MANAGE ACCESS POINTS
              </Link>
            </Stack>
          </VStack>
        </PanelBody>
      </Panel>
    </>
  );
};

const styles = StyleSheet.create({
  link: { fontSize: 16 },
});
