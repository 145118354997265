import { instance } from "@/lib/hooks";

/** Central Station User Application Metadata */
export interface UserApplicationMetadata {
  /** ui friendly name of the application */
  label: string;
  subtitle: string;
  icon_name: string;
  tags?: string[];
  is_mobile_app?: boolean;
}

/** Central Station User Application */
export interface UserApplication {
  /** id from the user record of the application */
  user_id: string | null;
  /** code friendly name of the application */
  name: string;
  /** metadata for the application */
  meta?: UserApplicationMetadata;
}

export interface FetchAppsResponse {
  /** umbrella user's id */
  id: string;
  /** list of all available applications */
  applications: UserApplication[];
}

/** FetchAppsFn
 * @returns Promise<FetchAppsResponse>
 * @example
 * const fetchApps: FetchAppsFn = async () => {
 *  const response = await fetch('/api/v1/my-applications');
 *  return response.json();
 * }
 */
export type FetchAppsFn = () => Promise<FetchAppsResponse>;

/** OnPressAppFn
 * @param appName - `community_manager`, `central_station`, etc.
 * @param path - optional path to redirect to such as `/manager/groups`
 * @returns Promise<string>
 * @example
 * const onPressApp: OnPressAppFn = async (app) => {
 *  let response;
 *  if (app.user_id) {
 *    response = await fetch(`/api/v1/my-applications/redirect/${app.name}`);
 *  } else {
 *    response = await fetch(`/api/v1/my-applications/cs-login`);
 *  }
 *  const data = response.json();
 *  return data.redirect_url;
 * }
 */
export type OnPressAppFn = (appName: string, path?: string) => Promise<string>;
export interface NavMenuProps {
  fetchApps: FetchAppsFn;
  onPressApp: OnPressAppFn;
  onPressViewAll: OnPressAppFn;
  viewAllInNewTab?: boolean;
}

interface GetApplicationRedirectLinkResponse {
  redirect_uri: string;
}

export const fetchApps = async (): Promise<FetchAppsResponse> => {
  const { data } = await instance.get<FetchAppsResponse>("my-applications");

  return data;
};

export const onPressApp: OnPressAppFn = async (appName, path) => {
  let url = `my-applications/redirect/${appName}`;

  // This is an optional redirect path that can be specified
  if (path) {
    url += `?path=${path}`;
  }

  const { data } = await instance.get<GetApplicationRedirectLinkResponse>(url);

  return data.redirect_uri;
};
