import { ComponentType, useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import { useParams, useRouteMatch } from "react-router-dom";

import {
  ConfirmDialog,
  ActivityIndicator,
  Button,
  HStack,
  Typography,
  VStack,
  StatusBadge,
} from "@smartrent/ui";

import {
  BulbOff,
  Thermostat,
  Lock,
  Outlet,
  Hub,
  Sensor,
  SmartHome,
  Pencil,
  Trash,
  IconProps,
} from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { Accordion } from "@/common/Accordion";

import {
  ScopeOfWorkDeviceProps,
  ScopeOfWorkProps,
  ScopeOfWorkStatus,
} from "@/types";

import { useGlobalContext } from "@/layout/Context";

import {
  useScopeOfWorkDeleteDeviceCategoryMutation,
  useScopeOfWorkProductsPriceQuery,
} from "@/api";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";

import { ScopeOfWorkDevice } from "./SowDevice";

const iconToShow = (category: string) => {
  switch (category) {
    case "Light Switches":
      return <BulbOff />;
    case "Thermostat":
      return <Thermostat />;
    case "Locks":
      return <Lock />;
    case "Outlet":
      return <Outlet />;
    case "Hub":
      return <Hub />;
    case "Sensor":
      return <Sensor />;
    case "Other":
      return <SmartHome />;
    default:
      return <SmartHome />;
  }
};

export const ScopeOfWorkDevices: React.FC<
  React.PropsWithChildren<{
    category: ScopeOfWorkDeviceProps["sf_device_category"];
    devices: ScopeOfWorkDeviceProps[];
    opportunity: ScopeOfWorkProps["sf_opportunity_id"];
  }>
> = ({ category, devices, opportunity }) => {
  const { user, history } = useGlobalContext();
  const { url } = useRouteMatch();
  const { scopeOfWorkId } = useParams<{ scopeOfWorkId: string }>();
  const { visible, onOpen, onClose } = useModalState();

  const { data: productPriceData, isLoading } =
    useScopeOfWorkProductsPriceQuery(
      {
        productIds: devices.map((device) => device.sf_product_id),
        opportunityId: opportunity || "",
        category,
        scopeOfWorkId: scopeOfWorkId,
      },
      { enabled: !!opportunity && !!devices.length }
    );

  const {
    scopeOfWorkQuery: { data: scopeOfWork },
  } = useScopeOfWorkContext();

  const [
    deleteScopeOfWorkDeviceCategoryMutation,
    { isLoading: deletingDevices },
  ] = useScopeOfWorkDeleteDeviceCategoryMutation();

  const deleteDevices = useCallback(() => {
    deleteScopeOfWorkDeviceCategoryMutation({
      scope_of_work_id: scopeOfWork?.id,
      device_category: category,
      current_order: scopeOfWork?.scope_of_work_devices_order,
    });
    onClose();
  }, [
    category,
    scopeOfWork?.id,
    scopeOfWork?.scope_of_work_devices_order,
    deleteScopeOfWorkDeviceCategoryMutation,
    onClose,
  ]);

  const addDeviceButtonIsDisplayed = useMemo<boolean>(
    () =>
      !!(
        scopeOfWork?.status &&
        scopeOfWork.status !== ScopeOfWorkStatus.ReadyForReview &&
        scopeOfWork.status !== ScopeOfWorkStatus.Syncing &&
        scopeOfWork.status !== ScopeOfWorkStatus.Completed &&
        user?.permissions?.manage_sow
      ),
    [scopeOfWork?.status, user?.permissions?.manage_sow]
  );

  const missingQuantityFlag = useMemo<boolean>(
    () =>
      scopeOfWork?.scope_of_work_devices?.some(
        (device) => device.sf_device_category === category && !device.quantity
      ) || false,
    [category, scopeOfWork?.scope_of_work_devices]
  );

  const missingDeviceTypeFlag = useMemo<boolean>(
    () =>
      scopeOfWork?.scope_of_work_devices?.some(
        (device) =>
          device.sf_device_category === category && !device.sf_device_sku
      ) || false,
    [category, scopeOfWork?.scope_of_work_devices]
  );

  const hasRevisionNotesFlag = useMemo<boolean>(
    () =>
      scopeOfWork?.scope_of_work_devices?.some(
        (device) =>
          device.sf_device_category === category &&
          device.scope_of_work_revision?.length > 0
      ) || false,
    [category, scopeOfWork?.scope_of_work_devices]
  );

  return (
    <>
      <Accordion
        initialOpenState={false}
        style={styles.accordion}
        title={
          <HStack spacing={8} style={styles.header} justify="space-between">
            <HStack align="center" spacing={8}>
              {iconToShow(category)}
              <Typography type="title2">{category}</Typography>
              {missingQuantityFlag ? (
                <StatusBadge
                  variation="outlined"
                  status="default"
                  size="semibold.title.five"
                >
                  Missing Quantity
                </StatusBadge>
              ) : null}
              {missingDeviceTypeFlag ? (
                <StatusBadge
                  variation="outlined"
                  status="knowledge"
                  size="semibold.title.five"
                >
                  Missing Device Type
                </StatusBadge>
              ) : null}
              {hasRevisionNotesFlag ? (
                <StatusBadge
                  variation="outlined"
                  status="warning"
                  size="semibold.title.five"
                >
                  Revision Notes
                </StatusBadge>
              ) : null}
            </HStack>
            {addDeviceButtonIsDisplayed ? (
              <HStack spacing={16}>
                <Button
                  iconLeft={Pencil}
                  onPress={() =>
                    history.push(`${url}/add/${category.toLowerCase()}`)
                  }
                  variation="muted"
                  size="x-small"
                >
                  Edit
                </Button>
                <Button
                  variation="outlined"
                  iconLeft={
                    deletingDevices
                      ? (ActivityIndicator as ComponentType<
                          React.PropsWithChildren<IconProps>
                        >)
                      : Trash
                  }
                  onPress={() => onOpen()}
                  color="destructive"
                  size="x-small"
                >
                  {deletingDevices ? "Deleting" : "Delete"}
                </Button>
              </HStack>
            ) : null}
          </HStack>
        }
      >
        <VStack spacing={16}>
          {devices.map((device) => {
            return (
              <ScopeOfWorkDevice
                key={device.id}
                device={device}
                isPriceLoading={isLoading}
                productPrice={productPriceData?.find(
                  (price) => price.id === device.sf_product_id
                )}
              />
            );
          })}
        </VStack>
      </Accordion>
      <ConfirmDialog
        visible={visible}
        onConfirm={deleteDevices}
        onClose={onClose}
        title="Are you sure?"
        description="This action will delete all devices in this category. Do you want to continue?"
        confirmText={deletingDevices ? "Deleting" : "Confirm"}
      />
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
    flex: 1,
  },
  accordion: {
    width: "100%",
    flex: 1,
  },
});
