import { useCallback } from "react";
import { View, StyleSheet } from "react-native";
import { startCase } from "lodash-es";
import {
  Typography,
  StatusBadge,
  useTheme,
  HStack,
  VStack,
  Tooltip,
} from "@smartrent/ui";
import {
  CheckCircle,
  XCircle,
  Person,
  QuestionCircleBorder,
} from "@smartrent/icons";
import * as Flags from "@smartrent/flags";

import { LoginActivity } from "@/api/users";

import Helpers from "@/lib/helpers";

import { countryCodesToNames } from "@/utils";

import { FlagIcon } from "../../common/icons/FlagIcon";

export const LoginActivityLineItem = ({
  loginActivityEvent,
}: {
  loginActivityEvent: LoginActivity;
}) => {
  const { colors } = useTheme();

  const convertStatus = useCallback((status: any) => {
    switch (status) {
      case "log-in":
      case "magic-link-log-in":
        return "knowledge";
      case "log-in-denied":
        return "error";
      case "password-reset":
        return "success";
      default:
        return "default";
    }
  }, []);

  const countryCodeForFlag =
    countryCodesToNames[loginActivityEvent.country_code?.toUpperCase()];

  const Flag = (Flags as any)[countryCodeForFlag];

  return (
    <View style={styles.padding}>
      <View style={styles.label}>
        <Typography type="title4" color="primary">
          {Helpers.formatDate(loginActivityEvent?.inserted_at)}
        </Typography>
        <StatusBadge
          status={convertStatus(loginActivityEvent.verb)}
          variation="outlined"
          size="semibold.title.five"
        >
          {startCase(loginActivityEvent.verb)}
        </StatusBadge>
      </View>
      <View style={styles.content}>
        <HStack style={styles.iconRow}>
          <VStack style={styles.iconAlignCenter}>
            <Typography>Logged In?</Typography>
            <Tooltip
              title={
                loginActivityEvent.was_the_user_authenticated
                  ? "User was authenticated at the time of this action."
                  : "User was not authenticated at the time of this action."
              }
              name="user-authenticated"
              placement="right"
            >
              <View>
                {loginActivityEvent.was_the_user_authenticated ? (
                  <CheckCircle color={colors.success} />
                ) : (
                  <XCircle color={colors.error} />
                )}
              </View>
            </Tooltip>
          </VStack>

          <VStack style={styles.iconAlignCenter}>
            <Typography color="textPrimary">User Agent: </Typography>
            <Tooltip
              title={loginActivityEvent.user_agent}
              name="user-agent"
              placement="right"
            >
              <View>
                <Person />
              </View>
            </Tooltip>
          </VStack>
          <VStack style={styles.iconAlignCenter}>
            <Typography color="textPrimary">Country:</Typography>
            <Tooltip
              title={
                loginActivityEvent.country_code
                  ? countryCodesToNames[
                      loginActivityEvent.country_code?.toUpperCase()
                    ]
                  : "Country not found"
              }
              name="country-code"
              placement="right"
            >
              <Typography color="textSecondary">
                {Flag ? <FlagIcon Flag={Flag} /> : <QuestionCircleBorder />}
              </Typography>
            </Tooltip>
          </VStack>
          <VStack style={styles.iconAlignCenter}>
            <Typography color="textPrimary">OS:</Typography>
            <Typography color="textSecondary">
              {loginActivityEvent.device_operating_system}
            </Typography>
          </VStack>
        </HStack>
        <VStack>
          <Typography color="textPrimary">
            Requested URL:{" "}
            <Typography color="textSecondary">
              {loginActivityEvent.requested_url}
            </Typography>
          </Typography>
        </VStack>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  padding: { padding: 8 },
  iconRow: {
    justifyContent: "space-between",
    marginVertical: 8,
  },
  iconAlignCenter: { alignItems: "center" },
  label: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 8,
  },
  content: { marginLeft: 8 },
});
