import { useContext, useState } from "react";
import { View, Text } from "react-native";
import { Bar } from "react-chartjs-2";

import { map, groupBy, first, sumBy } from "lodash-es";

import moment from "moment";

import Graph from "@/common/Graph";
import HoverText from "@/common/HoverText";
import ReactSelect from "@/common/ReactSelect";
import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

export default function PopularTourTimes() {
  const { isDesktop } = useContext(Context);
  const [dayFilter, setDayFilter] = useState(null);
  const [popularTourCount, setPopularTourCount] = useState(0);

  // When the user selects all days option from dropdown, we need to aggregate and sum
  // the counts for the time of day for tour for all days
  const allDaysAggregate = (popularTourTimes) => {
    return map(
      // Group tour times together by the time
      groupBy(popularTourTimes, (tourTime) => tourTime.truncated_time),
      // Gets an aggregate count of the number of tours by the time
      (tours) => ({
        truncated_time: first(tours).truncated_time,
        count: sumBy(tours, "count"),
      })
    );
  };

  // Filters out the times based on day of week dropdown selection
  const filterTimes = (popularTourTimes) => {
    const filteredTimes = (
      dayFilter || dayFilter === 0
        ? popularTourTimes.filter((record) => record.day_of_week === dayFilter)
        : allDaysAggregate(popularTourTimes)
    ).map((record) => {
      const formattedTime = moment(record.truncated_time, "hh:mm:ss")
        .format("hh:mm a")
        .replace(/^0(?:0:0?)?/, "");
      return { ...record, truncated_time: formattedTime };
    });
    setPopularTourCount(
      filteredTimes.reduce((acc, curr) => acc + curr.count, 0)
    );
    return filteredTimes;
  };

  return (
    <Graph
      path="/reports/tours"
      dataKey="tours"
      leftFooterRenderProps={({ tourCount }) => {
        return (
          <div>
            <div>
              <span className="u-sans-bold">Total Tours</span>:{" "}
              {popularTourCount.toLocaleString()}
            </div>
          </div>
        );
      }}
      renderProps={({ popularTourTimes }) => {
        const filteredTimes = filterTimes(popularTourTimes);
        const truncatedTimes = filteredTimes.map(
          (tourTime) => tourTime.truncated_time
        );

        const timeOfDay: string[] = Array.from(new Set(truncatedTimes));
        // Initializes the data set based on the counts given in popularTourTimes
        let dataSet = {};
        dataSet = filteredTimes.map(
          (tourTime) => (dataSet[tourTime.truncated_time] = tourTime.count)
        );
        // Converts dataset to an array for use in graph
        const graphData: number[] = Object.values(dataSet);
        // Gets the maximum value from the data for use in the graph scale
        const dataMax = Math.max(...graphData);
        // Options for the day filter drop down
        const options = [
          { label: "All Days", value: null },
          { label: "Sunday", value: 0 },
          { label: "Monday", value: 1 },
          { label: "Tuesday", value: 2 },
          { label: "Wednesday", value: 3 },
          { label: "Thursday", value: 4 },
          { label: "Friday", value: 5 },
          { label: "Saturday", value: 6 },
        ];
        return (
          <View>
            {isDesktop && (
              <View style={{ alignItems: "center" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ fontWeight: "600", marginRight: 8 }}>
                      Popular Times to Tour
                    </Text>
                    <HoverText
                      color="black"
                      tooltipText="Based on finalized and unfinalized tours"
                      tooltipDirection="right"
                      width={200}
                    />
                  </View>
                  <ReactSelect
                    onChange={(newValue: any) => setDayFilter(newValue.value)}
                    options={options}
                    defaultValue={{ label: "All Days", value: null }}
                  />
                </View>
                <Bar
                  height={50}
                  data={{
                    labels: timeOfDay,
                    datasets: [
                      {
                        data: graphData,
                        label: "Tours",
                        backgroundColor: Helpers.lineGraphColors.lightBlue,
                        borderColor: Helpers.lineGraphColors.lightBlue,
                        pointBorderColor: Helpers.lineGraphColors.lightBlue,
                        pointHoverBackgroundColor:
                          Helpers.lineGraphColors.lightBlue,
                        pointHoverBorderColor:
                          Helpers.lineGraphColors.lightBlue,
                      },
                    ],
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            min: 0,
                            max: dataMax,
                            stepSize: 100,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "Tours",
                          },
                        },
                      ],
                      xAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                            labelString: "Time of Day",
                          },
                        },
                      ],
                    },
                    title: {
                      display: false,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const label = `Number of Tours: ${tooltipItem.yLabel}`;
                          return label;
                        },
                      },
                      title: null,
                    },
                  }}
                />
              </View>
            )}
          </View>
        );
      }}
    />
  );
}
