import * as React from "react";
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";

import { ExclamationCircleOutline } from "@smartrent/icons";
import { Text, useTheme } from "@smartrent/ui";

export interface NoRecordsProps {
  title?: string;
  text?: string;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
}

export const NoRecords: React.FC<React.PropsWithChildren<NoRecordsProps>> = ({
  title = "No Records Found",
  text,
  style,
  titleStyle,
}) => {
  const { colors } = useTheme();

  return (
    <View style={[styles.root, style]}>
      <ExclamationCircleOutline size={72} color={colors.primary} />
      <Text type="title" style={[titleStyle, { color: colors.primary }]}>
        {title}
      </Text>
      {text ? <Text>{text}</Text> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 48,
  },
});
