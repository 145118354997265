import { StyleSheet, View } from "react-native";
import { filter } from "lodash-es";

import { DeviceProps } from "../../types";

import Device from "./Device";
import { useUnit } from "./UnitContext";

interface DevicesProps {
  devices?: DeviceProps[];
}

export default function Devices({ devices }: DevicesProps) {
  const { unit, deviceAttributes, accessCodes, organization } = useUnit();

  const elements = devices?.map((device) => {
    const attributes = filter(deviceAttributes, (da) => {
      return da.device_id === device.id && !da.name.includes("NOT_MAPPED");
    });

    const unmappedAttributes = filter(deviceAttributes, (da) => {
      return da.device_id === device.id && da.name.includes("NOT_MAPPED");
    });

    return (
      <View style={styles.padding} key={device.id}>
        <Device
          accessCodes={accessCodes}
          device={device}
          attributes={attributes}
          unmappedAttributes={unmappedAttributes}
          unit={unit}
          organization={organization}
        />
      </View>
    );
  });

  return <>{elements}</>;
}

const styles = StyleSheet.create({
  padding: { padding: 8 },
});
