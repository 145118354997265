import { useEffect, useCallback, useState } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Typography, Button, useTheme } from "@smartrent/ui";

export interface HubDebugTitleBarProps {
  title: string;
  isLoading?: boolean;
  onReloadClicked?: () => void;
}

export const HubDebugTitleBar: React.FC<
  React.PropsWithChildren<HubDebugTitleBarProps>
> = ({ title, isLoading = false, onReloadClicked }) => {
  const { colors } = useTheme();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      window.setTimeout(() => {
        setButtonDisabled(false);
      }, 1500);
    }
  }, [isLoading]);

  const onClickRefresh = useCallback(() => {
    setButtonDisabled(true);
    onReloadClicked();
  }, [onReloadClicked, setButtonDisabled]);

  return (
    <View style={[styles.container, { borderBottomColor: colors.border }]}>
      <Typography type="title3">{title}</Typography>
      {onReloadClicked ? (
        <Button
          size="small"
          disabled={isLoading || buttonDisabled}
          onPress={onClickRefresh}
        >
          Refresh
        </Button>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 8,
    borderBottomWidth: 1,
  },
});
