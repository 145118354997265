import { QueryClient, useQuery } from "@tanstack/react-query";

import { useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { ParkingSensor, ParkingSensorEvent } from "@/types";
import { createAxiosMutation, invalidateQueries } from "@/hooks/react-query";
import { getErrorMessage } from "@/lib/axios-helpers";

import { ParkingQueryKeys } from "./parking-query-keys";

interface SensorPaginatedResult {
  records: ParkingSensor[];
  total_records: number;
  total_pages: number;
  current_page: number;
}

interface SensorListFilters {
  page: number;
  property_id: string;
  dev_eui?: string;
  assigned?: boolean;
  space_id?: string;
  active?: boolean;
  limit?: number;
  dir?: "asc" | "desc";
}

interface SensorEventListFilters {
  property_id?: string;
  space_id?: string;
  limit?: number;
  dir?: "asc" | "desc";
}

interface SensorEventPaginatedResult {
  records: ParkingSensorEvent[];
  total_records: number;
  total_pages: number;
  current_page: number;
}

type UpdateSensorOptions = Omit<Partial<ParkingSensor>, "id"> &
  Pick<ParkingSensor, "id">;

export const useSensorsPaginatedQuery = (filters: SensorListFilters) =>
  useQuery<SensorPaginatedResult>(
    [ParkingQueryKeys.Sensors, filters],
    async () => {
      const { data } = await instance.get("/parking/sensors", {
        params: { ...filters },
      });
      return data;
    },
    {
      enabled: !!filters.property_id,
      keepPreviousData: true,
    }
  );

export const useSensorsInfiniteQuery = (
  filters: Omit<SensorListFilters, "page">
) => {
  return useReducedInfiniteQuery<SensorPaginatedResult>(
    [
      ParkingQueryKeys.Sensors,
      {
        ...filters,
      },
    ],
    async ({ pageParam }) => {
      const { data } = await instance.get("/parking/sensors", {
        params: { ...filters, page: pageParam },
      });
      return data;
    }
  );
};

export const useSensorEventsInfiniteQuery = (
  sensorId: string,
  filters: SensorEventListFilters
) => {
  return useReducedInfiniteQuery<SensorEventPaginatedResult>(
    [
      ParkingQueryKeys.SensorEvents,
      sensorId,
      {
        ...filters,
      },
    ],
    async ({ pageParam }) => {
      const { data } = await instance.get(
        `/parking/sensors/${sensorId}/events`,
        {
          params: { ...filters, page: pageParam },
        }
      );
      return data;
    }
  );
};

export const useSensorQuery = (sensorId: string) =>
  useQuery<ParkingSensor>(
    [ParkingQueryKeys.Sensor, sensorId],
    async () => {
      const { data } = await instance.get(`/parking/sensors/${sensorId}`);
      return data.sensor;
    },
    {
      enabled: !!sensorId,
    }
  );

export const useUpdateSensorMutation = createAxiosMutation(
  async ({ sensor }: { sensor: UpdateSensorOptions }) => {
    const { data } = await instance.patch(
      `/parking/sensors/${sensor.id}`,
      sensor
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated sensor.",
    }),
    errorToast: (err) => ({
      message: `Error updating sensor. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateSensorQueries(queryClient);
    },
  }
);

export const useUnassignSensorMutation = createAxiosMutation(
  async ({ sensorId, spaceId }: { sensorId: string; spaceId: string }) => {
    const { data } = await instance.delete(
      `/parking/sensors/${sensorId}/spaces/${spaceId}`
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully deactivated sensor.",
    }),
    errorToast: (err) => ({
      message: `Error deactivating sensor. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateSensorQueries(queryClient);
    },
  }
);

const invalidateSensorQueries = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [
    ParkingQueryKeys.Sensors,
    ParkingQueryKeys.Sensor,
  ]);
