import { chunk } from "lodash-es";
import { useMemo, useState } from "react";
import { StyleSheet } from "react-native";

import { ChipProps } from "@smartrent/ui";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

export interface VirtualGridHookProps {
  numColumns: number;
  numColumnsArr: string[];
  transposedSerials: string[][];
  width: number;
  marginLeft: number;
  gap: number;
  chipSize: number;
  fontSize: number;
  boxHeight: number;
  boxWidth: number;
}

export const useVirtualGridHook = () => {
  const { serials } = useHubRefurbishContext();

  const [maxHubsInColumn, setMaxHubsInColumn] = useState(5);
  const [columnLGBreakpoint, setColumnLGBreakpoint] = useState(10);

  // number of current columns displayed
  const numColumns = useMemo(
    () => (serials?.length ? Math.ceil(serials.length / maxHubsInColumn) : 0),
    [maxHubsInColumn, serials.length]
  );

  // generate our column headers with these values
  const numColumnsArr = useMemo(
    () => Array.from(Array(numColumns)),
    [numColumns]
  );

  // We transpose the chunks in order to display the original
  //  pattern of serials we have in hub prep batch grid
  const transposedSerials = useMemo(() => {
    const groupedSerials = chunk<string>(serials, maxHubsInColumn);

    if (!groupedSerials?.length) {
      return [];
    }
    return groupedSerials[0]
      .map((x, i) => groupedSerials.map((x) => x[i]))
      .map((row) => ["header", ...row]); // inject row header to make life easier
  }, [maxHubsInColumn, serials]);

  // increment width by 10% for each number of columns, helps make it look nice
  const width = useMemo(() => {
    return numColumns < 10 ? `${(numColumns + 1) * 10}%` : "100%";
  }, [numColumns]);

  // idk why but this style only applies if its memoized
  // we want to shift the entire grid over 1 column so the row headers don't use up space
  const marginLeft = useMemo(
    () =>
      numColumns < columnLGBreakpoint
        ? stylesLG.grid.marginLeft
        : stylesMD.grid.marginLeft,
    [columnLGBreakpoint, numColumns]
  );

  const gap = useMemo(
    () =>
      numColumns < columnLGBreakpoint ? stylesLG.grid.gap : stylesMD.grid.gap,
    [columnLGBreakpoint, numColumns]
  );

  //box styles
  const chipSize = useMemo<ChipProps["size"]>(
    () =>
      (numColumns < columnLGBreakpoint
        ? stylesLG.chip.chipSize
        : stylesMD.chip.chipSize) as ChipProps["size"],
    [columnLGBreakpoint, numColumns]
  );

  const fontSize = useMemo(
    () =>
      numColumns < columnLGBreakpoint
        ? stylesLG.chip.fontSize
        : stylesMD.chip.fontSize,
    [columnLGBreakpoint, numColumns]
  );

  const boxHeight = useMemo(
    () =>
      numColumns < columnLGBreakpoint
        ? stylesLG.chip.height
        : stylesMD.chip.height,
    [columnLGBreakpoint, numColumns]
  );

  const boxWidth = useMemo(
    () =>
      numColumns < columnLGBreakpoint
        ? stylesLG.chip.width
        : stylesMD.chip.width,
    [columnLGBreakpoint, numColumns]
  );

  return {
    setMaxHubsInColumn,
    setColumnLGBreakpoint,
    numColumns,
    numColumnsArr,
    transposedSerials,
    width,
    marginLeft,
    gap,
    chipSize,
    fontSize,
    boxHeight,
    boxWidth,
    styles,
  };
};

const stylesLG = {
  grid: {
    marginLeft: "-6%",
    gap: 20,
    margin: 0,
  },
  chip: {
    chipSize: "medium",
    fontSize: 22,
    height: 75,
    width: 75,
  },
};

const stylesMD = {
  grid: {
    marginLeft: "-1.33%",
    gap: 15,
    margin: undefined,
  },
  chip: {
    chipSize: "small",
    fontSize: 16,
    height: 54,
    width: 54,
  },
};

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    padding: 0,
    margin: -8,
    maxWidth: 1200,
  },
  box: {
    minWidth: 0,
    alignSelf: "center",
    height: 75,
    width: 75,
    paddingHorizontal: -4,
  },

  placeholderVisible: { aspectRatio: 1 },
});
