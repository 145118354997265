import { useRef } from "react";
import * as React from "react";
import Leaflet from "leaflet";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";

interface PropertiesMapProps {
  groups: any[];
  mapboxApiKey: string;
}

const PropertiesMap: React.FC<React.PropsWithChildren<PropertiesMapProps>> = ({
  groups,
  mapboxApiKey,
}) => {
  const mapboxRef = useRef(null);

  return (
    <div ref={mapboxRef} style={{ height: "60vh", width: "100%" }}>
      <Map
        zoom={5}
        center={{ lat: 37.0902, lng: -95.7129 }}
        style={{ height: "60vh", width: "100%" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&amp;copy <a href="#">Mapbox</a>'
          url={`https://api.mapbox.com/styles/v1/jwdotjs/ck3j20hr01f0n1clmvdlhp9oa/tiles/256/{z}/{x}/{y}?access_token=${mapboxApiKey}`}
        />
        {groups.map((group, idx) => {
          return (
            <Marker
              key={`${group.id}-${idx}`}
              icon={Leaflet.icon({
                iconUrl: "../../assets/images/markers/marker-icon-2x-blue.png",
                iconSize: [20, 32],
              })}
              position={{ lat: Number(group.lat), lng: Number(group.lng) }}
            >
              <Popup>
                <h3 style={styles.popupHeading}>
                  Organization:{" "}
                  <em style={{ fontWeight: 700 }}>{group.org_name}</em>
                </h3>
                <h3 style={styles.popupHeading}>
                  Community:{" "}
                  <em style={{ fontWeight: 700 }}>{group.group_name}</em>
                </h3>
                <h3 style={styles.popupHeading}>
                  Total Tours:{" "}
                  <em style={{ fontWeight: 700 }}>{group.tour_count}</em>
                </h3>
                <h3 style={styles.popupHeading}>
                  Total Prospects:{" "}
                  <em style={{ fontWeight: 700 }}>{group.prospect_count}</em>
                </h3>
              </Popup>
            </Marker>
          );
        })}
      </Map>
    </div>
  );
};

const styles = {
  popupHeading: {
    fontSize: 18,
  },
  popupHeadingLarge: {
    fontSize: 24,
    fontWeight: 700,
  },
};

export default PropertiesMap;
