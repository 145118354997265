import { useMemo } from "react";

import {
  FormikSelectField,
  FormikTextInputField,
  Typography,
  ColumnOptions,
  useTableQuery,
  Table,
  ListQueryResponse,
  useTheme,
} from "@smartrent/ui";
import { getJobStatusColor, getJobStatusText } from "@smartrent/install";

import {
  fetchJobs,
  FetchJobsQueryKey,
  InstallJobPropsWithFlags,
} from "@/api/jobs";
import { EM_DASH } from "@/utils/chars";
import {
  DEALER_API_RECORDS_LIMIT,
  formatDate,
  installDetailDeviceTypes,
  installDetailInstallTypes,
} from "@/utils";

import { Badges } from "../sr-table/Badges";

interface JobListProps {
  searchValue?: string;
  projectId?: string;
  assignedToUserId?: string;
}

const keyExtractor = ({ id }: InstallJobPropsWithFlags) => id;
const getRowHref = ({ id }: InstallJobPropsWithFlags) =>
  `/alloy-install/jobs/${id}`;

export function JobList({
  searchValue,
  projectId,
  assignedToUserId,
}: JobListProps) {
  const { colors } = useTheme();

  const columns = useMemo<ColumnOptions<InstallJobPropsWithFlags>[]>(
    () => [
      {
        header: "Name",
        name: "name",
        relativeWidth: columnWidths.name,
        render: ({ row }) => {
          const rowName = row?.name;
          const propertyName = row?.property?.name
            ? row?.property?.name
            : EM_DASH;
          const unitName = row?.unit?.name ? row?.unit?.name : EM_DASH;

          return (
            <Typography type="body" color="textPrimary">
              {rowName}
              {"\n"}
              <Typography color="textSecondary" type="title6">
                {propertyName}
                {" | "}
                {unitName}
              </Typography>
            </Typography>
          );
        },
        filterType: {
          type: "textInputField",
        },
      },

      {
        header: "Photos Taken",
        name: "photosTaken",
        relativeWidth: columnWidths.photos,
        render: ({ row }) => {
          return (
            <Typography type="body" color="textPrimary">
              {row?.photo_task_count > 0
                ? `${row.completed_photo_task_count}/${row.photo_task_count} Photos`
                : EM_DASH}
            </Typography>
          );
        },
      },
      {
        header: "Assigned Users",
        name: "assignedUsers",
        relativeWidth: columnWidths.assignedUsers,
        render: ({ row }) => {
          const firstAssignedUserFullName =
            row?.users.length > 0
              ? `${row?.users[0]?.first_name} ${row?.users[0]?.last_name}`
              : EM_DASH;
          const otherAssignedUsersCount =
            row?.users.length > 1
              ? ` & ${row?.users.length - 1} ${
                  row?.users.length > 2 ? "others" : "other"
                }`
              : null;

          return (
            <Typography type="body" color="textPrimary">
              {firstAssignedUserFullName}
              {otherAssignedUsersCount}
            </Typography>
          );
        },
      },
      {
        header: "Start Date",
        name: "startDate",
        relativeWidth: columnWidths.startDate,
        render: ({ row }) => {
          const startDate = formatDate({
            date: row?.start_date || "",
            pattern: "LLL d, yyyy", // Aug 17, 2021
          });
          return (
            <Typography type="body" color="textPrimary">
              {startDate}
            </Typography>
          );
        },
      },
      {
        header: "End Date",
        name: "endDate",
        relativeWidth: columnWidths.endDate,
        render: ({ row }) => {
          const endDate = formatDate({
            date: row?.end_date || "",
            pattern: "LLL d, yyyy", // Aug 17, 2021
          });
          return (
            <Typography type="body" color="textPrimary">
              {endDate}
            </Typography>
          );
        },
      },

      {
        header: "Tasks",
        name: "tasks",
        relativeWidth: columnWidths.tasks,
        render: ({ row }) => {
          const total = row?.task_count;
          const totalComplete = row?.completed_task_count;
          return total ? (
            <Typography type="body" color="textPrimary">
              {`${totalComplete}/${total} Task${
                total > 1 ? "s" : ""
              } Completed (${((totalComplete / total) * 100).toFixed(0)}%)`}
            </Typography>
          ) : (
            <Typography type="body" color="textPrimary">
              No Tasks
            </Typography>
          );
        },
      },

      {
        header: "Status",
        name: "status",
        relativeWidth: columnWidths.status,
        filter: () => (
          <FormikSelectField
            name="status"
            label="Status"
            options={[
              {
                label: "Completed",
                value: "completed",
              },
              {
                label: "Cannot Complete",
                value: "cannot complete",
              },
              {
                label: "In Progress",
                value: "in progress",
              },
              {
                label: "Scheduled",
                value: "not started",
              },
              {
                label: "Not Scheduled",
                value: "not scheduled",
              },
              {
                label: "Overdue",
                value: "overdue",
              },
              {
                label: "Opted Out",
                value: "opted out",
              },
              {
                label: "Ready for QA",
                value: "ready for qa",
              },
              {
                label: "Rescheduled",
                value: "rescheduled",
              },
            ]}
          />
        ),
        render: ({ row }) => (
          <Badges
            statusColor={getJobStatusColor(row, colors)}
            statusText={getJobStatusText(row)}
            flagCount={row.open_flags_count}
          />
        ),
      },
      {
        header: "Unit",
        name: "unit_name",
        hidden: true, // hidden because we want to keep this filter
        render: () => null,
        filter: () => <FormikTextInputField name="unit_name" label="Unit" />,
      },
      {
        header: "Install Type",
        name: "install_type",
        hidden: true,
        render: () => null,
        filter: () => (
          <FormikSelectField
            name="install_type"
            label="Install Type"
            options={installDetailInstallTypes}
          />
        ),
      },
      {
        header: "Device Type",
        name: "device_type",
        hidden: true,
        render: () => null,
        filter: () => (
          <FormikSelectField
            name="device_type"
            label="Device Type"
            options={installDetailDeviceTypes}
          />
        ),
      },
      ...(!projectId
        ? ([
            {
              header: "Property Name",
              name: "property",
              hidden: true, // hidden because we want to keep this filter
              render: () => null,
              filter: () => (
                <FormikTextInputField name="property" label="Property Name" />
              ),
            },
          ] as ColumnOptions<InstallJobPropsWithFlags>[])
        : []),
    ],
    [projectId, colors]
  );

  const tableProps = useTableQuery<
    FetchJobsQueryKey,
    InstallJobPropsWithFlags,
    ListQueryResponse<InstallJobPropsWithFlags>
  >({
    columns,
    fetch: fetchJobs,
    getQueryKey: ({ filters, page }) => [
      "jobs",
      {},
      {
        ...(projectId && { project_id: projectId }),
        ...(assignedToUserId && { assigned_to_user_id: assignedToUserId }),
        ...(searchValue && { name: searchValue }),
        ...filters,
        page,
      },
    ],
    keyExtractor,
    defaultPageSize: DEALER_API_RECORDS_LIMIT,
  });

  return (
    <Table<InstallJobPropsWithFlags>
      title="Jobs"
      noRecordsText="No jobs found"
      getRowHref={getRowHref}
      onRowPress={() => {}}
      {...tableProps}
    />
  );
}

const columnWidths = {
  name: 150,
  photos: 100,
  assignedUsers: 120,
  startDate: 60,
  endDate: 60,
  tasks: 120,
  status: 60,
};
