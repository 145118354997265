import { useCallback, useRef, useState } from "react";
import * as React from "react";
import {
  View,
  StyleSheet,
  Animated,
  TouchableWithoutFeedback,
} from "react-native";

import { useTheme, Typography } from "@smartrent/ui";

export interface SpoilerProps {
  label?: string;
}

export const Spoiler: React.FC<React.PropsWithChildren<SpoilerProps>> = ({
  children,
  label = "Click to Reveal",
}) => {
  const [overlayVisible, setOverlayVisible] = useState(true);
  const fadeAnimation = useRef(new Animated.Value(1)).current;

  const { colors } = useTheme();

  const fadeOut = useCallback(() => {
    Animated.timing(fadeAnimation, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [fadeAnimation]);

  const fadeIn = useCallback(() => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [fadeAnimation]);

  fadeAnimation.addListener(({ value }) => {
    if (value == 0) {
      setOverlayVisible(false);
    } else {
      setOverlayVisible(true);
    }
  });

  const toggleFade = useCallback(() => {
    if (overlayVisible) {
      fadeOut();
    } else {
      fadeIn();
    }
  }, [overlayVisible, fadeIn, fadeOut]);

  return (
    <TouchableWithoutFeedback onPress={toggleFade}>
      <View style={styles.container}>
        {children}

        {/* Overlay. */}
        {overlayVisible ? (
          <Animated.View
            style={[
              styles.contents,
              styles.layer2,
              {
                opacity: fadeAnimation,
                backgroundColor: colors.animatedPlaceholder,
              },
            ]}
          >
            <Typography type="caption">{label}</Typography>
          </Animated.View>
        ) : null}
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    cursor: "pointer",
  },
  contents: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  layer2: {
    zIndex: 1,
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "center",
  },
});
