import { useMemo } from "react";
import { View, StyleSheet } from "react-native";

import { useTheme } from "@smartrent/ui";

import { HubPrepLog } from "@/types";

import { HubPrepPill } from "./HubPrepPill";

interface HubPrepLogCounterProps {
  times_run: HubPrepLog["times_run"];
}
export const HubPrepLogCounter = ({ times_run }: HubPrepLogCounterProps) => {
  const { colors } = useTheme();

  const timesRunColor = useMemo(() => {
    // These numbers were defined by James from Procurement
    // If hub has been run more than 2 times, the hub tends to
    // to have some issues and will not succeed without deeper
    // investigation/work. In order to give them a better callout
    // and visual representation based on the `times_run` we color
    // it accordingly
    // 1-2 times: green
    // 3-5 times: orange
    // 5+ times: red
    if (times_run < 3) {
      return colors.green;
    }

    if (times_run > 2 && times_run < 5) {
      return colors.orange;
    }

    return colors.error;
  }, [colors.error, colors.green, colors.orange, times_run]);

  return (
    <View style={styles.root}>
      <HubPrepPill color={timesRunColor} borderColor={timesRunColor}>
        {`Run Count: ${times_run ? times_run : 1}`}
      </HubPrepPill>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    marginHorizontal: 4,
  },
});
