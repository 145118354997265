import { Route, Switch, RouteComponentProps, Redirect } from "react-router-dom";

// import * as Sentry from "@sentry/react"; // uncomment when SUPPORTRENT-API running in prod
import { ActionSheetProvider, ToastProvider } from "@smartrent/ui";

// Pages
import { CMWFeatureFlags } from "@/pages/CMWFeatureFlags";
import { Group } from "@/pages/Group";
import { GroupsContainer } from "@/pages/GroupsContainer";
import { HubInstallHealthDashboard } from "@/pages/HubInstallHealthDashboard";
import { HubPage } from "@/pages/hub-page/HubPage";
import { HubRefurbish } from "@/pages/hub-refurbish/HubRefurbish";
import { HubsMapPage } from "@/pages/HubsMapPage";
import { InstallsDashboard } from "@/pages/InstallsDashboard";
import { IntegrationAdoption } from "@/pages/IntegrationAdoption";
import { MaintenanceVendor } from "@/pages/MaintenanceVendor";
import { ParkingPropertyDetails } from "@/pages/parking/";
import { ScopeOfWork } from "@/pages/ScopeOfWork";
import { ScopeOfWorkPage } from "@/pages/scope-of-work/ScopeOfWorkPage";
import { SigningKeyPage } from "@/pages/SigningKeys";
import { Unit } from "@/pages/Unit";
import { User } from "@/pages/User";
import { UsersContainer } from "@/pages/UsersContainer";
import { VendorApplication } from "@/pages/vendor-applications/VendorApplication";
import { VendorApplications } from "@/pages/vendor-applications/VendorApplications";
import Accounts from "@/pages/Accounts";
import AlloyInstallUser from "@/pages/alloy-install/UserDetails";
import BatteryReport from "@/pages/BatteryReport";
import CustomReports from "@/pages/CustomReports";
import Dashboard from "@/pages/Dashboard";
import HubPrepBatch from "@/pages/hub-prep/HubPrepBatch";
import HubPrepContainer from "@/pages/hub-prep/HubPrepContainer";
import Job from "@/pages/Job";
import LeakReport from "@/pages/LeakReport";
import Login from "@/pages/Login";
import ManualVerifications from "@/pages/ManualVerifications";
import MonthlyReports from "@/pages/MonthlyReports"; // Monthly reports for Board of Directors and Product Analysis
import Organization from "@/pages/Organization";
import Organizations from "@/pages/Organizations";
import Project from "@/pages/Project";
import Prospect from "@/pages/Prospect";
import ReportingContainer from "@/pages/ReportingContainer";
import ToursDashboard from "@/pages/ToursDashboard";
import Verification from "@/pages/Verification";
import ProspectVerification from "@/pages/ProspectVerification";

// Layout
import Provider from "@/layout/Provider";
import { RequirePermission } from "@/common/RequirePermission";

//-- Providers
import { GroupProvider } from "@/pages/group/GroupContext";
import { UnitProvider } from "@/pages/unit/UnitContext";
import { HubPrepProvider } from "@/pages/hub-prep/HubPrepContext";
import { OrganizationProvider } from "@/pages/organization/OrganizationContext";
import { HubRefurbishProvider } from "@/pages/hub-refurbish/HubRefurbishContext";
import { ScopeOfWorkProvider } from "@/pages/scope-of-work/provider/ScopeOfWorkProvider";

// -- Drawer
import { AppDrawer } from "@/common/AppDrawer";

import { ResidentWebhooks } from "./pages/ResidentWebhooks";
import { GroupCommunityWifiAccessPoints } from "./pages/group/GroupCommunityWifiAccessPoints";

export function Router() {
  return (
    <Switch>
      <Route exact path="/login" render={() => <Login />} />

      <Route
        exact
        path="/"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <Dashboard />
          </Provider>
        )}
      />
      <Route
        exact
        path="/organizations"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="view_organizations">
              <AppDrawer anchor="right">
                <Organizations />
              </AppDrawer>
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/organizations/:organizationId"
        render={(props: RouteComponentProps<{ organizationId: string }>) => (
          <Provider {...props}>
            <AppDrawer anchor="right">
              <OrganizationProvider
                id={parseInt(props.match.params.organizationId, 10)}
              >
                <Organization />
              </OrganizationProvider>
            </AppDrawer>
          </Provider>
        )}
      />
      <Route
        exact
        path="/organizations/:organizationId/vendor-applications"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="view_vendor_applications">
              <AppDrawer anchor="right">
                <VendorApplications />
              </AppDrawer>
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/organizations/:organizationId/vendor-applications/:vendorApplicationId"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="view_vendor_applications">
              <AppDrawer anchor="right">
                <VendorApplication />
              </AppDrawer>
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/users"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="view_users">
              <UsersContainer />
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/tours"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <ToursDashboard />
          </Provider>
        )}
      />
      <Route
        exact
        path="/tours/manual-verifications"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="manual_verifications">
              <ManualVerifications />
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/verifications/:verificationId"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="manual_verifications">
              <Verification />
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/verifications/prospect/:prospectId"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="manual_verifications">
              <ProspectVerification
                prospectId={(props.match.params as any).prospectId}
                history={props.history}
              />
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/prospects/:prospectId"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <Prospect />
          </Provider>
        )}
      />
      <Route
        exact
        path="/maintenance-vendors/:maintenanceVendorId"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <MaintenanceVendor />
          </Provider>
        )}
      />
      <Route
        exact
        path="/maps/hubs"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <HubsMapPage />
          </Provider>
        )}
      />
      <Route
        exact
        path="/leak-report"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <LeakReport />
          </Provider>
        )}
      />
      <Route
        exact
        path="/scope-of-work"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="view_sow">
              <ToastProvider>
                <AppDrawer anchor="right">
                  <ScopeOfWork />
                </AppDrawer>
              </ToastProvider>
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        path="/scope-of-work/:scopeOfWorkId"
        render={(props: RouteComponentProps<{ scopeOfWorkId: string }>) => (
          <Provider {...props}>
            <RequirePermission permission="view_sow">
              <ToastProvider>
                <ScopeOfWorkProvider id={props.match.params.scopeOfWorkId}>
                  <ActionSheetProvider>
                    <AppDrawer anchor="right">
                      <ScopeOfWorkPage />
                    </AppDrawer>
                  </ActionSheetProvider>
                </ScopeOfWorkProvider>
              </ToastProvider>
            </RequirePermission>
          </Provider>
        )}
      />
      {/* Alloy Install */}
      <Route
        exact
        path="/alloy-install/users/:userId"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <AlloyInstallUser />
          </Provider>
        )}
      />
      <Route
        exact
        path="/alloy-install/projects/:projectId"
        render={(props: RouteComponentProps<{ projectId: string }>) => (
          <Provider {...props}>
            <RequirePermission permission="view_installs">
              {/*TODO: Add project provider?  */}
              <Project id={props.match.params.projectId} />
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/alloy-install/jobs/:jobId"
        render={(props: RouteComponentProps<{ jobId: string }>) => (
          <Provider {...props}>
            <RequirePermission permission="view_installs">
              {/*TODO: Add project provider?  */}
              <Job id={props.match.params.jobId} />
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        path="/alloy-install"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <InstallsDashboard />
          </Provider>
        )}
      />

      <Route
        path="/:hub_type/install-health"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <HubInstallHealthDashboard />
          </Provider>
        )}
      />

      <Route
        path="/integration-adoption"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <IntegrationAdoption />
          </Provider>
        )}
      />
      {/* Alloy Install Legacy Redirects */}
      <Route path="/installs" render={() => <Redirect to="/alloy-install" />} />
      <Route
        exact
        path="/projects"
        render={() => <Redirect to="/alloy-install/projects" />}
      />
      <Route
        exact
        path="/projects/:projectId"
        render={(props: RouteComponentProps<{ projectId: string }>) => (
          <Redirect
            to={`/alloy-install/projects/${props.match.params.projectId}`}
          />
        )}
      />
      <Route
        exact
        path="/jobs"
        render={() => <Redirect to="/alloy-install/jobs" />}
      />
      <Route
        exact
        path="/jobs/:jobId"
        render={(props: RouteComponentProps<{ jobId: string }>) => (
          <Redirect to={`/alloy-install/jobs/${props.match.params.jobId}`} />
        )}
      />

      <Route
        exact
        path="/accounts"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <AppDrawer maxWidth={550} anchor="right">
              <Accounts />
            </AppDrawer>
          </Provider>
        )}
      />
      <Route
        exact
        path="/groups"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="view_groups">
              <GroupsContainer />
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/groups/:groupId"
        render={(props: RouteComponentProps<{ groupId: string }>) => (
          <Provider {...props}>
            <GroupProvider id={parseInt(props.match.params.groupId, 10)}>
              <ActionSheetProvider>
                <AppDrawer anchor="right">
                  <Group />
                </AppDrawer>
              </ActionSheetProvider>
            </GroupProvider>
          </Provider>
        )}
      />
      <Route
        exact
        path="/groups/:groupId/battery-report"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <BatteryReport />
          </Provider>
        )}
      />
      <Route
        exact
        path="/groups/:groupId/manage-wifi"
        render={(props: RouteComponentProps<{ groupId: string }>) => (
          <Provider {...props}>
            <GroupProvider id={parseInt(props.match.params.groupId, 10)}>
              <AppDrawer anchor="right">
                <GroupCommunityWifiAccessPoints />
              </AppDrawer>
            </GroupProvider>
          </Provider>
        )}
      />
      <Route
        exact
        path="/groups/:groupId/parking"
        render={(props: RouteComponentProps<{ groupId: string }>) => (
          <Provider {...props}>
            <GroupProvider id={parseInt(props.match.params.groupId, 10)}>
              <AppDrawer anchor="right">
                <ParkingPropertyDetails
                  groupId={parseInt(props.match.params.groupId, 10)}
                />
              </AppDrawer>
            </GroupProvider>
          </Provider>
        )}
      />
      <Route
        exact
        path="/units/:unitId"
        render={(props: RouteComponentProps<{ unitId: string }>) => (
          <Provider {...props}>
            <ToastProvider>
              <UnitProvider id={parseInt(props.match.params.unitId, 10)}>
                <AppDrawer anchor="right">
                  <Unit />
                </AppDrawer>
              </UnitProvider>
            </ToastProvider>
          </Provider>
        )}
      />
      <Route
        exact
        path="/users/:userId"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <ToastProvider>
              <User />
            </ToastProvider>
          </Provider>
        )}
      />
      <Route
        exact
        path="/hub-prep"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="hub_prep">
              <HubPrepProvider>
                <HubPrepContainer />
              </HubPrepProvider>
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        path="/hub-prep/refurbish-batch/step/:step"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="hub_prep">
              <HubRefurbishProvider>
                <ActionSheetProvider>
                  <HubRefurbish />
                </ActionSheetProvider>
              </HubRefurbishProvider>
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/hub-prep/batch"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <RequirePermission permission="hub_prep">
              <HubPrepProvider>
                <HubPrepBatch />
              </HubPrepProvider>
            </RequirePermission>
          </Provider>
        )}
      />
      <Route
        exact
        path="/hubs/:serial"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <AppDrawer anchor="right">
              <HubPage {...(props.match.params as { serial: string })} />
            </AppDrawer>
          </Provider>
        )}
      />
      <Route
        exact
        path="/reporting"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <ReportingContainer />
          </Provider>
        )}
      />
      <Route
        exact
        path="/feature-flags"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <AppDrawer anchor="right">
              <CMWFeatureFlags />
            </AppDrawer>
          </Provider>
        )}
      />
      <Route
        exact
        path="/signing-keys"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <SigningKeyPage />
          </Provider>
        )}
      />
      <Route
        exact
        path="/monthly-reports/:reportName"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <MonthlyReports />
          </Provider>
        )}
      />
      <Route
        exact
        path="/custom-reports/:reportName"
        render={(props: RouteComponentProps) => (
          <Provider {...props}>
            <CustomReports />
          </Provider>
        )}
      />

      <Route
        exact
        path="/residents/:residentId/webhooks"
        render={(props: RouteComponentProps<{ residentId: string }>) => (
          <Provider {...props}>
            <RequirePermission permission="resident_details">
              <ToastProvider>
                <AppDrawer anchor="right">
                  <ResidentWebhooks
                    id={parseInt(props.match.params.residentId, 10)}
                  />
                </AppDrawer>
              </ToastProvider>
            </RequirePermission>
          </Provider>
        )}
      />
    </Switch>
  );
}
