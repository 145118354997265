import { useCallback } from "react";
import * as React from "react";

import { FormikHelpers } from "formik";

import { useUpdateGuestPaymentsModule } from "@/api";

import { useDrawerNav } from "@/common/AppDrawer";

import {
  PaymentConfigurationForm,
  PaymentConfigurationValues,
} from "./PaymentConfigurationForm";

interface Props {
  propertyId: string;
}

export const EditPaymentConfiguration: React.FC<
  React.PropsWithChildren<Props>
> = ({ propertyId }) => {
  const drawer = useDrawerNav();
  const [updateGuestPayments] = useUpdateGuestPaymentsModule();

  const handleCancel = useCallback(() => drawer.pop(), [drawer]);

  const handleSubmit = useCallback(
    async (
      values: PaymentConfigurationValues,
      actions: FormikHelpers<PaymentConfigurationValues>
    ) => {
      actions.setSubmitting(true);
      await updateGuestPayments({
        property: {
          id: propertyId,
          stripe_connect_id: values.stripe_connect_id,
          guest_parking_rate:
            Number.parseFloat(values.guest_parking_rate) * 100,
          guest_parking_session_max_subtotal:
            Number.parseFloat(values.guest_parking_session_max_subtotal) * 100,
          guest_parking_tax_rate:
            Number.parseFloat(values.guest_parking_tax_rate) / 100,
          guest_parking_payments_module_enabled: true,
          guest_parking_application_fee:
            Number.parseFloat(values.guest_parking_application_fee) / 100,
        },
      });
      actions.setSubmitting(false);

      drawer.reset();
    },
    [handleCancel]
  );

  return (
    <PaymentConfigurationForm
      propertyId={propertyId}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};
