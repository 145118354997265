import { StyleSheet } from "react-native";
import {
  DirectionStackProps,
  HStack,
  Typography,
  TypographyProps,
} from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import { DeviceProps } from "@/types";

const DebugText = (props: TypographyProps) => (
  <Typography type="caption" color="textSecondary" {...props}></Typography>
);

interface DeletedDeviceDebugInfoProps
  extends Pick<
    DeviceProps,
    | "id"
    | "device_descriptor_id"
    | "product_id"
    | "product_type_id"
    | "zw_mf_id"
  > {
  spacing?: DirectionStackProps["spacing"];
  empty?: string;
  separator?: string;
  startAdornment?: string;
  endAdornment?: string;
}

export const DeletedDeviceDebugInfo = ({
  id,
  device_descriptor_id,
  product_id,
  product_type_id,
  zw_mf_id,
  spacing = 2,
  empty = "n/a",
  separator = ",",
  startAdornment = "(",
  endAdornment = ")",
}: DeletedDeviceDebugInfoProps) => {
  const {
    permissions: { view_technical_details, view_device_identifiers },
  } = useUser();

  const formatId = (val: string | number | null | undefined) =>
    val ? val : empty;

  return (
    <>
      {view_technical_details ? (
        <HStack spacing={spacing} style={styles.root}>
          <DebugText>
            {startAdornment}id: {formatId(id)}
            {separator}
          </DebugText>
          <DebugText>
            device descriptor id: {formatId(device_descriptor_id)}
            {separator}
          </DebugText>
          <DebugText>
            product id: {formatId(product_id)}
            {separator}
          </DebugText>
          <DebugText>
            product type id: {formatId(product_type_id)}
            {endAdornment}
          </DebugText>
        </HStack>
      ) : null}
      <HStack>
        {view_device_identifiers ? (
          <DebugText>(zwave manufacturer id: {formatId(zw_mf_id)})</DebugText>
        ) : null}
      </HStack>
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flexWrap: "wrap",
  },
});
