import { useState, useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { ActivityIndicator, Pill, Typography } from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";

import { useIsDesktop } from "@/lib/hooks";
import { Card, CardHeader } from "@/common/card/index";
import { HubLogs, HealNetwork, HubMessages } from "@/pages/hub-page/logs";

import { HubRequest } from "@/types";

import { HubRefurbishHistory } from "./logs/HubRefurbishHistory";

export const TABS = {
  hubLog: "hub-log",
  healNetwork: "heal-network",
  hubMessages: "hub-messages",
  hubRefurbishHistory: "hub-refurbish-history",
};

interface HubInfoProps {
  hub: HubRequest;
  serial: string;
}

export const HubInfo: React.FC<React.PropsWithChildren<HubInfoProps>> = ({
  hub,
  serial,
}) => {
  const isDesktop = useIsDesktop();
  const [activeView, setActiveView] = useState(TABS.hubLog);
  const [ableToRefresh, setAbleToRefresh] = useState(true);
  const [fetchData, setFetchData] = useState("");

  const fetch = useCallback(() => {
    setAbleToRefresh(false);
    setFetchData(activeView);
    window.setTimeout(() => {
      setAbleToRefresh(true);
      setFetchData("");
    }, 1500);
  }, [activeView]);

  return (
    <View>
      <Typography type="title">Logs</Typography>
      <Card style={styles.cardRow}>
        <CardHeader>
          <View
            style={isDesktop ? styles.desktopPillWrapper : styles.pillWrapper}
          >
            {hub?.type === "zipato" ? (
              <Pill
                variation={activeView === TABS.hubLog ? "default" : "outlined"}
                onPress={() => setActiveView(TABS.hubLog)}
                style={[styles.pill, !isDesktop ? styles.marginBottom : null]}
              >
                Hub Log
              </Pill>
            ) : null}

            {hub && !hub?.deleted_at ? (
              <Pill
                variation={
                  activeView === TABS.healNetwork ? "default" : "outlined"
                }
                onPress={() => setActiveView(TABS.healNetwork)}
                style={[styles.pill, !isDesktop ? styles.marginBottom : null]}
              >
                Heal Network
              </Pill>
            ) : null}

            <Pill
              variation={
                activeView === TABS.hubMessages ? "default" : "outlined"
              }
              onPress={() => setActiveView(TABS.hubMessages)}
              style={styles.pill}
            >
              Hub Messages
            </Pill>

            {hub?.type === "zipato" ? (
              <Pill
                variation={
                  activeView === TABS.hubRefurbishHistory
                    ? "default"
                    : "outlined"
                }
                onPress={() => setActiveView(TABS.hubRefurbishHistory)}
                style={styles.pill}
              >
                Hub Refurbish History
              </Pill>
            ) : null}
          </View>

          {ableToRefresh ? (
            <TouchableOpacity onPress={fetch}>
              <Refresh size={24} />
            </TouchableOpacity>
          ) : (
            <ActivityIndicator size={24} />
          )}
        </CardHeader>

        {activeView === TABS.hubLog ? (
          <HubLogs serial={serial} fetchData={fetchData} />
        ) : null}

        {activeView === TABS.healNetwork && hub && !hub.deleted_at ? (
          <HealNetwork hub={hub} />
        ) : null}

        {activeView === TABS.hubMessages ? (
          <HubMessages serial={serial} fetchData={fetchData} />
        ) : null}

        {activeView === TABS.hubRefurbishHistory ? (
          <HubRefurbishHistory serial={serial} fetchData={fetchData} />
        ) : null}
      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  cardRow: {
    flexDirection: "column",
    marginTop: 16,
  },
  desktopPillWrapper: {
    flexDirection: "row",
  },
  pillWrapper: {
    flexDirection: "column",
  },
  pill: {
    marginRight: 8,
    marginBottom: 8,
  },
  marginBottom: { marginBottom: 8 },
});
