import { Fragment } from "react";
import * as React from "react";
import { find } from "lodash-es";

import Resident from "@/common/Resident";

import { useUser } from "@/layout/Context";

import { useUnit } from "./UnitContext";

function renderResident({
  resident,
  residentUsers,
  permissions,
  users,
  workflows,
  residents,
  accessCodes,
  CMW_BASE_URL,
  group,
  organization,
}) {
  const user = find(users, { id: resident.user_id });
  const residentWorkflows = workflows.filter(
    ({ resident_id }) => resident_id === resident.id
  );

  const cardStyles: React.CSSProperties = {
    zIndex: 0,
  };

  const accessCode = find(accessCodes || [], {
    resident_id: String(resident.id),
  });

  return (
    <div
      className="u-mright16 u-mbottom16"
      style={cardStyles}
      key={resident.id}
    >
      <Resident
        permissions={permissions}
        resident={resident}
        user={user}
        resident_users={residentUsers}
        workflows={residentWorkflows}
        residents={residents}
        accessCode={accessCode}
        CMW_BASE_URL={CMW_BASE_URL}
        group={group}
        organization={organization}
      />
    </div>
  );
}

export default function Residents() {
  const { permissions } = useUser();
  const {
    residents,
    users,
    group,
    organization,
    workflows,
    residentUsers,
    accessCodes,
    devices,
    CMW_BASE_URL,
  } = useUnit();

  const movedInResidents = residents.filter(({ moved_in }) => moved_in);
  const currentButNotMovedIn = residents.filter(
    ({ moved_in, status }) => !moved_in && status === "Current"
  );
  const remainingResidents = residents.filter(
    ({ moved_in, status }) => !moved_in && status !== "Current"
  );

  const primaryLock = find(devices, { primary_lock: true });
  const primaryLockAccessCodes = primaryLock ? accessCodes[primaryLock.id] : [];

  return (
    <Fragment>
      <div className="u-flex u-flex-wrap">
        {movedInResidents.map((resident) =>
          renderResident({
            resident,
            residentUsers,
            users,
            permissions,
            workflows,
            residents,
            accessCodes: primaryLockAccessCodes,
            CMW_BASE_URL,
            group,
            organization,
          })
        )}
      </div>
      <div className="u-flex u-flex-wrap">
        {currentButNotMovedIn.map((resident) =>
          renderResident({
            resident,
            residentUsers,
            users,
            permissions,
            workflows,
            residents,
            accessCodes: [],
            CMW_BASE_URL,
            group,
            organization,
          })
        )}
      </div>
      <div className="u-flex u-flex-wrap">
        {remainingResidents.map((resident) =>
          renderResident({
            resident,
            residentUsers,
            users,
            permissions,
            workflows,
            residents: [],
            accessCodes: [],
            CMW_BASE_URL,
            group,
            organization,
          })
        )}
      </div>
    </Fragment>
  );
}
