import * as React from "react";
import { View, StyleSheet, ViewProps } from "react-native";

export const PanelContent: React.FC<React.PropsWithChildren<ViewProps>> = ({
  style,
  children,
  ...props
}) => {
  return (
    <View style={[styles.root, style]} {...props}>
      {children}
    </View>
  );
};

export const styles = StyleSheet.create({
  root: {
    padding: 16,
  },
});
