import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";

export interface CardBodyProps {
  style?: StyleProp<ViewStyle>;
}

export const CardBody: React.FC<React.PropsWithChildren<CardBodyProps>> = ({
  children,
  style,
}) => <View style={[styles.cardBody, style]}>{children}</View>;

const styles = StyleSheet.create({
  cardBody: {
    padding: 18,
  },
});
