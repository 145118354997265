import { useCallback } from "react";
import * as React from "react";
import { ListRenderItemInfo, StyleSheet } from "react-native";

import { Chip, ListItemTouchableContainer, ListItemValue } from "@smartrent/ui";

import { useGlobalContext } from "@/layout/Context";
import { useLayout } from "@/hooks/use-layout";
import { groupsAllUnitsTableNativeId } from "@/utils/native-ids";

export const UnitProblemListItem: React.FC<
  React.PropsWithChildren<ListRenderItemInfo<any>>
> = ({ item: { label, count, link } }) => {
  const { history } = useGlobalContext();
  const { scrollTo } = useLayout();

  const onPress = useCallback(() => {
    const tableElement = document.getElementById(groupsAllUnitsTableNativeId);
    history.push(link);
    if (tableElement) {
      scrollTo({ y: tableElement.offsetTop });
    }
  }, [history, link, scrollTo]);
  return (
    <ListItemTouchableContainer
      style={styles.root}
      onPress={onPress}
      rightDetail={
        <Chip size="medium" variation="muted" color="warning">
          {`${count} Unit${count > 1 ? "s" : ""}`}
        </Chip>
      }
    >
      <ListItemValue valueStyle={styles.text} numberOfLines={1}>
        {label}
      </ListItemValue>
    </ListItemTouchableContainer>
  );
};

const styles = StyleSheet.create({
  root: { paddingLeft: 0 },
  text: { fontSize: 16 },
});
