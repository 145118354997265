import { useCallback, useState } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Button, ConfirmDialog, Typography } from "@smartrent/ui";

import { StatusBadgeDetailView } from "@/common/StatusBadgeDetailView";

import { useUser } from "@/layout/Context";

import { useCommunityWifiUpdateNetworkMutation } from "@/api";

import { useGroup } from "@/pages/group/GroupContext";

export interface GroupWiFiModuleProps {
  containerStyle: any;
}

export const GroupWiFiModule: React.FC<
  React.PropsWithChildren<GroupWiFiModuleProps>
> = ({ containerStyle }) => {
  const { permissions } = useUser();
  const { group, groupCommunityWifi } = useGroup();
  const [showEnableModal, setShowEnableModal] = useState(false);

  const [communityWifiUpdateNetworkMutation] =
    useCommunityWifiUpdateNetworkMutation();

  const enableCommunityWifi = useCallback(async () => {
    setShowEnableModal(false);
    await communityWifiUpdateNetworkMutation({
      groupId: group.id,
    });
  }, [communityWifiUpdateNetworkMutation, group]);

  return (
    <>
      <View style={containerStyle}>
        <Typography type="title5">WiFi Device Network</Typography>

        <StatusBadgeDetailView condition={groupCommunityWifi?.enabled} />
      </View>
      {groupCommunityWifi?.enabled && permissions.apply_community_wifi ? (
        <View style={styles.container}>
          <View style={styles.flexCol}>
            <Typography color="labelText" style={styles.label}>
              Apply To All Hubs:
            </Typography>
          </View>
          <View style={styles.flexColButton}>
            <Button
              variation="plain"
              size="small"
              onPress={() => setShowEnableModal(true)}
              contentStyle={styles.button}
              textStyle={styles.buttonText}
            >
              Update WiFi Device Network
            </Button>
          </View>
        </View>
      ) : null}{" "}
      <ConfirmDialog
        title="Are you sure?"
        description="Warning: this will update all hubs in the group to connect to the WiFi Device Network. In order to avoid all hubs going offline, please test that the SSID/password is correct before executing this action."
        visible={showEnableModal}
        onClose={() => setShowEnableModal(false)}
        onConfirm={enableCommunityWifi}
        testID="confirm-dialog"
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 8,
    paddingLeft: 8,
  },

  flexCol: {
    flexDirection: "column",
  },
  flexColButton: {
    flexDirection: "column",
    marginRight: 8,
  },

  label: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 380,
    fontSize: 16,
  },

  button: {
    paddingVertical: 6,
    paddingHorizontal: 6,
    justifyContent: "center",
  },
  buttonText: { lineHeight: 20, marginRight: -4 },
});
