import PublicPageLayout from "@/layout/PublicPageLayout";

import OauthOkta from "./login/OauthOkta";

export default function Login() {
  return (
    <PublicPageLayout>
      <div
        className="login panel u-shadow-lift"
        style={{
          alignSelf: "center",
          display: "flex",
          height: "240px",
          flexDirection: "column",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <h1 className="h3 u-sans-light u-text-gray-500 u-mbottom24 u-text-center">
          Sign in to your Account
        </h1>
        <div className="u-mtop16">
          <OauthOkta />
        </div>
      </div>
    </PublicPageLayout>
  );
}
