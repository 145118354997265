import * as React from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { upperCase } from "lodash-es";

import { Blockquote, Typography, TypographyProps } from "@smartrent/ui";

interface SowNotesProps {
  label: string;
  value: TypographyProps["children"];
  viewProps?: StyleProp<ViewStyle>;
}
export const SowNotesItem: React.FC<React.PropsWithChildren<SowNotesProps>> = ({
  label,
  value,
  viewProps,
}) => {
  return (
    <View style={[styles.flexRow, viewProps]}>
      <Typography
        type="captionLarge"
        style={styles.descriptionTitle}
        color="textSecondary"
      >
        {upperCase(label)}
      </Typography>
      <Blockquote type="body">{value}</Blockquote>
    </View>
  );
};

const styles = StyleSheet.create({
  flexRow: { flexDirection: "column", width: "100%" },
  descriptionTitle: { marginBottom: 8 },
});
