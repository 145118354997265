import { useState, useContext } from "react";

import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

export default function TroubleshootHubOnline() {
  const { user } = useContext(Context);
  const [disabled, setDisabled] = useState(false);
  const [label, setLabel] = useState("Make Hubs Online");

  if (!user?.permissions?.manage_system_health) {
    return null;
  }

  return (
    <div className="u-m16">
      <button
        className="button button--primary"
        disabled={disabled}
        onClick={() => {
          setDisabled(true);
          Helpers.troubleshoot.makeHubsOnline
            .get()
            .then(({ offlineHubCount }) =>
              setLabel(
                `Processing ${Number(
                  offlineHubCount
                ).toLocaleString()} offline hubs`
              )
            );
        }}
      >
        {label}
      </button>
    </div>
  );
}
