import { useContext, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import moment from "moment";
import { Link } from "react-router-dom";
import { get } from "lodash-es";
import { Refresh, CheckCircle } from "@smartrent/icons";
import { ActivityIndicator, Button } from "@smartrent/ui";

import Helpers from "@/lib/helpers";
import Context from "@/layout/Context";
import { PermissionProps } from "@/types";

import { useManualVerificationsQuery } from "@/api";

function MaybeLink({ verification, children }) {
  if (verification?.id) {
    return (
      <Link
        to={`/verifications/${verification.id}`}
        className="u-link u-link-clean cursor--pointer"
      >
        {children}
      </Link>
    );
  }

  return (
    <div>
      {children}
      <div className="u-font12 u-text-symbolic-warning">
        Corresponding verification for this tour not found
      </div>
    </div>
  );
}

export default function ManualVerifications() {
  const context = useContext(Context);
  const permissions: PermissionProps = context.user.permissions;

  const { data, isLoading, isFetching, refetch } = useManualVerificationsQuery(
    {},
    {
      enabled: !!permissions.manual_verifications,
      refetchInterval: 30000,
    }
  );

  const verificationCount = data?.length;

  useEffect(() => {
    const newTitle = `Support (${verificationCount})`;

    if (document.title !== newTitle) {
      document.title = newTitle;
    }
  }, [verificationCount]);

  if (!permissions.manual_verifications) {
    return null;
  }

  return (
    <div className="u-p8">
      <div className="u-flex u-flex-justify-between u-flex-align-items-center u-ptop4">
        <div className="h4 u-mbottom4">
          Manual Verifications ({verificationCount})
        </div>
        <Button onPress={() => refetch()} variation="plain">
          {isFetching ? (
            <ActivityIndicator size={20} />
          ) : (
            <Refresh size={20} color="#333" />
          )}
        </Button>
      </div>
      {!verificationCount && (
        <div
          className="u-p8"
          style={{
            border: "1px solid #eee",
            background: "#fefefe",
            boxShadow: "1px 1px 1px #fefefe",
          }}
        >
          {/* check for isLoading because otherwise it says no verification found until data is loaded */}
          {!isLoading ? (
            <div className="u-p4">
              No verifications require your attention.{" "}
              <span role="img" aria-label="hands raised emoji">
                🙌
              </span>
            </div>
          ) : null}
          <div
            style={{ borderTop: "1px solid #eee" }}
            className="u-flex-wrap u-flex u-flex-justify-end u-ptop4 u-font10 u-text-gray-400"
          >
            <div
              className="u-mright4 cursor--pointer"
              onClick={() => refetch()}
            >
              Last refresh: {moment().format("hh:mm:ss A")}
            </div>
            <div className="cursor--pointer" onClick={() => refetch()}>
              <Refresh size={12} color="#333" />
            </div>
          </div>
        </div>
      )}
      {data?.map((verification) => {
        return (
          <div
            key={verification.id}
            className="u-mbottom8 u-p8"
            style={{
              border: "1px solid #eee",
              background: "#fefefe",
              boxShadow: "1px 1px 1px #fefefe",
            }}
          >
            <MaybeLink verification={verification}>
              <div className="u-flex u-flex-wrap u-flex-align-items-start u-size-full">
                <div className="h5 u-mright16">
                  {verification.first_name} {verification.last_name}
                </div>
                <div className="u-flex">
                  <div className="u-mright8">
                    <IconForVerification
                      verification={verification}
                      type="front"
                    />{" "}
                    Front
                  </div>
                  <div className="u-mright8">
                    <IconForVerification
                      verification={verification}
                      type="back"
                    />{" "}
                    Back
                  </div>
                  <div className="u-mright8">
                    <IconForVerification
                      verification={verification}
                      type="selfie"
                    />{" "}
                    Selfie
                  </div>
                </div>
              </div>
              <View style={styles.verificationsContainer}>
                <div className="u-font10">
                  {"SCHEDULED START TIME: " +
                    Helpers.formatDate(verification.scheduled_start)}
                  {verification.scheduled_start_now ? ", TOUR NOW" : ""}
                </div>
              </View>
            </MaybeLink>
          </div>
        );
      })}
    </div>
  );
}

const styles = StyleSheet.create({
  verificationsContainer: {
    fontWeight: "600",
    fontSize: 12,
    flex: 1,
    flexWrap: "wrap",
    justifyContent: "space-between",
    color: Helpers.colors.gray,
    marginTop: 4,
  },
});

// u-line12 u-mtop4
function IconForVerification({ verification, type }) {
  if (!verification) {
    return null;
  }

  const isUploaded = verification[`${type}_uploaded`];

  if (isUploaded) {
    return <CheckCircle size={14} color={Helpers.colors.green} />;
  } else {
    const attemptsCount = (get(verification, "attempts_images") || []).filter(
      (attempt_image) => attempt_image.type === type
    ).length;
    return (
      <span
        style={{
          border: `1px solid ${Helpers.colors.red}`,
          fontSize: "8px",
          padding: "2px 5px",
          borderRadius: 15,
          color: Helpers.colors.red,
          verticalAlign: "top",
        }}
      >
        {attemptsCount}
      </span>
    );
  }
}
