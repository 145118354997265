import { useState, useCallback, useEffect, useMemo } from "react";
import * as React from "react";
import { FlatList, StyleProp, ViewStyle, TouchableOpacity } from "react-native";
import { map, startCase } from "lodash-es";

import {
  Typography,
  ActivityIndicator,
  FlatListItemSeparator,
} from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";

import { UnitModel } from "@/types";
import { useBoxQuery } from "@/api/zipato";
import { valueToHumanReadableString } from "@/utils/human-readable-values";

import { Card, CardHeader, CardBody } from "@/common/card/index";
import { ListItemLabelValueCompact } from "@/common/ListItemLabelValueCompact";
import { NoContent } from "@/pages/hub-page/NoContent";

interface BoxContentProps {
  data: any;
}

const BoxContent: React.FC<React.PropsWithChildren<BoxContentProps>> = ({
  data,
}) => {
  const formattedData = useMemo(
    () => (data ? map(data, (value, key) => ({ key, value })) : []),
    [data]
  );

  return (
    <FlatList
      data={formattedData}
      keyExtractor={({ key }) => key}
      renderItem={({ item }) => (
        <ListItemLabelValueCompact
          label={startCase(item.key)}
          value={
            item.key !== "firmware"
              ? valueToHumanReadableString(item.value)
              : item.value
          }
        />
      )}
      ItemSeparatorComponent={FlatListItemSeparator}
    />
  );
};

export interface BoxGetProps {
  unit: UnitModel;
  style?: StyleProp<ViewStyle>;
}

export const BoxGet: React.FC<React.PropsWithChildren<BoxGetProps>> = ({
  unit,
  style,
}) => {
  const [initiallyClicked, setInitiallyClicked] = useState(false);
  const [ableToRefresh, setAbleToRefresh] = useState(true);

  const { data, isLoading, isFetching, refetch, isError, isRefetchError } =
    useBoxQuery(
      { unit_id: unit?.unit?.id },
      { enabled: Boolean(unit?.unit?.id) && initiallyClicked }
    );

  useEffect(() => {
    let loader;

    if (!isLoading && !isFetching) {
      loader = window.setTimeout(() => {
        setAbleToRefresh(true);
      }, 1500);
    }

    return () => {
      window.clearTimeout(loader);
    };
  }, [isLoading, isFetching]);

  const fetch = useCallback(() => {
    setInitiallyClicked(true);
    setAbleToRefresh(false);
    refetch();
  }, [refetch]);

  return (
    <Card style={style}>
      <CardHeader>
        <Typography type="title2">Box Get</Typography>
        {ableToRefresh ? (
          <TouchableOpacity onPress={fetch}>
            <Refresh size={25} />
          </TouchableOpacity>
        ) : (
          <ActivityIndicator size={25} />
        )}
      </CardHeader>

      <CardBody>
        {!isLoading && !isFetching && initiallyClicked && ableToRefresh ? (
          <>
            {isError || isRefetchError ? (
              <NoContent isError={true} />
            ) : (
              <BoxContent data={data} />
            )}
          </>
        ) : (
          <NoContent />
        )}
      </CardBody>
    </Card>
  );
};
