import { useCallback, useState } from "react";
import * as React from "react";
import type { QueryFunction, QueryKey } from "@tanstack/react-query";

import { Button, Table, Typography, useTableQuery } from "@smartrent/ui";

import { useDrawerNav } from "@/common/AppDrawer";
import { useGlobalContext, useUser } from "@/layout/Context";

import { instance } from "@/lib/hooks";
import { GroupsTableApiResponse, GroupsTableProps } from "@/types";
import { formatDate, integrations, modules, states } from "@/utils";

export const GroupsTable: React.VFC = () => {
  const DEFAULT_PAGE_SIZE = 25;
  const { match } = useGlobalContext();
  const { permissions } = useUser();
  const drawer = useDrawerNav();

  const [organizationId] = useState(match.params?.organizationId);

  const getQueryKey = ({
    filters,
    page,
    pageSize,
    sortColumn,
    sortDirection,
  }) => [
    "groups-table-query-key",
    {},
    {
      ...filters,
      organizationId,
      page,
      limit: pageSize,
      sort: sortColumn,
      dir: sortDirection,
    },
  ];

  const fetchGroups: QueryFunction<GroupsTableApiResponse> = async ({
    queryKey,
  }: {
    queryKey: QueryKey;
  }) => {
    const [, , filters] = queryKey;
    const { data } = await instance.get<GroupsTableApiResponse>(
      "/groups-table",
      {
        params: filters,
      }
    );
    return data;
  };

  const tableProps = useTableQuery<any, GroupsTableProps, any>({
    fetch: fetchGroups,
    getQueryKey: getQueryKey,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    columns: [
      {
        name: "marketing_name",
        header: "Name",
        render: ({ row: { marketing_name } }) => (
          <Typography>{marketing_name}</Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },
      {
        name: "property_code",
        header: "Property Code",
        render: ({ row: { property_code } }) => (
          <Typography>{property_code || "-"}</Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },
      {
        name: "inserted_at",
        header: "Created",

        render: ({ row: { inserted_at } }) => (
          <Typography>{formatDate({ date: inserted_at })}</Typography>
        ),
        sortable: true,
      },
      // hidden fields, but we use them as filters
      {
        name: "module",
        header: "Modules",
        render: () => null,
        hidden: true,
        filterType: {
          type: "multiSelectField",
          options: modules,
        },
      },
      {
        name: "integration",
        header: "Integrations",
        render: () => null,
        hidden: true,
        filterType: {
          type: "multiSelectField",
          options: integrations,
        },
      },
      {
        name: "city",
        header: "City",
        render: () => null,
        hidden: true,
        filterType: {
          type: "textInputField",
        },
      },
      {
        name: "state",
        header: "States",
        render: () => null,
        hidden: true,
        filterType: {
          type: "multiSelectField",
          options: states,
        },
      },
      {
        name: "include_deleted",
        header: "Include Deleted Groups",
        render: () => null,
        hidden: true,
        filterType: { type: "switch" },
      },
    ],
  });

  const handleGetRowHref = useCallback(
    ({ id }: GroupsTableProps) => `/groups/${id}`,
    []
  );

  const handleCreateClick = useCallback(
    () => drawer.push("createGroup", { organizationId }),
    [drawer, organizationId]
  );

  return (
    <Table<GroupsTableProps>
      title="Groups"
      noRecordsText="No Groups"
      getRowHref={handleGetRowHref}
      showClearFiltersButton
      action={
        permissions.update_organization && organizationId ? (
          <Button onPress={handleCreateClick}>Add New Group</Button>
        ) : null
      }
      {...tableProps}
    />
  );
};
