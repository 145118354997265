import { useContext } from "react";
import { map, compact, find } from "lodash-es";
import moment from "moment";
import { Line } from "react-chartjs-2";

import Graph from "@/common/Graph";
import Context from "@/layout/Context";
import helpers from "@/lib/helpers";

export default function HubPrepStats() {
  const { isDesktop } = useContext(Context);
  return (
    <Graph
      path="/reports/hub-prep"
      dataKey="stats"
      refetchInterval={120000}
      leftFooterRenderProps={({ countLast24 }) => {
        return (
          <div>
            <div>
              <span className="u-sans-bold"># Hubs Prepped Last 24h</span>:{" "}
              {Number(countLast24).toLocaleString()}
            </div>
          </div>
        );
      }}
      renderProps={({ counts }) => {
        const timeslots = compact(map(counts, (r) => r.time_of_day));
        const daysOfYearRepresented = [];

        const data = map(timeslots, (time_of_day) => {
          const datapoint = find(counts, { time_of_day });
          return datapoint ? Number(Math.round(datapoint.count)) : 0;
        });

        return (
          <div>
            {isDesktop && (
              <div className="u-flex u-flex-wrap">
                <div className="h4 u-text-center"># Hubs Prepped</div>
                <Line
                  data={{
                    labels: map(timeslots, (r) => {
                      const dayOfYear = moment(r).dayOfYear();
                      if (!daysOfYearRepresented.includes(dayOfYear)) {
                        daysOfYearRepresented.push(dayOfYear);
                        return moment(r).format("ddd MM/DD hh:mm a");
                      } else {
                        return moment(r).format("MM/DD hh:mm a");
                      }
                    }),
                    datasets: [
                      {
                        fill: false,
                        label: "Count",
                        data,
                        lineTension: 0.1,
                        backgroundColor: helpers.lineGraphColors.green,
                        borderColor: helpers.lineGraphColors.green,
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: helpers.lineGraphColors.darkGreen,
                        pointBackgroundColor: helpers.lineGraphColors.darkGreen,
                        pointBorderWidth: 1,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        spanGaps: true,
                        showLine: true,
                      },
                    ],
                  }}
                  height={30}
                />
              </div>
            )}
          </div>
        );
      }}
    />
  );
}
