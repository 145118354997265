import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

import Alert from "@/common/Alert";

interface HeadlinesProps {
  cmwAndZipatoOutOfSync: boolean;
}

interface Headline {
  type: "success" | "error" | "warning" | "info";
  headline: JSX.Element;
}

const Headlines: React.FC<React.PropsWithChildren<HeadlinesProps>> = ({
  cmwAndZipatoOutOfSync,
}) => {
  const headlines: Headline[] = [];

  if (cmwAndZipatoOutOfSync) {
    headlines.push({
      type: "warning",
      headline: (
        <Typography color="textPrimaryInverse">
          CMW and Zipato hub records are out of sync
        </Typography>
      ),
    });
  }

  if (!headlines.length) {
    return null;
  }

  return (
    <View style={styles.container}>
      {headlines.map(({ headline, type }, idx) => (
        <Alert type={type} key={idx} style={styles.alert}>
          {headline}
        </Alert>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: "16",
  },
  alert: {
    flexDirection: "column",
    padding: "8",
  },
});

export default Headlines;
