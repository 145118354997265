import { Link, LinkProps } from "@smartrent/ui";
import * as React from "react";

import { DatadogIcon } from "../icons/Datadog";

interface DataDogLinkProps extends LinkProps {
  iconProps?: React.SVGProps<SVGSVGElement>;
}
export const DataDogLink = ({ iconProps, ...props }: DataDogLinkProps) => {
  return (
    <Link {...props}>
      <DatadogIcon {...iconProps} />
    </Link>
  );
};
