import { useCallback, useMemo } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { Switch } from "@smartrent/ui";
import { XCircle } from "@smartrent/icons";

import { Gate } from "@/types";
import { useCMWFeatureFlagMutation } from "@/api/cmw-feature-flags";

export function GateSwitch({
  gate,
  refetch,
  isLoading,
  flagName,
}: {
  isGlobal?: true;
  gate: Gate;
  refetch: () => void;
  isLoading: boolean;
  flagName: string;
}) {
  const isGlobal = gate.for === "global";
  const [updateFeatureFlag] = useCMWFeatureFlagMutation();
  const onValueChange = useCallback(
    async (enabled: boolean | null) => {
      const payload: { enabled: boolean | null } & Record<string, any> = {
        enabled,
      };

      if (gate.for !== "global" && gate.for.entity === "organization") {
        payload.organization_id = gate.for.entity_id;
      } else if (gate.for !== "global" && gate.for.entity === "group") {
        payload.group_id = gate.for.entity_id;
      }

      await updateFeatureFlag({
        flag: flagName,
        payload,
      });
      refetch();
    },
    [flagName, gate, refetch, updateFeatureFlag]
  );

  const { label, assistiveText } = useMemo(() => {
    if (gate.for === "global") {
      return {
        label: "Globally Enabled",
      };
    } else {
      return {
        label: gate.for.label,
        assistiveText: gate.enabled === false ? `Explicitly DISABLED` : null,
      };
    }
  }, [gate]);

  return (
    <View style={styles.row}>
      <View style={styles.switchWidth}>
        <Switch
          name={label}
          label={label}
          assistiveText={assistiveText}
          onValueChange={onValueChange}
          disabled={isLoading}
          value={gate.enabled}
        />
      </View>
      {isGlobal ? null : (
        <View style={styles.alignEnd}>
          <TouchableOpacity onPress={() => onValueChange(null)}>
            <XCircle color="error" />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  alignEnd: {
    display: "flex",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  switchWidth: {
    width: "75%",
  },
});
