export const integrations = [
  { label: "AppFolio", value: "AppFolio" },
  { label: "Atlas", value: "Atlas" },
  { label: "BDX", value: "BDX" },
  { label: "Brivo", value: "Brivo" },
  { label: "ButterflyMX", value: "ButterflyMX" },
  { label: "Entrata", value: "Entrata" },
  { label: "Funnel", value: "Funnel" },
  { label: "Igloo Home", value: "Igloo Home" },
  { label: "Knock", value: "Knock" },
  { label: "Lasso", value: "Lasso" },
  { label: "Lennar", value: "Lennar" },
  { label: "Peak Rewards", value: "Peak Rewards" },
  { label: "RealPage", value: "RealPage" },
  { label: "RentEGI", value: "RentEGI" },
  { label: "RentRoll", value: "RentRoll" },
  { label: "ResMan", value: "ResMan" },
  { label: "Ring", value: "Ring" },
  { label: "SightPlan", value: "SightPlan" },
  { label: "Venterra", value: "Venterra" },
  { label: "Yardi", value: "Yardi Voyager" },
];
