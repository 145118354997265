import * as React from "react";
import { Typography, useTheme } from "@smartrent/ui";
import { StyleSheet } from "react-native";

interface EmptyListProps {
  children: React.ReactNode;
}

// TODO: This component needs to be a type compatible with FlatList.ListEmptyComponent
export const EmptyList = React.memo<EmptyListProps>(({ children }) => {
  const { colors } = useTheme();
  return (
    <Typography
      style={[
        styles.emptyList,
        { backgroundColor: colors.background, color: colors.gray600 },
      ]}
    >
      {children}
    </Typography>
  );
});

const styles = StyleSheet.create({
  emptyList: {
    fontSize: 24,
    padding: 24,
    textAlign: "center",
  },
});
