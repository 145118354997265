import * as React from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "@smartrent/ui";

import { useGroupDeviceBreakdown } from "@/api/group";

import { DeviceBreakdown } from "@/common/devices/DeviceBreakdown";

/**
 * Responsible for fetching the data from the API and passing the data to the GroupDeviceBreakdown component.
 */
export const GroupDeviceBreakdownContainer: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const params = useParams<{ groupId: string }>();
  const { data, isLoading } = useGroupDeviceBreakdown(params.groupId);

  if (!data) {
    return null;
  }

  return isLoading ? (
    <Skeleton textType="title" />
  ) : (
    <DeviceBreakdown data={data} />
  );
};
