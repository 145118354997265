import {
  PaginatedResponse,
  useQueryCompat,
  useReducedInfiniteQuery,
} from "@smartrent/hooks";
import { InstallProjectProps } from "@smartrent/install";

import { ListQueryResponse } from "@/types";
import { DEALER_API_RECORDS_LIMIT } from "@/utils";

import { createAxiosQuery } from "@/hooks/react-query";

import { instance } from "../lib/hooks";

export const useFetchProjectsByGroup = createAxiosQuery(
  "fetch-projects-by-group",
  async ({ groupId, ...params }: { groupId: string | number } | any) => {
    return instance.get<PaginatedResponse>(`/groups/${groupId}/projects`, {
      params,
    });
  }
);

interface FetchProjectsFilterProps {
  groupId?: string;
  property_remote_id?: string;
  assigned_to_user_id?: string;
  name?: string;
  page?: number;
}

export type InstallProjectWithTaskFlags = InstallProjectProps & {
  open_flags_count: number;
};

export const fetchProjects = async (filters: Record<string, unknown>) => {
  const response = await instance.get<
    ListQueryResponse<InstallProjectWithTaskFlags>
  >("projects", {
    params: {
      ...filters,
      limit: DEALER_API_RECORDS_LIMIT,
    },
  });

  return response.data;
};

const getProject = async (projectId: string) => {
  const response = await instance.get(`/projects/${projectId}`);
  return response.data;
};

export const useGetProject = (projectId: string) =>
  // @ts-ignore - react-query types are not up to date
  useQueryCompat(["project", projectId], () => getProject(projectId));

export const useSearchProjectsQuery = (filters: FetchProjectsFilterProps) =>
  useReducedInfiniteQuery(["projects", filters], async ({ pageParam }) => {
    const response = await instance.get<
      PaginatedResponse<InstallProjectWithTaskFlags>
    >(`/projects`, {
      params: {
        page: pageParam,
        property_remote_id: filters?.groupId || null,
        ...filters,
      },
    });

    return response.data;
  });
