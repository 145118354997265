import * as React from "react";
import { View, StyleSheet } from "react-native";

import { PanelHeaderTitle, Box, useTheme } from "@smartrent/ui";

import { useGroup } from "../GroupContext";

import { GroupIntegrationsList } from "./group-integrations/GroupIntegrationsList";
import { GroupToursModule } from "./group-integrations/GroupToursModule";
import { GroupWiFiModule } from "./group-integrations/GroupWiFiModule";

export const GroupIntegrations: React.VFC = () => {
  const { colors } = useTheme();
  const { group } = useGroup();

  return (
    <Box style={styles.cardStyle}>
      <View
        style={[
          styles.headingWrapper,
          { backgroundColor: colors.pageBackground },
        ]}
      >
        <PanelHeaderTitle title="Integrations" />
      </View>

      <View style={styles.cardWrapper}>
        <GroupWiFiModule
          containerStyle={[
            styles.wifiModule,
            {
              borderBottomColor: colors.gray200,
            },
          ]}
        />

        <GroupToursModule
          containerStyle={[
            styles.toursModule,
            { borderBottomColor: colors.gray200 },
          ]}
        />

        <GroupIntegrationsList
          listKey="grpList"
          title="Group Providers"
          containerStyle={[
            styles.groupProviders,
            { borderBottomColor: colors.gray200 },
          ]}
          style={styles.providerList}
          tppc_scope="group"
          groupId={group?.id}
          organizationId={group?.organization_id}
        />

        <GroupIntegrationsList
          listKey="orgList"
          title="Org Providers"
          containerStyle={[
            styles.orgProviders,
            { borderBottomColor: colors.gray200 },
          ]}
          style={styles.providerList}
          tppc_scope="organization"
          groupId={group?.id}
          organizationId={group?.organization_id}
        />
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  cardStyle: { padding: 0 },
  headingWrapper: {
    flexDirection: "row",
    paddingVertical: 24,
    paddingHorizontal: 12,
  },

  cardWrapper: {
    flex: 1,
    marginBottom: 8,
    padding: 16,
    borderTopColor: "transparent",
  },

  wifiModule: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomWidth: 1,
    marginRight: 8,
  },
  toursModule: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomWidth: 1,
    marginTop: 32,
    marginRight: 8,
  },
  groupProviders: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 6,
    borderBottomWidth: 1,
    marginTop: 32,
    marginRight: 8,
  },
  orgProviders: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 12,
    paddingBottom: 6,
    borderBottomWidth: 1,
    marginTop: 16,
    marginRight: 8,
  },

  providerList: {
    flexDirection: "column",
    alignContent: "center",
    width: "100%",
    padding: 4,
    borderRadius: 0,
    borderTopColor: "transparent",
  },
});
