import { useCallback, useContext, useState } from "react";
import { View, StyleSheet } from "react-native";
import { find, get } from "lodash-es";
import { Link } from "react-router-dom";

import {
  HStack,
  ConfirmDialog,
  StatusBadge,
  Button,
  Grid,
} from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";

import { Email } from "@smartrent/icons";

import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";
import {
  useDeleteResident,
  useDissociateUserResident,
  resendResidentInvitation,
} from "@/api/residents";

import ResidentInfo from "@/pages/unit/resident/ResidentInfo";
import { SmartLinkQuickLaunch } from "@/common/links/SmartLinks";

import { PanelCard } from "@/common/PanelCard";

import { useIsDesktop } from "@/hooks/breakpoints";

import { cmwResidentOverview, residentWebhooks } from "@/utils/links";

import {
  PermissionProps,
  ResidentProps,
  UserProps,
  UnitProps,
  GroupProps,
  OrganizationProps,
  WorkflowProps,
  AccessCode,
  CardActionItemProps,
  ConfirmationModalProps,
} from "../types";

import Workflows from "./Workflows";

type Props = {
  permissions: PermissionProps;
  resident: ResidentProps;
  user: UserProps;
  resident_users: Object;
  unit?: UnitProps;
  group?: GroupProps;
  organization?: OrganizationProps;
  workflows?: WorkflowProps[];
  hideUserSection?: boolean;
  residents: ResidentProps[];
  accessCode: null | AccessCode;
  CMW_BASE_URL: null | string;
};

/* @todo - actions

  mark residents.status as Transferred
  move resident to a different lease
  move remote_id from another resident to moved in resident

*/

function MoveInBadge({ resident, isMinor }) {
  if (isMinor) {
    return (
      <StatusBadge
        variation="outlined"
        status="default"
        size="semibold.title.five"
      >
        Minor
      </StatusBadge>
    );
  } else if (!resident.moved_in) {
    return (
      <StatusBadge
        variation="outlined"
        status="warning"
        size="semibold.title.five"
      >
        Not Moved In
      </StatusBadge>
    );
  } else if (resident.user_id) {
    return (
      <StatusBadge
        variation="outlined"
        status="knowledge"
        size="semibold.title.five"
      >
        User
      </StatusBadge>
    );
  } else if (
    resident.resident_invitation_inserted_at &&
    !resident.resident_invitation_accepted_at
  ) {
    return (
      <StatusBadge
        variation="outlined"
        status="knowledge"
        size="semibold.title.five"
      >
        Moved In, Invite Not Accepted
      </StatusBadge>
    );
  } else if (!resident.resident_invitation_inserted_at) {
    return (
      <StatusBadge
        variation="outlined"
        status="error"
        size="semibold.title.five"
      >
        Moved In, No Invitation Found
      </StatusBadge>
    );
  }

  return null;
}

export default function Resident({
  permissions,
  resident,
  user,
  resident_users,
  unit,
  group,
  organization,
  workflows,
  hideUserSection,
  residents,
  accessCode,
  CMW_BASE_URL,
}: Props) {
  const context = useContext(Context);
  const { visible, onOpen, onClose } = useModalState();
  const isDesktop = useIsDesktop();

  const [deleteResident] = useDeleteResident();
  const [dissociateUserResident] = useDissociateUserResident();
  const [resendResidentInvite] = resendResidentInvitation();

  const [confirmationModal, setConfirmationModalProps] =
    useState<ConfirmationModalProps>();

  const openConfirmationModal = useCallback(
    (confirmationProps: ConfirmationModalProps) => {
      setConfirmationModalProps({
        ...confirmationProps,
        onConfirm: () => {
          confirmationProps.onConfirm();
          onClose();
        },
      });
      onOpen();
    },
    [onClose, onOpen]
  );

  if (!resident) {
    return null;
  }

  const residentUserCount = resident.user_id
    ? get(find(resident_users, { user_id: resident.user_id }), "count", 0)
    : 0;

  const cardActions = [];

  const viewTechnicalDetails = get(permissions, "view_technical_details");
  const hasUnlinkUserPermissions = get(
    permissions,
    "unlink_user_record",
    false
  );
  const hasResendInvitesPermissions = get(permissions, "resend_invites", false);

  if (permissions.delete_resident && !resident.moved_in) {
    cardActions.push({
      label: "Delete Resident",
      onPress: () => {
        openConfirmationModal({
          title: "Delete resident?",
          description: "Are you sure you want to delete this resident?",
          confirmText: "Delete",
          onConfirm: () => {
            deleteResident({
              residentId: resident.id,
            });
          },
        });
      },
    });
  }
  if (hasUnlinkUserPermissions && resident.user_id) {
    cardActions.push({
      label: "Unlink User/Resident",
      onPress: () => {
        openConfirmationModal({
          title: "Disassociate resident?",
          description: `Are you sure you want to unlink this resident (Resident ID: ${resident.id}) from this user (User ID: ${resident.user_id})?`,
          confirmText: "Disassociate",
          onConfirm: () => {
            dissociateUserResident({
              residentId: resident.id,
            });
          },
        });
      },
    });
  }

  if (hasResendInvitesPermissions && resident.moved_in) {
    cardActions.push({
      label: "Resend Resident Invitation",
      onPress: () => {
        openConfirmationModal({
          title: "Resend invitation?",
          description: `Are you sure you want to resend the invitation link?`,
          confirmText: "Confirm",
          onConfirm: () => {
            resendResidentInvite({
              residentId: resident.id,
            });
          },
        });
      },
    });
  }

  const isMinor =
    resident.relationship &&
    resident.relationship.toLowerCase().includes("minor");

  return (
    <PanelCard
      customStyles={styles.cardContainer}
      key={resident.id}
      actions={
        cardActions.length ? (cardActions as CardActionItemProps[]) : undefined
      }
      title={`${resident.first_name} ${resident.last_name}`}
    >
      <View>
        <HStack spacing={8} wrap={true} align="center">
          {!isMinor && (
            <>
              <StatusBadge
                variation="outlined"
                status={
                  resident.status === "Past" && !resident.moved_in
                    ? "default"
                    : "success"
                }
                size="semibold.title.five"
              >
                {resident.status}
              </StatusBadge>
              {/* {resident.locked_out && (
                <StatusBadge size="title5" variation="outlined" status="error">
                  Trouble Logging In
                </StatusBadge>
              )} */}
            </>
          )}
          <MoveInBadge resident={resident} isMinor={isMinor} />
          {user && user.tfa_enabled ? (
            <StatusBadge
              variation="outlined"
              status="success"
              size="semibold.title.five"
            >
              TFA Enabled
            </StatusBadge>
          ) : null}
        </HStack>

        <Grid numColumns={isDesktop ? 2 : 1} style={styles.buttonGroup}>
          {group &&
          resident &&
          context?.user?.permissions?.core_platform_access ? (
            <SmartLinkQuickLaunch
              title="Resident Overview"
              href={cmwResidentOverview(
                group?.id,
                resident?.unit_id,
                resident?.id
              )}
              organizationUrl={organization?.url || ""}
              contentStyle={styles.noPadding}
            />
          ) : null}
          {resident && context?.user?.permissions?.resident_details ? (
            <Button
              style={styles.leftJustified}
              contentStyle={styles.noPadding}
              iconLeft={Email}
              accessibilityRole="link"
              href={residentWebhooks(
                resident.id,
                user?.first_name,
                user?.last_name,
                unit?.marketing_name
              )}
              variation="plain"
            >
              Resident Webhooks
            </Button>
          ) : null}
        </Grid>

        {organization && group && unit && (
          <div className="u-font14 u-text-gray-800">
            <Link to={`/organizations/${organization.id}`} className="a">
              {organization.name}
            </Link>
            {" / "}
            <Link to={`/groups/${group.id}`} className="a">
              {group.marketing_name}
            </Link>
            {" / "}
            <Link to={`/units/${unit.id}`} className="a">
              {unit.marketing_name}
            </Link>
          </div>
        )}
        {permissions.resident_details && (
          <>
            <ResidentInfo
              resident={resident}
              user={user}
              residentUserCount={residentUserCount}
              permissions={permissions}
              hideUserSection={hideUserSection}
              residents={residents}
            />

            {workflows && !!workflows.length ? (
              <Workflows workflows={workflows} showFilters={false} />
            ) : null}

            {accessCode && (
              <div
                className="u-mtop8 u-font12"
                style={{
                  borderTop: "1px solid #eee",
                  paddingTop: "4px",
                }}
              >
                Access code used{" "}
                <span className="u-text-symbolic-knowledge u-sans-bold">
                  {accessCode.times_used}
                </span>{" "}
                times
              </div>
            )}

            {viewTechnicalDetails && (
              <div
                className={`${Helpers.technicalDetailsTextClass} u-font10 u-line14 u-ptop8`}
                style={{ borderTop: "1px solid #eee" }}
              >
                res id: {resident.id}, user id:{" "}
                {user && user.id ? user.id : "-"}, res remote id:{" "}
                {resident.remote_id || "-"}, lease id: {resident.lease_id}
                {resident.lease_remote_id
                  ? `, lease remote id: ${resident.lease_remote_id}`
                  : null}
                , inserted: {Helpers.formatDate(resident.inserted_at)}
              </div>
            )}
          </>
        )}
        <ConfirmDialog
          title={confirmationModal?.title}
          description={confirmationModal?.description}
          visible={visible}
          onConfirm={confirmationModal?.onConfirm}
          onClose={onClose}
        />
      </View>
    </PanelCard>
  );
}

const styles = StyleSheet.create({
  cardContainer: { marginTop: 16, marginRight: 16 },
  buttonGroup: { paddingVertical: 8 },
  noPadding: { paddingHorizontal: 0 },
  leftJustified: { justifyContent: "flex-start" },
});
