import { startCase } from "lodash-es";
import { View, StyleSheet } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { EM_DASH } from "@/utils/chars";
import { useUser } from "@/layout/Context";
import { RingAlarmLogs } from "@/pages/unit/device-components/RingAlarmLogs";

import { AccessControlDevice } from "@/types";

export const AccessControlDeviceCard = ({
  device,
}: {
  device: AccessControlDevice;
}) => {
  const { permissions } = useUser();
  const { colors } = useTheme();

  return (
    <View style={styles.box}>
      <View
        style={[
          styles.header,
          {
            backgroundColor: colors.gray050,
            borderBottomColor: colors.gray100,
          },
        ]}
      >
        <Typography type="title4Bold">
          {startCase(device.internal_name)}
        </Typography>
        <Typography color="textSecondary">
          {device.make ? startCase(device.make) : EM_DASH}
        </Typography>
      </View>
      <View style={styles.content}>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">Device Provider:</Typography>
          <Typography type="bodySemibold">
            {startCase(device.provider_name)}
          </Typography>
        </View>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">Device Type:</Typography>
          <Typography type="bodySemibold">
            {device.device_type ? startCase(device.device_type) : EM_DASH}
          </Typography>
        </View>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">Installed On:</Typography>
          <Typography type="bodySemibold">
            {Helpers.formatDate(device.inserted_at)}
          </Typography>
        </View>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">Network Type:</Typography>
          {/* All access_control_devices are non-Z-Wave devices at the moment */}
          <Typography type="bodySemibold">Non-Z-Wave</Typography>
        </View>
        <View style={styles.rowItem}>
          <Typography color="textSecondary">
            Can Grant Property Access:
          </Typography>
          <Typography type="bodySemibold">
            {device.grant_property_access ? "Yes" : "No"}
          </Typography>
        </View>
        {permissions.view_technical_details ? (
          <>
            <View style={styles.rowItem}>
              <Typography color="textSecondary">Remote ID:</Typography>
              <Typography type="bodySemibold">
                {device.remote_id ? device.remote_id : EM_DASH}
              </Typography>
            </View>
            <View style={styles.rowItem}>
              <Typography color="textSecondary">Device ID:</Typography>
              <Typography type="bodySemibold">
                {device.id ? device.id : EM_DASH}
              </Typography>
            </View>
          </>
        ) : null}
        {device.provider_name === "Ring" && device.device_type === "alarms" ? (
          <RingAlarmLogs device={device} />
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  box: {
    boxShadow: "rgba(26, 31, 39, 0.2) 0px 4px 8px",
    backgroundColor: "#FFFFFF",
    height: "auto",
    margin: 8,
    minWidth: 320,
  },
  header: {
    padding: 16,
    borderBottomWidth: 1,
    paddingBottom: 16,
  },
  rowItem: { marginBottom: 16 },
  content: {
    padding: 16,
  },
});
