import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import { FormikSubmit, HStack, PanelHeader } from "@smartrent/ui";

import { useGlobalContext, useUser } from "@/layout/Context";
import { RedisStatsFormikValues } from "@/types";

import { useStepNavigationHook } from "@/pages/hub-refurbish/hooks/useStepNavigationHook";
import { HubRefurbishStepPanelHeaderTitle } from "@/pages/hub-refurbish/HubRefurbishStepPanelHeaderTitle";
import { useApiHubRefurbishJobHook } from "@/pages/hub-refurbish/hooks/useApiHubRefurbishJobHook";
import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";
import { HubRefurbishStepLabels } from "@/pages/hub-refurbish/HubRefurbishStepsInfo";

// this component is separated out so we can use the useFormikContext() hook
export const HubRefurbishStepSubmit: React.VFC = () => {
  const {
    permissions: { advanced_hub_troubleshooting },
  } = useUser();

  // isValid means the formik validation 'errors' equal '{}'
  const { setToast } = useGlobalContext();
  const { isValid, isSubmitting, setSubmitting, values } =
    useFormikContext<RedisStatsFormikValues>();

  const { activeSerials, step } = useHubRefurbishContext();
  const {
    runHubRefurbishBatchJobMutation: { mutateAsync },
  } = useApiHubRefurbishJobHook();

  // persist serials as query params in history for step #1
  const { goToStepPage: updateCurrentUrl } = useStepNavigationHook(step);

  // inject serials as query params & navigate to step #2
  const { goToStepPage: goToNextStep } = useStepNavigationHook(step + 1);

  // navigate to next step
  const completeStepOne = useCallback(async () => {
    // kick off the bullmq jobs for step 2
    const {
      data: {
        job: { id },
        children,
      },
    } = await mutateAsync({
      serials: activeSerials,
    });

    // save data we want in other steps in the URL
    updateCurrentUrl();

    // set valid serials in next steps' URL
    goToNextStep({ flowId: id, flowHubsCount: children.length });

    // set success message
    setToast({
      type: "success",
      title: `Step ${step} was successful!`,
      timeout: 2000,
    });
  }, [
    mutateAsync,
    activeSerials,
    updateCurrentUrl,
    goToNextStep,
    setToast,
    step,
  ]);

  // add a small delay after pressing button so transition doesn't move too fast
  useEffect(() => {
    if (isSubmitting) {
      const timer = setTimeout(
        () => [setSubmitting(false), isValid ? completeStepOne() : () => null],
        1200
      );
      return () => clearTimeout(timer);
    }

    return () => null;
  }, [completeStepOne, isSubmitting, isValid, setSubmitting]);

  // action button needs a little space at the end when it exists
  const width = useMemo(
    () => (advanced_hub_troubleshooting ? "97%" : "100%"),
    [advanced_hub_troubleshooting]
  );

  return (
    <PanelHeader
      actions={
        advanced_hub_troubleshooting
          ? [
              {
                label: "Force go to next step",
                onPress: () => {
                  setTimeout(() => completeStepOne(), 1000);
                },
              },
            ]
          : undefined
      }
    >
      <HStack style={[styles.hStack, { width }]}>
        <HubRefurbishStepPanelHeaderTitle
          title={`Step ${step}`}
          label={HubRefurbishStepLabels[step]}
          numerator={(values?.offline || []).length}
          denominator={(activeSerials || []).length}
        />
        <FormikSubmit
          label={`Go To Step ${step + 1}`}
          submittingLabel="Validating..."
          style={
            advanced_hub_troubleshooting
              ? styles.submitButtonWithDebug
              : styles.submitButton
          }
          textStyle={styles.submitButtonText}
          disabled={!isValid}
        />
      </HStack>
    </PanelHeader>
  );
};

const styles = StyleSheet.create({
  hStack: {
    justifyContent: "space-between",
    paddingRight: 32,
  },
  submitButton: {
    paddingHorizontal: 32,
    paddingVertical: 0,
  },
  submitButtonWithDebug: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  submitButtonText: { paddingVertical: 0 },
});
