import { ActivityIndicator, Typography, useTheme } from "@smartrent/ui";

import { useRefurbishHistoryQuery } from "@/api";
import { useGlobalContext } from "@/layout/Context";

const Refurbished = ({ serial }: { serial: string }) => {
  const { data: refurbishedHistory, isLoading } = useRefurbishHistoryQuery({
    serial,
  });

  const { colors } = useTheme();

  const { user } = useGlobalContext();

  if (!user.permissions.refurbish_hubs) {
    return null;
  }

  if (isLoading) {
    return <ActivityIndicator size={24} color={colors.green} />;
  }

  return !isLoading && refurbishedHistory?.records?.length ? (
    <Typography color="info">Refurbished</Typography>
  ) : null;
};

export default Refurbished;
