import { useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";

import {
  FlatList,
  ListEmptyComponent,
  ListItemContainer,
  ListItemLoadingValue,
  ListItemValue,
} from "@smartrent/ui";

import { UnitProblem } from "@/types";

import { UnitProblemListItem } from "./UnitProblemsListItem";

interface UnitProblemsListProps {
  data: UnitProblem[];
  isLoading: boolean;
}

export const UnitProblemsList: React.FC<
  React.PropsWithChildren<UnitProblemsListProps>
> = ({ data, isLoading }) => {
  const filteredData = useMemo(
    () => (data ? data.filter(({ count }) => (count || 0) > 0) : data),
    [data]
  );
  return (
    <View>
      <ListItemContainer style={styles.shiftLeft}>
        <ListItemValue style={styles.shiftDown} valueStyle={styles.bold}>
          Problems
        </ListItemValue>
      </ListItemContainer>

      <FlatList<UnitProblem>
        keyExtractor={({ label }, index) => `${label}-${index}`}
        data={filteredData}
        contentContainerStyle={styles.contentContainer}
        LoadingItemComponent={() => <ListItemLoadingValue />}
        ItemComponent={(props) => <UnitProblemListItem {...props} />}
        ListEmptyComponent={(props) => (
          <ListEmptyComponent title="" description="No Problems Found." />
        )}
        isLoading={isLoading}
        totalRecordsLoaded={0}
        totalRecords={0}
        totalPages={0}
        itemSeparators={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: { paddingTop: 1 },
  shiftLeft: { paddingLeft: 0 },
  shiftDown: { paddingTop: 0 },
  bold: { fontWeight: "600" },
});
