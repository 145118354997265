import { useQuery } from "@tanstack/react-query";

import { instance } from "@/lib/hooks";

interface CoverageMapResponse {
  points: { lat: number; lng: number; count: number } & Record<string, any>;
  mapboxApiKey: string;
}

export function useCoverageMap(filters) {
  return useQuery(["coverage-map", filters], async () => {
    const response = await instance.get<CoverageMapResponse>("/maps/coverage", {
      params: filters,
    });

    return response.data;
  });
}
