import { useCallback, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";

import { Chip, HStack, Tooltip, Typography, useTheme } from "@smartrent/ui";

import { CheckCircle, ExclamationCircleSolid } from "@smartrent/icons";

import { useCreateScopeOfWorkReadyForReviewMutation } from "@/api";

import { ScopeOfWorkStatus } from "@/types";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";

export const SowReadyForReviewButton: React.VFC = () => {
  const { colors } = useTheme();
  // USE SOW CONTEXT
  const {
    scopeOfWorkQuery: { data, isLoading },
  } = useScopeOfWorkContext();

  const [markScopeOfWorkReadyForReview] =
    useCreateScopeOfWorkReadyForReviewMutation();

  const missingFields = useMemo(() => {
    const messages = [];

    if (!data || isLoading) {
      return messages;
    }

    if (!data?.sf_opportunity_id) {
      messages.push("Missing Opportunity");
    }

    if (!data?.scope_of_work_devices?.length) {
      messages.push("Missing Devices");
    }
    return messages.map((message, index) => `${index + 1}. ${message}`);
  }, [data, isLoading]);

  const shouldBeDisabled = useCallback(
    () =>
      (data?.status !== ScopeOfWorkStatus.Draft &&
        data?.status !== ScopeOfWorkStatus.Revision) ||
      missingFields.length > 0,
    [data?.status, missingFields.length]
  );

  if (
    data?.status === ScopeOfWorkStatus.Submitted ||
    data?.status === ScopeOfWorkStatus.Syncing ||
    data?.status === ScopeOfWorkStatus.Completed
  ) {
    return null;
  }

  return (
    <HStack spacing={8} align="center">
      <Chip
        disabled={shouldBeDisabled()}
        color="primary"
        variation="outlined"
        size="small"
        iconLeft={CheckCircle}
        onPress={() => {
          markScopeOfWorkReadyForReview({
            scope_of_work_id: data?.id,
            opportunity_id: data?.sf_opportunity_id,
          });
        }}
      >
        Ready for Review
      </Chip>
      {missingFields.length ? (
        <Tooltip
          name="error_tooltip"
          title={`${missingFields.join("\n")}`}
          placement="bottom"
        >
          <View style={styles.flexRowText}>
            <ExclamationCircleSolid color={colors.error} size={16} />
            <Typography type="captionSmall" color="error">
              Error
            </Typography>
          </View>
        </Tooltip>
      ) : null}
    </HStack>
  );
};

const styles = StyleSheet.create({
  flexRowText: {
    maxWidth: 90,
    flexWrap: "wrap",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
});
