import { useContext } from "react";
import * as React from "react";
import { View } from "react-native";
import { Panel, PanelBody } from "@smartrent/ui";

import Context from "@/layout/Context";

import UnitInfoHeader from "./unit-info/UnitInfoHeader";
import { UnitTechnicalDetails } from "./unit-info/UnitTechnicalDetails";
import UnitImage from "./unit-info/UnitImage";
import UnitLocation from "./unit-info/UnitLocation";
import UnitStatus from "./unit-info/UnitStatus";
import UnitLockStatus from "./unit-info/UnitLockStatus";
import UnitTourStatus from "./unit-info/UnitTourStatus";
import UnitNotes from "./unit-info/UnitNotes";
import MaintenanceConfig from "./unit-info/MaintenanceConfig";
import UnitCommunityWifiStatus from "./unit-info/UnitCommunityWifiStatus";

export const UnitInfo: React.VFC = () => {
  const {
    user: { permissions },
  } = useContext(Context);

  return (
    <Panel>
      <UnitInfoHeader />

      <PanelBody>
        <View>
          <UnitImage />
          <UnitLocation />
          <UnitStatus />
          <UnitLockStatus />
          <UnitCommunityWifiStatus />
          <UnitTourStatus />
          <UnitNotes />
          <UnitTechnicalDetails />
          {permissions?.resident_details ? <MaintenanceConfig /> : null}
        </View>
      </PanelBody>
    </Panel>
  );
};
