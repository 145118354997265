import { useCallback } from "react";
import * as React from "react";
import { FormikHelpers } from "formik";

import { DrawerHeader } from "@smartrent/ui";

import { useAssignGroupsToVendorApplicationMutation } from "@/api";
import { VendorApplication } from "@/types";
import { useDrawerNav } from "@/common/AppDrawer";
import {
  GroupVendorApplicationForm,
  GroupVendorApplicationFormValues,
} from "@/common/vendor-applications/drawer/GroupVendorApplicationForm";

interface AssignGroupToVendorApplicationProps {
  vendorApplication: VendorApplication;
}
export const AssignGroupToVendorApplication: React.FC<
  React.PropsWithChildren<AssignGroupToVendorApplicationProps>
> = ({ vendorApplication }) => {
  const drawer = useDrawerNav();

  const [assignGroupsToVendorApplication] =
    useAssignGroupsToVendorApplicationMutation();

  const handleCancel = useCallback(() => drawer.pop(), [drawer]);

  const handleSubmit = useCallback(
    async (
      values: GroupVendorApplicationFormValues,
      actions: FormikHelpers<GroupVendorApplicationFormValues>
    ) => {
      actions.setSubmitting(true);

      await assignGroupsToVendorApplication({
        organizationId: vendorApplication.organization_id,
        vendorApplicationId: vendorApplication.id,
        groupIds: values.groupIds,
      });

      actions.setSubmitting(false);
      drawer.pop();
    },
    [vendorApplication, assignGroupsToVendorApplication, drawer]
  );

  return (
    <>
      <DrawerHeader title="Assign Group" />
      <GroupVendorApplicationForm
        vendorApplication={vendorApplication}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};
