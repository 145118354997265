import HubsOnline from "@/common/graphs/HubsOnline";

import Graph from "./Graph";

export default function HubsOnlineOffline() {
  return (
    <Graph
      refetchInterval={120000}
      path="/reports/hubs/online"
      dataKey="hubsOnline"
      renderProps={({ hubs }) => {
        return (
          <>
            <HubsOnline hubs={hubs} />
          </>
        );
      }}
    />
  );
}
