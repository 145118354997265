import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "@smartrent/ui";

import { useIsDesktop } from "@/hooks/breakpoints";
import { Paginator } from "@/common/paginator";

export interface TablePaginatorProps {
  totalRecords: number;
  currentPage: number;
  totalPages: number;
  pageSize?: number;
  isLoading: boolean;
  isEmpty: boolean;
  onPageChange: (page: number) => void;
}

export const TablePaginator: React.FC<
  React.PropsWithChildren<TablePaginatorProps>
> = ({
  totalRecords,
  currentPage,
  totalPages,
  pageSize = 25,
  isLoading,
  isEmpty,
  onPageChange,
}) => {
  const isDesktop = useIsDesktop();

  // if there are 0 or 1 pages, this component can't render anything sensible
  if (!totalPages || totalPages === 1) {
    return null;
  }

  const page = `${(
    (currentPage - 1) * pageSize +
    1
  ).toLocaleString()} - ${(currentPage * pageSize > totalRecords
    ? totalRecords
    : currentPage * pageSize
  ).toLocaleString()}`;

  const resultText = `${page} of ${totalRecords.toLocaleString()}`;

  const pageText = `${currentPage} of ${totalPages} Page${
    totalPages !== 1 ? "s" : ""
  }`;

  return (
    <View style={styles.root}>
      <View style={styles.pageContainer}>
        {isDesktop && !isLoading && !isEmpty && totalPages ? (
          <Text type="title6">{pageText}</Text>
        ) : null}
      </View>

      <Paginator
        page={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        style={styles.paginator}
      />

      <View style={styles.resultsContainer}>
        {isDesktop && !isLoading && !isEmpty && totalRecords ? (
          <Text type="title6">{resultText}</Text>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    padding: 16,
  },

  paginator: { flex: 5 },
  pageContainer: { flex: 1, alignItems: "flex-start" },
  resultsContainer: { flex: 1, alignItems: "flex-end" },
});
