import { startCase } from "lodash-es";

export default function Name({ da }) {
  let content;
  switch (da.name) {
    case "locked":
      content = "Lock / Unlock Events";
      break;
    case "notifications":
      content = "Events";
      break;
    case "current_humidity":
      content = "Humidity";
      break;
    case "current_temp":
      content = "Temp";
      break;
    case "fan_mode":
      content = "Fan";
      break;
    case "on":
      content = "On / Off";
      break;
    case "leak":
      content = "Leak Status";
      break;
    default:
      content = startCase(da.name);
      break;
  }

  return <span>{content}</span>;
}
