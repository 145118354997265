import { useCallback, useContext } from "react";
import { View, Image, StyleSheet } from "react-native";
import { Typography, useTheme } from "@smartrent/ui";
import { Launch } from "@smartrent/icons";

import Context from "@/layout/Context";
import Card from "@/common/Card";
import { useDrawerNav } from "@/common/AppDrawer";
import { StatusBadgeDetailView } from "@/common/StatusBadgeDetailView";
import { useCompanies } from "@/api/alloy-install/companies";
import { salesforceUrl } from "@/utils";

import OrganizationInfoHeader from "./organization-info/OrganizationInfoHeader";
import { getOrganizationActions } from "./organization-info/OrganizationInfoActions";
import { useOrganization } from "./OrganizationContext";

export default function OrganizationInfo() {
  const { colors } = useTheme();
  const drawer = useDrawerNav();
  const context = useContext(Context);
  const { organization, refetchOrganization } = useOrganization();
  const {
    user: { permissions },
  } = useContext(Context);

  const { data } = useCompanies({ org_id: organization.id });

  const organizationImage = organization?.logo_url;

  const handleUpdateOrganizationActionClick = useCallback(
    () =>
      drawer.push("updateOrganization", { organization, refetchOrganization }),
    [drawer, organization, refetchOrganization]
  );

  const handleAlloyInstallOrganizationClick = useCallback(() => {
    drawer.push("alloyInstallEnvironment", { organization });
  }, [drawer, organization]);

  const handleLinkIntegrationClick = useCallback(() => {
    drawer.push("linkIntegrationToOrg", { organization, refetchOrganization });
  }, [drawer, organization, refetchOrganization]);

  return (
    <Card
      heading={<OrganizationInfoHeader />}
      actions={getOrganizationActions({
        organization,
        context,
        permissions,
        hasAlloyInstallCompany: !!(data && data.total_records > 0),
        handleUpdateOrganizationActionClick,
        handleAlloyInstallOrganizationClick,
        handleLinkIntegrationClick,
      })}
    >
      {organizationImage ? (
        <View style={styles.card}>
          <Image
            style={styles.organizationImage}
            source={{ uri: organizationImage }}
            resizeMode="contain"
          />
        </View>
      ) : null}
      <View style={styles.orgDetails}>
        <Typography>
          SAML
          {organization.saml_provider_name && organization.saml_provider_domain
            ? ` (${organization.saml_provider_domain})`
            : null}
        </Typography>
        <StatusBadgeDetailView
          condition={organization?.saml_provider_name ? true : false}
        />
      </View>
      <View style={styles.salesforce}>
        <Typography type="bodySmall">
          Salesforce Id:
          {organization.salesforce_id ? (
            <a
              href={`${salesforceUrl()}/lightning/r/Account/${
                organization.salesforce_id
              }/view`}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {organization.salesforce_id}{" "}
              <Launch size={8} color={colors.textPrimary} />
            </a>
          ) : (
            " Not Linked"
          )}
        </Typography>
        <Typography type="bodySmall">
          NetSuite Id:
          {organization.netsuite_id
            ? ` ${organization.netsuite_id}`
            : " Not Linked"}
        </Typography>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    maxWidth: 480,
  },
  orgDetails: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
  },
  salesforce: {
    padding: 8,
  },
  organizationImage: {
    height: 275,
  },
});
