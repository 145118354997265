import { map, startCase } from "lodash-es";

import { Typography } from "@smartrent/ui";

import { useBoxQuery } from "@/api/zipato";

import { BoxOrRedisValue } from "./BoxOrRedisValue";
import { HubDebugTitleBar } from "./HubDebugTitleBar";

export default function BoxGet({ unit_id }: { unit_id?: number }) {
  const { data, isLoading, isFetching, isError, refetch } = useBoxQuery({
    unit_id,
  });

  if (!unit_id) {
    return null;
  }

  return (
    <>
      <HubDebugTitleBar
        title="Box Get"
        isLoading={isLoading || isFetching}
        onReloadClicked={refetch}
      />

      {isLoading ? <Typography>Loading...</Typography> : null}
      {isError ? <Typography>Error loading data.</Typography> : null}

      {data ? <div className="u-line22">{iterateOverMap(data)}</div> : null}
    </>
  );
}

function iterateOverMap(obj: any) {
  return map(obj, (value, key) => {
    if (value instanceof Object && !Array.isArray(value)) {
      return (
        <div key={key}>
          <div className="u-text-gray-600">{startCase(key)}: </div>
          <div className="u-pleft8">{iterateOverMap(value)}</div>
        </div>
      );
    }

    return <BoxOrRedisValue key={key} value={value} label={key} />;
  });
}
