import { useContext } from "react";
import { View, Text } from "react-native";
import { Bar } from "react-chartjs-2";
import { startCase } from "lodash-es";

import Graph from "@/common/Graph";
import HoverText from "@/common/HoverText";

import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

export default function ImageErrorStats() {
  const { isDesktop } = useContext(Context);

  return (
    <Graph
      path="/reports/identity/image-error-report"
      dataKey="identity"
      leftFooterRenderProps={({ imageErrorReport }) => {
        const data = imageErrorReport.image_error_report;
        const totalErrors = data.reduce(
          (acc, current) => acc + current.count,
          0
        );

        return (
          <div>
            <div>
              <span className="u-sans-bold">Total Errors</span>:{" "}
              {totalErrors.toLocaleString()}
            </div>
          </div>
        );
      }}
      renderProps={({ imageErrorReport }) => {
        // Here I sort the data by the step (back, front, selfie)
        // then I sort by the count of errors ascending
        const data = imageErrorReport.image_error_report.sort((a, b) =>
          a.step > b.step
            ? 1
            : a.step === b.step
              ? a.count > b.count
                ? 1
                : -1
              : -1
        );
        const graphData = data.map((error) => error.count);
        const errorLabels = data.map(
          (error) =>
            `${startCase(error.step)} - ${startCase(error.error).replace(
              / Id/m,
              " ID"
            )}`
        );
        const dataMax = Math.max(...graphData);
        return (
          <View>
            {isDesktop && (
              <View style={{ alignItems: "center" }}>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ fontWeight: "600", marginRight: 8 }}>
                      ID Failure Reasons
                    </Text>
                    <HoverText
                      color="black"
                      tooltipText="Gathers a count of each ID failure that is generated upon each failure"
                      tooltipDirection="right"
                      width={200}
                    />
                  </View>
                </View>
                <Bar
                  height={50}
                  data={{
                    labels: errorLabels,
                    datasets: [
                      {
                        data: graphData,
                        label: "Errors",
                        backgroundColor: Helpers.lineGraphColors.red,
                        borderColor: Helpers.lineGraphColors.red,
                        pointBorderColor: Helpers.lineGraphColors.red,
                        pointHoverBackgroundColor: Helpers.lineGraphColors.red,
                        pointHoverBorderColor: Helpers.lineGraphColors.red,
                      },
                    ],
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                    scales: {
                      yAxes: [
                        {
                          // ticks: {
                          //   min: 0,
                          //   max: dataMax,
                          //   stepSize: 1,
                          // },
                          scaleLabel: {
                            display: true,
                            labelString: "Occurences",
                          },
                          ticks: {
                            min: 0,
                            max: dataMax,
                            stepSize: 1,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                            labelString: "Error Name",
                          },
                        },
                      ],
                    },
                    title: {
                      display: false,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const label = `Number of Errors: ${tooltipItem.yLabel}`;
                          return label;
                        },
                      },
                      title: null,
                    },
                  }}
                />
              </View>
            )}
          </View>
        );
      }}
    />
  );
}
