import { useQuery } from "@tanstack/react-query";
import { PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

export interface CompanyParams {
  org_id: string;
}

export const companiesQueryKeys = {
  all: ["companies"] as const,
  org: (params: CompanyParams) => [...companiesQueryKeys.all, params] as const,
};

export function useCompanies(params: CompanyParams) {
  return useQuery<PaginatedResponse<{ id: string }>>({
    queryKey: companiesQueryKeys.org(params),
    queryFn: () => getCompany(params),
  });
}

async function getCompany(params: CompanyParams) {
  const { data } = await instance.get(`/alloy-install/companies`, { params });
  return data;
}
