import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useQueryCompat } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

import { GroupFormValues } from "@/common/groups/GroupForm";
import { createAxiosQuery, createAxiosMutation } from "@/hooks/react-query";

import { DeviceStatsProps } from "@/types";

interface ListGroupsFilters {
  active?: boolean;
  limit?: number;
  sort?: "marketing_name" | "id";
  dir?: "asc" | "desc";
}

interface CreateGroupOptions {
  organizationId: string | number;
  body: GroupFormValues;
}

export const useCreateGroupMutation = createAxiosMutation(
  async ({ organizationId, body }: CreateGroupOptions) =>
    instance.post(`/organizations/${organizationId}/groups`, body)
);

export const useGroupsQuery = (
  organizationId: number | string,
  filters: ListGroupsFilters,
  page: number = 1
) =>
  useQueryCompat(
    ["organization-groups", organizationId],
    async (key: "organization-groups", organizationId) => {
      const { data } = await instance.get(
        `/groups?organizationId=${organizationId}`,
        {
          params: { ...filters, page, limit: 1000 }, // @todo replace with AsyncSelectDropdown
        }
      );

      return data.records;
    }
  );

export const useInvalidateGroupsQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (organizationId: number) =>
      queryClient.invalidateQueries(["organization-groups", organizationId]),
    [queryClient]
  );
};

export const useOrgDeviceBreakdown = createAxiosQuery(
  "org-device-breakdown",
  async (organizationId: number | string) => {
    return instance.get<DeviceStatsProps[]>(
      `/organizations/${organizationId}/device-breakdown`
    );
  }
);

interface LinkSalesforceToOrganizationProps {
  organization_id: string;
  salesforce_account_id: string;
}

export const useLinkSalesforceToOrganizationMutation = createAxiosMutation(
  async ({
    organization_id,
    salesforce_account_id,
  }: LinkSalesforceToOrganizationProps) => {
    return await instance.post(
      `/organizations/${organization_id}/link-salesforce`,
      {
        salesforce_account_id,
      }
    );
  },
  {
    onSuccess(queryClient) {
      queryClient.invalidateQueries(["salesforce-account"]);
    },
    successToast(_, { salesforce_account_id }) {
      if (!salesforce_account_id) {
        return {
          message: `Organization unlinked from Salesforce Account.`,
        };
      }

      return {
        message: `Organization linked to Salesforce Account.`,
      };
    },
    errorToast(_, { salesforce_account_id }) {
      if (!salesforce_account_id) {
        return {
          message: `Organization unlinked from Salesforce Account.`,
        };
      }
      return {
        message: `Failed to link organization to Salesforce Account.`,
      };
    },
  }
);
