import { useMemo } from "react";
import * as React from "react";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import range from "lodash/range";

import {
  Button,
  DrawerActions,
  DrawerContent,
  FormikSelectField,
  FormikSubmit,
  FormikTextInputField,
  MultiSelectOption,
  VStack,
} from "@smartrent/ui";

export interface VendorApplicationFormValues {
  name: string;
  key: string;
  max_codes_per_unit: number;
}

interface VendorApplicationFormProps {
  organizationId: number;
  onSubmit: (
    values: VendorApplicationFormValues,
    actions: FormikHelpers<VendorApplicationFormValues>
  ) => void;
  onCancel: () => void;
}

const initialValues = {
  name: "",
  key: "",
  max_codes_per_unit: 5,
};

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter a name.").trim(),
  key: Yup.string().required("Please enter a key.").trim(),
  max_codes_per_unit: Yup.number().default(5),
});

export const VendorApplicationForm: React.FC<
  React.PropsWithChildren<VendorApplicationFormProps>
> = ({ organizationId, onSubmit, onCancel }) => {
  const codeOptions = useMemo<MultiSelectOption[]>(
    () =>
      range(1, 30).map((i) => ({
        label: `${i}`,
        value: `${i}`,
      })),
    []
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <DrawerContent>
          <VStack spacing={8}>
            <FormikTextInputField name="name" label="Name" required />
            <FormikTextInputField name="key" label="Key" required />
            <FormikSelectField
              name="max_codes_per_unit"
              label="Max Codes Per Unit"
              options={codeOptions}
            />
          </VStack>
        </DrawerContent>
        <DrawerActions>
          <Button onPress={onCancel} variation="plain">
            Cancel
          </Button>
          <FormikSubmit label="Create" />
        </DrawerActions>
      </>
    </Formik>
  );
};
