import * as React from "react";
import { StyleProp, ViewStyle, StyleSheet, View } from "react-native";

import { Box, useTheme } from "@smartrent/ui";

import { PanelHeader } from "./PanelHeader";

export interface PanelProps {
  title?: React.ReactNode;
  action?: React.ReactNode;
  footer?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const Panel: React.FC<React.PropsWithChildren<PanelProps>> = ({
  title,
  action,
  footer,
  style,
  children,
}) => {
  const { colors } = useTheme();

  return (
    <Box style={[styles.root, style]}>
      {title || action ? <PanelHeader title={title} action={action} /> : null}
      {children}
      {footer ? (
        <View
          style={[
            styles.footer,
            { borderTopColor: colors.gray200, backgroundColor: colors.gray050 },
          ]}
        >
          {footer}
        </View>
      ) : null}
    </Box>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: 0,
  },
  footer: {
    borderTopWidth: 1,
  },
});
