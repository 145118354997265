import { AxiosError } from "axios";

import { ConnectionStatus } from "@/utils/zipato";

export enum HubRefurbishErrorNames {
  BoxIsNull = "BoxIsNull",
  HubIsLocked = "HubIsLocked",
  CMWConnectError = "CMWConnectError",
  SolicitApiError = "SolicitApiError",
  HubAccountNotFound = "HubAccountNotFoundError",
  SupportApiError = "SupportApiError",
  UnexpectedError = "UnexpectedError",
  BoxAndBoxSessionNotFound = "BoxAndBoxSessionNotFound",
  ZipatoAccountNotFound = "ZipatoAccountNotFound",
  ActiveHubPrepSession = "ActiveHubPrepSession",
}

// Formik Fields we use across all of Hub Refurbish steps
export enum HubRefurbishFormikFieldNames {
  Serials = "serials",
  Online = "online",
  Offline = "offline",
  Error = "error",
  Removed = "removed",
  Success = "success",
  JobErrors = "job-errors",
}
// labels for the formik fields
export enum HubRefurbishFormikFieldLabels {
  Serials = "Serial Numbers",
  Online = "Online",
  Offline = "Offline",
  Error = "Error",
  Removed = "Removed",
  Success = "Success",
  JobErrors = "Job Error",
}

// react query key's, used to access the cache with reactQueryClient hook
export enum HubRefurbishReactQueryKeys {
  HubRefurbishJob = "hub-refurbish-job",
  HubRefurbishJobMutation = "hub-refurbish-job-mutation",
  RedisStats = "zipato-redis-stats-batch-query",
}

export enum HubRefurbishBatchStatus {
  Removed = "removed",
  Disabled = "disabled",
  Loading = "loading",
}
export enum FactorySnapshotStatus {
  Success = "success",
  Error = "error",
  Unknown = "unknown",
}

// step navigation query param keys
export enum HubRefurbishStep {
  Reset,
  HubsOffline,
  ResetHubs,
  HubsOnlineWithSD,
  ProcureHubs,
  Home,
}

export interface RefurbishHub extends Partial<HubRefurbishBatchJob> {
  serial: string;
  isToBeRemoved?: boolean;
  isDisabled?: boolean;
  status?: ConnectionStatus;
  isError?: boolean;
}

export interface RefurbishHubDict
  extends Record<RefurbishHub["serial"], RefurbishHub> {}

// couldn't find this in lodash exports so just made one here
export type Dictionary<T> = Record<string, T>;

// the type of virtualbox component which is rendered in the virtualGrid
export type VirtualBox = "redis" | "hub-refurbish-job" | "placeholder";

export type HubRefurbishStatus = HubRefurbishBatchStatus | ConnectionStatus;
export type HubRefurbishFactorySnapshotStatus =
  | HubRefurbishBatchStatus
  | FactorySnapshotStatus;

export interface HubRefurbishError extends Partial<Omit<AxiosError, "stack">> {
  error: any;
  context: {
    name?: Error["name"];
    message?: Error["message"];
    createdAt?: string;
    expiresAt: string;
    ttl: number;
  };
}

export interface HubRefurbishBatchJob {
  serial: string;
  step: number;
  stepName: string;
  totalSteps: number;
  progress: number;
  isSuccess?: boolean;
  isError?: boolean;
  error?: HubRefurbishError;
}

export interface RunHubRefurbishBatchJobError extends HubRefurbishBatchJob {
  isError: boolean;
  error: HubRefurbishError;
}

export interface RunHubRefurbishBatchJobResponse {
  job: { id: string; data: any };
  children: any[];
}

export interface RunHubRefurbishBatchJobQueryData {
  jobs: HubRefurbishBatchJob[];
  errorJobs: HubRefurbishBatchJob[];
  successJobs: HubRefurbishBatchJob[];
}

export interface HubRefurbishBatchJobQueryResponse {
  jobsDict: RefurbishHubDict;
  totalCompletedJobs: number;
}

export interface RedisStatsFormikValues {
  serials: string;
  online?: string[];
  offline?: string[];
  error?: string[];
  removed?: string[];
}

export interface HubRefurbishJobFormikValues {
  success: RunHubRefurbishBatchJobError[];
  error: RunHubRefurbishBatchJobError[];
  removed?: string[];
}

export interface HubRefurbishRedisStatsFormikValues {
  serials: string;
  online?: RefurbishHub[];
  offline?: RefurbishHub[];
  error?: RefurbishHub[];
  removed?: string[];
}
