import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Typography, Button, AlertDialog, ConfirmDialog } from "@smartrent/ui";
import { Signal, QuestionCircleBorder } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { SyncCellStatsMutation } from "@/api";
import { HubRequest } from "@/types";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";

export interface SyncCellStatsProps {
  hub: HubRequest;
  serial: string;
}
export const SyncCellStats: React.FC<
  React.PropsWithChildren<SyncCellStatsProps>
> = ({ hub, serial }) => {
  const {
    onOpen: openHelp,
    onClose: onCloseHelp,
    visible: visibleHelp,
  } = useModalState();
  const { onOpen: onOpenConfirm, onClose, visible } = useModalState();
  const [syncCellStats] = SyncCellStatsMutation();

  const onDialogConfirm = useCallback(async () => {
    onClose();
    await syncCellStats({
      hubSerial: serial,
    });
  }, [syncCellStats, serial, onClose]);

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Sync Cell Stats</Typography>
            {!hub?.online ? (
              <Typography type="caption" color="warning">
                Hub is offline
              </Typography>
            ) : null}
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton
            disabled={!hub?.online}
            icon={Signal}
            onClick={() => {}}
          />
        </CardBody>
        <CardFooter>
          <Button disabled={!hub?.online} onPress={onOpenConfirm}>
            Run Action
          </Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description="Initiate process for syncing the hub's cell stats. The syncing of cell stats can take 2 minutes or more depending on network connection. A mobile connection for the device is required."
        visible={visibleHelp}
        onClose={onCloseHelp}
      />

      <ConfirmDialog
        title="Are you sure? "
        visible={visible}
        onClose={onClose}
        onConfirm={onDialogConfirm}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
});
