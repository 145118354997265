import { StyleSheet, View } from "react-native";

import { HStack, Skeleton, useTheme, VStack } from "@smartrent/ui";

export const ContextMenuLoading = () => {
  return (
    <VStack spacing={8}>
      <ContextMenuLoadingSectionHeader />
      <ContextMenuLoadingItem />
      <ContextMenuLoadingItem />
      <ContextMenuLoadingItem />
      <ContextMenuLoadingSectionHeader />
      <ContextMenuLoadingItem />
      <ContextMenuLoadingItem />
    </VStack>
  );
};

export const ContextMenuLoadingSectionHeader = () => {
  const { colors, spacing } = useTheme();
  return (
    <VStack spacing={4} style={{ marginTop: spacing.xxs }}>
      <Skeleton
        width={118}
        height={18}
        style={[styles.skeletonRadius, { marginLeft: spacing.md }]}
      />
      <View
        style={[
          styles.divider,
          {
            borderBottomColor: colors.border,
            marginHorizontal: spacing.sm,
          },
        ]}
      />
    </VStack>
  );
};

export const ContextMenuLoadingItem = () => {
  const { spacing } = useTheme();
  return (
    <HStack align="center" spacing={4} style={{ marginLeft: spacing.sm }}>
      <Skeleton width={32} height={32} circle />
      <VStack spacing={4}>
        <Skeleton width={118} height={14} style={styles.skeletonRadius} />
        <Skeleton width={118} height={14} style={styles.skeletonRadius} />
      </VStack>
    </HStack>
  );
};

const styles = StyleSheet.create({
  divider: {
    borderBottomWidth: 1,
  },
  skeletonRadius: {
    borderRadius: 4,
  },
});
