import { MenuOption } from "@smartrent/ui";

import { OrganizationProps, PermissionProps } from "@/types";
import { useDrawerNav } from "@/common/AppDrawer";

interface GetOrganizationIntegrationProps {
  permissions: PermissionProps;
  drawer: ReturnType<typeof useDrawerNav>;
  organization: OrganizationProps;
}

export const getOrganizationIntegrationActions = ({
  permissions,
  drawer,
  organization,
}: GetOrganizationIntegrationProps): MenuOption[] | undefined => {
  const actions: MenuOption[] = [];

  if (permissions.lock_pms) {
    actions.push({
      label: "PM Sync Actions",
      onPress: () => {
        drawer.push("pmSyncActions", { organization });
      },
    });
  }

  return actions.length > 0 ? actions : undefined;
};
