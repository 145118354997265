import { useState } from "react";
import * as React from "react";
import {
  FormikSelectField,
  FormikSelectFieldProps,
  SelectField,
  SelectFieldProps,
} from "@smartrent/ui";

import { GroupProps } from "@/types";

import { selectQueryPropsGroups } from "./useSelectGroupField";

interface Props<T = GroupProps> extends Partial<SelectFieldProps<T>> {
  organizationId?: string;
}

export const SelectGroupField: React.FC<React.PropsWithChildren<Props>> = ({
  organizationId,
  ...props
}) => {
  const [selectedGroup, setSelectedGroup] = useState<GroupProps | undefined>(
    undefined
  );

  return (
    <SelectField<GroupProps>
      label="Select a Group"
      name="group"
      value={selectedGroup}
      onChange={setSelectedGroup}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ marketing_name }) => marketing_name}
      {...selectQueryPropsGroups({ organizationId })}
      {...props}
    />
  );
};

interface FormikProps<T = GroupProps>
  extends Partial<FormikSelectFieldProps<T>> {
  organizationId?: string;
}

export const FormikSelectGroupField: React.FC<
  React.PropsWithChildren<FormikProps>
> = ({ organizationId, ...props }) => {
  return (
    <FormikSelectField<GroupProps>
      label="Select a Group"
      name="group"
      getOptionValue={({ id }) => id}
      getOptionLabel={({ marketing_name }) => marketing_name}
      {...selectQueryPropsGroups({ organizationId })}
      {...props}
    />
  );
};
