import { useMemo } from "react";

import { Breadcrumbs, useQueryStringField } from "@smartrent/ui";

import { useGroupQuery, useOrganizationQuery } from "@/api";
import { useGlobalContext } from "@/layout/Context";

export const UrlOrgGroupBreadcrumbs = ({
  currentPage,
}: {
  currentPage?: string;
}) => {
  const group_id = useQueryStringField("group_id");
  const organizationId = useQueryStringField("organization_id");

  const { match } = useGlobalContext();

  const possibleGroupFromURL = match?.params?.groupId || group_id;

  // 1. GET GROUP
  const { data: group } = useGroupQuery(Number(possibleGroupFromURL), {
    enabled: possibleGroupFromURL && Number(possibleGroupFromURL) > 0,
  });

  const orgId = useMemo(() => {
    // 1. try to use the organizationId from table filter first
    if (organizationId && Number(organizationId) > 0) {
      return Number(organizationId);
    }

    // 2. then, check if organizationId exists on the group record response
    if (group?.organization_id && Number(group?.organization_id) > 0) {
      return Number(group.organization_id);
    }

    // otherwise, return undefined so that we disable org query
    return undefined;
  }, [group?.organization_id, organizationId]);

  // 2. GET ORG
  const { data: org } = useOrganizationQuery(orgId);

  const orgName = useMemo(
    () => (org?.organization?.name ? org.organization.name : "Organization"),
    [org?.organization?.name]
  );
  const groupName = useMemo(
    () => (group?.marketing_name ? group.marketing_name : "Group"),
    [group?.marketing_name]
  );

  const breadcrumbs = useMemo(() => {
    // if group id present, show org & group relationship
    if (possibleGroupFromURL) {
      return [
        { label: `${orgName}`, to: `/organizations/${orgId}` },
        { label: `${groupName}`, to: `/groups/${possibleGroupFromURL}` },
        { label: currentPage || "Users", to: "" },
      ];
    }
    // if only org id present, do not build group link
    if (organizationId) {
      return [
        { label: `${orgName}`, to: `/organizations/${orgId}` },
        { label: "Users", to: "" },
      ];
    }
    return [];
  }, [
    currentPage,
    possibleGroupFromURL,
    groupName,
    orgId,
    orgName,
    organizationId,
  ]);

  return <Breadcrumbs items={breadcrumbs} />;
};
