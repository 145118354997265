import * as React from "react";

import { Breadcrumbs } from "@smartrent/ui";

import { group, groups, organization, organizations } from "@/utils/links";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";

export const SowBreadcrumbs: React.VFC = () => {
  const {
    scopeOfWorkQuery: { data },
  } = useScopeOfWorkContext();

  const items = [
    {
      label: "Home",
      to: "/",
    },
    {
      label: "Scope of Work",
      to: "/scope-of-work",
    },
  ];

  if (data?.organization) {
    items.push({
      label: data?.organization?.name || "Orgs",
      to: data?.organization?.id
        ? organization(data?.organization?.id)
        : organizations,
    });
  }

  if (data?.group) {
    items.push({
      label: data?.group?.marketing_name ? data.group.marketing_name : `Groups`,
      to: data?.group?.id ? group(data?.group?.id) : groups,
    });
  }

  items.push({
    label: data?.name || `Report Name`,
    to: "/",
  });

  return <Breadcrumbs items={items} />;
};
