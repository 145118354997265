import * as React from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";

import { useTheme } from "@smartrent/ui";
import { IconProps } from "@smartrent/icons";

export interface CircleIconButtonProps {
  icon: React.ComponentType<React.PropsWithChildren<IconProps>>;
  disabled?: boolean;
  onClick: () => void;
}
export const CircleIconButton: React.FC<
  React.PropsWithChildren<CircleIconButtonProps>
> = ({ icon: Icon, disabled = false, onClick }) => {
  const { colors } = useTheme();
  return (
    <TouchableOpacity disabled={disabled} onPress={onClick}>
      <View
        style={[
          styles.circleButton,
          {
            borderColor: disabled ? colors.gray200 : colors.tertiary,
            backgroundColor: colors.pageBackground,
          },
        ]}
      >
        <Icon size={48} color={disabled ? colors.gray200 : colors.tertiary} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  circleButton: {
    width: 124,
    height: 124,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 4,
    borderRadius: "50%" as any,
  },
});
