import * as React from "react";
import { StyleSheet, View } from "react-native";

import { Button, ButtonProps } from "@smartrent/ui";

interface ActionsProps {
  actions?: Array<ButtonProps & { key?: String; content: String }>;
  children?: React.ReactNode;
  style?: object;
}

const isNil = (item: any) =>
  item === null ||
  item === undefined ||
  (Array.isArray(item) && item.length === 0);

export default ({ actions, children, style }: ActionsProps) => {
  if (!isNil(children)) {
    return <View style={styles.actions}>{children}</View>;
  }

  return (
    <View style={[styles.actions, style]}>
      {actions.map(({ key, content, ...rest }, index) => (
        <View
          key={`action_button_${key || index}`}
          style={styles.actionButtons}
        >
          <Button {...rest}>{content}</Button>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  actions: {
    flex: 1,
    flexDirection: "row",
    flexShrink: 0,
    paddingTop: 24,
    marginBottom: -16,
  },

  actionButtons: {
    flex: 1,
    padding: 8,
  },
});
