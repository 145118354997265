import * as React from "react";
import { StyleSheet, View } from "react-native";

import { Panel, Typography, useTheme } from "@smartrent/ui";

import Layout from "@/layout/Layout";

import { HubRefurbishBreadcrumbs } from "@/pages/hub-refurbish/HubRefurbishBreadcrumbs";

export const HubRefurbishKillSwitchOn: React.VFC = () => {
  const { colors } = useTheme();

  return (
    <Layout>
      <View style={styles.breadcrumbs}>
        <HubRefurbishBreadcrumbs />
      </View>
      <Panel style={[styles.panel, { backgroundColor: colors.pageBackground }]}>
        <View
          style={[styles.container, { backgroundColor: colors.pageBackground }]}
        >
          <Typography
            style={styles.text}
            type="bodyLargeSemibold"
            color="labelText"
          >
            Hub Refurbish has been temporarily disabled, we're sorry for the
            inconvenience.
          </Typography>
        </View>
      </Panel>
    </Layout>
  );
};

const styles = StyleSheet.create({
  breadcrumbs: {
    marginVertical: 16,
    marginHorizontal: 20,
  },
  panel: {
    margin: "2%",
  },
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "5%",
  },
  text: {
    justifyContent: "flex-start",
    alignItems: "center",
  },
});
