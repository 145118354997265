import { useMemo } from "react";
import { ScrollView, View, StyleSheet } from "react-native";
import { sortBy, upperFirst } from "lodash-es";
import { Text } from "@smartrent/ui";

import { VerificationProps } from "../../types";

import VerificationImage from "./VerificationImage";

interface Attempt {
  type: "front" | "back" | "selfie";
  image: string; // path to image, not the image itself
  inserted_at: string;
  reason: string;
}

export default function VerifyRow({
  verification,
  type,
  onUpdate,
}: {
  verification: VerificationProps;
  type: "front" | "back" | "selfie";
  onUpdate?: Function;
}) {
  const label = upperFirst(type);
  const attempts = useMemo(() => {
    return verification && verification.attempts_images
      ? sortBy(
          verification.attempts_images.filter((attempt: Attempt) => {
            return attempt.type === type;
          }),
          (attempt: Attempt) => {
            return attempt.inserted_at;
          }
        ).reverse()
      : [];
  }, [verification, type]);

  const verifiedPath = `${type}_path`; // e.g. verification.front_path, verification.back_path, verification.selfie_path

  const isOpen = !(verification.rejected_at || verification.verified_at);

  return (
    <View style={{ marginBottom: 16 }}>
      <Text type="title2" style={styles.imageHeading}>
        {label}
      </Text>
      {attempts.length > 0 && !verification[verifiedPath] && isOpen && (
        <Text>(Click on the approved image)</Text>
      )}
      <ScrollView horizontal alwaysBounceHorizontal>
        {verification[verifiedPath] || attempts.length > 0 ? (
          <>
            {/* saved verified image from DB */}
            {verification[verifiedPath] && (
              <VerificationImage
                label="Approved"
                isValid={true}
                path={verification[verifiedPath]}
              />
            )}
            {attempts.map((attempt: Attempt, index: number) => (
              <VerificationImage
                key={index}
                label={`Attempt ${attempts.length - (index + 1) + 1}`}
                isValid={false}
                path={attempt.image}
                reason={attempt.reason}
                selectAcceptedImage={
                  // Don't allow selection of an image if the image has been approved for this type
                  // or if the verification isn't open anymore
                  verification[verifiedPath] || !isOpen
                    ? null
                    : () => {
                        const shouldUpdate = window.confirm(
                          "Are you sure you want this to become the approved image?"
                        );
                        if (shouldUpdate && onUpdate && isOpen) {
                          onUpdate(attempt.image);
                        }
                      }
                }
              />
            ))}
          </>
        ) : (
          <View style={{ marginBottom: 16 }}>
            <Text>No Images Uploaded</Text>
          </View>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  imageHeading: {
    marginTop: 16,
    marginBottom: 8,
    fontWeight: "bold",
    fontSize: 24,
  },
});
