import * as React from "react";

import { Button, HStack, useTheme } from "@smartrent/ui";

import { CaretUp, CaretDown } from "@smartrent/icons";

export const SowSortArrows: React.FC<
  React.PropsWithChildren<{
    index: number;
    length: number;
    current_order: string[];
    scope_of_work_id: string;
    onPress: Function;
    isLoading: boolean;
  }>
> = ({
  index,
  length,
  current_order,
  scope_of_work_id,
  onPress,
  isLoading,
}) => {
  const { colors } = useTheme();
  return (
    <HStack spacing={4}>
      {index === 0 ? null : (
        <Button
          variation="plain"
          onPress={() =>
            onPress({
              scope_of_work_id,
              current_order,
              index,
              direction: "up",
            })
          }
          disabled={isLoading}
        >
          <CaretUp
            color={
              isLoading
                ? colors.animatedPlaceholder
                : colors.iconsWithinComponents
            }
          />
        </Button>
      )}
      {index === length - 1 ? null : (
        <Button
          variation="plain"
          onPress={() =>
            onPress({
              scope_of_work_id,
              current_order,
              index,
              direction: "down",
            })
          }
          disabled={isLoading}
        >
          <CaretDown
            color={
              isLoading
                ? colors.animatedPlaceholder
                : colors.iconsWithinComponents
            }
          />
        </Button>
      )}
    </HStack>
  );
};
