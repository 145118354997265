import { useState, useMemo } from "react";
import { kebabCase } from "lodash-es";
import { Typography, Button, HStack } from "@smartrent/ui";
import { Download, Visible, Invisible } from "@smartrent/icons";
import { View, StyleSheet } from "react-native";
import { CSVLink } from "react-csv";

import { formatDate } from "@/utils";

import { Table } from "@/common/sr-table";

import { MonthlyReportProps } from "@/types";
import { EM_DASH } from "@/utils/chars";

export interface MonthlyReportTableProps extends MonthlyReportProps<any> {
  filters: Record<string, any>;
  reportName: string;
}

export default function MonthlyReportTable({
  name,
  description,
  organizations,
  groups,
  columns,
  rows,
  filters,
  supportedFilters,
  lastRanAt,
  reportName,
}: MonthlyReportTableProps) {
  const [collapsed, setCollapsed] = useState(false);
  const [currentPage, setCurrentPage] = useState(filters?.page || 1);
  const DISPLAY_ON_SCREEN = 1000;
  const tableColumns = useMemo(
    () =>
      columns?.map((column, index) => ({
        header: column,
        name: column,
        style: styles.column,
        render: ({ row }: { row: Array<string> }) => {
          return <Typography>{row?.[index] ? row[index] : EM_DASH}</Typography>;
        },
      })),
    [columns]
  );
  // Example:
  //  report      : CMW Monthly Report / Organization User Counts
  //  csvFileName : cmw-organization-user-counts-2021-21-04-112137.csv
  const buildCsvFileName = useMemo(() => {
    const orgName = organizations?.length === 1 ? organizations?.[0]?.name : "";
    const groupName = groups?.length === 1 ? groups?.[0]?.marketing_name : "";
    const timestamp = formatDate({
      date: lastRanAt,
      pattern: "yyyy MM dd HHmmss",
    });
    const kebabName = kebabCase(
      [orgName, groupName, reportName, name, timestamp].join(" ")
    );
    return `${kebabName}.csv`;
  }, [organizations, groups, reportName, name, lastRanAt]);

  return (
    <View key={name} style={styles.card}>
      <View style={styles.cardHeader}>
        <View>
          <View style={styles.cardText}>
            <Typography type="title3">{name}</Typography>
          </View>
          <View style={styles.cardText}>
            <Typography type="body">{description}</Typography>
          </View>
          <View style={styles.cardText}>
            <Typography color="helperText" type="caption">
              Supported filters: {supportedFilters?.join(", ")}
            </Typography>
          </View>
        </View>

        <View style={styles.cardIcons}>
          <HStack spacing={8}>
            <Button onPress={() => setCollapsed(!collapsed)} variation="plain">
              {collapsed ? (
                <Invisible size={24} color="#000" />
              ) : (
                <Visible size={24} color="#000" />
              )}
            </Button>
            <CSVLink
              data={rows as Array<string[]>}
              headers={columns as string[]}
              filename={buildCsvFileName}
            >
              <Download size={22} color="#000" />
            </CSVLink>
          </HStack>
        </View>
      </View>

      {!collapsed && (
        <View>
          <View>
            <View>
              <Typography style={styles.cardText} type="caption">
                <strong>Rows</strong>:{" "}
                {rows?.length ? rows.length.toLocaleString() : 0}
              </Typography>
            </View>

            <View>
              <Typography style={styles.cardText} type="caption">
                <strong>Organizations</strong>:{" "}
                {organizations?.length ? (
                  organizations.map(({ name }) => name).join(", ")
                ) : (
                  <span>All organizations</span>
                )}
              </Typography>
            </View>

            <View>
              <Typography style={styles.cardText} type="caption">
                <strong>Groups</strong>:{" "}
                {groups?.length ? (
                  groups.map(({ marketing_name }) => marketing_name).join(", ")
                ) : (
                  <span>All groups</span>
                )}
              </Typography>
            </View>
          </View>
          <Table
            loading={false}
            data={
              rows?.length
                ? rows?.slice(
                    (currentPage - 1) * DISPLAY_ON_SCREEN,
                    (currentPage - 1) * DISPLAY_ON_SCREEN + DISPLAY_ON_SCREEN
                  )
                : []
            }
            // data={rows}
            columns={tableColumns}
            noRecordsText="No Records Found"
            totalRecords={rows?.length}
            totalPages={Math.ceil(
              rows?.length ? rows?.length / DISPLAY_ON_SCREEN : 1
            )}
            currentPage={currentPage}
            pageSize={DISPLAY_ON_SCREEN}
            onPageChange={setCurrentPage}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  column: { flexDirection: "row", flexWrap: "wrap" },
  card: {
    marginVertical: 8,
  },
  cardHeader: {
    alignContent: "center",
  },
  cardText: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  cardIcons: {
    padding: 4,
    alignSelf: "flex-end",
  },
});
