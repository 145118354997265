import * as React from "react";
import { StyleSheet } from "react-native";

import { Typography, useTheme, VStack } from "@smartrent/ui";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";
import { HubRefurbishStep } from "@/types";

export const HubRefurbishStepLabels: Record<HubRefurbishStep, string> = {
  [HubRefurbishStep.Home]: "",
  [HubRefurbishStep.Reset]: "",
  [HubRefurbishStep.HubsOffline]: "Take Hubs Offline",
  [HubRefurbishStep.ResetHubs]: "Factory Reset Hub",
  [HubRefurbishStep.HubsOnlineWithSD]:
    "Replace SD Cards (optional) & Bring Hubs Online",
  [HubRefurbishStep.ProcureHubs]: "Procure Hubs (Refurbish action)",
};

interface StepInfoProps {
  step: HubRefurbishStep;
}

const StepInfo = ({ step }: StepInfoProps) => {
  const { step: activeStep } = useHubRefurbishContext();
  const isActive = step === activeStep;
  return (
    <>
      <Typography
        type={isActive ? "bodySmallSemibold" : "bodySmall"}
        color={isActive ? "textSecondary" : "mutedBackgroundGrayscale"}
      >
        Step {step}:
      </Typography>
      <Typography
        type={isActive ? "bodySmallSemibold" : "bodySmall"}
        color={isActive ? "textPrimary" : "mutedBackgroundGrayscale"}
      >
        {HubRefurbishStepLabels[step]}
      </Typography>
    </>
  );
};

export const HubRefurbishStepsInfo: React.VFC = () => {
  const { colors } = useTheme();
  return (
    <VStack spacing={8} style={styles.root}>
      <Typography
        style={[styles.header, { borderBottomColor: colors.gray100 }]}
        type="bodySmallSemibold"
      >
        ACTIONS TO OCCUR
      </Typography>
      <StepInfo step={HubRefurbishStep.HubsOffline} />
      <StepInfo step={HubRefurbishStep.ResetHubs} />
      <StepInfo step={HubRefurbishStep.HubsOnlineWithSD} />
      <StepInfo step={HubRefurbishStep.ProcureHubs} />
    </VStack>
  );
};

const styles = StyleSheet.create({
  root: {
    marginTop: 16,
  },
  header: { borderBottomWidth: 1 },
});
