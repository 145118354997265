import { StyleSheet, View } from "react-native";
import { useTheme, Skeleton } from "@smartrent/ui";

export const TaskListItemLoading = () => {
  const { shadows, colors } = useTheme();

  return (
    <View
      style={[
        styles.taskListItem,
        shadows.regular,
        { backgroundColor: colors.white },
      ]}
    >
      <View style={styles.taskInformation}>
        <Skeleton width={170} height={30} />
        <View style={styles.status}></View>
        <View>
          <Skeleton width={100} height={24} />
          <Skeleton width={100} height={30} style={styles.nameSkeleton} />
        </View>
        <View style={styles.buttonContainerSkeleton}>
          <Skeleton style={styles.buttonSkeleton} />
        </View>
      </View>
      <View style={styles.taskPicture}>
        <Skeleton style={styles.imageSkeleton} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  taskListItem: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 8,
    minHeight: 220,
  },
  taskInformation: {
    flex: 1,
    padding: 16,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  taskPicture: {
    flex: 1,
    height: "100%",
  },
  status: {
    flexDirection: "row",
    marginVertical: 8,
  },
  nameSkeleton: {
    marginTop: 8,
  },
  buttonContainerSkeleton: {
    height: 40,
  },
  buttonSkeleton: {
    flex: 1,
  },
  imageSkeleton: {
    height: "100%",
    flex: 1,
  },
});
