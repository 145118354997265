import { useFormikContext } from "formik";
import { useMemo } from "react";
import * as React from "react";
import { View } from "react-native";

import { ActivityIndicator, FlatList, Skeleton } from "@smartrent/ui";

import { Accordion } from "@/common/Accordion";

import { HubRefurbishJobErrorsListItem } from "@/pages/hub-refurbish/hub-refurbish-job/HubRefurbishJobErrorsListItem";
import { HubRefurbishErrorsListTitle } from "@/pages/hub-refurbish/HubRefurbishErrorsListTitle";

import {
  HubRefurbishFormikFieldNames,
  HubRefurbishJobFormikValues,
} from "@/types";

export const HubRefurbishJobErrorsList: React.VFC = () => {
  const { values } = useFormikContext<HubRefurbishJobFormikValues>();

  const errorJobs = useMemo(
    () =>
      values && values[HubRefurbishFormikFieldNames.JobErrors]
        ? values[HubRefurbishFormikFieldNames.JobErrors]
        : [],
    [values]
  );

  const errorCount = useMemo(() => (errorJobs || []).length, [errorJobs]);

  return (
    <Accordion
      isDisabled={errorCount <= 0}
      initialOpenState={false}
      title={
        <HubRefurbishErrorsListTitle
          disabled={errorCount <= 0}
          value={`Error Details (${errorCount})`}
        />
      }
    >
      <View>
        <FlatList
          itemSeparators={true}
          keyExtractor={(item) => `hub-refurbish-step-2-error-${item.serial}`}
          data={errorJobs}
          LoadingItemComponent={ActivityIndicator}
          ItemComponent={HubRefurbishJobErrorsListItem}
          isLoading={false}
          totalRecords={errorCount}
          totalRecordsLoaded={errorCount}
          totalPages={errorCount > 0 ? 1 : 0}
          ListEmptyComponent={Skeleton as React.FC}
        />
      </View>
    </Accordion>
  );
};
