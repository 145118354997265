import { View, StyleSheet } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";
import { InformationSolid } from "@smartrent/icons";

import { useUnit } from "../UnitContext";

export default function UnitLocation() {
  const { colors } = useTheme();
  const { unit } = useUnit();

  return (
    <View>
      <Typography type="bodyLarge" color="textPrimary">
        {unit.street_address_1}
        {unit.street_address_2 ? " " : ""}
        {unit.street_address_2},{"\n"}
        {unit.city}, {unit.state} {unit.zip} {unit.country}
      </Typography>
      <Typography type="body" color="textSecondary">
        {unit.building ? `Building ${unit.building}` : ""}
        {unit.building && unit.floor ? ", " : ""}
        {unit.floor ? `Floor ${unit.floor}` : ""}
      </Typography>
      {unit.country === "US" ? (
        <View style={[styles.badgeBox, { borderColor: colors.error }]}>
          <InformationSolid size={32} color={colors.error} />
          <Typography style={styles.marginLeft} type="caption" color="error">
            WARNING: The country is currently saved as "US", which will prevent
            SMS messages from being sent. Please change this unit's country to
            "United States".
          </Typography>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  badgeBox: {
    flexDirection: "row",
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
    paddingRight: 16,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
  },
  marginLeft: { marginLeft: 8 },
});
