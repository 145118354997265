import { StyleSheet, View } from "react-native";

import { Typography } from "@smartrent/ui";

export const ColHeader = ({ index }) => {
  return (
    <View style={styles.root}>
      <Typography type="bodySmallSemibold">C{index + 1}</Typography>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
