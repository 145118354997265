import { useState, useEffect, useContext } from "react";
import * as React from "react";
import { first, shuffle } from "lodash-es";

import Context from "@/layout/Context";

import { LoadingDots } from "./LoadingDots";

const quotes = [
  "Poke the bear, get the horns",
  "Wisdom is repeating the same mistakes less frequently",
  "Give the gift of a smile today",
  "Leave no stone unturned, no lock unpaired",
  "Shout, shout, let it all out! Just not on the phone.",
  "Everything in moderation. Except production outages.",
  "Lost time is never found again. Same for data loss.",
  "Loading...",
  "Please enjoy the 🎶 while your 🎉 is reached...",
  "Magic 8-Ball says: Loading...",
  "After this short commercial break...",
];

export default function Loading({
  loading,
  children,
}: {
  loading?: boolean;
  children?: React.ReactNode;
}) {
  const { pageLoading, user } = useContext(Context);
  const [loadingText, setLoadingText] = useState("");
  useEffect(() => {
    setLoadingText(first(shuffle(quotes)));
  }, []);

  const isLoading = pageLoading || loading;

  let styles: React.CSSProperties = {
    border: "1px solid #eee",
    background: "#fefefe",
    boxShadow: "1px 1px #ddd",
    visibility: "visible",
    opacity: 1,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    height: "100%",
    transition: "",
  };

  if (!isLoading && user) {
    styles = {
      border: "1px solid #eee",
      background: "#fefefe",
      boxShadow: "1px 1px #ddd",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      visibility: "hidden",
      opacity: 0,
      transition: "visibility 0s 1s, opacity 1s linear",
      zIndex: 2,
      height: "100%",
    };
  }

  if (!pageLoading && user && children) {
    return <>{children}</>;
  }

  return (
    <>
      <div
        style={styles}
        className="u-flex u-flex-col u-flex-justify-center u-flex-align-items-center"
      >
        <div className="u-p32 u-m16">
          <div
            className="u-font30 u-mtop16 u-mbottom16 u-text-center"
            style={{ color: "#00aca0" }}
          >
            {loadingText}
          </div>
          <div className="u-flex u-flex-wrap u-flex-justify-center">
            <LoadingDots />
          </div>
        </div>
      </div>
    </>
  );
}
