import {
  parsePhoneNumberWithError,
  CountryCode,
  NumberFormat,
  FormatNumberOptions,
  ParseError,
} from "libphonenumber-js";

import { formatPhone as _formatPhoneV1 } from "@smartrent/formatters";

import * as Sentry from "@sentry/react";

import { EM_DASH } from "./chars";

/*
  @docs https://gitlab.com/catamphetamine/libphonenumber-js/-/tree/master#format-phone-number

    const phoneNumber = '+12133734253'

    Example of available formats:

    - INTERNATIONAL             => '+1 213 373 4253'
    - NATIONAL                  => '(213) 373-4253'
    - RFC3966                   => 'tel:+12133734253'
    - E.164                     => '+12133734253'
    - IDD, {fromCountry: "US"}  => '1 (213) 373-4253'

    Possible ParseError messages

    'INVALID_COUNTRY' | 'NOT_A_NUMBER' | 'TOO_SHORT' | 'TOO_LONG' | 'INVALID_LENGTH'
  */
export const formatPhone = ({
  phone,
  format = "NATIONAL",
  defaultCountry = "US",
  options,
}: {
  phone: string;
  format?: NumberFormat;
  defaultCountry?: CountryCode;
  options?: FormatNumberOptions;
}) => {
  if (!Boolean(phone)) {
    return EM_DASH;
  }

  try {
    const phoneNumber = parsePhoneNumberWithError(phone, defaultCountry);
    return phoneNumber.format(format, options);
  } catch (error) {
    if (error instanceof ParseError) {
      const { message } = error;
      if (message === "NOT_A_NUMBER") {
        return phone; // just send back raw value so user can see the phone number is invalid
      }
    } else {
      // send sentry exception since this error is unexpected
      Sentry.captureException(error, { extra: { phone } });
    }

    // fall back to using format from js-shared
    return _formatPhoneV1(phone);
  }
};
