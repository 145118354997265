import { useMemo } from "react";
import * as React from "react";

import { Breadcrumbs } from "@smartrent/ui";

import { useStepNavigationHook } from "@/pages/hub-refurbish/hooks/useStepNavigationHook";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";
import { HubRefurbishStep } from "@/types";

export const HubRefurbishBreadcrumbs: React.VFC = () => {
  const { step } = useHubRefurbishContext();

  const { link: homeLink } = useStepNavigationHook(HubRefurbishStep.Home);
  const { link: resetLink } = useStepNavigationHook(HubRefurbishStep.Reset);
  const { link: hubsOfflineLink } = useStepNavigationHook(
    HubRefurbishStep.HubsOffline
  );
  const { link: resetHubsLink } = useStepNavigationHook(
    HubRefurbishStep.ResetHubs
  );
  const { link: hubsOnlineWithSDLink } = useStepNavigationHook(
    HubRefurbishStep.HubsOnlineWithSD
  );

  const items = useMemo(() => {
    if (step === HubRefurbishStep.Home) {
      return [];
    }

    const items = [
      { label: "Hub Prep", to: homeLink },
      { label: "Refurbish Batch", to: resetLink },
      {
        label: `Step ${HubRefurbishStep.HubsOffline}`,
        to: hubsOfflineLink,
      },
      {
        label: `Step ${HubRefurbishStep.ResetHubs}`,
        to: resetHubsLink,
      },
      {
        label: `Step ${HubRefurbishStep.HubsOnlineWithSD}`,
        to: hubsOnlineWithSDLink,
      },
    ];

    return items.slice(0, step + 2);
  }, [
    homeLink,
    resetLink,
    step,
    hubsOfflineLink,
    resetHubsLink,
    hubsOnlineWithSDLink,
  ]);

  return <Breadcrumbs items={items} />;
};
