import { useState, useEffect, useRef, useCallback, useContext } from "react";
import * as React from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
} from "react-native";

import { useTheme } from "@smartrent/ui";
import { Close, KabobVerticalSolid } from "@smartrent/icons";

import Context from "@/layout/Context";

import { CardActionItemProps } from "../types";

import CardActionSheet from "./CardActionSheet";
import Box from "./Box";

interface MutableRefObject {
  current: any;
}

interface cardProps {
  actions?: CardActionItemProps[];
  heading: React.ReactNode;
  children: React.ReactNode;
  customStyles?: StyleProp<ViewStyle>;
  customHeaderStyles?: StyleProp<ViewStyle>;
}

export default function Card({
  actions,
  heading,
  children,
  customStyles,
  customHeaderStyles,
}: cardProps) {
  const { isDesktop } = useContext(Context);
  const { colors } = useTheme();
  const [isShowingActionSheet, setIsShowingActionSheet] =
    useState<boolean>(false);
  const actionSheetElement: MutableRefObject = useRef(null);
  const windowClickCallback = useCallback((event: any) => {
    if (
      actionSheetElement.current &&
      event.target !== actionSheetElement &&
      !actionSheetElement.current.contains(event.target)
    ) {
      setIsShowingActionSheet(false);
      window.removeEventListener("click", windowClickCallback);
    }
  }, []);

  useEffect(() => {
    if (isShowingActionSheet) {
      window.addEventListener("click", windowClickCallback);
    }
  }, [isShowingActionSheet, windowClickCallback]);

  return (
    <Box
      style={[
        styles.card,
        { width: !isDesktop ? "100%" : 480 },
        customStyles || {},
      ]}
    >
      <View
        style={[
          styles.cardHeader,
          {
            backgroundColor: colors.pageBackground,
            borderBottomColor: colors.gray100,
          },
          customHeaderStyles || {},
        ]}
      >
        <div
          style={{ position: "absolute", right: 0, top: 0 }}
          ref={actionSheetElement}
        >
          {actions && actions.length > 0 && (
            <TouchableOpacity
              style={styles.actionKabob}
              onPress={() => setIsShowingActionSheet(!isShowingActionSheet)}
            >
              {isShowingActionSheet ? (
                <View
                  style={{
                    backgroundColor: "#efefef",
                    padding: 12,
                  }}
                >
                  <Close color="#00AC9F" />
                </View>
              ) : (
                <View style={{ paddingVertical: 18, paddingHorizontal: 8 }}>
                  <KabobVerticalSolid color="#000" />
                </View>
              )}
            </TouchableOpacity>
          )}
          {actions && isShowingActionSheet && (
            <CardActionSheet
              actions={actions}
              onClick={() => setIsShowingActionSheet(!isShowingActionSheet)}
            />
          )}
        </div>
        {heading}
      </View>
      <View style={styles.cardBody}>{children}</View>
    </Box>
  );
}

const styles = StyleSheet.create({
  card: {
    // alignSelf: "center",
    padding: 0,
    position: "relative",
    zIndex: 1,
  },
  cardHeader: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    zIndex: 1,
    borderBottomWidth: 1,
  },
  cardBody: {
    padding: 12,
  },
  actionKabob: {
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10,
  },
});
