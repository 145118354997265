import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Typography, Button, AlertDialog, ConfirmDialog } from "@smartrent/ui";
import { WireDisconnected, QuestionCircleBorder } from "@smartrent/icons";
import { useModalState } from "@smartrent/hooks";

import { useHubAccountMutation } from "@/api";

import { Card, CardHeader, CardBody, CardFooter } from "@/common/card/index";
import { CircleIconButton } from "@/common/CircleIconButton";

export interface DetachHubProps {
  serial: string;
}
export const DetachHub: React.FC<React.PropsWithChildren<DetachHubProps>> = ({
  serial,
}) => {
  const {
    onOpen: openHelp,
    onClose: onCloseHelp,
    visible: visibleHelp,
  } = useModalState();
  const { onOpen: onOpenConfirm, onClose, visible } = useModalState();
  const [mutateHubAccount] = useHubAccountMutation();

  const onDialogConfirm = useCallback(async () => {
    onClose();
    await mutateHubAccount({
      serial,
      params: { attached: false },
    });
  }, [mutateHubAccount, serial, onClose]);

  return (
    <>
      <Card style={styles.card}>
        <CardHeader>
          <View>
            <Typography>Detach Hub</Typography>
          </View>
          <TouchableOpacity onPress={openHelp}>
            <QuestionCircleBorder size={22} />
          </TouchableOpacity>
        </CardHeader>
        <CardBody style={styles.cardBody}>
          <CircleIconButton icon={WireDisconnected} onClick={() => {}} />
        </CardBody>
        <CardFooter>
          <Button onPress={onOpenConfirm}>Run Action</Button>
        </CardFooter>
      </Card>

      <AlertDialog
        title="Help"
        description="Detach hub should be used if the hub is attached in CMW, but doesn't have a Zipato account."
        visible={visibleHelp}
        onClose={onCloseHelp}
      />

      <ConfirmDialog
        title="Are you sure?"
        visible={visible}
        onClose={onClose}
        onConfirm={onDialogConfirm}
      />
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    flexGrow: 0,
    width: 296,
    margin: 8,
  },
  cardBody: {
    alignItems: "center",
  },
});
