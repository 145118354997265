export const feedbackComponents = [
  {
    label: "Tours (TOURS)",
    value: "TOURS",
  },
  {
    label: "SupportRent (SUPP)",
    value: "SUPP",
  },
  {
    label: "Resident (RES)",
    value: "RES",
  },
  {
    label: "Alloy Pass (PASS)",
    value: "PASS",
  },
  {
    label: "Parking (PARK)",
    value: "PARK",
  },
  {
    label: "Alloy Install (INSTALL)",
    value: "INSTALL",
  },
  {
    label: "CMW (CMW)",
    value: "CMW",
  },
  {
    label: "CMM (CMM)",
    value: "CMM",
  },
  {
    label: "Alloy Access (AA)",
    value: "AA",
  },
];
