import { useMemo } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { startCase, orderBy } from "lodash-es";

import { Typography, useTheme } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { UserProps } from "@/types";

import { useGetUserWebhooksQuery } from "@/api/users";

export interface EmailWebhooksProps {
  user: UserProps;
}

export const EmailWebhooks: React.FC<
  React.PropsWithChildren<EmailWebhooksProps>
> = ({ user }) => {
  const { colors } = useTheme();
  const { data, isLoading } = useGetUserWebhooksQuery(user);

  const webhooks = useMemo(() => {
    return data?.webhooks ?? [];
  }, [data]);

  const orderedWebhooks = orderBy(webhooks || [], ["inserted_at"], ["desc"]);

  const getColorForEvent = (event: string) => {
    switch (event.toLowerCase()) {
      // for success events we style them green
      case "open":
      case "click":
      case "delivered":
        return "success";
      // for failure events we style them red
      case "bounce":
      case "dropped":
        return "error";
      // for other events we style them grey
      default:
        return "textSecondary";
    }
  };

  return (
    <View style={styles.container}>
      {isLoading ? <Typography>Loading...</Typography> : null}
      {!orderedWebhooks.length && !isLoading ? (
        <Typography color="textSecondary">No webhooks found</Typography>
      ) : null}
      {orderedWebhooks.map(({ subject, event, inserted_at, response }, idx) => (
        <View
          key={idx}
          style={[styles.emailRow, { borderColor: colors.border }]}
        >
          <Typography type="title6" style={styles.title}>
            {subject || "(no subject)"}
          </Typography>
          <View>
            <Typography style={styles.smallText}>
              {
                <Typography
                  style={[styles.bold, styles.smallText]}
                  color={getColorForEvent(event)}
                >
                  {startCase(event)}
                </Typography>
              }{" "}
              at {Helpers.formatDate(inserted_at)}{" "}
            </Typography>
            {response ? (
              <Typography style={styles.smallText}>
                <Typography style={[styles.bold, styles.smallText]}>
                  Details:{" "}
                </Typography>
                {response}
              </Typography>
            ) : null}
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxHeight: "300px",
    width: "100%",
    paddingHorizontal: 4,
    overflow: "scroll",
    backgroundColor: "#f9f9f9",
    borderRadius: 4,
  },
  emailRow: {
    paddingVertical: 4,
    borderBottomWidth: 1,
  },
  title: {
    marginBottom: 4,
  },
  bold: {
    fontWeight: "bold",
  },
  smallText: {
    fontSize: 12,
  },
});
