import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "querystring";

export const useQueryString = () => {
  const location = useLocation();

  const qs = useMemo(
    () => parse(location.search.replace("?", "")),
    [location.search]
  );

  return qs;
};

type TypeConverter<V> = (v: string) => V;

export function useQueryStringField<T = any>(
  field: string,
  convert: TypeConverter<T>,
  multiple: true
): T[];
export function useQueryStringField<T = any>(
  field: string,
  convert: TypeConverter<T>,
  multiple?: false
): T | undefined;

export function useQueryStringField<T = any>(
  field: string,
  convert: TypeConverter<T>,
  multiple?: boolean
): T[] | T | undefined {
  const qs = useQueryString();

  if (!(field in qs)) {
    return multiple ? [] : undefined;
  }

  const value = qs[field];

  if (multiple) {
    if (Array.isArray(value)) {
      return value.map((v) => convert(v));
    }

    return convert(value);
  }

  if (Array.isArray(value)) {
    return value.length > 0 ? convert(value[0]) : undefined;
  }

  return convert(value);
}
