import { View, Text, StyleSheet } from "react-native";
import { AnimatedPlaceholder, useTheme } from "@smartrent/ui";

export interface LegendItemProps {
  total?: number;
  color: string;
  description: string;
  loading?: boolean;
}

export const LegendItem = ({
  total = null,
  color,
  description,
  loading = false,
}: LegendItemProps) => {
  const { colors } = useTheme();

  return (
    <View style={styles.legendItem}>
      {loading ? (
        <AnimatedPlaceholder
          style={[styles.placeholder, { backgroundColor: colors.gray100 }]}
        />
      ) : (
        <>
          <View style={[styles.legendColor, { backgroundColor: color }]} />
          <Text style={[styles.text, { color: colors.gray800 }]}>
            {description}
            {total !== null ? ` (${total.toLocaleString()})` : ""}
          </Text>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  legendItem: {
    flexDirection: "row",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "30%",
    marginTop: 16,
    marginHorizontal: 8,
    alignItems: "center",
  },
  legendColor: {
    width: 16,
    height: 16,
    borderRadius: 8,
    marginRight: 8,
  },
  text: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  placeholder: {
    width: 100,
    height: 16,
  },
});
