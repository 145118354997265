import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import { compact } from "lodash-es";
import { Link } from "react-router-dom";

import {
  Calendar,
  Close,
  Community,
  Feedback,
  Flip,
  HighRiseBuilding,
  Home,
  Map,
  Mask,
  Stopwatch,
  Users,
  WaterDrop,
  List,
  ShieldPerson,
  ClipboardFilled,
} from "@smartrent/icons";
import { Text, useTheme } from "@smartrent/ui";
import { useModalState } from "@smartrent/hooks";

import Helpers from "@/lib/helpers";
import { hostname } from "@/utils";

import RoleSwitcher from "@/common/RoleSwitcher";
import { SupportRentLogo } from "@/common/supportrent-logo";
import { FeedbackModal } from "@/common/modals/FeedbackModal";

import { useGlobalContext, useUser } from "../Context";

const SideNav = () => {
  const { colors, fonts, shadows } = useTheme();
  const { setSidenavOpen, history } = useGlobalContext();
  const { permissions } = useUser();

  const {
    visible: feedbackModalVisible,
    onOpen: feedbackModalOnOpen,
    onClose: feedbackModalOnClose,
  } = useModalState();

  const sections = [
    {
      title: "",
      links: compact([
        {
          label: "Home",
          href: "/",
          onClick: () => {
            history.push("/");
            setSidenavOpen(false);
          },
          icon: Home,
        },
        {
          label: "Tours",
          href: "/tours",
          onClick: () => {
            history.push("/tours");
            setSidenavOpen(false);
          },
          icon: Map,
        },
        {
          label: "Installs",
          href: "/alloy-install",
          onClick: () => {
            history.push("/alloy-install");
            setSidenavOpen(false);
          },
          icon: Calendar,
        },

        permissions?.view_organizations
          ? {
              label: "Organizations",
              href: "/organizations",
              onClick: () => {
                history.push("/organizations");
                setSidenavOpen(false);
              },
              icon: Community,
            }
          : null,

        permissions?.view_groups
          ? {
              label: "Groups",
              href: "/groups",
              onClick: () => {
                history.push("/groups");
                setSidenavOpen(false);
              },
              icon: HighRiseBuilding,
            }
          : null,

        permissions?.view_users
          ? {
              label: "Property Staff Users",
              href: "/users?employee_role=property_staff",
              onClick: () => {
                history.push("/users?employee_role=property_staff");
                setSidenavOpen(false);
              },
              icon: Users,
            }
          : null,

        {
          label: "Leaks",
          href: "/leak-report",
          onClick: () => {
            history.push("/leak-report");
            setSidenavOpen(false);
          },
          icon: WaterDrop,
        },

        permissions?.view_sow
          ? {
              label: "SOW",
              href: "/scope-of-work",
              onClick: () => {
                history.push("/scope-of-work");
                setSidenavOpen(false);
              },
              icon: ClipboardFilled,
            }
          : null,

        {
          label: "Leave Feedback",
          onClick: () => {
            feedbackModalOnOpen();
          },
          icon: Feedback,
        },

        permissions?.modify_permissions
          ? {
              label: "Accounts",
              href: "/accounts",
              onClick: () => {
                history.push("/accounts");
                setSidenavOpen(false);
              },
              icon: ShieldPerson,
            }
          : null,

        permissions?.hub_prep
          ? {
              label: "Hub Prep",
              href: "/hub-prep",
              onClick: () => {
                history.push("/hub-prep");
                setSidenavOpen(false);
              },
              icon: Stopwatch,
            }
          : null,

        permissions?.reporting
          ? {
              label: "Reporting",
              href: "/reporting",
              onClick: () => {
                history.push("/reporting");
                setSidenavOpen(false);
              },
              icon: List,
            }
          : null,

        permissions?.feature_flags
          ? {
              label: "Feature Flags",
              href: "/feature-flags",
              onClick: () => {
                history.push("/feature-flags");
                setSidenavOpen(false);
              },
              icon: Flip,
            }
          : null,
      ]),
    },
    {
      title: "Account",
      links: [
        {
          label: "Logout",
          href: "",
          onClick: () => {
            Helpers.auth.logout();
            setSidenavOpen(false);
          },
          icon: Mask,
        },
      ],
    },
  ];

  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: colors.white,
          ...shadows.regular,
        },
      ]}
    >
      <ScrollView style={styles.scrollView}>
        <View style={styles.container}>
          <SupportRentLogo size={24} />
          <TouchableOpacity
            style={styles.icon}
            onPress={() => setSidenavOpen(false)}
          >
            <Close size={20} />
          </TouchableOpacity>
        </View>
        {sections.map((s) => (
          <View
            key={s.title}
            style={[styles.borders, { borderBottomColor: colors.gray100 }]}
          >
            {s.title ? (
              <Text variation="bold.title.three" style={styles.titleText}>
                {s.title}
              </Text>
            ) : null}
            {s.links.map((l) => {
              const Icon = l.icon;
              const Content = (
                <View style={[styles.item, { borderTopColor: colors.gray100 }]}>
                  <Icon color={colors.gray600} size={18} />
                  <Text style={[fonts.semibold, styles.labelText]}>
                    {l.label}
                  </Text>
                </View>
              );

              if (l.href) {
                return (
                  <Link
                    key={l.label}
                    to={l.href}
                    onClick={() => setSidenavOpen(false)}
                  >
                    {Content}
                  </Link>
                );
              }
              return (
                <TouchableOpacity key={l.label} onPress={l.onClick}>
                  {Content}
                </TouchableOpacity>
              );
            })}
          </View>
        ))}

        {window.location.hostname !== hostname.prod ? <RoleSwitcher /> : null}
      </ScrollView>
      <FeedbackModal
        visible={feedbackModalVisible}
        onClose={feedbackModalOnClose}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    height: "100%",
  },
  root: {
    width: 240,
    height: "100vh",
    justifyContent: "space-between",
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderTopWidth: 1,
  },

  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
  },
  icon: {
    padding: 4, // adds a little more hitbox to the icon
  },

  borders: {
    borderBottomWidth: 1,
    marginBottom: 24,
  },

  labelText: { marginLeft: 8 },
  titleText: {
    padding: 8,
  },
});

export default SideNav;
