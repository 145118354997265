import * as React from "react";
import { ListRenderItemInfo, StyleSheet } from "react-native";

import { Button, Link, ListItemContainer } from "@smartrent/ui";

import { HubRefurbishErrorNames, ZipatoRedisStatsProps } from "@/types";

import { HubRefurbishErrorMessage } from "@/pages/hub-refurbish/HubRefurbishErrorMessage";
import { useHubPrepTerminateMutation } from "@/api";

export const RedisStatsBatchErrorsListItem: React.FC<
  React.PropsWithChildren<ListRenderItemInfo<ZipatoRedisStatsProps>>
> = ({ item }) => {
  const [loadingTerminate, setLoadingTerminate] = React.useState(false);
  const [terminateHubPrep] = useHubPrepTerminateMutation();
  const onTerminatePress = async () => {
    setLoadingTerminate(true);
    await terminateHubPrep({ serial: item.serial });
    setLoadingTerminate(false);
  };

  return (
    <ListItemContainer
      style={styles.root}
      rightDetail={
        item.error?.context?.name ===
          HubRefurbishErrorNames.ActiveHubPrepSession && (
          <Button
            variation="outlined"
            size="small"
            onPress={onTerminatePress}
            isLoading={loadingTerminate}
            color="destructive"
          >
            Terminate Hub Prep
          </Button>
        )
      }
    >
      <Link style={styles.link} href={`/hubs/${item?.serial}`}>
        {item?.serial}
      </Link>
      <HubRefurbishErrorMessage {...item.error?.context} />
    </ListItemContainer>
  );
};

const styles = StyleSheet.create({
  root: {
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: 0,
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
  },
  link: { width: 225 },
});
