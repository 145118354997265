import * as React from "react";
import classNames from "classnames";

interface Props {
  type: "success" | "error" | "info" | "warning";
  children: React.ReactNode;
  className?: string;
  style?: Object;
}

export default function Alert({ type, children, className, style }: Props) {
  if (!children) {
    return null;
  }
  return (
    <div
      style={{ margin: 0, ...(style || {}) }}
      className={classNames("alert", className, {
        "alert--symbolic-success": type === "success",
        "alert--symbolic-error": type === "error",
        "alert--symbolic-knowledge": type === "info",
        "alert--symbolic-warning": type === "warning",
      })}
    >
      {children}
    </div>
  );
}
