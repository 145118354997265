import { View, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

import { useUser } from "@/layout/Context";

import { useAccountQuery } from "@/api/zipato";
import { ListValue } from "@/common/ListValue";
import { Spoiler } from "@/common/Spoiler";
import { CopyText } from "@/common/CopyText";

import { HubDebugTitleBar } from "./HubDebugTitleBar";

export default function ZipatoAccount({ serial }: { serial: string }) {
  const { permissions } = useUser();

  const {
    data: zipato,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useAccountQuery({
    serial,
  });

  return (
    <View>
      <HubDebugTitleBar
        title="Zipato Account"
        isLoading={isLoading || isFetching}
        onReloadClicked={refetch}
      />

      <View style={styles.error}>
        {isError ? (
          <Typography color="error">No account linked to hub</Typography>
        ) : null}
      </View>

      {zipato ? (
        <View style={styles.container}>
          <ListValue
            padBottom={false}
            label="Environment"
            value={zipato.zipato_environment}
          />

          <>
            <ListValue
              padBottom={false}
              label="Realm ID"
              value={zipato.realm_id}
            />

            <ListValue
              padBottom={false}
              label="Redirect Server ID"
              value={zipato.redirect_server_id}
            />

            <ListValue
              padBottom={false}
              label="Zipato Hub ID"
              value={zipato.zipato_hub_id}
            />

            <ListValue
              padBottom={false}
              label="Zipato User ID"
              value={zipato.zipato_user_id}
            />

            {permissions.hub_extended_details ? (
              <>
                {zipato?.hubAccount ? (
                  <View>
                    <Typography color="textSecondary">
                      Registered To Hub Account (attached):
                    </Typography>
                    <View style={styles.copyContainer}>
                      <Typography
                        color={
                          zipato?.hubAccount?.attached ? "textPrimary" : "error"
                        }
                        style={styles.value}
                      >
                        {zipato?.hubAccount?.attached ? "Yes" : "No"}
                      </Typography>
                    </View>
                  </View>
                ) : null}

                {zipato?.hubAccount?.zipato_username ? (
                  <View>
                    <Typography color="textSecondary">
                      Zipato Username
                    </Typography>
                    <View style={styles.copyContainer}>
                      <Typography style={styles.value}>
                        {zipato.hubAccount.zipato_username}
                      </Typography>
                      <CopyText text={zipato.hubAccount.zipato_username} />
                    </View>
                  </View>
                ) : null}

                {zipato?.hubAccount?.zipato_password ? (
                  <View>
                    <Typography color="textSecondary">
                      Zipato User Password
                    </Typography>
                    <Spoiler label="Click to reveal password">
                      <View style={styles.copyContainer}>
                        <Typography style={styles.value}>
                          {zipato.hubAccount.zipato_password}
                        </Typography>
                        <CopyText text={zipato.hubAccount.zipato_password} />
                      </View>
                    </Spoiler>
                  </View>
                ) : null}
              </>
            ) : null}
          </>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  error: {
    marginVertical: 8,
  },
  container: {
    padding: 8,
  },
  value: { fontWeight: "bold", marginRight: 8 },
  copyContainer: { flexDirection: "row", alignItems: "center" },
});
