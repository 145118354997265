import { useEffect, useState, useMemo } from "react";
import { StyleProp, ViewStyle } from "react-native";

import { useField, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  Button,
  FormSection,
  FormikTextInputField,
  Stack,
  FormikNumberInputField,
} from "@smartrent/ui";
import { useWindowDimensionsQuery } from "@smartrent/hooks";

import { countries, getRegionOptions, Countries } from "@/utils";
import { FormikSelectOrInputField } from "@/common/formik/FormikSelectOrInputField";

interface LocationFormProps {
  title: string;
  canEdit: boolean;
  lat?: string | number | null;
  lng?: string | number | null;
  style?: StyleProp<ViewStyle>;
  requiredFields?: boolean;
}

interface LocationProperties {
  country: Countries;
  state: string;
  street_address_1: string;
  street_address_2: string;
  zip: number;
  lng: number;
  lat: number;
}

const countriesValue = countries.map(({ value }) => value);

export const locationValidationFields = {
  street_address_1: Yup.string(),
  street_address_2: Yup.string(),
  city: Yup.string().max(100, "Must be less than 100 characters"),
  zip: Yup.string()
    .trim()
    .required("Required")
    .min(1)
    .max(100, "Must be less than 100 characters"),
  state: Yup.string()
    .required("Required")
    .max(100, "Must be less than 100 characters"),
  country: Yup.string()
    .required("Required")
    .max(100, "Must be less than 100 characters")
    .oneOf(countriesValue),
  lng: Yup.number()
    .nullable()
    .default(null)
    .max(180, "Longitude must be less than or equal to 180")
    .min(-180, "Longitude must be greater than or equal to -180"),
  lat: Yup.number()
    .nullable()
    .default(null)
    .max(90, "Latitude must be less than or equal to 90")
    .min(-90, "Latitude must be greater than or equal to -90"),
};

export const LocationForm = ({
  title,
  canEdit,
  lat,
  lng,
  style,
  requiredFields = true,
}: LocationFormProps) => {
  const [{ value: country }] = useField("country");
  const [localCountry, setLocalCountry] = useState(country);
  const { values, setFieldValue } = useFormikContext<LocationProperties>();

  useEffect(() => {
    if (canEdit && values.country !== localCountry) {
      setFieldValue("state", "");
      setLocalCountry(values.country);
    }
  }, [setFieldValue, values.country, localCountry, setLocalCountry, canEdit]);

  const { small } = useWindowDimensionsQuery();

  const getCountryLabel = useMemo(() => {
    if (!country) {
      return "State / Province / Region";
    }

    return country === "United States" ? "State" : "Province";
  }, [country]);

  return (
    <FormSection style={style} title={title}>
      <FormikSelectOrInputField
        name="country"
        label="Country"
        options={countries}
        isTextField={!canEdit}
        textDisabled={true}
        required={requiredFields}
      />

      <FormikTextInputField
        name="street_address_1"
        label="Street Address"
        disabled={!canEdit}
      />
      <FormikTextInputField
        name="street_address_2"
        label="Street Address 2"
        disabled={!canEdit}
      />
      <Stack
        direction={small ? "column" : "row"}
        flexChildren={small ? false : true}
        spacing={16}
      >
        <FormikTextInputField name="city" label="City" disabled={!canEdit} />

        <FormikSelectOrInputField
          name="state"
          label={getCountryLabel}
          options={getRegionOptions(country)}
          isTextField={!canEdit}
          textDisabled={true}
          required={requiredFields}
        />
      </Stack>

      <FormikTextInputField
        name="zip"
        label={country === "United States" ? "ZIP Code" : "Postal Code"}
        disabled={!canEdit}
        required={requiredFields}
      />
      <Stack
        direction={small ? "column" : "row"}
        flexChildren={small ? false : true}
        spacing={16}
      >
        <FormikNumberInputField
          name="lat"
          label="Latitude"
          disabled={!canEdit}
        />
        <FormikNumberInputField
          name="lng"
          label="Longitude"
          disabled={!canEdit}
        />
      </Stack>

      {lat && lng ? (
        <Button
          variation="outlined"
          disabled={!values.lat || !values.lng}
          accessibilityRole="link"
          rel="noopener noreferrer"
          target="_blank"
          href={
            values.lat && values.lng
              ? `https://maps.google.com/?q=${values.lat},${values.lng}`
              : undefined
          }
        >
          View this location on a map
        </Button>
      ) : null}
    </FormSection>
  );
};
