import { useQueryCompat } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { StripeAccount } from "@/types";

export const useStripeAccountsQuery = (starting_after?: string) =>
  useQueryCompat(
    ["stripe-accounts", starting_after],
    async (queryKey: "stripe-accounts") => {
      const { data } = await instance.get<StripeAccount[]>(
        `parking/stripe-accounts`
      );
      return data;
    }
  );
