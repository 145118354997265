export default function PublicPageLayout({ children }) {
  return (
    <div
      style={{
        height: "100vh",
        background:
          "url(https://control.smartrent.com/images/public-background-bdb7480b17f9250ea75fff652b7d4c6a.jpg?vsn=d) 50% no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{ background: "rgb(26, 31, 39, 0.8)", height: "100vh" }}
        className="page"
      >
        <div
          className="page__content page__content--centered u-flex u-flex-col"
          style={{ opacity: 1 }}
        >
          <div className="public__header">
            <img
              className="public__header__branding"
              src="https://control.smartrent.com/images/public-branding-675ab340434e07f99e78ae58feaf2a24.svg"
              alt="SmartRent Support"
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
