import { useEffect } from "react";
import { StyleSheet } from "react-native";

import { Button, useQueryStringField } from "@smartrent/ui";

import { useGlobalContext } from "@/layout/Context";

import { HubPrepAction } from "@/types";

type HubPrepSidebarActionsProps = {
  action: HubPrepAction;
  setAction: (action: HubPrepAction) => void;
  handleAdvancedPress: () => void;
};

export function HubPrepSidebarActions({
  action,
  setAction,
  handleAdvancedPress,
}: HubPrepSidebarActionsProps) {
  const { user } = useGlobalContext();

  const actionQueryParam = useQueryStringField("action");

  // Same as procure, just has extra step of 'resetting zwave' which sometimes can take up to 5 minutes
  // We control the 'refurbish' action with url query param instead of a button to help avoid people using it in hub prep batch
  useEffect(
    () => (actionQueryParam === "refurbish" ? setAction("refurbish") : null),
    [actionQueryParam, setAction]
  );

  return (
    <>
      <Button
        onPress={() => setAction("procure")}
        variation={
          action === "procure" || action === "refurbish" // we want people to see 'procure' when using refurbish action
            ? "default"
            : "outlined"
        }
      >
        Procure
      </Button>
      <Button
        onPress={() => setAction("unregister")}
        variation={action === "unregister" ? "default" : "outlined"}
        style={styles.actionButton}
      >
        Unregister From Group
      </Button>

      {user.permissions.advanced_hub_troubleshooting ? (
        <Button
          onPress={handleAdvancedPress}
          variation={action === "advanced" ? "default" : "outlined"}
          style={styles.actionButton}
        >
          Advanced
        </Button>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  actionButton: {
    marginTop: 4,
  },
});
