import { useMemo } from "react";
import { ListItemContainer, ListItemLabelValue } from "@smartrent/ui";

import { formatDate } from "@/utils";
import { JobTask } from "@/types";

interface ManagementNotesItemProps {
  item: JobTask;
}
export const ManagementNotesItem = ({ item }: ManagementNotesItemProps) => {
  const name = useMemo(
    () => `${item.created_by.first_name} ${item.created_by.last_name}`.trim(),
    [item.created_by.first_name, item.created_by.last_name]
  );

  const modifierText = useMemo(() => {
    if (!item.completed_at) {
      return "";
    }

    return formatDate({
      date: item.completed_at,
      pattern: "P p",
    });
  }, [item.completed_at]);

  return (
    <ListItemContainer>
      <ListItemLabelValue
        label={name}
        modifier={modifierText}
        value={item.meta?.note || ""}
        numberOfLines={100}
      />
    </ListItemContainer>
  );
};
