import { useCallback } from "react";
import { ListRenderItemInfo, StyleSheet, View } from "react-native";

import { HStack, StatusBadge, Typography } from "@smartrent/ui";

import { GroupProps, ThirdPartyProviderConfigurationProps } from "@/types";
import { CredentialsList } from "@/common/third-party-providers";
import { StatusBadgeDetailView } from "@/common/StatusBadgeDetailView";

// provider list item using only tpp_view records
export type ThirdPartyProviderItem = {
  provider_name: string;
  custom_provider_configuration?: any;
  enabled?: boolean;
  locked?: boolean;
  provider_id?: string | number;
  credential?: Record<string, any>;
};
export interface GroupIntegrationsListItemProps
  extends ListRenderItemInfo<ThirdPartyProviderItem> {
  displayCredentials: boolean;
  group: GroupProps;
}

export const GroupIntegrationsListItem = ({
  item: {
    provider_name,
    custom_provider_configuration,
    enabled,
    credential,
    locked,
  },
  index,
  displayCredentials,
  group,
}: GroupIntegrationsListItemProps) => {
  /**
   * 1. do we have a value in tpp_view? use that value regardless of whether it's true or false
   * 2. fallback to the group value (regardless of true or false)
   */
  const displayAMIMOStatusBadge = useCallback(
    ({
      custom_provider_configuration,
    }: ThirdPartyProviderConfigurationProps) => {
      const integrationV1AMIMO = group?.automatic_move_in_move_out_enabled;
      const integrationV2AMIMO =
        custom_provider_configuration?.automatic_move_in_move_out_enabled;

      return integrationV2AMIMO !== undefined
        ? integrationV2AMIMO
        : integrationV1AMIMO;
    },
    [group]
  );

  return (
    <>
      <View style={styles.container}>
        {/* SHOW INTERGRATION NAME */}
        <Typography>
          {++index}. {provider_name}
        </Typography>
        <HStack spacing={8}>
          {/* SHOW AMIMO BADGE (AUTOMATED MOVE IN MOVE OUT STATUS) */}
          {displayAMIMOStatusBadge({
            custom_provider_configuration,
          }) ? (
            <StatusBadgeDetailView condition={enabled}>
              AMIMO
            </StatusBadgeDetailView>
          ) : null}

          {/* SHOW STATUS BADGE (ENABLED/DISABLED) */}
          <StatusBadgeDetailView condition={enabled} />
          {locked ? (
            <StatusBadge
              status="error"
              variation="outlined"
              size="semibold.title.five"
            >
              Locked
            </StatusBadge>
          ) : null}
        </HStack>
      </View>

      {displayCredentials ? (
        <CredentialsList
          listKey={provider_name}
          credentials={credential}
          size="small"
        />
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    flexWrap: "wrap",
  },
});
