import * as React from "react";
import { StyleSheet, View } from "react-native";
import {
  FlatList,
  ListItemLoadingContainer,
  ListItemLoadingValue,
  Typography,
  useTheme,
} from "@smartrent/ui";
import { useFlatListQuery } from "@smartrent/hooks";

import { getJobComments } from "@/api";
import { EmptyList } from "@/common/EmptyList";

import { JobCommentListItem } from "./JobCommentsListItem";

interface JobCommentsProps {
  id: string;
}

export const JobComments = ({ id }: JobCommentsProps) => {
  const { shadows, fonts } = useTheme();

  const flatListProps = useFlatListQuery(["jobComments", id], ({ pageParam }) =>
    getJobComments(id, pageParam)
  );

  return (
    <View style={styles.container}>
      <Typography style={[styles.headerTitle, fonts.semibold]}>
        Comments
      </Typography>

      <View style={[styles.listContainer, shadows.regular]}>
        <FlatList
          style={styles.flatlist}
          keyExtractor={(item) => item.id}
          ItemComponent={JobCommentListItem}
          LoadingItemComponent={JobCommentListItemLoading}
          ListEmptyComponent={() => <EmptyList>No comments</EmptyList>}
          {...flatListProps}
        />
      </View>
    </View>
  );
};

const JobCommentListItemLoading = React.memo(() => {
  return (
    <ListItemLoadingContainer>
      <ListItemLoadingValue />
    </ListItemLoadingContainer>
  );
}) as React.FC;

const styles = StyleSheet.create({
  container: {
    marginTop: 32,
    paddingHorizontal: 32,
  },
  flatlist: {
    borderRadius: 8,
  },
  headerTitle: {
    fontSize: 18,
  },
  listContainer: {
    borderRadius: 8,
    marginTop: 16,
    flex: 1,
  },
});
