import { AxiosError, AxiosResponse } from "axios";
import { merge } from "lodash-es";
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryClient,
  useQueryClient,
} from "@tanstack/react-query";

import { useGlobalContext } from "@/layout/Context";
import { ToastProps } from "@/types";

/**
 * createAxiosQuery is a hook factory that returns a customized useQuery hook.
 *
 * @link https://react-query.tanstack.com/reference/useQuery
 *
 * @param collectionOrEntityName - The name of the collection or entity being queried.
 * @param fetchFn - A function that performs the actual query (typically an API call).
 * @param defaultOptions - The default options to use for this query. Note that these
 *                         can be overridden by the second argument passed to the hook
 *                         that this function returns.
 *
 * @template TFilters - Type of variables passed to the query function.
 * @template TQueryFnData - Type of data returned from the query function.
 * @template TData - Type of data returned by the hook. If the `select` option is
 *                   passed, it will be the return type of that function. Otherwise,
 *                   it will always be the same as TQueryFnData.
 * @template TError - Type of error thrown from the query function.
 */
export function createAxiosQuery<
  TFilters = unknown,
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TError extends Error = AxiosError,
>(
  collectionOrEntityName: string,
  fetchFn: (filters?: TFilters) => Promise<AxiosResponse<TQueryFnData>>,
  defaultOptions?: UseQueryOptions<TQueryFnData, TError, TData>
) {
  return (
    filters?: TFilters,
    options?: UseQueryOptions<TQueryFnData, TError, TData>
  ) => {
    return useQuery<TQueryFnData, TError, TData>(
      [collectionOrEntityName, filters],
      async () => (await fetchFn(filters)).data,
      merge(defaultOptions, options)
    );
  };
}

type SetToastOptionsWithOptionalType = Omit<ToastProps, "type"> &
  Partial<Pick<ToastProps, "type">>;

/**
 * Mutation options.
 *
 * @link https://react-query.tanstack.com/reference/useMutation
 *
 * @template TData - Type of the data returned from the mutate function.
 * @template TError - Type of error thrown by the mutate function.
 * @template TVariables - Type of variables passed to the mutate function.
 * @template TContext - Type of context value returned from the `onMutate` callback.
 */
interface CreateMutationOptions<
  TData = unknown,
  TError = AxiosError,
  TVariables = unknown,
  TContext = unknown,
> extends Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "onMutate" | "onSuccess" | "onError" | "onSettled"
  > {
  /**
   * If provided, a success toast with the returned content will be displayed
   * after the mutation succeeds.
   *
   * @param data - The result returned from the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  successToast?: (
    data: TData,
    variables: TVariables,
    context: TContext | undefined
  ) => SetToastOptionsWithOptionalType | null;

  /**
   * If provided, an error toast with the returned content will be displayed
   * after the mutation fails.
   *
   * @param error - The error thrown by the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  errorToast?: (
    error: TError,
    variables: TVariables,
    context: TContext | undefined
  ) => SetToastOptionsWithOptionalType | null;

  /**
   * Called prior to executing the mutate function. The value returned by this
   * callback is passed as the last argument to the `onSuccess`, `onError`, and
   * `onSettled` callbacks.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param variables - The variables passed to the mutate function.
   *
   * @returns The context value passed to the `onSuccess`, `onError`,
   * and `onSettled` callbacks.
   */
  onMutate?: (
    queryClient: QueryClient,
    variables: TVariables
  ) => Promise<TContext> | TContext;

  /**
   * Called after the mutate function succeeds.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param data - The result returned from the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  onSuccess?: (
    queryClient: QueryClient,
    data: TData,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<void> | void;

  /**
   * Called after the mutate function fails.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param error - The error thrown by the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  onError?: (
    queryClient: QueryClient,
    error: TError,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<void> | void;

  /**
   * Called after the mutate function finishes whether or not it was successful.
   *
   * @param queryClient - The query client being used for this mutation.
   * @param data - The result returned from the mutate function.
   * @param error - The error thrown by the mutate function.
   * @param variables - The variables passed to the mutate function.
   * @param context - The context value returned by the optional `onMutate` callback.
   */
  onSettled?: (
    queryClient: QueryClient,
    data: TData | undefined,
    error: TError | null,
    variables: TVariables,
    context: TContext | undefined
  ) => Promise<void> | void;
}

/**
 * createAxiosMutation is a hook factory that returns a customized useMutation hook.
 *
 * @param mutateFn An async function that performs the actual work of the mutation
 *                 (typically, this will be an API call).
 *
 * @param defaultOptions - The default options to use for this mutation. Note that these
 *                         can be overridden by the first argument passed to the hook
 *                         that this function returns.
 *
 * @template TData - Type of the data returned from the mutate function.
 * @template TError - Type of error thrown by the mutate function.
 * @template TVariables - Type of variables passed to the mutate function.
 * @template TContext - Type of context value returned from the `onMutate` callback.
 */
export function createAxiosMutation<
  TData = unknown,
  TError = AxiosError,
  TVariables = unknown,
  TContext = unknown,
>(
  mutateFn: (variables: TVariables) => Promise<AxiosResponse<TData>>,
  defaultOptions?: CreateMutationOptions<TData, TError, TVariables, TContext>
) {
  interface MutationOptions
    extends CreateMutationOptions<TData, TError, TVariables, TContext> {}
  interface UseCreateMutationOptions
    extends UseMutationOptions<TData, TError, TVariables, TContext> {}

  return (options?: MutationOptions) => {
    const context = useGlobalContext();
    const queryClient = useQueryClient();
    const resolvedOptions = merge(defaultOptions, options) || {};

    // Custom onMutate callbacks that will be passed to useMutation
    // If the option function is not passed then the correct type
    // will now be used for the callback
    type OnMutateProps = (
      callback: NonNullable<MutationOptions["onMutate"]>
    ) => UseCreateMutationOptions["onMutate"];
    const onMutate: OnMutateProps = (callback) => (variables) =>
      callback(queryClient, variables);

    type OnSettledProps = (
      callback: NonNullable<MutationOptions["onSettled"]>
    ) => UseCreateMutationOptions["onSettled"];
    const onSettled: OnSettledProps =
      (callback) =>
      (...args) =>
        callback(queryClient, ...args);

    type OnSuccessProps = (
      callback: MutationOptions["onSuccess"],
      successToast?: MutationOptions["successToast"]
    ) => UseCreateMutationOptions["onSuccess"];
    const onSuccess: OnSuccessProps =
      (callback, toastCallback) =>
      (...args) => {
        if (toastCallback && toastCallback(...args) !== null) {
          context.setToast({
            type: "success",
            ...toastCallback(...args),
          });
        }
        callback?.(queryClient, ...args);
      };

    type OnErrorProps = (
      callback: MutationOptions["onError"],
      successToast?: MutationOptions["errorToast"]
    ) => UseCreateMutationOptions["onError"];
    const onError: OnErrorProps =
      (callback, toastCallback) =>
      (...args) => {
        if (toastCallback && toastCallback(...args) !== null) {
          context.setToast({
            type: "error",
            ...toastCallback(...args),
          });
        }
        callback?.(queryClient, ...args);
      };

    const mutation = useMutation<TData, TError, TVariables, TContext>(
      async (variables) => (await mutateFn(variables)).data,
      {
        onMutate: resolvedOptions?.onMutate
          ? onMutate(resolvedOptions.onMutate)
          : undefined,
        onSettled: resolvedOptions?.onSettled
          ? onSettled(resolvedOptions.onSettled)
          : undefined,
        onSuccess: onSuccess(
          resolvedOptions.onSuccess,
          resolvedOptions.successToast
        ),
        onError: onError(resolvedOptions.onError, resolvedOptions.errorToast),
      }
    );

    return [mutation.mutateAsync, mutation] as const;
  };
}

/**
 * Pass the queryClient and a list of query keys to be invalidated
 *
 * This only supports the string key and not more granular keys
 *
 * @param queryClient
 * @param queryKeys
 */
export const invalidateQueries = async (
  queryClient: QueryClient,
  queryKeys: string[]
) => {
  return queryClient.invalidateQueries({
    predicate: (query) => {
      if (
        !Array.isArray(query.queryKey) ||
        typeof query.queryKey[0] !== "string"
      ) {
        return false;
      }

      return queryKeys.includes(query.queryKey[0]);
    },
  });
};
