import { useCallback } from "react";
import * as React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Button } from "@smartrent/ui";

import { useParkingPropertyQuery } from "@/api";
import { Panel } from "@/common/panel";
import { useUser } from "@/layout/Context";
import { useDrawerNav } from "@/common/AppDrawer";

import { MapRow } from "./MapRow";

interface ParkingMapPanelProps {
  propertyId: string;
  style?: StyleProp<ViewStyle>;
}

export const ParkingMapPanel: React.FC<
  React.PropsWithChildren<ParkingMapPanelProps>
> = ({ propertyId, style }) => {
  const drawer = useDrawerNav();
  const { permissions } = useUser();

  const { data: property, isLoading: loadingProperty } =
    useParkingPropertyQuery(propertyId);

  const handleManagePress = useCallback(
    () => drawer.push("updateParkingMaps", { propertyId }),
    [drawer, propertyId]
  );

  return (
    <Panel
      title="Map"
      action={
        permissions.manage_parking && (
          <Button onPress={handleManagePress}>Manage</Button>
        )
      }
      style={[styles.root, style]}
    >
      <MapRow isLoading={loadingProperty} mapId={property.map_id} />
    </Panel>
  );
};

const styles = StyleSheet.create({
  root: {
    minWidth: 300,
  },
});
