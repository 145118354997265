import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";

import {
  Dialog,
  DialogAction,
  DialogContent,
  DialogTitle,
  VStack,
  SelectField,
  useToast,
  TextInputField,
  Button,
} from "@smartrent/ui";

import { Search } from "@smartrent/icons";

import {
  useCreateSOWTemplateMutation,
  useUpdateSOWTemplateMutation,
  selectQueryPropsTemplates,
} from "@/api";

import { useScopeOfWorkContext } from "./provider/ScopeOfWorkContext";

export const ScopeOfWorkTemplateModal: React.FC<
  React.PropsWithChildren<{
    visible: boolean;
    onClose: VoidFunction;
  }>
> = ({ visible, onClose }) => {
  const [name, setName] = useState("");
  const [overwriteName, setOverwriteName] = useState(null);
  const [isNewTemplate, setIsReplaceTemplate] = useState(true);
  const [createSOWTemplate, { isError, isSuccess, error, reset }] =
    useCreateSOWTemplateMutation();
  const [
    updateSOWTemplate,
    { isSuccess: isSuccessUpdate, isError: isErrorUpdate },
  ] = useUpdateSOWTemplateMutation();
  const setToast = useToast();

  const {
    scopeOfWorkQuery: { data },
  } = useScopeOfWorkContext();

  const handleSubmitCreate = useCallback(async () => {
    await createSOWTemplate({
      name: name,
      devices: data?.scope_of_work_devices,
      device_order: data?.scope_of_work_devices_order,
    });
  }, [createSOWTemplate, data, name]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      setName("");
      setOverwriteName("");
      setToast({
        status: "success",
        title: "Template created!",
        message: "",
      });
    }
  }, [isSuccess, isError, onClose, setToast]);

  const handleSubmitUpdate = useCallback(async () => {
    await updateSOWTemplate({
      name: overwriteName.name,
      devices: data?.scope_of_work_devices,
      device_order: data?.scope_of_work_devices_order,
    });
  }, [updateSOWTemplate, data, overwriteName]);

  useEffect(() => {
    if (isSuccessUpdate) {
      onClose();
      setName("");
      setOverwriteName("");
      setToast({
        status: "success",
        title: "Template updated!",
        message: "",
      });
    } else if (isErrorUpdate) {
      onClose();
      setToast({
        status: "error",
        title: "Error updating template.",
        message: "",
      });
    }
  }, [isSuccessUpdate, isErrorUpdate, onClose, setToast]);

  return (
    <>
      <Dialog
        titleID="template"
        visible={visible}
        onClose={onClose}
        descriptionID="dialog-desc"
      >
        <DialogContent style={styles.dialogContent}>
          <VStack spacing={20}>
            <DialogTitle style={styles.dialogTitle}>
              {isNewTemplate
                ? "Please choose a name for this template"
                : "Search for an existing template"}
              <Button
                variation="outlined"
                onPress={() => {
                  setName("");
                  setOverwriteName("");
                  reset();
                  setIsReplaceTemplate(!isNewTemplate);
                }}
                style={styles.buttonMargin}
              >
                {isNewTemplate
                  ? "replace an existing template"
                  : "create a new template"}
              </Button>
            </DialogTitle>

            <TextInputField
              label="Save as a new template"
              textInputProps={{
                value: name,
                onChangeText: setName,
              }}
              disabled={!!overwriteName}
              style={isNewTemplate ? null : styles.hidden}
              error={
                isError
                  ? error?.response?.data || "Could not save template"
                  : null
              }
            />

            <View style={isNewTemplate ? styles.hidden : null}>
              <SelectField
                label="Overwrite existing template"
                name="name"
                value={overwriteName}
                onChange={setOverwriteName}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                disabled={!!name}
                StartAdornment={Search}
                {...selectQueryPropsTemplates()}
              />
            </View>

            <View style={styles.buttons}>
              <DialogAction
                text="Cancel"
                type="cancel"
                onPress={() => {
                  reset();
                  onClose();
                }}
              />
              <DialogAction
                text={isNewTemplate ? "Save" : "Update"}
                onPress={
                  isNewTemplate ? handleSubmitCreate : handleSubmitUpdate
                }
                disabled={!overwriteName && !name}
              />
            </View>
          </VStack>
        </DialogContent>
      </Dialog>
    </>
  );
};

const styles = StyleSheet.create({
  dialogContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    minWidth: 360,
  },
  dialogTitle: { textAlign: "center" },
  buttons: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  hidden: { display: "none" },
  buttonMargin: { marginLeft: 32 },
});
