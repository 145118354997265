import { instance } from "@/lib/hooks";
import { createAxiosQuery } from "@/hooks/react-query";

import { ScheduledTour, ProspectProps, VerificationProps } from "../types";

export interface ScheduledTourResponse {
  current_page: number;
  records: ScheduledTour[];
  prospects: ProspectProps[];
  total_records: number;
  total_pages: number;
}

export const fetchScheduledTours = async (key: "scheduled-tours", filters) => {
  const { data } = await instance.get<ScheduledTourResponse>(
    `/tours/scheduled-tours`,
    {
      params: { ...filters },
    }
  );

  const records = data.records.map((tour) => {
    const prospect = data.prospects.find(
      (prospect) => prospect.id == tour.prospect_id
    );
    return { ...tour, prospect };
  });

  return {
    records,
    current_page: data.current_page,
    total_records: data.total_records,
    total_pages: data.total_pages,
  };
};

export const useManualVerificationsQuery = createAxiosQuery(
  "manual-verifications",
  async () =>
    instance.get<VerificationProps[]>("/verifications/manual-verifications")
);
