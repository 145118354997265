// actual role values used in CMW db
export enum UserRole {
  PropertyStaff = "employee",
  PendingStaff = "imported_employee",
  Consumer = "consumer",
}

// these are the filters that internal api Accounts.search/1 uses for filtering 'role'
export enum UserEmployeeRole {
  PropertyStaff = "property_staff",
  PendingStaff = "pending_staff",
  OrganizationAdmin = "organization_admin",
}

// label's we want to use in UI
export enum UserEmployeeRoleName {
  PropertyStaff = "Property Staff",
  PendingStaff = "Pending Staff",
  OrganizationAdmin = "Organization Admin",
}

// table filter options to get the right 'employee role' label
//  with the value the API expects for 'employee_role' filter
export const userEmployeeRoleOptions = [
  {
    label: UserEmployeeRoleName.PendingStaff,
    value: UserEmployeeRole.PendingStaff,
  },
  {
    label: UserEmployeeRoleName.PropertyStaff,
    value: UserEmployeeRole.PropertyStaff,
  },
  {
    label: UserEmployeeRoleName.OrganizationAdmin,
    value: UserEmployeeRole.OrganizationAdmin,
  },
];

// trying to make sense of the different values we use for user's role in Account.search logic from CMW
//  - employee's have a user.employee_role_id foriegn key
//  - other types of users have null for employee_role
//      and use string values from user.role column
export const getUserRoleName = ({ role, employee_role }) => {
  if (employee_role) {
    return UserEmployeeRoleName.PropertyStaff;
  }

  if (role === UserRole.PendingStaff) {
    return UserEmployeeRoleName.PendingStaff;
  }

  // there is no employee role, so return the raw role value
  return role;
};
