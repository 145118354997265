import * as React from "react";
import { StyleSheet } from "react-native";

import { LegacyListItem, Typography, useTheme } from "@smartrent/ui";
import { formatConstructionType, Space } from "@smartrent/parking";

interface SignSpaceProps {
  space: Space;
}

export const SignSpace: React.FC<React.PropsWithChildren<SignSpaceProps>> = ({
  space,
}) => {
  const { colors } = useTheme();

  return (
    <LegacyListItem
      item={{
        label: (
          <Typography type="title4" color="primary">
            {space.space_number}
          </Typography>
        ),
        value: (
          <Typography type="title6" style={{ color: colors.gray600 }}>
            {space?.area?.name ?? "\u2014"}
          </Typography>
        ),
      }}
      style={[
        styles.item,
        {
          borderBottomColor: colors.gray200,
        },
      ]}
    >
      <Typography type="title6" style={{ color: colors.gray600 }}>
        {formatConstructionType(space.construction_type)}
      </Typography>
    </LegacyListItem>
  );
};

const styles = StyleSheet.create({
  item: {
    borderBottomWidth: 1,
  },
});
