import { useContext } from "react";
import { Link } from "react-router-dom";

import { MapPin } from "@smartrent/icons";

import { formatPhone } from "@/utils";

import Context from "../Context";

export default function ExtendedUserSearch(data) {
  const {
    prospect_id,
    maintenance_vendor_id,
    name,
    email,
    phone,
    setSearchResults,
    setKeywords,
  } = data;
  const { isDesktop } = useContext(Context);

  if (!prospect_id && !maintenance_vendor_id) {
    return null;
  }

  if (prospect_id) {
    return (
      <div
        className="u-flex-grow1"
        style={{
          minWidth: "200px",
          marginBottom: isDesktop ? 0 : "32px",
        }}
      >
        <div
          className="u-flex u-flex-align-items-start"
          style={{ lineHeight: 1 }}
        >
          <span className="u-mright4">
            <MapPin size={24} color="#333" />
          </span>

          <Link
            className="a u-font24"
            onClick={() => {
              setKeywords("");
              setSearchResults([]);
            }}
            to={`/prospects/${prospect_id}`}
          >
            {name}
          </Link>
        </div>
        <div className="u-line18 u-font18">
          <div>
            {email && <div className="u-text-gray-600">{email}</div>}
            {phone ? (
              <div className="u-text-gray-600">{formatPhone({ phone })}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="u-flex-grow1"
        style={{
          minWidth: "200px",
          marginBottom: isDesktop ? 0 : "32px",
        }}
      >
        <div
          className="u-flex u-flex-align-items-start"
          style={{ lineHeight: 1 }}
        >
          <span className="u-mright4">
            <MapPin size={24} color="#333" />
          </span>

          <Link
            className="a u-font24"
            onClick={() => {
              setKeywords("");
              setSearchResults([]);
            }}
            to={`/maintenance-vendors/${maintenance_vendor_id}`}
          >
            {name}
          </Link>
        </div>
        <div className="u-line18 u-font18">
          <div>
            {email && <div className="u-text-gray-600">{email}</div>}
            {phone ? (
              <div className="u-text-gray-600">{formatPhone({ phone })}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
