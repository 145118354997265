import { useCallback, useMemo } from "react";

import { useGlobalContext } from "@/layout/Context";
import { buildUrl } from "@/utils";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";
import { HubRefurbishStep } from "@/types";

// we update the serial in the URL params when a hub is disabled between steps
export const DISABLED_SERIAL_REGEX = new RegExp(/disabled_/gi);
export const DISABLED_SERIAL_PREFIX = "disabled_";

export const useStepNavigationHook = (step: HubRefurbishStep) => {
  const { history } = useGlobalContext();

  const { hubsDict, flowId, flowHubsCount } = useHubRefurbishContext();

  // inject "DISABLED_" to beginning of serial number when its been selected for removal
  const disabledSerialsMap = useMemo(
    () =>
      Object.entries(hubsDict).reduce((arr, [serial, val]) => {
        if (val?.isToBeRemoved && !/disabled_/gi.test(serial)) {
          arr.push(DISABLED_SERIAL_PREFIX + serial);
        } else {
          arr.push(serial);
        }
        return arr;
      }, []),
    [hubsDict]
  );

  const pathname = useMemo(() => {
    switch (step) {
      case HubRefurbishStep.Reset:
      case HubRefurbishStep.HubsOffline:
        return "hub-prep/refurbish-batch/step/offline";
      case HubRefurbishStep.ResetHubs:
        return "hub-prep/refurbish-batch/step/reset-hubs";
      case HubRefurbishStep.HubsOnlineWithSD:
        return "hub-prep/refurbish-batch/step/online-w-sd";
      case HubRefurbishStep.ProcureHubs:
        return "hub-prep/batch";
      default:
        return "hub-prep"; // home path
    }
  }, [step]);

  const serials = useMemo(() => {
    switch (step) {
      case HubRefurbishStep.Home:
      case HubRefurbishStep.Reset:
        return undefined;
      case HubRefurbishStep.HubsOffline:
      case HubRefurbishStep.ResetHubs:
      case HubRefurbishStep.HubsOnlineWithSD:
      case HubRefurbishStep.ProcureHubs:
        return disabledSerialsMap;
      default:
        return [];
    }
  }, [disabledSerialsMap, step]);

  const search = useMemo(() => {
    switch (step) {
      case HubRefurbishStep.Home:
        return {};
      case HubRefurbishStep.ResetHubs:
        return { serials, flowId, flowHubsCount };
      case HubRefurbishStep.HubsOffline:
      case HubRefurbishStep.HubsOnlineWithSD:
        return { serials };
      case HubRefurbishStep.ProcureHubs:
        return {
          action: "refurbish",
          serials,
        };
      default:
        return {};
    }
  }, [flowHubsCount, flowId, serials, step]);

  const url = useMemo(() => buildUrl({ pathname, search }), [pathname, search]);

  const link = useMemo(() => {
    if (!url) {
      return "";
    }
    const { pathname, search } = url;
    return pathname + search;
  }, [url]);

  const goToStepPage = useCallback(
    ({
      flowId,
      flowHubsCount,
    }: { flowId?: string; flowHubsCount?: string | number } = {}) => {
      if (flowId) {
        url.searchParams.set("flowId", flowId);
      }
      if (flowHubsCount) {
        url.searchParams.set("flowHubsCount", String(flowHubsCount));
      }
      // we use the URL properties for pathname & search to handle encoding special chars
      const { pathname: urlPathname, search: urlSearch } = url;
      history.push({ pathname: urlPathname, search: urlSearch });
    },
    [history, url]
  );

  return {
    url,
    link,
    goToStepPage,
  };
};
