import isValid from "date-fns/isValid";
import getYear from "date-fns/getYear";

import Helpers from "@/lib/helpers";

import { EM_DASH } from "./chars";

export const valueToHumanReadableString = (
  value,
  useAdvanced?: boolean
): string => {
  // typeof null === "object", so we have to do this one manually.
  if (value === null) {
    return EM_DASH;
  }

  // Check if it's a timestamp, and if so, make it pretty.
  const date = new Date(value);
  if (isValid(date) && getYear(date) >= 2017) {
    return Helpers.formatDate(value);
  }

  if (useAdvanced) {
    if (typeof value === "string" && ["true", "false"].includes(value)) {
      return value === "true" ? "Yes" : "No";
    }
  }

  switch (typeof value) {
    case "number":
      return String(value);
    case "string":
      return value;
    case "boolean":
      return value ? "Yes" : "No";
    case "undefined":
      return EM_DASH;
    case "object":
      return JSON.stringify(value);
    default:
      return String(value);
  }
};

// TODO for anyone feeling ambitious.
// Write a function that reverses the results of the above. [Uno Reverse Card]
