import { StyleSheet, View } from "react-native";

import { useTheme } from "@smartrent/ui";

import { SortChangeHandler } from "@/hooks/url-sort";

import { BaseRecord, Column } from "./types";
import { HeaderCell } from "./HeaderCell";

export interface ColumnHeadersProps<TRecord extends BaseRecord> {
  columns?: Column<TRecord>[];
  onSortChange?: SortChangeHandler;
}

export function ColumnHeaders<TRecord extends BaseRecord>({
  columns,
  onSortChange,
}: ColumnHeadersProps<TRecord>) {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: colors.inputBackground,
          borderBottomColor: colors.border,
        },
      ]}
    >
      {columns ? (
        <View style={styles.columnHeaders}>
          {columns.map((column) => (
            <HeaderCell
              key={`${column.name}_header`}
              column={column}
              onSortChange={onSortChange}
            />
          ))}
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 16,
    paddingBottom: 8,
    borderBottomWidth: 1,
  },
  columnHeaders: {
    marginTop: 16,
    flexDirection: "row",
  },
});
