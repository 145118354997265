import { get, filter, last, startCase } from "lodash-es";
import moment from "moment";

function getLockUser({ accessCodes, slotId, timestamp, deviceId }) {
  const acs = accessCodes[deviceId];

  const filtered = filter(acs, (ac) => {
    const isBetween = moment(timestamp).isBetween(
      moment(ac.added_at).subtract(1, "minute"),
      moment(ac.removed_at || moment()).add(2, "minutes") // sometimes when we get the notification is slightly after the access code is removed, specifically for WOs
    );

    return ac.original_slot_id === Number(slotId) && isBetween;
  });

  if (filtered.length > 1) {
    return "Ambiguous"; // This should not occur
  }

  const ac = filtered[0];

  const name = get(ac, "lock_label", null);

  if (!name) {
    if (ac && ac.type) {
      return startCase(ac.type);
    }

    return `Unknown - slot ${slotId}`;
  }

  return `${name}${ac && ac.type.includes("resident") ? " - resident" : ""}`;
}

/**
 * Subtle but important logic - if the word "resident" exists, we won't show time metadata from logs for privacy
 * Additionally, don't return a value where we can discern if a resident locked/unlocked a lock
 */
export default function Value({
  accessCodes,
  attribute: { name, id, device_id },
  value,
  inserted_at, // when the device attribute log was inserted
}) {
  switch (name) {
    case "locked":
      return value ? (value === "false" ? "Unlocked" : "Locked") : "Unknown";
    case "leak":
      return value ? (value === "false" ? "No Leak" : "Leak 😱") : "Unknown";
    case "user_codes":
      return "supports adding access codes";
    case "current_humidity":
      return value ? `${value}%` : "no humidity reading";

    case "cooling_setpoint":
    case "heating_setpoint":
    case "current_temp":
      return value ? `${value}°` : "no temp reading";

    default:
      break;
  }

  switch (value) {
    // thermostat
    case "auto_low":
    case "heat":
    case "cool":
      return startCase(value);

    case "3_MINUTES_LOCK":
      return "3 Minute Lock";
    case "KEYPAD_ATTEMPTS_EXCEEDED":
      return "Too Many Unlock Attempts";
    case "KEYPAD_LOCK_MASTER_CODE":
      return "Installer (lock)";
    case "KEYPAD_UNLOCK_MASTER_CODE":
      return "Installer (ac unlock)";
    case "KEYPAD_UNLOCK_USER_ID_1":
    case "KEYPAD_UNLOCK_USER_ID__1":
      return "PM Backup Code (ac unlock)";
    case "KEYPAD_UNLOCK_USER_ID_2":
    case "KEYPAD_UNLOCK_USER_ID__2":
      return "Resident Backup Code (ac unlock)";
    case "USER_CODE_ADDED_ID_2":
      return "Res Backup (code added)";
    case "USER_CODE_DELETED_ID_2":
      return "Res Backup (code removed)";
    case "USER_CODE_ADDED_ID_1":
      return "PM Backup (code added)";
    case "USER_CODE_DELETED_ID_1":
      return "PM Backup (code removed)";
    case "USER_CODE_ADDED_ID_251":
      return "Installer (code added)";
    case "USER_CODE_DELETED_ID_251":
      return "Installer (code removed)";
    case "THUMBTURN_LOCK": // locked
    case "KEY_OR_THUMBTURN_LOCK": // locked
    case "PALM_TOUCH_LOCK": // locked
    case "THUMBTURN_UNLOCK": // unlocked
    case "KEY_OR_THUMBTURN_UNLOCK": //unlocked
      return "Locked / Unlocked (hand)";

    case "AUTO_LOCK":
      return "Auto Lock";
    case "LOCK_VIA_RF": // unsure if this is a valid event
    case "UNLOCK_VIA_RF":
      return "Locked / Unlocked (mobile app | Control Room)";
    case "ALARM_TYPE_9_LEVEL_1":
      return "Jammed (while locking)";
    case "ALARM_TYPE_9_LEVEL_2":
      return "Jammed (while unlocking)";
    case "ALARM_TYPE_168_LEVEL_255":
      return "Battery Too Low To Operate Motor";
    case "ALARM_TYPE_41_LEVEL_0":
      return "Lever Rotated"; // mortise
    case "ALARM_TYPE_42_LEVEL_0":
      return "Deadbolt (unsecure)"; // mortise
    case "ALARM_TYPE_42_LEVEL_1":
      return "Deadbolt (secure)"; // mortise
    case "ALARM_TYPE_43_LEVEL_0": // opened
    case "ALARM_TYPE_43_LEVEL_1": // closed
      return "Door (opened / closed)"; // mortise
    case "ALARM_TYPE_43_LEVEL_2":
      return "Door (propped open)"; // mortise
    case "INSIDE_BUTTON_LOCK":
      return "Inside Button Locked Door Handle"; // mortise
    case "ALARM_TYPE_129_LEVEL_0":
      return "Invalid Lock Handing (right side)";
    case "ALARM_TYPE_129_LEVEL_1":
      return "Invalid Lock Handing (left side)";
    case "MASTER_CODE_CHANGED_RF":
      return "Installer (code changed)";
    case "USER_DELETED_ID_255":
      return "All Codes Reset";
    case "RF_MODULE_POWER_CYCLED":
      return "Lock's Clock Needs Sync";
    default:
      if (!value) {
        return "No value";
      }

      if (value.includes("DISABLED_USER")) {
        return "Keypad Access Code Unlock Failed (lock in vacation mode)";
      }

      if (value.includes("ALARM_TYPE_167")) {
        return `Low Battery, ${last(value.split("_"))}%`;
      } else if (value.includes("ALARM_TYPE_168")) {
        return `Critically Low Battery, ${last(value.split("_"))}%`;
      }

      // Alarm Type 21 Level 1 - locked by lifting lock handle (1 handle, 2 keypad)
      // ALARM_TYPE_22_LEVEL_1 - manual unlock by internal thumb turn
      // ALARM_TYPE_24_LEVEL_1 - RF lock operated (lock by zwave)
      // ALARM_TYPE_161_LEVEL_3 - max keypad attempts
      // ALARM_TYPE_161_LEVEL_2 - external tamper
      // ALARM_TYPE_161_LEVEL_1 - internal tamper

      // Timeout on lock set access code - we got an ack but no confirmation from lock during a time interval Zipato set - should be retried
      // code_reset_failed - we never got an ack when we requested access code be set, should retry
      // invalid_slot_id, we need to change config to increase max code size, need firmware upgrade on hub

      if (value.includes("ALARM_TYPE_9_LEVEL_0")) {
        return "Jammed (while locking)";
      }

      if (value.includes("ALARM_TYPE_9_LEVEL_1")) {
        return "Jammed (while unlocking)";
      }

      if (value.includes("ALARM_TYPE_113")) {
        return `Keypad Access Code Set Failed On Unknown Slot (code already on slot ${last(
          value.split("_")
        )})`;
      }
      if (
        value.includes("KEYPAD_UNLOCK") ||
        value.includes("ALARM_TYPE_19_LEVEL_")
      ) {
        const name = getLockUser({
          accessCodes,
          slotId: last(value.split("_")),
          timestamp: inserted_at,
          deviceId: device_id,
        });
        return `${name ? `${name}` : ""} (ac unlock)`;
      }

      if (
        value.includes("ADDED_ID") ||
        value.includes("ALARM_TYPE_112_LEVEL_")
      ) {
        const name = getLockUser({
          accessCodes,
          slotId: last(value.split("_")),
          timestamp: inserted_at,
          deviceId: device_id,
        });
        return `${name} (code added)`;
      }

      if (
        value.includes("DELETED_ID") ||
        value.includes("ALARM_TYPE_33_LEVEL_")
      ) {
        const name = getLockUser({
          accessCodes,
          slotId: last(value.split("_")),
          timestamp: inserted_at,
          deviceId: device_id,
        });
        return `${name} (code removed)`;
      }

      return value;
  }
}
