import { useQuery } from "@tanstack/react-query";

import { useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { OrganizationProps, PermissionProps } from "@/types";
import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";
import helpers from "@/lib/helpers";

interface OrganizationPaginatedResult {
  records: OrganizationProps[];
  total_records: number;
  total_pages: number;
  current_page: number;
}

export interface OrganizationListFilters {
  name?: string;
  limit?: number;
  dir?: "asc" | "desc";
}

export const useOrganizationQuery = (organizationId: number | undefined) =>
  useQuery<{
    organization: Pick<
      OrganizationProps,
      "id" | "industry" | "inserted_at" | "logo_url" | "name"
    >;
    permissions: PermissionProps;
  }>(
    ["organization", organizationId],
    async () => {
      const { data } = await instance.get(`/organizations/${organizationId}`);
      return data;
    },
    {
      enabled: !!organizationId,
    }
  );

export const useOrganizationInfiniteQuery = (
  filters?: OrganizationListFilters
) => {
  return useReducedInfiniteQuery<OrganizationPaginatedResult>(
    [
      "organizations",
      {
        ...filters,
      },
    ],
    async ({ pageParam }) => {
      const { data } = await instance.get("/organizations", {
        params: { ...filters, page: pageParam },
      });
      return data;
    }
  );
};

export interface OrganizationsFilters {
  filters: {
    name: string;
  };
}

export interface OrganizationsResponse {
  current_page: number;
  records: OrganizationProps[];
  total_records: number;
  total_pages: number;
}

export const useOrganizationsQuery = createAxiosQuery(
  "get-organizations",
  async ({ filters }) => {
    return instance.get<OrganizationsResponse>(
      `/organizations?${helpers.toQS(filters)}`
    );
  }
);

export const useCreateOrganizationMutation = createAxiosMutation(
  async ({ ...formikValues }: Partial<OrganizationProps>) =>
    instance.post<Partial<OrganizationProps>>(
      "/organizations/create",
      formikValues
    ),
  {
    successToast: () => ({
      message:
        "Organization created successfully. You should see it in the list soon. Please try refreshing the page.",
    }),
  }
);

export const useUpdateOrganizationMutation = createAxiosMutation(
  async ({ id, ...formikValues }: Partial<OrganizationProps>) =>
    instance.patch(`/organizations/${id}`, formikValues)
);
