import * as React from "react";
import classNames from "classnames";
import AriaModal from "react-aria-modal";
import { Close } from "@smartrent/icons";
import { Formik } from "formik";

import Button from "../Button";

type FormikModalProps = {
  title?: string;
  toggleModal: () => void;
  className?: string;
  style?: Object;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  confirmEnabled?: boolean;
  onSubmit: (formikBag: Object) => void;
  validationSchema?: Object; // a form can be submitted without inputs
  initialValues?: Object; // a form can be submitted without inputs
};

/**
 * A modal that opens above other page content to display important information or user actions.
 */
export default function FormikModal({
  title,
  toggleModal,
  className,
  style,
  children,
  cancelText,
  confirmText,
  confirmEnabled,
  onSubmit,
  validationSchema,
  initialValues,
}: FormikModalProps) {
  return (
    <AriaModal
      titleText={title}
      onExit={() => toggleModal()}
      underlayStyle={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
      dialogClass=""
      focusDialog={true}
    >
      <div
        className={classNames(className)}
        style={{
          ...{
            display: "flex",
            position: "relative",
            flexDirection: "column",
            justifyContent: "flex-start",
            margin: "0 auto",
            flexGrow: 1,
            maxHeight: "70vh",
            border: "none",
            boxShadow:
              "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
            overflow: "hidden",
            backgroundColor: "#FFF",
          },
          ...style,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Formik
          initialValues={initialValues || {}}
          onSubmit={onSubmit}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <header
                style={{
                  flex: "0 0 auto",
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "12px 6px 12px 24px",
                  margin: "0 auto",
                  borderBottom: `1px solid #eee`,
                  textAlign: "left",
                }}
              >
                <div>
                  <h4 className={classNames("h4")} style={classes.title}>
                    {title}
                  </h4>
                </div>
                <div>
                  <Button
                    style={classes.close}
                    type="button"
                    aria-label="Close"
                    onClick={() => toggleModal()}
                  >
                    <Close size={24} color="#000" />
                  </Button>
                </div>
              </header>
              <div
                style={{
                  flex: "0 1 auto",
                  padding: "18px 24px",
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                {children}
              </div>
              <footer style={classes.footer}>
                <Button
                  type="button"
                  className="button button--outline u-mbottom0 u-mright8"
                  style={{ ...classes.cancel }}
                  onClick={() => toggleModal()}
                >
                  {cancelText || "Cancel"}
                </Button>
                <Button
                  type="submit"
                  className="button button--secondary u-m0"
                  onClick={handleSubmit}
                  // style={confirmButtonStyle}
                  disabled={confirmEnabled !== null ? confirmEnabled : true}
                >
                  {confirmText || "Confirm"}
                </Button>
              </footer>
            </form>
          )}
        </Formik>
      </div>
    </AriaModal>
  );
}

const classes = {
  dialog: {
    position: "relative",
    maxHeight: "90vh",
  },
  title: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",

    "& svg": {
      marginRight: "8px",
    },
  },
  close: {
    width: "42px",
    height: "42px",
    borderRadius: "50%",
    borderColor: "transparent",
  },
  footer: {
    flex: "0 0 auto",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    padding: "8px",
    backgroundColor: "rgb(243, 243, 243)",
    borderTop: "#eee",

    "& > * + *": {
      marginLeft: "12px",
    },
  },
  cancel: {
    borderColor: "transparent",
  },
};
