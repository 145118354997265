import { instance } from "@/lib/hooks";

import { createAxiosMutation } from "@/hooks/react-query";

export interface PropertyTransferProps {
  id: string | number;
  group_id: string | number;
  to_organization_id: string | number;
}

export interface PropertyTransferResult {
  message: string;
}

export const usePropertyTransferMutation = createAxiosMutation(
  async ({ id, group_id, to_organization_id }: PropertyTransferProps) => {
    return await instance.post<PropertyTransferResult>(
      `/organizations/${id}/transfer`,
      {
        group_id,
        to_organization_id,
      }
    );
  },
  {
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: ["groups-table-query-key"],
      });
      queryClient.invalidateQueries({
        queryKey: ["tpp-view"],
      });
      queryClient.invalidateQueries({
        queryKey: ["integration-organization-credentials"],
      });
      queryClient.invalidateQueries({
        queryKey: ["groups"],
      });
    },
  }
);
