import { format, utcToZonedTime, OptionsWithTZ } from "date-fns-tz";
import {
  formatDistance,
  isToday as isTodayFn,
  formatRelative,
  parseISO,
} from "date-fns";

import { EM_DASH } from "./chars";

// compare timestamps we get from CMW database with current browser's time
export const isToday = ({
  date,
  timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "UTC",
  opts = {},
}) => {
  const zonedDate = utcToZonedTime(
    date,
    (timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "UTC"),
    opts
  );

  return isTodayFn(zonedDate);
};

/**
 *
 * 1. parse date string, ex: "2021-08-06T00:00:00.000Z"
 * 2. converts timezone to client's locale timezone or UTC
 * 3. use date-fns format
 *  https://date-fns.org/v2.23.0/docs/format
 *
 *
 * @example
 * using defaults
 *
 * formatDate({ date: "2021-08-06T00:00:00.000Z" })
 *  => 08/05/2021 3:30 PM MST
 *
 * overriding pattern
 *
 * formatDate({
 *  date: "2021-08-06T00:00:00.000Z",
 *  pattern: "P p"
 * })
 *  => 08/05/2021 3:30 PM
 *
 * overriding pattern + timeZone
 *
 * formatDate({
 *  date: "2021-08-06T00:00:00.000Z",
 *  pattern: "P",
 *  timeZone: "UTC"
 * })
 * => 08/06/2021
 */
export const formatDate = ({
  date,
  pattern = "P p z", // ex: 08/05/2021 3:30 PM MST
  timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "UTC",
  opts = {},
}: {
  date: string;
  pattern?: string;
  timeZone?: string;
  opts?: OptionsWithTZ;
}) => {
  if (!date) {
    return EM_DASH;
  }

  const zonedDate = utcToZonedTime(date, timeZone, opts);
  return format(zonedDate, pattern, { timeZone });
};

export const formatTime = (
  timestamp: number,
  pattern = "P p z",
  timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "UTC"
) => {
  if (!timestamp) {
    return EM_DASH;
  }

  const zonedDate = new Date(timestamp);
  return format(zonedDate, pattern, { timeZone });
};

// example output: "3 days ago"
// note: date should be in UTC so that we don't worry about timezones
export const formatDateToBeHumanReadable = ({
  date,
  timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? "UTC",
  opts = {},
  formatOpts = {
    addSuffix: true,
  },
}: {
  date: string | Date;
  timeZone?: string;
  opts?: OptionsWithTZ;
  formatOpts?: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
    locale?: Locale;
  };
}) => {
  if (!date) {
    return EM_DASH;
  }

  const zonedDate = utcToZonedTime(date, timeZone, opts);
  return formatDistance(new Date(zonedDate), new Date(), formatOpts);
};

/**
 * Use this function to consistently display datetimes across the app.
 *
 * @param {string} dateTime - A datetime in ISO8601 Format (e.g. "2019-02-25T16:36:29Z")
 * @returns {string} A nicely formatted dateTime (e.g. "4:36 PM")
 */
export function formatTimeFromDateTime(dateTime?: string) {
  if (!dateTime) {
    return "";
  }

  return format(parseISO(dateTime), "hh:mm a");
}

/**
 * Use this function to consistently display datetimes across the app.
 *
 * @todo Rename to formatDateTimeRelative
 *
 * @param {string} dateTime - A datetime in ISO8601 Format (e.g. "2019-02-25T16:36:29Z")
 * @returns {string} A nicely formatted dateTime (e.g. "Last Thursday at 4:36 PM")
 */
export function formatDateTime(dateTime?: string) {
  if (!dateTime) {
    return "";
  }
  return formatRelative(parseISO(dateTime), new Date());
}
