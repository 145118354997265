import * as React from "react";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  borderColor?: string;
  color?: string;
  className?: string;
  style?: Object;
};

export default function Pill({
  children,
  borderColor,
  color,
  className,
  style,
}: Props) {
  return (
    <div
      style={{
        borderRadius: "15px",
        border: `1px solid ${borderColor || "#333"}`,
        ...style,
      }}
      className={classNames("u-pleft8 u-pright8 u-m4", className)}
    >
      <div style={{ color: color || "#333" }}>{children}</div>
    </div>
  );
}
