import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import {
  Button,
  DrawerActions,
  DrawerContent,
  DrawerLoader,
  FormikCurrencyInputField,
  FormikSelectField,
  FormikSubmit,
  FormikTextInputField,
  FormTitle,
  VStack,
} from "@smartrent/ui";

import NoRecords from "@/common/NoRecords";

import { useParkingPropertyQuery, useStripeAccountsQuery } from "@/api";
import { useDialog } from "@/lib/contexts/dialog";

interface Props {
  propertyId: string;
  onSubmit: (
    values: PaymentConfigurationValues,
    actions: FormikHelpers<PaymentConfigurationValues>
  ) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

export interface PaymentConfigurationValues {
  stripe_connect_id: string;
  guest_parking_rate: string;
  guest_parking_session_max_subtotal: string;
  guest_parking_tax_rate: string;
  guest_parking_application_fee: string;
}

const validationSchema = Yup.object({
  stripe_connect_id: Yup.string()
    .nullable()
    .required("Stripe account is required"),
  guest_parking_rate: Yup.number()
    .required("Rate is required")
    .moreThan(0, "Rate must be greater than $0.00"),
  guest_parking_session_max_subtotal: Yup.number()
    .required("Session max is required")
    .moreThan(0.99, "Session max must be more than $0.99"),
  guest_parking_tax_rate: Yup.number()
    .typeError("Tax rate must be a number")
    .required("Tax rate is required")
    .moreThan(-1, "Tax rate must not be negative")
    .lessThan(100, "Tax rate must be less than 100%"),
  guest_parking_application_fee: Yup.number()
    .typeError("Application fee must be a number")
    .required("Application fee is required")
    .moreThan(2.9, "Application fee must be 3% or more")
    .lessThan(100, "Application fee must be less than 100%"),
});

export const PaymentConfigurationForm: React.FC<
  React.PropsWithChildren<Props>
> = ({ propertyId, onSubmit, onCancel, isLoading }) => {
  const { confirm } = useDialog();

  const { data: property, isLoading: loadingProperty } =
    useParkingPropertyQuery(propertyId);

  const { data: accounts, isLoading: loadingAccounts } =
    useStripeAccountsQuery();

  const loading = loadingProperty || loadingAccounts || isLoading;

  const accountOptions =
    accounts?.map((account) => ({
      label: `${account.business_name ?? account.email} (${account.id.substring(
        account.id.length - 4,
        account.id.length
      )})`,
      value: account.id,
    })) ?? [];

  const initialValues: PaymentConfigurationValues = useMemo(
    () => ({
      stripe_connect_id: property.stripe_connect_id,
      guest_parking_rate: (property.guest_parking_rate / 100).toString(),
      guest_parking_session_max_subtotal:
        (property.guest_parking_session_max_subtotal / 100).toString() ??
        "50.00",
      guest_parking_tax_rate:
        (property.guest_parking_tax_rate * 100).toString() ?? "",
      guest_parking_application_fee: (
        property.guest_parking_application_fee * 100
      ).toString(),
    }),
    [property]
  );

  const handleSubmit = useCallback(
    async (
      values: PaymentConfigurationValues,
      actions: FormikHelpers<PaymentConfigurationValues>
    ) => {
      if (values.guest_parking_tax_rate === "0") {
        const confirmed = await confirm({
          title: `Confirm Sales Tax`,
          description: "Are you sure the sales tax is 0% for this property?",
        });

        if (confirmed) {
          onSubmit(values, actions);
        }
      } else {
        onSubmit(values, actions);
      }
    },
    [confirm, onSubmit]
  );

  const submitText = useMemo(
    () =>
      property.guest_parking_payments_module_enabled ? "Update" : "Enable",
    [property]
  );

  return loading ? (
    <DrawerLoader />
  ) : (
    <Formik<PaymentConfigurationValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <>
        <DrawerContent>
          <View style={styles.header}>
            <FormTitle>Payment Configuration</FormTitle>
          </View>
          <VStack spacing={24}>
            {accountOptions.length > 0 ? (
              <FormikSelectField
                name="stripe_connect_id"
                options={accountOptions}
                label="Associate Stripe Connected Account"
                required
              />
            ) : (
              <NoRecords title="No Stripe Connect Accounts Available" />
            )}
            <FormikCurrencyInputField
              name="guest_parking_rate"
              label="Hourly Rate"
              decimal
              required
            />
            <FormikCurrencyInputField
              name="guest_parking_session_max_subtotal"
              label="Max Session Limit"
              decimal
              required
            />
            <FormikTextInputField
              name="guest_parking_tax_rate"
              label="Sales Tax %"
              required
            />
            <FormikTextInputField
              name="guest_parking_application_fee"
              label="SmartRent Application Fee %"
              required
            />
          </VStack>
        </DrawerContent>
        <DrawerActions>
          <Button onPress={onCancel} variation="plain">
            Cancel
          </Button>
          <FormikSubmit label={submitText} />
        </DrawerActions>
      </>
    </Formik>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
