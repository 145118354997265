import { QueryFunction, QueryKey, useQuery } from "@tanstack/react-query";
import { InstallUserProps } from "@smartrent/install";

import { ListQueryResponse } from "@/types";

import { instance } from "@/lib/hooks";
import { DEALER_API_RECORDS_LIMIT } from "@/utils";

export interface InstallUserListProps extends InstallUserProps {
  company?: {
    name: string;
  };
  job_count: number;
  project_count: number;
}

const getUser = async (userId: string) => {
  const { data } = await instance.get(`/alloy-install/users/${userId}`);
  return data;
};

export const useGetUser = (userId: string, opts = {}) =>
  useQuery(["alloy-install-user", userId], () => getUser(userId), opts);

export const getUsers = async (_key: string, filters: any) => {
  const response = await instance.get<ListQueryResponse<InstallUserListProps>>(
    `/alloy-install/users`,
    {
      params: {
        ...filters,
        limit: DEALER_API_RECORDS_LIMIT,
      },
    }
  );

  return response.data;
};

interface FetchAlloyInstallUsersFilterProps {
  name?: string;
  property_remote_id?: string;
  page?: number;
}

export const fetchAlloyInstallUsers: QueryFunction<
  ListQueryResponse<InstallUserListProps>
> = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [, , filters] = queryKey as [
    string,
    Record<string, unknown>,
    FetchAlloyInstallUsersFilterProps,
  ];

  const response = await instance.get<ListQueryResponse<InstallUserListProps>>(
    "alloy-install/users",
    {
      params: {
        ...filters,
        limit: DEALER_API_RECORDS_LIMIT,
      },
    }
  );

  return response.data;
};
