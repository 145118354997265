/* Dev Note: This file is deprecated, use sr-table/Table.tsx */

import { Component, Fragment } from "react";
import classNames from "classnames";
import { Location } from "history";
import { clamp } from "lodash-es";

import Helpers from "@/lib/helpers";

interface PaginationProps {
  totalPages: number;
  className?: string;
  history: any;
  location: any;
  pageKey?: string;
}

function getClampedPageNumberFromLocation(
  location: Location,
  totalPages: number = 1,
  pageQueryKey: string = "page"
) {
  const qs = Helpers.qsFromLocation(location);

  const page = Number(qs[pageQueryKey]);
  if (isNaN(page)) {
    return 1;
  }

  if (totalPages < 1) {
    totalPages = 1;
  }

  return clamp(page, 1, totalPages || 1);
}

class Pagination extends Component<PaginationProps> {
  static defaultProps: Partial<PaginationProps> = {
    pageKey: "page",
  };

  componentDidUpdate() {
    const { totalPages, location, history, pageKey: pageQueryKey } = this.props;

    const qs = Helpers.qsFromLocation(location);
    const page = Number(qs[pageQueryKey]);

    if (!page) {
      // if page isn't set in the query string, we'll default to 1 and leave the
      // url alone to avoid navigating just to add the implicit ?page=1
      return;
    }

    if (page < 1) {
      Helpers.updateQS({
        history,
        method: "replace",
        update: { [pageQueryKey]: 1 },
        location,
      });
    } else if (totalPages && page > totalPages) {
      // we'll only navigate to the last page if the page count is provided
      // (and isn't falsey). that way if totalPages is 0 for some reason,
      // we don't redirect to ?page=0, which would then cause us to redirect on
      // the next render to ?page=1

      Helpers.updateQS({
        history,
        method: "replace",
        update: { [pageQueryKey]: totalPages },
        location,
      });
    }
  }

  renderPrev({ page }) {
    const { location, history, pageKey: pageQueryKey } = this.props;
    return (
      <button
        disabled={page < 1}
        className="button button__pagination button--outline"
        type="button"
        onClick={() =>
          Helpers.updateQS({
            history,
            location,
            update: { [pageQueryKey]: page },
          })
        }
      >
        &laquo; Prev
      </button>
    );
  }

  renderNext({ page, totalPages }) {
    const { location, history, pageKey: pageQueryKey } = this.props;
    return (
      <button
        disabled={page > totalPages}
        className="button button__pagination button--outline"
        type="button"
        onClick={() =>
          Helpers.updateQS({
            history,
            location,
            update: { [pageQueryKey]: page },
          })
        }
      >
        Next &raquo;
      </button>
    );
  }

  renderInactivePage({ page }) {
    const { location, history, pageKey: pageQueryKey } = this.props;
    return (
      <button
        onClick={() =>
          Helpers.updateQS({
            history,
            location,
            update: { [pageQueryKey]: page },
          })
        }
        className="button button__pagination button--outline"
        type="button"
      >
        {page}
      </button>
    );
  }

  renderEllipsis() {
    return <span className="u-font12 u-text-gray-400">...</span>;
  }

  renderNumbers({ page, totalPages }) {
    return (
      <Fragment>
        {page - 2 >= 1 ? this.renderEllipsis() : <span>&nbsp;&nbsp;</span>}
        {page === totalPages && page - 2 >= 1
          ? this.renderInactivePage({ page: page - 2 })
          : null}
        {page > 1 ? this.renderInactivePage({ page: page - 1 }) : null}
        <button className="button button__pagination" type="button">
          {page}
        </button>
        {page < totalPages ? this.renderInactivePage({ page: page + 1 }) : null}
        {page === 1 && page + 2 <= totalPages
          ? this.renderInactivePage({ page: page + 2 })
          : null}
        {page + 2 <= totalPages ? (
          this.renderEllipsis()
        ) : (
          <span>&nbsp;&nbsp;</span>
        )}
      </Fragment>
    );
  }

  render() {
    const {
      className,
      totalPages,
      location,
      pageKey: pageQueryKey,
    } = this.props;

    // 0 pages (no records found) or only 1 page, don't render this component, it's not necessary
    if (!totalPages || totalPages === 1) {
      return null;
    }

    const page = getClampedPageNumberFromLocation(
      location,
      totalPages,
      pageQueryKey
    );

    return (
      <div className="u-flex u-flex-justify-around">
        <div
          className={classNames(
            "pagination",
            "u-flex-align-items-center",
            className
          )}
        >
          {this.renderPrev({ page: page - 1 })}
          {this.renderNumbers({ page, totalPages })}
          {this.renderNext({ page: page + 1, totalPages })}
        </div>
      </div>
    );
  }
}

export default Pagination;
