import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet } from "react-native";
import { Formik, FormikHelpers } from "formik";
import {
  Button,
  FormikSubmit,
  useTheme,
  Typography,
  Drawer,
  DrawerContent,
  DrawerActions,
} from "@smartrent/ui";
import { CloseCircleSolid } from "@smartrent/icons";

import { Column } from "./types";

export interface TableFiltersProps {
  title?: string;

  open: boolean;
  onClose?: () => void;
  columns: Column<any>[];

  filters?: Record<string, unknown>;
  onFiltersChange?: (filters: Record<string, unknown>) => void;
  drawerClearFiltersAction: boolean;
  onClearFilters?: () => void;
}

const _TableFilters: React.FC<React.PropsWithChildren<TableFiltersProps>> = ({
  title = "Filter",
  open,
  filters,
  onFiltersChange,
  onClose,
  columns,
  drawerClearFiltersAction,
  onClearFilters,
}) => {
  const { colors } = useTheme();

  const handleSubmit = useCallback(
    (values: Record<string, unknown>, helpers: FormikHelpers<any>) => {
      onClose();
      onFiltersChange(values);
      helpers.resetForm();
    },
    [onFiltersChange, onClose]
  );

  const handleClearFilters = useCallback(() => {
    onClose();
    onClearFilters();
  }, [onClose, onClearFilters]);

  return (
    <Drawer title={title} open={open} onClose={onClose} anchor="right">
      {open ? (
        <Formik
          enableReinitialize
          initialValues={filters}
          onSubmit={handleSubmit}
        >
          <>
            {drawerClearFiltersAction ? (
              <View style={styles.clearFieldsWrapper}>
                <Button
                  variation="plain"
                  textStyle={{ color: colors.error }}
                  onPress={handleClearFilters}
                >
                  <Typography style={styles.clearFieldsText}>
                    Clear Filters
                  </Typography>
                  <CloseCircleSolid />
                </Button>
              </View>
            ) : null}

            <DrawerContent>
              {columns
                .filter((c) => c.filter)
                .map((c) => {
                  const { name, filter: Filter } = c;
                  return <Filter key={`${name}_filter`} name={name} />;
                })}
            </DrawerContent>

            <DrawerActions>
              <Button size="small" onPress={onClose} variation="plain">
                Cancel
              </Button>

              <FormikSubmit size="small" label="Apply Filters" />
            </DrawerActions>
          </>
        </Formik>
      ) : null}
    </Drawer>
  );
};

export const styles = StyleSheet.create({
  clearFieldsWrapper: {
    marginTop: 25,
    marginRight: 25,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  clearFieldsText: { marginRight: 5 },
});

export const TableFilters = React.memo(_TableFilters);
