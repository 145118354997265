import { useCallback, useContext, useMemo } from "react";
import * as React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import QRCode from "qrcode.react";

import { AnimatedPlaceholder, Typography, useTheme } from "@smartrent/ui";

import { Sign } from "@/types";
import { useGenerateSignQRCodeMutation, useParkingWebUrlQuery } from "@/api";
import Context from "@/layout/Context";

interface SignQRCodeProps {
  sign: Sign;
  qrCodeSize?: number;
}

export const SignQRCode: React.FC<React.PropsWithChildren<SignQRCodeProps>> = ({
  sign,
  qrCodeSize: qrCodeSize = 96,
}) => {
  const context = useContext(Context);
  const { colors } = useTheme();

  const { data, isLoading } = useParkingWebUrlQuery();

  const url = useMemo(() => `${data}/s/${sign.id}`, [data, sign]);

  const [generateQRCode] = useGenerateSignQRCodeMutation();

  const handleDownloadPress = useCallback(async () => {
    const qrCode = (await generateQRCode({
      propertyId: sign.property_id,
      signId: sign.id,
    })) as unknown as string;

    const qrCodeUrl = URL.createObjectURL(
      new Blob([qrCode], { type: "image/svg" })
    );

    const aLink = document.createElement("a");
    aLink.download = `${sign?.location} QR Code.svg`;
    aLink.href = qrCodeUrl;
    aLink.target = "_blank";
    aLink.setAttribute("dataType", "svg");

    const event = new MouseEvent("click");
    aLink.dispatchEvent(event);

    context.setToast({
      type: "success",
      title: "Success",
      message: "Successfully downloaded QR Code for sign.",
    });
  }, [sign]);

  return (
    <View>
      <View style={styles.qrCode}>
        {isLoading ? (
          <AnimatedPlaceholder
            style={{
              backgroundColor: colors.gray100,
              height: qrCodeSize,
              width: qrCodeSize,
            }}
          />
        ) : (
          <QRCode value={url} size={qrCodeSize} />
        )}
      </View>

      <View style={styles.buttonContainer}>
        {isLoading ? (
          <AnimatedPlaceholder
            style={[
              styles.buttonLoading,
              {
                backgroundColor: colors.gray100,
                width: qrCodeSize,
              },
            ]}
          />
        ) : (
          <TouchableOpacity onPress={handleDownloadPress}>
            <Typography color="info" type="title5">
              DOWNLOAD
            </Typography>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  qrCode: {
    alignItems: "center",
    marginBottom: 8,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  buttonLoading: { width: 200, height: 50 },
});
