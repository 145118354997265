import { createAxiosQuery } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";

import { GroupProps } from "@/types";

export interface MaintenanceVendor {
  id: number | string;
  email: string;
  first_name: string;
  group_id: string;
  inserted_at: string;
  last_name: string;
  phone: string;
  make_ready_vendor: boolean;
  receives_leak_notifications: boolean;
}
export interface MaintenanceVendorResponse {
  maintenanceVendor: MaintenanceVendor;
  group: GroupProps;
}

export interface MaintenanceVendorRequest {
  maintenanceVendorId: string | null;
}

export const useMaintenanceVendorQuery = createAxiosQuery(
  "maintenance-vendor",
  async ({ maintenanceVendorId }: MaintenanceVendorRequest) => {
    return instance.get<MaintenanceVendorResponse>(
      `/maintenance-vendors/${maintenanceVendorId}`
    );
  }
);
