import { Redirect, Route, Switch } from "react-router-dom";

import { View } from "react-native";
import { TabProvider, TabsNavigation, useTheme } from "@smartrent/ui";
import {
  ClipboardFilled,
  ClipboardTask,
  FlagSolid,
  SmartHome,
  Users,
} from "@smartrent/icons";

import { ProjectList } from "@/common/projects/ProjectList";
import UsersList from "@/common/users/UsersList";
import Installs from "@/pages/Installs";
import { JobList } from "@/common/jobs/JobList";
import { FlaggedJobsList } from "@/common/jobs/FlaggedJobsList";
import Layout from "@/layout/Layout";

const tabs = [
  {
    id: "installs",
    label: "Installs",
    Icon: SmartHome,
    to: "/alloy-install",
  },
  {
    id: "users",
    label: "Users",
    Icon: Users,
    to: "/alloy-install/users",
  },
  {
    id: "projects",
    label: "Projects",
    Icon: ClipboardFilled,
    to: "/alloy-install/projects",
  },
  {
    id: "jobs",
    label: "Jobs",
    Icon: ClipboardTask,
    to: "/alloy-install/jobs",
  },
  {
    id: "flagged-jobs",
    label: "Flagged Jobs",
    Icon: FlagSolid,
    to: "/alloy-install/flagged-jobs",
  },
];

export function InstallsDashboard() {
  const { colors } = useTheme();

  return (
    <Layout>
      <TabProvider>
        <View>
          <View style={{ backgroundColor: colors.background }}>
            <TabsNavigation items={tabs} />
          </View>
          <Switch>
            <Route exact path="/alloy-install">
              <Installs />
            </Route>
            <Route path="/alloy-install/users">
              <UsersList />
            </Route>
            <Route path="/alloy-install/projects">
              <ProjectList />
            </Route>
            <Route path="/alloy-install/jobs">
              <JobList />
            </Route>
            <Route path="/alloy-install/flagged-jobs">
              <FlaggedJobsList />
            </Route>
            <Route exact path="/alloy-install/*">
              <Redirect to="/alloy-install" />
            </Route>
          </Switch>
        </View>
      </TabProvider>
    </Layout>
  );
}
