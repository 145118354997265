import { useState, useRef } from "react";
import * as React from "react";
import { Map, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Skeleton } from "@smartrent/ui";

import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

interface Props {
  loading: boolean;
  markersDOM: React.ReactNode[];
  mapboxApiKey: string;
}

/**
 * This is a dumb component, all data fetching must occur in the parent
 * @returns
 */
export function SRMap({ loading, markersDOM, mapboxApiKey }: Props) {
  const position: [number, number] = [39.598106, -100.51848];
  const initialZoom = 5;
  const [zoom, setZoom] = useState(initialZoom);
  const mapRef = useRef();

  return (
    <>
      {!loading && (
        <Map
          style={{ height: "100%" }}
          zoom={zoom}
          ref={mapRef}
          onZoom={(e) => setZoom(e.target._zoom)}
          center={position}
        >
          <TileLayer
            attribution='&amp;copy <a href="#">Mapbox</a>'
            url={`https://api.mapbox.com/styles/v1/jwdotjs/ck3j20hr01f0n1clmvdlhp9oa/tiles/256/{z}/{x}/{y}?access_token=${
              mapboxApiKey || ""
            }`}
          />
          {markersDOM ? (
            <MarkerClusterGroup>{markersDOM}</MarkerClusterGroup>
          ) : null}
        </Map>
      )}
      {loading && (
        <Skeleton style={{ width: "100%", height: "100%" }} textType="title" />
      )}
    </>
  );
}
