import { EM_DASH } from "./chars";

export const formatNumber = (number: string | number | null) => {
  // if it doesn't exist, return a '-'
  if (number === undefined || number === null) {
    return EM_DASH;
  }
  // if its a string, try to parse it as a number
  if (typeof number === "string") {
    return Number.parseInt(number).toLocaleString();
  }

  // otherwise, return formatted number
  return Number(number).toLocaleString();
};
