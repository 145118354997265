import { capitalize, startCase } from "lodash-es";

import {
  ScopeOfWorkDetailCellQuality,
  ScopeOfWorkDeviceCategory,
  ScopeOfWorkStatus,
} from "@/types";

export const PROJECT_ASSESSMENT_REPORT_SF_FIELD_CHAR_LIMIT = 255;
export const PROJECT_ASSESSMENT_REPORT_INPUT_TEXT_CHAR_LIMIT = 5000;
export const NUMBER_MAX = 10000;
export const PROJECT_ASSESSMENT_REPORT_QUANTITY_LIMIT = 100000;

export const formatScopeOfWorkStatus = (status: ScopeOfWorkStatus) =>
  startCase(status.toLowerCase());

export const statusOptions = [
  {
    label: capitalize(ScopeOfWorkStatus.Draft),
    value: ScopeOfWorkStatus.Draft,
  },
  {
    label: startCase(ScopeOfWorkStatus.ReadyForReview),
    value: ScopeOfWorkStatus.ReadyForReview,
  },
  {
    label: capitalize(ScopeOfWorkStatus.Submitted),
    value: ScopeOfWorkStatus.Submitted,
  },
  {
    label: capitalize(ScopeOfWorkStatus.Syncing),
    value: ScopeOfWorkStatus.Syncing,
  },
  {
    label: capitalize(ScopeOfWorkStatus.Error),
    value: ScopeOfWorkStatus.Error,
  },
  {
    label: capitalize(ScopeOfWorkStatus.Completed),
    value: ScopeOfWorkStatus.Completed,
  },
];

export const cellQualityOptions = [
  {
    label: capitalize(ScopeOfWorkDetailCellQuality.Bad),
    value: ScopeOfWorkDetailCellQuality.Bad,
  },
  {
    label: capitalize(ScopeOfWorkDetailCellQuality.Okay),
    value: ScopeOfWorkDetailCellQuality.Okay,
  },
  {
    label: capitalize(ScopeOfWorkDetailCellQuality.Good),
    value: ScopeOfWorkDetailCellQuality.Good,
  },
  {
    label: capitalize(ScopeOfWorkDetailCellQuality.Excellent),
    value: ScopeOfWorkDetailCellQuality.Excellent,
  },
];

export const deviceCategoryOptions = [
  {
    label: "Thermostats",
    value: ScopeOfWorkDeviceCategory.Thermostats,
  },
  {
    label: "Light Switches",
    value: ScopeOfWorkDeviceCategory["Light Switches"],
  },
  {
    label: "Locks",
    value: ScopeOfWorkDeviceCategory.Locks,
  },
  {
    label: "Outlets",
    value: ScopeOfWorkDeviceCategory.Outlets,
  },
  {
    label: "Hubs",
    value: ScopeOfWorkDeviceCategory.Hubs,
  },
  {
    label: "Sensors",
    value: ScopeOfWorkDeviceCategory.Sensors,
  },
  {
    label: "Professional Services",
    value: ScopeOfWorkDeviceCategory["Professional Services"],
  },
  {
    label: "Other",
    value: ScopeOfWorkDeviceCategory.Other,
  },
];

export const cellQualityValues = cellQualityOptions.map((opt) => opt.value);

export const YesNoOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const mockDeviceCategoryOptions = [
  {
    label: "Lock",
    value: "lock",
  },
  {
    label: "Thermostat",
    value: "thermostat",
  },
  {
    label: "Sensor",
    value: "sensor",
  },
  {
    label: "Security",
    value: "security",
  },
];
