import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";

import { Drawer, DrawerProps } from "@smartrent/ui";

import { useStack } from "@/lib/hooks";

interface Route {
  component: React.ComponentType<React.PropsWithChildren<any>>;
  header?: React.ComponentType<React.PropsWithChildren<any>>;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export type RouteMap = Record<string, Route>;

export type RouteParams<
  TRouteMap extends RouteMap,
  TRoute extends keyof TRouteMap,
> = Omit<React.ComponentProps<TRouteMap[TRoute]["component"]>, "children">;

export interface RouteDescriptor<
  TRouteMap extends RouteMap,
  TRoute extends keyof TRouteMap,
> {
  route: TRoute;
  params: RouteParams<TRouteMap, TRoute>;
}

export interface DrawerNavState<
  TRouteMap extends RouteMap,
  TRoutes extends keyof TRouteMap = keyof TRouteMap,
> {
  push: <TRoute extends TRoutes>(
    route: TRoute,
    params: RouteParams<TRouteMap, TRoute>
  ) => void;

  replace: <TRoute extends TRoutes>(
    route: TRoute,
    params: RouteParams<TRouteMap, TRoute>
  ) => void;

  pop: () => void;
  reset: () => void;

  activeRoute: RouteDescriptor<TRouteMap, TRoutes> | undefined;
}

interface CreateDrawerOptions<TRouteMap extends RouteMap> {
  resetOnNavigation?: boolean;
  routes: TRouteMap;
}

export function createDrawer<TRouteMap extends RouteMap>(
  displayName: string,
  { resetOnNavigation = true, routes }: CreateDrawerOptions<TRouteMap>
) {
  const DrawerNavCtx = createContext<DrawerNavState<TRouteMap>>(
    undefined as any
  );

  DrawerNavCtx.displayName = `createDrawer(${displayName})`;

  const useDrawerNav = () => useContext(DrawerNavCtx);

  const Provider: React.FC<
    React.PropsWithChildren<Omit<DrawerProps, "open" | "onClose">>
  > = ({ children, ...drawerProps }) => {
    const history = useHistory();

    const { stack, push, pop, replace, reset } =
      useStack<RouteDescriptor<TRouteMap, keyof TRouteMap>>();

    const activeRoute = useMemo(() => stack[stack.length - 1], [stack]);

    const pushRoute = useCallback(
      (route: keyof TRouteMap, params: any) => {
        push({ route, params });
      },
      [push]
    );

    const replaceRoute = useCallback(
      (route: keyof TRouteMap, params: any) => {
        replace({ route, params });
      },
      [replace]
    );

    useEffect(() => {
      return history.listen(() => {
        if (resetOnNavigation) {
          reset();
        }
      });
    }, [history, reset]);

    const { component, title, subtitle, header } = activeRoute
      ? routes[activeRoute.route]
      : ({} as any);

    return (
      <DrawerNavCtx.Provider
        value={{
          pop,
          reset,
          push: pushRoute,
          replace: replaceRoute,
          activeRoute,
        }}
      >
        {children}

        <Drawer
          {...drawerProps}
          title={title}
          subtitle={subtitle}
          open={!!activeRoute}
          onClose={reset}
          // maxWidth={}
        >
          {header ? React.createElement(header, activeRoute.params) : undefined}
          {component
            ? React.createElement(component, activeRoute.params)
            : undefined}
        </Drawer>
      </DrawerNavCtx.Provider>
    );
  };

  Provider.displayName = `createDrawer(${displayName}).Provider`;

  return {
    Provider,
    useDrawerNav,
  };
}
