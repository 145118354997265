import {
  UseReducedInfiniteQueryOptions,
  useReducedInfiniteQuery,
} from "@smartrent/hooks";

import { instance } from "@/lib/hooks";
import { createAxiosQuery } from "@/hooks/react-query";
import { SmsWebhookProps } from "@/types";

export interface SmsWebhooksResponse {
  current_page: number;
  records: SmsWebhookProps[];
  total_records: number;
  total_pages: number;
}

export interface SmsWebhooksOptions {
  phoneNumber: string | number;
  params?: {
    limit?: number;
    orderBy?: "DESC" | "ASC";
    sortBy?: keyof SmsWebhookProps;
  };
}

// why are there two of these :(

export const useWebhooksQuery = (
  phoneNumber: string,
  options: UseReducedInfiniteQueryOptions<SmsWebhooksResponse, [string, string]>
) =>
  useReducedInfiniteQuery(
    ["smsWebhooks", phoneNumber],
    async ({ pageParam = 1 }): Promise<SmsWebhooksResponse> => {
      return instance
        .get(`/sms-webhooks/${phoneNumber}?page=${pageParam}`)
        .then((response) => response.data);
    },
    { ...options }
  );

export const useSmsWebhooksQuery = createAxiosQuery(
  "sms-webhooks",
  async ({
    phoneNumber,
    params = { limit: 25, sortBy: "inserted_at", orderBy: "DESC" },
  }: SmsWebhooksOptions) => {
    return instance.get<SmsWebhooksResponse>(
      `/sms-webhooks/${encodeURIComponent(phoneNumber)}`,
      {
        params,
      }
    ); // RESTful
  }
);
