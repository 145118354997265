import { createContext, useContext } from "react";

import Helpers from "@/lib/helpers";

import { ContextProps, ToastProps, AccountProps } from "../types";

const toast: null | ToastProps = null;

// Global Context For Device Setup
// These are just defaults needed to make Typescript happy
// The values will get overridden when the top-level component loads or has state/prop changes
const Context = createContext({
  user: Helpers.defaultUser(),
  pageLoading: true,
  setPageLoading: (bool: boolean) => {},
  setUser: (user: AccountProps) => {},
  sidenavOpen: false,
  setSidenavOpen: (bool: boolean) => {},
  match: null,
  location: null,
  history: null,
  isDesktop: true,
  setFlashMessage: (v: any) => {},
  flashMessage: null,
  setToast: (props: ToastProps) => {},
  toast,
  scrollViewRef: null,
} as ContextProps);

export default Context;

export const useGlobalContext = () => useContext(Context);
export const useUser = () => useContext(Context).user as AccountProps;
