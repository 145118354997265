import LastState from "./device-attribute-components/LastState";
import Name from "./device-attribute-components/Name";
import FailedAttempts from "./device-attribute-components/FailedAttempts";
import Insights from "./device-attribute-components/Insights";

export default function DeviceAttribute({ unit, da, accessCodes }) {
  // attributes to hide altogether as they aren't relevant for support
  if (["user_codes"].includes(da.name)) {
    return null;
  }

  // If the unit is vacant show all values
  const restrictedAttributes =
    unit?.is_occupied || unit?.status === "Occupied"
      ? [
          "cooling_setpoint",
          "heating_setpoint",
          "mode",
          "fan_mode",
          "level",
          "on",
        ]
      : [];

  // Ignored because they contain sensitive data
  const lastReadState = restrictedAttributes.includes(da.name) ? null : (
    <LastState da={da} accessCodes={accessCodes} />
  );

  return (
    <div data-device-attribute-id={da.id}>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          padding: "4px",
          borderTop: "1px solid #eee",
          borderBottom: "1px solid #eee",
        }}
      >
        <div className="u-flex u-flex-align-items-center u-flex-justify-between">
          <div>
            <span className="h6">
              <Name da={da} />
            </span>{" "}
            {lastReadState}
          </div>
        </div>
        <FailedAttempts da={da} />
      </div>

      <Insights attribute={da} />
    </div>
  );
}
