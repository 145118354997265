import * as React from "react";

import Helpers from "@/lib/helpers";

import { useUser } from "@/layout/Context";

import { useUnit } from "../UnitContext";

export const UnitTechnicalDetails: React.VFC = () => {
  const { permissions } = useUser();
  const { unit, group, organization } = useUnit();

  if (!permissions.view_technical_details) {
    return null;
  }

  return (
    <div
      className={`${Helpers.technicalDetailsTextClass} u-font10 u-mtop12 u-line18`}
    >
      org_id: {organization.id} / group_id: {group.id} / id: {unit.id}
      <div>
        property_code: {group.property_code}
        {" / "}
        unit.remote_id: {unit.remote_id}
      </div>
    </div>
  );
};
