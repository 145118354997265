import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  ComponentType,
} from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { startCase } from "lodash-es";
import { useQueryClient } from "@tanstack/react-query";

import { Formik } from "formik";
import * as Yup from "yup";

import {
  ConfirmDialog,
  HStack,
  Panel,
  PanelBody,
  PanelHeader,
  Typography,
  VStack,
  Button,
  ActivityIndicator,
  TextareaField,
  Form,
  FormikSelectField,
  FormikNumberInputField,
  FormikTextareaField,
  FormikSubmit,
  useSelectQuery,
  StaticTextField,
  CharacterCount,
  Tooltip,
  useTheme,
} from "@smartrent/ui";
import { useModalState, PaginatedResponse } from "@smartrent/hooks";
import {
  PlusSolid,
  Minus,
  IconProps,
  Pencil,
  CheckSolid,
  InformationSolid,
} from "@smartrent/icons";

import { useParams } from "react-router-dom";

import { instance } from "@/lib/hooks";

import {
  useCreateScopeOfWorkDeviceMutation,
  useScopeOfWorkDeleteDeviceMutation,
  useUpdateScopeOfWorkDeviceMutation,
  useScopeOfWorkProductsPriceQuery,
  useSubmitRevisionNotesMutation,
} from "@/api";

import { useGlobalContext } from "@/layout/Context";

import { ScopeOfWorkDeviceProps, SalesforceDevice } from "@/types";
import { formatDate } from "@/utils";
import { EM_DASH } from "@/utils/chars";

import { useScopeOfWorkContext } from "./provider/ScopeOfWorkContext";
import { PROJECT_ASSESSMENT_REPORT_INPUT_TEXT_CHAR_LIMIT } from "./utils";

interface AddDeviceUrlParams {
  category: string;
}
export const ScopeOfWorkDeviceSelection: React.VFC = () => {
  const { colors } = useTheme();
  const [deviceToEdit, setDeviceToEdit] = useState(null);
  const queryClient = useQueryClient();
  const { visible, onOpen, onClose } = useModalState();

  const { category } = useParams<AddDeviceUrlParams>();

  // capitalize the device category matched in the url
  const deviceType = useMemo(
    () => (category ? startCase(category) : category),
    [category]
  );

  // This is used to display what category is being used, e.g. "Light Switches"
  // but we want to remove the "es" or "s" from the end so that it displays as
  // singular. e.g. "Light Switch 1 Type"
  const deviceTypeForUI = useMemo(() => {
    if (!deviceType) {
      return "";
    }

    return deviceType
      .replace(/\b(Professional Services)\b/, "Professional Service") // Replace "Professional Services" with "Professional Service"
      .replace(/(es)\b$/, "") // Remove 'es' at the end of words
      .replace(/s\b$/, ""); // Remove 's' at the end of words
  }, [deviceType]);

  const [devices, setDevices] = useState<ScopeOfWorkDeviceProps[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAddButton, setShowAddButton] = useState(false);
  const [deletingIndex, setDeletingIndex] = useState<number | null>(null);

  useEffect(() => {
    if (devices.length >= 1) {
      setShowAddButton(true);
    }
  }, [devices]);

  const {
    scopeOfWorkQuery: { data, isLoading },
  } = useScopeOfWorkContext();

  const [createScopeOfWorkDeviceMutation] =
    useCreateScopeOfWorkDeviceMutation();
  const [deleteScopeOfWorkDeviceMutation, { isLoading: deletingDevice }] =
    useScopeOfWorkDeleteDeviceMutation();
  const [updateScopeOfWorkDeviceMutation] =
    useUpdateScopeOfWorkDeviceMutation();
  const [submitScopeOfWorkRevisionNotesMutation] =
    useSubmitRevisionNotesMutation();

  const { data: productPriceData, isLoading: priceDataLoading } =
    useScopeOfWorkProductsPriceQuery(
      {
        productIds: devices.map((device) => device.sf_product_id),
        opportunityId: data?.sf_opportunity_id || "",
        category: deviceType,
        scopeOfWorkId: data?.id,
      },
      { enabled: !!data?.sf_opportunity_id && !!devices.length }
    );

  useEffect(() => {
    setDevices(
      deviceType && data?.scope_of_work_devices?.length
        ? data.scope_of_work_devices.filter(
            (device) => device.sf_device_category === deviceType
          )
        : ([] as ScopeOfWorkDeviceProps[])
    );
  }, [deviceTypeForUI, data?.scope_of_work_devices, deviceType]);

  const { history, setToast } = useGlobalContext();

  useEffect(() => {
    queryClient.invalidateQueries(["salesforce-devices", "product-price"]);
  }, [deviceTypeForUI, queryClient]);

  const selectQueryPropsSalesforceDevices = useSelectQuery(
    (inputValue) => ["salesforce-devices", deviceType, { inputValue }] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, deviceType, { inputValue }] = queryKey;
      return instance
        .get(`/salesforce/devices`, {
          params: {
            term: inputValue ? inputValue : undefined,
            category: deviceType,
            page: pageParam,
            limit: 25,
          },
        })
        .then(
          (response) => response.data as PaginatedResponse<SalesforceDevice>
        );
    },
    {},
    {
      inputDebounceInterval: 250,
    }
  );

  const addDevice = useCallback(
    async (values: any, actions: any) => {
      actions.setSubmitting(true);
      try {
        const selectedDevice = selectQueryPropsSalesforceDevices.options.find(
          (option) => option.Id === values.selectedDevice
        );
        const deviceToBeAdded = {
          selectedDevice: {
            ...selectedDevice,
            Product_category__c: deviceType,
          },
          unitAmount: values.unitAmount,
          additionalNotes: values.additionalNotes,
        };
        if (
          devices.some(
            (device) => device.sf_product_id === values.selectedDevice
          )
        ) {
          setToast({
            type: "error",
            title: "Error",
            message: `You've already added ${deviceToBeAdded?.selectedDevice.Name}. Please remove it or select a different device before trying again.`,
          });
          return;
        }

        await createScopeOfWorkDeviceMutation({
          scope_of_work_id: data?.id ? data.id : "",
          device: deviceToBeAdded,
          current_order: data?.scope_of_work_devices_order
            ? data?.scope_of_work_devices_order
            : [],
          opportunity: data?.sf_opportunity_id,
          category: deviceType,
        });

        setCurrentIndex(currentIndex + 1);
      } catch (error) {
        setToast({
          type: "error",
          title: "Error",
          message: "Something went wrong. Please contact engineering.",
        });
      }
      actions.setSubmitting(false);
      actions.resetForm();
      setShowAddButton(true);
    },
    [
      createScopeOfWorkDeviceMutation,
      currentIndex,
      data?.id,
      data?.scope_of_work_devices_order,
      data?.sf_opportunity_id,
      deviceType,
      devices,
      selectQueryPropsSalesforceDevices.options,
      setToast,
    ]
  );

  const editDevice = useCallback(
    async (values: any, actions: any) => {
      if (!devices.length || !deviceToEdit) {
        return null;
      }
      actions.setSubmitting(true);
      try {
        const selectedDevice =
          selectQueryPropsSalesforceDevices.options.find(
            (option) => option.Id === values.selectedDevice
          ) || ({ Name: "Unknown Device" } as SalesforceDevice);
        if (
          devices.some((device) => {
            // This allows the same product ID to be submitted for "editing" without throwing an error
            if (device?.id === deviceToEdit?.id) {
              return false;
            } else {
              return device.sf_product_id === values.selectedDevice;
            }
          })
        ) {
          setToast({
            type: "error",
            title: "Error",
            message: `You've already added ${selectedDevice.Name}. Please select a different device before trying again.`,
          });
          return;
        }

        const deviceChanged =
          deviceToEdit?.sf_device_sku != selectedDevice.Name;
        const quantityChanged = deviceToEdit?.quantity != values.unitAmount;
        const notesChanged = deviceToEdit?.notes != values.additionalNotes;

        let notes = "";

        if (deviceChanged) {
          notes += `Device Changed: ${deviceToEdit?.sf_device_sku} → ${selectedDevice.Name}\n\n`;
        }
        if (quantityChanged) {
          notes += `Quantity Changed: ${deviceToEdit?.quantity} → ${values.unitAmount}\n\n`;
        }
        if (notesChanged) {
          notes += `Notes Changed: ${deviceToEdit?.notes} → ${values.additionalNotes}`;
        }

        await submitScopeOfWorkRevisionNotesMutation({
          scope_of_work_id: data?.id,
          scope_of_work_device_id: deviceToEdit?.id,
          notes,
        });

        await updateScopeOfWorkDeviceMutation({
          scope_of_work_id: data?.id ? data.id : "",
          device: {
            id: deviceToEdit?.id,
            quantity: values.unitAmount || null,
            notes: values.additionalNotes || null,
            sf_product_id: values.selectedDevice,
            sf_device_sku: selectQueryPropsSalesforceDevices.options.find(
              (device) => device?.Id === values.selectedDevice
            )?.Name,
          },
          opportunity: data?.sf_opportunity_id,
          category: deviceType,
        });
      } catch (error) {
        setToast({
          type: "error",
          title: "Error",
          message: "Something went wrong. Please contact engineering.",
        });
      }
      actions.setSubmitting(false);
      actions.resetForm();
      setDeviceToEdit(null);
    },
    [
      devices,
      deviceToEdit,
      selectQueryPropsSalesforceDevices.options,
      updateScopeOfWorkDeviceMutation,
      data?.id,
      data?.sf_opportunity_id,
      deviceType,
      setToast,
    ]
  );

  const removeDevice = useCallback(
    (index: number) => {
      setDeletingIndex(index);
      deleteScopeOfWorkDeviceMutation({
        scope_of_work_id: data?.id ? data.id : "",
        device_id: devices[index].id,
      });
      if (devices.length === 0) {
        setShowAddButton(false);
      }
    },
    [data?.id, deleteScopeOfWorkDeviceMutation, devices]
  );

  const shouldBeDone = useCallback(() => {
    if (devices.length === 0 || !showAddButton) {
      return onOpen();
    } else {
      history.push(`/scope-of-work/${data?.id}`);
    }
  }, [data?.id, devices?.length, history, onOpen, showAddButton]);

  const initialValues = useMemo(
    () => ({
      selectedDevice: "",
      unitAmount: 0,
      additionalNotes: "",
    }),
    []
  );

  const getPriceToShow = (id: string) => {
    const pricing = productPriceData
      ? productPriceData.find((price) => price.id === id)
      : null;

    return (
      <HStack spacing={4} align="center">
        <Typography type="title4Bold" color="textSecondary">
          Price (each):
        </Typography>
        {data.sf_opportunity_id ? (
          <Typography type="title4">
            {!priceDataLoading ? (
              `$${pricing?.price || EM_DASH}`
            ) : (
              <ActivityIndicator />
            )}
          </Typography>
        ) : (
          <Typography type="title4">
            Select an opportunity to view price
          </Typography>
        )}
        {!priceDataLoading && (
          <Tooltip
            name="prospect_information"
            title={pricing ? pricing.type : "No price found"}
          >
            <View>
              <InformationSolid
                color={
                  pricing
                    ? pricing.type === "Contracted Price"
                      ? colors.warning
                      : colors.primary
                    : colors.error
                }
                size={16}
              />
            </View>
          </Tooltip>
        )}
      </HStack>
    );
  };

  const validationSchema = Yup.object({
    unitAmount: Yup.number().nullable(),
    additionalNotes: Yup.string()
      .max(
        PROJECT_ASSESSMENT_REPORT_INPUT_TEXT_CHAR_LIMIT,
        `Notes cannot exceed maximum character limit of ${PROJECT_ASSESSMENT_REPORT_INPUT_TEXT_CHAR_LIMIT.toLocaleString()}.`
      )
      .nullable()
      .default(null),
  });

  const initialValuesForEdit = {
    selectedDevice: deviceToEdit?.sf_product_id,
    unitAmount: deviceToEdit?.quantity,
    additionalNotes: deviceToEdit?.notes,
  };
  return (
    <View style={styles.flexRow}>
      <View style={styles.leftContent}>
        <Panel>
          <PanelHeader style={styles.header}>
            <>
              <Typography type="title2">
                {deviceTypeForUI || "Device"} Information
              </Typography>
              <HStack spacing={16}>
                <Button onPress={() => shouldBeDone()}>Done</Button>
              </HStack>
            </>
          </PanelHeader>
          <PanelBody>
            <VStack spacing={16}>
              <VStack spacing={16}>
                {/* Sort by the id of the device so that we maintain the order, otherwise 
                the edited device would shift to the bottom and it looked confusing */}
                {devices?.length &&
                  devices
                    .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                    // Even though we can see the deleted devices, we don't want to edit them.
                    .filter((device) => device.deleted_at === null)
                    .map((device, index) => {
                      return deviceToEdit?.id === device.id && showAddButton ? (
                        <Formik
                          initialValues={initialValuesForEdit}
                          onSubmit={editDevice}
                          validationSchema={validationSchema}
                          key={device?.sf_device_sku}
                        >
                          {({ values }) => (
                            <Form>
                              <VStack spacing={16}>
                                <HStack justify="space-between">
                                  <Typography type="title3">
                                    {deviceTypeForUI}{" "}
                                    {(index + 1).toLocaleString()} Type
                                  </Typography>
                                  {getPriceToShow(device?.sf_product_id)}
                                </HStack>
                                <View style={styles.deviceWrapper}>
                                  <VStack
                                    spacing={16}
                                    style={styles.leftSelect}
                                  >
                                    <Typography type="title4">
                                      Choose {deviceTypeForUI} Type
                                    </Typography>
                                    <FormikSelectField
                                      label="Device Selection"
                                      name="selectedDevice"
                                      getOptionValue={({ Id }) => Id}
                                      getOptionLabel={({ Name }) => Name}
                                      {...selectQueryPropsSalesforceDevices}
                                    />
                                  </VStack>
                                  <VStack
                                    spacing={16}
                                    style={styles.rightSelect}
                                  >
                                    <Typography type="title4">
                                      Unit Amount
                                    </Typography>
                                    <FormikNumberInputField
                                      label="Unit Amount"
                                      name="unitAmount"
                                      decimal={false}
                                    />
                                  </VStack>
                                </View>
                                <FormikTextareaField
                                  label="Additional Notes"
                                  name="additionalNotes"
                                  EndAdornment={() => {
                                    return (
                                      <CharacterCount
                                        currentCount={
                                          values?.additionalNotes?.length
                                        }
                                        maxCount={
                                          PROJECT_ASSESSMENT_REPORT_INPUT_TEXT_CHAR_LIMIT
                                        }
                                      />
                                    );
                                  }}
                                />
                                <View style={styles.buttonFlexEnd}>
                                  <Button
                                    variation="plain"
                                    style={styles.buttonMargin}
                                    onPress={() => setDeviceToEdit(null)}
                                  >
                                    Cancel
                                  </Button>
                                  <FormikSubmit
                                    iconLeft={CheckSolid}
                                    label="Update Current Device"
                                    submittingLabel="Saving..."
                                  />
                                </View>
                              </VStack>
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        <VStack spacing={16} key={device.id + index}>
                          <HStack justify="space-between">
                            <Typography type="title3">
                              {deviceTypeForUI} {index + 1} Type
                            </Typography>
                            {getPriceToShow(device?.sf_product_id)}
                          </HStack>

                          <View style={styles.deviceWrapper}>
                            <VStack spacing={16} style={styles.leftSelect}>
                              <Typography type="title4">
                                Choose {deviceTypeForUI} Type
                              </Typography>

                              <StaticTextField
                                label="Device"
                                value={device?.sf_device_sku}
                                disabled
                                onPress={() => null}
                              />
                            </VStack>
                            <VStack spacing={16} style={styles.rightSelect}>
                              <Typography type="title4">Unit Amount</Typography>
                              <StaticTextField
                                label="Unit Amount"
                                value={
                                  device?.quantity
                                    ? device?.quantity?.toLocaleString()
                                    : EM_DASH
                                }
                                disabled
                                onPress={() => null}
                              />
                            </VStack>
                          </View>
                          <TextareaField
                            label="Additional Notes"
                            disabled
                            textInputProps={{
                              value: devices[index].notes,
                            }}
                          />
                          <VStack style={styles.buttonFlexEnd}>
                            <HStack spacing={8} style={styles.centerAlign}>
                              {device.is_accepted ? (
                                <View>
                                  <HStack
                                    spacing={8}
                                    style={styles.centerAlign}
                                  >
                                    <Typography type="caption">
                                      Previously Accepted At:{" "}
                                      {formatDate({
                                        date: device.accepted_at,
                                      })}
                                    </Typography>
                                    <Tooltip
                                      title='This device has already been accepted by the client. Editing the device will reset the acceptance and this SOW will be set to a "draft" status.'
                                      name="deviceAccepted"
                                    >
                                      <View>
                                        <InformationSolid
                                          color={colors.warning}
                                        />
                                      </View>
                                    </Tooltip>
                                  </HStack>
                                </View>
                              ) : null}
                              {!showAddButton ? (
                                <Tooltip
                                  title="Finish saving the new device before editing or removing an existing device."
                                  name="finishEditing"
                                >
                                  <View>
                                    <InformationSolid />
                                  </View>
                                </Tooltip>
                              ) : null}
                              <Button
                                variation="outlined"
                                onPress={() => {
                                  setDeviceToEdit(device);
                                }}
                                iconLeft={Pencil}
                                disabled={!showAddButton}
                              >
                                Edit
                              </Button>
                              <Button
                                variation="outlined"
                                onPress={() => removeDevice(index)}
                                iconLeft={
                                  deletingDevice && deletingIndex === index
                                    ? (ActivityIndicator as ComponentType<
                                        React.PropsWithChildren<IconProps>
                                      >)
                                    : Minus
                                }
                                disabled={!showAddButton}
                              >
                                {deletingDevice && deletingIndex === index
                                  ? "Deleting"
                                  : "Remove"}
                              </Button>
                            </HStack>
                          </VStack>
                          <HStack style={styles.buttonFlexEnd}>
                            {showAddButton && index === devices.length - 1 ? (
                              <Button
                                iconLeft={PlusSolid}
                                onPress={() => {
                                  setDeviceToEdit(null);
                                  setShowAddButton(false);
                                }}
                              >
                                Add Another Product
                              </Button>
                            ) : null}
                          </HStack>
                        </VStack>
                      );
                    })}
              </VStack>
              {isLoading || !data?.id ? <ActivityIndicator /> : null}
              {devices.length === 0 || !showAddButton ? (
                <VStack spacing={16}>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={addDevice}
                    validationSchema={validationSchema}
                  >
                    {({ values }) => (
                      <Form>
                        <VStack spacing={16}>
                          <Typography type="title3">
                            {deviceTypeForUI}{" "}
                            {(devices?.length + 1).toLocaleString()} Type
                          </Typography>
                          <View style={styles.deviceWrapper}>
                            <VStack spacing={16} style={styles.leftSelect}>
                              <Typography type="title4">
                                Choose {deviceTypeForUI} Type
                              </Typography>
                              <FormikSelectField
                                label="Product Selection"
                                name="selectedDevice"
                                getOptionValue={({ Id }) => Id}
                                getOptionLabel={({ Name }) => Name}
                                {...selectQueryPropsSalesforceDevices}
                              />
                            </VStack>
                            <VStack spacing={16} style={styles.rightSelect}>
                              <Typography type="title4">Unit Amount</Typography>
                              <FormikNumberInputField
                                label="Unit Amount"
                                name="unitAmount"
                                decimal={false}
                              />
                            </VStack>
                          </View>
                          <FormikTextareaField
                            label="Additional Notes"
                            name="additionalNotes"
                            EndAdornment={() => {
                              return (
                                <CharacterCount
                                  currentCount={values.additionalNotes.length}
                                  maxCount={
                                    PROJECT_ASSESSMENT_REPORT_INPUT_TEXT_CHAR_LIMIT
                                  }
                                />
                              );
                            }}
                          />
                          <View style={styles.buttonFlexEnd}>
                            {devices?.length > 1 ? (
                              <Button
                                variation="plain"
                                onPress={() => setShowAddButton(true)}
                                style={styles.buttonMargin}
                              >
                                Cancel
                              </Button>
                            ) : null}
                            <FormikSubmit
                              iconLeft={PlusSolid}
                              label="Save Current Product"
                              submittingLabel="Saving..."
                            />
                          </View>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </VStack>
              ) : null}
            </VStack>
          </PanelBody>
        </Panel>
      </View>
      <ConfirmDialog
        title="Are you sure?"
        description="You're in the process of adding a device, and you'll lose any unsaved progress. Are you sure you want to continue?"
        visible={visible}
        onClose={onClose}
        onConfirm={() => history.push(`/scope-of-work/${data?.id}`)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    justifyContent: "space-between",
  },
  flexRow: { flexDirection: "row", flex: 1 },
  leftContent: { flexDirection: "column", flex: 5 },
  deviceWrapper: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  leftSelect: { flex: 1, paddingRight: 8 },
  rightSelect: { flex: 1, paddingLeft: 8 },
  buttonFlexEnd: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonMargin: { marginRight: 16 },
  centerAlign: {
    alignItems: "center",
  },
});
