import { UnitStatus } from "@/types";

export const YesNoOptions = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

export const UnitStatusOptions = [
  { label: UnitStatus.CommonArea, value: UnitStatus.CommonArea },
  { label: UnitStatus.Down, value: UnitStatus.Down },
  { label: UnitStatus.Model, value: UnitStatus.Model },
  { label: UnitStatus.Occupied, value: UnitStatus.Occupied },
  { label: UnitStatus.Sold, value: UnitStatus.Sold },
  { label: UnitStatus.Vacant, value: UnitStatus.Vacant },
];

export const HubConnectionOptions = [
  {
    label: "Wifi",
    value: "wifi",
  },
  {
    label: "Ethernet",
    value: "ethernet",
  },
  {
    label: "Mobile",
    value: "mobile",
  },
];
