import moment from "moment";
import { last } from "lodash-es";
import { Line } from "react-chartjs-2";

import { useUser } from "@/layout/Context";

import Graph from "@/common/Graph";
import helpers from "@/lib/helpers";

import { GenericObject } from "@/types";

export default function DistinctDeletedHubs() {
  const { permissions } = useUser();
  if (!permissions.manage_system_health) {
    return null;
  }

  return (
    <Graph
      path="/reports/distinct-deleted-hubs-by-month"
      dataKey="hubs"
      renderProps={(hubs: GenericObject[]) => {
        return (
          <Line
            data={{
              labels: hubs.map((r) => moment.utc(r.date).format("ddd MM/DD")),
              datasets: [
                {
                  ...helpers.lineGraphDefaults,
                  label: `Distinct Deleted Hubs (${Number(
                    last(hubs)?.count
                  ).toLocaleString()})`,
                  data: hubs.map((r) => Number(r.count)),
                  backgroundColor: helpers.lineGraphColors.red,
                  borderColor: helpers.lineGraphColors.red,
                  pointBorderColor: helpers.lineGraphColors.red,
                  pointHoverBackgroundColor: helpers.lineGraphColors.red,
                  pointHoverBorderColor: helpers.lineGraphColors.red,
                },
              ],
            }}
            height={45}
          />
        );
      }}
    />
  );
}
