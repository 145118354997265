import * as React from "react";

import { StatusBadge } from "@smartrent/ui";

interface SignStatusPillProps {
  active?: boolean;
}

export const SignStatusPill: React.FC<
  React.PropsWithChildren<SignStatusPillProps>
> = ({ active }) => {
  return (
    <StatusBadge
      style={{ width: 100 }}
      status={active ? "success" : "warning"}
      size="semibold.title.four"
    >
      {active === undefined ? "UNKNOWN" : active ? "ACTIVE" : "ARCHIVED"}
    </StatusBadge>
  );
};
