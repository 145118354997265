import { useMemo } from "react";
import * as React from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { groupBy, isEmpty } from "lodash-es";

import { Panel, PanelBody, PanelHeader, PanelHeaderTitle } from "@smartrent/ui";

import {
  TppViewProps,
  GroupProps,
  ThirdPartyProviderSummaryItem,
} from "@/types";

import { useTppViewQuery } from "@/api";

import { ProvidersListEmpty } from "@/common/third-party-providers";
import { useDrawerNav } from "@/common/AppDrawer";
import { useUser } from "@/layout/Context";

import { useOrganization } from "../OrganizationContext";

import { OrganizationIntegrationsListItem } from "./OrganizationIntegrationsListItem";
import { OrganizationLockedIntegrationsListItem } from "./OrganizationLockedIntegrationsListItem";
import { getOrganizationIntegrationActions } from "./OrganizationIntegrationsActions";

interface LockedIntegrationListItemProps {
  provider_name: string;
  group_name: string;
  group_id: string | number;
}

interface DefinedTppViewProps extends TppViewProps {
  group_id: string | number;
}

interface OrganizationIntegrationsCardProps {
  group?: Partial<GroupProps>;
}
export const OrganizationIntegrationsCard: React.FC<
  React.PropsWithChildren<OrganizationIntegrationsCardProps>
> = () => {
  const { permissions } = useUser();
  const { organization } = useOrganization();
  const drawer = useDrawerNav();

  // API CALL - fetch tpp_view records
  const { data, isLoading, isError } = useTppViewQuery({
    filters: {
      organizationId: organization.id,
      sortBy: "provider",
      orderBy: "asc",
      limit: 9999,
    },
  });

  // FLATLIST DATA - summarize the records from tpp_view
  const providers = useMemo(() => {
    if (isLoading || isError || isEmpty(data) || !data?.records?.length) {
      return [];
    }

    const nonLockedRecords = data?.records?.filter(
      (r) => r.locked === false || r.locked === undefined
    );

    // group tpp_view records by provider name
    const groupedRecords = groupBy(
      nonLockedRecords,
      ({ provider }) => provider
    ) as Record<string, Array<TppViewProps>>;

    // map the tpp_view records to props for OrganizationIntegrationsCardListItem
    const summaryRecords = Object.entries(groupedRecords).reduce<
      ThirdPartyProviderSummaryItem[]
    >((items, [key, value]: [string, Array<TppViewProps>]) => {
      if (value.length && value.length > 0) {
        const [{ provider_id }] = value;
        const enabledCount = value?.filter((v) => v.enabled === true).length;
        const disabledCount = value?.filter((v) => v.enabled === false).length;
        const nullCount = value?.filter(
          (v) => v.enabled === undefined || v.enabled === null
        ).length;
        items.push({
          provider_name: key,
          provider_id: provider_id as string,
          enabledCount,
          disabledCount,
          nullCount,
        });
      }
      return items;
    }, []);

    // sort by enabled integrations so they appear at the top
    const sortedSummaryRecords = summaryRecords.sort(
      ({ enabledCount: a }, { enabledCount: b }) => Number(b) - Number(a)
    );

    return sortedSummaryRecords;
  }, [isLoading, isError, data]);

  const lockedIntegrations: LockedIntegrationListItemProps[] = useMemo(() => {
    if (isLoading || isError || isEmpty(data) || !data?.records?.length) {
      return [];
    }

    return data.records
      .filter(
        (record): record is DefinedTppViewProps =>
          record.locked && record.group_id !== null
      )
      .map((record) => ({
        provider_name: record.provider,
        group_name: record.group,
        group_id: record.group_id,
      }));
  }, [data, isError, isLoading]);

  return (
    <Panel>
      <PanelHeader
        actions={getOrganizationIntegrationActions({
          permissions,
          drawer,
          organization,
        })}
      >
        <PanelHeaderTitle title="Organization Integrations" />
      </PanelHeader>

      <PanelBody>
        <View>
          <FlatList
            key="org-providers"
            keyExtractor={(_item, index) => `org-providers-${index}`}
            testID="org-providers-list"
            centerContent={true}
            style={styles.providerList}
            data={providers}
            renderItem={(props) => (
              <OrganizationIntegrationsListItem {...props} />
            )}
            ListEmptyComponent={ProvidersListEmpty}
          />
        </View>
      </PanelBody>

      {lockedIntegrations.length > 0 ? (
        <View>
          <PanelHeader>
            <PanelHeaderTitle title="Locked Integrations" />
          </PanelHeader>
          <PanelBody>
            <View>
              <FlatList
                key="org-providers"
                keyExtractor={(item, index) => `org-providers-${index}`}
                testID="org-providers-list"
                centerContent={true}
                style={styles.providerList}
                data={lockedIntegrations}
                renderItem={(props) => (
                  <OrganizationLockedIntegrationsListItem {...props} />
                )}
                ListEmptyComponent={ProvidersListEmpty}
              />
            </View>
          </PanelBody>
        </View>
      ) : null}
    </Panel>
  );
};

const styles = StyleSheet.create({
  providerList: {
    flexDirection: "column",
    alignContent: "center",
    width: "100%",
    paddingHorizontal: 4,
    paddingBottom: 4,
    paddingTop: 0,
    borderRadius: 0,
    borderTopColor: "transparent",
  },
});
