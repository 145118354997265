import * as React from "react";
import { StyleSheet, View, ScrollView } from "react-native";

import { useTheme } from "@smartrent/ui";

interface ContentProps {
  children: React.ReactNode;
  scrollable?: boolean;
}

export default ({ children, scrollable = true }: ContentProps) => {
  const { colors } = useTheme();

  return scrollable ? (
    <ScrollView>
      <View
        style={[
          styles.content,
          {
            borderBottomColor: colors.gray100,
          },
        ]}
      >
        {children}
      </View>
    </ScrollView>
  ) : (
    <View
      style={[
        styles.content,
        {
          borderBottomColor: colors.gray100,
        },
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: "row",
  },
});
