import * as React from "react";
import { ViewStyle } from "react-native";

import { Button } from "@smartrent/ui";

import { CardFooter } from "./CardFooter";

interface OpenCloseFooterProps {
  isOpen: boolean;
  setOpen: (newValue: boolean) => void;
  style?: ViewStyle;
}

export const OpenCloseFooter: React.FC<
  React.PropsWithChildren<OpenCloseFooterProps>
> = ({ isOpen, setOpen, style }) => (
  <CardFooter style={style}>
    <Button
      variation="plain"
      onPress={() => {
        setOpen(!isOpen);
      }}
    >
      {isOpen ? "Show Less" : "Show More"}
    </Button>
  </CardFooter>
);
