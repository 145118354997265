import { View } from "react-native";
import { startCase } from "lodash-es";
import { Typography, Table, useTableQuery } from "@smartrent/ui";

import helpers from "@/lib/helpers";
import { fetchAccessCredentials } from "@/api";
import { EM_DASH } from "@/utils/chars";

import { useUnit } from "./UnitContext";

export const AccessCredentials = () => {
  const { unit } = useUnit();

  const getQueryKey = ({ filters, page, pageSize }) => [
    "access-credentials",
    {},
    {
      ...filters,
      page,
      limit: pageSize,
      unitId: unit.id,
    },
  ];

  const tableProps = useTableQuery<any, any, any>({
    fetch: fetchAccessCredentials,
    getQueryKey: getQueryKey,
    defaultPageSize: 25,
    columns: [
      {
        name: "id",
        header: "Credential ID",
        render: ({ row }) => <Typography>{row?.id}</Typography>,
        maxWidth: 140,
      },
      {
        name: "identity_id",
        header: "Identity ID",
        render: ({ row }) => <Typography>{row?.master_identity_id}</Typography>,
        maxWidth: 140,
      },

      {
        name: "first_name",
        header: "Name",
        render: ({ row }) => (
          <Typography>{`${row?.first_name}\n${row?.last_name}`}</Typography>
        ),
        sortable: false,
      },
      {
        name: "identity_type",
        header: "Type",
        render: ({ row }) => {
          return (
            <Typography>
              {startCase(row.identity_type?.replace(/_/g, " "))}
            </Typography>
          );
        },
        sortable: false,
      },
      {
        name: "credential_type",
        header: "Credential Type",
        render: ({ row }) => (
          <Typography>{startCase(row.credential_type)}</Typography>
        ),
        sortable: false,
      },
      {
        name: "inserted_at",
        header: "Inserted At",
        render: ({ row }) => (
          <Typography>{helpers.formatDate(row.inserted_at)}</Typography>
        ),
        sortable: false,
      },
      {
        name: "updated_at",
        header: "Updated At",
        render: ({ row }) => (
          <Typography>{helpers.formatDate(row.updated_at)}</Typography>
        ),
        sortable: false,
      },
      {
        name: "deleted_at",
        header: "Deleted At",
        render: ({ row }) =>
          row.deleted_at ? (
            <Typography>{helpers.formatDate(row.deleted_at)}</Typography>
          ) : (
            <Typography>&mdash;</Typography>
          ),
        sortable: false,
      },
      {
        name: "start_end",
        header: "Start At / End At",
        render: ({ row }) => (
          <View>
            <Typography type="caption">
              Start: {helpers.formatDate(row.start_date, "L LT", EM_DASH)}
            </Typography>
            <Typography type="caption">
              End: {helpers.formatDate(row.end_date, "L LT", EM_DASH)}
            </Typography>
          </View>
        ),
      },
    ],
  });

  return (
    <Table
      title="Access Credentials"
      noRecordsText="No credentials found"
      {...tableProps}
    />
  );
};
