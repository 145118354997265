import { View, StyleSheet } from "react-native";
import { startCase } from "lodash-es";
import { TrashX } from "@smartrent/icons";
import { Box, StatusBadge, Typography, useTheme } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { useUser } from "@/layout/Context";
import { EM_DASH } from "@/utils/chars";

import { useUnit } from "./UnitContext";

export const DeletedAccessControlDevices = () => {
  const { permissions } = useUser();
  const { colors } = useTheme();
  const { deletedAccessControlDevices } = useUnit();

  const elements = deletedAccessControlDevices.map((device) => {
    return (
      <View
        key={device.id}
        style={[styles.cardOutline, { borderColor: colors.error }]}
      >
        <Box style={styles.boxStyle}>
          <View style={styles.verticalMargin}>
            <View style={styles.flexRow}>
              <TrashX size={18} color={colors.error} />
              <Typography type="title5" color="error">
                {`Deleted ${device.internal_name}`}
              </Typography>
            </View>
            <View>
              <Typography type="title4">
                {`${startCase(device.make)} ${startCase(device.device_type)}`}
              </Typography>
            </View>
          </View>

          <View
            style={[styles.viewBorderTop, { borderTopColor: colors.gray200 }]}
          >
            <Typography
              style={styles.providerMarginTop}
              type="caption"
              color="textSecondary"
            >
              Provider: {device.provider_name}
            </Typography>
            <View style={styles.verticalMargin}>
              <Typography type="title5" color="textSecondary">
                {`Legacy install on ${Helpers.formatDate(
                  device.inserted_at,
                  "L LT"
                )}`}
              </Typography>
              <Typography type="title5" color="textSecondary">
                Deleted on {Helpers.formatDate(device.deleted_at, "L LT")}
              </Typography>
              <View style={styles.verticalMargin}>
                <StatusBadge status="default" size="semibold.title.four">
                  Non-Z-Wave Device
                </StatusBadge>
              </View>
            </View>
            {permissions.view_technical_details ? (
              <View>
                <Typography type="caption" color="textSecondary">
                  (Access Control Device ID: {device.id}, Remote ID:{" "}
                  {device.remote_id || EM_DASH})
                </Typography>
              </View>
            ) : null}
          </View>
        </Box>
      </View>
    );
  });

  return <>{elements}</>;
};

const styles = StyleSheet.create({
  cardOutline: {
    margin: 4,
    width: 480,
    borderWidth: 1,
    borderRadius: 2,
  },
  providerMarginTop: { marginTop: 8 },
  flexRow: { flexDirection: "row" },
  boxStyle: { height: "100%" },
  viewBorderTop: {
    borderTopWidth: 1,
    padding: 4,
  },
  verticalMargin: { marginVertical: 8 },
});
