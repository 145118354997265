import { isEmpty } from "lodash-es";
import { useEffect, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useQueryStringField } from "@smartrent/ui";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

import {
  HubRefurbishFormikFieldNames,
  HubRefurbishReactQueryKeys,
  RefurbishHub,
} from "@/types/HubRefurbishProps";

export const useSerialsQueryStringFieldHook = () => {
  const queryClient = useQueryClient();

  const serialsQueryStringField = useQueryStringField(
    HubRefurbishFormikFieldNames.Serials
  );

  const { setHubsDict } = useHubRefurbishContext();

  const serials = useMemo(
    () =>
      serialsQueryStringField && typeof serialsQueryStringField === "string"
        ? serialsQueryStringField.split(",")
        : [],
    [serialsQueryStringField]
  );

  const initHubsDict = useMemo(() => {
    if (!serials?.length) {
      return {};
    }
    return Object.fromEntries(
      serials.map((serial) => [
        serial,
        {
          serial,
          isDisabled: /disabled_/gi.test(serial),
        } as RefurbishHub,
      ])
    );
  }, [serials]);

  useEffect(() => {
    // clear out any cached data if url has no serials
    if (isEmpty(initHubsDict)) {
      setHubsDict({});
      queryClient.removeQueries([HubRefurbishReactQueryKeys.RedisStats]);
      queryClient.removeQueries([HubRefurbishReactQueryKeys.HubRefurbishJob]);
    } else {
      // otherwise set the hubsDict to what we have saved in the URL
      setHubsDict(initHubsDict);
    }
  }, [initHubsDict, queryClient, setHubsDict]);

  const formikFormattedSerials = useMemo(
    () =>
      serials
        .map((s) => s.replaceAll(/disabled_/gi, "").toUpperCase())
        .join("\n"),
    [serials]
  );

  const initialValues = useMemo(
    () => ({ serials: formikFormattedSerials }),
    [formikFormattedSerials]
  );

  return {
    initialValues,
  };
};
