import "./Loader.css";

export const Loader = ({ color = "#00ADA0", size = 32, width = 10 }) => (
  <div
    style={{
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    }}
  >
    <div className="lds-ring">
      {new Array(4).fill({}).map((value, index) => (
        <div
          key={index}
          style={{
            borderWidth: width,
            borderStyle: "solid",
            borderColor: `${color} transparent transparent transparent`,
            height: size,
            width: size,
          }}
        ></div>
      ))}
    </div>
  </div>
);

export const SectionLoader = () => (
  <div
    style={{
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      width: "100%",
      background: "rgba(0, 0, 0, 0.12)",
    }}
  >
    <Loader size={100} width={10} />
  </div>
);

export const FullScreenLoader = () => (
  <div
    style={{
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      background: "rgba(0, 0, 0, 0.12)",
      zIndex: 20,
    }}
  >
    <div style={{ margin: "-150px 0 0 -80px" }}>
      <Loader size={100} width={10} />
    </div>
  </div>
);
