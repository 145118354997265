import { useContext, useState, useEffect } from "react";
import * as React from "react";
import { Box } from "@smartrent/ui";
import { Refresh } from "@smartrent/icons";
import moment from "moment";

import Skeleton from "react-loading-skeleton";

import Context from "@/layout/Context";
import { useApi, useInterval } from "@/lib/hooks";

type $Props = {
  refetchInterval?: number;
  path?: string;
  dataKey?: string;
  renderProps: (data: any) => React.ReactNode;
  defaultValue?: any;
  leftFooterRenderProps?: (data: any) => React.ReactNode;
};

export default function Graph({
  refetchInterval,
  path,
  dataKey,
  renderProps,
  defaultValue,
  leftFooterRenderProps,
}: $Props) {
  const { location } = useContext(Context);
  const [data, setData] = useState(defaultValue || null);
  const { response, loading, reFetch } = useApi({
    url: `${path}${location.search ? location.search : ""}`,
    trigger: [location.search],
  });

  useEffect(() => {
    if (response && response.data) {
      if (dataKey && response.data[dataKey]) {
        setData(response.data[dataKey]);
      } else {
        setData(response.data);
      }
    }
  }, [response, dataKey]);

  useInterval(() => {
    if (refetchInterval && reFetch) {
      reFetch();
    }
  }, refetchInterval);

  return (
    <Box style={{ margin: 8 }}>
      {loading && !data && <Skeleton width="100%" />}
      {data && renderProps(data)}

      <div
        style={{ borderTop: "1px solid #eee" }}
        className="u-flex-wrap u-flex u-flex-justify-between u-ptop4 u-font10 u-text-gray-400"
      >
        {data && (
          <div>{leftFooterRenderProps && leftFooterRenderProps(data)}</div>
        )}
        <div
          className="cursor--pointer u-flex-wrap u-flex u-flex-justify-end u-flex-align-items-start"
          onClick={() => reFetch()}
        >
          <div className="u-mright4">
            Last refresh: {moment().format("hh:mm:ss A")}
          </div>
          <div onClick={() => reFetch()}>
            <Refresh size={12} color="#333" />
          </div>
        </div>
      </div>
    </Box>
  );
}
