import { useMemo } from "react";
import * as React from "react";

import { LegacyListItem, Typography } from "@smartrent/ui";

import { ParkingSensorEvent } from "@/types";

export interface SensorActivityItemProps {
  event: ParkingSensorEvent;
}

const SensorActivityItem: React.FC<
  React.PropsWithChildren<SensorActivityItemProps>
> = ({ event }) => {
  const label = useMemo(() => {
    if (event.battery_level) {
      return "Battery Level";
    }

    if (event.temperature) {
      return "Temperature";
    }

    return "Vehicle Presence";
  }, [event]);

  const value = useMemo(() => {
    if (event.battery_level) {
      return `${event.battery_level} V`;
    }

    if (event.temperature) {
      return `${event.temperature} C\u00B0`;
    }

    return event.vehicle_present ? "Occupied" : "Vacant";
  }, [event]);

  return (
    <LegacyListItem
      item={{
        label: label,
        value: new Date(event.inserted_at).toLocaleString(),
      }}
      size="normal"
    >
      <Typography>{value}</Typography>
    </LegacyListItem>
  );
};

export default SensorActivityItem;
