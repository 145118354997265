import { useContext } from "react";
import { map, compact, last } from "lodash-es";
import moment from "moment";
import { Line } from "react-chartjs-2";

import Graph from "@/common/Graph";
import Context from "@/layout/Context";
import Helpers from "@/lib/helpers";

export default function FirstUnitInstalls() {
  const { isDesktop } = useContext(Context);
  if (!isDesktop) {
    return null;
  }
  return (
    <Graph
      path="/reports/installs/first-per-unit"
      dataKey="unitInstalls"
      renderProps={(raw) => {
        const weekdays = compact(
          map(raw, (r) => {
            if (
              ["Saturday", "Sunday"].includes(moment.utc(r.date).format("dddd"))
            ) {
              return null;
            } // strip out data from weekends
            return r;
          })
        );
        return (
          <Line
            data={{
              labels: weekdays.map((r) =>
                moment.utc(r.date).format("ddd MM/DD")
              ),
              datasets: [
                {
                  ...Helpers.lineGraphDefaults,
                  label: `New Smart Units (${Number(
                    last(weekdays)?.count ?? 0
                  ).toLocaleString()})`,
                  data: weekdays.map((r) => Number(r.count)),
                  backgroundColor: Helpers.lineGraphColors.green,
                  borderColor: Helpers.lineGraphColors.green,
                  pointBorderColor: Helpers.lineGraphColors.green,
                  pointHoverBackgroundColor: Helpers.lineGraphColors.green,
                  pointHoverBorderColor: Helpers.lineGraphColors.green,
                },
              ],
            }}
            height={45}
          />
        );
      }}
    />
  );
}
