import { instance } from "@/lib/hooks";
import {
  HubRefurbishBatchJobQueryResponse,
  RunHubRefurbishBatchJobResponse,
} from "@/types/HubRefurbishProps";

export const runHubRefurbishBatchJobMutateFn = async (variables: {
  serials: string[];
}) =>
  await instance.post<RunHubRefurbishBatchJobResponse>(`/hub-refurbish/batch`, {
    ...variables,
  });

export const getHubRefurbishBatchJobQueryFn = async ({ flowId }) =>
  await instance.get<HubRefurbishBatchJobQueryResponse>(
    `/hub-refurbish/batch/${flowId}`
  );
