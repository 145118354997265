import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import {
  ActionSheetProvider,
  PlatformProvider,
  ThemeProvider,
} from "@smartrent/ui";

import { QueryClientProvider } from "@/api/query-client";
import { DialogProvider } from "@/lib/contexts/dialog";

import { Router } from "./Router";
import "./main.css";

import "@/lib/sentry";
import "@/utils/favicon";

const root = createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider initialMode="light">
    <QueryClientProvider>
      {import.meta.env.REACT_APP_REACT_QUERY_DEV_TOOLS ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
      <PlatformProvider>
        <BrowserRouter>
          <DialogProvider>
            <ActionSheetProvider>
              <Router />
            </ActionSheetProvider>
          </DialogProvider>
        </BrowserRouter>
      </PlatformProvider>
    </QueryClientProvider>
  </ThemeProvider>
);
