import { useContext } from "react";
import classNames from "classnames";

import Context, { useUser } from "@/layout/Context";
import Graph from "@/common/Graph";

const accessCodeStyles = {
  minWidth: "125px",
  marginBottom: "8px",
  marginRight: "8px",
};

export default function AccessCodeSync() {
  const { isDesktop } = useContext(Context);
  const { permissions } = useUser();
  if (!permissions.manage_system_health) {
    return null;
  }

  return (
    <Graph
      refetchInterval={60000}
      path="/reports/access-codes/add-remove-stats"
      dataKey="addRemoveStats"
      leftFooterRenderProps={() => <div>Access Codes (last 15m)</div>}
      renderProps={({
        timeUntilAddInSeconds,
        timeUntilRemoveInSeconds,
        recentlyAddedCount,
        recentlyRemovedCount,
        pendingRemoval,
        pendingAdd,
      }) => (
        <div
          className={classNames({
            "u-flex u-flex-wrap u-text-center": isDesktop,
          })}
        >
          <div
            className="u-mright4 u-flex-grow1 u-flex-shrink1"
            style={accessCodeStyles}
          >
            <div className="u-text-gray-600 u-font12">Avg Seconds To Add</div>
            <div
              className={classNames({
                "u-font24": true,
                "u-text-symbolic-success": timeUntilAddInSeconds <= 70,
                "u-text-symbolic-warning":
                  timeUntilAddInSeconds > 70 && timeUntilAddInSeconds < 100,
                "u-text-symbolic-error": timeUntilAddInSeconds > 100,
              })}
            >
              {timeUntilAddInSeconds}
            </div>
          </div>
          <div
            className="u-mright4 u-flex-grow1 u-flex-shrink1"
            style={accessCodeStyles}
          >
            <div className="u-text-gray-600 u-font12">
              Avg Seconds To Remove
            </div>
            <div
              className={classNames({
                "u-font24": true,
                "u-text-symbolic-success": timeUntilRemoveInSeconds <= 120,
                "u-text-symbolic-warning":
                  timeUntilRemoveInSeconds > 120 &&
                  timeUntilRemoveInSeconds < 150,
                "u-text-symbolic-error": timeUntilRemoveInSeconds > 150,
              })}
            >
              {timeUntilRemoveInSeconds}
            </div>
          </div>
          <div
            className="u-mright4 u-flex-grow1 u-flex-shrink1"
            style={accessCodeStyles}
          >
            <div className="u-text-gray-600 u-font12">Pending Add</div>
            <div
              className={classNames({
                "u-font24": true,
                "u-text-symbolic-success": pendingAdd <= 200,
                "u-text-symbolic-warning": pendingAdd > 200 && pendingAdd < 300,
                "u-text-symbolic-error": pendingAdd > 300,
              })}
            >
              {Number(pendingAdd).toLocaleString()}
            </div>
          </div>
          <div
            className="u-mright4 u-flex-grow1 u-flex-shrink1"
            style={accessCodeStyles}
          >
            <div className="u-text-gray-600 u-font12">Pending Removal</div>
            <div
              className={classNames({
                "u-font24": true,
                "u-text-symbolic-success": pendingRemoval <= 200,
                "u-text-symbolic-warning":
                  pendingRemoval > 200 && pendingRemoval < 300,
                "u-text-symbolic-error": pendingRemoval > 300,
              })}
            >
              {Number(pendingRemoval).toLocaleString()}
            </div>
          </div>
          <div
            className="u-mright4 u-flex-grow1 u-flex-shrink1"
            style={accessCodeStyles}
          >
            <div className="u-text-gray-600 u-font12"># Recently Added</div>
            <div className="u-font24">
              {Number(recentlyAddedCount).toLocaleString()}
            </div>
          </div>
          <div
            className="u-mright4 u-flex-grow1 u-flex-shrink1"
            style={accessCodeStyles}
          >
            <div className="u-text-gray-600 u-font12"># Recently Removed</div>
            <div className="u-font24">
              {Number(recentlyRemovedCount).toLocaleString()}
            </div>
          </div>
        </div>
      )}
    />
  );
}
