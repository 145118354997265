import { View, StyleSheet } from "react-native";

import { Tooltip, Typography, useTheme } from "@smartrent/ui";
import { QuestionCircleBorder } from "@smartrent/icons";

import { useIsDesktop } from "@/lib/hooks";
import { NumberMetricWithLabel } from "@/common/NumberMetricWithLabel";
import Box from "@/common/Box";

interface NumberMetricProps {
  dataKey: string;
  refetchInterval?: number;
  path?: string;
  label?: string;
  defaultValue?: any;
  tooltip?: { title: string; name: string };
  canBeRefreshed?: boolean;
}

interface MetricCardProps {
  title: string;
  tooltip?: { name: string; title: string };
  metricsToShow: NumberMetricProps[];
}

export const MetricCard = ({
  title,
  tooltip,
  metricsToShow,
}: MetricCardProps) => {
  const { colors } = useTheme();
  const isDesktop = useIsDesktop();

  return (
    <Box style={styles.box}>
      <View
        style={[
          styles.headingWrapper,
          { backgroundColor: colors.pageBackground },
        ]}
      >
        <Typography type="title4">{title}</Typography>
        <View>
          {tooltip ? (
            <Tooltip title={tooltip.title} name={tooltip.name}>
              <View>
                <QuestionCircleBorder />
              </View>
            </Tooltip>
          ) : null}
        </View>
      </View>
      <View style={isDesktop ? styles.desktop : styles.mobile}>
        {metricsToShow.map(
          (
            { refetchInterval, path, dataKey, label, tooltip, canBeRefreshed },
            index
          ) => {
            return (
              <NumberMetricWithLabel
                key={index}
                refetchInterval={refetchInterval}
                path={path}
                dataKey={dataKey}
                label={label}
                tooltip={tooltip}
                canBeRefreshed={canBeRefreshed}
              />
            );
          }
        )}
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  box: { margin: 8 },
  headingWrapper: {
    flexDirection: "row",
    height: 56,
    alignItems: "center",
    padding: 16,
    justifyContent: "space-between",
  },
  desktop: { flexDirection: "row" },
  mobile: { flexDirection: "column" },
});
