import { useContext, useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";
import {
  ConfirmDialog,
  Panel,
  PanelBody,
  PanelHeader,
  Typography,
  useToast,
  VStack,
} from "@smartrent/ui";
import { useModalState, useWindowDimensionsQuery } from "@smartrent/hooks";

import Context, { useUser } from "@/layout/Context";

import HubDebug from "@/pages/unit/hub/HubDebug";

import { useConnectToCommunityWifiMutation } from "@/api";

import { Automations } from "./hub/Automations";
import DeletedHubs from "./hub/DeletedHubs";
import { HubTroubleshooting } from "./hub/HubTroubleshooting";
import HubInfo from "./hub/HubInfo";
import { HubHeader } from "./hub/HubHeader";
import { HubConnectionHistory } from "./hub/HubConnectionHistory";
import { getHubActions, ConfirmationModalProps } from "./hub/HubActions";
import { useUnit } from "./UnitContext";
import { HubCommunityWifiInfo } from "./hub/HubCommunityWifiInfo";

export const Hub: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { large } = useWindowDimensionsQuery();

  const context = useContext(Context);
  const { permissions } = useUser();
  const {
    hub,
    hubConnectionHistory,
    deletedHubs,
    unit,
    communityWifi,
    devices,
  } = useUnit();
  const { visible, onOpen, onClose } = useModalState();
  const setToast = useToast();
  const [confirmationModalProps, setConfirmationModalProps] =
    React.useState<ConfirmationModalProps>();

  const openConfirmationModal = React.useCallback(
    (confirmationProps: ConfirmationModalProps) => {
      setConfirmationModalProps({
        ...confirmationProps,
        onConfirm: () => {
          confirmationProps.onConfirm();
          onClose();
        },
      });
      onOpen();
    },
    [onClose, onOpen]
  );

  const [connectToCommunityWifi] = useConnectToCommunityWifiMutation({
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries(["check-units-hub-community-wifi-status"]); // trigger refetch
      communityWifi.setRefetchCheckWifiAttemptIndex(0); // reset refetch counter to 0, since it only tries up to certain amount of times
      setToast({
        message:
          "This process may take a few minutes to complete, use hub logs or try refreshing the page to see connection status.",
        status: "success",
      });
    },
    onError: (queryClient) => {
      queryClient.invalidateQueries(["check-units-hub-community-wifi-status"]);
      setToast({
        message:
          "There was an error sending this request, please contact engineering if this issue persists.",
        status: "error",
      });
    },
  });

  const actions = useMemo(() => {
    const hubActions = getHubActions({
      context,
      hub,
      permissions,
      unit,
      openConfirmationModal,
    });

    // Easier to add actions that use reactQuery & ToastProvider here, legacy actions don't use those
    if (hub && permissions.troubleshoot && communityWifi.isEnabled) {
      hubActions.push({
        label: "Reconnect to WiFi Device Network",
        onPress: () =>
          openConfirmationModal({
            title: "Are you sure?",
            description:
              "When clicked, the hub's SSID / password will be updated to match the Group's WiFi Device Network configuration.  Please confirm the hub is online and connected to a stable power source.",
            confirmText: "Continue",
            onConfirm: () => {
              connectToCommunityWifi({ unitId: unit.id });
            },
          }),
      });
    }

    return hubActions;
  }, [
    context,
    hub,
    permissions,
    unit,
    communityWifi,
    connectToCommunityWifi,
    openConfirmationModal,
  ]);

  return (
    <>
      <Panel style={large ? styles.large : undefined}>
        <PanelHeader actions={actions?.length ? actions : null}>
          {hub ? (
            <HubHeader hub={hub} />
          ) : (
            <Typography type="title5">No hub found</Typography>
          )}
        </PanelHeader>
        <PanelBody>
          <VStack spacing={8}>
            <HubInfo hub={hub} />
            <HubCommunityWifiInfo hub={hub} />
            <HubConnectionHistory
              hub_connection_history={hubConnectionHistory}
            />
            <Automations hub={hub} permissions={permissions} />
            <HubTroubleshooting hub={hub} permissions={permissions} />
            <DeletedHubs deleted_hubs={deletedHubs} />
            <HubDebug hub={hub} permissions={permissions} devices={devices} />
          </VStack>
        </PanelBody>
      </Panel>

      <ConfirmDialog
        title={confirmationModalProps?.title}
        description={confirmationModalProps?.description}
        visible={visible}
        onConfirm={confirmationModalProps?.onConfirm}
        onClose={onClose}
      />
    </>
  );
};

const styles = StyleSheet.create({
  large: {
    flexGrow: 1,
    maxWidth: 720,
  },
});
