import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ExclamationCircleOutline } from "@smartrent/icons";
import { useStatusBanner } from "@smartrent/ui";

import {
  getHubRefurbishBatchJobQueryFn,
  runHubRefurbishBatchJobMutateFn,
} from "@/api/hub-refurbish";

import { useStepNavigationHook } from "@/pages/hub-refurbish/hooks/useStepNavigationHook";
import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

import {
  HubRefurbishBatchJobQueryResponse,
  HubRefurbishReactQueryKeys,
  RunHubRefurbishBatchJobResponse,
} from "@/types/HubRefurbishProps";

import { EM_DASH } from "@/utils/chars";

export const HUB_REFURBISH_JOB_REFETCH_INTERVAL = 1500;

export const useApiHubRefurbishJobHook = () => {
  const queryClient = useQueryClient();

  const [refetchInterval, setRefetchInterval] = useState<number>(
    HUB_REFURBISH_JOB_REFETCH_INTERVAL
  );
  const { addStatusBanner, clearStatusBanner } = useStatusBanner();

  const {
    flowId,
    setFlowId,
    flowHubsCount,
    setFlowHubsCount,
    hubsDict,
    setIsCriticalError,
    setHubsDict,
  } = useHubRefurbishContext();

  const runHubRefurbishBatchJobQuery = useQuery<
    AxiosResponse<HubRefurbishBatchJobQueryResponse>,
    AxiosError,
    AxiosResponse<HubRefurbishBatchJobQueryResponse>
  >(
    [HubRefurbishReactQueryKeys.HubRefurbishJob, { flowId }],
    () => getHubRefurbishBatchJobQueryFn({ flowId }),
    {
      enabled: !!flowId, // only enable when flowId exists
      refetchInterval: refetchInterval ? refetchInterval : false, // only refetch when set to a number
      retry: 1,
      onError: () => setRefetchInterval(0), // stop auto refetch when error exists
      onSuccess: ({ data: { jobsDict, totalCompletedJobs } }) => {
        const mergedJobsRecords = Object.entries(jobsDict || {}).reduce(
          (hubsDictCopy, [serial, jobData]) =>
            // only merge if serial exists in the current context's dictionary
            hubsDictCopy[serial]
              ? {
                  ...hubsDictCopy,
                  [serial]: { ...hubsDictCopy[serial], ...jobData },
                }
              : hubsDictCopy,
          hubsDict
        );

        setHubsDict(mergedJobsRecords);

        // turn off refetch when all the jobs are complete
        if (
          refetchInterval > 0 &&
          flowHubsCount > 0 &&
          flowHubsCount === totalCompletedJobs
        ) {
          setRefetchInterval(0);
        }
      },
    }
  );

  const runHubRefurbishBatchJobMutation = useMutation<
    AxiosResponse<RunHubRefurbishBatchJobResponse>,
    AxiosError,
    { serials: string[] }
  >(
    [HubRefurbishReactQueryKeys.HubRefurbishJobMutation],
    runHubRefurbishBatchJobMutateFn,
    {
      onSuccess: (result, variables, context) => {
        // bullmq generates an uuid anytime we kick off a new 'flow'
        setFlowId(result.data.job.id);

        // the children represent all the workers created to process each hub
        setFlowHubsCount(result.data.job.data.totalChildren);

        // set this in the case that we're retrying using debug button
        setRefetchInterval(HUB_REFURBISH_JOB_REFETCH_INTERVAL);
      },
      onError: (error, variables, context) => {
        // delete what's in the cache
        queryClient.cancelQueries([HubRefurbishReactQueryKeys.HubRefurbishJob]);

        // set critical error flag so we disable UI buttons
        setIsCriticalError(true);

        // display status banner so user knows what to do
        addRestartBatchStatusBanner();
      },
    }
  );

  // restart batch by navigating to step 1
  const { goToStepPage: goToStepOne } = useStepNavigationHook(1);

  // display banner to let user know something went wrong & give action to restart
  const addRestartBatchStatusBanner = useCallback(
    () =>
      addStatusBanner({
        title: `Critical Error ${EM_DASH} Please try again or contact engineering if this issue persists`,
        numberOfLines: 1,
        icon: ExclamationCircleOutline,
        status: "error",
        actions: [
          {
            label: "RESTART BATCH",
            onPress: () => {
              setTimeout(() => clearStatusBanner(), 1500);
              goToStepOne();
            },
          },
        ],
      }),
    [addStatusBanner, clearStatusBanner, goToStepOne]
  );

  return {
    runHubRefurbishBatchJobMutation,
    runHubRefurbishBatchJobQuery,
  };
};
