import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isNil, omitBy, pick } from "lodash-es";
import { parse, stringify } from "querystring";

import { useQueryString } from "./query-string";
import { useDebounce } from "./debounce";

const stringFromQs = (value: string | string[] | undefined) => {
  if (Array.isArray(value)) {
    return value[0] ?? "";
  }

  return typeof value === "string" ? value : "";
};

export interface UseUrlTextInputFilterOptions {
  resetPaginationOnChange?: boolean;
  debounceInterval?: number;
}

/**
 * For use with TextInputField from @smartrent/ui. Pass the return value of this
 * hook as prop to TextInputField and it will handle updating the URL.
 */
export const useUrlTextInputFilter = (
  filterKey: string,
  {
    debounceInterval,
    resetPaginationOnChange = true,
  }: UseUrlTextInputFilterOptions = {}
) => {
  const history = useHistory();
  const location = useLocation();
  const qs = useQueryString();
  const filterValue = stringFromQs(qs[filterKey]);

  const [inputValue, setInputValue] = useState(filterValue);
  const debouncedInputValue = useDebounce(inputValue, debounceInterval);

  // clear the field if the query string value is cleared
  useEffect(() => {
    return history.listen((location, action) => {
      const newQueryString = parse(location.search.replace("?", ""));
      if (!(filterKey in newQueryString) || !newQueryString[filterKey]) {
        setInputValue("");
      }
    });
  }, [history, filterKey]);

  useEffect(() => {
    if (filterValue !== debouncedInputValue) {
      const update = { [filterKey]: debouncedInputValue };

      if (resetPaginationOnChange) {
        update.page = null;
      }

      const updatedQs = omitBy(
        { ...qs, ...update },
        (v) => isNil(v) || v === ""
      );

      history.replace({
        pathname: location.pathname,
        search: stringify(updatedQs),
      });
    }
  }, [
    qs,
    history,
    filterKey,
    filterValue,
    location.pathname,
    debouncedInputValue,
    resetPaginationOnChange,
  ]);

  return { filterValue, inputValue, setInputValue };
};

export interface UseUrlFiltersOptions<K = string> {
  keys?: readonly K[];
  resetPaginationOnChange?: boolean;
}

export type FilterInputValue =
  | string
  | number
  | boolean
  | null
  | readonly string[]
  | readonly number[];

export type FilterValue = string | readonly string[];

export const useUrlFilters = <
  TFilterKey extends string,
  TFiltersInput extends Record<TFilterKey, FilterInputValue> = Record<
    TFilterKey,
    FilterInputValue
  >,
  TFilters extends Record<TFilterKey, FilterValue> = Record<
    TFilterKey,
    FilterValue
  >,
>({
  keys,
  resetPaginationOnChange = true,
}: UseUrlFiltersOptions<TFilterKey>) => {
  const history = useHistory();
  const location = useLocation();
  const qs = useQueryString();

  const filters = useMemo(
    () => (keys ? pick(qs, keys) : qs),
    [keys, qs]
  ) as TFilters;

  const setAllFilters = useCallback(
    (filters: TFiltersInput) => {
      const update: Record<string, FilterInputValue> = { ...filters };

      // By default, if any filters change, we will clear page=x from the QS unless the change is to the page itself
      if (resetPaginationOnChange && update.page === qs.page) {
        update.page = null;
      }

      const updatedQs = omitBy(
        { ...qs, ...update },
        (v) => isNil(v) || v === "" || (Array.isArray(v) && v.length === 0)
      );

      history.replace({
        pathname: location.pathname,
        search: stringify(updatedQs),
      });
    },
    [qs, history, location.pathname, resetPaginationOnChange]
  );

  const setFilter = useCallback(
    (key: TFilterKey, value: FilterInputValue) => {
      setAllFilters({ [key]: value } as any);
    },
    [setAllFilters]
  );

  return { filters, setAllFilters, setFilter };
};
