import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import * as Yup from "yup";

import {
  Button,
  ConfirmDialog,
  Form,
  FormikNumberInputField,
  FormikSelectField,
  FormikStaticTextField,
  FormikSubmit,
  FormikTextInputField,
  Grid,
  HStack,
  Panel,
  PanelBody,
  PanelHeader,
  Tooltip,
  Typography,
  useToast,
} from "@smartrent/ui";

import { InformationOutline } from "@smartrent/icons";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AxiosError } from "axios";

import { Formik, FormikHelpers } from "formik";

import { useModalState } from "@smartrent/hooks";

import { ScopeOfWorkDetailProps, ScopeOfWorkProps } from "@/types";

import { SOW_QUERY_KEY, updateSowDetailsFn } from "@/api";

import { useGlobalContext } from "@/layout/Context";

import { formatAddress } from "@/utils/format-address";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";

import {
  cellQualityOptions,
  cellQualityValues,
  NUMBER_MAX,
  YesNoOptions,
} from "../utils";

export const validationSchema = Yup.object({
  group_name: Yup.string().nullable(),
  group_full_address: Yup.string().nullable(),
  units_count: Yup.number()
    .nullable()
    .positive("Units must be greater than 0")
    .max(NUMBER_MAX, `Units must be less than ${NUMBER_MAX.toLocaleString()}`)
    .default(1)
    .defined(),
  cell_quality: Yup.string().oneOf(cellQualityValues),
  floors_count: Yup.number()
    .positive("Floors must be greater than 0")
    .max(NUMBER_MAX, `Floors must be less than ${NUMBER_MAX.toLocaleString()}`)
    .nullable(),
  floor_plans_count: Yup.number()
    .positive("Floor Plans must be greater than 0")
    .max(
      NUMBER_MAX,
      `Floor Plans must be less than ${NUMBER_MAX.toLocaleString()}`
    )
    .nullable(),
  phases_count: Yup.number()
    .positive("Phases must be greater than 0")
    .max(NUMBER_MAX, `Phases must be less than ${NUMBER_MAX.toLocaleString()}`)
    .nullable(),
  ada_count: Yup.number()
    .positive("ADA Count must be greater than 0")
    .max(
      NUMBER_MAX,
      `ADA Count must be less than ${NUMBER_MAX.toLocaleString()}`
    )
    .nullable(),
  units_surveyed_count: Yup.number()
    .positive("Units Surveyed must be greater than 0")
    .max(
      NUMBER_MAX,
      `Units Surveyed must be less than ${NUMBER_MAX.toLocaleString()}`
    )
    .nullable(),
  has_secondary_entrances: Yup.boolean().nullable(),
  has_multiple_tstats: Yup.boolean().nullable(),
  has_multiple_heaters: Yup.boolean().nullable(),
});

export const SowPropertySectionEditForm: React.VFC = () => {
  const { history } = useGlobalContext();

  const setToast = useToast();
  const queryClient = useQueryClient();
  const { visible, onOpen, onClose } = useModalState();

  // USE SOW CONTEXT
  const {
    scopeOfWorkId,
    scopeOfWorkQuery: { data },
    scopeOfWorkQueryKey,
  } = useScopeOfWorkContext();

  const editPropertySectionMutation = useMutation<
    ScopeOfWorkDetailProps,
    AxiosError,
    Partial<ScopeOfWorkDetailProps>
  >(
    [SOW_QUERY_KEY, "edit-button"],
    (props) => updateSowDetailsFn(scopeOfWorkId, props),
    {
      onSuccess: (result) => {
        // Replace query data with the result
        queryClient.setQueryData<ScopeOfWorkProps>(
          scopeOfWorkQueryKey,
          (previous) => ({
            ...previous,
            ...{ scope_of_work_detail: result },
          })
        );
        setToast({
          status: "success",
          message: "Successfully updated Property Section!",
        });
      },
      onError: (error) => {
        setToast({
          status: "error",
          message: `Updating Property Section failed.`,
        });
      },
      retry: 0,
    }
  );

  const handleSubmit = useCallback(
    async (
      values: any,
      actions: FormikHelpers<Partial<ScopeOfWorkDetailProps>>
    ) => {
      // If the value is cleared in the component, Formik doesn't pass that in the values so we need to set it to null before submitting
      const valuesForSubmit = {
        group_name: null,
        group_full_address: null,
        units_count: null,
        cell_quality: null,
        floors_count: null,
        floor_plans_count: null,
        phases_count: null,
        ada_count: null,
        units_surveyed_count: null,
        has_secondary_entrances: null,
        has_multiple_tstats: null,
        has_multiple_heaters: null,
        ...values,
      };
      actions.setSubmitting(true);
      try {
        await editPropertySectionMutation.mutateAsync(valuesForSubmit);

        history.goBack(); // go back to SOW Editor page if successfully updated
      } catch (error) {
        // try to display FORMIK inline validations using solicit error messages from CMW API
        const solicitErrors = (error as any)?.response?.data?.errors;
        if (solicitErrors && solicitErrors.length) {
          solicitErrors.forEach(({ field, description }) => {
            actions.setFieldError(field, description);
            actions.setFieldTouched(field, true);
          });
        }
      }
      actions.setSubmitting(false);
    },
    [editPropertySectionMutation, history]
  );

  const handleCancelButtonConfirmation = useCallback(
    () => history.goBack(),
    [history]
  );

  const initialValues = useMemo(() => {
    return {
      group_name: data?.group?.marketing_name
        ? data.group.marketing_name
        : null,
      group_full_address: formatAddress(data?.group),
      units_count: data?.scope_of_work_detail?.units_count
        ? data.scope_of_work_detail.units_count
        : null,
      cell_quality: data?.scope_of_work_detail?.cell_quality
        ? data.scope_of_work_detail.cell_quality
        : "",
      floors_count: data?.scope_of_work_detail?.floors_count
        ? data.scope_of_work_detail.floors_count
        : null,
      floor_plans_count: data?.scope_of_work_detail?.floor_plans_count
        ? data.scope_of_work_detail.floor_plans_count
        : null,
      phases_count: data?.scope_of_work_detail?.phases_count
        ? data.scope_of_work_detail.phases_count
        : null,
      ada_count: data?.scope_of_work_detail?.ada_count
        ? data.scope_of_work_detail.ada_count
        : null,
      units_surveyed_count: data?.scope_of_work_detail?.units_surveyed_count
        ? data.scope_of_work_detail.units_surveyed_count
        : null,
      has_secondary_entrances: data?.scope_of_work_detail
        ?.has_secondary_entrances
        ? data.scope_of_work_detail.has_secondary_entrances
        : null,
      has_multiple_tstats: data?.scope_of_work_detail?.has_multiple_tstats
        ? data.scope_of_work_detail.has_multiple_tstats
        : null,
      has_multiple_heaters: data?.scope_of_work_detail?.has_multiple_heaters
        ? data.scope_of_work_detail.has_multiple_heaters
        : null,
    };
  }, [data?.group, data?.scope_of_work_detail]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values }) => (
        <Panel style={styles.panel}>
          <Form>
            <PanelHeader
              EndAdornment={
                <HStack spacing={16} align="center">
                  <Button onPress={onOpen} variation="outlined">
                    Cancel
                  </Button>

                  <FormikSubmit label="Save" submittingLabel="Saving..." />
                </HStack>
              }
            >
              <Typography type="title2">Property Information</Typography>
            </PanelHeader>
            <PanelBody>
              <View style={styles.formContainer}>
                <Typography type="title2">Details</Typography>

                <Grid numColumns={2} style={styles.grid}>
                  <View style={styles.flexRow}>
                    <FormikStaticTextField
                      style={styles.formBox}
                      name="group_name"
                      transformValue={(val) => val}
                      onPress={() => null}
                      label="Name"
                      disabled={true}
                    />
                    <Tooltip
                      title="Property name will be automatically updated when a group is selected in the SOW Builder"
                      name="propertyName"
                      placement="right"
                    >
                      <View style={styles.tooltipPosition}>
                        <InformationOutline />
                      </View>
                    </Tooltip>
                  </View>

                  <View style={styles.flexRow}>
                    <FormikStaticTextField
                      style={styles.formBox}
                      name="group_full_address"
                      transformValue={(val) => val}
                      onPress={() => null}
                      label="Address"
                      disabled={true}
                    />
                    <Tooltip
                      title="Property address will be automatically updated when a group is selected in the SOW Builder"
                      name="propertyAddress"
                      placement="left"
                    >
                      <View style={styles.tooltipPosition}>
                        <InformationOutline />
                      </View>
                    </Tooltip>
                  </View>

                  <FormikNumberInputField
                    style={styles.formBox}
                    name="units_count"
                    label="Units"
                  />

                  <FormikSelectField
                    style={styles.formBox}
                    name="cell_quality"
                    label="Cell Quality"
                    options={cellQualityOptions}
                  />

                  <FormikNumberInputField
                    style={styles.formBox}
                    name="floors_count"
                    label="Floors"
                  />
                  <FormikNumberInputField
                    style={styles.formBox}
                    name="floor_plans_count"
                    label="Floor Plans"
                  />
                  <FormikNumberInputField
                    style={styles.formBox}
                    name="phases_count"
                    label="Phases"
                  />
                  <FormikNumberInputField
                    style={styles.formBox}
                    name="units_surveyed_count"
                    label="Units Surveyed"
                  />
                  <FormikTextInputField
                    style={styles.formBox}
                    name="ada_count"
                    label="ADA Count"
                  />

                  <FormikSelectField
                    style={styles.formBox}
                    name="has_secondary_entrances"
                    label="Secondary Entrances"
                    options={YesNoOptions}
                  />
                  <FormikSelectField
                    style={styles.formBox}
                    name="has_multiple_tstats"
                    label="Multiple Thermostats"
                    options={YesNoOptions}
                  />
                  <FormikSelectField
                    style={styles.formBox}
                    name="has_multiple_heaters"
                    label="Multiple Heaters"
                    options={YesNoOptions}
                  />
                </Grid>
              </View>
            </PanelBody>
          </Form>
          <ConfirmDialog
            title="Are you sure?"
            description={
              <Typography type="bodyLarge" style={styles.confirmPrompt}>
                Are you sure you want to discard this information?
              </Typography>
            }
            visible={visible}
            onClose={onClose}
            onConfirm={() => {
              handleCancelButtonConfirmation();
              onClose();
            }}
          />
        </Panel>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  panel: {
    marginTop: 8,
    paddingBottom: 0,
  },
  formContainer: {
    flexDirection: "column",
    width: "100%",
  },
  formBox: { margin: 8, flex: 1 },
  grid: {
    paddingVertical: 16,
    width: "100%",
  },
  confirmPrompt: { textAlign: "center", marginVertical: 16 },
  tooltipPosition: {
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
    marginTop: -16,
  },
  flexRow: {
    flexDirection: "row",
  },
});
