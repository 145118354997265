import { useContext } from "react";

import { useMutationCompat, useQueryCompat } from "@smartrent/hooks";

import Context from "@/layout/Context";
import { instance } from "@/lib/hooks";
import { useInvalidateGroup, useInvalidateParkingProperty } from "@/api";
import { AxiosMutationConfig } from "@/lib/react-query-helpers";

interface EnableParkingOptions {
  groupId: number;
}

interface DisableParkingOptions {
  groupId: number;
}

interface ImportParkingUserOptions {
  groupId: number;
  propertyId: string;
}

export const useParkingWebUrlQuery = () =>
  useQueryCompat(["parking-web"], async (key: "parking-web") => {
    const { data } = await instance.get("/parking-web/url");
    return data;
  });

export const useEnableParkingMutation = (
  options?: AxiosMutationConfig<EnableParkingOptions>
) => {
  const context = useContext(Context);
  const invalidateGroup = useInvalidateGroup();

  return useMutationCompat(async ({ groupId }: EnableParkingOptions) => {
    try {
      const { data } = await instance.post(`/groups/${groupId}/parking/enable`);

      context.setToast({
        type: "success",
        title: "Success",
        message: "Parking successfully enabled.",
      });

      invalidateGroup(groupId);

      return data;
    } catch (err) {
      context.setToast({
        type: "error",
        title: "Error",
        message: "Error enabling parking.",
      });

      throw err;
    }
  }, options);
};

export const useDisableParkingMutation = (
  options?: AxiosMutationConfig<DisableParkingOptions>
) => {
  const context = useContext(Context);
  const invalidateGroup = useInvalidateGroup();

  return useMutationCompat(async ({ groupId }: DisableParkingOptions) => {
    try {
      const { data } = await instance.post(
        `/groups/${groupId}/parking/disable`
      );

      context.setToast({
        type: "success",
        title: "Success",
        message: "Parking successfully disabled.",
      });

      invalidateGroup(groupId);

      return data;
    } catch (err) {
      context.setToast({
        type: "error",
        title: "Error",
        message: "Error disabling parking.",
      });

      throw err;
    }
  }, options);
};

export const useImportParkingUsersMutation = (
  options?: AxiosMutationConfig<ImportParkingUserOptions>
) => {
  const context = useContext(Context);
  const invalidateProperty = useInvalidateParkingProperty();

  return useMutationCompat(
    async ({ groupId, propertyId }: ImportParkingUserOptions) => {
      try {
        const { data } = await instance.post(
          `/groups/${groupId}/parking/users`
        );

        context.setToast({
          type: "success",
          title: "Success",
          message: "Import parking users successful.",
        });

        invalidateProperty(propertyId);

        return data;
      } catch (err) {
        context.setToast({
          type: "error",
          title: "Error",
          message: "Error importing parking users.",
        });

        throw err;
      }
    },
    options
  );
};
