import { useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import { FormikStaticTextField, useTheme } from "@smartrent/ui";

import { useFormikFieldsHook } from "@/pages/hub-refurbish/hooks/useFormikFieldsHook";
import {
  HubRefurbishFormikFieldLabels,
  HubRefurbishFormikFieldNames,
  HubRefurbishReactQueryKeys,
} from "@/types/HubRefurbishProps";

import { ConnectionStatus } from "@/utils/zipato";

import { useHubRefurbishContext } from "@/pages/hub-refurbish/HubRefurbishContext";

interface FormikRedisStatsOnlineFieldProps {
  required?: boolean;
}

export const RedisStatsFormikFieldOnline: React.FC<
  React.PropsWithChildren<FormikRedisStatsOnlineFieldProps>
> = ({ required }) => {
  const { colors } = useTheme();
  const { getHubs } = useHubRefurbishContext();

  const onlineHubs = useMemo(
    () => getHubs((hub) => hub?.status === ConnectionStatus.Online),
    [getHubs]
  );

  // ONLINE
  const { onClearCallback, containerStyle, labelStyle, disabled } =
    useFormikFieldsHook({
      required,
      name: HubRefurbishFormikFieldNames.Online,
      queryKey: HubRefurbishReactQueryKeys.RedisStats,
      value: onlineHubs,
    });

  return (
    <FormikStaticTextField
      style={styles.root}
      containerStyle={{
        ...containerStyle,
        ...{ backgroundColor: colors.mutedBackgroundSuccess },
      }}
      labelStyle={{
        ...labelStyle,
        ...{ color: colors.mutedTextSuccess },
      }}
      name={HubRefurbishFormikFieldNames.Online}
      label={HubRefurbishFormikFieldLabels.Online}
      onPress={() => null}
      transformValue={(value) => value?.length} // display count only
      onClear={onClearCallback}
      disabled={disabled}
    />
  );
};

const styles = StyleSheet.create({
  root: { maxWidth: 175 },
});
