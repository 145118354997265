import { useCallback } from "react";
import * as React from "react";
import { QueryFunction } from "@tanstack/react-query";

import {
  Typography,
  Table,
  useTableQuery,
  ListQueryResponse,
  TableProps,
  Button,
  QueryKey,
} from "@smartrent/ui";

import { useModalState } from "@smartrent/hooks";

import { Launch, Plus } from "@smartrent/icons";

import {
  formatDateToBeHumanReadable,
  getOrgName,
  salesforceUrl,
} from "@/utils";
import { instance } from "@/lib/hooks";

import { ScopeOfWorkProps } from "@/types/ScopeOfWorkProps";

import { EM_DASH } from "@/utils/chars";

import { formatUsersFullName } from "@/utils/format-username";

import { useGlobalContext } from "@/layout/Context";

import { ScopeOfWorkCreateModal } from "./ScopeOfWorkCreateModal";
import { formatScopeOfWorkStatus, statusOptions } from "./utils";
import { SowStatusBadge } from "./sow-report/SowStatusBadge";

export interface ScopeOfWorkResponse
  extends ListQueryResponse<ScopeOfWorkProps> {}

interface ScopeOfWorkTableProps extends Partial<TableProps> {}

export const ScopeOfWorkTable: React.FC<
  React.PropsWithChildren<ScopeOfWorkTableProps>
> = ({ style }) => {
  const { user } = useGlobalContext();

  const DEFAULT_PAGE_SIZE = 25;

  const { visible, onOpen, onClose } = useModalState();

  const fetchScopeOfWork: QueryFunction<
    ListQueryResponse<ScopeOfWorkProps>
  > = async ({ queryKey }) => {
    const [, , filters] = queryKey;
    const { data } = await instance.get<ScopeOfWorkResponse>("/scope-of-work", {
      params: filters,
    });
    return data;
  };

  const tableProps = useTableQuery<QueryKey, ScopeOfWorkProps, any>({
    fetch: fetchScopeOfWork,
    getQueryKey: ({
      filters,
      page,
      pageSize,
      sortColumn = "inserted_at",
      sortDirection = "desc",
    }) => [
      ["scope-of-work-table"],
      {},
      {
        ...filters,
        page,
        limit: pageSize,
        sort: sortColumn,
        dir: sortDirection,
      },
    ],
    defaultPageSize: DEFAULT_PAGE_SIZE,
    columns: [
      {
        name: "name",
        header: "Name",
        render: ({ row }) => <Typography>{row.name}</Typography>,
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },

      {
        name: "group_name",
        header: "Property Name",
        render: ({ row: { group } }) => (
          <Typography>
            {group?.marketing_name ? group.marketing_name : EM_DASH}
          </Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },

      {
        name: "organizations_name",
        header: "Organization Name",
        render: ({ row }) => (
          <Typography>{getOrgName(row.organization, row.group)}</Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },

      {
        name: "inserted_at",
        header: "Date Created",

        render: ({ row: { inserted_at } }) => (
          <Typography>
            {formatDateToBeHumanReadable({
              date: inserted_at,
              opts: { timeZone: "UTC" },
            })}
          </Typography>
        ),
        sortable: true,
      },
      {
        name: "submitted_at",
        header: "Date Submitted",

        render: ({ row: { submitted_at } }) => (
          <Typography>
            {formatDateToBeHumanReadable({
              date: submitted_at,
              opts: { timeZone: "UTC" },
            })}
          </Typography>
        ),
        sortable: true,
      },

      {
        name: "users_name",
        header: "Submitted by",
        render: ({ row: { user } }) => (
          <Typography>{user ? formatUsersFullName(user) : EM_DASH}</Typography>
        ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },

      {
        name: "completed_at",
        header: "Date Completed",

        render: ({ row: { completed_at } }) => (
          <Typography>
            {formatDateToBeHumanReadable({
              date: completed_at,
              opts: { timeZone: "UTC" },
            })}
          </Typography>
        ),
        sortable: true,
      },

      {
        name: "sf_opportunity_id",
        header: "Opportunity",
        minWidth: 175,
        render: ({ row }) =>
          row?.sf_opportunity_id ? (
            <Button
              iconRight={Launch}
              variation="plain"
              size="x-small"
              // I have no idea why an inline style is the only way to get this margin to work

              contentStyle={{ marginLeft: -24 }}
              onPress={() => {
                window.open(`${salesforceUrl()}/${row?.sf_opportunity_id}`);
              }}
            >
              View in Salesforce
            </Button>
          ) : (
            <Typography>{EM_DASH}</Typography>
          ),
        sortable: true,
        filterType: {
          type: "textInputField",
        },
      },

      {
        name: "status",
        header: "Status",
        render: ({ row: { status } }) => (
          <SowStatusBadge parStatus={status} size="semibold.title.three">
            {formatScopeOfWorkStatus(status)}
          </SowStatusBadge>
        ),
        sortable: true,
        filterType: {
          type: "selectField",
          options: statusOptions,
        },
      },
    ],
  });

  const handleGetRowHref = useCallback(
    ({ id }: ScopeOfWorkProps) => `/scope-of-work/${id}`,
    []
  );

  return (
    <>
      <Table<ScopeOfWorkProps>
        title="Scope of Work"
        noRecordsText="No Reports"
        getRowHref={handleGetRowHref}
        showClearFiltersButton
        action={
          user?.permissions?.manage_sow ? (
            <Button iconLeft={Plus} onPress={onOpen}>
              Generate Report
            </Button>
          ) : null
        }
        style={style}
        {...tableProps}
      />
      <ScopeOfWorkCreateModal visible={visible} onClose={onClose} />
    </>
  );
};
