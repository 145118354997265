import { useCallback } from "react";

import { useGlobalContext } from "@/layout/Context";

export interface ScrollToProps {
  x?: number;
  y?: number;
  animated?: boolean;
}

/**
 *
 * Hook to use callbacks that originate from references we define in the <Layout /> component
 *
 * This hook uses the GlobalContext to manage state, so the <Provider /> component is required to use the hook.
 *
 * Example:
 *
 *  When component <Foo /> wants to use this hook
 *  Then the Router.tsx definition looks like:
 *
 *    <Provider>
 *     <Foo />
 *    <Provider/>
 */
export const useLayout = () => {
  const { scrollViewRef } = useGlobalContext();

  const scrollTo = useCallback(
    (props: ScrollToProps) => {
      if (!scrollViewRef) {
        console.warn(
          "scrollTo method is being skipped! \nRef to <ScrollView/> component is missing!"
        );
        return;
      }
      if (!scrollViewRef?.current) {
        console.warn(
          "scrollTo method is being skipped! \nCould not find a 'current' reference to any <ScrollView/> component."
        );
        return;
      }

      try {
        scrollViewRef.current.scrollTo(props);
      } catch (e) {
        console.warn(e);
      }
    },
    [scrollViewRef]
  );

  return { scrollTo };
};
