import { useCallback } from "react";
import * as React from "react";
import { FormikHelpers } from "formik";

import { useUpdateParkingMapsModule } from "@/api";
import { useDrawerNav } from "@/common/AppDrawer";

import { ParkingMapsForm, ParkingMapsFormValues } from "./ParkingMapsForm";

interface UpdateParkingMapsProps {
  propertyId: string;
}

export const UpdateParkingMaps: React.FC<
  React.PropsWithChildren<UpdateParkingMapsProps>
> = ({ propertyId }) => {
  const drawer = useDrawerNav();
  const [updateParkingMapModule] = useUpdateParkingMapsModule();

  const handleCancel = useCallback(() => drawer.pop(), [drawer]);

  const handleSubmit = useCallback(
    async (
      values: ParkingMapsFormValues,
      actions: FormikHelpers<ParkingMapsFormValues>
    ) => {
      actions.setSubmitting(true);
      await updateParkingMapModule({
        property: {
          id: propertyId,
          map_id: values.map_id,
        },
      });
      actions.setSubmitting(false);

      drawer.reset();
    },
    [drawer, propertyId, updateParkingMapModule]
  );

  return (
    <>
      <ParkingMapsForm
        propertyId={propertyId}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </>
  );
};
