import { useContext, useState, useEffect } from "react";
import moment from "moment";
import { startCase, get } from "lodash-es";
import { Link } from "react-router-dom";

import Helpers from "@/lib/helpers";
import { useApi } from "@/lib/hooks";

import Card from "@/common/Card";
import CardHeading from "@/common/CardHeading";
import Button from "@/common/Button";
import Context from "@/layout/Context";
// import CardActionSheet from "@/common/CardActionSheet";

import { PermissionProps } from "../../types";

function TourStatus({ tour }) {
  if (tour.deleted_at) {
    return (
      <div className="u-font12 u-text-symbolic-error">
        {startCase(tour.deleted_reason)}
      </div>
    );
  } else if (moment().isAfter(tour.scheduled_end)) {
    return <div className="u-font12 u-text-symbolic-success">Past</div>;
  } else if (
    moment().isAfter(tour.scheduled_start) &&
    moment().isBefore(tour.scheduled_end)
  ) {
    return <div className="u-font12 u-text-symbolic-success">Active</div>;
  }

  return <div className="u-font12 u-text-symbolic-knowledge">Future</div>;
}

function TourTime({ tour }) {
  return (
    <div className="u-font12 u-text-gray-600">
      {Helpers.formatDate(tour.scheduled_start)} to{" "}
      {Helpers.formatDate(tour.scheduled_end)}
    </div>
  );
}

export default function UpcomingTours() {
  const context = useContext(Context);
  const [tours, setTours] = useState(null);
  const [moreRecords, setMoreRecords] = useState(true);
  const [page, setPage] = useState(1);

  const permissions: PermissionProps = context.user.permissions;
  const { response, reFetch } = useApi({
    url: `/tours/upcoming?${Helpers.toQS({
      page,
    })}`,
  });

  useEffect(() => {
    if (get(response, "data.tours.length", 0)) {
      setTours([...(tours || []), ...response.data.tours]);
      setPage(page + 1);
    } else if (get(response, "data")) {
      setMoreRecords(false);
    }
  }, [response]);

  if (!permissions.resident_details) {
    return null; // if you don't have this permission you cannot view this data
  }

  return (
    <div className="u-p8">
      {tours &&
        tours.map((tour) => {
          return (
            <Card
              key={tour.id}
              customStyles={{ marginBottom: "12px" }}
              heading={
                <CardHeading styles={{ lineHeight: 18 }}>
                  <div>
                    <Link to={`/prospects/${tour.prospect.id}`} className="a">
                      {tour.prospect.first_name} {tour.prospect.last_name}
                    </Link>
                  </div>
                  <TourTime tour={tour} />
                  <TourStatus tour={tour} />
                </CardHeading>
              }
            >
              {tour.units.map((unit) => {
                return (
                  <div
                    key={unit.id}
                    className="u-flex u-flex-wrap u-flex-justify-between"
                  >
                    <Link
                      to={`/units/${unit.id}`}
                      className="a"
                      target="_blank"
                    >
                      {unit.marketing_name}
                    </Link>
                    <a
                      href={`https://maps.google.com?q=${
                        unit.lat || tour.group.lat
                      },${unit.lng || tour.group.lng}`}
                      target="_blank"
                      className="a u-font11"
                      rel="noopener noreferrer"
                    >
                      GMaps
                    </a>
                  </div>
                );
              })}

              <div
                className="u-text-gray-600 u-font12 u-mtop12 u-ptop8 u-line14"
                style={{ borderTop: "1px solid #eee" }}
              >
                <div>
                  {tour.group.marketing_name} ({tour.organization.name})
                </div>
                <div className="u-font10">
                  created on {Helpers.formatDate(tour.inserted_at)}
                </div>
              </div>
            </Card>
          );
        })}
      {moreRecords && (
        <Button
          onClick={() => reFetch()}
          className="button button--primary button--sm"
        >
          {!response ? "Fetch Upcoming Tours" : "More Tours"}
        </Button>
      )}
    </div>
  );
}
