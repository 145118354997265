import { useMemo } from "react";
import * as React from "react";
import { StyleSheet } from "react-native";

import { DecalStatus } from "@smartrent/parking";
import { StatusBadge, StatusBadgeProps } from "@smartrent/ui";

import { DecalListItem } from "../../api/parking/decals";

interface DecalStatusPillProps {
  decal: DecalListItem;
}

export const getDecalStatusText = (decalStatus: DecalStatus): string => {
  switch (decalStatus) {
    case "unassigned":
      return "Pre-Activated";
    case "inactive":
      return "Archived";
    case "active":
      return "Active";
    default:
      return "Unknown";
  }
};

export const getDecalStatus = (decal: DecalListItem): DecalStatus => {
  if (decal.active && decal.assigned) {
    return "active";
  }

  if (decal.active && !decal.assigned) {
    return "unassigned";
  }

  return "inactive";
};

const DecalStatusPill: React.FC<
  React.PropsWithChildren<DecalStatusPillProps>
> = ({ decal }) => {
  const status = getDecalStatus(decal);

  const statusType: StatusBadgeProps["status"] = useMemo(() => {
    switch (status) {
      case "active":
        return "success";
      case "inactive":
        return "warning";
      default:
        return "knowledge";
    }
  }, [status]);

  const text = useMemo(() => {
    return getDecalStatusText(status).toUpperCase();
  }, [status]);

  return (
    <StatusBadge
      status={statusType}
      style={styles.root}
      size="semibold.title.four"
    >
      {text}
    </StatusBadge>
  );
};

const styles = StyleSheet.create({ root: { width: 120 } });

export default DecalStatusPill;
