import { useCallback } from "react";
import * as React from "react";
import { View, StyleSheet, FlatList, ListRenderItemInfo } from "react-native";

import { Skeleton, Text, useTheme } from "@smartrent/ui";

interface PanelKeyValueRowProps {
  label: React.ReactNode;
  value: React.ReactNode;
  loading?: boolean;
  onRowPress?: () => void;
}

const PanelKeyValueRow: React.FC<
  React.PropsWithChildren<PanelKeyValueRowProps>
> = ({ label, value, loading }) => {
  const { colors } = useTheme();
  return (
    <View style={styles.rowRoot}>
      {React.isValidElement(label) ? (
        label
      ) : (
        <Text type="title5" style={{ color: colors.textSecondary }}>
          {label}
        </Text>
      )}
      {loading ? (
        <Skeleton textType="title5" width={150} />
      ) : React.isValidElement(value) ? (
        value
      ) : (
        <Text type="title5">{value ?? "\u2014"}</Text>
      )}
    </View>
  );
};

const ItemSeparator = () => {
  const { colors } = useTheme();

  return (
    <View style={[styles.separator, { backgroundColor: colors.border }]} />
  );
};

interface PanelKeyValueItem {
  label: React.ReactNode;
  value: React.ReactNode;
}

interface PanelKeyValueListProps {
  items: PanelKeyValueItem[];
  loading?: boolean;
}

export const PanelKeyValueList: React.FC<
  React.PropsWithChildren<PanelKeyValueListProps>
> = ({ items, loading }) => {
  const renderItem = useCallback(
    ({ item: { label, value } }: ListRenderItemInfo<PanelKeyValueItem>) => (
      <PanelKeyValueRow label={label} value={value} loading={loading} />
    ),
    [loading]
  );

  return (
    <FlatList
      data={items}
      extraData={loading}
      keyExtractor={(_, index) => String(index)}
      renderItem={renderItem}
      ItemSeparatorComponent={ItemSeparator}
    />
  );
};

const styles = StyleSheet.create({
  rowRoot: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    width: "100%",
  },
});
