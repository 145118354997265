import { useCallback } from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

import { Box, Text, useTheme } from "@smartrent/ui";

import { BaseRecord } from "./types";
import { RowProps } from "./Row";
import { TextCell } from "./TextCell";

export function GenericCardRow<TRecord extends BaseRecord>({
  columns,
  value,
  onPress,
}: RowProps<TRecord>) {
  const { colors } = useTheme();

  const cells = columns
    // remove the magic right chevron column that `Table` adds when `onRowPress`
    // is provided
    .filter((c) => c.name !== "__chevron_right__")
    .map((column) => {
      let cellContent = column.render({ column, row: value });

      if (typeof cellContent === "string") {
        cellContent = <TextCell>{cellContent}</TextCell>;
      }

      return (
        <View
          key={`${column.name}_cell`}
          style={[
            {
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottomWidth: 1,
              borderBottomColor: colors.gray100,
              paddingVertical: 8,
            },
            column.style,
          ]}
        >
          <Text type="title5">{column.header}</Text>
          {cellContent}
        </View>
      );
    });

  const handlePress = useCallback(() => {
    onPress(value);
  }, [onPress, value]);

  if (onPress) {
    return (
      <TouchableOpacity style={styles.root} onPress={handlePress}>
        <Box style={styles.inner}>{cells}</Box>
      </TouchableOpacity>
    );
  }

  return <Box style={styles.root}>{cells}</Box>;
}

const styles = StyleSheet.create({
  root: {},
  inner: {
    flex: 1,
  },
});
