import { useCallback } from "react";
import * as React from "react";

import { useImportParkingUsersMutation } from "@/api";
import { ActionItem } from "@/common/ActionItem";
import { useDialog } from "@/lib/contexts/dialog";

interface ImportParkingUsersActionProps {
  groupId: number;
  propertyId: string;
  onSuccess: () => void;
}

export const ImportParkingUsersAction: React.FC<
  React.PropsWithChildren<ImportParkingUsersActionProps>
> = ({ groupId, propertyId, onSuccess }) => {
  const { confirm } = useDialog();
  const [importUsers] = useImportParkingUsersMutation();

  const handleImportUsers = useCallback(async () => {
    const confirmed = await confirm({
      title: `Import Parking Users?`,
      confirmText: "Import",
      description:
        "Are you sure you would like to import group users into parking?",
    });

    if (confirmed) {
      await importUsers({ groupId, propertyId });
      onSuccess();
    }
  }, [importUsers, groupId, onSuccess]);

  return (
    <ActionItem onPress={handleImportUsers} title="Import Parking Users" />
  );
};
