import { useState, useEffect } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useQueryClient } from "@tanstack/react-query";

import {
  ListQueryResponse,
  Dialog,
  DialogAction,
  DialogContent,
  DialogTitle,
  VStack,
  SelectField,
  TextInputField,
  useToast,
} from "@smartrent/ui";

import { useGlobalContext } from "@/layout/Context";

import { ScopeOfWorkProps } from "@/types/ScopeOfWorkProps";

import {
  useCreateSOWMutation,
  selectQueryPropsOrg,
  selectQueryPropsGroups,
} from "@/api";

export interface ScopeOfWorkResponse
  extends ListQueryResponse<ScopeOfWorkProps> {}

export const ScopeOfWorkCreateModal: React.FC<
  React.PropsWithChildren<{
    visible: boolean;
    onClose: VoidFunction;
  }>
> = ({ visible, onClose }) => {
  const queryClient = useQueryClient();

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [name, setName] = useState("");
  const [createSOW] = useCreateSOWMutation();
  const { history } = useGlobalContext();
  const setToast = useToast();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["groups"] });
    setSelectedGroup(null);
  }, [selectedOrg, queryClient]);

  const handleSubmit = async () => {
    const { id } = await createSOW({
      organization_id: selectedOrg?.id,
      group_id: selectedGroup?.id,
      name: name || selectedGroup?.marketing_name,
    });

    id
      ? history.push(`/scope-of-work/${id}`)
      : setToast({
          status: "error",
          message: `Invalid Organization ID returned: '${id}'`,
        });
  };

  return (
    <>
      <Dialog
        visible={visible}
        onClose={onClose}
        titleID="dialog-title"
        descriptionID="dialog-desc"
      >
        <DialogContent style={styles.dialogContent}>
          <VStack spacing={20}>
            <DialogTitle style={styles.dialogTitle}>
              Generate a new Scope of Work
            </DialogTitle>

            <SelectField
              label="Select an Organization"
              name="orgSelection"
              value={selectedOrg}
              onChange={setSelectedOrg}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ name }) => name}
              {...selectQueryPropsOrg()}
            />

            <SelectField
              label="Select a Group (optional)"
              name="groupSelection"
              value={selectedGroup}
              onChange={setSelectedGroup}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ marketing_name }) => marketing_name}
              disabled={!selectedOrg}
              {...selectQueryPropsGroups({ selectedOrg })}
            />

            <TextInputField
              label="Add a name (optional)"
              textInputProps={{
                value: name,
                onChangeText: setName,
              }}
              disabled={!selectedOrg}
            />

            <View style={styles.buttons}>
              <DialogAction text="Cancel" type="cancel" onPress={onClose} />
              <DialogAction
                text="Continue"
                onPress={handleSubmit}
                disabled={!selectedOrg}
              />
            </View>
          </VStack>
        </DialogContent>
      </Dialog>
    </>
  );
};

const styles = StyleSheet.create({
  dialogContent: {
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  dialogTitle: { textAlign: "center" },
  buttons: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
});
