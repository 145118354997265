import * as React from "react";

import { StyleSheet, View } from "react-native";

import { Box, PanelHeaderTitle, useTheme } from "@smartrent/ui";

import { useGroupPropertyStaffUsersReportQuery } from "@/api/reports";

import { UsersReport } from "@/common/users/UsersReport";
import { UsersTableLink } from "@/common/users/UsersTableLink";

import { useGroup } from "../GroupContext";

export const GroupUsersCard: React.VFC = () => {
  const { colors } = useTheme();
  const {
    group: { id, organization_id },
  } = useGroup();

  const { data } = useGroupPropertyStaffUsersReportQuery(
    {
      groupId: id,
    },
    { enabled: id && id !== null }
  );

  return (
    <Box style={styles.root}>
      {/* CARD HEADING */}
      <View style={[styles.header, { backgroundColor: colors.pageBackground }]}>
        <PanelHeaderTitle title="Property Staff" />
      </View>

      {/* CARD BODY */}
      <View style={styles.body}>
        <UsersReport {...data} />
      </View>

      {/* CARD FOOTER */}
      <View style={styles.footer}>
        <UsersTableLink
          search={{
            employee_role: "property_staff",
            organization_id,
            group_id: id,
          }}
        />
      </View>
    </Box>
  );
};

const styles = StyleSheet.create({
  root: { padding: 0 },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 24,
    paddingHorizontal: 12,
  },
  body: {
    flex: 1,
    paddingTop: 8,
    paddingBottom: 16,
    paddingHorizontal: 24,
    borderTopColor: "transparent",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
  },
});
