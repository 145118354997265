import { StyleSheet } from "react-native";

import { Link } from "@smartrent/ui";

import { buildUrl } from "@/utils";

interface UsersTableLinkProps {
  search?: Record<string, string | number | null>;
}
export const UsersTableLink = ({ search }: UsersTableLinkProps) => {
  // link for page with users table
  const href = buildUrl({
    pathname: "users",
    search, // set query param/s to set the table filter/s
  }).toString();

  return (
    <Link type="bodyLargeSemibold" style={styles.root} href={href}>
      {" "}
      VIEW USER TABLE
    </Link>
  );
};

const styles = StyleSheet.create({
  root: {
    textDecorationThickness: "from-font",
    fontSize: 16,
    paddingBottom: 16,
  },
});
