import { Panel, PanelBody, PanelHeader, Typography } from "@smartrent/ui";

import { UnitJobList } from "./UnitJobList";

export default function UnitJobsCard() {
  return (
    <Panel>
      <PanelHeader>
        <Typography type="title2">Install Status</Typography>
      </PanelHeader>
      <PanelBody>
        <UnitJobList height={450} />
      </PanelBody>
    </Panel>
  );
}
