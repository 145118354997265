import { useCallback, useMemo } from "react";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  Chip,
  ConfirmDialog,
  HStack,
  Tooltip,
  Typography,
  useTheme,
  useToast,
  VStack,
} from "@smartrent/ui";

import { ExclamationCircleSolid, InformationSolid } from "@smartrent/icons";

import { useModalState } from "@smartrent/hooks";

import { completeSowFn, SOW_QUERY_KEY } from "@/api";

import {
  ScopeOfWorkDeviceProps,
  ScopeOfWorkProps,
  ScopeOfWorkStatus,
} from "@/types";

import { SalesforceIcon } from "@/common/icons/Salesforce";

import { formatDateToBeHumanReadable } from "@/utils";

import { useUser } from "@/layout/Context";

import { useScopeOfWorkContext } from "../provider/ScopeOfWorkContext";
import { useSowSyncingStatusHook } from "../provider/useSowSyncingStatusHook";

interface CompleteSOWMutateFnProps extends ScopeOfWorkProps {
  productIds: ScopeOfWorkDeviceProps["sf_product_id"][];
}
export const SowCompleteButton: React.VFC = () => {
  const { colors } = useTheme();
  const setToast = useToast();
  const queryClient = useQueryClient();
  const { visible, onOpen, onClose } = useModalState();
  const { permissions } = useUser();

  // USE SOW CONTEXT
  const {
    scopeOfWorkQuery: { data },
    scopeOfWorkQueryKey,
    parCompleteErrorsQuery: { data: dataRedisError, dataUpdatedAt },
  } = useScopeOfWorkContext();

  // triggers the reactQuery to refetch the status when we're syncing with Salesforce
  useSowSyncingStatusHook({
    status: data?.status,
    queryKey: scopeOfWorkQueryKey,
  });

  const completeSOWMutation = useMutation<
    ScopeOfWorkProps,
    AxiosError,
    CompleteSOWMutateFnProps
  >([SOW_QUERY_KEY, "complete-button"], completeSowFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(scopeOfWorkQueryKey);
      setToast({
        status: "success",
        title: "Sync has started!",
        message:
          "Please allow a few minutes while we sync data with Salesforce",
      });
    },
    onError: () => {
      queryClient.invalidateQueries(scopeOfWorkQueryKey);
      setToast({
        status: "error",
        message: `Complete failed.`,
      });
    },
    retry: 0,
  });

  // Build list of devices we will sync with salesforce
  const productIds = useMemo(
    () =>
      data?.scope_of_work_devices?.length
        ? data.scope_of_work_devices.reduce((acc, device) => {
            // for now we will use any device with a sf_product_id field defined
            if (device?.sf_product_id) {
              acc.push(device.sf_product_id);
            }
            return acc;
          }, [])
        : [],
    [data?.scope_of_work_devices]
  );

  const missingDeviceTypeFlag = useMemo(
    () =>
      data?.scope_of_work_devices?.length
        ? data.scope_of_work_devices.reduce((acc, device) => {
            if (!device?.sf_product_id) {
              return true;
            }
            return acc;
          }, false)
        : [],
    [data?.scope_of_work_devices]
  );

  const missingQuantityFlag = useMemo(
    () =>
      data?.scope_of_work_devices?.length
        ? data.scope_of_work_devices.reduce((acc, device) => {
            if (!device?.quantity) {
              return true;
            }
            return acc;
          }, false)
        : false,
    [data?.scope_of_work_devices]
  );

  const handleCompleteButtonOnPress = useCallback(
    async () => completeSOWMutation.mutateAsync({ productIds, ...data }),
    [completeSOWMutation, data, productIds]
  );

  const toolTipMessages = useMemo(() => {
    const messages = [];
    if (data?.status === ScopeOfWorkStatus.Syncing) {
      messages.push(
        `It may take a few moments for data to sync with Salesforce, please wait for a few minutes.`
      );
      messages.push(`,Try refreshing the page, to refresh the status`);
    }
    if (
      data?.status === ScopeOfWorkStatus.Draft ||
      data?.status === ScopeOfWorkStatus.ReadyForReview
    ) {
      messages.push(
        `Unable to complete report while it is in '${
          data?.status || "unknown"
        }' status.`
      );
    }

    if (missingDeviceTypeFlag) {
      messages.push("At least 1 device is missing a valid device type.");
    }
    if (missingQuantityFlag) {
      messages.push("At least 1 device is missing a quantity.");
    }
    if (!data?.sf_opportunity_id) {
      messages.push("There needs to an opportunity assigned to this SOW.");
    }
    if (
      !data?.scope_of_work_detail?.units_count ||
      !data?.scope_of_work_detail?.cell_quality
    ) {
      messages.push(
        "Unit Count and Cell Quality must be saved in the property section."
      );
    }

    return messages.map((message, index) => `${index + 1}. ${message}`);
  }, [
    data?.status,
    data?.sf_opportunity_id,
    data?.scope_of_work_detail?.units_count,
    data?.scope_of_work_detail?.cell_quality,
    missingDeviceTypeFlag,
    missingQuantityFlag,
  ]);

  const toolTipErrorMessage = useMemo(() => {
    if (
      completeSOWMutation.isLoading ||
      data?.status !== ScopeOfWorkStatus.Error
    ) {
      return null;
    }

    if (permissions?.debug_sow && dataRedisError && dataUpdatedAt) {
      const date = dataRedisError?.inserted_at;
      const insertedAtHumanReadable = date
        ? formatDateToBeHumanReadable({
            date,
            formatOpts: { includeSeconds: true },
          })
        : null;

      // try to add human readable timestamp to show when error occurred
      const label = insertedAtHumanReadable
        ? `Sync Error\n${insertedAtHumanReadable} ago`
        : "Sync Error";

      // return the entire error in JSON format
      const title = JSON.stringify(dataRedisError, null, 2).replaceAll(
        /\\n/g,
        "\n"
      );

      return { label, title };
    }
    // return generic error message
    return {
      label: "Error",
      title:
        "Salesforce sync encountered an error, our engineering team has been notified.",
    };
  }, [
    completeSOWMutation.isLoading,
    data?.status,
    dataRedisError,
    permissions?.debug_sow,
    dataUpdatedAt,
  ]);

  // figure out how many lines the tooltip needs to display
  const numberOfLines = useMemo(() => {
    if (toolTipErrorMessage?.title) {
      const newLineMatches = toolTipErrorMessage.title.match(/\n/g) || [];
      return newLineMatches?.length ? newLineMatches.length + 2 : 1;
    }
    return null;
  }, [toolTipErrorMessage]);

  const isButtonDisabled = useMemo(
    () =>
      completeSOWMutation.isLoading ||
      !data?.sf_opportunity_id ||
      !data?.scope_of_work_devices?.length ||
      data?.status === ScopeOfWorkStatus.Draft ||
      data?.status === ScopeOfWorkStatus.ReadyForReview ||
      data?.status === ScopeOfWorkStatus.Syncing ||
      !data?.scope_of_work_detail?.units_count ||
      !data?.scope_of_work_detail?.cell_quality ||
      !productIds?.length ||
      missingQuantityFlag,
    [
      completeSOWMutation.isLoading,
      data?.scope_of_work_devices?.length,
      data?.sf_opportunity_id,
      data?.status,
      data?.scope_of_work_detail?.units_count,
      data?.scope_of_work_detail?.cell_quality,
      productIds?.length,
      missingQuantityFlag,
    ]
  );

  return (
    <VStack spacing={16}>
      <HStack spacing={8} align="center">
        <Chip
          color="primary"
          variation="outlined"
          size="small"
          iconLeft={(props) => (
            <SalesforceIcon disabled={isButtonDisabled} {...props} />
          )}
          onPress={onOpen}
          disabled={isButtonDisabled}
        >
          {completeSOWMutation.isLoading
            ? "Initiating Salesforce Sync.."
            : "Complete SOW"}
        </Chip>

        {toolTipErrorMessage && data?.status === ScopeOfWorkStatus.Error ? (
          <Tooltip
            name="error_tooltip"
            title={toolTipErrorMessage.title}
            placement="bottom"
            numberOfLines={numberOfLines}
          >
            <View style={styles.flexRowText}>
              <ExclamationCircleSolid color={colors.error} size={16} />
              <Typography type="captionSmall" color="error">
                {toolTipErrorMessage.label}
              </Typography>
            </View>
          </Tooltip>
        ) : toolTipMessages?.length ? (
          <Tooltip
            name="invalid_info_tooltip"
            title={toolTipMessages.join("\n\n")}
            placement="bottom"
            numberOfLines={toolTipMessages.length * 2}
          >
            <View style={styles.flexRow}>
              <InformationSolid color={colors.gray400} size={16} />
            </View>
          </Tooltip>
        ) : null}

        <ConfirmDialog
          title="Are you sure?"
          description={
            <Typography type="bodyLarge" style={styles.confirmPrompt}>
              By completing this Scope of Work, you are confirming the client
              has acknowledged and agreed to the information displayed
            </Typography>
          }
          visible={visible}
          onClose={onClose}
          onConfirm={() => {
            handleCompleteButtonOnPress();
            onClose();
          }}
        />
      </HStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  flexRowText: {
    maxWidth: 90,
    flexWrap: "wrap",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  confirmPrompt: { textAlign: "center", marginVertical: 16 },
});
