import * as React from "react";
import { ListRenderItemInfo, StyleSheet, View } from "react-native";

import { StatusBadge, Typography } from "@smartrent/ui";

import { StatusBadgeDetailView } from "@/common/StatusBadgeDetailView";
import { ThirdPartyProviderSummaryItem } from "@/types";

export const OrganizationIntegrationsListItem: React.FC<
  React.PropsWithChildren<ListRenderItemInfo<ThirdPartyProviderSummaryItem>>
> = ({
  item: { provider_name, enabledCount, disabledCount, nullCount },
  index,
}) => {
  return (
    <View style={styles.container}>
      {/* SHOW INTERGRATION NAME */}
      <View style={styles.flexCol}>
        <Typography>
          {++index}. {provider_name}
        </Typography>
      </View>
      <View style={styles.flexCol}>
        <View style={styles.flexRow}>
          {enabledCount > 0 ? (
            <View style={styles.statusBadgeContainer}>
              <StatusBadgeDetailView
                condition={true}
                style={styles.statusBadge}
              >
                {`Enabled: ${enabledCount}`}
              </StatusBadgeDetailView>
            </View>
          ) : null}
          {disabledCount > 0 ? (
            <View style={styles.statusBadgeContainer}>
              <StatusBadgeDetailView
                condition={false}
                style={styles.statusBadge}
              >
                {`Disabled: ${disabledCount}`}
              </StatusBadgeDetailView>
            </View>
          ) : null}
          {nullCount > 0 ? (
            <View style={styles.statusBadgeContainer}>
              <StatusBadge
                status="error"
                variation="outlined"
                style={styles.statusBadge}
                size="semibold.title.five"
              >
                {`UNKNOWN: ${nullCount}`}
              </StatusBadge>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 8,
    flexWrap: "wrap",
  },

  flexRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexCol: {
    flexDirection: "column",
  },

  statusBadgeContainer: {
    flexDirection: "column",
    paddingHorizontal: 2,
    minWidth: 120,
  },
  statusBadge: { maxWidth: 120, marginLeft: 0 },
});
