import * as React from "react";
import { View, StyleSheet, TextInput } from "react-native";
import { Button, HStack, Typography, VStack, useTheme } from "@smartrent/ui";
import { useClipboard } from "@smartrent/hooks";
import { InformationSolid, Invisible, VisibleSolid } from "@smartrent/icons";

import { ScopeOfWorkProps } from "@/types";
import { cmwScopeOfWorkExportLink } from "@/utils/links";

interface SowExportDetailsProps {
  sow?: ScopeOfWorkProps;
}

export const SowExportDetails: React.FC<
  React.PropsWithChildren<SowExportDetailsProps>
> = ({ sow }) => {
  const { colors, spacing, rounding } = useTheme();

  const [passcodeHidden, setPasscodeHidden] = React.useState(true);

  const { ClipboardIcon: LinkClipboardIcon, onCopy: linkOnCopy } =
    useClipboard();
  const { ClipboardIcon: PasscodeClipboardIcon, onCopy: passcodeOnCopy } =
    useClipboard();

  if (!sow) {
    return null;
  }

  if (!sow.passcode) {
    return (
      <View
        style={[
          styles.section,
          {
            backgroundColor: colors.gray050,
            borderRadius: rounding.md,
            padding: spacing.sm,
            borderColor: colors.gray300,
          },
        ]}
      >
        <VStack spacing={8} width="100%">
          <Typography type="bodySemibold">Export SOW</Typography>
          <HStack spacing={4} align="center">
            <InformationSolid size={16} color={colors.helperText} />

            <Typography type="bodySmallSemibold" color="helperText">
              URL and passcode will be available after the SOW is submitted.
            </Typography>
          </HStack>
        </VStack>
      </View>
    );
  }

  const link = cmwScopeOfWorkExportLink(sow.uuid);

  return (
    <View
      style={[
        styles.section,
        {
          backgroundColor: colors.gray050,
          borderRadius: rounding.md,
          padding: spacing.sm,
          borderColor: colors.gray300,
        },
      ]}
    >
      <VStack spacing={8} width="100%">
        <Typography type="bodySemibold">Export SOW</Typography>

        <View style={styles.exportLine}>
          <View
            style={[
              styles.inputContainer,
              {
                backgroundColor: colors.gray100,
                borderRadius: rounding.md,
              },
            ]}
          >
            <TextInput style={styles.input} value={link} editable={false} />
          </View>
          <Button
            variation="plain"
            size="x-small"
            color="primary"
            style={styles.copyButton}
            onPress={() => {
              linkOnCopy(link);
            }}
          >
            <LinkClipboardIcon color={colors.primary} />
          </Button>
        </View>

        <View style={styles.exportLine}>
          <View
            style={[
              styles.inputContainer,
              {
                backgroundColor: colors.gray100,
                borderRadius: rounding.md,
              },
            ]}
          >
            <TextInput
              style={styles.input}
              value={sow.passcode}
              secureTextEntry={passcodeHidden}
              editable={false}
            />
            <Button
              variation="plain"
              size="x-small"
              color="primary"
              style={styles.copyButton}
              onPress={() => {
                setPasscodeHidden(!passcodeHidden);
              }}
            >
              {passcodeHidden ? (
                <Invisible color={colors.labelText} />
              ) : (
                <VisibleSolid color={colors.labelText} />
              )}
            </Button>
          </View>
          <Button
            variation="plain"
            size="x-small"
            color="primary"
            style={styles.copyButton}
            onPress={() => {
              passcodeOnCopy(sow.passcode || "");
            }}
          >
            <PasscodeClipboardIcon color={colors.primary} />
          </Button>
        </View>
      </VStack>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderWidth: 1,
  },
  exportLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
    flexGrow: 1,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 32,
    paddingHorizontal: 8,
    flexGrow: 1,
    marginRight: 8,
  },
  input: {
    height: 48,
    flex: 1,
  },
  copyButton: {
    height: 32,
    paddingHorizontal: 4,
  },
});
