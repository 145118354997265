import { useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/lib/hooks";

import { GroupProps, OrganizationProps } from "@/types";

export const selectQueryPropsGroups = ({
  organizationId,
}: {
  organizationId?: OrganizationProps["id"];
}) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSelectQuery<PaginatedResponse<GroupProps>>(
    (inputValue) => ["groups", inputValue] as const,
    function fetch({ queryKey, pageParam = 1 }) {
      const [, inputValue] = queryKey;

      return instance
        .get(`/groups`, {
          params: {
            marketing_name: inputValue ? inputValue : undefined,
            organization_id: organizationId,
            active: true,
            page: pageParam,
            limit: 25,
          },
        })
        .then((response) => response.data);
    },
    { enabled: organizationId ? true : false },
    {
      inputDebounceInterval: 250,
    }
  );
