import * as React from "react";

import { Typography } from "@smartrent/ui";

export const ProvidersListEmpty: React.FC = () => {
  return (
    <Typography type="caption" color="textSecondary">
      No providers
    </Typography>
  );
};
