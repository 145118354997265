import {
  GarageDoorOpen,
  Lock,
  Notification,
  OutletWithPlug,
  QuestionCircleBorder,
  SecurityCamera,
  Signal,
  Slider,
  Thermostat,
  VolumeHighSolid,
  WindowShadeOpen,
} from "@smartrent/icons";

export const DeviceTypeIcon = ({ type }) => {
  switch (type) {
    case "entry_control":
      return <Lock />;
    case "thermostat":
      return <Thermostat />;
    case "switch_multilevel":
      return <Slider />;
    case "switch_binary":
      return <OutletWithPlug />;
    case "sensor_notification":
      return <Notification />;
    case "sensor_alarm":
      return <SecurityCamera />;
    case "shade_control":
      return <WindowShadeOpen />;
    case "repeater_slave":
      return <Signal />;
    case "garage_door_controller":
      return <GarageDoorOpen />;
    case "siren":
      return <VolumeHighSolid />;
    default:
      return <QuestionCircleBorder />;
  }
};
