import { useMemo, useState } from "react";
import { StyleSheet, TouchableOpacity, View, Text } from "react-native";
import { chunk } from "lodash-es";
import { HStack, VStack } from "@smartrent/ui";

import { HubPrepVirtualBox } from "@/pages/hub-prep/HubPrepVirtualBox";
import { HubPrepStats } from "@/pages/hub-prep/HubPrepStats";
import HubPrepLog from "@/pages/hub-prep/HubPrepLog";
import { HubPrepLog as HubPrepLogProp } from "@/types";

/**
 * The Procurement team always lines hubs up in rows of 5, in order of the scanned serials.
 *
 * We want to chunk `serials`, not `hubPrepLogs` here because if any fail to start, we cannot modify/drop
 * the order of the serials as they are setup physically in the Hub Prep area of the warehouse.
 *
 * We will use this virtual representation of the physical Hub Prep area to indicate hubs that are prepped and done
 * and others that are problematic
 */
export function HubPrepVirtualGrid({
  hubPrepLogs,
  serials,
}: {
  hubPrepLogs: undefined | HubPrepLogProp[];
  serials: string[];
}) {
  const [selectedSerial, setSelectedSerial] = useState<string | null>(null);
  const groupedSerials = useMemo(() => {
    const CHUNK_AMOUNT = 5;
    return chunk(serials, CHUNK_AMOUNT);
  }, [serials]);

  const statuses = useMemo(() => {
    return hubPrepLogs?.length ? hubPrepLogs.map((hpl) => hpl.status) : [];
  }, [hubPrepLogs]);

  if (!(serials?.length && hubPrepLogs?.length)) {
    return null;
  }

  const hubPrepLog = selectedSerial
    ? hubPrepLogs.find((hpl) => hpl.serial == selectedSerial)
    : null;

  return (
    <View style={styles.container}>
      <HubPrepStats statuses={statuses} total={serials?.length} />
      <View style={styles.serialAndLogContainer}>
        <HStack spacing={8}>
          {groupedSerials.map((chunkOfSerials, idx) => {
            return (
              <VStack key={idx} spacing={8}>
                <View style={styles.rowHeader}>
                  <Text style={styles.textAlignCenter}>Row {idx + 1}</Text>
                </View>
                {chunkOfSerials.map((serial) => {
                  const hubPrepLog = hubPrepLogs.find(
                    (hpl) => hpl.serial === serial
                  );
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        selectedSerial === serial
                          ? setSelectedSerial(null)
                          : setSelectedSerial(serial);
                      }}
                      key={serial}
                    >
                      <HubPrepVirtualBox
                        key={serial}
                        serial={serial}
                        status={
                          /disabled_/gi.test(serial)
                            ? "Disabled"
                            : hubPrepLog?.status
                        }
                      />
                    </TouchableOpacity>
                  );
                })}
              </VStack>
            );
          })}
        </HStack>

        {selectedSerial && hubPrepLog ? (
          <HubPrepLog hubPrepLog={hubPrepLog} />
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 16,
    flexWrap: "wrap",
  },
  serialAndLogContainer: {
    marginBottom: 16,
    flex: 1,
    flexWrap: "wrap",
  },
  rowHeader: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  textAlignCenter: { textAlign: "center" },
});
