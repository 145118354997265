import { createAxiosMutation, createAxiosQuery } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";

import {
  HubAccount,
  HubProps,
  HubRequest,
  HubsQueryParams,
  HubRefurbishHistoryProps,
  HubDetails,
  HubLogsQueryParams,
  HubLogProps,
} from "@/types";

export const HUB_REFURBISH_HISTORY_QUERY_KEY = "hub-refurbish-history";

export interface RefurbishHistoryResponse {
  records: HubRefurbishHistoryProps[];
}

export interface CellStrengthQuery {
  cellStrength: string | null;
}

export const useRefurbishHistoryQuery = createAxiosQuery<
  Partial<HubProps>,
  RefurbishHistoryResponse
>("hub-refurbish-history", async (hub) => {
  return instance.get(`/hubs/${hub?.serial}/refurbish-history`);
});

export const useHub = createAxiosQuery(
  "get-hub",
  async ({ serial }: HubsQueryParams) => {
    return instance.get<HubRequest & HubDetails>(`/hubs/${serial}`);
  }
);

export const useHubLogs = createAxiosQuery(
  "hub-logs-query",
  async ({ remote_id, serial, type = "zipato" }: HubLogsQueryParams) => {
    if (type === "zipato") {
      return instance.get<HubLogProps[]>("/zipato/message-logs", {
        params: { remote_id, serial },
      });
    } else {
      return instance.get<HubLogProps[]>(`/hubs/${serial}/logs`);
    }
  }
);

export const useCellStrengthQuery = createAxiosQuery(
  "hub-cell-strength",
  async ({ serial }: HubsQueryParams) =>
    instance.get<CellStrengthQuery>(`/hubs/${serial}/cell-strength`)
);

export const useHubAccountQuery = createAxiosQuery(
  "hub-account",
  async ({ serial }: HubsQueryParams) =>
    instance.get<HubAccount>(`/hubs/${serial}/hub-account`)
);

export const useHubPrepLogQuery = createAxiosQuery(
  "hub-prep-logs",
  async ({ serial }: HubsQueryParams) =>
    instance.get(`/hubs/${serial}/hub-prep-log`)
);

// CMW Internal API only requires a unit ID to reboot a hub.
// But I feel like passing a serial is more future proof, so we'll do both for now.
// Also the internal api route is deprecated because it only works with Zipato hubs.
// So who knows what the future actually holds for this action.
export const useHubRebootQuery = createAxiosMutation(
  async ({ serial, unitId }: HubsQueryParams) =>
    instance.patch(`/hubs/${serial}/reboot`, { unitId }),
  {
    successToast: () => ({
      message: "Rebooting hub!",
    }),
    errorToast: () => ({
      message: "Unable to reboot hub.",
    }),
  }
);

export const useHubOfflineMutation = createAxiosMutation(
  async ({ serial }) => instance.post(`/hubs/${serial}/offline`),
  {
    successToast: () => ({
      message: "Bringing hub online!",
    }),
    errorToast: () => ({
      message: "Unable to bring hub online.",
    }),
  }
);

export const useHubBoxSyncQuery = createAxiosMutation(
  async ({ serial, unitId }: HubsQueryParams) =>
    instance.post(`/hubs/${serial}/box-sync`, { unitId }),
  {
    successToast: () => ({
      message: "Box sync started!",
    }),
    errorToast: () => ({
      message: "Unable to to start box sync.",
    }),
  }
);

type HubMessageLogsRequest = {
  records: { message: string; timestamp: string }[];
  total_records: number;
};
export const useLatestHubMessagesQuery = createAxiosQuery(
  "hub-latest-messages",
  async ({ serial }: HubsQueryParams) =>
    instance.get<HubMessageLogsRequest>(`/hubs/${serial}/latest-messages`)
);

type HubRefurbishHistoryPropsResponse = {
  records: HubRefurbishHistoryProps[];
};
export const useHubRefurbishHistoryQuery = createAxiosQuery<
  Partial<HubProps>,
  HubRefurbishHistoryPropsResponse
>("hub-refurbish-history", async (hub) =>
  instance.get(`/hubs/${hub?.serial}/refurbish-history`)
);

export const useResetZWaveMutation = createAxiosMutation(
  async ({ serial, unitId }: HubsQueryParams) =>
    instance.patch(`/hubs/${serial}/reset-zwave`, { unitId }),
  {
    successToast: () => ({ message: "Resetting Z-Wave" }),
    errorToast: () => ({ message: "Failed to reset Z-Wave" }),
  }
);

export const useHealNetworkMutation = createAxiosMutation(
  async ({ serial, unitId }: HubsQueryParams) =>
    instance.patch(`/hubs/${serial}/heal-network`, { unitId }),
  {
    successToast: () => ({ message: "Healing Network" }),
    errorToast: () => ({ message: "Failed to heal network" }),
  }
);

export const useHubUpgradeFirmware = createAxiosMutation(
  async ({ serial }: HubsQueryParams) =>
    instance.post(`/hubs/${serial}/upgrade`),
  {
    successToast: () => ({
      message:
        "Firmware upgrade started. Please wait up to ten minutes before performing additional actions.",
    }),
    errorToast: () => ({
      message: "Unable to upgrade hub firmware.",
    }),
  }
);

interface SyncCellStatsOptions {
  hubSerial: string;
}

export const SyncCellStatsMutation = createAxiosMutation(
  async ({ hubSerial }: SyncCellStatsOptions) => {
    return instance.post(`/hubs/${hubSerial}/sync-cell-stats`);
  },
  {
    successToast: () => ({
      message:
        "Successfully started to sync cell stats. This process can take 2 minutes or more depending on network connection. Refresh to see the updated stats.",
    }),
    errorToast: (err) => ({
      message: "Unable to run sync cell stats.",
    }),
  }
);

export interface LogResponse {
  changed_at: Date | string;
  code: string | number;
  type: string;
  user: string;
}

export interface LogType {
  records: LogResponse[];
  total_pages: number;
  total_records: number;
}
export interface BackupCodesLogsResponse {
  installer_codes: LogType;
  resident_codes: LogType;
  property_manager_codes: LogType;
}

export const useBackupCodeLogsQuery = createAxiosQuery(
  "backup-code-logs",
  async ({ unitId }: BackupCodesParams) =>
    instance.get<BackupCodesLogsResponse>(`/hubs/${unitId}/backup-code-logs`)
);

export interface BackupCodesParams {
  unitId: string | number;
}

export interface CodeType {
  code: string | number;
  type: string;
  id: number;
}

export interface BackupCodesResponse {
  has_locks_supporting_access_codes: boolean;
  locks: any[];
  installer_code: CodeType;
  property_manager_code: CodeType;
  resident_code: CodeType;
}

export const useGetBackupCodesQuery = createAxiosQuery(
  "hub-backup-codes",
  async ({ unitId }: BackupCodesParams) =>
    instance.get<BackupCodesResponse>(`/hubs/${unitId}/backup-codes`)
);

export interface ResetBackupAccessCodeOptions {
  unitId: number | string;
  codeId: number | string;
}

export interface ResetBackupAccessCodeResponse {
  code: number | string;
}

export const useResetBackupCodeMutation = createAxiosMutation(
  async ({ unitId, codeId }: ResetBackupAccessCodeOptions) => {
    return instance.post<ResetBackupAccessCodeOptions>(
      `/hubs/${unitId}/reset-backup-code`,
      { codeId }
    );
  },
  {
    successToast: null,
    errorToast: null,
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({ queryKey: ["hub-backup-codes"] });
    },
  }
);

export interface HubScripts {
  scripts: HubCommands[];
}

export interface HubCommands {
  id: string;
  name: string;
  text: string;
}

export const useHubCommandsQuery = createAxiosQuery(
  "hub-commands",
  async ({ serial }: HubsQueryParams) => {
    return instance.get<HubScripts>(`/hubs/${serial}/commands`);
  }
);

export interface HubCommandResponse {
  [key: string]: string;
}

export const useHubCommandMutation = createAxiosMutation(
  async ({ serial, command }: { serial: string; command: string | number }) =>
    instance.post<HubCommandResponse>(`/hubs/${serial}/commands/${command}`),
  {
    successToast: () => ({
      message: "Command sent!",
    }),
    errorToast: () => ({
      message: "Unable to send command.",
    }),
  }
);
