import { useCallback } from "react";
import {
  useQueryClient,
  UseQueryOptions,
  useQuery,
} from "@tanstack/react-query";

import { useQueryCompat } from "@smartrent/hooks";

import { createAxiosQuery, createAxiosMutation } from "@/hooks/react-query";

import { instance } from "@/lib/hooks";
import {
  GroupProps,
  GroupLockConfigProps,
  UserProps,
  DeviceStatsProps,
} from "@/types";

import { HubHealthReportProps } from "@/pages/group/cards/group-details/HubHealthReport";

interface RotateVacantAccessCodeBody {
  group_id: GroupProps["id"];
  user_id: UserProps["id"];
}

export const useRotateVacantAccessCodeMutation = createAxiosMutation(
  async ({ group_id, user_id }: RotateVacantAccessCodeBody) => {
    return instance.post(
      `/groups/${group_id}/rotate-vacant-access-code`,
      null,
      { params: { user_id } }
    );
  },
  {
    successToast: () => ({
      message: "Vacant Access Code rotation is now processing.",
    }),
    errorToast: (data) => {
      if (data?.response?.status === 422) {
        if (
          data?.response?.data?.errors?.[0]?.code ===
          "unable_to_rotate_vacant_access_code"
        ) {
          return {
            message: `Access code was not rotated. You may need to wait a day before rotating the access code again.`,
          };
        }
        if (
          data?.response?.data?.errors?.[0]?.code ===
          "unable_to_fetch_vacant_access_code"
        ) {
          return {
            message: `Unable to fetch vacant access code. This user may be missing their vacant access code for this group.`,
          };
        }
      }

      return {
        message: `Something went wrong. Please contact engineering.`,
      };
    },
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({
        queryKey: ["rotate-vacant-access-code"],
      });
    },
  }
);

export const useHubHealth = (groupId: string | number) => {
  return useQuery(
    ["use-group-hub-health", groupId],
    async () => {
      const { data } = await instance.get<HubHealthReportProps>(
        `/groups/${groupId}/iot-health/hub-health-report`
      );
      return data;
    },
    { enabled: !!groupId }
  );
};

export const useGroupQuery = (
  groupId: number,
  options?: UseQueryOptions<GroupProps, unknown>
) =>
  useQueryCompat(
    ["group", groupId],
    async (key: "group", groupId: number) => {
      const {
        data: { group },
      } = await instance.get<{ group: GroupProps }>(`/groups/${groupId}`); // RESTful

      return group;
    },
    options
  );

export const useInvalidateGroup = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (groupId: number) => queryClient.invalidateQueries(["group", groupId]),
    [queryClient]
  );
};

export interface WhiteLabelConfigResponse {
  id: number;
  group_id: number;
  enabled: boolean;
  logo_url: string | null;
}
export interface WhiteLabelConfigOptions {
  groupId: number;
}

export const useWhiteLabelConfigQuery = createAxiosQuery(
  "white-label-config",
  async ({ groupId }: WhiteLabelConfigOptions) => {
    return instance.get<WhiteLabelConfigResponse>(
      `/groups/${groupId}/white-label-config`
    );
  }
);

export interface UpdateWhiteLabelConfigPayload {
  enabled: boolean;
}

export interface UpdateWhiteLabelConfigOptions {
  groupId: string | number;
  payload: UpdateWhiteLabelConfigPayload;
}

export const useUpdateWhiteLabelConfigMutation = createAxiosMutation(
  async ({ groupId, payload }: UpdateWhiteLabelConfigOptions) => {
    return instance.patch<UpdateWhiteLabelConfigPayload>(
      `/groups/${groupId}/white-label-config`,
      payload
    );
  },
  {
    successToast: () => ({
      message: "White Label Configuration successfully updated.",
    }),
    errorToast: () => ({
      message: `Something went wrong. Please contact engineering.`,
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({ queryKey: ["white-label-config"] });
    },
  }
);

export interface GroupLockSettingsOptions {
  groupId: number;
}
export interface GroupLockSettingsResponse {
  data: GroupLockConfigProps;
}

export const useGroupLockSettingsQuery = createAxiosQuery(
  "group-lock-settings",
  async ({ groupId }: GroupLockSettingsOptions) => {
    return instance.get<GroupLockSettingsResponse>(
      `/groups/${groupId}/lock-settings`
    );
  }
);

export interface CommunityWifiUpdateNetworkPayload {
  enabled: boolean;
}

export interface CommunityWifiUpdateNetworkOptions {
  groupId: string | number;
}

export const useCommunityWifiUpdateNetworkMutation = createAxiosMutation(
  async ({ groupId }: CommunityWifiUpdateNetworkOptions) => {
    return instance.post<CommunityWifiUpdateNetworkPayload>(
      `/groups/${groupId}/community-wifi-update-network`
    );
  },
  {
    successToast: () => ({
      message:
        "WiFi Device Network is being applied to hubs. Please wait at least 20 minutes before attempting to run again.",
    }),
    errorToast: () => ({
      message: `Something went wrong. Please contact engineering.`,
    }),
    onSuccess: (queryClient) => {
      queryClient.invalidateQueries({ queryKey: ["update-network"] });
    },
  }
);

export const useGroupDeviceBreakdown = createAxiosQuery(
  "group-device-breakdown",
  async (groupId: number | string) => {
    return instance.get<DeviceStatsProps[]>(
      `/groups/${groupId}/device-breakdown`
    );
  }
);

interface LinkSalesforceToGroupProps {
  group_id: number;
  salesforce_property_id: string;
}

export const useLinkSalesforceToGroupMutation = createAxiosMutation(
  async ({ group_id, salesforce_property_id }: LinkSalesforceToGroupProps) => {
    return await instance.post(`/groups/${group_id}/link-salesforce`, {
      salesforce_property_id,
    });
  },
  {
    onSuccess(queryClient) {
      queryClient.invalidateQueries(["salesforce-property"]);
    },
    successToast(_, { salesforce_property_id }) {
      if (!salesforce_property_id) {
        return {
          message: `Group unlinked from Salesforce Property.`,
        };
      }
      return {
        message: `Group linked to Salesforce Property.`,
      };
    },
    errorToast(_, { salesforce_property_id }) {
      if (!salesforce_property_id) {
        return {
          message: `Failed to unlink group from Salesforce Property.`,
        };
      }
      return {
        message: `Failed to link group to Salesforce Property.`,
      };
    },
  }
);

interface GroupCommunityWifiProps {
  enabled: boolean;
  hostname: string;
  ssid: string;
}
export const useGroupCommunityWifi = createAxiosQuery(
  "group-community-wifi",
  async (groupId: number | string) => {
    return instance.get<GroupCommunityWifiProps>(
      `/groups/${groupId}/managed-wifi`
    );
  }
);

export interface GroupsManagedWifiAccessPointsResponse {
  records: ManagedWifiAccessPoints[];
  current_page: number;
  total_pages: number;
  total_records: number;
}

export interface ManagedWifiAccessPoints {
  id: string;
  name: string;
  last_seen_at: string;
  client_count: string;
  connection_state: string;
  ip: string;
  latitude: string;
  longitude: string;
  mac: string;
  note: string;
  online: boolean;
  serial_number: string;
  uptime: string;
  zone: string;
  model: string;
  created_at: string;
  updated_at: string;
}

export interface RebootManagedWifiAccessPoint {
  group_id: string | number;
  access_point_id: string;
}

export const useRebootManagedWifiAccessPointMutation = createAxiosMutation(
  async ({ group_id, access_point_id }: RebootManagedWifiAccessPoint) => {
    return await instance.post(
      `/groups/${group_id}/managed-wifi/access-points/${access_point_id}/reboot`
    );
  },
  {
    successToast: () => ({
      message: "Successfully rebooted access point.",
    }),
    errorToast: () => ({
      message: `Unable to reboot access point.`,
    }),
  }
);
