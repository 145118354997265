import moment from "moment";
import { startCase } from "lodash-es";
import { HStack, Typography } from "@smartrent/ui";

import Helpers from "@/lib/helpers";

import { HubOnlineCircle } from "./HubOnlineCircle";

function TransformedValue({ label, value }) {
  if (label === "online") {
    return <HubOnlineCircle isOnline={value} />;
  }

  let transformedValue;
  if (value === true || value === "true") {
    transformedValue = "Yes";
  } else if (value === false || value === "false") {
    transformedValue = "No";
  } else if (Array.isArray(value)) {
    transformedValue = JSON.stringify(value, null, 2);
  } else if (
    label !== "firmware" &&
    moment(value).toString() !== "Invalid date" &&
    moment(value).year() > 2017 // firmware and GMT offset can be parsed as a date
  ) {
    transformedValue = Helpers.formatDate(value);
  } else if (
    label === "onlineTimestamp" ||
    label === "bootDate" ||
    label === "lastDeviceAdded"
  ) {
    transformedValue = moment(Number(value)).format("L LT");
  } else {
    transformedValue = value || "n/a";
  }

  return <Typography>{transformedValue}</Typography>;
}

export function BoxOrRedisValue({
  value,
  label,
}: {
  value: any;
  label: string;
}) {
  // Unused and they clutter UI
  if (["serverId", "handlerUuid", "sessionId", "serial"].includes(label)) {
    return null;
  }

  return (
    <HStack align="center">
      <div className="u-text-gray-600 u-mright4">{startCase(label)}:</div>
      <TransformedValue value={value} label={label} />
    </HStack>
  );
}

export default BoxOrRedisValue;
