import * as React from "react";

import { useUrlPagination, UrlPaginationOptions } from "@/hooks/url-pagination";

import { Paginator } from "./Paginator";

export const UrlPaginator = React.memo(
  ({
    totalPages,
    pageQueryKey,
    redirectIfOutOfRange,
  }: UrlPaginationOptions) => {
    const { page, setPage } = useUrlPagination({
      totalPages,
      pageQueryKey,
      redirectIfOutOfRange,
    });

    return (
      <Paginator page={page} totalPages={totalPages} onPageChange={setPage} />
    );
  }
);
