import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import * as React from "react";

import { UseQueryResult } from "@tanstack/react-query";

import {
  UnitProps,
  GroupProps,
  GroupTourConfigProps,
  GroupLockConfigProps,
  GroupCommunityWifi,
  HubProps,
  OrganizationProps,
  DeviceProps,
  AccessControlDevice,
  ResidentProps,
  UserProps,
  AccessCode,
  WorkflowProps,
  TransferWorkflow,
  UnitNote,
  DeviceAttributeProps,
  HubDetails,
  HubRequest,
  RingDoorbellDevice,
} from "@/types";
import Context from "@/layout/Context";
import { useApi } from "@/lib/hooks";
import Layout from "@/layout/Layout";
import Alert from "@/common/Alert";
import { useCheckUnitsCommunityWifiStateQuery } from "@/api";
import { CheckHubsCommunityWifiStateProps } from "@/types/CheckHubsCommunityWifiStateProps";

export interface UnitContextState {
  unit: UnitProps;
  group: GroupProps;
  organization: OrganizationProps;
  groupTourConfig?: GroupTourConfigProps;
  groupLockConfig: GroupLockConfigProps;
  hub?: HubRequest & HubDetails;
  deletedHubs: HubProps[];
  devices: DeviceProps[];
  accessControlDevices: AccessControlDevice[];
  deletedAccessControlDevices: AccessControlDevice[];
  ringDoorbells: RingDoorbellDevice[];
  nonZwaveLocks: AccessControlDevice[];
  deletedDevices: DeviceProps[];
  residents: ResidentProps[];
  users: UserProps[];
  accessCodes: Record<number, AccessCode>;
  residentUsers: UserProps[];
  workflows: WorkflowProps[];
  transferWorkflow?: TransferWorkflow;
  unitNotes: UnitNote[];

  // TODO(someday): typings for the following
  deviceAttributes: DeviceAttributeProps[];
  hubConnectionHistory: any[];
  workOrders?: any[];

  CMW_BASE_URL: string;

  communityWifi: {
    isEnabled: boolean;
    isHubConnected: boolean;
    data: UseQueryResult<CheckHubsCommunityWifiStateProps>["data"];
    setRefetchCheckWifiAttemptIndex: React.Dispatch<
      React.SetStateAction<number>
    >;
  };
  residentUnitWifiConfiguration: {
    enabled: boolean;
    provider: string;
  };
  residentUnitWifiAccounts: any[];
}

interface UnitContext extends UnitContextState {
  refetchUnit: () => void;
  refetchWorkOrders: () => void;
}

const UnitCtx = createContext<UnitContext>(undefined);

export const useUnit = () => useContext(UnitCtx);

interface UnitProviderProps {
  id: number;
}

export const UnitProvider: React.FC<
  React.PropsWithChildren<UnitProviderProps>
> = ({ id, children }) => {
  const { user, setPageLoading } = useContext(Context);
  const [data, setData] = useState<any>();
  const [workOrders, setWorkOrders] = useState<any>();
  const [refetchCheckWifiAttemptIndex, setRefetchCheckWifiAttemptIndex] =
    useState(0);

  const { response, reFetch } = useApi({
    url: `/units/${id}`,
    trigger: [id],
  });

  useEffect(() => {
    if (response && response.data) {
      setPageLoading(false);
      setData(response.data);
    } else {
      setPageLoading(true);
    }
  }, [response, setPageLoading]);

  const workOrdersApi = useApi({
    url: `/units/${id}/work-orders`,
  });

  useEffect(() => {
    // reset work orders when unit changes
    setWorkOrders(undefined);
  }, [id]);

  useEffect(() => {
    if (
      workOrdersApi.response &&
      workOrdersApi.response.data &&
      workOrdersApi.response.data.workOrders
    ) {
      setPageLoading(false);
      setWorkOrders(workOrdersApi.response.data.workOrders);
    } else {
      setPageLoading(true);
    }
  }, [workOrdersApi.response, setPageLoading]);

  const isCommunityWifiEnabled = useMemo(
    () =>
      data?.group_community_wifi_config
        ? (data.group_community_wifi_config as GroupCommunityWifi).enabled
        : false,
    [data]
  );

  const refetchUpTo = useCallback(
    (nTimes: any) =>
      refetchCheckWifiAttemptIndex === 0 ||
      refetchCheckWifiAttemptIndex > nTimes
        ? false
        : 7500,
    [refetchCheckWifiAttemptIndex]
  );

  const checkUnitsCommunityWifiStateQuery =
    useCheckUnitsCommunityWifiStateQuery(
      { unitId: id },
      {
        enabled: Boolean(
          user.permissions.units && data?.hub && isCommunityWifiEnabled
        ),
        onSettled: (data) =>
          data?.connected_to_community_wifi
            ? setRefetchCheckWifiAttemptIndex(0)
            : setRefetchCheckWifiAttemptIndex(refetchCheckWifiAttemptIndex + 1), // only refetch when hub is not connected
        refetchInterval: refetchUpTo(5), // refetch up to 5 times max
        refetchIntervalInBackground: false,
      }
    );

  // In most cases, we just want to know 2 things:
  //  1. is WiFi Device Network enabled for the unit
  //  2. is unit's hub connected to WiFi Device Network
  const communityWifi = useMemo(
    () => ({
      isEnabled: isCommunityWifiEnabled,
      // assume hub is not connected until CMW says otherwise
      isHubConnected:
        checkUnitsCommunityWifiStateQuery.data?.connected_to_community_wifi ??
        false,
      data: checkUnitsCommunityWifiStateQuery.data,
      setRefetchCheckWifiAttemptIndex,
    }),
    [checkUnitsCommunityWifiStateQuery, isCommunityWifiEnabled]
  );

  const refetchUnit = useCallback(() => {
    reFetch();
    // TODO(someday): fix useAxios hook
  }, [id]);

  const refetchWorkOrders = useCallback(() => {
    workOrdersApi.reFetch();
    // TODO(someday): fix useAxios hook
  }, [id]);

  if (!(data && user)) {
    // loading indicator, if we return null we get no loading indicator and if we continue from here without data
    // we get a runtime error
    return <Layout>{null}</Layout>;
  }

  const {
    unit,
    group,
    hub,
    organization,
    devices,
    residents,
    users,
    workflows,
    access_control_devices: accessControlDevices,
    deleted_access_control_devices: deletedAccessControlDevices,
    ring_doorbells: ringDoorbells,
    non_zwave_locks: nonZwaveLocks,
    deleted_hubs: deletedHubs,
    group_tour_config: groupTourConfig,
    group_lock_config: groupLockConfig,
    deleted_devices: deletedDevices,
    device_attributes: deviceAttributes,
    access_codes: accessCodes,
    hub_connection_history: hubConnectionHistory,
    resident_users: residentUsers,
    unit_notes: unitNotes,
    transfer_workflow: transferWorkflow,
    resident_unit_wifi_configuration: residentUnitWifiConfiguration,
    resident_unit_wifi_accounts: residentUnitWifiAccounts,
    error,
    CMW_BASE_URL,
  } = data;

  if (error) {
    return <Alert type="error">{error}</Alert>;
  }

  if (!unit) {
    return <div>Unit not found</div>;
  }

  return (
    <UnitCtx.Provider
      value={{
        unit,
        group,
        hub,
        organization,
        devices,
        accessControlDevices,
        deletedAccessControlDevices,
        ringDoorbells,
        nonZwaveLocks,
        residents,
        users,
        workflows,
        deletedHubs,
        groupTourConfig,
        groupLockConfig,
        deletedDevices,
        deviceAttributes,
        accessCodes,
        hubConnectionHistory,
        residentUsers,
        unitNotes,
        transferWorkflow,
        workOrders,
        refetchUnit,
        refetchWorkOrders,
        CMW_BASE_URL,
        communityWifi,
        residentUnitWifiConfiguration,
        residentUnitWifiAccounts,
      }}
    >
      {children}
    </UnitCtx.Provider>
  );
};
