import * as React from "react";
import Tooltip from "rc-tooltip";

import { ExclamationCircleOutline } from "@smartrent/icons";

import Helpers from "@/lib/helpers";

interface HoverTextProps {
  hoverElement?: any;
  tooltipDirection?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
  tooltipText: string | React.ReactNode;
  width?: number;
  color?: string;
}

export default function HoverText({
  hoverElement,
  tooltipDirection = "left",
  tooltipText,
  width = 375,
  color,
}: HoverTextProps) {
  const id = `tooltip-${Math.floor(Math.random() * 1000) + 1}`;
  // rudimentary randomizer to get different id's if there are multiple
  // tooltips on a page for screen readers

  return (
    <Tooltip
      placement={tooltipDirection}
      trigger={["hover", "click"]}
      overlay={
        <div
          style={{
            width,
          }}
        >
          <div
            style={{
              fontSize: 12,
              textAlign: "center",
              lineHeight: 1.35,
              color: "white",
            }}
          >
            {tooltipText}
          </div>
        </div>
      }
      id={id}
    >
      <span aria-describedby={id}>
        {hoverElement ? (
          hoverElement
        ) : (
          <ExclamationCircleOutline
            size={16}
            color={color || Helpers.colors.green}
          />
        )}
      </span>
    </Tooltip>
  );
}
