import { utcToZonedTime } from "date-fns-tz";

// function to return a bool if the time is between operating hours depending on the timezone
export const isDecentHoursToCall = (timezone: string, date = new Date()) => {
  const zonedDate = utcToZonedTime(date, timezone);

  // Extract the current hour and minute
  const currentHour = zonedDate.getHours();
  const currentMinute = zonedDate.getMinutes();

  // if the current time is between 8am and 8pm
  // 8am is inclusive and 8pm is exclusive meaning 8:00am is included but 8:00pm is technically after 8pm
  const isBetweenDecentHours =
    currentHour >= 8 && currentHour < 20 && currentMinute >= 0;

  // Check if the current time is within operating hours
  return isBetweenDecentHours;
};
