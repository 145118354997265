import { useMemo } from "react";
import {
  HStack,
  OrderedList,
  OrderedListItem,
  Text,
  Tooltip,
  Typography,
} from "@smartrent/ui";
import { View, StyleSheet } from "react-native";

import { InformationSolid } from "@smartrent/icons";

import { HubPrepAction } from "@/types";

export function HubPrepActionsInfo({ action }: { action: HubPrepAction }) {
  const steps = useMemo(() => {
    if (action === "procure") {
      return [
        "Register Hub Account",
        "Update Firmware (If Not Fusion)",
        "Check Cell Network",
        "Ensure Secure Inclusion Enabled",
      ];
    } else if (action === "unregister") {
      return ["Unregister From Group Account", "Register Hub Account"];
    } else if (action === "replacedSDCard") {
      return ["Rekey", "Box Sync"];
    } else if (action === "configUpdateRequired") {
      return [
        `Applies config updates to hub (e.g. applying unit's timezone, temperature scale)`,
      ];
    } else if (action === "advanced") {
      return [`Custom Selection`];
    } else if (action === "refurbish") {
      return [
        "Z-Wave reset",
        "Register Hub Account",
        "Update Firmware (If Not Fusion)",
        "Check Cell Network",
        "Ensure Secure Inclusion Enabled",
      ];
    }

    return [];
  }, [action]);

  return (
    <View style={styles.itemContainer}>
      <Text type="body">The following actions will be taken:</Text>

      <OrderedList type="numeric">
        {steps.map((step, idx) => (
          <OrderedListItem key={step} index={idx}>
            {/* add a tooltip for zwave reset step that we add when coming from hub refurbish wizard*/}
            {action === "refurbish" && idx === 0 ? (
              <HStack spacing={4} style={styles.zWaveRefurbishStep}>
                <Typography>{step}</Typography>
                <Tooltip
                  name="refurbishZWaveReset"
                  title="This step can take up to 5 minutes &amp; hub will reboot upon completion"
                  key={`${step}-refurbishZWaveReset`}
                >
                  <View style={styles.zWaveRefurbishStep}>
                    <InformationSolid
                      size={14}
                      style={styles.zWaveRefurbishStep}
                    />
                  </View>
                </Tooltip>
              </HStack>
            ) : (
              <Typography>{step}</Typography>
            )}
          </OrderedListItem>
        ))}
      </OrderedList>
    </View>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    maxWidth: "260px",
    marginVertical: 16,
  },
  zWaveRefurbishStep: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
